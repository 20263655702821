<template>
    <section class="page-auth-registration">
        <div class="page-auth-registration__success"
            v-if="step == 'success'"
            >
            <div class="page-auth-registration__success-title"
                v-text="'Регистрация прошла успешно'"
            />
            <div class="page-auth-registration__success-text"
                v-text="'Теперь вы можете просматривать объекты и управлять своим личным кабинетом'"
            />
            <div class="page-auth-registration__button">
                <ui-button
                    v-bind:href="urls.cabinet.dashboard"
                    target="_self"
                    text="В кабинет"
                />
            </div>
        </div>
        <template v-else>
            <div class="page-auth-registration__header">
                <div class="page-auth-registration__header-back"
                    v-if="step === 'verification'"
                    v-on:click="back"
                    >
                    <icon name="chevron-left" />
                </div>
                <page-auth-switcher
                    v-bind:content="content"
                    v-on:switch="$emit('switch', $event)"
                />
            </div>
            <div class="page-auth-registration__container">
                <template v-if="step === 'form'">
                    <div class="page-auth-registration__form">
                        <ui-form
                            v-bind:model="fields"
                            v-bind:validation="$v"
                            v-bind:submit-handler="getAuthCode"
                            v-on:update="updateFormData"
                        />
                    </div>
                    <div class="page-auth-registration__agreement">
                        <ui-checkbox-with-text
                            v-model="isAgreementAccepted"
                            >
                            Подтверждаю, что ознакомился и согласен с <a v-bind:href="urls.policies.userAgreement" target="_blank">Пользовательским
    соглашением</a> и <a v-bind:href="urls.policies.oferta" target="_blank">Условиями оферты</a>
                        </ui-checkbox-with-text>
                        <div class="page-auth-registration__agreement-error"
                            v-if="agreementError"
                            v-text="agreementError"
                        />
                    </div>
                    <div class="page-auth-registration__recaptcha">
                        <vue-recaptcha
                            v-bind:sitekey="recaptchaKey"
                            v-on:verify="recaptchaVerifyHandler"
                            v-on:expired="recaptchaExpiredHandler"
                            load-recaptcha-script
                            ref="recaptcha"
                        />
                        <div class="page-auth-registration__recaptcha-error"
                            v-if="recaptchaError"
                            v-text="recaptchaError"
                        />
                    </div>
                    <div class="page-auth-registration__button">
                        <ui-button
                            v-on:click="getAuthCode"
                            v-bind:disabled="$v.$invalid && $v.$dirty"
                            text="Подтвердить"
                        />
                    </div>
                </template>
                <template v-else-if="step === 'verification'">
                    <div class="page-auth-registration__text _center">
                        Введите код, отправленный на {{ fields.phone.value }}.
                    </div>
                    <div class="page-auth-registration__verification">
                        <div class="page-auth-registration__verification-code">
                            <ui-input-character-set
                                v-bind:size="codeLength"
                                v-model="code"
                                v-on:submit="registration"
                                v-on:update="resetCodeError"
                                v-bind:is-invalid="isCodeInvalid"
                                is-autofocus
                            />
                        </div>
                        <div class="page-auth-registration__verification-help"
                            v-if="codeRequestTimer === 0"
                            >
                            Не пришел код? <span class="page-auth-registration__verification-help-trigger"
                                v-on:click="resetCode"
                                >Отправить повторно</span>
                        </div>
                        <div class="page-auth-registration__verification-help"
                            v-else
                            >
                            <span v-text="'Повторно отправить код можно через '" /><span
                                class="page-auth-registration__verification-help-timer"
                                v-text="formattedTimerValue"
                            />
                        </div>
                    </div>
                    <div class="page-auth-registration__text _error"
                        v-if="multipleUsersError"
                        >
                        В системе найдено несколько пользователей с указанным номером телефона или адресом электронной почты.<br />
                        Обратитесь в <a v-bind:href="urls.support">поддержку</a>
                    </div>
                    <div class="page-auth-registration__text _error"
                        v-if="emailExistsError"
                        >
                        Указанный адрес электронной почты уже используется.<br />
                        Попробуйте <a v-bind:href="urls.auth.reset">восстановить пароль</a>
                    </div>
                    <div class="page-auth-registration__button">
                        <ui-button
                            v-on:click="registration"
                            v-bind:disabled="code.length !== codeLength || isCodeInvalid || multipleUsersError || emailExistsError || !isAgreementAccepted"
                            text="Регистрация"
                        />
                    </div>
                </template>
            </div>
        </template>
    </section>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import { mapState } from 'vuex';
import moment from 'moment';
import config from '~/config';
import forms from '~/forms';
import utils from '~/utils';

const localStorageReferralKey = config.localStorage.keyPrefix + config.localStorage.keyAffixies.referralCode;

export default {
    name: 'page-auth-registration',
    components: { VueRecaptcha },
    inject: [ 'image', 'recaptchaKey' ],
    props: {
        content: {
            type: String,
            default: 'login',
        },
    },
    data: () => ({
        step: 'form',
        recaptchaValue: null,
        urls: config.urls,
        codeLength: 6,
        code: '',
        isCodeInvalid: false,
        multipleUsersError: false,
        emailExistsError: false,
        isReferralToBeDeleted: false,
        isAgreementAccepted: false,
        fields: {
            firstName: {
                ...forms.fields.fullName,
            },
            phone: {
                ...forms.fields.phone,
                placeholder: '+7',
                label: 'Телефон (на этот номер придет СМС с кодом)',
            },
            email: {
                ...forms.fields.email,
                label: 'Эл.почта',
            },
            password1: {
                ...forms.fields.passwordNew,
            },
            password2: {
                ...forms.fields.passwordRepeat,
            },
        },
    }),
    validations() {
        const result = {
            fields: {
                firstName: { ...forms.validations.registrationFullName },
                phone: { ...forms.validations.phone },
                email: { ...forms.validations.email },
                password1: { ...forms.validations.passwordNew },
                password2: { ...forms.validations.passwordRepeat },
            },
            recaptchaValue: forms.validations.genericRequired.value,
            isAgreementAccepted: {
                isValid: value => value,
            },
        };
        return result;
    },
    computed: {
        ...mapState('sms', [
            'getAuthCodeIsLoading',
            'getAuthCodeError',
            'getAuthCodeResult',
            'codeRequestTimer',
        ]),
        ...mapState('users', [
            'registrationResult',
            'registrationIsLoading',
            'registrationError',
        ]),
        headerBackground() {
            return window.innerWidth <= 1023
                ? { backgroundImage: `url(${this.image})` }
                : null;
        },
        backTheme() {
            return window.innerWidth <= 1023 ? 'dark' : null;
        },
        recaptchaError() {
            if (this.$v.$invalid && this.$v.$dirty && !this.recaptchaValue) {
                return 'Подтвердите, что вы не робот';
            }
            return null;
        },
        agreementError() {
            if (this.$v.$invalid && this.$v.$dirty && !this.isAgreementAccepted) {
                return 'Необходимо ваше согласие';
            }
            return null;
        },
        isLoading() {
            return this.getAuthCodeIsLoading || this.registrationIsLoading;
        },
        formattedTimerValue() {
            return moment(this.codeRequestTimer).format('mm:ss');
        },
    },
    methods: {
        updateFormData(newData) {
            this.fields = newData;
        },
        recaptchaVerifyHandler(response) {
            this.recaptchaValue = response;
        },
        recaptchaExpiredHandler() {
            this.recaptchaValue = null;
        },
        back() {
            this.step = 'form';
        },
        resetRecaptcha() {
            if (this.$refs.recaptcha) {
                this.$refs.recaptcha.reset();
            }
        },
        resetCode() {
            this.code = '';
            this.back();
            this.$nextTick(this.resetRecaptcha);
        },
        getAuthCode() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            this.$store.dispatch('sms/getAuthCode', { value: this.fields.phone.value, recaptcha: this.recaptchaValue });
            this.step = 'verification';
        },
        registration() {
            if (this.code.length !== this.codeLength || this.isCodeInvalid || this.multipleUsersError || this.emailExistsError) {
                return;
            }
            const params = {
                phone: this.fields.phone.value,
                code: this.code,
                password: this.fields.password1.value,
                email: this.fields.email.value,
                firstName: this.fields.firstName.value,
            };
            let referral = window.localStorage.getItem(localStorageReferralKey);
            if (referral && referral !== 'null') {
                params.referralCode = referral;
                this.isReferralToBeDeleted = true;
            };
            this.$store.dispatch('users/registration', params);
        },
        resetCodeError() {
            this.isCodeInvalid = false;
        },
        checkReferral() {
            const referralQuery = utils.common.parseUrl().find(x => x.title === config.common.referralQuery);
            if (referralQuery) {
                window.localStorage.setItem(localStorageReferralKey, referralQuery.value);
            }
        },
    },
    mounted() {
        this.checkReferral();
    },
    watch: {
        isLoading(newVal) {
            this.$parent.$emit('update-loading', newVal);
        },
        getAuthCodeIsLoading(newVal) {
            if (newVal) {
                this.$v.$reset();
                this.resetRecaptcha();
            }
        },
        registrationIsLoading(newVal, oldVal) {
            if (!newVal && oldVal && this.registrationResult) {
                utils.analytics.gtag.register();
                utils.analytics.ym.register();
                utils.analytics.ym.registr();
                if (this.isReferralToBeDeleted) {
                    window.localStorage.setItem(localStorageReferralKey, '');
                    this.isReferralToBeDeleted = false;
                }
                this.step = 'success';
            }
            if (this.registrationError) {
                if (this.registrationError === ' Invalid verification code') {
                    this.isPasswordInvalid = true;
                } else {
                    this.isPasswordInvalid = false;
                }
                if (this.registrationError === ' Multiple users finds') {
                    this.multipleUsersError = true;
                } else {
                    this.multipleUsersError = false;
                }
                if (this.registrationError === ' Email exists') {
                    this.emailExistsError = true;
                } else {
                    this.emailExistsError = false;
                }
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-auth-registration {
    flex: 1 1 auto;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-direction: column;
    &__header {
        position: relative;

        flex: 0 0 auto;
        padding-bottom: 8px;
        &-back {
            position: absolute;
            top: 6px;
            left: 0;

            color: @color-gray-main;
            font-size: 2rem;

            cursor: pointer;
        }
    }
    &__container {
        flex: 1 1 auto;
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        flex-direction: column;
    }
    &__text {
        .typography-body();

        margin-bottom: 16px;
        &._center {
            text-align: center;
        }
        &._error {
            color: @color-accent-negative;
            text-align: center;
            & > a {
                color: @color-primary;
                text-decoration: underline;
            }
        }
    }
    &__success {
        text-align: center;
        &-title {
            .typography-large();

            margin-bottom: 16px;
            @media @media-md-down {
                margin-bottom: 32px;
            }
        }
        &-text {
            .typography-small();

            margin-bottom: 40px;
        }
    }
    &__form {
        flex: 0 0 auto;
        margin-bottom: 24px;
    }
    &__agreement {
        margin-bottom: 24px;
        &-error {
            .typography-body();

            margin-top: 16px;

            color: @color-accent-negative;
            text-align: center;
        }
    }
    &__recaptcha {
        flex: 0 0 auto;
        margin: 0 auto 40px;
        &-error {
            .typography-body();

            margin-top: 16px;

            color: @color-accent-negative;
            text-align: center;
        }
    }
    &__button {
        flex: 0 0 auto;
        width: 194px;
        margin: 0 auto 32px;
    }
    &__verification {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 40px;

        text-align: center;
        &-password {
            margin-bottom: 16px;
        }
        &-help {
            .typography-body();

            margin-top: 8px;

            text-align: center;
            &-trigger {
                cursor: pointer;

                color: @color-primary;
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }
            &-timer {
                display: inline-block;
                min-width: 40px;

                color: @color-primary;
                text-align: left;
            }
        }
    }
    &__login {
        .typography-body();

        flex: 0 0 auto;
        margin: auto 0 0;

        text-align: center;
        & a {
            color: @color-primary;
        }
    }

    @media @media-md-down {
        &__container {
            .container-paddings();

            padding-bottom: 32px;
        }
    }
    @media @media-xs-down {
        &__button {
            width: 100%;
        }
    }
}
</style>
