<template>
    <section class="page-market-item">
        <div class="page-market-item__main">
            <div class="page-market-item__main-image">
                <common-image
                    v-bind:href="isAuthorized ? item.estate.url : null"
                    v-bind:image="image"
                    is-absolute
                />
            </div>
            <div class="page-market-item__main-content">
                <div class="page-market-item__main-content-headline">
                    <div class="page-market-item__main-content-headline-amount"
                        v-if="item.userShares > 0"
                        >
                        <ui-label
                            v-text="getSharesAmountText(item.userShares)"
                            is-uppercase
                        />
                    </div>
                    <div class="page-market-item__main-content-headline-title"
                        v-text="item.estate.title"
                        v-on:click="toggle"
                    />
                </div>
                <div class="page-market-item__main-content-description"
                    v-text="item.estate.description"
                />
                <div class="page-market-item__main-content-apply"
                    >
                    <ui-button
                        v-on:click="apply"
                        theme="primary"
                        size="sm"
                        >
                        Оставить заявку
                    </ui-button>
                </div>
                <div class="page-market-item__main-content-stats">
                    <div class="page-market-item__main-content-stats-item">
                        <div class="page-market-item__main-content-stats-item-icon">
                            <icon name="buildings" />
                        </div>
                        <div class="page-market-item__main-content-stats-item-text"
                            v-text="item.estate.type"
                        />
                    </div>
                    <div class="page-market-item__main-content-stats-item"
                        v-if="item.estate.address"
                        >
                        <div class="page-market-item__main-content-stats-item-icon">
                            <icon name="pin" />
                        </div>
                        <div class="page-market-item__main-content-stats-item-text"
                            v-text="item.estate.address"
                        />
                    </div>
                </div>
                <div class="page-market-item__main-content-estate">
                    <component class="page-market-item__main-content-estate-link"
                        v-bind:is="isAuthorized ? 'a' : 'div'"
                        v-bind:href="isAuthorized ? item.estate.url : null"
                        v-on="isAuthorized ? null : { click: showAuthPopup }"
                        >
                        <span class="page-market-item__main-content-estate-link-text">
                            Страница объекта
                        </span>
                        <span class="page-market-item__main-content-estate-link-icon">
                            <icon name="arrow-right" />
                        </span>
                    </component>
                </div>
                <div class="page-market-item__main-content-info">
                    <div class="page-market-item__main-content-info-item">
                        <div class="page-market-item__main-content-info-item-title">
                            Рекомендуемая цена акции:
                        </div> <div class="page-market-item__main-content-info-item-value">
                            {{ item.priceFormatted + '\xa0' + currency }}
                            <!-- <span class="page-market-item__main-content-info-item-value-modifier"
                                v-text="'-2%'"
                            /> -->
                        </div>
                    </div>
                    <!-- <div class="page-market-item__main-content-info-item">
                        <div class="page-market-item__main-content-info-item-title">
                            Акций на продажу / покупку:
                        </div>
                        <div class="page-market-item__main-content-info-item-value">
                            50 / 40
                        </div>
                    </div> -->
                    <div class="page-market-item__main-content-info-item">
                        <div class="page-market-item__main-content-info-item-title">
                            Доходность (IRR):
                        </div>
                        <div class="page-market-item__main-content-info-item-value">
                            {{ item.estate.targetProfitability }} %
                        </div>
                    </div>
                    <div class="page-market-item__main-content-info-item">
                        <div class="page-market-item__main-content-info-item-title">
                            Комиссия для продавцов:
                        </div>
                        <div class="page-market-item__main-content-info-item-value">
                            {{ item.commissionForSeller * 100 }} %
                        </div>
                    </div>
                    <div class="page-market-item__main-content-info-item">
                        <div class="page-market-item__main-content-info-item-title">
                            Комиссия для покупателей:
                        </div>
                        <div class="page-market-item__main-content-info-item-value">
                            {{ item.commissionForBuyer * 100 }} %
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-market-item__expandable"
            v-if="isExpanded"
            >
            <div class="page-market-item__expandable-headline">
                <div class="page-market-item__expandable-headline-tabs">
                    <div class="page-market-item__expandable-headline-tabs-item"
                        v-for="(mode, index) in modes"
                        v-bind:key="index"
                        v-bind:class="{ _active: activeMode === mode.value }"
                        v-text="mode.title"
                        v-on:click="setMode(mode.value)"
                    />
                </div>
                <div class="page-market-item__expandable-headline-notifications _desktop">
                    <ui-button theme="secondary-1"
                        size="xxs"
                        icon-left="notifications"
                        v-on:click="showNotificationsModal"
                        >
                        <span>Уведомлять о новых заявках</span>
                    </ui-button>
                </div>
                <div class="page-market-item__expandable-headline-notifications _mobile">
                    <ui-button theme="secondary-1"
                        size="xxs"
                        icon-left="notifications"
                        is-width-free
                        v-on:click="showNotificationsModal"
                    />
                </div>
            </div>
            <div class="page-market-item__expandable-content">
                <page-market-item-orders
                    v-bind:filter="activeMode"
                    v-bind:share-id="item.id"
                />
            </div>
        </div>
        <div class="page-market-item__toggler">
            <ui-button
                v-on:click="toggle"
                v-bind:text="isExpanded ? 'Скрыть заявки' : 'показать заявки'"
                theme="secondary-1"
                size="sm"
            />
        </div>
    </section>
</template>

<script>
import { mapState } from 'vuex';
import utils from '~/utils';

export default {
    name: 'page-market-item',
    props: {
        item: {
            type: Object,
        },
    },
    data: () => ({
        isExpanded: false,
        isMobile: window.innerWidth < 1024,
        modes: [
            { title: 'Все заявки', value: 'all' },
            { title: 'Продажа', value: 'sale' },
            { title: 'Покупка', value: 'buy' },
        ],
        activeMode: 'all',
    }),
    computed: {
        ...mapState('market', [
            'createOrderIsLoading',
            'createOrderResult',

            'acceptOrderIsLoading',
            'acceptOrderResult',

            'cancelOrderIsLoading',
            'cancelOrderResult',
        ]),
        isAuthorized() {
            return this.$store.getters['users/isAuthorized'];
        },
        isVerified() {
            return this.$store.getters['users/isVerified'];
        },
        orders() {
            return this.$store.state.market.getOrdersByShare[this.item.id];
        },
        image() {
            return utils.common.injectResize(this.item.estate.image, this.isMobile ? '1000xAUTO' : '600x600');
        },
        currency() {
            return this.$store.state.site.currency.name.short;
        },
    },
    methods: {
        toggle() {
            if (!this.isAuthorized) {
                this.showAuthPopup();
                return;
            }
            this.isExpanded = !this.isExpanded;
        },
        showAuthPopup() {
            this.$store.commit('modals/push', 'auth-required');
        },
        showNotificationsModal() {
            this.$store.commit('modals/push', {
                name: 'market-notifications',
                props: {
                    shareId: this.item.id,
                    estate: this.item.estate,
                },
            });
        },
        apply() {
            if (!this.isAuthorized) {
                this.showAuthPopup();
            } else if (!this.isVerified) {
                this.$store.commit('modals/push', 'verification-required');
            } else {
                this.$store.commit('modals/push', {
                    name: 'market-application-create',
                    props: {
                        givenShareId: this.item.id,
                    },
                });
            }
        },
        setMode(newValue) {
            this.activeMode = newValue;
        },
        getSharesAmountText(amount) {
            return `У вас ${amount} ${utils.common.getDeclension(amount, [ 'акция', 'акции', 'акций' ])}`;
        },
        requestOrders() {
            this.$store.dispatch('market/getShareById', { id: this.item.id });
            this.$store.dispatch('market/getOrdersByShare', {
                share: this.item.id,
                status: 'active',
                ordering: 'created',
            });
        },
    },
    watch: {
        // orders(newVal, oldVal) {
        //     // if there are no more items
        //     // reloading list of all shares
        //     if (newVal.result && newVal.result.length === 0) {
        //         this.$emit('reload');
        //     }
        // },
        createOrderIsLoading(newVal) {
            if (!newVal && this.createOrderResult && this.createOrderResult.share === this.item.id) {
                this.requestOrders();
            }
        },
        acceptOrderIsLoading(newVal) {
            if (!newVal && this.acceptOrderResult && this.acceptOrderResult.share.id === this.item.id) {
                this.requestOrders();
            }
        },
        cancelOrderIsLoading(newVal) {
            if (!newVal && this.cancelOrderResult && this.cancelOrderResult.share.id === this.item.id) {
                this.requestOrders();
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-market-item {
    padding: 24px;
    border-radius: @border-radius-lg;

    background-color: @color-gray-lightest;
    &__main {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        &-image {
            position: relative;

            flex: 0 0 auto;
            width: 282px;
            height: 282px;
            margin-right: 40px;
        }
        &-content {
            flex: 1 1 auto;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-wrap: wrap;
            &-headline {
                flex: 0 0 auto;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                width: 100%;
                margin-bottom: 16px;
                &-amount {
                    flex: 0 0 auto;
                    margin-top: 2px;
                    margin-right: 16px;
                }
                &-title {
                    .typography-medium();

                    flex: 0 0 auto;

                    cursor: pointer;
                }
            }
            &-description {
                .typography-body();

                flex: 1 1 auto;
                max-width: calc(100% - 175px);
                padding-right: 33px;
            }
            &-apply {
                flex: 0 0 auto;
                height: 40px;
                width: 174px;
            }
            &-stats {
                flex: 1 1 auto;
                display: flex;
                align-items: baseline;
                justify-content: flex-start;
                width: 100%;
                margin-top: 14px;
                margin-bottom: 12px;
                &-item {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    &-icon {
                        padding: 5px;
                        border-radius: 50%;
                        margin-right: 8px;

                        font-size: 1.4rem;
                    }
                    &-text {
                        .typography-body();
                    }
                    &:not(:last-child) {
                        margin-right: 40px;
                    }
                    &:nth-child(1) &-icon {
                        color: @color-accent-negative;

                        background-color: fade(@color-accent-negative, 10%);
                    }
                    &:nth-child(2) &-icon {
                        color: @color-primary;

                        background-color: fade(@color-primary, 10%);
                    }
                }
            }
            &-estate {
                flex: 1 1 auto;
                width: 100%;
                margin-bottom: 16px;
                &-link {
                    .typography-body();

                    display: inline-block;

                    text-decoration: none;

                    cursor: pointer;
                    &-text {
                        display: inline-block;
                        margin-right: 12px;

                        text-decoration: underline;
                    }
                    &-icon {
                        display: inline-block;

                        font-size: 1.2rem;
                        vertical-align: middle;
                    }
                }
            }
            &-info {
                flex: 1 1 auto;
                width: 100%;
                border-top: 1px solid @color-gray-light;
                padding-top: 17px;
                &-item {
                    &:not(:last-child) {
                        margin-bottom: 6px;
                    }
                    &-title {
                        .typography-body();

                        flex: 0 0 auto;
                        display: inline-block;
                        margin-right: 8px;

                        color: @color-gray-main;
                    }
                    &-value {
                        .typography-heading();

                        display: inline-block;
                        &-modifier {
                            .typography-caption();

                            display: inline-block;
                            padding: 2px 4px 1px;
                            border-radius: @border-radius-lg;
                            margin-left: 8px;

                            color: @color-accent-negative;
                            font-weight: 700;

                            background-color: fade(@color-accent-negative, 10%);
                        }
                    }
                }
            }
        }
    }
    &__expandable {
        margin-top: 24px;
        &-headline {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            margin-bottom: 16px;
            &-tabs {
                flex: 1 1 auto;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                &-item {
                    .typography-heading();

                    opacity: 0.5;
                    cursor: pointer;

                    transition: opacity @duration-fast @easing-default;
                    &:not(:last-child) {
                        margin-right: 24px;
                    }
                    &._active {
                        opacity: 1;
                        pointer-events: none;
                    }
                }
            }
            &-notifications {
                flex: 0 0 auto;
                &._desktop {
                    display: block;
                }
                &._mobile {
                    display: none;
                }
            }
        }
        &-content {
        }
    }
    &__toggler {
        margin-top: 24px;
    }
    @media @media-md-down {
        padding: 16px;
        &__main {
            flex-direction: column;
            &-image {
                width: 100%;
                height: auto;
                padding-top: 50%;
                margin-right: 0;
                margin-bottom: 16px;
            }
            &-content {
                width: 100%;
                &-headline {
                    position: relative;

                    order: -4;
                    flex-wrap: wrap;
                    padding-right: 32px;
                    &-amount {
                        order: -2;
                        margin-bottom: 8px;
                    }
                    &-title {
                        .typography-heading();

                        width: 100%;
                    }
                }
                &-estate {
                    order: -3;
                }
                &-info {
                    order: -2;
                    margin-bottom: 24px;
                    &-item {
                        &-value {
                            &-modifier {
                                margin-left: 4px;
                            }
                        }
                    }
                }
                &-apply {
                    order: -1;
                    width: 100%;
                    height: 48px;
                    margin: 0 auto 24px;
                }
                &-description {
                    width: 100%;
                    max-width: 100%;
                    padding-right: 0;
                    margin-bottom: 16px;
                }
                &-stats {
                    flex-direction: column;
                    &-item {
                        &:not(:last-child) {
                            margin-bottom: 8px;
                        }
                    }
                }
            }
        }
    }
    @media @media-sm-down {
        &__expandable {
            &-headline {
                &-tabs {
                    &-item {
                        margin-right: 8px;
                        &:not(:last-child) {
                            margin-right: 8px;
                        }
                    }
                }
                &-notifications {
                    &._desktop {
                        display: none;
                    }
                    &._mobile {
                        display: block;
                    }
                }
            }
        }
    }
}
</style>
