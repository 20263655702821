const state = {
    files: {},
};

const getters = {
    files(state) {
        return state.files;
    },
};

const actions = {
};

const mutations = {
    toggleFile(state, payload) {
        let files = { ...state.files };
        if (files[payload.variantId] === undefined) {
            files[payload.variantId] = [ payload.documentId ];
        } else {
            if (files[payload.variantId].indexOf(payload.documentId) > -1) {
                files[payload.variantId] = files[payload.variantId].filter(x => x !== payload.documentId);
            } else {
                files[payload.variantId].push(payload.documentId);
            }
        }
        state.files = files;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
