<template>
    <section class="page-password" />
</template>

<script>
export default {
    name: 'page-password',
    props: {
        email: {
            type: String,
        },
        token: {
            type: String,
        },
        uid: {
            type: String,
        },
    },
    mounted() {
        this.$store.commit('modals/push', {
            name: 'reset-complete',
            props: {
                email: this.email,
                token: this.token,
                uid: this.uid,
                isOverlayClickable: false,
            },
        });
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-password {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    background-image: url('/static/images/page-password-background.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
</style>
