<template>
    <div class="page-admin-login-form">
        <div class="page-admin-login-form__text">
            Введите номер телефона или электронную почту, которые вы указывали при регистрации
        </div>
        <div class="page-admin-login-form__form">
            <ui-form
                v-bind:model="fields"
                v-bind:validation="$v"
                v-bind:submit-handler="submitHandler"
                v-on:update="updateFormData"
            />
        </div>
        <div class="page-admin-login-form__text _error"
            v-if="loginError"
            v-text="'Ошибка: неверный email/телефон или пароль'"
        />
        <div class="page-admin-login-form__button">
            <ui-button
                v-on:click="submitHandler"
                v-bind:disabled="$v.$invalid && $v.$dirty"
                text="Войти"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import config from '~/config';
import forms from '~/forms';

export default {
    name: 'page-admin-login-form',
    data: () => ({
        step: 'form',
        urls: config.urls,
        fields: {
            username: { ...forms.fields.username },
            password: { ...forms.fields.passwordCurrent },
        },
    }),
    validations() {
        const result = {
            fields: {
                username: { ...forms.validations.genericRequired },
                password: { ...forms.validations.genericRequired },
            },
        };
        return result;
    },
    computed: {
        ...mapState('users', [
            'loginResult',
            'loginIsLoading',
            'loginError',
        ]),
    },
    methods: {
        updateFormData(newData) {
            this.fields = newData;
        },
        submitHandler() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            this.$store.dispatch('users/login', {
                username: this.fields.username.value,
                password: this.fields.password.value,
            });
        },
    },
    watch: {
        loginIsLoading(newVal, oldVal) {
            if (!newVal && oldVal && this.loginResult) {
                window.location.href = config.urls.adminDashboard.estates;
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-admin-login-form {
    .typography-body();
    &__text {
        .typography-small();

        margin-bottom: 16px;
        &._error {
            color: @color-accent-negative;
        }
    }
    &__form {
        margin-bottom: 24px;
    }
    &__button {
        flex: 0 0 auto;
        width: 194px;
        margin: 0 auto 32px;
    }
    @media @media-sm-down {
    }
}
</style>
