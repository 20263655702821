<template>
    <ui-input-base-select
        v-bind:options="options"
        v-bind:value="value"
        v-on:change="changeHandler"
        v-on:focus="focusHandler"
        v-on:blur="blurHandler"
        is-list
        icon="document"
    />
</template>

<script>
export default {
    name: 'ui-link-list',
    props: {
        options: {
            type: Array,
        },
    },
    data: () => ({
        value: '',
    }),
    methods: {
        changeHandler(newValue) {
            this.$emit('change', newValue);
            this.value = newValue;
            window.open(newValue, '_blank');
        },
        focusHandler(data) {
            this.$emit('focus', data);
        },
        blurHandler(data) {
            this.$emit('blur', data);
        },
    },
    beforeMount() {
        if (this.options?.length > 0) {
            this.value = this.options[0].value;
        }
    },
};
</script>

<style scoped lang="less">
@import "~theme";
</style>
