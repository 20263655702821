<template>
    <div class="plugin-referral-solutions">
        <div class="plugin-referral-solutions__title"
             v-if="title"
             v-html="title"
        />
        <div class="plugin-referral-solutions__items">
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'plugin-referral-solutions',
    props: {
        title: {
            type: String,
            default: null,
        },
    },
};
</script>

<style lang="less">
@import '~theme';

.plugin-referral-solutions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 64px;

    margin-top: 150px;

    &__title {
        .typography-landing-heading();
    }

    &__items {
        display: grid;
        grid-gap: 60px;
    }

    @media @media-lg-down {
        grid-template-columns: 1fr 1fr;

        &__title {
            .typography-extra-large();
        }
    }

    @media @media-md-down {
        grid-gap: 40px;

        &__title {
            .typography-landing-small-heading();
        }
    }

    @media @media-sm-down {
        grid-template-columns: 1fr;
    }
}
</style>
