<template>
    <div class="layout-cabinet-sideblock-userinfo">
        <template v-if="user && user !== 'guest'">
            <div class="layout-cabinet-sideblock-userinfo__name">
                <span v-text="user.firstName" /> <span class="layout-cabinet-sideblock-userinfo__name-last"
                    v-text="user.lastName"
                /><span class="layout-cabinet-sideblock-userinfo__name-qi"
                    v-if="user.isQualifiedInvestor"
                    >
                    <icon name="sign-check" original />
                </span>
            </div>
            <div class="layout-cabinet-sideblock-userinfo__email">
                <span v-text="user.email" />
            </div>
        </template>
        <template v-else>
            <div class="layout-cabinet-sideblock-userinfo__name">
                Гость
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'layout-cabinet-sideblock-userinfo',
    computed: {
        user() {
            return this.$store.getters['users/user'];
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.layout-cabinet-sideblock-userinfo {
    flex: 1 1 auto;
    padding-bottom: 24px;
    margin-bottom: auto;
    &__name {
        .typography-heading();

        margin-bottom: 9px;

        line-height: 1.8rem;
        &-last {
            margin-right: 4px;
        }
        &-qi {
            display: inline-block;

            font-size: 1.4rem;
            vertical-align: middle;
            &:hover {
                position: relative;
            }
            &:hover:after {
                .typography-body();

                content: 'Квалифицированный инвестор';

                position: absolute;
                top: 100%;
                left: 100%;

                padding: 4px 8px;
                border-radius: @border-radius-lg;

                font-weight: normal;
                text-align: center;

                background-color: @color-gray-lightest;

                box-shadow: @shadow-primary;
            }
        }
    }
    &__email {
        .typography-caption();

        margin-left: 1px;

        color: @color-gray-main;
    }
    @media @media-md-down {
        display: none;
    }
}
</style>
