<template>
    <label class="common-file-loader"
        v-on:drop.stop.prevent="onDrop"
        v-on:dragover.prevent
        v-bind:class="{
            _loaded: isLoaded,
            _loading: isLoading,
            _error: error !== null,
        }"
        v-bind:for="inputId"
        >
        <div class="common-file-loader__loader"
            v-bind:class="{
                _instant: file === null,
            }"
            v-bind:style="loaderStyle"
        />
        <div class="common-file-loader__empty"
            v-if="file === null && !error"
            >
            <div class="common-file-loader__empty-icon">
                <icon name="plus" />
            </div>
            <div class="common-file-loader__empty-text"
                v-if="text"
                v-text="text"
            />
        </div>
        <div class="common-file-loader__info"
            v-else
            >
            <div class="common-file-loader__info-title"
                v-text="file ? file.name : innerName"
            />
            <div class="common-file-loader__info-status"
                v-text="status"
            />
            <div class="common-file-loader__info-remove"
                v-if="!isLoading"
                v-on:click.prevent="reset"
                >
                <icon name="cross-round" />
            </div>
        </div>
        <input class="common-file-loader__input"
            v-bind:id="inputId"
            type="file"
            v-on:change="onFileChange"
            v-bind:disabled="isLoading"
            ref="input"
        />
        <!-- accept=".jpg, .jpeg, .png, .pdf" -->
    </label>
</template>

<script>
export default {
    name: 'common-file-loader',
    props: {
        text: {
            type: String,
            default: 'Загрузить файл',
        },
        file: {
            type: [ File, String ],
            default: null,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        givenError: {
            type: String,
            default: null,
        },
        progress: {
            type: Number,
            default: 0,
        },
    },
    model: {
        prop: 'file',
        event: 'change',
    },
    data: () => ({
        // validFileTypes: [ 'image/jpeg', 'image/pjpeg', 'image/png', 'application/pdf' ],
        validFileTypes: [],
        internalError: null,
        imageTempSrc: null,
        limit: 8 * 1024 * 1024, // 8 Mb
        innerName: '',
    }),
    computed: {
        loaderStyle() {
            return { transform: `translateX(${this.progress - 100}%)` };
        },
        status() {
            if (this.error) {
                return this.error;
            } else if (this.isLoading) {
                return 'Загружается...';
            } else {
                return `${(this.file.size / 1024 / 1024).toFixed(1)} Мб, загружено`;
            }
        },
        error() {
            return this.internalError || this.givenError || null;
        },
        isLoaded() {
            return !this.error && !this.isLoading && this.file !== null;
        },
        inputId() {
            return 'upload-photo-' + this._uid;
        },
    },
    methods: {
        reset() {
            this.internalError = null;
            this.$refs.input.value = '';
            this.$emit('change', null);
        },
        isValidFileType(file) {
            return this.validFileTypes.length === 0 || this.validFileTypes.indexOf(file.type) > -1;
        },
        onDrop(e) {
            const files = e.dataTransfer.files;
            if (files.length > 0) {
                this.setImage(files[0]);
            } else {
                this.$emit('change', null);
            }
        },
        onFileChange(e) {
            if (e.target.files.length > 0) {
                this.setImage(e.target.files[0]);
            } else {
                this.$emit('change', null);
            }
        },
        async setImage(file) {
            this.internalError = null;
            this.imageTempSrc = null;
            if (!this.isValidFileType(file)) {
                this.internalError = 'Тип файла не jpeg/png/pdf';
                return;
            }
            if (file.size > this.limit) {
                this.innerName = file.name;
                this.internalError = 'Файл не должен превышать 20 Мб';
                return;
            }
            this.$emit('change', file);
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.common-file-loader {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: @border-radius-lg;
    border: 1px dashed currentColor;
    overflow: hidden;

    color: @color-gray-main;

    background-color: @color-gray-lighter;

    cursor: pointer;
    &._error,
    &._loaded {
        border-style: solid;
    }
    &._error &,
    &._loaded & {
        &__icon {
            font-size: 3.2rem;
        }
    }
    &._error {
        color: @color-accent-negative;

        background-color: fade(@color-accent-negative, 10%);
    }
    &._loading {
        pointer-events: none;
    }
    &._loaded {
        color: @color-primary;
    }
    &__loader {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background-color: fade(@color-gray-main, 10%);

        transition: transform @duration-normal @easing-default, background-color @duration-fast @easing-default;
        &._instant {
            transition-duration: 0s;
        }
        ._loaded > & {
            background-color: fade(@color-primary, 10%);
        }
    }
    &__empty {
        position: relative;
        z-index: 1;

        display: flex;
        justify-self: center;
        align-items: center;
        padding: 7px;

        text-align: center;
        &-icon {
            font-size: 1rem;
        }
        &-text {
            .typography-body();

            margin-left: 8px;
        }
    }
    &__info {
        .typography-caption();

        position: relative;
        z-index: 1;

        width: 100%;
        height: 100%;
        padding: 12px 48px 12px 16px;
        &-title {
            margin-bottom: 4px;

            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        &-text {

        }
        &-remove {
            position: absolute;
            top: 6px;
            right: 0;

            padding: 16px;

            font-size: 1.6rem;

            cursor: pointer;
        }
    }
    &__input {
        position: absolute;
        z-index: -1;

        opacity: 0;
    }
    @media @media-md-down {
    }
}
</style>
