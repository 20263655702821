<template>
    <span>common-columns-36</span>
</template>

<script>
export default {
    name: 'common-columns-36',
};
</script>

<style lang="less">
@import '~theme';

.common-columns-36 {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: stretch;
    margin: 0 -@grid-gap-half;
    &__item {
        order: 36;
        flex: 0 0 auto;
        padding: 0 @grid-gap-half;
        width: 100%;
        &._common {
            &_hidden {
                display: none;
            }
            &_width {
                .generate-columns(36);
            }
            &_order {
                .generate-orders(36);
            }
            &_offset {
                .generate-offsets(36);
            }
        }
        &._xl {
            @media @media-xl-up {
                &_hidden {
                    display: none;
                }
                &_width {
                    .generate-columns(36);
                }
                &_order {
                    .generate-orders(36);
                }
                &_offset {
                    .generate-offsets(36);
                }
            }
        }
        &._lg {
            @media @media-lg-only {
                &_hidden {
                    display: none;
                }
                &_width {
                    .generate-columns(36);
                }
                &_order {
                    .generate-orders(36);
                }
                &_offset {
                    .generate-offsets(36);
                }
            }
        }
        &._md {
            @media @media-md-only {
                &_hidden {
                    display: none;
                }
                &_width {
                    .generate-columns(36);
                }
                &_order {
                    .generate-orders(36);
                }
                &_offset {
                    .generate-offsets(36);
                }
            }
        }
        &._sm {
            @media @media-sm-down {
                &_hidden {
                    display: none;
                }
                &_width {
                    .generate-columns(36);
                }
                &_order {
                    .generate-orders(36);
                }
                &_offset {
                    .generate-offsets(36);
                }
            }
        }
    }
}</style>
