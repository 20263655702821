<template>
    <div class="notification-holidays-warning">
        В период с 28.10.2021 по 07.11.2021 инвестиционная платформа SimpleEstate работает в штатном режиме, без изменений графика и выходных дней.
    </div>
</template>

<script>
import config from '~/config';

export default {
    name: 'notification-holidays-warning',
    props: [ 'onClose' ],
    data: () => ({
        urls: config.urls,
    }),
};
</script>

<style scoped lang="less">
@import '~theme';

.notification-holidays-warning {
    .notification();
}
</style>
