<template>
    <div class="plugin-about-person">
        <div class="plugin-about-person__header">
            <div class="plugin-about-person__header-texts"
                v-if="title || description"
                >
                <div class="plugin-about-person__header-texts-title"
                    v-if="title"
                    v-text="title"
                />
                <div class="plugin-about-person__header-texts-description"
                    v-if="description"
                    v-text="description"
                />
            </div>
            <a class="plugin-about-person__header-award"
                href="https://30-under-30.forbes.ru/2023/487734-nikita-kornienko"
                target="_blank"
                v-bind:class="{ _active: isAwardVisible }"
                >
                <img class="plugin-about-person__header-award-image"
                    src="/static/images/forbes-30-under-30.png"
                />
                <div class="plugin-about-person__header-award-text">
                    🔥 Номинант списка Forbes «30 самых перспективных финансистов до 30 лет 2023»
                </div>
            </a>
            <common-intersection-observer
                v-if="title === 'Основатель: Никита Корниенко'"
                v-on:intersect="isAwardVisible = true"
                >
            </common-intersection-observer>
        </div>
        <div class="plugin-about-person__body">
            <div class="plugin-about-person__body-image"
                v-bind:style="imageBackgroundStyle"
            />
            <div class="plugin-about-person__body-info">
                <div class="plugin-about-person__body-info-title"
                    v-text="subtitle"
                />
                <div class="plugin-about-person__body-info-content">
                    <slot />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import utils from '~/utils';

export default {
    name: 'plugin-about-person',
    props: {
        title: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        subtitle: {
            type: String,
            default: '',
        },
        text: {
            type: String,
            default: '',
        },
        image: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            isAwardVisible: false,
        };
    },
    computed: {
        imageBackgroundStyle() {
            return { backgroundImage: `url(${utils.common.injectResize(this.image, '876xAUTO')})` };
        },
    },
};
</script>

<style lang="less">
@import '~theme';

.plugin-about-person {
    .container();
    .container-paddings();

    padding-top: 60px;
    padding-bottom: 80px;
    &__header {
        position: relative;

        margin-bottom: 60px;
        &::before {
            content: '';

            position: absolute;
            top: -60px;
            bottom: 176px;
            left: -@container-padding;
            z-index: -1;

            display: block;
            width: calc(@container-padding + @grid-column * 3 + @grid-gap * 2);
            height: 606px;

            background-color: @color-gray-lighter;
        }
        &-texts {
            .typography-extra-large();

            flex: 1 1 auto;
            width: 100%;
            padding: 0 calc(5 * (@grid-column + @grid-gap)) 0 calc(@grid-column + @grid-gap);
            &-title {
                .typography-extra-large();

                margin-bottom: 24px;
            }
            &-description {
                .typography-body-lg();

                white-space: pre-line;
            }
        }
        &-award{
            position: absolute;
            top: 50%;
            right: calc(2.5 * (@grid-column + @grid-gap));
            z-index: 1;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 365px;
            padding: 16px;
            border: 2px solid @color-gray-darkest;
            border-radius: @border-radius-xl;

            text-decoration: none;

            background-color: @color-gray-lightest;
            box-shadow: 0px 18px 223px rgba(0, 0, 0, 0.07), 0px 4.02054px 49.81px rgba(0, 0, 0, 0.0417275), 0px 1.19702px 14.8297px rgba(0, 0, 0, 0.0282725);

            transform: translate(50%, -50%);
            opacity: 0;
            &._active {
                animation: plugin-about-person__header-award 0.75s ease-in-out forwards;
            }
            &-image {
                display: block;
                width: 180px;
                margin-bottom: 8px;
            }
            &-text {
                .typography-heading();

                text-align: center;
                line-height: 2rem;
            }
        }
    }
    &__body {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        &-image {
            position: sticky;
            top: @container-header-height;

            flex: 0 0 auto;
            margin-left: calc(@grid-column + @grid-gap);
            margin-right: @grid-gap;
            width: calc(@grid-column * 4 + @grid-gap * 3);
            padding-top: 41%;

            background-color: @color-gray-light;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            .cms-toolbar-expanded & {
                top: @container-cms-height + @container-header-height;
            }
        }
        &-info {
            position: relative;

            flex: 1 1 auto;
            padding: 0 calc(@grid-column + @grid-gap);
            border-radius: @border-radius-lg 0 0 @border-radius-lg;
            &-title {
                .typography-large();

                padding-bottom: 16px;
                border-bottom: 2px solid @color-gray-darkest;
                margin-bottom: 32px;
            }
        }
    }
    @media @media-md-down {
        padding-bottom: 60px;
        &__header {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            margin-bottom: 32px;
            &::before {
                left: -@container-padding-mobile;

                width: 256px;
                height: 309px;
            }
            &-texts {
                padding: 0;
                &-title {
                    .typography-large();

                    margin-bottom: 16px;
                }
                &-description {
                    .typography-small();
                }
            }
            &-award {
                position: static;
                right: unset;
                padding: 8px;
                max-width: 100%;
                margin: 24px auto 0;
                &-image {
                    width: 140px;
                }
                &-text {
                    font-size: 1.4rem;
                    line-height: 1.8rem;
                }
            }
        }
        &__body {
            &-image {
                top: @container-header-height-mobile;
                margin: 0 24px 24px 0;
                width: 264px;
                max-width: 100%;
                height: 336px;
                padding: 0;
                .cms-toolbar-expanded & {
                    top: @container-cms-height + @container-header-height-mobile;
                }
            }
            &-info {
                width: auto;
                margin: 0 -@container-padding;
                padding: 0 @container-padding;
                &-title {
                    .typography-medium();

                    margin-bottom: 24px;
                }
            }
        }
    }
    @media @media-sm-down {
        &__body {
            display: block;
            &-image {
                position: static;
                top: 0;
                margin-bottom: 32px;
                .cms-toolbar-expanded & {
                    top: 0;
                }
            }
        }
    }
}
@keyframes plugin-about-person__header-award {
    0% {
        opacity: 0;
        transform: translate(50%, -50%) rotate(-30deg) scale(0.35);
    }
    50% {
        opacity: 1;
        transform: translate(50%, -50%) rotate(15deg) scale(1.15);
    }
    100% {
        opacity: 1;
        transform: translate(50%, -50%) rotate(5deg) scale(1);
    }
}
@media @media-md-down {
    @keyframes plugin-about-person__header-award {
        0% {
            opacity: 0;
            transform: rotate(-30deg) scale(0.35);
        }
        50% {
            opacity: 1;
            transform: rotate(15deg) scale(1.15);
        }
        100% {
            opacity: 1;
            transform: rotate(5deg) scale(1);
        }
    }
}
</style>
