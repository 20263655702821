<template>
    <div class="plugin-how-it-works-info-item">
        <div class="plugin-how-it-works-info-item__title">
            <div class="plugin-how-it-works-info-item__title-number" />
            <span class="plugin-how-it-works-info-item__title-text"
                v-text="title"
            />
        </div>
        <div class="plugin-how-it-works-info-item__text">
            <common-ckeditor v-html="text" />
        </div>
        <img class="plugin-how-it-works-info-item__image"
            v-bind:src="'/static/images/plugin-how-it-works-info_' + (itemIndex + 1) + (itemIndex <= 1 ? '_mobile' : '') + '.svg'"
        />
    </div>
</template>

<script>
export default {
    name: 'plugin-how-it-works-info-item',
    props: {
        title: {
            type: String,
        },
        text: {
            type: String,
        },
        isActive: {
            type: Boolean,
            default: false,
        },
        itemIndex: {
            type: Number,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-how-it-works-info-item {
    &__title {
        margin-bottom: 24px;
        &-number {
            .typography-medium();

            display: inline-block;
            width: 34px;
            height: 34px;
            border-radius: 50%;
            margin-right: 16px;

            color: @color-gray-lightest;
            line-height: 34px;
            text-align: center;
            vertical-align: middle;

            background-color: @color-primary;
            &:before {
                counter-increment: main-item;
                content: counter(main-item);
            }
        }
        &-text {
            .typography-large();

            vertical-align: middle;
        }
    }
    &__image {
        display: none;
    }
    @media @media-sm-down {
        &__title {
            margin-bottom: 16px;
            &-number {
                .typography-heading();

                width: 28px;
                height: 28px;
                margin-right: 8px;

                line-height: 28px;
            }
            &-text {
                .typography-medium();
            }
        }
        &__image {
            display: block;
            width: 100%;
            max-width: 312px;
            margin: 24px auto 0;
        }
    }
}
</style>
