var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"ui-form",class:{
        _gap_wide: _vm.gap === 'wide',
    },on:{"submit":function($event){$event.preventDefault();},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submitHandler($event)}}},[_c('div',{staticClass:"ui-form__fields"},_vm._l((_vm.model),function(field,key,i){return _c('div',{key:key,staticClass:"ui-form__fields-item",class:{
                _indent: field.withIndent,
                _half: field.isHalf,
                _fill: field.isFill,
                _fill_left: field.isFillLeft,
            },style:({ zIndex: field.type === 'select' ? Object.keys(_vm.model).length - i : null })},[_c('ui-form-field',{attrs:{"model":field,"validation":_vm.getFieldValidation(key),"value":field.value},on:{"update":function (val) { return _vm.modelUpdate(val, key); }}})],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }