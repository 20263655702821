<template>
    <div class="page-cabinet-referral-info-link">
        <div class="page-cabinet-referral-info-link__title">
            Партнеская программа
        </div>
        <div class="page-cabinet-referral-info-link__description">
            Для того, чтобы пригласить друга, отправьте ему данную ссылку:
        </div>
        <ui-card-base>
            <div class="page-cabinet-referral-info-link__data">
                <div class="page-cabinet-referral-info-link__data-link"
                    v-text="link"
                />
                <div class="page-cabinet-referral-info-link__data-button"
                    v-if="!isIE"
                    >
                    <ui-button
                        v-bind:disabled="isCopyInProgress"
                        v-on:click="copyLink"
                        text="Копировать"
                    />
                </div>
            </div>
        </ui-card-base>
    </div>
</template>

<script>
import config from '~/config';
import utils from '~/utils';

export default {
    name: 'page-cabinet-referral-info-link',
    data: () => ({
        isIE: utils.environments.isIE(),
        isCopyInProgress: false,
    }),
    computed: {
        user() {
            return this.$store.getters['users/user'];
        },
        link() {
            return `${config.urls.prodRoot}${config.urls.auth.registration}?${config.common.referralQuery}=${this.user.referrerCode}`;
        },
    },
    methods: {
        copyLink() {
            this.isCopyInProgress = true;
            navigator.clipboard.writeText(this.link)
                .then(() => {
                    this.isCopyInProgress = false;
                });
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-referral-info-link {
    &__title {
        .typography-heading();

        margin-bottom: 24px;
    }
    &__description {
        .typography-body();

        max-width: 529px;
        margin-bottom: 16px;
    }
    &__data {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        &-link {
            .typography-heading();

            flex: 1 1 auto;

            word-break: break-all;
        }
        &-button {
            flex: 0 0 auto;
            width: 206px;
            margin-left: 40px;
        }
    }
    @media @media-sm-down {
        &__title {
            margin-bottom: 16px;
        }
        &__description {
            margin-bottom: 24px;
        }
        &__data {
            display: block;
            &-button {
                width: 100%;
                margin-left: 0;
                margin-top: 16px;
            }
        }
    }
}
</style>
