<template>
    <div class="plugin-how-it-works-info-schemes">
        <img class="plugin-how-it-works-info-schemes__image"
            v-for="i in amount"
            v-bind:key="i"
            v-bind:src="'/static/images/plugin-how-it-works-info_' + i + '.svg'"
            v-bind:class="{ _active: activeItemIndex + 1 === i }"
        />
    </div>
</template>

<script>
export default {
    name: 'plugin-how-it-works-info-schemes',
    props: {
        amount: {
            type: Number,
        },
        activeItemIndex: {
            type: Number,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-how-it-works-info-schemes {
    position: relative;
    &__image {
        position: absolute;
        top: 0;
        left: 50%;

        display: block;
        width: auto;
        max-height: calc(100vh - @container-header-height - 59px - 178px);
        margin: 0 auto;

        opacity: 0;
        pointer-events: none;
        user-select: none;

        transform: translateX(-50%);

        transition: opacity @duration-normal @easing-default;
        &._active {
            position: static;
            opacity: 1;

            transform: none;
        }
    }
    @media @media-xxxxl-up {
        &__image {
            width: 100%;
        }
    }
}
</style>
