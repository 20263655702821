<template>
    <section class="page-cabinet-profile">
        <div class="page-cabinet-profile__container">
            <div class="page-cabinet-profile__loader"
                v-if="isLoading"
                >
                <div class="page-cabinet-profile__loader-container">
                    <ui-loader />
                </div>
            </div>
            <template v-else>
                <div class="page-cabinet-profile__block"
                    v-if="user !== null && user.status !== 'new' && !user.isIndividualEntrepreneur && !user.isQualifiedInvestor && user.qiRequestedDate !== null"
                    >
                    <common-qualification-link />
                </div>
                <div class="page-cabinet-profile__block">
                    <page-cabinet-profile-card
                        v-bind:title="'Персональные данные'"
                        >
                        <page-cabinet-profile-personal />
                    </page-cabinet-profile-card>
                </div>
                <div class="page-cabinet-profile__block">
                    <page-cabinet-profile-card
                        v-bind:title="'Юридические данные'"
                        >
                        <page-cabinet-profile-juridical />
                    </page-cabinet-profile-card>
                </div>
                <div class="page-cabinet-profile__block">
                    <div class="page-cabinet-profile__buttons">
                        <div class="page-cabinet-profile__buttons-item">
                            <ui-button
                                type="button"
                                v-on:click="showWarningModal"
                                v-bind:disabled="isLoading"
                                >
                                Изменить данные
                            </ui-button>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </section>
</template>

<script>
import { mapState } from 'vuex';
import config from '~/config';

export default {
    name: 'page-cabinet-profile',
    computed: {
        ...mapState('users', [
            'verificationResetIsLoading',
            'getUserProfileIsLoading',
            'getUserPersonalInfoIsLoading',
            'getUserJuridicalInfoIsLoading',
            'getUserAdditionalInfoIsLoading',
        ]),
        isLoading() {
            return this.getUserProfileIsLoading ||
                this.verificationResetIsLoading ||
                this.getUserPersonalInfoIsLoading ||
                this.getUserJuridicalInfoIsLoading ||
                this.getUserAdditionalInfoIsLoading;
        },
        user() {
            return this.$store.getters['users/user'];
        },
    },
    methods: {
        showWarningModal() {
            this.$store.commit('modals/push', {
                name: 'generic',
                props: {
                    theme: 'error',
                    title: 'Внимание',
                    text: 'Изменение данных осуществляется путем повторного прохождения верификации аккаунта. Большая часть функций аккаунта будет недоступна до завершения процесса проверки данных.',
                    buttons: [
                        {
                            text: 'Сброс верификации',
                            theme: 'primary',
                            callback: () => {
                                this.$store.dispatch('users/verificationReset', {
                                    onSuccess: () => {
                                        window.location.href = config.urls.cabinet.verification;
                                    },
                                });
                                this.$store.commit('modals/pop');
                            },
                        },
                        {
                            text: 'Отмена',
                            theme: 'secondary-1',
                        },
                    ],
                },
            });
        },
    },
    beforeMount() {
        this.$store.dispatch('users/getUserProfile');
        this.$store.dispatch('users/getUserPersonalInfo');
        this.$store.dispatch('users/getUserJuridicalInfo');
        this.$store.dispatch('users/getUserAdditionalInfo');
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-profile {
    width: 100%;
    padding: 20px 0 0;
    &__loader {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        min-height: 75vh;
        &-container {
            width: 100px;
        }
    }
    &__container {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        flex-direction: column;
        width: ~'calc(100% + 20px)';
        margin: 0 -10px;
    }
    &__block {
        flex: 1 1 auto;
        max-width: 668px;
        width: 668px;
        margin: 0 10px 40px;
        &._small {
            max-width: 370px;
            width: 370px;
        }
    }
    &__buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        width: 100%;
        margin: 0 0 40px;
        gap: 16px;
        &-item {
            flex: 0 0 auto;
        }
    }
    @media @media-md-down {
        &__block {
            order: -1;
            width: auto;
            &._small {
                order: 0;
            }
        }
    }
}
</style>
