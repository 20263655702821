<template>
    <div class="ui-progress"
        v-bind:class="[
            `_theme_${theme}`,
            {
                _empty: !value || value <= 0,
                _enlarged: isEnlarged,
                _minimum: value > 0,
            },
        ]"
        >
        <div class="ui-progress__indicator"
            v-bind:style="style"
        />
    </div>
</template>

<script>
export default {
    name: 'ui-progress',
    props: {
        value: {
            type: Number,
            default: 0,
        },
        theme: {
            type: String,
            default: 'primary',
        },
        isEnlarged: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        style() {
            return {
                width: this.value * 100 + '%',
            };
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-progress {
    width: 100%;
    height: 8px;
    border-radius: 10px;

    background-color: @color-secondary;
    &__indicator {
        width: 100%;
        height: 100%;
        max-width: 100%;
        border-radius: 10px;

        background-color: @color-primary;
        ._enlarged & {
            box-shadow: 0 0 0 2px @color-primary;
        }
        ._minimum & {
            min-width: 10px;
        }
    }
    &._theme_dark {
        background-color: @color-gray-lightest;
    }
    &._theme_dark > &__indicator {
        background-color: @color-gray-darkest;
        ._enlarged & {
            box-shadow: 0 0 0 2px @color-gray-darkest;
        }
    }
    &._theme_disabled {
        background-color: @color-gray-light;
    }
    &._theme_disabled > &__indicator {
        background-color: @color-gray-main;
        ._enlarged & {
            box-shadow: 0 0 0 2px @color-gray-main;
        }
    }
    &._empty:not(._theme_dark) {
        background-color: @color-gray-light;
    }
    &._empty:not(._theme_dark) > &__indicator {
        display: none;
    }
}
</style>
