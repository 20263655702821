<template>
    <div class="ui-input-radio-item"
        v-bind:class="{
            _disabled: !!model.isDisabled
        }"
        >
        <label class="ui-input-radio-item__main"
            tabindex="0"
            v-on:keydown.space.prevent="activate"
            >
            <div class="ui-input-radio-item__main-input">
                <ui-input-base-radio
                    v-bind:name="name"
                    v-bind:checked="isChecked"
                    v-bind:is-disabled="!!model.isDisabled"
                    v-on:change="updateModel"
                />
            </div>
            <div class="ui-input-radio-item__main-text"
                v-text="model.text ? model.text : model.value"
            />
        </label>
    </div>
</template>

<script>
export default {
    name: 'ui-input-radio-item',
    props: {
        model: {
            type: Object,
        },
        name: {
            type: String,
            default: '',
        },
        value: {
            type: [ String, Number, Boolean ],
            default: '',
        },
    },
    model: {
        prop: 'value',
        event: 'change',
    },
    methods: {
        activate() {
            if (this.model.value !== this.value) {
                this.$emit('change', this.model.value);
            }
        },
        updateModel(newValue) {
            if (newValue) {
                this.$emit('change', this.model.value);
            }
        },
    },
    computed: {
        isChecked() {
            return this.model.value === this.value;
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-input-radio-item {
    &__main {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        width: fit-content;

        cursor: pointer;
        outline: none;
        ._disabled > & {
            cursor: default;
        }
        &-input {
            flex: 0 0 auto;
            margin-top: 2px;
        }
        &-text {
            .typography-body();

            flex: 1 1 auto;
            margin-left: 8px;
        }
    }
    &__extra {
        max-width: 413px;
    }
}
</style>
