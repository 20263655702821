<template>
    <div class="page-cabinet-verification-confirmation">
        <transition name="fade">
            <div class="page-cabinet-verification-confirmation__loader"
                v-if="isLoading"
                >
                <div class="page-cabinet-verification-confirmation__loader-container">
                    <ui-loader />
                </div>
            </div>
        </transition>
        <div class="page-cabinet-verification-confirmation__list">
            <div class="page-cabinet-verification-confirmation__list-item">
                <ui-checkbox-with-text
                    v-model="rulesAccepted"
                    >
                    Подтверждаю, что ознакомился и согласен с <a v-bind:href="urls.rules" target="_blank">Правилами инвестиционной платормы</a>
                </ui-checkbox-with-text>
            </div>
            <div class="page-cabinet-verification-confirmation__list-item">
                <ui-checkbox-with-text
                    v-model="risksAccepted"
                    >
                    Подтверждаю, что ознакомился и согласен с <a v-bind:href="urls.risks" target="_blank">Декларацией о рисках</a>
                </ui-checkbox-with-text>
            </div>
            <div class="page-cabinet-verification-confirmation__list-item">
                <ui-checkbox-with-text
                    v-model="privacyAccepted"
                    >
                    Согласен на <a v-bind:href="urls.personalData" target="_blank">Обработку персональных данных</a>
                </ui-checkbox-with-text>
            </div>
            <div class="page-cabinet-verification-confirmation__list-item">
                <ui-checkbox-with-text
                    v-model="serviceAccepted"
                    >
                    Подтверждаю, что ознакомился и принимаю <a v-bind:href="urls.service" target="_blank">Договор оказания услуг по содействию в инвестировании между Оператором платформы и инвестором</a>
                </ui-checkbox-with-text>
            </div>
            <div class="page-cabinet-verification-confirmation__list-item">
                <ui-checkbox-with-text
                    v-model="genericAgreementAccepted"
                    >
                    Подтверждаю, что являюсь налоговым резидентом РФ и не являюсь российским или иностранным должностным лицом, действую к собственной выгоде и не имею бенефициаров
                </ui-checkbox-with-text>
            </div>
        </div>
        <div class="page-cabinet-verification-confirmation__phone"
            v-if="profile !== null"
            >
            <div class="page-cabinet-verification-confirmation__phone-title">
                Код будет выслан на номер:
            </div>
            <div class="page-cabinet-verification-confirmation__phone-value"
                v-text="profile.phone"
            />
        </div>
        <div class="page-cabinet-verification-confirmation__code"
            v-if="isGetCodeInitiated"
            >
            <common-sms-verification
                v-bind:phone="profile.phone"
                v-on:submit="approve"
                is-request-required
                no-text
            />
        </div>
        <div class="page-cabinet-verification-confirmation__buttons">
            <div class="page-cabinet-verification-confirmation__buttons-item"
                v-if="!isGetCodeInitiated"
                >
                <ui-button
                    v-on:click="isGetCodeInitiated = true"
                    v-bind:disabled="!isValid"
                    theme="primary"
                    size="md"
                    >
                    Отправить СМС-код
                </ui-button>
            </div>
        </div>
    </div>
</template>

<script>
import config from '~/config';
import { mainClient } from '~/network';

export default {
    name: 'page-cabinet-verification-confirmation',
    data: () => ({
        urls: { ...config.urls.policies },
        rulesAccepted: false,
        risksAccepted: false,
        privacyAccepted: false,
        serviceAccepted: false,
        genericAgreementAccepted: false,
        isApproveLoading: false,
        isGetCodeInitiated: false,
    }),
    computed: {
        isLoading() {
            return this.isApproveLoading;
        },
        isValid() {
            return this.rulesAccepted && this.risksAccepted &&
                this.privacyAccepted && this.serviceAccepted && this.genericAgreementAccepted;
        },
        profile() {
            return this.$store.state.users.getUserProfileResult;
        },
    },
    methods: {
        reset() {
            this.isGetCodeInitiated = false;
        },
        getCode() {
            this.isGetCodeInitiated = true;
        },
        async approve(code) {
            if (this.isApproveLoading) {
                return;
            }
            this.isApproveLoading = true;
            const { error, status } = await mainClient.users.requests.verificationApprove({
                code,
                value: this.profile.phone,
            });
            if (error) {
                console.error(error, status);
                console.error('Error above relates to component page-cabinet-verification-confirmation approve method');
            } else {
                location.href = config.urls.cabinet.verificationSuccess;
            }
            this.isApproveLoading = false;
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-verification-confirmation {
    position: relative;
    &__loader {
        .transition-fade();

        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        background-color: fade(@color-gray-lightest, 50%);
        &-container {
            width: 100px;
            margin: auto;
        }
    }
    &__list {
        margin: -8px 0 32px;
        max-width: 760px;
        &-item {
            padding: 8px 0;
        }
    }
    &__phone {
        margin: 0 auto 24px;

        text-align: center;
        &-title {
            .typography-body();

            margin-bottom: 8px;
        }
        &-value {
            .typography-heading();
        }
    }
    &__code {
        margin-bottom: 40px;
        &-headline {
            .typography-body();

            margin-bottom: 24px;

            text-align: center;
            &-trigger {
                cursor: pointer;

                color: @color-primary;
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        &-input {
            width: fit-content;
            margin: 0 auto;
        }
    }
    &__error {
        .typography-body();

        margin: 16px 0 40px;

        color: @color-accent-negative;
        text-align: center;
    }
    &__buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: -12px;
        &-item {
            width: 100%;
            max-width: 290px;
            padding: 12px;
        }
    }
    &__timer {
        .typography-body();

        margin-top: 24px;

        text-align: center;
        &-value {
            display: inline-block;
            min-width: 40px;

            color: @color-primary;
            text-align: left;
        }
    }
    @media @media-md-down {
    }
}
</style>
