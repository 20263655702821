<template>
    <div class="page-cabinet-profile-juridical">
        <ui-infolist
            v-bind:info="info"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'page-cabinet-profile-juridical',
    computed: {
        ...mapState('users', [
            'getUserProfileResult',
            'getUserJuridicalInfoResult',
        ]),
        info() {
            let result = [
                { title: 'ИНН', value: this.getUserJuridicalInfoResult.inn, isCopyAllowed: true },
            ];
            if (this.getUserProfileResult.legalType === 'I') {
                result = result.concat([
                    { title: 'Название', value: this.getUserJuridicalInfoResult.organizationName, isCopyAllowed: true },
                    { title: 'Адрес', value: this.getUserJuridicalInfoResult.organizationAddress, isCopyAllowed: true },
                    { title: 'ОГРНИП', value: this.getUserJuridicalInfoResult.ogrn, isCopyAllowed: true },
                ]);
            }
            if (this.getUserProfileResult.legalType === 'J') {
                result = result.concat([
                    { title: 'Краткое название', value: this.getUserJuridicalInfoResult.organizationName, isCopyAllowed: true },
                    { title: 'Полное название', value: this.getUserJuridicalInfoResult.organizationNameFull, isCopyAllowed: true },
                    { title: 'Юридический адрес', value: this.getUserJuridicalInfoResult.organizationAddress, isCopyAllowed: true },
                    { title: 'Адрес регистрации', value: this.getUserJuridicalInfoResult.organizationAddress, isCopyAllowed: true },
                    { title: 'Почтовый адрес', value: this.getUserJuridicalInfoResult.organizationAddress, isCopyAllowed: true },
                    { title: 'Банк', value: this.getUserJuridicalInfoResult.bankName, isCopyAllowed: true },
                    { title: 'ОГРН', value: this.getUserJuridicalInfoResult.ogrn, isCopyAllowed: true },
                    { title: 'ОКВЕД', value: this.getUserJuridicalInfoResult.okved, isCopyAllowed: true },
                    { title: 'КПП', value: this.getUserJuridicalInfoResult.kpp, isCopyAllowed: true },
                    { title: 'БИК', value: this.getUserJuridicalInfoResult.bik, isCopyAllowed: true },
                    { title: 'Корр. счет', value: this.getUserJuridicalInfoResult.ogrn, isCopyAllowed: true },
                    { title: 'Дата регистрации', value: this.getUserJuridicalInfoResult.dateRegistration, isCopyAllowed: true },
                ]);
            }
            return result;
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-profile-juridical {}
</style>
