<template>
    <div class="plugin-how-it-works-info">
        <div class="plugin-how-it-works-info__header">
            <div class="plugin-how-it-works-info__header-cover" />
            <div class="plugin-how-it-works-info__header-title">
                <div class="plugin-how-it-works-info__header-title-overlay" />
                <div class="plugin-how-it-works-info__header-title-content">
                    <h1 class="plugin-how-it-works-info__header-title-content-main">Как мы работаем</h1>
                    <h2 class="plugin-how-it-works-info__header-title-content-subtitle"
                        v-text="'Помогаем разобраться с инвестициями в коммерческую недвижимость'"
                    />
                </div>
            </div>
        </div>
        <div class="plugin-how-it-works-info__intro">
            <plugin-how-it-works-info-intro />
        </div>
        <div class="plugin-how-it-works-info__menu">
            <div class="plugin-how-it-works-info__menu-toggler"
                v-bind:class="{ _active: isMenuActive }"
                v-on:click="isMenuActive = !isMenuActive"
                >
                <div class="plugin-how-it-works-info__menu-toggler-title"
                    v-text="'Разделы'"
                />
                <div class="plugin-how-it-works-info__menu-toggler-icon">
                    <icon name="triangle" />
                </div>
            </div>
            <div class="plugin-how-it-works-info__menu-content"
                v-bind:class="{ _active: isMenuActive }"
                ref="menu"
                >
                <a class="plugin-how-it-works-info__menu-content-item"
                    v-for="(item, i) in items"
                    v-bind:key="i"
                    v-bind:class="{ _active: i === activeItemIndex }"
                    v-bind:href="'#' + itemIdText + i"
                    v-on:click="isMenuActive = false"
                    >
                    <div class="plugin-how-it-works-info__menu-content-item-text"
                        v-text="item.titleMenu ? item.titleMenu : item.title"
                    />
                </a>
            </div>
        </div>
        <div class="plugin-how-it-works-info__main">
            <div class="plugin-how-it-works-info__main-left">
                <div class="plugin-how-it-works-info__main-item"
                    v-for="(item, i) in items"
                    v-bind:key="i"
                    v-bind:ref="itemIdText + i"
                    >
                    <div class="plugin-how-it-works-info__main-item-anchor"
                        v-bind:id="itemIdText + i"
                    />
                    <plugin-how-it-works-info-item
                        v-bind:title="item.title"
                        v-bind:text="item.text"
                        v-bind:is-reversed="i % 2 !== 0"
                        v-bind:is-active="i <= activeItemIndex"
                        v-bind:item-index="i"
                    />
                </div>
            </div>
            <div class="plugin-how-it-works-info__main-right">
                <plugin-how-it-works-info-schemes
                    v-bind:amount="items.length"
                    v-bind:active-item-index="activeItemIndex"
                />
            </div>
        </div>
        <div class="plugin-how-it-works-info__footer">
            <div class="plugin-how-it-works-info__footer-title"
                v-if="isAuthorized"
                >
                Перейти к объектам <ui-logo-text />
            </div>
            <div class="plugin-how-it-works-info__footer-title"
                v-else
                >
                Зарегистрируйтесь сейчас и получите доступ к объектам <ui-logo-text />
            </div>
            <a class="plugin-how-it-works-info__footer-button"
                v-if="isAuthorized"
                v-bind:href="buttonHref"
                >
                <ui-button
                    icon-right="arrow-right"
                    theme="primary"
                    size="lg"
                    >
                    Наши объекты
                </ui-button>
            </a>
            <div class="plugin-how-it-works-info__footer-button"
                v-else
                >
                <ui-button
                    v-on:click="registration"
                    icon-right="arrow-right"
                    theme="primary"
                    size="lg"
                    >
                    Зарегистрироваться
                </ui-button>
            </div>
        </div>
    </div>
</template>

<script>
import config from '~/config';
import utils from '~/utils';

export default {
    name: 'plugin-how-it-works-info',
    props: {
        items: {
            type: Array,
        },
    },
    data: () => ({
        activeItemIndex: null,
        itemIdText: 'plugin-how-it-works-info__main-item-',
        buttonHref: config.urls.estates.list,
        isMenuActive: false,
    }),
    computed: {
        isAuthorized() {
            return this.$store.getters['users/isAuthorized'];
        },
    },
    methods: {
        checkMenuScroll() {
            const menuRef = this.$refs.menu;
            if (window.innerWidth > 1023 && menuRef && menuRef.scrollWidth > menuRef.offsetWidth) {
                this.updateMenuScroll();
            }
        },
        updateMenuScroll() {
            if (this.activeItemIndex === null) {
                return;
            }
            const menuRef = this.$refs.menu;
            const activeMenuItem = menuRef.children[this.activeItemIndex];
            const activeBounding = activeMenuItem.getBoundingClientRect();
            const menuBounding = menuRef.getBoundingClientRect();
            const currentScroll = menuRef.scrollLeft;
            if (activeBounding.left < menuBounding.left) {
                menuRef.scrollTo(currentScroll + activeBounding.left - menuBounding.left, 0);
            } else if (activeBounding.right > menuBounding.right) {
                menuRef.scrollTo(currentScroll + activeBounding.right - menuBounding.right, 0);
            }
        },
        registration() {
            window.location.href = config.urls.auth.registration;
        },
        updateActiveItemIndex(newIndex) {
            this.activeItemIndex = newIndex;
        },
        checkScroll() {
            for (let i = this.items.length - 1; i >= 0; i--) {
                const ref = this.$refs[this.itemIdText + i][0];
                if (ref.getBoundingClientRect().top <= window.innerHeight / 2) {
                    this.activeItemIndex = i;
                    break;
                }
            }
        },
        bindEvents() {
            window.addEventListener('scroll', this.checkScroll);
            window.addEventListener('resize', this.checkScroll);
            window.addEventListener('load', this.checkMenuScroll);
            window.addEventListener('resize', this.checkMenuScroll);
        },
        unbindEvents() {
            window.removeEventListener('scroll', this.checkScroll);
            window.removeEventListener('resize', this.checkScroll);
            window.removeEventListener('load', this.checkMenuScroll);
            window.removeEventListener('resize', this.checkMenuScroll);
        },
    },
    mounted() {
        this.checkScroll();
        this.checkMenuScroll();
        setTimeout(this.checkMenuScroll, 100);
        utils.dom.addLoadedCallback(this.checkScroll);
        this.bindEvents();
    },
    beforeDestroy() {
        this.unbindEvents();
    },
    watch: {
        activeItemIndex(newVal) {
            if (newVal !== null) {
                this.checkMenuScroll();
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-how-it-works-info {
    .container();
    &__header {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        width: 100%;
        min-height: 323px;
        margin-bottom: 40px;
        &-cover {
            flex: 0 0 auto;
            width: 50%;

            background-image: url('/static/images/plugin-how-it-works-info-cover.jpg');
            background-color: @color-gray-main;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

            border-radius: 4px 0 0 4px;
        }
        &-title {
            position: relative;

            flex: 0 0 auto;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 50%;
            padding: 50px calc(@grid-gap + @grid-column) 45px;
            border-radius: 0 4px 4px 0;
            overflow: hidden;
            &-overlay {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                background: linear-gradient(60.83deg, #FFFEFB 7.74%, #F0F4F8 96.7%);
            }
            &-content {
                position: relative;
                &-main {
                    .typography-extra-large();

                    margin: 0 0 20px;
                }
                &-subtitle {
                    .typography-small();

                    margin: 0;
                    max-width: 360px;

                    font-weight: normal;
                }
            }
        }
    }
    &__intro {
        margin: 40px 0 80px;
    }
    &__menu {
        display: none;
        position: sticky;
        top: @container-header-height;
        z-index: 1;
        .cms-toolbar-expanded & {
            top: @container-header-height + @container-cms-height;
        }
        &-toggler {
            display: none;
        }
        &-content {
            display: flex;
            align-items: stretch;
            justify-self: start;
            overflow: auto;
            margin: 0 -8px;

            // background: linear-gradient(to top, fade(@color-gray-lightest, 0%) 0, @color-gray-lightest 8px);
            background: @color-gray-lightest;

            counter-reset: menu-item;
            &::-webkit-scrollbar {
                width: 4px;
                height: 4px;
            }
            &::-webkit-scrollbar-track,
            &::-webkit-scrollbar-thumb {
                border-radius: 2px;
                box-shadow: inset 0 0 0 10px rgba(0,0,0,0);
            }
            &:hover::-webkit-scrollbar-thumb,
            &:hover::-webkit-scrollbar-track {
                background-color: rgba(0,0,0,0.1);
            }
            &-item {
                flex: 1 1 0;
                display: block;
                min-width: 170px;
                padding: 8px;

                text-decoration: none;

                opacity: 0.25;

                transition: opacity @duration-fast @easing-default;
                &._active,
                &._active:after {
                    opacity: 1;
                }
                &-text {
                    .typography-caption();

                    display: -webkit-box;
                    height: 32px;
                    padding: 0 12px;
                    margin-bottom: 8px;
                    overflow: hidden;

                    font-weight: bold;
                    line-clamp: 2;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    text-align: center;
                    text-overflow: ellipsis;
                    text-transform: uppercase;
                    &:before {
                        counter-increment: menu-item;
                        content: counter(menu-item)'. ';
                    }
                }
                &:after {
                    content: '';

                    display: block;
                    height: 3px;
                    width: 100%;
                    border-radius: 2px;

                    background-color: currentColor;

                    opacity: 0.5;

                    transition: opacity @duration-fast @easing-default;
                }
            }
        }
    }
    &__main {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        margin-top: 40px;

        counter-reset: main-item;
        &-left {
            flex: 0 0 auto;
            width: calc(@grid-column * 7 + @grid-gap * 7);
            padding-left: calc(@grid-column + @grid-gap);
            padding-right: calc(@grid-column + @grid-gap * 2);
        }
        &-right {
            position: sticky;
            top: calc(99px + @container-header-height);

            flex: 0 0 auto;
            width: calc(@grid-column * 5 + @grid-gap * 4);
            padding-top: 58px;
            padding-right: calc(@grid-column + @grid-gap);
            margin-bottom: 100px;
            .cms-toolbar-expanded & {
                top: calc(99px + @container-header-height + @container-cms-height);
            }
        }
        &-item {
            position: relative;

            min-height: 50vh;
            margin-bottom: 100px;
            &-anchor {
                position: absolute;
                top: calc(-83px - @container-header-height);
                left: 0;

                opacity: 0;
                pointer-events: none;
                .cms-toolbar-expanded & {
                    top: calc(-83px - @container-header-height - @container-cms-height);
                }
            }
        }
    }
    &__footer {
        padding: 100px 115px 120px;
        margin-bottom: 40px;
        border-radius: @border-radius-lg;

        background-color: @color-gray-main;
        background-size: auto 100%, calc(100% - 385px) 100%;
        background-position: right center, left center;
        background-repeat: no-repeat;
        background-image: url(/static/images/plugin-how-it-works-info-footer.jpg), linear-gradient(75deg, #FEFDF8 0, #dee5ef 100%);
        &-title {
            .typography-extra-large();

            max-width: 660px;
            margin-bottom: 60px;
        }
        &-button {
            display: block;
            width: fit-content;

            text-decoration: none;
        }
    }
    @media @media-lg-down {
        &__main {
            &-left {
                padding-left: @grid-gap;
                padding-right: calc(@grid-column + @grid-gap);
            }
            &-right {
                padding-right: @grid-gap;
            }
        }
    }
    @media @media-md-down {
        padding: 0;
        &__header {
            min-height: 160px;
            padding: 0;
            margin-bottom: 0;
            &-cover {
                display: none;
            }
            &-title {
                .container-paddings();

                width: 100%;
                border-radius: 0;

                background-image: url('/static/images/plugin-how-it-works-info-cover_mobile.jpg');
                background-color: @color-gray-main;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                &-overlay {
                    opacity: 0.9;
                }
                &-content {
                    &-main {
                        .typography-large();

                        margin-bottom: 0;
                    }
                    &-subtitle {
                        .typography-body();
                    }
                }
            }
        }
        &__menu {
            top: @container-header-height-mobile;
            &-toggler {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                padding: 15px 24px;

                background: @color-gray-lighter;

                cursor: pointer;
                &-title {
                    .typography-caption();

                    font-weight: bold;
                    text-transform: uppercase;
                }
                &-icon {
                    font-size: 1.8rem;

                    transform: scaleY(-1);
                    ._active > & {
                        transform: scaleY(1);
                    }
                }
            }
            &-content {
                position: absolute;
                top: 48px;
                left: 0;

                display: none;
                width: 100%;
                padding: 12px 24px 24px;
                margin: 0;
                box-shadow: 0px 10px 10px rgba(33, 28, 64, 0.05);
                &._active {
                    display: block;
                }
                &-item {
                    padding: 0;
                    &-text {
                        height: auto;
                        padding: 0;
                        margin-top: 12px;

                        text-align: left;
                    }
                }
            }
        }
        &__main {
            padding: 0;
            &-right {
                top: calc(99px + @container-header-height-mobile);
                .cms-toolbar-expanded & {
                    top: calc(99px + @container-header-height-mobile + @container-cms-height);
                }
            }
        }
        &__footer {
            padding: 60px 24px;
            border-radius: 0;
            margin-bottom: 0;

            background-size: cover;
            background-position: center;
            background-image: linear-gradient(75deg, fade(#FEFDF8, 85%) 0, fade(#dee5ef, 85%) 100%), url(/static/images/plugin-how-it-works-info-footer.jpg);
            &-title {
                .typography-large();

                margin-bottom: 40px;
            }
            &-button {
                width: 100%;
            }
        }
    }
    @media @media-sm-down {
        &__main {
            &-left {
                width: 100%;
                padding-right: @grid-gap;
            }
            &-right {
                display: none;
            }
        }
    }
}
</style>
