import { mainClient } from '~/network';

const state = {
    getNoticesIsLoading: false,
    getNoticesError: null,
    getNoticesResult: null,
};

const getters = {
};

const actions = {
    async getNotices({ state }, payload = {}) {
        if (state.getNoticesIsLoading) {
            return;
        }
        state.getNoticesIsLoading = true;

        const { data, error } = await mainClient.cabinet.requests.getNotices(payload);
        if (error) {
            state.getNoticesError = error;
            state.getNoticesResult = null;
        } else if (data) {
            state.getNoticesResult = data;
            state.getNoticesError = null;
        }

        state.getNoticesIsLoading = false;
    },
};

const mutations = {
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
