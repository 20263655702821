<template>
    <div class="ui-table-cell-image">
        <common-image
            v-bind:image="src"
            v-bind:href="href"
            is-absolute
        />
    </div>
</template>

<script>
export default {
    name: 'ui-table-cell-image',
    props: {
        src: {
            type: String,
            default: '',
        },
        href: {
            type: String,
            default: null,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-table-cell-image {
    position: relative;

    width: 100%;
    padding-top: 126.7%;
}
</style>
