<template>
    <div class="common-footer-subscription">
        <div class="common-footer-subscription__title"
            v-text="`Подпишитесь на наши каналы на Youtube и в Telegram или на Email рассылку, чтобы ничего не пропустить`"
        />
        <div class="common-footer-subscription__channels">
            <a class="common-footer-subscription__channels-item"
                v-bind:href="telegramUrl"
                target="_blank"
                >
                <div class="common-footer-subscription__channels-item-icon">
                    <icon name="telegram" />
                </div>
                <div class="common-footer-subscription__channels-item-title"
                    v-text="'Telegram'"
                />
            </a>
            <a class="common-footer-subscription__channels-item"
                v-bind:href="youtubeUrl"
                target="_blank"
                >
                <div class="common-footer-subscription__channels-item-icon">
                    <icon name="youtube" />
                </div>
                <div class="common-footer-subscription__channels-item-title"
                    v-text="'Youtube'"
                />
            </a>
        </div>
        <form class="common-footer-subscription__form"
            v-if="!subscribeResult"
            v-on:submit.prevent="submit"
            v-bind:class="{ _loading: subscribeIsLoading }"
            >
            <input class="common-footer-subscription__form-input"
                placeholder="Email"
                v-model="email"
                type="email"
            />
            <button class="common-footer-subscription__form-button"
                type="submit"
                >
                <div class="common-footer-subscription__form-button-text">
                    Отправить
                </div>
                <div class="common-footer-subscription__form-button-icon">
                    <icon name="arrow-right" />
                </div>
            </button>
            <div class="common-footer-subscription__form-loader">
                <ui-loader />
            </div>
        </form>
        <div class="common-footer-subscription__error"
            v-if="!subscribeIsLoading && error"
            >
            <div class="common-footer-subscription__error-icon">
                <icon name="warning" />
            </div>
            <div class="common-footer-subscription__error-text">
                {{ error }}
            </div>
        </div>
        <div class="common-footer-subscription__success"
            v-if="!subscribeIsLoading && subscribeResult"
            >
            <div class="common-footer-subscription__success-icon">
                <icon name="check" />
            </div>
            <div class="common-footer-subscription__success-text">
                Вы подписаны на рассылку
            </div>
        </div>
        <div class="common-footer-subscription__disclaimer"
            v-else
            >
            Нажимая кнопку "Отправить", я{{'\xa0'}}даю согласие на{{'\xa0'}}получение новостной и{{'\xa0'}}рекламной рассылки.
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import forms from '~/forms';
import utils from '~/utils';

export default {
    name: 'common-footer-subscription',
    data: () => ({
        email: '',
    }),
    validations() {
        return { email: forms.validations.email.value };
    },
    computed: {
        ...mapState('subscription', [ 'subscribeIsLoading', 'subscribeError', 'subscribeResult' ]),
        error() {
            if (this.subscribeResult) {
                return null;
            }
            if (this.subscribeError) {
                return 'Ошибка: ' + utils.network.parseError(this.subscribeError, true);
            }
            if (this.$v.$invalid && this.$v.$dirty) {
                return 'Введите корректный email';
            }
            return null;
        },
        telegramUrl() {
            return this.$store.state.site.contacts.socials.telegram.href;
        },
        youtubeUrl() {
            return this.$store.state.site.contacts.socials.youtube.href;
        },
    },
    methods: {
        submit() {
            if (this.subscribeIsLoading) {
                return;
            }
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            this.$store.dispatch('subscription/subscribe', {
                email: this.email,
            });
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.common-footer-subscription {
    &__title {
        .typography-small();

        margin-bottom: 20px;
    }
    &__channels {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 100%;
        gap: 8px;
        margin-bottom: 20px;

        &-item {
            flex: 0 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 110px;
            height: 34px;
            border: 1px solid @color-gray-lightest;
            border-radius: 17px;

            text-decoration: none;
            &-icon {
                margin-right: 12px;

                color: @color-gray-lightest;
                font-size: 1.2rem;
            }
            &-title {
                .typography-body();
            }
        }
    }
    &__form {
        position: relative;

        display: flex;
        align-items: baseline;
        width: 100%;
        height: 48px;
        border-radius: 48px;
        margin-bottom: 12px;
        overflow: hidden;

        background-color: transparent;

        box-shadow: inset 0 0 0 1px @color-gray-main;
        &._loading {
            pointer-events: none;
        }
        &-input {
            .typography-body();

            flex: 1 1 auto;
            padding: 13px 16px 13px 24px;

            color: @color-gray-lighter;

            transition: opacity @duration-fast @easing-default;
            &::placeholder {
                color: @color-gray-main;
            }
            ._loading > & {
                opacity: 0;
            }
        }
        &-button {
            flex: 0 0 auto;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 24px;

            color: @color-gray-lighter;

            transition: opacity @duration-fast @easing-default;
            &-text {
                .typography-caption();

                margin-right: 8px;

                text-transform: uppercase;
            }
            &-icon {
                font-size: 1.6rem;
            }
            ._loading > & {
                opacity: 0;
            }
        }
        &-loader {
            position: absolute;
            top: 50%;
            left: 50%;

            width: 32px;
            height: 32px;

            transform: translate(-50%, -50%);

            pointer-events: none;
            opacity: 0;

            transition: opacity @duration-fast @easing-default;
            ._loading > & {
                opacity: 1;
            }
        }
    }
    &__error {
        .typography-small();

        display: flex;
        align-items: center;
        justify-content: flex-start;
        &-icon {
            flex: 0 0 auto;
            padding: 6px 0 0 8px;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            margin-right: 12px;

            color: @color-gray-lightest;
            font-size: 1.6rem;

            background-color: @color-accent-negative;
        }
    }
    &__success {
        .typography-small();

        display: flex;
        align-items: center;
        justify-content: flex-start;
        &-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            margin-right: 12px;

            color: @color-gray-lightest;
            font-size: 1.6rem;

            background-color: @color-primary;
        }
    }
    &__disclaimer {
        .typography-body();
    }
    @media @media-md-down {
        &__form {
            margin-bottom: 24px;
        }
    }
    @media @media-xs-down {
        &__title {
            margin-bottom: 16px;
        }
        &__channels {
            justify-content: stretch;
            flex-wrap: nowrap;
            &-item {
                flex: 1 1 100%;
            }
        }
        &__form {
            margin-bottom: 12px;
        }
        &__error {
            justify-content: center;

            text-align: center;
        }
        &__success {
            justify-content: center;

            text-align: center;
        }
    }
}
</style>
