<template>
    <div class="common-ckeditor">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'common-ckeditor',
};
</script>

<style lang="less">
@import '~theme';

.common-ckeditor {
    .typography-body();
    * {
        font-family: unset !important;
    }
    & h1 {
        .typography-extra-large();

        margin: 0;
    }
    & h2 {
        .typography-large();

        margin: 0;
    }
    & h3 {
        .typography-medium();

        margin: 0;
    }
    & h4 {
        .typography-small();

        font-weight: inherit;

        margin: 0;
    }
    & h5 {
        .typography-heading();

        margin: 0;
    }
    p, div {
        .typography-body();

        margin: 0;
    }
    @media @media-xs-down {
        & h1 {
            .typography-large();
        }
    }
}
</style>
