var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ui-loader",class:{
        _dark: _vm.dark,
        _light: _vm.light,
        _centered: _vm.centered,
        _mini: _vm.isMini,
    },style:({
        width: _vm.fixed ? _vm.fixed + 'px' : null,
        paddingTop: _vm.fixed ? _vm.fixed + 'px' : null,
    })})}
var staticRenderFns = []

export { render, staticRenderFns }