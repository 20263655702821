<template>
    <div class="plugin-referral-footer" role="footer">
        <div class="plugin-referral-footer__headline">
            <plugin-referral-footer-headline />
        </div>
        <!-- <div class="plugin-referral-footer__description"
            v-if="description"
            v-html="description"
        /> -->
    </div>
</template>

<script>
export default {
    name: 'plugin-referral-footer',
    props: {
        description: {
            type: String,
            default: null,
        },
    },
};
</script>

<style lang="less">
@import '~theme';

.plugin-referral-footer {
    margin: auto 0 80px;
    padding-top: 120px;
    &__description {
        .typography-body();

        padding: 40px 0 80px;
        border-top: 1px solid @color-gray-main;

        color: @color-gray-main;
        white-space: pre-line;
    }
    @media @media-lg-down {
        padding-top: 80px;
        &__description {
            margin-top: 40px;
        }
    }
}
</style>
