<template>
    <div class="page-cabinet-referral-create">
        <div class="page-cabinet-referral-create__info">
            <div class="page-cabinet-referral-create__info--container">
                <div class="page-cabinet-referral-create__info-title">
                    Приведи друга
                </div>
                <div class="page-cabinet-referral-create__info-description">
                    Приглашай друзей в SimpleEstate и получай 2% от суммы их первой инвестиции!
                </div>
                <div class="page-cabinet-referral-create__info-buttons">
                    <div class="page-cabinet-referral-create__info-buttons-item">
                        <ui-button
                            v-on:click="apply"
                            text="Стать партнером"
                            size="lg"
                        />
                    </div>
                    <div class="page-cabinet-referral-create__info-buttons-item">
                        <ui-button
                            v-bind:href="referralLandingUrl"
                            theme="secondary-1"
                            size="lg"
                            text="Подробнее"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="page-cabinet-referral-create__illustration" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import config from '~/config';

export default {
    name: 'page-cabinet-referral-create',
    data: () => ({
        referralLandingUrl: config.urls.referralLanding,
    }),
    computed: {
        ...mapState('referral', [
            'createReferrerResult',
        ]),
        user() {
            return this.$store.getters['users/user'];
        },
    },
    methods: {
        apply() {
            this.$store.dispatch('referral/createReferrer');
            // this.$store.dispatch('referral/createReferrer', {
            //     value: this.user.phone,
            // });
        },
    },
    watch: {
        createReferrerResult(newVal) {
            if (newVal) {
                window.location.reload();
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-referral-create {
    flex: 1 1 auto;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    border-radius: @border-radius-lg;
    margin: 24px 0;
    overflow: hidden;

    background-color: @color-gray-lightest;
    &__info {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 40px;
        &--container {
            max-width: 438px;
        }
        &-title {
            .typography-large();

            margin-bottom: 16px;
        }
        &-description {
            .typography-small();

            margin-bottom: 40px;
        }
        &-buttons {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            margin: -12px;
            &-item {
                flex: 0 0 auto;
                width: 207px;
                margin: 12px;
            }
        }
    }
    &__illustration {
        flex: 0 0 calc((100vh - 125px) * 0.6);
        max-width: 50%;

        background-image: url('/static/images/referral/page-cabinet-referral-create-bg.jpg');
        background-position: center top;
        background-size: cover;
    }
    @media @media-md-down {
        &__info {
            &-buttons {
                margin: -8px;
                &-item {
                    margin: 8px;
                }
            }
        }
    }
    @media @media-sm-down {
        width: auto;
        margin: 0 -@container-padding-mobile;
        &__info {
            --overlay: fade(@color-gray-lighter, 90%);
            padding: 24px;

            text-align: center;

            background-image: linear-gradient(0deg, var(--overlay), var(--overlay)), url('/static/images/referral/page-cabinet-referral-create-bg.jpg');
            background-position: center top;
            background-size: cover;
            &-button {
                margin: 0 auto;
            }
        }
        &__illustration {
            display: none;
        }
    }
}
</style>
