<template>
    <div class="plugin-how-it-works-info-intro">
        <ui-card-base v-bind="{
                paddingTop: 0,
                paddingRight: 0,
                paddingBottom: 0,
                paddingLeft: 0,
                withShadow: true,
                breakpoints: { 768: false }
            }">
            <div class="plugin-how-it-works-info-intro--container">
                <div class="plugin-how-it-works-info-intro__title">
                    Создаем Акционерное общество (АО)
                </div>
                <div class="plugin-how-it-works-info-intro__description">
                    SimpleEstate создает отдельное акционерное общество (АО), которое будет владеть объектом недвижимости. Уставный капитал АО составляет 10 000 рублей, а 100% обыкновенных акций принадлежит SimpleEstate.
                    <br />
                    Акционерное общество выступает в роли «юридической прослойки» между объектом недвижимости и инвесторами, которая нужна для того, чтобы:
                </div>
                <div class="plugin-how-it-works-info-intro__scheme">
                    <img class="plugin-how-it-works-info-intro__scheme-image"
                        v-bind:src="'/static/images/plugin-how-it-works-info_0.svg'"
                    />
                </div>
                <ul class="plugin-how-it-works-info-intro__list _left">
                    <li class="plugin-how-it-works-info-intro__list-item"
                        v-for="(item, index) in itemsLeft"
                        v-bind:key="index"
                        >
                        <div class="plugin-how-it-works-info-intro__list-item-title"
                            v-text="item.title"
                        />
                        <div class="plugin-how-it-works-info-intro__list-item-description"
                            v-text="item.description"
                        />
                    </li>
                </ul>
                <ul class="plugin-how-it-works-info-intro__list _right">
                    <li class="plugin-how-it-works-info-intro__list-item"
                        v-for="(item, index) in itemsRight"
                        v-bind:key="index"
                        >
                        <div class="plugin-how-it-works-info-intro__list-item-title"
                            v-text="item.title"
                        />
                        <div class="plugin-how-it-works-info-intro__list-item-description"
                            v-text="item.description"
                        />
                    </li>
                </ul>
            </div>
        </ui-card-base>
    </div>
</template>

<script>
export default {
    name: 'plugin-how-it-works-info-intro',
    data: () => ({
        items: [
            { title: 'Разделить собственность между множеством инвесторов', description: `После покупки объекта его единственным собственником будет АО, а инвесторы будут владеть долей в объекте через долю в АО (доля в АО = доля в объекте).` },
            { title: 'Отделить функцию управления от экономической выгоды', description: `SimpleEstateосуществляет операционное управление объектом, так как является единоличным исполнительным органом АО и владеет 100% его обыкновенных акций. Инвесторы будут владеть привилегированными акциями, которые имеют ограниченный контроль, но дают всю экономическую выгоду (дивиденды и ликвидационная стоимость)` },
            { title: 'Аккумулировать доходы и расходы', description: `АО получает доход от сдачи объекта недвижимости в аренду, а также уплачивает все операционные расходы (эксплуатация, налог на имущество и т.д.), а значит инвесторам не нужно самим следить за арендными платежами или оплачивать расходы, как в случае прямого владения недвижимостью.` },
            { title: 'Иметь возможность продать свою долю', description: `Если инвестор хочет выйти из актива и продать долю в объекте, он реализует свои акции через инвестиционную платформу SimpleEstate. Продажа акций осуществляется дистанционно, без необходимости посещать нотариуса.` },
        ],
    }),
    computed: {
        itemsLeft() {
            return this.items.slice(0, Math.ceil(this.items.length / 2));
        },
        itemsRight() {
            return this.items.slice(Math.ceil(this.items.length / 2), this.items.length);
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-how-it-works-info-intro {
    &--container {
        display: grid;
        grid-template-columns:
            calc(@grid-column + @grid-gap)
            calc(@grid-column * 5 + @grid-gap * 4)
            calc(@grid-column + @grid-gap * 2)
            calc(@grid-column * 4 + @grid-gap * 3)
            calc(@grid-column + @grid-gap);
        grid-template-rows: auto 24px auto 40px;
        width: 100%;
        padding: 60px 0 60px;
    }
    &__title {
        .typography-large();

        grid-column: 2;
        grid-row: 1;
    }
    &__description {
        .typography-small();

        grid-column: 2;
        grid-row: 3;
    }
    &__scheme {
        grid-column: 4;
        grid-row: 3;
        place-self: center;
    }
    &__list {
        padding: 0;
        margin: 0;
        list-style: none;
        &._left {
            grid-column: 2;
            grid-row: 5;
        }
        &._right {
            grid-column: 4;
            grid-row: 5;
        }
        &-item {
            position: relative;
            padding-left: 33px;
            &:before {
                content: '';

                position: absolute;
                top: 0.85em;
                left: 0;

                display: block;
                width: 8px;
                height: 8px;
                border-radius: 50%;

                background-color: currentColor;
            }
            & ~ & {
                margin-top: 24px;
            }
            &-title {
                .typography-small();

                margin-bottom: 8px;
            }
            &-description {
                .typography-body();
            }
        }
    }
    @media @media-md-down {
        padding: 0 @grid-gap;
        &--container {
            grid-template-columns:
                @grid-gap
                calc(@grid-column * 6 + @grid-gap * 5)
                @grid-column
                calc(@grid-column * 5 + @grid-gap * 4)
                @grid-gap;
        }
    }
    @media @media-sm-down {
        &--container {
            grid-template-columns: 0 1fr 0;
            grid-template-rows: auto;
            padding-top: 0;
        }
        &__title {
            grid-column: 2;
            grid-row: 1;
            margin-bottom: 24px;
        }
        &__description {
            grid-column: 2;
            grid-row: 2;
            margin-bottom: 24px;
        }
        &__scheme {
            grid-column: 2;
            grid-row: 5;
            max-width: 312px;
            margin: 0 auto auto;
        }
        &__list {
            margin: 0 0 24px;
            &._left {
                grid-column: 2;
                grid-row: 3;
            }
            &._right {
                grid-column: 2;
                grid-row: 4;
            }
            &-item {
                padding-left: 24px;
            }
        }
    }
}
</style>
