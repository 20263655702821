<template>
    <div class="modal-investment-type">
        <div class="modal-investment-type__title"
            v-text="title"
        />
        <div class="modal-investment-type__subtitle"
            v-text="subtitle"
        />
        <div class="modal-investment-type__options">
            <div class="modal-investment-type__options-item"
                v-for="(type, index) in availableInvestmentTypes"
                v-bind:key="index"
                >
                <modal-investment-type-option
                    v-bind:type="type"
                    v-bind:active-type="activeType"
                    v-on:select="setType"
                />
            </div>
        </div>
        <div class="modal-investment-type__error"
            v-if="error"
            v-text="error"
        />
        <div class="modal-investment-type__buttons">
            <div class="modal-investment-type__buttons-item">
                <ui-button
                    v-bind:disabled="$v.$dirty && $v.$invalid"
                    v-on:click="submit"
                    v-bind:text="'Подтвердить'"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'modal-investment-type',
    props: {
        availableInvestmentTypes: {
            type: Array,
        },
    },
    data: () => ({
        activeType: null,
    }),
    validations() {
        return {
            activeType: {
                isValid: value => value !== null,
            },
        };
    },
    computed: {
        title() {
            return this.availableInvestmentTypes.length > 1
                ? 'Каким способом вы хотите инвестировать?'
                : 'Способ инвестирования';
        },
        subtitle() {
            return this.availableInvestmentTypes.length > 1
                ? 'Тип инвестирования нельзя изменить после подачи заявки'
                : 'Для этого объекта доступен один тип инвестирования';
        },
        error() {
            if (!this.$v.$dirty) {
                return null;
            }
            if (!this.activeType) {
                return 'Необходимо выбрать способ инвестирования';
            }
            return null;
        },
    },
    methods: {
        setType(newVal) {
            this.activeType = newVal;
        },
        submit() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                // return;
            }
            // console.log('submit');
        },
    },
    beforeMount() {
        if (this.availableInvestmentTypes.length === 1) {
            this.activeType = this.availableInvestmentTypes[0];
        }
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-investment-type {
    .modal();

    width: 100%;

    text-align: center;
    &__title {
        .typography-large();

        margin-bottom: 8px;
    }
    &__subtitle {
        .typography-body();

        margin-bottom: 40px;
    }
    &__options {
        display: flex;
        align-items: stretch;
        justify-content: center;
        flex-wrap: wrap;
        padding-bottom: 40px;
        margin: -12px;
        &-item {
            flex: 0 0 auto;
            max-width: 437px;
            width: 50%;
            padding: 12px;
        }
    }
    &__error {
        .typography-body();

        margin-bottom: 40px;

        color: @color-accent-negative;
    }
    &__buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin: -12px;
        &-item {
            flex: 0 0 auto;
            width: 207px;
            padding: 12px;
        }
    }
    @media @media-sm-down {
        &__title {
            .typography-medium();
        }
        &__subtitle {
            .typography-caption();

            margin-bottom: 24px;
        }
        &__options {
            margin: -4px;
            padding-bottom: 24px;
            &-item {
                width: 100%;
                padding: 4px;
            }
        }
        &__buttons {
            margin: -4px;
            &-item {
                width: 100%;
                padding: 4px;
            }
        }
    }
}
</style>
