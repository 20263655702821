<template>
    <ui-input-base-text
        v-bind:formatter="formatDown"
        v-bind:is-invalid="isInvalid"
        v-bind="formattedModel"
        v-bind:type="'text'"
        v-on:input="inputHandler"
        inputmode="numeric"
        pattern="\\d*"
    />
</template>

<script>
import utils from '~/utils';

export default {
    name: 'ui-input-currency',
    props: {
        model: {
            type: Object,
        },
        isInvalid: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [ String, Number ],
        },
    },
    model: {
        prop: 'value',
        event: 'input',
    },
    computed: {
        formattedModel() {
            return { ...this.model, value: this.formatDown(this.model.value) };
        },
    },
    methods: {
        formatDown(value) {
            let result = value.toString ? value.toString() : '';
            if (result.length === 0) {
                return '';
            }
            if (this.model.maxLength) {
                result = result.substring(0, this.model.maxLength);
            }
            result = result.replace(/\./g, (c, i, text) => text.indexOf(c) === i ? c : '');
            let floatPointIndex = result.indexOf('.');
            if (floatPointIndex > -1) {
                result = result.substring(0, floatPointIndex);
            }
            let filtered = result.replace(/[^0-9]/g, '');
            if (this.model.maxValue && parseFloat(filtered) > this.model.maxValue) {
                filtered = this.model.maxValue.toString();
            }
            return filtered ? utils.formats.formatNumberWithSpaces(filtered) : '';
        },
        formatUp(value) {
            const result = value.replace(/[^0-9]/g, '');
            if (this.model.maxValue && parseFloat(result) > this.model.maxValue) {
                return this.model.maxValue.toString();
            }
            return result;
        },
        inputHandler(newValue) {
            this.$emit('input', this.formatUp(newValue));
        },
    },
    mounted() {
        if (this.value?.toString() !== this.formatUp(this.formattedModel.value)) {
            this.$emit('input', this.formatUp(this.formattedModel.value));
        }
    },
};
</script>

<style scoped lang="less">
@import "~theme";
</style>
