import { render, staticRenderFns } from "./cabinet-estates-list-cards-item.vue?vue&type=template&id=80e76af6&scoped=true&"
import script from "./cabinet-estates-list-cards-item.vue?vue&type=script&lang=js&"
export * from "./cabinet-estates-list-cards-item.vue?vue&type=script&lang=js&"
import style0 from "./cabinet-estates-list-cards-item.vue?vue&type=style&index=0&id=80e76af6&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80e76af6",
  null
  
)

export default component.exports