<template>
    <div class="ui-graph">
        <div class="ui-graph__content"
            v-if="isReady"
            >
            <apexchart
                width="100%"
                height="100%"
                v-bind:type="type"
                v-bind:options="options"
                v-bind:series="series"
            />
        </div>
        <div class="ui-graph__loader"
            v-else
            >
            <ui-loader
                v-bind:fixed="50"
                v-bind:centered="true"
            />
        </div>
    </div>
</template>

<script>
import utils from '~/utils';

export default {
    name: 'ui-graph',
    props: {
        type: {
            type: String,
            default: 'bar',
        },
        isStacked: {
            type: Boolean,
            default: false,
        },
        data: {
            type: Array,
            default: () => [ 1, 2, 4, 8, 16, 32, 64, 128 ],
        },
        categories: {
            type: Array,
            default: () => [ 'cat1', 'cat2', 'cat3', 'cat4', 'cat5', 'cat6', 'cat7', 'cat8' ],
        },
        noCurrency: {
            type: Boolean,
            default: true,
        },
    },
    data: function() {
        return {
            isReady: false,
            baseColors: [
                '#7630B0',
                '#27242D',
            ],
            baseGradientToColors: [
                '#7C65E6',
                '#564E64',
            ],
        };
    },
    computed: {
        currency() {
            return this.$store.getters['site/currency'];
        },
        options() {
            const result = {
                chart: {
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 400,
                        animateGradually: {
                            enabled: true,
                            delay: 150,
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 350,
                        },
                    },
                    fontFamily: 'Noah, Helvetica, Arial, sans-serif',
                    stacked: this.isStacked,
                    toolbar: {
                        show: false,
                    },
                },
                colors: this.baseColors,
                dataLabels: {
                    enabled: (this.type === 'bar' && !this.isStacked) || this.type === 'line',
                    offsetY: -30,
                    style: {
                        colors: [ '#27242D' ],
                        fontSize: '16px',
                        fontFamily: 'Noah, Helvetica, Arial, sans-serif',
                        fontWeight: 700,
                    },
                    background: {
                        enabled: true,
                        foreColor: '#27242D',
                        padding: 4,
                        borderRadius: 4,
                        opacity: 1,
                    },
                    formatter: this.formatter,
                },
                fill: {
                    type: 'gradient',
                    colors: this.baseColors,
                    gradient: {
                        type: 'vertical',
                        shadeIntensity: 0,
                        gradientToColors: this.baseGradientToColors,
                        stops: [0, 100],
                    },
                },
                grid: {
                    xaxis: {
                        lines: {
                            show: false,
                        },
                    },
                },
                legend: {
                    horizontalAlign: 'right',
                    offsetY: 0,
                    labels: {
                        colors: '#B9B8C6',
                    },
                },
                markers: {
                    size: 12,
                    radius: 3,
                    colors: [ '#9155EB' ],
                    shape: 'square',
                    strokeWidth: 0,
                },
                plotOptions: {
                    bar: {
                        columnWidth: '33%',
                        borderRadius: this.isStacked ? undefined : 8,
                        borderRadiusApplication: 'end',
                        dataLabels: {
                            position: 'top',
                        },
                    },
                },
                stroke: {
                    show: this.type === 'line',
                    colors: [ '#9155EB' ],
                    width: 4,
                },
                tooltip: {
                    y: {
                        formatter: this.formatter,
                        title: {
                            formatter: x => null,
                        },
                    },
                },
                xaxis: {
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    categories: this.categories,
                    labels: {
                        style: {
                            cssClass: 'ui-graph__bar-xaxis-label',
                        },
                    },
                    tickPlacement: 'between',
                },
                yaxis: {
                    labels: {
                        formatter: this.formatter,
                        style: {
                            cssClass: 'ui-graph__bar-yaxis-label',
                        },
                    },
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    forceNiceScale: true,
                    min: 0,
                    tickAmount: 4,
                },
                responsive: [
                    {
                        breakpoint: 1279,
                        options: {
                            plotOptions: {
                                bar: {
                                    columnWidth: '50%',
                                },
                            },
                        },
                    },
                    {
                        breakpoint: 1023,
                        options: {
                            plotOptions: {
                                bar: {
                                    columnWidth: '66%',
                                },
                            },
                        },
                    },
                    {
                        breakpoint: 767,
                        options: {
                            dataLabels: {
                                offsetY: this.type === 'line' ? -10 : -20,
                                style: {
                                    fontSize: '12px',
                                },
                                background: {
                                    padding: 2,
                                    borderRadius: 2,
                                },
                            },
                            markers: {
                                size: 6,
                                radius: 2,
                            },
                            plotOptions: {
                                bar: {
                                    columnWidth: '75%',
                                    borderRadius: this.isStacked ? undefined : 4,
                                },
                            },
                            stroke: {
                                width: 2,
                            },
                        },
                    },
                ],
            };
            const combinedValues = this.isStacked
                ? this.data.reduce((acc, item) => {
                    if (!acc) {
                        return item.data;
                    } else {
                        return acc.map((value, index) => value + item.data[index]);
                    }
                }, null)
                : this.data;
            const max = Math.max(...combinedValues);
            const min = Math.min(...combinedValues);
            if (max === 0) {
                result.yaxis.max = 1000;
            } else {
                result.yaxis.max = max;
            }
            if (this.type === 'line') {
                result.yaxis.min = min;
                result.dataLabels.offsetY = -10;
                result.fill = {
                    type: 'solid',
                    colors: [ '#9155EB' ],
                };
            }
            return result;
        },
        series() {
            if (this.isStacked) {
                return this.data;
            }
            return [
                {
                    name: 'Значение',
                    data: this.data,
                },
            ];
        },
    },
    mounted() {
        utils.dom.addLoadedCallback(this.activate);
    },
    beforeDestroy() {
        utils.dom.removeLoadedCallback(this.activate);
    },
    methods: {
        activate() {
            this.isReady = true;
        },
        formatter(x) {
            let result = utils.formats.formatNumberWithSpaces(x);
            if (!this.noCurrency) {
                result += ' ' + this.currency.name.short;
            }
            return result;
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-graph {
    .transition-fade();

    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    height: 100%;
    width: 100%;

    &__content {
        flex: 1 1 auto;
        height: 100%;
        width: 100%;
    }
    &__loader {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }
    &__bar {
        &-xaxis {
            &-label {
                fill: @color-gray-main;
            }
        }
        &-yaxis {
            &-label {
                fill: @color-gray-main;
            }
        }
    }
    /deep/ .apexcharts-data-labels rect {
        fill: @color-gray-lightest;
    }
}
</style>
