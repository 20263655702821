<template>
    <div class="ui-loader"
        v-bind:class="{
            _dark: dark,
            _light: light,
            _centered: centered,
            _mini: isMini,
        }"
        v-bind:style="{
            width: fixed ? fixed + 'px' : null,
            paddingTop: fixed ? fixed + 'px' : null,
        }"
    />
</template>

<script>
export default {
    name: 'ui-loader',
    props: {
        dark: {
            type: Boolean,
            default: false,
        },
        light: {
            type: Boolean,
            default: false,
        },
        centered: {
            type: Boolean,
            default: false,
        },
        isMini: {
            type: Boolean,
            default: false,
        },
        fixed: {
            type: Number,
            default: null,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-loader {
    .transition-fade();

    position: relative;

    display: inline-block;
    width: 100%;
    padding-top: 100%;
    color: @color-primary-2;
    &:after {
        content: '';

        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        display: block;
        width: 100%;
        height: 100%;
        border: 4px solid currentColor;
        border-color: currentColor currentColor @color-gray-main @color-gray-main;
        border-radius: 50%;

        animation: loader 1s linear infinite;
    }
    &._dark {
        color: @color-gray-dark;
    }
    &._light {
        &:after {
            border-color: @color-gray-light @color-gray-light @color-primary @color-primary;
        }
    }
    &._centered {
        display: block;
        margin: auto;
    }
    &._mini {
        &:after {
            border: 2px solid currentColor;
            border-color: currentColor currentColor transparent;
        }
    }
}
@keyframes loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
