<template>
    <div class="plugin-referral-offers-item"
         v-bind:class="{ '_full-width': with_image == 'True' }"
    >
        <div class="plugin-referral-offers-item__info">
            <div class="plugin-referral-offers-item__info__title"
                 v-if="title"
                 v-html="title"
            />
            <div class="plugin-referral-offers-item__info__description"
                 v-if="description"
                 v-html="description"
            />
        </div>
        <div class="plugin-referral-offers-item__image__container"
             v-if="with_image"
        >
            <img class="plugin-referral-offers-item__image"
                 src="/static/images/referral/offers/dashboard.png"
                 alt="dashboard"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'plugin-referral-offers-item',
    props: {
        title: {
            type: String,
            default: null,
        },
        description: {
            type: String,
            default: null,
        },
        with_image: {
            type: String,
            default: null,
        },
    },
};
</script>

<style lang="less">
@import '~theme';

.plugin-referral-offers-item {
    background: rgba(249, 249, 252, 0.1);
    border-radius: 20px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    padding: 40px 40px 56px;

    &__info {
        &__title {
            .typography-extra-large();

            color: @color-gray-lightest;

            margin-bottom: 30px;
        }

        &__description {
            .typography-small();

            color: rgba(255, 255, 255, 0.6);

            width: 100%;

            p {
                margin: 0;
            }
        }
    }

    &:first-child {
        background: @color-gray-light;

        grid-row: 1;
        grid-column: 1;

        & .plugin-referral-offers-item__info {

            &__title {
                color: @color-gray-darker;
            }

            &__description {
                color: rgba(39, 36, 45, 0.6);
            }
        }
    }

    &:nth-child(2) {
        grid-row: 2;
        grid-column: 1;
    }

    &:nth-child(3) {
        padding-bottom: 30px;

        position: relative;
        align-items: center;
        justify-content: space-between;

        grid-row: 1 / span 2;
        grid-column: 2;
    }

    &__image {
        max-height: 420px;

        &__container {
            margin-top: 30px;
            max-width: 100%;
            max-height: 450px;
        }
    }

    @media @media-lg-down {
        &__info {
            &__title {
                .typography-landing-small-heading();

                margin-bottom: 24px;
            }
        }
    }

    @media @media-md-down {
        &:nth-child(2) {
            grid-row: 3;
            grid-column: 1;
        }

        &:nth-child(3) {
            grid-row: 2;
            grid-column: 1;
        }
    }

    @media @media-sm-down {
        padding: 32px 24px;

        &__info {
            &__title {
                .typography-large();
            }
        }
    }
}
</style>
