import moment from 'moment';

export const migrationNoVisaRequired = {
    name: 'migrationNoVisaRequired',
    label: 'Имею право находиться на территории РФ без визы',
    type: 'checkbox',
    value: true,
};

export const migrationInfoMode = {
    name: 'migrationInfoMode',
    label: 'Выберите тип миграционного документа',
    value: null,
    initialValue: null,
    type: 'radio-group',
    options: [
        { value: 'card', text: 'Миграционная карта' },
        { value: 'other', text: 'Другой документ' },
    ],
    errors: {
        required: 'Выберите тип данных для заполнения',
    },
};

export const migrationCardNumber = {
    name: 'migrationCardNumber',
    label: 'Номер миграционной карты',
    value: '',
    initialValue: '',
    type: 'digital',
    errors: {
        required: 'Введите номер миграционной карты',
    },
};

export const migrationCardDateStart = {
    name: 'migrationCardDateStart',
    label: 'Дата начала срока пребывания в РФ',
    value: '',
    initialValue: '',
    type: 'date',
    errors: {
        required: 'Введите дату начала срока пребывания в РФ',
        isValid: 'Введите корректную дату в формате ДД.ММ.ГГГГ',
        isBeforeEnd: 'Дата начала действия должна быть раньше даты окончания',
    },
};

export const migrationCardDateEnd = {
    name: 'migrationCardDateEnd',
    label: 'Дата окончания срока пребывания в РФ',
    value: '',
    initialValue: '',
    type: 'date',
    errors: {
        required: 'Введите дату окончания срока пребывания в РФ',
        isValid: 'Введите корректную дату в формате ДД.ММ.ГГГГ',
        isAfterNow: () => `Дата окончания действия должна быть позже сегодняшней даты (${moment().format('DD.MM.YYYY')})`,
    },
};

export const migrationDocumentNumber = {
    name: 'migrationDocumentNumber',
    label: 'Серия (при наличии) и номер',
    value: '',
    initialValue: '',
    type: 'digital',
    errors: {
        required: 'Введите идентификатор документа',
    },
};

export const migrationDocumentDateStart = {
    name: 'migrationDocumentDateStart',
    label: 'Дата начала срока действия права пребывания (проживания)',
    value: '',
    initialValue: '',
    type: 'date',
    errors: {
        required: 'Введите дату начала действия документа',
        isValid: 'Введите корректную дату в формате ДД.ММ.ГГГГ',
        isBeforeEnd: 'Дата начала действия должна быть раньше даты окончания',
        isBeforeOrNow: () => `Дата начала действия должна быть не позже сегодняшней даты (${moment().format('DD.MM.YYYY')})`,
    },
};

export const migrationDocumentDateEnd = {
    name: 'migrationDocumentDateEnd',
    label: 'Дата окончания срока действия права пребывания (проживания)',
    value: '',
    initialValue: '',
    type: 'date',
    errors: {
        required: 'Введите дату окончания действия документа',
        isValid: 'Введите корректную дату в формате ДД.ММ.ГГГГ',
        isAfterNow: () => `Дата окончания действия должна быть позже сегодняшней даты (${moment().format('DD.MM.YYYY')})`,
    },
};
