<template>
    <article class="page-cabinet-estates-list-table-item-row">
        <div class="page-cabinet-estates-list-table-item-row__field"
            v-for="(field, i) in fields"
            v-bind:key="i"
            v-text="field.title === 'Текущая Стоимость' ? item.currentValueFormatted : item[field.valueCode]"
        />
        <!-- v-bind:style="{ flexBasis: field.width + 'px' }" -->
        <!-- <div class="page-cabinet-estates-list-table-item-row__additional">
            <div class="page-cabinet-estates-list-table-item-row__additional-stats">
                <div class="page-cabinet-estates-list-table-item-row__additional-stats-item"
                    v-for="(stat, i) in stats"
                    v-bind:key="i"
                    >
                    <div class="page-cabinet-estates-list-table-item-row__additional-stats-item-title"
                        v-text="stat.title + ':'"
                    />
                    <div class="page-cabinet-estates-list-table-item-row__additional-stats-item-value"
                        v-text="stat.value"
                    />
                    <div class="page-cabinet-estates-list-table-item-row__additional-stats-item-delta"
                        v-if="stat.delta"
                        v-bind:class="{ _negative: stat.delta < 0 }"
                        v-text="stat.delta + '%'"
                    />
                </div>
            </div>
            <div class="page-cabinet-estates-list-table-item-row__additional-actions"
                v-if="isSaleAvailable"
                >
                <div class="page-cabinet-estates-list-table-item-row__additional-actions-sale">
                    <ui-button
                        v-on:click="saleHandler"
                        text="Выставить на продажу"
                        theme="secondary-1"
                    />
                </div>
            </div>
        </div> -->
    </article>
</template>

<script>
import utils from '~/utils';

export default {
    name: 'page-cabinet-estates-list-table-item-row',
    props: {
        item: {
            type: Object,
        },
        fields: {
            type: Array,
        },
    },
    computed: {
        stats() {
            const result = [
                {
                    title: 'Кол-во акций',
                    value: utils.formats.formatNumberWithSpaces(this.item.quantity),
                    delta: null,
                },
                {
                    title: 'Рыночная стоимость акции',
                    value: utils.formats.formatNumberWithSpaces(this.item.price),
                    delta: null,
                },
            ];
            if (this.item.dividends > 0) {
                result.push({
                    title: 'Выплачено дивидендов',
                    value: utils.formats.formatNumberWithSpaces(this.item.dividends),
                    delta: null,
                });
            }
            return result;
        },
        isSaleAvailable() {
            return this.item.quantity > 0;
        },
    },
    methods: {
        saleHandler() {
            this.$store.commit('modals/push', {
                name: 'market-application-create',
                props: {
                    givenShareId: this.item.id,
                },
            });
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-estates-list-table-item-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    text-align: right;
    &__field {
        flex: 1 1 100%;

        &:not(:last-child) {
            padding-right: 16px;
        }
    }
    @media @media-md-down {
    }
}
</style>
