<template>
    <div class="ui-infolist">
        <div class="ui-infolist__item"
            v-for="(item, i) in info"
            v-bind:key="i"
            >
            <ui-infolist-item v-bind:item="item" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ui-infolist',
    props: {
        info: {
            type: Array,
            default: null,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-infolist {
    margin: -8px 0;
    &__item {
        padding: 8px 0;
    }
}
</style>
