<template>
    <article class="page-cabinet-dashboard-income">
        <div class="page-cabinet-dashboard-income__headline">
            <div class="page-cabinet-dashboard-income__headline-switchers">
                <div class="page-cabinet-dashboard-income__headline-switchers-item"
                    v-on:click="setMode('percent')"
                    v-bind:class="{ _active: mode === 'percent' }"
                    v-text="'Доход в %'"
                />
                <div class="page-cabinet-dashboard-income__headline-switchers-item"
                    v-on:click="setMode('absolute')"
                    v-bind:class="{ _active: mode === 'absolute' }"
                    v-text="'Доход в ₽'"
                />
            </div>
            <div class="page-cabinet-dashboard-income__headline-title"
                v-for="(column, index) in columns"
                v-bind:key="index"
                v-text="column.title"
            />
        </div>
        <div class="page-cabinet-dashboard-income__data-wrapper">
            <ui-card-base with-border>
                <template v-if="data">
                    <div class="page-cabinet-dashboard-income__data">
                        <div class="page-cabinet-dashboard-income__data-group">
                            <page-cabinet-dashboard-income-info
                                v-bind:data="data[mode].dividends"
                                v-bind:columns="columns"
                                v-bind:title="'Дивиденды'"
                                v-bind:mode="mode"
                            />
                        </div>
                        <div class="page-cabinet-dashboard-income__data-group">
                            <page-cabinet-dashboard-income-info
                                v-bind:data="data[mode].portfolio"
                                v-bind:columns="columns"
                                v-bind:title="'Рост стоимости'"
                                v-bind:mode="mode"
                            />
                        </div>
                        <div class="page-cabinet-dashboard-income__data-group">
                            <page-cabinet-dashboard-income-info
                                v-bind:data="totals[mode]"
                                v-bind:columns="columns"
                                v-bind:title="'Итого'"
                                v-bind:mode="mode"
                                theme="primary"
                            />
                        </div>
                    </div>
                </template>
                <template v-else>
                    <ui-loader
                        v-bind:fixed="50"
                        v-bind:centered="true"
                    />
                </template>
            </ui-card-base>
        </div>
    </article>
</template>

<script>
import { mainClient } from '~/network';

export default {
    name: 'page-cabinet-dashboard-income',
    data: () => ({
        mode: 'percent',
        data: null,
        columns: [
            {
                title: `${new Date().getFullYear()} YTD`,
                key: 'year',
            },
            {
                title: 'Все время',
                key: 'all',
            },
        ],
    }),
    mounted() {
        this.getData();
    },
    computed: {
        totals() {
            if (!this.data) {
                return {};
            }
            return {
                absolute: {
                    year: this.data.absolute.dividends.year + this.data.absolute.portfolio.year,
                    all: this.data.absolute.dividends.all + this.data.absolute.portfolio.all,
                },
                percent: {
                    year: this.data.percent.dividends.year + this.data.percent.portfolio.year,
                    all: this.data.percent.dividends.all + this.data.percent.portfolio.all,
                },
            };
        },
    },
    methods: {
        async getData() {
            const { data, error, status } = await mainClient.users.requests.getDashboardIncome();
            if (error) {
                console.error(error, status);
            } else {
                this.data = data;
            }
        },
        setMode(mode) {
            this.mode = mode;
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-dashboard-income {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin-bottom: 40px;
    &__headline {
        flex: 0 0 auto;
        display: flex;
        align-items: baseline;
        justify-content: flex-start;
        width: 100%;
        padding: 0 16px 11px;
        &-switchers {
            .typography-heading();

            flex: 0 0 auto;
            display: flex;
            align-items: baseline;
            justify-content: flex-start;
            width: 50%;
            &-item {
                color: @color-gray-main;

                cursor: pointer;

                transition: color @duration-fast ease-in-out;
                &._active {
                    color: @color-gray-darker;
                }
                & ~ & {
                    margin-left: 12px;
                }
            }
        }
        &-title {
            .typography-body();

            flex: 0 0 auto;
            width: 25%;

            color: @color-gray-main;
        }
    }
    &__data {
        &-wrapper {
            flex: 1 1 auto;
        }
        &-group {
            & ~ & {
                margin-top: 14px;
            }
        }
    }
    @media @media-md-down {
        &__headline {
            &-switchers {
                width: 100%;
            }
            &-title {
                display: none;
            }
        }
        &__data {
            &-group {
                & ~ & {
                    padding-top: 12px;
                    border-top: 1px solid fade(@color-gray-darkest, 5%);
                    margin-top: 12px;
                }
            }
        }
    }
}
</style>
