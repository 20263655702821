<template>
    <div class="plugin-blog-text">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'plugin-blog-text',
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-blog-text {
    display: block;
    width: 100%;

    & h2 {
        .typography-large();

        margin: 40px 0 24px;
    }
    & h3 {
        .typography-medium();

        margin: 24px 0 16px;
    }
    & p {
        .typography-small();

        margin: 40px 0;
    }
    & h3 + p {
        margin-top: 0;
    }
    & ul {
        .typography-small();

        padding: 0;
        margin: 24px 0;

        list-style: none;
        & > li {
            position: relative;

            padding-left: 56px;
            &:before {
                content: '';

                position: absolute;
                top: 10px;
                left: 24px;

                display: block;
                width: 8px;
                height: 8px;
                border-radius: 50%;

                background-color: @color-primary-2;
            }
        }
        & > li ~ li {
            margin-top: 24px;
        }
    }
    & a {
        color: @color-primary-2;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    @media @media-md-down {
        & h2 {
            margin: 40px 0 16px;
        }
        & h3 {
            margin: 16px 0 12px;
        }
        & p {
            margin: 24px 0;
        }
        & ul {
            margin: 16px 0 24px;
            & > li {
                padding-left: 40px;
                &:before {
                    left: 8px;
                }
            }
            & > li ~ li {
                margin-top: 12px;
            }
        }
    }
}
</style>
