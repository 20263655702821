<template>
    <div class="ui-infolist-item">
        <div class="ui-infolist-item__title">
            <div class="ui-infolist-item__title-text"
                v-text="item.title"
            />
            <div class="ui-infolist-item__title-hint"
                v-if="item.hint"
                >
                <ui-hint
                    v-bind:text="item.hint"
                />
            </div>
        </div>
        <div class="ui-infolist-item__value">
            <div class="ui-infolist-item__value-icon"
                v-if="icon"
                >
                <div class="ui-infolist-item__value-icon-loader"
                    v-if="isLoading"
                    >
                    <ui-loader is-mini />
                </div>
                <div class="ui-infolist-item__value-icon-content"
                    v-else
                    v-bind:class="{ _interactable: action }"
                    v-bind:title="item.isCopyAllowed ? 'Копировать' : null"
                    v-on:click="action"
                    >
                    <ui-icon v-bind:icon="icon" />
                </div>
            </div>
            <div class="ui-infolist-item__value-text"
                v-bind:class="{ _accented: item.isAccented }"
                v-text="item.value"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ui-infolist-item',
    props: {
        item: {
            type: Object,
        },
    },
    data: () => ({
        isCopyInProgress: false,
    }),
    computed: {
        action() {
            if (this.item.isCopyAllowed) {
                return this.copy;
            }
            return null;
        },
        icon() {
            if (this.item.isCopyAllowed) {
                return 'copy';
            }
            return null;
        },
        isLoading() {
            return this.item.isCopyAllowed && this.isCopyInProgress;
        },
    },
    methods: {
        copy() {
            this.isCopyInProgress = true;
            navigator.clipboard.writeText(this.item.value)
                .then(() => {
                    setTimeout(() => {
                        this.isCopyInProgress = false;
                    }, 500);
                });
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-infolist-item {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    &__title {
        flex: 0 0 auto;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 138px;
        padding-right: 16px;
        &-text {
            .typography-caption();

            font-weight: bold;
            text-transform: uppercase;
        }
        &-hint {
            flex: 0 0 auto;
            margin-left: 4px;
        }
    }
    &__value {
        .typography-body();

        flex: 1 1 auto;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        &-icon {
            flex: 0 0 auto;
            margin-right: 8px;

            transform: translateY(2px);
            &-content {
                color: @color-gray-main;
                font-size: 1.6rem;

                transform: translateY(2px);
                &._interactable {
                    cursor: pointer;
                    &:hover {
                        color: @color-primary;
                    }
                }
            }
            &-loader {
                flex: 0 0 auto;
                width: 1.6rem;
                height: 1.6rem;
            }
        }
        &-text {
            flex: 1 1 auto;
            max-width: 480px;
            &._accented {
                .typography-heading();
            }
        }
    }
    @media @media-md-down {
        flex-wrap: wrap;
        &__title {
            width: 114px;
            margin-bottom: 4px;
        }
    }
}
</style>
