import client from '../_client';
import config from '~/config';
import utils from '~/utils';
import transforms from './transforms';

export const getFormData = ({ id, ...params }) => {
    return client.request({
        url: config.api.status.getFormData.replace('{id}', id),
        method: 'GET',
        params,
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        transformData: transforms.responses.getFormData,
    });
};

export const createFormData = ({ id, ...params }) => {
    return client.request({
        url: config.api.status.createFormData,
        method: 'POST',
        data: { estate: id },
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        transformData: transforms.responses.createFormData,
    });
};

export const updateFormData = ({ id, formData, ...rest }) => {
    return client.request({
        url: config.api.status.updateFormData.replace('{id}', id),
        method: 'PUT',
        data: formData,
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        transformData: transforms.responses.createFormData,
    });
};

export const uploadFormFile = ({ formData, ...rest }) => {
    return client.request({
        url: config.api.status.uploadFile,
        method: 'POST',
        data: formData,
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        // transformData: transforms.responses.createFormData,
    });
};
