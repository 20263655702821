<template>
    <div class="page-admin-estates-item-info-document">
        <ui-form
            v-bind:model="selectedFields"
            v-bind:submit-handler="submitHandler"
            v-bind:validation="validation"
            v-on:update="updateFormData"
            gap="wide"
        />
    </div>
</template>

<script>
export default {
    name: 'page-admin-estates-item-info-document',
    props: [ 'fields', 'validation' ],
    computed: {
        selectedFields() {
            return {
                documentTitle: this.fields.documentTitle,
                document: this.fields.document,
            };
        },
    },
    methods: {
        submitHandler() {
            this.$emit('submit');
        },
        updateFormData(newData) {
            this.$emit('update', newData);
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-admin-estates-item-info-document {
}
</style>
