<template>
    <div class="page-cabinet-verification-profile-photos">
        <div class="page-cabinet-verification-profile-photos__headline">
            <div class="page-cabinet-verification-profile-photos__headline-title">
                Паспорт{{ legalType === 'J' ? ' ген. директора' : '' }}
            </div>
            <div class="page-cabinet-verification-profile-photos__headline-hint">
                <ui-hint text="Загрузите 2, 3 и 5 страницы паспорта в формате jpg, png или pdf" />
            </div>
        </div>
        <div class="page-cabinet-verification-profile-photos__description">
            Загрузите сканы или фотографии двух разворотов паспорта:
        </div>
        <div class="page-cabinet-verification-profile-photos__loaders">
            <div class="page-cabinet-verification-profile-photos__loaders-item">
                <div class="page-cabinet-verification-profile-photos__loaders-item-title">
                    Разворот с фотографией и ФИО
                </div>
                <common-photo-loader
                    title="Изображение разворота с фотографией"
                    v-bind:text="'Изображение разворота с фотографией'"
                    v-bind:is-loading="photoPassportFirstIsLoading"
                    v-bind:file="photoPassportFirst"
                    v-bind:given-error="photoPassportFirstError"
                    v-on:change="updatePhoto($event, 'photoPassportFirst')"
                />
            </div>
            <div class="page-cabinet-verification-profile-photos__loaders-item">
                <div class="page-cabinet-verification-profile-photos__loaders-item-title">
                    Разворот с действующей пропиской
                </div>
                <common-photo-loader
                    title="Изображение разворота с действующей пропиской"
                    v-bind:text="'Изображение разворота с действующей пропиской'"
                    v-bind:is-loading="photoPassportRegistrationIsLoading"
                    v-bind:file="photoPassportRegistration"
                    v-bind:given-error="photoPassportRegistrationError"
                    v-on:change="updatePhoto($event, 'photoPassportRegistration')"
                />
            </div>
        </div>
        <div class="page-cabinet-verification-profile-photos__description">
            *Загружайте качественный исходный документ
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'page-cabinet-verification-profile-photos',
    props: {
        isTouched: {
            type: Boolean,
        },
    },
    data: () => ({
        photoPassportFirstIsLoading: false,
        photoPassportRegistrationIsLoading: false,
    }),
    computed: {
        ...mapState('verification/type', [
            'legalType',
        ]),
        ...mapState('users', [
            'getUserProfileResult',
            'getUserProfileIsLoading',

            'getUserPhotosResult',
            'getUserPhotosIsLoading',
        ]),
        user() {
            return this.$store.getters['users/user'];
        },
        photoPassportFirst() {
            return this.getUserPhotosResult?.photoPassportFirst;
        },
        photoPassportRegistration() {
            return this.getUserPhotosResult?.photoPassportRegistration;
        },
        isValid() {
            return this.photoPassportRegistration && this.photoPassportFirst;
        },
        photoPassportFirstError() {
            if (this.isTouched && !this.photoPassportFirst) {
                return 'Загрузите фото';
            }
            return null;
        },
        photoPassportRegistrationError() {
            if (this.isTouched && !this.photoPassportRegistration) {
                return 'Загрузите фото';
            }
            return null;
        },
    },
    methods: {
        updatePhoto(file, type) {
            if (file !== null) {
                const formData = new FormData();
                formData.append('file', file);
                this[type + 'IsLoading'] = true;
                this.$store.dispatch('users/updateUserPhoto', {
                    userId: this.user.id,
                    type,
                    formData,
                    isBackground: true,
                    onSuccess: () => {
                        this.$store.dispatch('users/getUserPhotos', {
                            userId: this.user.id,
                            onSuccess: () => {
                                this[type + 'IsLoading'] = false;
                            },
                        });
                    },
                });
            }
        },
    },
    watch: {
        isValid: {
            handler(newVal) {
                this.$emit('validation-update', { entityName: 'isPhotosDataValid', value: !!newVal });
            },
            immediate: true,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-verification-profile-photos {
    &__headline {
        display: flex;
        margin-bottom: 16px;

        align-items: center;
        &-title {
            .typography-heading();

            flex: 0 1 auto;
        }
        &-hint {
            flex: 0 0 auto;
            margin-top: -2px;
            margin-left: 4px;
        }
    }
    &__description {
        .typography-body();

        margin-bottom: 20px;
    }
    &__loaders + &__description {
        margin-top: 16px;
    }
    &__loaders {
        &-item {
            &-title {
                .typography-body();

                margin-bottom: 4px;
            }
            & ~ & {
                margin-top: 16px;
            }
        }
    }
}
</style>
