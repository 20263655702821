<template>
    <article class="page-cabinet-dashboard-welcome">
        <div class="page-cabinet-dashboard-welcome__title">
            Поздравляем с регистрацией!
        </div>
        <div class="page-cabinet-dashboard-welcome__subtitle">
            Чтобы начать инвестировать - выберите объект или пройдите верификацию
        </div>
        <div class="page-cabinet-dashboard-welcome__buttons">
            <a class="page-cabinet-dashboard-welcome__buttons-item"
                v-bind:href="urls.verification"
                >
                <ui-button theme="primary">
                    Верификация
                </ui-button>
            </a>
            <a class="page-cabinet-dashboard-welcome__buttons-item"
                v-bind:href="urls.estates"
                >
                <ui-button theme="secondary-1">
                    выбрать объект
                </ui-button>
            </a>
        </div>
    </article>
</template>

<script>
import config from '~/config';

export default {
    name: 'page-cabinet-dashboard-welcome',
    data: () => ({
        urls: {
            verification: config.urls.cabinet.verification,
            estates: config.urls.estates.list,
        },
    }),
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-dashboard-welcome {
    margin-top: 60px;
    margin-bottom: 40px;

    text-align: center;
    &__title {
        .typography-large();

        max-width: 434px;
        width: 100%;
        margin: 0 auto 16px;
    }
    &__subtitle {
        .typography-body();

        max-width: 434px;
        width: 100%;
        margin: 0 auto 24px;
    }
    &__buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: -12px;
        &-item {
            flex: 0 0 auto;
            display: block;
            min-width: 230px;
            padding: 12px;

            text-decoration: none;
        }
    }
    @media @media-md-down {
        margin-top: 20px;
        &__title {
            .typography-heading();

            margin-bottom: 12px;
        }
        &__subtitle {
            .typography-body();

            margin-bottom: 20px;
        }
        &__buttons {
            margin: -4px;
            &-item {
                flex: 1 1 auto;
                padding: 4px;
            }
        }
    }
}
</style>
