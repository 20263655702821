import client from '../_client';
import config from '~/config';
import transforms from './transforms';
import utils from '~/utils';

export const getAllShares = (params = {}) => {
    return client.request({
        url: config.api.market.shares.all,
        method: 'GET',
        params,
        transformData: transforms.responses.getAllShares,
    });
};

export const getActiveShares = (params = {}) => {
    return client.request({
        url: config.api.market.shares.list,
        method: 'GET',
        params,
        transformData: transforms.responses.getActiveShares,
    });
};

export const getShareById = (params = {}) => {
    const id = params.id;
    const paramsFiltered = { ...params };
    delete paramsFiltered.id;
    return client.request({
        url: `${config.api.market.shares.all}${id}/`,
        method: 'GET',
        params: paramsFiltered,
        transformData: transforms.responses.getAllSharesItem,
    });
};

export const getOrders = (params = {}) => {
    return client.request({
        url: config.api.market.orders.list,
        method: 'GET',
        params,
        transformData: transforms.responses.ordersList,
    });
};

export const editOrder = (data = {}) => {
    const id = data.id;
    delete data.id;
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.market.orders.list + id + '/',
        method: 'PATCH',
        data,
        // transformData: transforms.responses.ordersListItem,
    });
};

export const createOrder = (data = {}) => {
    return client.request({
        url: config.api.market.orders.create,
        method: 'POST',
        data,
        // data: transforms.requests.createOrder(data),
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
    });
};

export const acceptOrder = (data) => {
    return client.request({
        url: config.api.market.orders.accept.replace('{id}', data.id),
        method: 'POST',
        data,
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        // transformData: transforms.responses.ordersList,
    });
};

export const signOrder = (data = {}) => {
    const id = data.id;
    delete data.id;
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.market.orders.sign.replace('{id}', id),
        method: 'POST',
        data,
    });
};

export const cancelOrder = (data = {}) => {
    const id = data.id;
    delete data.id;
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.market.orders.cancel.replace('{id}', id),
        method: 'POST',
        data,
        // transformData: transforms.responses.dealsListItem,
    });
};

export const getDeals = (params = {}) => {
    return client.request({
        url: config.api.market.deals.list,
        method: 'GET',
        params,
        transformData: transforms.responses.dealsList,
    });
};

export const getDeal = (params = {}) => {
    const id = params.id;
    delete params.id;
    return client.request({
        url: config.api.market.deals.list + id + '/',
        method: 'GET',
        params,
        transformData: transforms.responses.dealsListItem,
    });
};

export const getSubscriptionsItem = ({ id, ...params } = {}) => {
    return client.request({
        url: config.api.market.subscriptions + id + '/',
        method: 'GET',
        params,
        transformData: transforms.responses.subscriptionsItem,
    });
};

export const updateSubscriptionsItem = (data) => {
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.market.subscriptions + data.id + '/',
        method: 'PUT',
        data: transforms.requests.updateSubscriptionsItem(data),
        // transformData: transforms.responses.dealsListItem,
    });
};
