<template>
    <ui-card-base>
        <article class="page-cabinet-requests-list-new-item">
            <div class="page-cabinet-requests-list-new-item__info">
                <div class="page-cabinet-requests-list-new-item__info-image">
                    <common-image
                        v-bind:image="image"
                        v-bind:href="item.url"
                    />
                </div>
                <div class="page-cabinet-requests-list-new-item__info-content">
                    <div class="page-cabinet-requests-list-new-item__info-content-status">
                        <ui-label
                            v-bind:theme="labelTheme"
                            v-text="item.statusFormatted"
                            is-uppercase
                        />
                    </div>
                    <div class="page-cabinet-requests-list-new-item__info-content-title"
                        v-text="item.title"
                    />
                    <div class="page-cabinet-requests-list-new-item__info-content-amount">
                        <span class="page-cabinet-requests-list-new-item__info-content-amount-title"
                            v-text="'Сумма:'"
                        /> <span class="page-cabinet-requests-list-new-item__info-content-amount-value"
                            v-text="amount"
                        />
                    </div>
                </div>
            </div>
            <div class="page-cabinet-requests-list-new-item__action"
                v-if="isStatusFormRequired"
                >
                <ui-button
                    v-on:click="openStatusModal"
                    theme="primary"
                    >
                    STATUS_FORM_ACTION
                </ui-button>
            </div>
            <div class="page-cabinet-requests-list-new-item__action"
                v-else-if="item.status === 'wait_sign'"
                >
                <ui-button
                    v-bind:href="signUrl"
                    theme="secondary-3"
                    >
                    Подписать документы
                </ui-button>
            </div>
            <div class="page-cabinet-requests-list-new-item__progress"
                v-else
                >
                <div class="page-cabinet-requests-list-new-item__progress-title"
                    v-text="'Собрано средств'"
                />
                <div class="page-cabinet-requests-list-new-item__progress-values">
                    <span class="page-cabinet-requests-list-new-item__progress-values-number"
                        v-text="current"
                    /> из <span class="page-cabinet-requests-list-new-item__progress-values-number"
                        v-text="target"
                    /> <span class="page-cabinet-requests-list-new-item__progress-values-currency"
                        v-text="'/' + currency"
                    />
                </div>
                <div class="page-cabinet-requests-list-new-item__progress-bar">
                    <ui-progress v-bind:value="progress" theme="dark" />
                </div>
            </div>
        </article>
    </ui-card-base>
</template>

<script>
import config from '~/config';
import utils from '~/utils';

export default {
    name: 'page-cabinet-requests-list-new-item',
    props: {
        item: {
            type: Object,
        },
    },
    computed: {
        isStatusFormRequired() {
            return this.item.isStatusFormRequired;
        },
        labelTheme() {
            if (this.isStatusFormRequired) {
                return 'warning';
            }
            if (this.item.status === 'wait_sign' || this.item.status === 'signed') {
                return 'positive';
            }
            return 'inversed';
        },
        image() {
            return utils.common.injectResize(this.item.image, '140x140');
        },
        signUrl() {
            return `${config.urls.cabinet.signRequest}${this.item.id}/`;
        },
        amount() {
            return `${utils.formats.formatNumberWithSpaces(this.item.amount)} ${this.currency}`;
        },
        current() {
            return utils.formats.formatNumberWithSpaces(Math.min(this.item.current, this.item.target));
        },
        target() {
            return utils.formats.formatNumberWithSpaces(this.item.target);
        },
        currency() {
            return this.$store.getters['site/currency'].name.short;
        },
        progress() {
            if (!this.item.target || this.item.target <= 0) {
                return 1;
            }
            const value = this.item.current / this.item.target;
            return Math.max(Math.min(value, 1), 0);
        },
    },
    methods: {
        openStatusModal() {
            this.$store.commit('modals/push', {
                name: 'status-form',
                props: {
                    estate: this.item.estate,
                },
            });
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-requests-list-new-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 8px;
    &__info {
        flex: 0 1 auto;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding-right: 24px;
        &-image {
            flex: 0 0 auto;
            width: 70px;
            height: 70px;
            margin-right: 24px;
        }
        &-content {
            flex: 0 1 auto;
            &-status {
                width: fit-content;
                margin-bottom: 4px;
            }
            &-title {
                .typography-heading();

                max-width: 650px;
            }
            &-amount {
                .typography-body();
                &-title {
                    color: @color-gray-main;
                }
                &-value {
                }
            }
        }
    }
    &__progress {
        flex: 0 0 auto;
        min-width: 193px;
        &-title {
            .typography-body();

            display: none;

            color: @color-gray-main;
        }
        &-values {
            .typography-caption();

            margin-bottom: 8px;
            &-number {
                .typography-body();
            }
            &-currency {
                color: @color-gray-main;
            }
        }
        &-bar {
            width: 100%;
        }
    }
    @media @media-sm-down {
        display: block;
        padding: 0 0 8px;
        &__info {
            padding-right: 0;
            margin-bottom: 26px;
            &-image {
                width: 48px;
                height: 48px;
                margin-right: 16px;
            }
            &-content {
                width: calc(100% - 76px);
            }
        }
        &__progress {
            &-title {
                display: block;
            }
        }
    }
}
</style>
