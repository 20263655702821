<template>
    <div class="modal-prepare-invest-offer-price">
        <div class="modal-prepare-invest-offer-price__row">
            <div class="modal-prepare-invest-offer-price__form">
                <div class="modal-prepare-invest-offer-price__form-title"
                    v-text="'Стоимость и количество'"
                />
                <ui-form
                    v-bind:model="fields"
                    v-bind:validation="$v"
                    v-bind:submit-handler="submitHandler"
                    v-on:update="updateFormData"
                />
            </div>
            <div class="modal-prepare-invest-offer-price__stats"
                v-if="calculateSharesResult"
                >
                <div class="modal-prepare-invest-offer-price__stats-item"
                    v-for="(item, index) in infoItems"
                    v-bind:key="index"
                    >
                    <div class="modal-prepare-invest-offer-price__stats-item-title"
                        v-text="item.title"
                    />
                    <div class="modal-prepare-invest-offer-price__stats-item-value"
                        v-text="item.value"
                    />
                </div>
            </div>
        </div>
        <div class="modal-prepare-invest-offer-price__buttons"
            v-if="!calculateSharesResult"
            >
            <div class="modal-prepare-invest-offer-price__buttons-item"
                >
                <ui-button
                    v-bind:disabled="($v.$dirty && $v.$invalid) || isLoading"
                    v-on:click="submitHandler"
                    theme="primary"
                    >
                    Продолжить
                </ui-button>
            </div>
        </div>
        <template v-else-if="isRecalculationRequired">
            <div class="modal-prepare-invest-offer-price__warning"
                v-text="'Вы изменили рассчетную стоимость акций. Необходимо пересчитать результат выпуска.'"
            />
            <div class="modal-prepare-invest-offer-price__buttons">
                <div class="modal-prepare-invest-offer-price__buttons-item">
                    <ui-button
                        v-bind:disabled="($v.$dirty && $v.$invalid) || isLoading"
                        v-on:click="cancelChanges"
                        theme="secondary-1"
                        >
                        Отменить
                    </ui-button>
                </div>
                <div class="modal-prepare-invest-offer-price__buttons-item">
                    <ui-button
                        v-bind:disabled="($v.$dirty && $v.$invalid) || isLoading"
                        v-on:click="submitHandler"
                        theme="primary"
                        >
                        Пересчитать
                    </ui-button>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import forms from '~/forms';
import utils from '~/utils';

export default {
    name: 'modal-prepare-invest-offer-price',
    props: {
        estate: {
            type: Object,
        },
    },
    data: () => ({
        fields: {
            price: { ...forms.fields.admin.shares.sharePriceCalculation, isAutofocus: true },
        },
        lastValue: null,
    }),
    validations: {
        fields: {
            price: { ...forms.validations.genericRequired },
        },
    },
    computed: {
        ...mapState('admin/investments', [
            'generateInvestmentOfferDocumentsIsLoading',

            'calculateSharesResult',
            'calculateSharesIsLoading',
            'calculateSharesError',
        ]),
        infoItems() {
            if (!this.calculateSharesResult) {
                return [];
            }
            return [
                { title: 'Сумма по акциям', value: utils.formats.formatNumberWithSpaces(this.calculateSharesResult.totalAmount, true) },
                { title: 'Количество акций', value: utils.formats.formatNumberWithSpaces(this.calculateSharesResult.totalQuantity) },
                { title: 'Общий остаток', value: utils.formats.formatNumberWithSpaces(this.calculateSharesResult.totalRemainder, true) },
            ];
        },
        user() {
            return this.$store.getters['users/user'];
        },
        isLoading() {
            return this.generateInvestmentOfferDocumentsIsLoading ||
                this.calculateSharesIsLoading;
        },
        isRecalculationRequired() {
            return this.lastValue !== this.fields.price.value;
        },
    },
    methods: {
        updateFormData(data) {
            this.fields = data;
        },
        submitHandler() {
            if (this.isLoading) {
                return;
            }
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            this.$store.dispatch('admin/investments/calculateShares', {
                id: this.estate.id,
                price: this.fields.price.value,
            });
            this.lastValue = this.fields.price.value;
            this.$emit('calculated-price-change', this.fields.price.value);
        },
        cancelChanges() {
            this.fields.price.value = this.lastValue;
        },
    },
    watch: {
        calculateSharesError(newVal) {
            if (newVal) {
                this.cancelChanges();
            }
        },
        isRecalculationRequired(newVal) {
            this.$emit('recalculation-requirement-change', newVal);
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-prepare-invest-offer-price {
    &__row {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 40px;
    }
    &__form {
        flex: 0 0 auto;
        width: 50%;
        &-title {
            .typography-small();

            margin-bottom: 24px;
        }
    }
    &__stats {
        flex: 1 1 auto;
        margin-top: 52px;
        margin-left: 24px;
        padding-left: 16px;
        border-left: 2px solid @color-primary;
        &-item {
            .typography-body();

            display: flex;
            align-items: baseline;
            justify-content: flex-start;
            & ~ & {
                margin-top: 8px;
            }
            &-title {
                margin-right: 4px;

                color: @color-gray-main;
                &:after {
                    content: ':';
                }
            }
        }
    }
    &__warning {
        .typography-body();

        margin: 24px 0;

        text-align: center;
    }
    &__buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin: -8px;
        &-item {
            flex: 0 0 auto;
            width: 222px;
            padding: 8px;
        }
    }
    @media @media-sm-down {
        &__row {
            flex-direction: column;
            align-items: stretch;
        }
        &__form {
            flex: 1 1 100%;
            width: auto;
        }
        &__stats {
            flex: 1 1 100%;
            margin-top: 24px;
            margin-left: 0;
        }
    }
}
</style>
