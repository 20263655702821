<template>
    <div class="ui-form-field"
        v-bind:class="{ _for_file: model.type === 'file' || model.type === 'image' }"
        >
        <template v-if="model.type !== 'checkbox'">
            <label class="ui-form-field__title"
                v-if="model.labelIsHtml"
                v-bind:class="{
                    _empty: isEmpty,
                    _invalid: isInvalid,
                    _focused: isFocused,
                    _disabled: model.isDisabled,
                    _large: model.largeLabel || model.type === 'radio-group',
                }"
                >
                <span v-html="model.label" />
                <ui-hint
                    v-if="model.labelHint"
                    v-bind="model.labelHint"
                />
            </label>
            <label class="ui-form-field__title"
                v-else-if="labelContainsBBCode"
                v-bind:class="{
                    _empty: isEmpty,
                    _invalid: isInvalid,
                    _focused: isFocused,
                    _disabled: model.isDisabled,
                    _large: model.largeLabel || model.type === 'radio-group',
                }"
                >
                <span v-html="parsedLabel" />
                <ui-hint
                    v-if="model.labelHint"
                    v-bind="model.labelHint"
                />
            </label>
            <label class="ui-form-field__title"
                v-else
                v-bind:class="{
                    _empty: isEmpty,
                    _invalid: isInvalid,
                    _focused: isFocused,
                    _disabled: model.isDisabled,
                    _large: model.largeLabel || model.type === 'radio-group',
                }"
                >
                {{ model.label + (isRequired ? '*' : '') }}
                <ui-hint
                    v-if="model.labelHint"
                    v-bind="model.labelHint"
                />
            </label>
        </template>
        <div class="ui-form-field__labels"
            v-if="model.labels && model.labels.length > 0"
            >
            <div class="ui-form-field__labels-item"
                v-for="(label, index) in model.labels"
                v-bind:key="index"
                >
                <ui-label v-bind="{ isInForm: true, ...label }" />
            </div>
        </div>
        <div class="ui-form-field__input">
            <div class="ui-form-field__input-prefix"
                v-if="model.prefix"
                v-text="model.prefix"
            />
            <div class="ui-form-field__input-field">
                <component v-bind:is="input"
                    v-bind:model="model"
                    v-bind:value="value"
                    v-bind:is-invalid="isInvalid"
                    v-on="getHandler(model.type)"
                />
            </div>
        </div>
        <div class="ui-form-field__botline">
            <div class="ui-form-field__botline-error"
                v-if="error && isInvalid"
                v-text="error"
            />
            <div class="ui-form-field__botline-hint"
                v-if="hint"
                >
                <template v-if="hint === 'recovery'">
                    <a class="ui-form-field__botline-hint-link"
                        v-bind:href="passwordResetHref"
                        v-text="'Забыли пароль?'"
                    />
                </template>
            </div>
        </div>
        <div class="ui-form-field__warning"
            v-if="model.warning"
            >
            <div class="ui-form-field__warning-icon">
                <icon name="attention" width="auto" height="1em" />
            </div>
            <div class="ui-form-field__warning-text"
                v-text="model.warning"
            />
        </div>
    </div>
</template>

<script>
import config from '~/config';
import utils from '~/utils';

export default {
    name: 'ui-form-field',
    props: {
        model: {
            type: Object,
            default: () => ({}),
        },
        validation: {
            type: Object,
            default: null,
        },
        value: {
            type: [ String, Boolean, Number, File ],
        },
    },
    model: {
        prop: 'value',
        event: 'input',
    },
    data: () => ({
        isFocused: false,
    }),
    computed: {
        input() {
            let component = 'ui-input-text';
            const validTypes = [
                'checkbox',
                'currency', 'currency-float', 'date', 'digital', 'email', 'float', 'numeric', 'numeric-alpahbetic', 'password', 'phone', 'username',
                'file', 'image',
                'radio-group',
                'inn',
                'select',
                'textarea',
            ];
            if (validTypes.indexOf(this.model.type) > -1) {
                component = `ui-input-${this.model.type}`;
            }
            return component;
        },
        labelContainsBBCode() {
            if (!this.model.label) {
                return false;
            }
            return this.model.label.includes('[');
        },
        parsedLabel() {
            if (this.labelContainsBBCode) {
                return utils.common.parseBBCode(this.model.label) + (this.isRequired ? '*' : '');
            }
            return this.model.label;
        },
        isRequired() {
            if (this.validation === null || this.validation === undefined) {
                return false;
            }
            return this.validation.$params.required !== undefined;
        },
        isInvalid() {
            if (this.validation === null || this.validation === undefined) {
                return false;
            }
            return this.validation.$dirty && this.validation.$invalid;
        },
        isEmpty() {
            if (this.model.type === 'radio-group') {
                return this.value === null || this.value === '';
            }
            return !this.value;
        },
        hint() {
            if (this.model.hint === undefined) {
                return null;
            }
            return this.model.hint;
        },
        passwordResetHref() {
            const redirectQuery = utils.common.parseUrl().find(x => x.title === 'next');
            if (redirectQuery) {
                return config.urls.auth.reset + `?${redirectQuery.title}=${redirectQuery.value}`;
            }
            return config.urls.auth.reset;
        },
        error() {
            if (!this.model.errors || !this.validation) {
                return null;
            }
            const errorKeys = Object.keys(this.validation).filter(x => x.substring(0, 1) !== '$').filter(x => !this.validation[x]);
            const validErrorKey = errorKeys.find(x => this.model.errors[x] !== undefined);
            if (validErrorKey !== undefined) {
                const error = this.model.errors[validErrorKey];
                return typeof error === 'function' ? error(this.model) : error;
            }
            return null;
        },
    },
    methods: {
        getHandler(type) {
            const typesWithChange = [ 'checkbox', 'file', 'image', 'radio-group', 'select' ];
            const result = {
                focus: this.focusHandler,
                blur: this.blurHandler,
            };
            if (typesWithChange.indexOf(type) > -1) {
                result.change = this.updateHandler;
            } else {
                result.input = this.updateHandler;
            }
            return result;
        },
        focusHandler(e) {
            this.isFocused = true;
            if (this.model.onFocus) {
                this.model.onFocus(e);
            }
        },
        blurHandler(e) {
            this.isFocused = false;
            if (this.model.onBlur) {
                this.model.onBlur(e);
            }
        },
        updateHandler(newValue) {
            this.$emit('update', newValue);
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-form-field {
    .typography-body();
    display: grid;
    width: 100%;
    &__title {
        grid-row: 1;
        grid-column: 1;
        display: block;
        margin-bottom: 4px;

        transition: color @duration-fast ease-in-out;
        ._for_file & {
            margin-bottom: 24px;
        }
        &._disabled {
            color: @color-gray-main;
        }
        &._invalid {
            color: @color-accent-negative;
        }
        &._focused {
            color: @color-primary;
        }
        &._large {
            .typography-heading();

            margin-bottom: 16px;

            color: @color-gray-darkest;
        }
    }
    &__labels {
        grid-row: 1;
        grid-column: 2;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: -4px -4px 0;
        &-item {
            flex: 0 0 auto;
            padding: 4px;
        }
    }
    &__input {
        grid-column: ~'1 / 3';
        display: flex;
        align-items: baseline;
        justify-content: flex-start;
        &-prefix {
            flex: 0 0 auto;
            margin-right: 8px;

            color: @color-gray-main;
        }
        &-field {
            flex: 1 1 auto;
        }
    }
    &__botline {
        grid-column: ~'1 / 3';
        &-hint {
            padding-top: 5px;

            text-align: right;
            &-link {
                color: @color-gray-main;
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        &-error {
            margin-bottom: 10px;

            color: @color-accent-negative;
            ._for_file & {
                margin-top: 16px;
            }
        }
    }
    &__warning {
        grid-column: ~'1 / 3';
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        &-icon {
            margin-top: 10px;
            margin-right: 9px;

            color: @color-primary;
            font-size: 1.65rem;
        }
        &-text {
            margin-top: 8px;
        }
    }
    @media @media-md-down {
        &__title {
            grid-column: 1;
        }
        &__labels {
            grid-column: 1;
            grid-row: 3;
            margin: 4px -4px -4px;
            justify-content: flex-start;
        }
        &__input,
        &__botline,
        &__warning {
            grid-column: 1;
        }
    }
}
</style>
