<template>
    <div class="page-cabinet-estates-list-cards-item-share">
        <div class="page-cabinet-estates-list-cards-item-share__field"
            v-for="(field, index) of fields"
            v-bind:key="index"
            >
            <div class="page-cabinet-estates-list-cards-item-share__field-title"
                v-text="field.title + ':'"
            />
            <div class="page-cabinet-estates-list-cards-item-share__field-value"
                v-text="field.title === 'Текущая Стоимость' ? item.currentValueFormatted : item[field.valueCode]"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'page-cabinet-estates-list-cards-item-share',
    props: {
        item: {
            type: Object,
        },
        fields: {
            type: Array,
        },
    },
    methods: {
        saleHandler() {
            this.$store.commit('modals/push', {
                name: 'market-application-create',
                props: {
                    givenShareId: this.item.shareData.id,
                },
            });
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-estates-list-cards-item-share {
    &__field {
        display: flex;
        align-items: baseline;
        justify-content: flex-start;
        &:not(:last-child) {
            margin-bottom: 8px;
        }
        &-title {
            .typography-caption();

            margin-right: 4px;

            color: @color-gray-main;
            font-weight: 700;
            text-transform: uppercase;
        }
        &-value {
            .typography-body();

            margin-right: 4px;
        }
    }
}
</style>
