<template>
    <div class="plugin-about-persons-item">
        <div class="plugin-about-persons-item__info">
            <div class="plugin-about-persons-item__info-name"
                v-text="name"
            />
            <div class="plugin-about-persons-item__info-description"
                v-html="description"
            />
        </div>
        <div class="plugin-about-persons-item__image"
            v-bind:style="{ backgroundImage: `url(${resizedImage})` }"
        />
    </div>
</template>

<script>
import utils from '~/utils';

export default {
    name: 'plugin-about-persons-item',
    props: {
        image: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
    },
    computed: {
        resizedImage() {
            return utils.common.injectResize(this.image, '220x220');
        },
    },
};
</script>

<style lang="less">
@import '~theme';

.plugin-about-persons-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    &:not(:last-child) {
        margin-bottom: 40px;
    }
    &__info {
        flex: 1 1 auto;
        padding-right: 40px;
        &-name {
            .typography-large();

            margin-bottom: 16px;
        }
        &-description {
            p {
                .typography-small();

                margin: 0;

                white-space: pre-line;
            }
        }
    }
    &__image {
        flex: 0 0 auto;
        width: 110px;
        height: 110px;

        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    @media @media-md-down {
        &:not(:last-child) {
            margin-bottom: 16px;
        }
        &__info {
            padding-right: 16px;
            &-name {
                .typography-heading();

                margin-bottom: 8px;
            }
            &-description {
                /deep/ p {
                    .typography-body();
                }
            }
        }
        &__image {
            width: 72px;
            height: 72px;
        }
    }
}
</style>
