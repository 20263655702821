export const item = (data) => {
    const result = {
        id: data.id,
        image: data.cover,
        title: data.title,
        date: data.timestamp,
        readingDuration: data.reading_duration,
    };
    return result;
};

export const list = (data) => {
    const result = {
        items: data.results.map(item),
        paging: {
            count: data.count || 0,
            page: data.page || 1,
        },
        ...data,
    };
    return result;
};
