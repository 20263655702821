<template>
    <div class="modal-market-application-confirm-info">
        <div class="modal-market-application-confirm-info__title">
            Подтверждение сделки
        </div>
        <div class="modal-market-application-confirm-info__description">
            Проверьте данные и получите код подтверждения
        </div>
        <div class="modal-market-application-confirm-info__estate-wrapper">
            <component
                v-bind:is="isMobile ? 'div' : 'ui-card-base'"
                v-bind:with-shadow="isMobile ? null : true"
                >
                <div class="modal-market-application-confirm-info__estate">
                    <a class="modal-market-application-confirm-info__estate-image"
                        v-bind:href="deal.estate.url"
                        v-bind:style="estateBackgroundStyle"
                    />
                    <div class="modal-market-application-confirm-info__estate-info">
                        <div class="modal-market-application-confirm-info__estate-info-title"
                            v-text="deal.estate.title"
                        />
                        <div class="modal-market-application-confirm-info__estate-info-stats">
                            <modal-market-application-confirm-info-stats
                                v-bind:stats="stats"
                            />
                        </div>
                    </div>
                </div>
                <div class="modal-market-application-confirm-info__stats">
                    <modal-market-application-confirm-info-stats
                        v-bind:stats="stats"
                    />
                </div>
            </component>
        </div>
        <div class="modal-market-application-confirm-info__submit">
            <ui-button
                v-on:click="submit"
                v-bind:text="'Получить смс-код'"
            />
        </div>
        <a class="modal-market-application-confirm-info__document"
            v-if="deal.document"
            v-bind:href="deal.document.url"
            target="_blank"
            >
            <div class="modal-market-application-confirm-info__document-icon">
                <icon name="download" />
            </div>
            <div class="modal-market-application-confirm-info__document-text">
                Скачать договор
            </div>
        </a>
    </div>
</template>

<script>
import utils from '~/utils';

export default {
    name: 'modal-market-application-confirm-info',
    props: {
        deal: {
            type: Object,
        },
    },
    computed: {
        estateBackgroundStyle() {
            return { backgroundImage: `url(${utils.common.injectResize(
                this.deal.estate.image,
                '184x184'
            )})` };
        },
        stats() {
            const result = [
                { title: 'Тип сделки:', value: this.deal.typeTitle },
                { title: 'Стоимость акции:', value: this.deal.priceFormatted },
                { title: 'Кол-во акций:', value: this.deal.amount },
            ];
            if (this.deal.commission) {
                // result.push({ title: 'Комиссия', value: this.deal.commissionFormatted });
                // result.push({ title: 'Сумма', value: this.deal.sumFormatted });
                // result.push({ title: 'Сумма с комиссией', value: this.deal.sumWithCommissionFormatted });
                result.push({ title: 'Сумма', value: this.deal.sumWithCommissionFormatted });
            } else {
                result.push({ title: 'Сумма', value: this.deal.sumFormatted });
            }
            return result;
        },
        isMobile() {
            return window.innerWidth < 768;
        },
    },
    methods: {
        submit() {
            this.$emit('step-change', 'confirmation');
        },
    },
};
</script>

<style lang="less">
@import '~theme';

.modal-market-application-confirm-info {
    &__title {
        .typography-large();

        margin-bottom: 8px;

        text-align: center;
    }
    &__description {
        .typography-body();

        margin-bottom: 24px;

        text-align: center;
    }
    &__estate {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        &-wrapper {
            max-width: 437px;
            margin: 0 auto;
        }
        &-image {
            .mixin-background-image();

            flex: 0 0 auto;
            display: block;
            width: 92px;
            height: 92px;
            margin-right: 16px;
            border-radius: @border-radius-md;

            text-decoration: none;

            background-color: @color-gray-light;
        }
        &-info {
            flex: 1 1 auto;
            &-title {
                .typography-heading();

                margin-bottom: 8px;
            }
        }
    }
    &__stats {
        display: none;
    }
    &__submit {
        width: fit-content;
        min-width: 205px;
        margin: 32px auto;
    }
    &__document {
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        margin: 24px auto;

        text-decoration: none;
        &:hover > &-text {
            text-decoration: underline;
        }
        &-icon {
            flex: 0 0 auto;

            margin-right: 12px;

            font-size: 2rem;
        }
        &-text {
            .typography-caption();

            font-weight: bold;
            text-transform: uppercase;

            flex: 0 0 auto;
        }
    }
    @media @media-sm-down {
        &__title {
            .typography-medium();
        }
        &__description {
            .typography-caption();
        }
        &__estate {
            align-items: center;
            margin-bottom: 16px;
            &-image {
                width: 48px;
                height: 48px;
            }
            &-info {
                &-title {
                    margin-bottom: 0;
                }
                &-stats {
                    display: none;
                }
            }
        }
        &__stats {
            display: block;
        }
    }
}
</style>
