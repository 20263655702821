<template>
    <section class="page-admin-estates-item-info-footer">
        <div class="page-admin-estates-item-info-footer__text"
            v-if="!initial || error"
            v-bind:class="{ _error: error }"
            v-text="error ? error : isSaved ? 'Изменения сохранены' : 'Последние изменения не сохранены'"
        />
        <div class="page-admin-estates-item-info-footer__button">
            <ui-button
                v-on:click="submitHandler"
                v-bind:disabled="!isButtonActive || isSaved || (isTouched && isInvalid)"
                v-bind:variant="isSmallScreen ? 'large' : 'small'"
                text="Сохранить изменения"
                theme="primary"
            />
        </div>
    </section>
</template>

<script>
import utils from '~/utils';

export default {
    name: 'page-admin-estates-item-info-footer',
    data: () => ({
        initial: true,
    }),
    computed: {
        error() {
            const error = this.$store.state.admin.estates.updateEstateInfoError;
            if (error) {
                return utils.network.parseError(error);
            }
            return null;
        },
        isSaved() {
            return this.$store.state.admin.estates.updateEstateInfoIsSaved;
        },
        isInvalid() {
            return this.$store.state.admin.estates.updateEstateInfoIsInvalid;
        },
        isTouched() {
            return this.$store.state.admin.estates.updateEstateInfoIsTouched;
        },
        isButtonActive() {
            return this.$store.state.admin.estates.updateEstateInfoInfoIsButtonActive;
        },
        isSmallScreen() {
            return window.innerWidth < 768;
        },
    },
    methods: {
        submitHandler() {
            if (!this.isButtonActive) {
                return;
            }
            if (this.isInvalid) {
                this.$store.commit('admin/estates/setUpdateEstateInfoIsTouchNeeded', true);
            }
            this.$store.dispatch('admin/estates/updateEstateInfo');
        },
    },
    watch: {
        isSaved(newVal) {
            if (!newVal) {
                this.initial = false;
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-admin-estates-item-info-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &__text {
        .typography-body();

        margin-right: 24px;

        color: @color-gray-main;
        &._error {
            color: @color-accent-negative;
        }
    }
    &__button {
    }
    @media @media-sm-down {
        display: block;

        text-align: center;
        &__text {
            margin: 0 0 16px;
        }
    }
}
</style>
