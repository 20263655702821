<template>
    <div class="modal-market-application-create-headline">
        <div class="modal-market-application-create-headline__warning">
            Уважаемые инвесторы!<br>
            <span v-text="`Минимальная сумма любой сделки на платформе`" /> <span class="modal-market-application-create-headline__warning-accent" v-text="'100 000 руб.'"/>
        </div>
        <div class="modal-market-application-create-headline__title"
            v-text="title"
        />
        <div class="modal-market-application-create-headline__subtitle"
            v-text="subtitle"
        />
    </div>
</template>

<script>
export default {
    props: {
        variant: {
            type: String,
            default: 'empty',
        },
        mode: {
            type: String,
        },
    },
    name: 'modal-market-application-create-headline',
    computed: {
        title() {
            if (this.mode === 'edit') {
                return 'Редактирование заявки';
            }
            return this.variant === 'empty' || this.variant === 'normal' ? 'Создание заявки' : 'Встречная заявка';
        },
        subtitle() {
            if (this.mode === 'edit') {
                return 'Измените параметры заявки или отмените ее.';
            }
            return this.variant === 'empty'
                ? 'Выберите объект для создания заявки'
                : 'Настройте параметры заявки';
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-market-application-create-headline {
    &__warning {
        .typography-heading();

        padding: 8px 12px;
        margin-bottom: 16px;
        border: 1px solid @color-gray-light;
        border-radius: 10px;
        &-accent {
            color: @color-primary;
        }
    }
    &__title {
        .typography-large();

        margin-bottom: 16px;
    }
    &__subtitle {
        .typography-body();

        margin-bottom: 24px;
    }
    @media @media-md-down {
        &__title {
            .typography-medium();

            margin-bottom: 8px;
        }
        &__subtitle {
            .typography-body();

            margin-bottom: 12px;
        }
    }
    @media @media-sm-down {
        &__warning {
            border-top: none;
            border-right: none;
            border-bottom: none;
            border-left: 2px solid @color-primary;
            border-radius: 0;
        }
    }
}
</style>
