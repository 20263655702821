<template>
    <form class="ui-input-character-set"
        v-bind:class="{
            _shrink: size > 6,
        }"
        autocomplete="chrome-off"
        >
        <div class="ui-input-character-set__item"
            v-for="(char, i) in characters"
            v-bind:key="i"
            >
            <ui-input-base-character
                v-bind:value="char"
                v-bind:index="i"
                v-bind:is-autofocus="isAutofocus && i === 0"
                v-bind:is-active="currentPosition === i"
                v-bind:regexp="regexp"
                v-bind:is-invalid="isInvalid"
                v-bind:is-numeric="isNumeric"
                v-on:update-value="updateValue"
                v-on:submit="submit"
                v-on:delete="deleteHandler"
            />
        </div>
    </form>
</template>

<script>
export default {
    name: 'ui-input-character-set',
    props: {
        size: {
            type: Number,
        },
        value: {
            type: [ String, Number ],
        },
        isAutofocus: {
            type: Boolean,
            default: false,
        },
        isInvalid: {
            type: Boolean,
            default: false,
        },
        isNumeric: {
            type: Boolean,
            default: true,
        },
        regexp: {
            type: RegExp,
            default: null,
        },
    },
    model: {
        prop: 'value',
        event: 'input',
    },
    data: () => ({
        characters: [],
        currentPosition: null,
        currentFocus: null, // null or 0-based index
    }),
    methods: {
        resetFocus() {
            this.currentFocus = null;
        },
        deleteHandler(index) {
            if (this.characters[index]) {
                return;
            }
            this.currentPosition = Math.max(this.currentPosition - 1, 0);
        },
        updateValue(data) {
            const dataArray = data.value.substring(0, Math.min(this.size - data.index, data.value.length)).split('');
            // saving empty value
            if (dataArray.length === 0) {
                dataArray.push('');
            }
            this.characters = this.characters
                .slice(0, data.index)
                .concat(dataArray)
                .concat(this.characters.slice(data.index + dataArray.length));
            this.currentPosition = data.index + dataArray.filter(x => x.length > 0).length;
            this.$emit('update');
        },
        submit() {
            this.$emit('submit');
        },
    },
    beforeMount() {
        const newCharacters = new Array(this.size);
        for (let i = 0; i < newCharacters.length; i++) {
            newCharacters[i] = '';
        }
        this.characters = newCharacters;
        if (this.isAutofocus) {
            this.currentPosition = 0;
        }
    },
    watch: {
        characters(newVal) {
            this.$emit('input', newVal.join(''));
        },
    },
};
</script>

<style scoped lang="less">
@import "~theme";

.ui-input-character-set {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    margin: 0 -6px;
    &._shrink {
        margin: 0 -3px
    }
    &__item {
        flex: 0 0 auto;
        padding: 0 6px;
        ._shrink > & {
            padding: 0 3px;
        }
    }
    @media @media-xs-down {
        margin: 0 -4px;
        &._shrink {
            margin: 0 -2px
        }
        &__item {
            padding: 0 4px;
            ._shrink > & {
                padding: 0 2px;
            }
        }
    }
}
</style>
