import client from '../_client';
import config from '~/config';
// import utils from '~/utils';
import transforms from './transforms';

export const getInnSuggest = (data = {}) => {
    return client.request({
        // headers: {
        //     'X-CSRFToken': utils.network.getCSRFToken(),
        // },
        url: config.api.dadata.inn,
        method: 'POST',
        data,
        transformData: transforms.responses.list,
    });
};
