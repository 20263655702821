<template>
    <label class="ui-input-checkbox"
        v-bind:aria-checked="value"
        role="checkbox"
        v-bind:aria-labelledby="labelId"
        >
        <div class="ui-input-checkbox__input">
            <ui-input-base-checkbox
                v-bind:value="model.value"
                v-on:change="updateValue"
            />
        </div>
        <div class="ui-input-checkbox__right"
            v-bind:id="labelId"
            >
            <div class="ui-input-checkbox__right-error"
                v-if="error && isInvalid"
                v-text="error"
            />
            <div class="ui-input-checkbox__right-label"
                v-if="$slots.default"
                >
                <slot />
            </div>
            <template v-else-if="model.label">
                <div class="ui-input-checkbox__right-label"
                    v-if="labelIsHtml"
                    >
                    <span v-html="parsedLabel" /> <ui-hint
                        v-if="model.hint"
                        v-bind:text="model.hint"
                    />
                </div>
                <div class="ui-input-checkbox__right-label"
                    v-else
                    >
                    {{ model.label }} <ui-hint
                        v-if="model.hint"
                        v-bind:text="model.hint"
                    />
                </div>
            </template>
        </div>
    </label>
</template>

<script>
import utils from '~/utils';

export default {
    name: 'ui-input-checkbox',
    props: {
        model: {
            type: Object,
            default: () => ({}),
        },
        validation: {
            type: Object,
            default: null,
        },
        value: {
            type: Boolean,
            default: false,
        },
    },
    model: {
        prop: 'value',
        event: 'change',
    },
    computed: {
        labelId() {
            return 'label-' + this.model.name;
        },
        labelContainsBBCode() {
            if (!this.model.label) {
                return false;
            }
            return this.model.label.includes('[');
        },
        labelIsHtml() {
            return this.model.labelIsHtml || this.labelContainsBBCode;
        },
        parsedLabel() {
            if (this.labelContainsBBCode) {
                return utils.common.parseBBCode(this.model.label);
            }
            return this.model.label;
        },
        isInvalid() {
            if (this.validation === null || this.validation === undefined) {
                return false;
            }
            return this.validation.$dirty && this.validation.$invalid;
        },
        error() {
            if (!this.model.errors || !this.validation) {
                return null;
            }
            const errorKeys = Object.keys(this.validation).filter(x => x.substring(0, 1) !== '$').filter(x => !this.validation[x]);
            const validErrorKey = errorKeys.find(x => this.model.errors[x] !== undefined);
            if (validErrorKey !== undefined) {
                return this.model.errors[validErrorKey];
            }
            return null;
        },
    },
    methods: {
        updateValue(value) {
            this.$emit('change', value);
        },
    },
};
</script>

<style scoped lang="less">
@import "~theme";

.ui-input-checkbox {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    cursor: pointer;
    &__input {
        flex: 0 0 auto;
        margin-top: 2px;
    }
    &__right {
        .typography-body();

        flex: 1 1 auto;
        margin-left: 8px;
        &-error {
            margin-bottom: 6px;

            color: @color-accent-negative;
        }
        &-label {
            white-space: pre-line;
            /deep/ a {
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
</style>
