<template>
    <label class="ui-input-radio"
        v-bind:class="{
            _active: checked,
        }"
        >
        <input class="ui-input-radio__input"
            type="radio"
            v-bind:name="name"
            v-bind:checked="checked"
            v-on:change="updateModel($event.target.checked)"
        />
        <div class="ui-input-radio__check">
        </div>
    </label>
</template>

<script>
export default {
    name: 'ui-input-radio',
    props: {
        checked: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            default: '',
        },
    },
    model: {
        prop: 'checked',
        event: 'change',
    },
    methods: {
        updateModel(newValue) {
            this.$emit('change', newValue);
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-input-radio {
    position: relative;
    z-index: 1;

    display: block;
    width: 16px;
    height: 16px;
    border: 1px solid currentColor;
    border-radius: 50%;

    color: @color-gray-main;

    cursor: pointer;

    transition-property: color;
    transition-duration: @duration-fast;
    transition-timing-function: ease-in-out;
    &._active {
        color: @color-gray-darkest;
    }
    &__input {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;

        opacity: 0;
        visibility: hidden;
        appearance: none;
    }
    &__check {
        position: absolute;
        top: 3px;
        left: 3px;
        z-index: 2;

        width: 8px;
        height: 8px;
        border-radius: 50%;

        background-color: currentColor;

        opacity: 0;
        transform: scale(0);

        transition: opacity @duration-fast ease-in-out, transform @duration-fast ease-in-out;
        ._active > & {
            opacity: 1;
            transform: scale(1);
        }
    }
}
</style>
