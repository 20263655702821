<template>
    <div class="modal-market-notifications">
        <transition name="fade">
            <div class="modal-market-notifications__loader"
                v-if="isLoading && !error"
                >
                <div class="modal-market-notifications__loader-container">
                    <ui-loader />
                </div>
            </div>
        </transition>
        <div class="modal-market-notifications__content"
            v-if="updateSubscriptionsItemResult"
            >
            <div class="modal-market-notifications__content-title _success"
                v-text="'Подписки обновлены'"
            />
            <div class="modal-market-notifications__content-button">
                <ui-button
                    v-on:click="close"
                    >
                    Закрыть
                </ui-button>
            </div>
        </div>
        <div class="modal-market-notifications__content"
            v-else-if="!error"
            >
            <div class="modal-market-notifications__content-title"
                v-text="estate.title"
            />
            <div class="modal-market-notifications__content-description">
                Мы будем оповещать вас, когда появятся новые заявки, на почту <b v-if="user" class="modal-market-notifications__content-description-email" v-text="user.email" />
            </div>
            <div class="modal-market-notifications__content-checkboxes">
                <div class="modal-market-notifications__content-checkboxes-item">
                    <ui-input-checkbox
                        v-model="isSubscribedToSale.value"
                        v-bind:model="isSubscribedToSale"
                    />
                </div>
                <div class="modal-market-notifications__content-checkboxes-item">
                    <ui-input-checkbox
                        v-model="isSubscribedToBuy.value"
                        v-bind:model="isSubscribedToBuy"
                    />
                </div>
            </div>
            <div class="modal-market-notifications__content-button">
                <ui-button
                    v-bind:disabled="isLoading"
                    v-on:click="submit"
                    >
                    подтвердить
                </ui-button>
            </div>
        </div>
        <div class="modal-market-notifications__error"
            v-if="error"
            >
            Ошибка загрузки данных
        </div>
        <div class="modal-market-notifications__error"
            v-if="user && !user.email"
            >
            Не найден email, проверьте данные в личном кабинете
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import forms from '~/forms';

export default {
    name: 'modal-market-notifications',
    props: {
        shareId: {
            type: [ Number, String ],
        },
        estate: {
            type: Object,
        },
    },
    data: () => ({
        isSubscribedToSale: { ...forms.fields.marketSubscriptionSale },
        isSubscribedToBuy: { ...forms.fields.marketSubscriptionBuy },
    }),
    computed: {
        ...mapState('market', [
            'getSubscriptionsItemResult',
            'getSubscriptionsItemIsLoading',
            'getSubscriptionsItemError',

            'updateSubscriptionsItemResult',
            'updateSubscriptionsItemIsLoading',
            'updateSubscriptionsItemError',
        ]),
        ...mapState('users', [
            'user',
        ]),
        isLoading() {
            return this.updateSubscriptionsItemIsLoading ||
                this.getSubscriptionsItemIsLoading ||
                !this.user;
        },
        error() {
            return this.getSubscriptionsItemError || this.updateSubscriptionsItemError;
        },
    },
    methods: {
        submit() {
            this.$store.dispatch('market/updateSubscriptionsItem', {
                payload: {
                    id: this.estate.id,
                    isSubscribedToSale: this.isSubscribedToSale.value,
                    isSubscribedToBuy: this.isSubscribedToBuy.value,
                },
            });
        },
        close() {
            this.$store.commit('modals/pop');
        },
    },
    beforeMount() {
        this.$store.state.market.updateSubscriptionsItemResult = null;
        this.$store.state.market.updateSubscriptionsItemIsLoading = false;
        this.$store.state.market.updateSubscriptionsItemError = null;

        this.$store.state.market.getSubscriptionsItemResult = null;
        this.$store.state.market.getSubscriptionsItemIsLoading = false;
        this.$store.state.market.getSubscriptionsItemError = null;
        this.$store.dispatch('market/getSubscriptionsItem', {
            id: this.estate.id,
        });
        if (!this.user) {
            this.$store.dispatch('users/getUser');
        }
    },
    watch: {
        getSubscriptionsItemResult() {
            if (this.getSubscriptionsItemResult) {
                const {
                    isSubscribedToSale,
                    isSubscribedToBuy,
                } = this.getSubscriptionsItemResult;
                this.isSubscribedToSale.value = isSubscribedToSale;
                this.isSubscribedToBuy.value = isSubscribedToBuy;
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-market-notifications {
    .modal();

    position: relative;

    display: flex;
    flex-direction: column;
    &__loader {
        .transition-fade();

        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        background-color: @color-gray-lightest;
        &-container {
            width: 50px;
        }
    }
    &__content {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        width: 100%;
        &-title {
            .typography-large();

            margin-bottom: 8px;
            &._success {
                margin-bottom: 24px;

                text-align: center;
            }
        }
        &-description {
            .typography-body();

            margin-bottom: 24px;
            &-email {
                .typography-heading();
            }
        }
        &-checkboxes {
            display: flex;
            align-items: baseline;
            justify-content: flex-start;
            flex-wrap: wrap;
            width: 100%;
            margin-bottom: 16px;
            &-item {
                flex: 0 0 auto;
                margin-right: 16px;
                padding-bottom: 8px;
            }
        }
        &-button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 206px;
            max-width: 100%;
            margin: 0 auto auto;
        }
    }
    &__error {
        .typography-large();

        color: @color-accent-negative;
    }
    @media @media-md-down {
        &__content {
            &-title {
                .typography-medium();
            }
        }
    }
}
</style>
