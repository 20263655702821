import client from '../_client';
import config from '~/config';
import utils from '~/utils';
import transforms from './transforms';

export const getSubscriptions = (data = {}) => {
    return client.request({
        url: config.api.subscriptions.get,
        method: 'GET',
        data,
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        transformData: transforms.responses.subscriptionsList,
    });
};

export const subscribe = (data = {}) => {
    return client.request({
        url: config.api.subscriptions.subscribe.replace('{id}', '1'),
        method: 'POST',
        data: transforms.requests.subscribe(data),
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        // transformData: transforms.responses.countriesList,
    });
};
