<template>
    <section class="page-admin-estates-list">
        <div class="page-admin-estates-list__headline">
            <div class="page-admin-estates-list__headline-container">
                <h1 class="page-admin-estates-list__headline-title">
                    Объекты инвестирования
                </h1>
                <div class="page-admin-estates-list__headline-create">
                    <ui-button
                        v-on:click="createEstateHandler"
                        v-bind:theme="'primary'"
                        icon-left="plus"
                        >
                        Добавить объект
                    </ui-button>
                </div>
                <div class="page-admin-estates-list__headline-create _mobile">
                    <ui-button
                        v-on:click="createEstateHandler"
                        v-bind:theme="'primary'"
                        >
                        <icon name="plus" />
                    </ui-button>
                </div>
            </div>
        </div>
        <section class="page-admin-estates-list__list">
            <div class="page-admin-estates-list__list-content">
                <div class="page-admin-estates-list__list-content-item"
                    v-for="(item, index) in accumulatedItems"
                    v-bind:key="index"
                    >
                    <common-card-estate
                        v-bind:item="item"
                        v-bind:resizes="{
                            1920: 24,
                            1599: 35,
                            1279: 30,
                            1023: 46,
                            767: 100,
                        }"
                        button-text="Редактировать"
                    />
                </div>
            </div>
            <div class="page-admin-estates-list__list-loader"
                v-if="isLoading">
                <ui-loader
                    v-bind:fixed="50"
                    v-bind:centered="true"
                />
            </div>
            <div class="page-admin-estates-list__list-buttons"
                v-if="!isLoading && pagingTotal > pagingCurrent"
                >
                <div class="page-admin-estates-list__list-buttons-item"
                    v-on:click="getMore"
                    >
                    <ui-button>
                        Показать еще
                    </ui-button>
                </div>
            </div>
        </section>
    </section>
</template>

<script>
import config from '~/config';

export default {
    name: 'page-admin-estates-list',
    data: () => ({
        pagingSize: 6,
        pagingCurrent: 1,
        accumulatedItems: [],
    }),
    computed: {
        items() {
            const result = this.$store.getters['estates/items'];
            if (result) {
                return result.map(x => ({ ...x, url: `${config.urls.adminDashboard.estates}${x.id}` }));
            }
            return result;
        },
        totalItems() {
            return this.$store.getters['estates/count'];
        },
        isLoading() {
            return this.$store.getters['estates/isLoading'];
        },
        pagingTotal() {
            return this.totalItems / this.pagingSize;
        },
    },
    mounted() {
        if (!this.items) {
            this.getItems();
        }
    },
    methods: {
        getItems() {
            this.$store.dispatch('estates/getItems', {
                page: this.pagingCurrent,
                page_size: this.pagingSize,
            });
        },
        getMore() {
            this.pagingCurrent += 1;
            this.getItems();
        },
        createEstateHandler() {
            this.$store.commit('modals/push', 'create-estate');
        },
    },
    watch: {
        items(newItems) {
            if (newItems) {
                this.accumulatedItems.push(...newItems);
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-admin-estates-list {
    padding-top: 24px;
    padding-bottom: 120px;
    &__headline {
        background-color: @color-gray-lighter;
        &-container {
            .container();
            .container-paddings();

            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-top: 40px;
            padding-bottom: 40px;
        }
        &-title {
            .typography-extra-large();

            flex: 1 1 auto;
            margin: 0 40px 0 0;
        }
        &-create {
            flex: 0 0 auto;
            width: 205px;
            &._mobile {
                display: none;
            }
        }
    }
    &__list {
        .container();
        &-content {
            display: flex;
            flex-wrap: wrap;
            align-content: stretch;
            justify-content: flex-start;
            padding-top: 40px;
            padding-bottom: 12px;
            margin: -20px -16px 0;
            &-item {
                flex: 0 0 auto;
                width: calc(100% / 4);
                padding: 20px 16px;
            }
        }
        &-buttons {
            display: flex;
            align-content: center;
            justify-content: center;
        }
    }
    @media @media-xxl-down {
        &__list {
            &-content {
                &-item {
                    width: calc(100% / 3);
                }
            }
        }
    }
    @media @media-md-down {
        &__headline {
            background-color: @color-gray-lightest;
            &-container {
                padding-top: 24px;
                padding-bottom: 24px;
            }
            &-title {
                .typography-large();
            }
            &-create {
                display: none;
                &._mobile {
                    width: fit-content;
                    display: block;
                }
            }
        }
        &__list {
            &-content {
                &-item {
                    width: calc(100% / 2);
                }
            }
        }
    }
    @media @media-sm-down {
        &__list {
            &-content {
                &-item {
                    width: 100%;
                }
            }
        }
    }
}
</style>
