<template>
    <div class="plugin-about-persons">
        <div class="plugin-about-persons__title"
            v-if="title"
            v-text="title"
        />
        <div class="plugin-about-persons__items">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'plugin-about-persons',
    props: {
        title: {
            type: String,
            default: '',
        },
    },
};
</script>

<style lang="less">
@import '~theme';

.plugin-about-persons {
    .container();
    .container-paddings();

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    &__title {
        .typography-extra-large();

        flex: 0 0 auto;
        width: calc((@grid-column + @grid-gap) * 4);
        padding-left: calc(@grid-column + @grid-gap);
        padding-right: @grid-gap;
    }
    &__items {
        flex: 1 1 auto;
        width: calc(@grid-column * 6 + @grid-gap * 5);
        padding-right: calc(@grid-column + @grid-gap);
        padding-left: calc((@grid-column + @grid-gap) * 2);
    }
    @media @media-lg-down {
        &__title {
            width: calc((@grid-column + @grid-gap) * 6);
        }
        &__items {
            width: auto;
            padding-right: 0;
            padding-left: 0;
        }
    }
    @media @media-md-down {
        display: block;
        &__title {
            .typography-large();

            padding: 0;
            width: 100%;
            margin-bottom: 24px;
        }
        &__items {
            padding: 0;
            width: 100%;
        }
    }
}
</style>
