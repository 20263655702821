import config from '~/config';
import utils from '~/utils';

export const username = {
    name: 'username',
    label: 'Email / Номер телефона',
    value: '',
    initialValue: '',
    placeholder: '',
    type: 'username',
    autocomplete: 'username',
    errors: {
        required: 'Введите email или номер телефона, использованные при регистрации',
        isValid: `Введите корректный телефон c +7 или email`,
    },
};

export const fullName = {
    name: 'name',
    label: 'Ваше имя',
    value: '',
    initialValue: '',
    placeholder: 'Имя',
    type: 'text',
    autocomplete: 'name',
    errors: {
        required: 'Введите имя',
        isValid: `Имя может содержать только символы русского алфавита и пробел`,
    },
};

export const firstName = {
    name: 'firstName',
    label: 'Имя',
    value: '',
    initialValue: '',
    placeholder: 'Имя',
    type: 'text',
    autocomplete: 'given-name',
    errors: {
        required: 'Введите имя',
        isValid: `Имя может содержать только символы русского алфавита`,
    },
};

export const lastName = {
    name: 'lastName',
    label: 'Фамилия',
    value: '',
    initialValue: '',
    placeholder: '',
    autocomplete: 'family-name',
    type: 'text',
    errors: {
        required: 'Введите фамилию',
        isValid: `Фамилия может содержать только символы русского алфавита`,
    },
};

export const secondName = {
    name: 'secondName',
    label: 'Отчество',
    value: '',
    initialValue: '',
    placeholder: '',
    autocomplete: 'additional-name',
    type: 'text',
    errors: {
        required: 'Введите отчество',
        isValid: `Отчество может содержать только символы русского алфавита`,
    },
};

export const noSecondName = {
    name: 'noSecondName',
    label: 'Нет отчества',
    value: false,
    type: 'checkbox',
};

export const isRussianCitizen = {
    name: 'isRussianCitizen',
    label: 'Подтверждаю наличие российского гражданства',
    value: true,
    type: 'checkbox',
    errors: {
        isValid: 'В данный момент инвестирование доступно только для граждан РФ',
    },
};

export const phone = {
    name: 'phone',
    label: 'Телефон',
    value: '',
    initialValue: '',
    placeholder: '',
    type: 'phone',
    errors: {
        required: 'Введите телефон',
        isValid: 'Формат ввода: +7 (XXX) XXX-XX-XX',
    },
};

export const email = {
    name: 'email',
    label: 'Email',
    type: 'email',
    autocomplete: 'email',
    placeholder: 'Адрес электронной почты',
    value: '',
    errors: {
        required: 'Введите email',
        emailv: 'Введите корректный email',
    },
};

export const passportNumber = {
    name: 'passportNumber',
    label: 'Номер паспорта',
    value: '',
    initialValue: '',
    placeholder: '',
    type: 'numeric',
    maxLength: config.common.passportNumberLength,
    minLength: config.common.passportNumberLength,
    errors: {
        required: 'Введите номер паспорта',
        isLengthValid: `Номера паспорта должен состоять из ${config.common.passportNumberLength} ${utils.common.getDeclension(config.common.passportNumberLength, ['цифры', 'цифр', 'цифр'])}`,
    },
};

export const passportSeries = {
    name: 'passportSeries',
    label: 'Серия паспорта',
    value: '',
    initialValue: '',
    placeholder: '',
    type: 'numeric',
    maxLength: config.common.passportSeriesLength,
    minLength: config.common.passportSeriesLength,
    errors: {
        required: 'Введите серию паспорта',
        isLengthValid: `Номера серии должен состоять из ${config.common.passportSeriesLength} ${utils.common.getDeclension(config.common.passportSeriesLength, ['цифры', 'цифр', 'цифр'])}`,
    },
};

export const snils = {
    name: 'snils',
    label: 'СНИЛС',
    value: '',
    initialValue: '',
    placeholder: '',
    type: 'numeric',
    maxLength: config.common.snilsLength,
    minLength: config.common.snilsLength,
    errors: {
        required: 'Введите номер СНИЛС',
        isLengthValid: `Номер СНИЛС должен состоять из ${config.common.snilsLength} ${utils.common.getDeclension(config.common.snilsLength, ['цифры', 'цифр', 'цифр'])}`,
        isValid: 'Введите корректный номер СНИЛС',
    },
};

export const isIndividualEntrepreneur = {
    name: 'isIndividualEntrepreneur',
    label: 'Хочу инвестировать как индивидуальный предприниматель.\nИндивидуальные предприниматели могут инвестировать без ограничений',
    type: 'checkbox',
    value: false,
    hint: 'Физические лица, не являющиеся квалифицированными инвесторами или индивидуальными предпринимателями, имеют право инвестировать не более 600к в год суммарно через все инвестиционные платформы',
};

export const inn = {
    name: 'inn',
    label: 'ИНН',
    value: '',
    initialValue: '',
    placeholder: '',
    minLength: 10,
    maxLength: 12,
    type: 'inn',
    mode: 'F', // 'F' || 'I' || 'J'
    dadataParams: {},
    errors: {
        required: 'Введите ИНН',
        isValid: `Введите корректный ИНН (10 или 12 цифр)`,
    },
};

export const inn12 = {
    name: 'inn',
    label: 'ИНН',
    value: '',
    initialValue: '',
    placeholder: '',
    minLength: 12,
    maxLength: 12,
    type: 'numeric',
    errors: {
        required: 'Введите ИНН',
        isValid: `Введите корректный ИНН (12 цифр)`,
    },
};

export const inn10 = {
    ...inn12,
    minLength: 10,
    maxLength: 10,
    errors: {
        required: 'Введите ИНН',
        isValid: `Введите корректный ИНН (10 цифр)`,
    },
};

export const inn12dadata = {
    ...inn12,
    type: 'inn',
    dadataParams: {},
    errors: {
        required: 'Введите ИНН',
        isValid: `Введите корректный ИНН (12 цифр)`,
    },
};
export const inn10dadata = {
    ...inn12dadata,
    minLength: 10,
    maxLength: 10,
    type: 'inn',
    dadataParams: {},
    errors: {
        required: 'Введите ИНН',
        isValid: `Введите корректный ИНН (10 цифр)`,
    },
};

export const okved = {
    name: 'okved',
    label: 'ОКВЭД',
    value: '',
    initialValue: '',
    type: 'digital',
    errors: {
        required: 'Введите ОКВЭД',
        isValid: 'Введите только цифры',
    },
};

export const ogrn = {
    name: 'ogrn',
    label: 'ОГРН',
    value: '',
    initialValue: '',
    placeholder: '',
    type: 'numeric',
    maxLength: config.common.ogrnLength,
    errors: {
        required: `Введите ${config.common.ogrnLength} цифр ОГРН`,
        isValid: `Введите ${config.common.ogrnLength} цифр ОГРН`,
    },
};

export const ogrnip = {
    name: 'ogrnip',
    label: 'ОГРНИП',
    value: '',
    initialValue: '',
    placeholder: '',
    type: 'numeric',
    maxLength: config.common.ogrnipLength,
    errors: {
        required: `Введите ${config.common.ogrnipLength} цифр ОГРНИП`,
        isValid: `Введите ${config.common.ogrnipLength} цифр ОГРНИП`,
    },
};

export const hasIp = {
    name: 'hasIp',
    label: 'Статус ИП',
    hint: `Если у вас есть статус Индивидуального Предпринимателя, то вы сможете инвестировать без ограничения в 600 тыс. руб. в год.`,
    // labelHint: {
    //     text: `Если у вас есть статус Индивидуального Предпринимателя, то вы сможете инвестировать без ограничения в 600 тыс. руб. в год.`,
    //     isWide: true,
    // },
    value: '',
    initialValue: '',
    placeholder: '',
    type: 'checkbox',
    errors: {},
};

export const investmentType = {
    name: 'investmentType',
    label: 'Способ инвестирования',
    type: 'select',
    value: '',
    placeholder: 'Выберите способ',
    options: config.common.investmentTypes.map(x => ({
        text: x.title,
        value: x.id + '',
        code: x.code,
    })),
    errors: {
        required: 'Выберите желаемый способ инвестирования',
    },
};

export const amount = {
    name: 'amount',
    label: 'Сумма инвестиции',
    value: '',
    initialValue: '',
    placeholder: '',
    type: 'currency-float',
    units: '₽',
    errors: {
        required: 'Введите сумму',
        isValidMin: 'Введенное значение ниже инвестиционного порога объекта',
        isValidLimit: 'Превышен лимит в 600 000 руб. для неквалифицированных инвесторов. Чтобы инвестировать без ограничений, подтвердите статус квалифицированного инвестора',
        isValidRest: 'Введенное значение выше недостающей объекту суммы инвестиций',
        isValidBalance: 'На вашем счете недостаточно средств',
    },
};

export const sharesQuantity = {
    name: 'sharesQuantity',
    label: 'Количество акций',
    value: '',
    initialValue: '',
    placeholder: '',
    type: 'numeric',
    units: 'шт.',
};

export const currentYearInvestments = {
    name: 'amount',
    label: '',
    value: '',
    initialValue: '',
    placeholder: `Сумма инвестиций за ${new Date().getFullYear()} год`,
    type: 'currency',
    errors: {
        required: 'Введите сумму',
        numeric: 'Введите число',
        minValue: 'Значение вне допустимого диапазона',
        maxValue: 'Превышен лимит в 600 000 руб. для неквалифицированных инвесторов. Чтобы инвестировать без ограничений, подтвердите статус квалифицированного инвестора',
        isValid: 'Значение вне допустимого диапазона',
        isValidSEInvestments: 'Указанная вами сумма меньше суммы созданных вами инвестиционных заявок в этом году на платформе SimpleEstate',
    },
};

export const password = {
    name: 'password',
    label: 'Пароль',
    type: 'password',
    placeholder: 'Введите пароль',
    value: '',
    errors: {
        required: 'Введите пароль',
    },
};

export const passwordNew = {
    name: 'password',
    label: 'Придумайте надёжный пароль',
    type: 'password',
    placeholder: `Минимум ${config.common.minPasswordLength} символов`,
    autocomplete: 'new-password',
    value: '',
    errors: {
        required: 'Введите пароль',
        isValid: `Минимум ${config.common.minPasswordLength} символов и не менее одной цифры и одной буквы английского алфавита`,
    },
};

export const passwordRepeat = {
    ...password,
    label: 'Повторите пароль',
    placeholder: 'Пароль',
    type: 'password',
    autocomplete: 'new-password',
    errors: {
        required: 'Повторите пароль',
        sameAsPassword: 'Введенные пароли не совпадают',
    },
};

export const passwordCurrent = {
    ...password,
    autocomplete: 'current-password',
    hint: 'recovery',
};

export const policy = {
    name: 'policy',
    label: 'Даю согласие на заморозку указанной суммы на счете на платформе',
    type: 'checkbox',
    value: false,
    errors: {
        isValid: 'Необходимо ваше согласие',
    },
};

export const marketSubscriptionSale = {
    name: 'marketSubscriptionSale',
    label: 'Продажа',
    type: 'checkbox',
    value: false,
    errors: {
        isValid: 'Необходимо ваше согласие',
    },
};

export const marketSubscriptionBuy = {
    name: 'marketSubscriptionBuy',
    label: 'Покупка',
    type: 'checkbox',
    value: false,
    errors: {
        isValid: 'Необходимо ваше согласие',
    },
};

export const annualInvestmentPolicy = {
    name: 'policy',
    label: 'Подтверждаю, что указанная информация является достоверной',
    type: 'checkbox',
    value: false,
    errors: {
        isValid: 'Необходимо подтверждение',
    },
};

export const citizenship = {
    name: 'citizenship',
    label: 'Гражданство',
    type: 'select',
    value: '',
    placeholder: 'Выберите страну',
    errors: {
        required: 'Укажите гражданство',
    },
};

export const withdrawalAmount = {
    name: 'withdrawalAmount',
    label: 'Сумма вывода',
    value: '',
    initialValue: '',
    placeholder: `Н-р 100 000`,
    type: 'currency',
    errors: {
        required: 'Введите сумму',
        numeric: 'Введите число',
        minValue: 'Введите сумму больше нуля',
        maxValue: 'На вашем счете недостаточно средств',
    },
};
