<template>
    <div class="plugin-faq-container">
        <h1 class="plugin-faq-container__title"
            v-text="title"
        />
        <div class="plugin-faq-container__content">
            <div class="plugin-faq-container__content-sections">
                <div class="plugin-faq-container__content-sections-item"
                    v-for="(section, index) in sections"
                    v-bind:key="index"
                    v-bind:class="{ _active: index === activeSectionIndex }"
                    v-on:click="setActiveSectionIndex(index)"
                    v-text="section.title"
                />
            </div>
            <div class="plugin-faq-container__content-questions"
                v-if="activeSection"
                >
                <div class="plugin-faq-container__content-questions-item"
                    v-for="(question, index) in activeSection.questions"
                    v-bind:key="activeSectionIndex + '-' + index"
                    >
                    <plugin-faq-question v-bind:question="question" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'plugin-faq-container',
    props: {
        title: {
            type: String,
            default: 'FAQ',
        },
        sections: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        activeSectionIndex: 0,
    }),
    computed: {
        activeSection() {
            if (this.activeSectionIndex === null) {
                return null;
            }
            return this.sections[this.activeSectionIndex];
        },
    },
    methods: {
        setActiveSectionIndex(newVal) {
            this.activeSectionIndex = newVal;
        },
    },
};
</script>

<style lang="less">
@import '~theme';

.plugin-faq-container {
    &__title {
        .container();
        .container-paddings();
        .typography-extra-large();

        padding-top: 40px;
        padding-bottom: 40px;
        margin-top: 0;
        margin-bottom: 0;

        background-color: @color-gray-lighter;
    }
    &__content {
        .container();
        .container-paddings();

        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding-bottom: 80px;
        &-sections {
            flex: 0 0 auto;
            width: calc(@grid-column + @grid-gap);
            padding-top: 27px;
            &-item {
                .typography-heading();

                position: relative;

                padding: 13px 0;

                color: @color-gray-main;

                cursor: pointer;

                transition: color @duration-fast @easing-default;
                &:hover::after,
                &._active::after {
                    opacity: 1;

                    transform: scaleX(1);
                }
                &._active {
                    color: @color-primary;
                }
                &::after {
                    content: '';

                    position: absolute;
                    bottom: 4px;
                    left: 0;

                    display: block;
                    width: 24px;
                    height: 3px;
                    border-radius: 4px;

                    background-color: currentColor;

                    opacity: 0;

                    transform: scaleX(0);
                    transform-origin: left center;

                    transition: transform @duration-fast @easing-default, opacity @duration-fast @easing-default;
                }
            }
        }
        &-questions {
            flex: 1 1 auto;
            padding: 40px calc(@grid-column * 2 + @grid-gap * 2) 0;
            &-item {
                &:not(:last-child) {
                    margin-bottom: 32px;
                }
            }
        }
    }
    @media @media-lg-down {
        &__content {
            &-questions {
                padding: 40px calc(@grid-column + @grid-gap) 80px;
            }
        }
    }
    @media @media-sm-down {
        &__title {
            .typography-large();

            padding-top: 24px;
            padding-bottom: 24px;
        }
        &__content {
            display: block;
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 60px;
            &-sections {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                overflow: auto;
                padding: 0;
                border-bottom: 1px solid @color-gray-light;
                &-item {
                    flex: 0 0 auto;
                    white-space: nowrap;
                    padding: 16px 8px;
                    &::after {
                        display: none;
                    }
                    &:first-child {
                        padding-left: @container-padding-mobile;
                    }
                    &:last-child {
                        padding-right: @container-padding-mobile;
                    }
                }
            }
            &-questions {
                padding: 0 @container-padding-mobile;
                &-item {
                    margin-top: 24px;
                    &:not(:last-child) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
</style>
