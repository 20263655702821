<template>
    <label class="ui-input-base-checkbox"
        v-bind:class="{
            _active: value,
            _focused: isFocused,
        }"
        v-on:keydown.space.prevent="toggle"
        tabindex="0"
        >
        <input class="ui-input-base-checkbox__input"
            type="checkbox"
            v-bind:checked="value"
            v-bind:autofocus="isAutofocus"
            v-on:focus="focusHandler"
            v-on:blur="blurHandler"
            v-on:change="updateModel($event.target.checked)"
        />
        <div class="ui-input-base-checkbox__check">
            <icon name="check" />
        </div>
    </label>
</template>

<script>
export default {
    name: 'ui-input-base-checkbox',
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        isAutofocus: {
            type: Boolean,
            default: false,
        },
    },
    model: {
        prop: 'value',
        event: 'change',
    },
    data: () => ({
        isFocused: false,
    }),
    methods: {
        toggle() {
            this.$emit('change', !this.value);
        },
        updateModel(newValue) {
            this.$emit('change', newValue);
        },
        focusHandler() {
            this.isFocused = true;
        },
        blurHandler() {
            this.isFocused = false;
        },
    },
};
</script>

<style scoped lang="less">
@import "~theme";

.ui-input-base-checkbox {
    position: relative;
    z-index: 0;

    display: block;
    width: 16px;
    height: 16px;
    border: 1px solid currentColor;
    border-radius: 2px;

    color: @color-gray-main;

    cursor: pointer;

    transition-property: color;
    transition-duration: @duration-fast;
    transition-timing-function: ease-in-out;
    &._active {
        color: @color-gray-darker;
    }
    &__input {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;

        opacity: 0;
        visibility: hidden;
        appearance: none;
    }
    &__check {
        position: absolute;
        top: 2px;
        left: 2px;
        z-index: 2;

        font-size: 1rem;

        opacity: 0;
        transform: translateY(3px) scale(0);

        transition: opacity @duration-fast ease-in-out, transform @duration-fast ease-in-out;
        ._active > & {
            opacity: 1;
            transform: translateY(0) scale(1);
        }
    }
}
</style>
