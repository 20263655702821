import utils from '~/utils';

export const getReferralsItem = data => {
    const fee = data.investment_amount * 0.02;
    return {
        name: [
            data.user.last_name,
            data.user.first_name,
            data.user.second_name,
        ].filter(x => !!x).join(' '),
        status: data.investment_amount > 0 ? 'invested' : data.user.status,
        investment: data.investment_amount,
        investmentFormatted: `${utils.formats.formatNumberWithSpaces(data.investment_amount)} ₽`,
        fee,
        feeFormatted: `${utils.formats.formatNumberWithSpaces(fee)} ₽ (2%)`,
    };
};

export const getReferrals = data => {
    return data.results.map(getReferralsItem);
};
