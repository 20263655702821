<template>
    <section class="page-cabinet-refill">
        <ui-card-base>
            <div class="page-cabinet-refill__container">
                <div class="page-cabinet-refill__title">
                    Реквизиты для пополнения счета инвестора
                </div>
                <div class="page-cabinet-refill__description"
                    v-text="`Для пополнения Вашего счета в SimpleEstate осуществите банковский перевод по реквизитам ниже со своего личного счета (счета родственников или друзей не подходят)`"
                />
                <div class="page-cabinet-refill__warning">
                    <div class="page-cabinet-refill__warning-icon">
                        <icon name="warning" />
                    </div>
                    <div class="page-cabinet-refill__warning-text">
                        <span v-text="`Внимательно указывайте назначение платежа! Это ускорит поступление денег. В случае неправильного заполнения назначения, ваша заявка может быть отклонена.`" />
                        <br />
                        <br />
                        <span v-text="`Срок зачисления денег зависит от банка отправителя. Обычно деньги поступают через 2-3 часа. Переводы на выходных поступают в понедельник утром`" />
                    </div>
                </div>
                <div class="page-cabinet-refill__content">
                    <div class="page-cabinet-refill__content-data">
                        <ui-infolist
                            v-bind:info="items"
                        />
                    </div>
                    <div class="page-cabinet-refill__content-qr">
                        <div class="page-cabinet-refill__content-qr-image"
                            v-if="profile"
                            >
                            <qrcode-vue
                                v-bind:value="qrData"
                                v-bind:size="181"
                                foreground="#000"
                                background="transparent"
                                render-as="svg"
                                level="L"
                            />
                        </div>
                        <div class="page-cabinet-refill__content-qr-caption"
                            v-text="'Чтобы быстрее пополнить счет, воспользуйтесь функцией сканирования QR кода для платежей в мобильном приложении вашего банка'"
                        />
                    </div>
                </div>
                <div class="page-cabinet-refill__copy">
                    <ui-button
                        v-on:click="copy"
                        theme="primary"
                        icon-left="copy"
                        text="Копировать реквизиты"
                    />
                </div>
            </div>
            <transition name="fade">
                <div class="page-cabinet-refill__container-loader"
                    v-if="isLoading"
                    >
                    <div class="page-cabinet-refill__container-loader-container">
                        <ui-loader />
                    </div>
                </div>
            </transition>
        </ui-card-base>
    </section>
</template>

<script>
import QrcodeVue from 'qrcode.vue';

export default {
    name: 'page-cabinet-refill',
    components: {
        QrcodeVue,
    },
    data: () => ({
        isCopyInProgress: false,
    }),
    computed: {
        info() {
            return {
                company: 'ООО "СИМПЛ ЭСТЭЙТ"',
                inn: '7707430579',
                kpp: '772801001',
                account: '40702810001500075724',
                bank: 'ООО "Банк Точка"',
                bik: '044525104',
                city: 'Москва',
                corrAccount: '30101810745374525104',
                purpose: `Пополнение счета ${this.accountCode} на платформе SimpleEstate. НДС не облагается.`,
            };
        },
        items() {
            const result = [
                { title: 'Наименование', value: this.info.company },
                { title: 'ИНН', value: this.info.inn },
                { title: 'КПП', value: this.info.kpp },
                { title: 'Расч/счет', value: this.info.account },
                { title: 'Банк', value: this.info.bank },
                { title: 'БИК', value: this.info.bik },
                { title: 'Город', value: this.info.city },
                { title: 'Корр/счет', value: this.info.corrAccount },
                { title: 'Назначение', value: this.info.purpose, isAccented: true },
            ];
            result.forEach(x => {
                x.isCopyAllowed = true;
            });
            return result;
        },
        qrData() {
            // <qrcode-vue value='ST00011|Name=ООО "Три кита"|PersonalAcc=40702810138250123017|BankName=ОАО "БАНК"|BIC=044525225|CorrespAcc=30101810400000000225|PayeelNN=6200098765|LastName=Иванов|FirstName=Иван|MiddleName=Иванович|Purpose=Оплата членского взноса|PayerAddress=г.Рязань ул.Ленина д.10 кв.15' size="300" foreground="#6737AF" background="transparent" level="L" />
            // return `ST00011|Name=${this.info.company}|PersonalAcc=${this.info.account}|BankName=${this.info.bank}|BIC=${this.info.bik}|CorrespAcc=${this.info.corrAccount}|PayeelNN=${this.info.inn}|LastName=Иванов|FirstName=Иван|MiddleName=Иванович|Purpose=${this.info.purpose}|PayerAddress=г.Рязань ул.Ленина д.10 кв.15`;
            const separator = '|';
            const result = [
                'ST00011',
                `Name=${this.info.company}`,
                `PersonalAcc=${this.info.account}`,
                `BankName=${this.info.bank}`,
                `BIC=${this.info.bik}`,
                `CorrespAcc=${this.info.corrAccount}`,
                `PayeeINN=${this.info.inn}`,
                `Purpose=${this.info.purpose}`,
                `KPP=${this.info.kpp}`,
            ];
            if (this.profile?.lastName) {
                result.push(`LastName=${this.profile.lastName}`);
            }
            if (this.profile?.firstName) {
                result.push(`FirstName=${this.profile.firstName}`);
            }
            if (this.profile?.secondName) {
                result.push(`MiddleName=${this.profile.secondName}`);
            }
            return result.join(separator);
        },
        isLoading() {
            return this.$store.state.users.getUserProfileIsLoading || this.isCopyInProgress;
        },
        profile() {
            return this.$store.state.users.getUserProfileResult;
        },
        accountCode() {
            if (!this.profile) {
                return '';
            }
            return this.profile.accountCode;
        },
    },
    methods: {
        copy() {
            this.isCopyInProgress = true;
            const textToCopy = this.items.map(x => `${x.title}: ${x.value}`).join('\r\n');
            navigator.clipboard.writeText(textToCopy)
                .then(() => {
                    setTimeout(() => {
                        this.isCopyInProgress = false;
                    }, 500);
                });
        },
    },
    mounted() {
        this.$store.dispatch('users/getUserProfile');
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-refill {
    width: 100%;
    max-width: 898px;
    padding: 20px 0;
    &__container {
        position: relative;

        padding: 8px 8px 16px;
        &-loader {
            .transition-fade();

            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;

            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;

            background-color: fade(@color-gray-lightest, 50%);
            &-container {
                width: 100px;
                margin: auto;
            }
        }
    }
    &__title {
        .typography-large();

        margin-bottom: 20px;
    }
    &__description {
        .typography-body();

        margin-bottom: 16px;
    }
    &__warning {
        .typography-heading();

        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 16px;
        margin-bottom: 40px;
        border-radius: @border-radius-lg;

        color: @color-primary;

        background-color: @color-secondary;
        &-icon {
            flex: 0 0 auto;
            margin-right: 12px;

            font-size: 2rem;
        }
    }
    &__content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 16px;
        &-data {
            flex: 1 1 auto;
        }
        &-qr {
            flex: 0 0 auto;
            width: 261px;
            margin-left: 40px;
            &-image {
                padding: 40px;
                margin-bottom: 8px;
                & /deep/ svg {
                    display: block;
                    width: 100%;
                    margin: 0 auto;
                }
            }
            &-caption {
                .typography-caption();

                text-align: center;
            }
        }
    }
    &__copy {
        width: fit-content;
    }
    @media @media-md-down {
        &__container {
            padding: 0 0 8px;
        }
        &__title {
            .typography-heading();

            margin-bottom: 16px;
        }
        &__description {
            margin-bottom: 8px;
        }
        &__warning {
            padding: 12px;
            margin-bottom: 32px;
        }
        &__content {
            flex-direction: column-reverse;
            align-items: stretch;
            &-qr {
                margin: 0 auto 32px;
                &-image {
                    padding: 0 24px 24px;
                }
            }
        }
    }
    @media @media-xs-down {
        &__copy {
            width: 100%;
        }
    }
}
</style>
