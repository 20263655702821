<template>
    <div class="plugin-referral-call-to-action">
        <div class="plugin-referral-call-to-action__info">
            <div class="plugin-referral-call-to-action__info__title"
                 v-if="title"
                 v-html="title"
            />
            <div class="plugin-referral-call-to-action__info__description"
                 v-if="description"
                 v-html="description"
            />
            <div class="plugin-referral-call-to-action__info__button">
                <ui-button v-bind:href="buttonLink" theme="landing-inverted" text="Пригласить клиента" />
            </div>
        </div>
    </div>
</template>

<script>
import config from '~/config';

export default {
    name: 'plugin-referral-call-to-action',
    props: {
        title: {
            type: String,
            default: null,
        },
        description: {
            type: String,
            default: null,
        },
    },
    computed: {
        buttonLink() {
            return this.$store.getters['users/isAuthorized']
                ? config.urls.cabinet.referral
                : config.urls.auth.registration;
        },
    },
};
</script>

<style lang="less">
@import '~theme';

.plugin-referral-call-to-action {
    display: grid;
    grid-template-columns: 1.25fr .75fr;
    grid-gap: 64px;

    margin: 160px -115px 0;
    padding: 115px 120px;

    background: @color-primary url(/static/images/referral/call-to-action/building.png) no-repeat bottom right;
    background-size: auto 101%;
    border-radius: 15px;

    &__info {
        &__title {
            .typography-landing-heading();

            margin-bottom: 30px;
            max-width: 525px;
        }

        &__description {
            .typography-small();

            color: @color-gray-lightest;

            max-width: 435px;
            margin-bottom: 50px;

            p {
                margin: 0;
            }
        }

        &__button {
            max-width: 204px;
        }
    }

    @media @media-md-down {
        grid-template-columns: 1fr;
        grid-template-rows: 1.5fr .5fr;

        margin: 0 -35px;
        padding: 40px 35px;

        &__info {
            margin: auto;
            text-align: center;

            &__title {
                .typography-landing-small-heading();

                max-width: 350px;
                margin: 0 auto 30px;
            }

            &__description {
                margin: 0 auto 50px;
            }

            &__button {
                margin: auto;
            }
        }
    }

    @media @media-sm-down {
        margin: 0 -24px;
        background: @color-primary url(/static/images/referral/call-to-action/building-min.png) no-repeat bottom right;
        background-size: auto 55%;
    }
}
</style>
