<template>
    <div class="page-market-item-orders-cards">
        <div class="page-market-item-orders-cards__item"
            v-for="(item, index) in items"
            v-bind:key="index"
            >
            <page-market-item-orders-cards-item
                v-bind:item="item"
                v-bind:is-user-shareless="isUserShareless"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'page-market-item-orders-cards',
    props: {
        items: {
            type: Array,
        },
        isUserShareless: {
            type: Boolean,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-market-item-orders-cards {
    display: flex;
    align-content: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: -6px;
    &__item {
        padding: 6px;
        width: 33.33%;
        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }
    @media @media-sm-down {
        &__item {
            width: 50%;
        }
    }
    @media @media-xs-down {
        &__item {
            width: 100%;
        }
    }
}
</style>
