<template>
    <div class="common-footer-documents">
        <div class="common-footer-documents__item"
            v-for="(document, i) in documents"
            v-bind:key="i"
            >
            <a class="common-footer-documents__item-link"
                v-bind:href="document.url"
                v-text="document.title"
                v-bind:target="document.target || '_blank'"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'common-footer-documents',
    props: {
        documents: {
            type: Array,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.common-footer-documents {
    .typography-body();
    &__item {
        margin-top: 16px;
    }
    @media @media-md-down {
    }
}
</style>
