<template>
    <section class="page-cabinet-qualification">
        <div class="page-cabinet-qualification__content">
            <ui-card-base>
                <div class="page-cabinet-qualification__content-container">
                    <div class="page-cabinet-qualification__content-title"
                        v-text="'Подтверждение статуса квалифицированного инвестора'"
                    />
                    <div class="page-cabinet-qualification__content-description"
                        v-text="'Пожалуйста, укажите пункты, которым Вы соответствуете и прикрепите подтверждающий документ.'"
                    />
                    <div class="page-cabinet-qualification__content-subtitle"
                        v-text="'Выберите хотя бы один из вариантов:'"
                    />
                    <div class="page-cabinet-qualification__content-form">
                        <transition name="fade">
                            <div class="page-cabinet-qualification__content-form-loader"
                                v-if="isLoading"
                                >
                                <div class="page-cabinet-qualification__content-form-loader-container">
                                    <ui-loader />
                                </div>
                            </div>
                        </transition>
                        <div class="page-cabinet-qualification__content-form-list">
                            <div class="page-cabinet-qualification__content-form-list-item"
                                v-for="(variant, i) in variants"
                                v-bind:key="variant.id"
                                >
                                <ui-checkbox-with-text
                                    v-bind:text="variant.text"
                                    v-bind:checked="variant.isChecked"
                                    v-on:change="updateVariant(i)"
                                />
                                <div class="page-cabinet-qualification__content-form-list-item-description"
                                    v-if="variant.description && variant.isChecked"
                                    v-html="variant.description"
                                />
                                <div class="page-cabinet-qualification__content-form-list-item-files"
                                    v-if="variant.isAttachmentRequired && variant.isChecked"
                                    >
                                    <page-cabinet-qualification-files
                                        v-model="variant.files"
                                        v-bind:variant-id="variant.id"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="page-cabinet-qualification__content-form-buttons">
                            <div class="page-cabinet-qualification__content-form-buttons-item">
                                <ui-button
                                    v-on:click="submitHandler"
                                    v-bind:disabled="!isValid || isLoading"
                                    theme="primary"
                                    size="lg"
                                    >
                                    Готово
                                </ui-button>
                            </div>
                        </div>
                    </div>
                </div>
            </ui-card-base>
        </div>
    </section>
</template>

<script>
import config from '~/config';
import { mainClient } from '~/network';

export default {
    name: 'page-cabinet-qualification',
    data: () => ({
        inputTimeout: null,
        isLoading: false,
        isChanged: false,
        variants: [],
    }),
    computed: {
        documents() {
            return this.$store.getters['qualification/files'];
        },
        isValid() {
            return this.variants.some(v => v.isChecked) &&
                this.variants.every(v => {
                    if (v.isChecked) {
                        return v.isAttachmentRequired
                            ? this.documents[v.id] !== undefined && this.documents[v.id].length > 0
                            : true;
                    } else {
                        return true;
                    }
                });
        },
    },
    methods: {
        async getData() {
            if (this.isLoading) {
                return;
            }
            this.isLoading = true;

            let result = [];
            const variantsRequest = await mainClient.qualification.requests.getVariants();
            if (variantsRequest.error) {
                console.error(variantsRequest.error, variantsRequest.status);
                console.error('Error above relates to component page-cabinet-qualification getVariants');
            } else {
                result = variantsRequest.data.items;
                result.forEach(x => {
                    x.isChecked = false;
                    if (x.isAttachmentRequired) {
                        x.files = [ null ];
                    }
                });
            }

            this.variants = result;
            this.isLoading = false;
        },
        updateVariant(index) {
            const variant = this.variants[index];
            variant.isChecked = !variant.isChecked;
            if (variant.isChecked) {
                if (!variant.isPositive) {
                    this.variants.forEach(x => { if (x.isPositive) x.isChecked = false; });
                } else {
                    this.variants.forEach(x => { if (!x.isPositive) x.isChecked = false; });
                }
            }
        },
        async submitHandler() {
            if (this.isLoading || !this.isValid) {
                return;
            }
            this.isLoading = true;
            const selected = this.variants.filter(v => v.isChecked);
            const payload = {
                variants: selected.map(v => v.id),
                documents: [],
            };
            selected.forEach(v => {
                if (v.isAttachmentRequired) { payload.documents = payload.documents.concat(this.documents[v.id]); }
            });
            const { error, status } = await mainClient.qualification.requests.apply(payload);
            if (error) {
                console.error(error, status);
                console.error('Error above relates to component page-cabinet-verification-questionary submitHandler');
            } else {
                if (selected.some(x => !x.isPositive)) {
                    location.href = config.urls.cabinet.qualificationFailure;
                } else {
                    location.href = config.urls.cabinet.qualificationSuccess;
                }
            }
            this.isLoading = false;
        },
    },
    mounted() {
        this.getData();
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-qualification {
    width: 100%;
    max-width: 898px;
    margin: 20px auto 0;
    &__content {
        &-container {
            padding: 8px 32px 24px 8px;
        }
        &-title {
            .typography-medium();

            margin-bottom: 16px;
        }
        &-description {
            .typography-body();

            margin-bottom: 24px;
        }
        &-subtitle {
            .typography-heading();

            margin-bottom: 24px;
        }
        &-form {
            position: relative;
            &-loader {
                .transition-fade();

                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;

                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;

                background-color: fade(@color-gray-lightest, 50%);
                &-container {
                    width: 100px;
                    margin: auto;
                }
            }
            &-list {
                margin-bottom: 40px;
                &-item {
                    margin-bottom: 16px;
                    &:after {
                        content: '';

                        display: block;
                        height: 1px;
                        margin: 16px 0 0 24px;

                        background-color: @color-gray-light;
                    }
                    &-description {
                        padding: 16px 24px 16px 21px;
                        border-left: 3px solid @color-gray-darkest;
                        border-radius: 0 @border-radius-lg @border-radius-lg 0;
                        margin-top: 16px;
                        margin-left: 24px;

                        background-color: @color-gray-light;
                        /deep/ * {
                            .typography-body();

                            margin: 0;
                        }
                        /deep/ b,
                        /deep/ strong {
                            .typography-heading();
                        }
                    }
                    &-files {
                        padding-left: 24px;
                        margin-top: 16px;
                    }
                }
            }
            &-buttons {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: -12px;
                &-item {
                    width: 100%;
                    max-width: 290px;
                    padding: 12px;
                }
            }
        }
    }
    @media @media-xl-down {
        margin-left: 0;
    }
    @media @media-md-down {
        &__content {
            &-container {
                padding: 0 0 16px;
            }
        }
    }
    @media @media-xs-down {
        &__content {
            &-form {
                &-list {
                    &-item {
                        &-description {
                            margin-left: 0;
                        }
                        &-files {
                            padding-left: 0;
                        }
                    }
                }
            }
        }
    }
}
</style>
