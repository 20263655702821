<template>
    <div class="page-cabinet-dashboard-income-info"
        v-bind:class="{
                _accented: isAccented,
            }"
        >
        <div class="page-cabinet-dashboard-income-info-item"
            v-text="title"
        />
        <div class="page-cabinet-dashboard-income-info-item"
            v-for="(column, index) in columns"
            v-bind:key="index"
            >
            <span class="page-cabinet-dashboard-income-info-item-title"
                v-text="column.title + ':'"
            />
            <span class="page-cabinet-dashboard-income-info-item-value"
                v-text="getFormattedValue(data[column.key])"
            />
        </div>
    </div>
</template>

<script>
import utils from '~/utils';

export default {
    name: 'page-cabinet-dashboard-income-info',
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
        title: {
            type: String,
            default: '',
        },
        mode: {
            type: String,
            default: '',
        },
        columns: {
            type: Array,
            default: () => [{}],
        },
        isAccented: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        currency() {
            return this.$store.getters['site/currency'];
        },
    },
    methods: {
        getFormattedValue(value) {
            if (this.mode === 'absolute') {
                return `${utils.formats.formatNumberWithSpaces(value)} ${this.currency.name.short}`;
            }
            return `${value.toFixed(2)}%`;
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-dashboard-income-info {
    .typography-body();

    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    width: 100%;
    &._accented {
        .typography-caption();
    }
    &._accented & {
        &-item:first-child,
        &-item-value {
            font-weight: 700;
            text-transform: uppercase;
        }
    }
    &-item {
        flex: 0 0 25%;
        &:first-child {
            flex: 0 0 50%;
        }
        &-title {
            display: none;
        }
    }
    & ~ & {
        margin-top: 14px;
    }
    @media @media-md-down {
        display: block;
        &-item {
            &:first-child {
                .typography-caption();

                font-weight: 700;
                text-transform: uppercase;
            }
            &:nth-child(2) {
                margin-top: 12px;
            }
            &:nth-child(2) ~ & {
                padding-right: 0;
                margin-top: 6px;
            }
            &-title {
                .typography-body();

                display: inline-block;
                margin-right: 4px;

                color: @color-gray-main;
            }
            &-value {
                display: inline-block;
            }
        }
    }
}
</style>
