var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ui-card-base--wrapper"},[(_vm.isVisible)?_c('article',{staticClass:"ui-card-base",class:{
            _accented: _vm.isAccented,
            _shadowed: _vm.withShadow,
            _bordered: _vm.withBorder,
        },style:({
            paddingTop: _vm.paddingTop,
            paddingRight: _vm.paddingRight,
            paddingBottom: _vm.paddingBottom,
            paddingLeft: _vm.paddingLeft,
        })},[_vm._t("default")],2):_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }