<template>
    <div class="plugin-about-numbers-item">
        <svg class="plugin-about-numbers-item__check" width="192" height="201" viewBox="0 0 192 201" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.9" filter="url(#filter0_d)">
                <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="20" y="9" width="152" height="152">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M29.8699 85C29.8699 48.4855 59.4717 18.8848 95.9856 18.8848C112.467 18.8848 127.354 25.135 138.932 35.1137L145.872 28.3676C132.556 16.6227 115.13 9.44446 95.9832 9.44446C54.2628 9.44446 20.4443 43.2671 20.4443 85C20.4443 126.733 54.2652 160.556 95.9832 160.556C137.706 160.556 171.524 126.733 171.524 85C171.524 80.686 171.08 76.49 170.388 72.372L161.681 80.8348C161.718 81.4299 161.799 82.0127 161.881 82.5956C161.991 83.3865 162.101 84.1776 162.101 85C162.101 121.517 132.499 151.111 95.9856 151.111C59.4717 151.111 29.8699 121.517 29.8699 85ZM104.243 111.283L170.188 45.7064C172.022 43.8859 172.022 40.9272 170.191 39.1067C168.358 37.2838 165.386 37.2838 163.553 39.1067L100.617 101.687L75.6922 76.8962C73.8637 75.0781 70.8991 75.0781 69.073 76.8962C67.247 78.7191 67.247 81.666 69.073 83.4794L96.9669 111.219C97.0239 111.289 97.0423 111.372 97.0606 111.455C97.081 111.548 97.1013 111.64 97.1748 111.711C98.1363 112.669 99.393 113.064 100.652 113.019C101.888 113.049 103.128 112.639 104.073 111.699C104.132 111.638 104.149 111.561 104.166 111.484C104.181 111.414 104.196 111.344 104.243 111.283Z" fill="black"/>
                </mask>
                <g mask="url(#mask0)">
                    <rect x="11" width="170" height="170" fill="#F0EBF7"/>
                </g>
            </g>
            <defs>
                <filter id="filter0_d" x="-9" y="0" width="210" height="210" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                    <feOffset dy="20"/>
                    <feGaussianBlur stdDeviation="10"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.403922 0 0 0 0 0.215686 0 0 0 0 0.686275 0 0 0 0.11 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                </filter>
            </defs>
        </svg>
        <div class="plugin-about-numbers-item__title"
            v-text="title"
        />
        <div class="plugin-about-numbers-item__text"
            v-text="text"
        />
    </div>
</template>

<script>
export default {
    name: 'plugin-about-numbers-item',
    props: {
        title: {
            type: String,
            default: '',
        },
        text: {
            type: String,
            default: '',
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-about-numbers-item {
    flex: 1 1 auto;
    width: 100%;
    padding: 12px;

    text-align: center;
    &__check {
        display: block;
        width: 170px;
        height: 170px;
        margin: 0 auto -90px;
    }
    &__title {
        margin-bottom: 8px;

        font-size: 7.2rem;
        font-weight: bold;
        line-height: 9rem;
    }
    &__text {
        .typography-small();
    }
    @media @media-sm-down {
        padding: 0;
        &:not(:last-child) {
            padding-bottom: 24px;
        }
        &__check {
            width: 90px;
            height: 90px;
            margin-bottom: -30px;
        }
        &__title {
            .typography-extra-large();
            margin-bottom: 8px;
        }
    }
}
</style>
