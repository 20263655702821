<template>
    <article class="page-cabinet-dashboard-highlights">
        <div class="page-cabinet-dashboard-highlights__switchers">
            <div class="page-cabinet-dashboard-highlights__switchers-item"
                v-for="(switcher, index) in modes"
                v-bind:key="index"
                v-bind:class="{ _active: switcher.isActive }"
                v-on:click="setMode(index)"
                v-text="switcher.title"
            />
        </div>
        <div class="page-cabinet-dashboard-highlights__blocks">
            <div class="page-cabinet-dashboard-highlights__blocks-item">
                <page-cabinet-dashboard-highlights-card
                    v-bind="investments"
                />
            </div>
            <div class="page-cabinet-dashboard-highlights__blocks-item">
                <page-cabinet-dashboard-highlights-card
                    v-bind="income"
                />
            </div>
            <div class="page-cabinet-dashboard-highlights__blocks-item">
                <page-cabinet-dashboard-highlights-card
                    v-bind="estimatedValue"
                />
            </div>
            <div class="page-cabinet-dashboard-highlights__blocks-item">
                <page-cabinet-dashboard-highlights-card
                    v-bind="dividends"
                />
            </div>
            <div class="page-cabinet-dashboard-highlights__blocks-item _small">
                <page-cabinet-dashboard-highlights-card
                    v-bind="irr"
                />
            </div>
            <div class="page-cabinet-dashboard-highlights__blocks-item _small">
                <page-cabinet-dashboard-highlights-card
                    v-bind="mm"
                />
            </div>
        </div>
    </article>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';

export default {
    name: 'page-cabinet-dashboard-highlights',
    data: () => {
        const dateFormat = 'YYYY-MM-DD';
        return {
            modes: [
                {
                    title: 'Квартал',
                    isActive: false,
                    params: {
                        date_from: moment().subtract(1, 'quarters').format(dateFormat),
                        date_to: moment().format(dateFormat),
                    },
                },
                {
                    title: 'Полгода',
                    isActive: false,
                    params: {
                        date_from: moment().subtract(6, 'months').format(dateFormat),
                        date_to: moment().format(dateFormat),
                    },
                },
                {
                    title: 'Год',
                    isActive: false,
                    params: {
                        date_from: moment().subtract(1, 'years').format(dateFormat),
                        date_to: moment().format(dateFormat),
                    },
                },
                {
                    title: 'Все время',
                    isActive: true,
                    params: {
                    },
                },
            ],
            investments: {
                isAccented: true,
                title: 'Инвестиции',
                value: 0,
                units: 'руб',
            },
            income: {
                title: 'Чистый доход',
                value: 0,
                units: 'руб',
                delta: 0,
            },
            estimatedValue: {
                title: 'Текущая стоимость портфеля',
                value: 0,
                units: 'руб',
                delta: 0,
            },
            dividends: {
                title: 'Дивиденды',
                value: 0,
                units: 'руб',
            },
            irr: {
                title: 'Доходность (IRR)',
                value: '20',
                units: '%',
            },
            mm: {
                title: 'Доходность (MM)',
                value: '1.2',
                units: 'x',
            },
        };
    },
    computed: {
        ...mapState('dashboard', [
            'getHighlightsResult',
            'getHighlightsError',
            'getHighlightsIsLoading',
        ]),
        activeMode() {
            return this.modes.find(x => x.isActive);
        },
    },
    methods: {
        setMode(index) {
            const modes = this.modes;
            const active = modes.find(mode => mode.isActive);
            if (active) {
                active.isActive = false;
            }
            modes[index].isActive = true;
            this.modes = modes;
        },
    },
    watch: {
        getHighlightsResult(newVal) {
            this.$set(this.investments, 'value', newVal.investments.toFixed(0));

            this.$set(this.income, 'value', newVal.income[1].toFixed(0));
            this.$set(this.income, 'delta', newVal.income[3]);

            this.$set(this.dividends, 'value', (newVal.dividends[1] - newVal.dividends[0]).toFixed(0));

            this.$set(this.estimatedValue, 'value', newVal.portfolio[1].toFixed(0));
            this.$set(this.estimatedValue, 'delta', newVal.portfolio[3]);

            this.$set(this.irr, 'value', (newVal.irr * 100).toFixed(1));

            this.$set(this.mm, 'value', newVal.mm.toFixed(2));
        },
        activeMode() {
            this.$store.dispatch('dashboard/getHighlights', this.activeMode?.params || {});
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-dashboard-highlights {
    padding: 10px 0 24px;
    &__switchers {
        .typography-caption();

        display: flex;
        align-items: baseline;
        justify-content: flex-end;
        width: ~'calc(100% + 24px)';
        margin: 0 -12px;
        &-item {
            padding: 10px 12px;

            color: @color-gray-main;

            cursor: pointer;

            transition: color @duration-fast ease-in-out;
            &._active {
                color: @color-gray-darker;
            }
        }
    }
    &__blocks {
        display: flex;
        flex-wrap: wrap;
        align-content: stretch;
        justify-content: flex-start;
        width: ~'calc(100% + 18px)';
        margin: 0 -9px;
        &-item {
            width: ~'calc(100% / 3 - 18px)';
            margin: 0 9px 16px;
        }
    }
    @media @media-md-down {
        &__switchers {
            justify-content: flex-start;
        }
        &__blocks {
            width: ~'calc(100% + 8px)';
            margin: 0 -4px;
            &-item {
                width: ~'calc(100% - 8px)';
                margin: 0 4px 8px;

                &._small {
                    width: ~'calc(50% - 8px)';
                }
            }
        }
    }
}
</style>
