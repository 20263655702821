<template>
    <div class="page-cabinet-verification-check">
        <transition name="fade">
            <div class="page-cabinet-verification-check__loader"
                v-if="isLoading"
                >
                <div class="page-cabinet-verification-check__loader-container">
                    <ui-loader />
                </div>
            </div>
        </transition>
        <div class="page-cabinet-verification-check__list">
            <ui-infolist
                v-bind:info="info"
            />
        </div>
        <div class="page-cabinet-verification-check__buttons">
            <div class="page-cabinet-verification-check__buttons-item"
                v-on:click="goToStart"
                >
                <ui-button
                    v-bind:disabled="isLoading"
                    theme="secondary-1"
                    size="lg"
                    >
                    Редактировать
                </ui-button>
            </div>
            <div class="page-cabinet-verification-check__buttons-item"
                v-on:click="submitHandler"
                >
                <ui-button
                    v-bind:disabled="isLoading"
                    theme="primary"
                    size="lg"
                    >
                    Все верно
                </ui-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'page-cabinet-verification-check',
    computed: {
        ...mapState('users', [
            'getUserProfileResult',
            'getUserProfileIsLoading',

            'getUserJuridicalInfoResult',
            'getUserJuridicalInfoIsLoading',

            'getUserPersonalInfoResult',
            'getUserPersonalInfoIsLoading',
        ]),
        isLoading() {
            return this.getUserProfileIsLoading ||
                this.getUserJuridicalInfoIsLoading ||
                this.getUserPersonalInfoIsLoading;
        },
        info() {
            const result = [];
            if (this.getUserProfileResult) {
                result.push({
                    title: 'ФИО',
                    value: this.getUserProfileResult.fullName,
                });
                result.push({
                    title: 'Телефон',
                    value: this.getUserProfileResult.phone,
                });
            }
            if (this.getUserJuridicalInfoResult) {
                result.push({
                    title: 'ИНН',
                    value: this.getUserJuridicalInfoResult.inn,
                });
            }
            if (this.getUserPersonalInfoResult) {
                result.push({
                    title: 'Паспорт',
                    value: `${this.getUserPersonalInfoResult.passportSeries} ${this.getUserPersonalInfoResult.passportNumber}`,
                });
            }
            return result;
        },
    },
    methods: {
        submitHandler() {
            if (this.isLoading) {
                return;
            }
            this.$emit('filled', 'check');
            this.$emit('submitted');
        },
        goToStart() {
            this.$emit('update-step', 'type');
        },
    },
    beforeMount() {
        this.$store.dispatch('users/getUserProfile');
        this.$store.dispatch('users/getUserPersonalInfo');
        this.$store.dispatch('users/getUserJuridicalInfo');
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-verification-check {
    position: relative;
    &__loader {
        .transition-fade();

        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        background-color: fade(@color-gray-lightest, 50%);
        &-container {
            width: 100px;
            margin: auto;
        }
    }
    &__list {
        min-height: 144px;
        margin-bottom: 40px;
    }
    &__buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: -12px;
        &-item {
            flex: 0 0 auto;
            padding: 12px;
        }
    }
    @media @media-md-down {
        &__buttons {
            flex-direction: column;
            align-items: stretch;
            &-item {
                width: 100%;
            }
        }
    }
}
</style>
