const state = {
    contacts: {
        phone: {
            value: '+74951490500',
            valueFormatted: '+7 (495) 149-05-00',
            href: 'tel:+74951490500',
        },
        email: {
            value: 'info@simpleestate.ru',
            href: 'mailto:info@simpleestate.ru',
        },
        socials: {
            // facebook: {
            //     title: 'facebook',
            //     icon: 'facebook',
            //     href: 'https://www.facebook.com/SimpleEstate-622180241751024',
            // },
            // instagram: {
            //     title: 'instagram',
            //     icon: 'instagram',
            //     href: 'https://www.instagram.com/simpleestate.ru',
            // },
            facebook: {
                title: 'f',
                icon: 'telegram',
                href: 'https://t.me/simpleestate',
            },
            instagram: {
                title: 'i',
                icon: 'telegram',
                href: 'https://t.me/simpleestate',
            },
            telegram: {
                title: 'telegram',
                icon: 'telegram',
                href: 'https://t.me/simpleestate',
            },
            youtube: {
                title: 'youtube',
                icon: 'youtube',
                href: 'https://youtube.com/@simpleestate?si=EH6CUQaLOVJU0S0W',
            },
        },
    },
    currency: {
        name: {
            short: 'руб.',
        },
        symbol: '₽',
    },
    company: {
        tin: 7707430579, // ИНН
        type: 'ООО',
        name: 'СИМПЛ ЭСТЭЙТ',
        ogrn: '1197746322289', // ОГРН
        jur_address: '117342, город Москва, улица Бутлерова, дом 17, эт/комн 5/248', // юр адрес
    },
};

const getters = {
    site(state) {
        return state;
    },
    contacts(state) {
        return state.contacts;
    },
    currency(state) {
        return state.currency;
    },
    company(state) {
        return state.company;
    },
};

const actions = {
};

const mutations = {
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
