<template>
    <article class="page-cabinet-estates-list-table-item">
        <div class="page-cabinet-estates-list-table-item__main">
            <div class="page-cabinet-estates-list-table-item__main-info">
                <div class="page-cabinet-estates-list-table-item__main-info-image">
                    <common-image
                        v-bind:image="image"
                        v-bind:href="item.url"
                    />
                </div>
                <div class="page-cabinet-estates-list-table-item__main-info-title"
                    v-text="item.name"
                />
            </div>
            <div class="page-cabinet-estates-list-table-item__main-rows">
                <div class="page-cabinet-estates-list-table-item__main-rows-item"
                    v-for="(share, i) in this.item.shares"
                    v-bind:key="i"
                    >
                    <page-cabinet-estates-list-table-item-row
                        v-bind:item="share"
                        v-bind:fields="fields"
                    />
                </div>
            </div>
        </div>
        <div class="page-cabinet-estates-list-table-item__additional">
            <div class="page-cabinet-estates-list-table-item__additional-stats">
                <div class="page-cabinet-estates-list-table-item__additional-stats-item"
                    v-for="(stat, i) in stats"
                    v-bind:key="i"
                    >
                    <div class="page-cabinet-estates-list-table-item__additional-stats-item-title"
                        v-text="stat.title + ':'"
                    />
                    <div class="page-cabinet-estates-list-table-item__additional-stats-item-value"
                        v-text="stat.value"
                    />
                    <div class="page-cabinet-estates-list-table-item__additional-stats-item-delta"
                        v-if="stat.delta"
                        v-bind:class="{ _negative: stat.delta < 0 }"
                        v-text="stat.delta + '%'"
                    />
                </div>
            </div>
            <div class="page-cabinet-estates-list-table-item__additional-actions"
                v-if="isSaleAvailable"
                >
                <div class="page-cabinet-estates-list-table-item__additional-actions-sale">
                    <ui-button
                        v-on:click="saleHandler"
                        text="Выставить на продажу"
                        theme="secondary-1"
                    />
                </div>
            </div>
        </div>
    </article>
</template>

<script>
import utils from '~/utils';

export default {
    name: 'page-cabinet-estates-list-table-item',
    props: {
        item: {
            type: Object,
        },
        fields: {
            type: Array,
        },
    },
    computed: {
        image() {
            return utils.common.injectResize(this.item.image, '96x96');
        },
        stats() {
            const result = [
                {
                    title: 'Рыночная стоимость акции',
                    value: this.item.marketPriceFormatted,
                    delta: null,
                },
            ];
            if (this.item.dividends > 0) {
                result.push({
                    title: 'Выплачено дивидендов',
                    value: utils.formats.formatNumberWithSpaces(this.item.dividends),
                    delta: null,
                });
            }
            return result;
        },
        isSaleAvailable() {
            return this.item.quantity > 0 && this.item.idForSale !== null;
        },
    },
    methods: {
        saleHandler() {
            this.$store.commit('modals/push', {
                name: 'market-application-create',
                props: {
                    givenShareId: this.item.idForSale,
                },
            });
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-estates-list-table-item {
    padding: 24px 24px 16px;
    border-radius: @border-radius-lg;

    background-color: @color-gray-lightest;
    &__main {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &-info {
            flex: 0 0 auto;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 175px;
            padding-right: 16px;
            &-image {
                flex: 0 0 auto;
                width: 48px;
                height: 48px;
                margin-right: 16px;
            }
            &-title {
                .typography-heading();

                flex: 0 0 auto;
                width: 109px;
            }
        }
        &-rows {
            .typography-body();

            flex: 1 1 100%;
        }
    }
    &__additional {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-top: 16px;
        border-top: 1px solid @color-gray-light;
        margin-top: 16px;
        &-stats {
            flex: 1 1 auto;
            &-item {
                .typography-body();

                display: flex;
                align-items: baseline;
                justify-content: flex-start;
                &:not(:last-child) {
                    margin-bottom: 4px;
                }
                &-title {
                    color: @color-gray-main;
                    margin-right: 4px;
                }
                &-value {
                    margin-right: 4px;
                }
                &-delta {
                    .typography-caption();

                    padding: 3px 4px 1px;
                    margin-bottom: 4px;
                    border-radius: @border-radius-lg;

                    color: @color-positive;
                    font-weight: 700;
                    text-transform: uppercase;

                    background-color: fade(@color-positive, 10%);
                    &._negative {
                        color: @color-accent-negative;

                        background-color: fade(@color-accent-negative, 10%);
                    }
                }
            }
        }
        &-actions {
            flex: 0 0 auto;
        }
    }
    @media @media-lg-down {
        &__main {
            &-info {
                width: 145px;
                &-title {
                    width: 79px;
                }
            }
        }
    }
}
</style>
