<template>
    <a class="plugin-about-media-item swiper-slide"
        v-bind:href="url"
        target="_blank"
        >
        <div class="plugin-about-media-item__image swiper-lazy"
            v-bind:data-background="resizedImage"
            >
        </div>
        <div class="plugin-about-media-item__loader">
            <ui-loader
                v-bind:fixed="50"
                v-bind:centered="true"
                >
            </ui-loader>
        </div>
    </a>
</template>

<script>
import utils from '~/utils';

export default {
    name: 'plugin-about-media-item',
    props: {
        image: {
            type: String,
            default: '',
        },
        url: {
            type: String,
            default: '',
        },
    },
    computed: {
        resizedImage() {
            return utils.common.injectResize(this.image, '316xAUTO');
        },
    },
};
</script>

<style lang="less">
@import '~theme';

.plugin-about-media-item {
    position: relative;

    display: block;
    width: 206px;
    height: 138px;
    padding: 24px;
    border-radius: @border-radius-lg;
    border: 1px solid @color-gray-light;

    text-decoration: none;
    &__image {
        width: 100%;
        height: 100%;

        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    &__loader {
        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);

        transition: opacity @duration-fast ease-in-out;
        .swiper-lazy-loaded + & {
            opacity: 0;
            pointer-events: none;
        }
    }
    @media @media-md-down {
        width: 152px;
        height: 100px;
        padding: 16px;
    }
}
</style>
