<template>
    <div class="modal-cancel-expired-investment-requests">
        <transition name="fade">
            <div class="modal-cancel-expired-investment-requests__loader"
                v-if="isLoading"
                >
                <div class="modal-cancel-expired-investment-requests__loader-container">
                    <ui-loader />
                </div>
            </div>
        </transition>
        <div class="modal-cancel-expired-investment-requests__title">
            Отмена неподписанных инвестиционных заявок
        </div>
        <div class="modal-cancel-expired-investment-requests__subtitle">
            {{ estate.title }}
            <br />
            <br />
            Будет отменено заявок: {{ requestsToCancel.length }}
            <br />
            Общая сумма отмены: {{ sumToCancel }}
        </div>
        <div class="modal-cancel-expired-investment-requests__buttons">
            <div class="modal-cancel-expired-investment-requests__buttons-item">
                <ui-button
                    v-on:click="submit"
                    text="Выполнить отмену"
                    theme="primary"
                />
            </div>
            <div class="modal-cancel-expired-investment-requests__buttons-item">
                <ui-button
                    v-on:click="close"
                    text="Вернуться"
                    theme="secondary-1"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import utils from '~/utils';

export default {
    name: 'modal-cancel-expired-investment-requests',
    props: {
        estate: {
            type: Object,
        },
        requests: {
            type: Array,
        },
    },
    computed: {
        ...mapState('admin/investments', [
            'cancelExpiredInvestmentRequestsIsLoading',
            'cancelExpiredInvestmentRequestsResult',
        ]),
        requestsToCancel() {
            if (!this.requests) {
                return [];
            }
            return this.requests.filter(item => item.status === 'new' || item.status === 'wait_sign');
        },
        sumToCancel() {
            if (!this.requestsToCancel) {
                return 0;
            }
            return utils.formats.formatNumberWithSpaces(this.requestsToCancel.reduce((acc, item) => acc + item.amount, 0), true);
        },
        isLoading() {
            return this.cancelExpiredInvestmentRequestsIsLoading;
        },
    },
    methods: {
        submit() {
            this.$store.dispatch('admin/investments/cancelExpiredInvestmentRequests', {
                id: this.estate.id,
            });
        },
        close() {
            this.$store.commit('modals/pop');
        },
    },
    watch: {
        cancelExpiredInvestmentRequestsResult(newVal) {
            if (newVal) {
                this.close();
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-cancel-expired-investment-requests {
    .modal();

    position: relative;

    max-width: 960px;
    width: 100%;
    padding: 40px 24px 32px;
    border-radius: @border-radius-lg;
    overflow: hidden;

    background-color: @color-gray-lightest;
    &__loader {
        .transition-fade();

        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        background: fade(@color-gray-lightest, 75%);
        &-container {
            width: 50px;
            padding-top: 8px;
        }
    }
    &__title {
        .typography-large();

        margin: 0 auto 8px;

        text-align: center;
    }
    &__subtitle {
        .typography-small();

        margin: 0 auto 24px;

        text-align: center;
    }
    &__buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 16px;
        flex-wrap: wrap;
        &-item {
            flex: 0 0 240px;
        }
    }
    @media @media-md-down {
        &__title {
            .typography-medium();
        }
    }
}
</style>
