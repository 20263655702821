<template>
    <section class="plugin-home-hero">
        <div class="plugin-home-hero__container"
            v-bind:style="backgroundStyle"
            >
            <h1 class="plugin-home-hero__header"
                v-if="title !== null || subtitle !== null"
                >
                <span class="plugin-home-hero__header-title"
                    v-if="title !== null"
                    v-text="title"
                />
                <br />
                <span class="plugin-home-hero__header-subtitle"
                    v-if="subtitle !== null"
                    v-text="subtitle"
                />
            </h1>
            <div class="plugin-home-hero__description"
                v-if="description !== null"
                v-text="description"
            />
            <div class="plugin-home-hero__button"
                v-if="buttonHref !== null && buttonText !== null"
                >
                <a class="plugin-home-hero__button-link"
                    v-bind:href="buttonHref"
                    >
                    <ui-button
                        icon-right="arrow-right"
                        theme="primary"
                        size="lg"
                        >
                        {{ buttonText }}
                    </ui-button>
                </a>
            </div>
            <a class="plugin-home-hero__bank"
                v-bind:href="bankHref"
                >
                <div class="plugin-home-hero__bank-icon">
                    <icon name="bank-registry" width="auto" original />
                </div>
                <div class="plugin-home-hero__bank-text">
                    В реестре ЦБ РФ
                </div>
            </a>
        </div>
    </section>
</template>

<script>
import config from '~/config';
import utils from '~/utils';

export default {
    name: 'plugin-home-hero',
    props: {
        image: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
        subtitle: {
            type: String,
            default: null,
        },
        description: {
            type: String,
            default: null,
        },
        buttonHref: {
            type: String,
            default: null,
        },
        buttonText: {
            type: String,
            default: null,
        },
    },
    data: () => ({
        bankHref: config.urls.footer.register,
    }),
    computed: {
        backgroundStyle() {
            if (!this.image) {
                return null;
            }
            const resize = utils.common.getResize();
            return { backgroundImage: `url(${utils.common.injectResize(this.image, resize)})` };
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-home-hero {
    .container();

    padding-bottom: 40px;
    &__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: calc(100vh - @container-header-height - 40px);
        padding-top: 123px;
        padding-right: calc(@grid-gap + @grid-column);
        padding-bottom: 123px;
        padding-left: calc(@grid-gap + @grid-column);
        border-radius: @border-radius-lg;

        background-color: @color-gray-main;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    &__header {
        max-width: 553px;
        margin: 0 0 30px;
        &-title {
            margin-bottom: 2px;

            font-size: 7rem;
            font-weight: 700;
            line-height: 8.7rem;
            text-transform: uppercase;
        }
        &-subtitle {
            .typography-large();

            text-transform: uppercase;
        }
    }
    &__description {
        .typography-small();

        max-width: 553px;
        margin-bottom: 60px;
    }
    &__button {
        display: flex;
        justify-content: flex-start;
        padding-bottom: 60px;
        &-link {
            display: block;

            text-decoration: none;
        }
    }
    &__bank {
        flex: 0 1 auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: auto auto 0 0;

        text-decoration: none;
        &-icon {
            flex: 0 0 auto;

            color: @color-gray-dark;
            font-size: 4.7em;

            // grayscales it
            filter: grayscale(100%);
        }
        &-text {
            .typography-small();

            flex: 1 1 auto;
            margin-left: 20px;
        }
    }
    @media @media-md-down {
        padding: 0;

        &__container {
            .container();

            min-height: calc(100vh - @container-header-height-mobile);
            padding-top: 92px;
            padding-bottom: 90px;
            border-radius: 0;
        }
        &__header {
            margin: 0 0 32px;
            &-title {
                .typography-large();

                margin-bottom: 8px;
            }
            &-subtitle {
                .typography-heading();
            }
        }
        &__description {
            .typography-body();

            margin-bottom: 60px;
        }
        &__button {
            &-link {
                width: 100%;
                max-width: 320px;
            }
        }
    }
    @media @media-sm-down {
        &__container {
            padding-bottom: 0;
        }
        &__button {
            padding-bottom: 80px;
        }
        &__bank {
            margin: auto -24px 0;
            padding: 18px 18px 18px 24px;
            background-color: fade(@color-gray-lightest, 80%);
            &-icon {
                font-size: 3.8em;
            }
            &-text {
                .typography-body();

                margin-left: 16px;
            }
        }
    }
}
</style>
