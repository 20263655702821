export const feedback = {
    name: 'feedback',
    label: 'Текст обращения',
    value: '',
    initialValue: '',
    type: 'textarea',
    errors: {
        required: 'Введите текст обращения',
    },
};

export const files = {
    name: 'document',
    text: 'Дополнительные файлы',
    type: 'file',
    value: null,
    initialValue: null,
    // formats: [],
    errors: {
        required: 'Загрузите файл',
    },
};
