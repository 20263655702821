<template>
    <ui-input-base-file
        v-bind:is-invalid="isInvalid"
        v-bind="{ ...model }"
        v-on:change="changeHandler"
        v-on:focus="focusHandler"
        v-on:blur="blurHandler"
    />
</template>

<script>
export default {
    name: 'ui-input-file',
    props: {
        model: {
            type: Object,
        },
        isInvalid: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [ String, Number, File ],
        },
    },
    model: {
        prop: 'value',
        event: 'change',
    },
    methods: {
        changeHandler(newValue) {
            this.$emit('change', newValue);
        },
        focusHandler(data) {
            this.$emit('focus', data);
        },
        blurHandler(data) {
            this.$emit('blur', data);
        },
    },
};
</script>

<style scoped lang="less">
@import "~theme";
</style>
