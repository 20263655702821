<template>
    <section class="page-auth">
        <transition name="fade">
            <div class="page-auth__loader"
                v-if="isLoading"
                >
                <div class="page-auth__loader-container">
                    <ui-loader />
                </div>
            </div>
        </transition>
        <div class="page-auth__content">
            <component
                v-bind:is="contentComponent"
                v-bind:content="content"
                v-on:switch="content = $event"
            />
        </div>
    </section>
</template>

<script>
import utils from '~/utils';

export default {
    name: 'page-auth',
    props: {
        givenContent: {
            type: String,
            default: null,
        },
    },
    provide: function() {
        return {
            image: this.imageMobile,
        };
    },
    data: () => ({
        content: 'login',
        isLoading: false,
    }),
    computed: {
        contentComponent() {
            if (this.content === 'register') {
                return 'page-auth-registration';
            }
            return `page-auth-${this.content}`;
        },
    },
    methods: {
        setIsLoading(newVal) {
            this.isLoading = newVal;
        },
    },
    beforeMount() {
        this.content = this.givenContent ||
            utils.common.parseUrl().find((item) => item.title === 'tab')?.value ||
            this.content;
        this.$on('update-loading', this.setIsLoading);
    },
    beforeDestroy() {
        this.$off('update-loading', this.setIsLoading);
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-auth {
    .container();
    .container-paddings();

    position: relative;

    flex: 1 1 auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: ~'max(60px, 8vh)';
    padding-bottom: 40px;
    &__loader {
        .transition-fade();

        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        background-color: @color-gray-lighter;
        &-container {
            width: 100px;
        }
    }
    &__content {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
        width: 100%;
        max-width: 436px;
        &-switcher {
            margin-bottom: 16px;
        }
    }
    @media @media-md-down {
        padding: 40px 0;
    }
}
</style>
