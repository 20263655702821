<template>
    <div>
        layout-default
    </div>
</template>

<script>
export default {
    name: 'layout-cabinet',
    data: () => ({
        isSideblockFittable: true,
    }),
    methods: {
        checkSideblockHeight() {
            const sideblock = this.$refs.sideblock;
            if (!sideblock || window.innerWidth < 1024) {
                this.isSideblockFittable = false;
                return;
            }
            this.isSideblockFittable = sideblock.clientHeight <= window.innerHeight - 77;
        },
    },
    mounted() {
        this.checkSideblockHeight();
        window.addEventListener('resize', this.checkSideblockHeight);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkSideblockHeight);
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.layout-cabinet {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    background-color: @color-gray-lighter;
    &__container {
        .container();

        flex: 1 1 auto;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
    }
    &__sideblock {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        align-content: stretch;
        justify-items: flex-start;
        width: 231px;
        min-height: calc(100vh - @container-header-height);
        padding: 24px 40px 24px 0;
        overflow: auto;
        .cms-toolbar-expanded & {
            min-height: calc(100vh - (@container-header-height + @container-cms-height)); // common-header height
        }
        &._sticky {
            position: sticky;
            top: @container-header-height;
            .cms-toolbar-expanded & {
                top: @container-header-height + @container-cms-height;
            }
        }
    }
    &__content {
        flex: 1 1 auto;
        width: calc(100% - 271px);
        align-self: stretch;
    }
    @media @media-md-down {
        &__container {
            flex-direction: column;
        }
        &__sideblock {
            position: sticky;
            top: @container-header-height-mobile;
            z-index: 2;

            width: calc(100% + 48px);
            min-height: auto;
            padding: 0;
            margin: 0 -24px;
            .cms-toolbar-expanded & {
                top: @container-header-height-mobile + @container-cms-height;

                min-height: auto;
            }
        }
        &__content {
            width: 100%;
        }
    }
}
</style>
