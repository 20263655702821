<template>
    <div class="page-cabinet-verification-profile-juridical">
        <ui-form
            v-bind:model="fields"
            v-bind:validation="$v"
            v-bind:submit-handler="submit"
            v-on:update="updateFormData"
            gap="wide"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import forms from '~/forms';

const isScreenLarge = () => window.innerWidth >= 768;
export default {
    name: 'page-cabinet-verification-profile-juridical',
    props: {
        isTouched: {
            type: Boolean,
        },
    },
    data: () => ({
        isLargeScreen: isScreenLarge(),
        observer: null,
    }),
    validations() {
        const result = {
            fields: {
                organizationName: { ...forms.validations.genericRequired },
                organizationNameFull: { ...forms.validations.genericRequired },
                organizationAddress: { ...forms.validations.genericRequired },
                organizationRegistrationAddress: { ...forms.validations.genericRequired },
                organizationPostalAddress: { ...forms.validations.genericRequired },
                bankName: { ...forms.validations.genericRequired },
                ogrn: { ...forms.validations.ogrn },
                okved: { ...forms.validations.genericRequired },
                kpp: { ...forms.validations.kpp },
                bik: { ...forms.validations.bik },
                corrAccount: { ...forms.validations.genericRequired },
                organisationRegistrationDate: { ...forms.validations.date },
            },
        };
        return result;
    },
    computed: {
        ...mapState('verification/profile', [
            'juridical',
        ]),
        fields() {
            const result = {
                organizationName: { ...forms.fields.user.juridical.juridicalName },
                organizationNameFull: { ...forms.fields.user.juridical.juridicalNameFull },
                organizationAddress: { ...forms.fields.user.juridical.juridicalAddress },
                organizationRegistrationAddress: { ...forms.fields.user.juridical.juridicalRegistrationAddress },
                organizationPostalAddress: { ...forms.fields.user.juridical.juridicalPostalAddress },
                bankName: { ...forms.fields.user.juridical.juridicalBankName },
                ogrn: { ...forms.fields.ogrn },
                okved: { ...forms.fields.user.juridical.juridicalOkved },
                kpp: { ...forms.fields.user.juridical.juridicalKpp },
                bik: { ...forms.fields.user.juridical.juridicalBik },
                corrAccount: { ...forms.fields.user.juridical.juridicalCorrAccount },
                organisationRegistrationDate: { ...forms.fields.user.juridical.juridicalRegistrationDate },
            };
            if (this.isLargeScreen) {
                result.ogrn.isHalf = true;
                result.okved.isHalf = true;
                result.kpp.isHalf = true;
                result.bik.isHalf = true;
                result.corrAccount.isHalf = true;
                result.organisationRegistrationDate.isHalf = true;
            }
            Object.keys(result).forEach(key => {
                result[key].value = this.juridical[key] === null ? '' : this.juridical[key];
            });
            return result;
        },
    },
    methods: {
        submit() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            this.$emit('submit');
        },
        updateFormData(newData) {
            const result = {};
            Object.keys(newData).forEach(key => {
                result[key] = newData[key].value === '' ? null : newData[key].value;
            });
            this.$store.state.verification.profile.juridical = {
                ...this.$store.state.verification.profile.juridical,
                ...result,
            };
        },
    },
    mounted() {
        this.observer = new ResizeObserver(() => {
            this.isLargeScreen = isScreenLarge();
        });
        this.observer.observe(document.body);
    },
    beforeDestroy() {
        this.observer.disconnect();
    },
    watch: {
        isTouched: {
            handler(newVal) {
                if (newVal) {
                    this.$v.$touch();
                }
            },
            immediate: true,
        },
        '$v.$dirty': {
            handler(newVal) {
                if (newVal) {
                    this.$emit('touch');
                }
            },
            immediate: true,
        },
        '$v.$invalid': {
            handler(newVal) {
                this.$emit('validation-update', { entityName: 'isJuridicalDataValid', value: !newVal });
            },
            immediate: true,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-verification-profile-juridical {
}
</style>
