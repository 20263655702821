<template>
    <div class="ui-card-base--wrapper">
        <article class="ui-card-base"
            v-if="isVisible"
            v-bind:style="{
                paddingTop,
                paddingRight,
                paddingBottom,
                paddingLeft,
            }"
            v-bind:class="{
                _accented: isAccented,
                _shadowed: withShadow,
                _bordered: withBorder,
            }"
            >
            <slot />
        </article>
        <slot v-else />
    </div>
</template>

<script>
export default {
    name: 'ui-card-base',
    props: {
        paddingTop: {
            type: [ String, Number ],
            default: null,
        },
        paddingRight: {
            type: [ String, Number ],
            default: null,
        },
        paddingBottom: {
            type: [ String, Number ],
            default: null,
        },
        paddingLeft: {
            type: [ String, Number ],
            default: null,
        },
        isAccented: {
            type: Boolean,
            default: false,
        },
        withShadow: {
            type: Boolean,
            default: false,
        },
        withBorder: {
            type: Boolean,
            default: false,
        },
        breakpoints: {
            type: Object,
            default: null,
        },
    },
    computed: {
        isVisible() {
            if (!this.breakpoints) {
                return true;
            }
            const breakpointsWidths = Object.keys(this.breakpoints).map(x => parseInt(x, 10)).sort((a, b) => a >= b ? 1 : -1);
            const activeBreakpoint = breakpointsWidths.find(x => x > window.innerWidth);
            return activeBreakpoint ? this.breakpoints[activeBreakpoint] : true;
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-card-base {
    &--wrapper {
        width: 100%;
        height: 100%;
    }
    width: 100%;
    height: 100%;
    padding: 16px;
    border-radius: @border-radius-lg;

    background-color: @color-gray-lightest;
    &._accented {
        background-image: @color-gradient-background;

        color: @color-gray-lightest;
    }
    &._shadowed {
        box-shadow: @shadow-primary;
    }
    &._bordered {
        border: 1px solid @color-gray-light;
    }
}
</style>
