<template>
    <div class="page-cabinet-referral-info-list">
        <div class="page-cabinet-referral-info-list__title">
            Друзья, воспользовавшиеся вашей ссылкой
        </div>
        <div class="page-cabinet-referral-info-list__table">
            <page-cabinet-referral-info-list-table />
        </div>
        <div class="page-cabinet-referral-info-list__cards">
            <page-cabinet-referral-info-list-cards />
        </div>
    </div>
</template>

<script>
export default {
    name: 'page-cabinet-referral-info-list',
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-referral-info-list {
    &__title {
        .typography-heading();

        margin-bottom: 16px;
    }
    &__cards {
        display: none;
    }
    @media @media-md-down {
        &__table {
            display: none;
        }
        &__cards {
            display: block;
        }
    }
}
</style>
