<template>
    <div class="page-cabinet-verification-profile-foreign">
        <div class="page-cabinet-verification-profile-foreign__title"
            v-text="'Данные миграционной карты'"
        />
        <div class="page-cabinet-verification-profile-foreign__container">
            <div class="page-cabinet-verification-profile-foreign__container-left">
                <div class="page-cabinet-verification-profile-foreign__form">
                    <ui-form
                        v-bind:model="fieldsMigrationCard"
                        v-bind:validation="$v"
                        v-bind:validation-path="'fieldsMigrationCard'"
                        v-bind:submit-handler="submit"
                        v-on:update="updateFormData"
                        gap="wide"
                    />
                </div>
            </div>
            <div class="page-cabinet-verification-profile-foreign__container-right">
                <div class="page-cabinet-verification-profile-foreign__subtitle">
                    Загрузите скан миграционной карты в формате PDF, JPEG, JPG, PNG
                </div>
                <div class="page-cabinet-verification-profile-foreign__loaders">
                    <div class="page-cabinet-verification-profile-foreign__loaders-item">
                        <common-photo-loader
                            title="Изображение миграционной карты"
                            v-bind:text="'Изображение миграционной карты'"
                            v-bind:is-loading="photoMigrationCardIsLoading"
                            v-bind:file="photoMigrationCard"
                            v-bind:given-error="photoMigrationCardError"
                            v-on:change="updatePhoto($event, 'photoMigrationCard')"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="page-cabinet-verification-profile-foreign__form">
            <ui-form
                v-bind:model="fieldsVisaRequirement"
                v-bind:submit-handler="submit"
                v-on:update="updateFormData"
                gap="wide"
            />
        </div>
        <template v-if="!fieldsVisaRequirement.isNoVisaRequired.value">
            <div class="page-cabinet-verification-profile-foreign__title"
                v-text="'Данные документа, подтверждающие право иностранного гражданина или лица без гражданства на пребывание в РФ'"
            />
            <div class="page-cabinet-verification-profile-foreign__container">
                <div class="page-cabinet-verification-profile-foreign__container-left">
                    <div class="page-cabinet-verification-profile-foreign__form">
                        <ui-form
                            v-bind:model="fieldsMigrationDocument"
                            v-bind:validation="$v"
                            v-bind:validation-path="'fieldsMigrationDocument'"
                            v-bind:submit-handler="submit"
                            v-on:update="updateFormData"
                            gap="wide"
                        />
                    </div>
                </div>
                <div class="page-cabinet-verification-profile-foreign__container-right">
                    <div class="page-cabinet-verification-profile-foreign__subtitle">
                        Загрузите скан визы в формате PDF, JPEG, JPG, PNG
                    </div>
                    <div class="page-cabinet-verification-profile-foreign__loaders">
                        <div class="page-cabinet-verification-profile-foreign__loaders-item">
                            <common-photo-loader
                                title="Изображение визы"
                                v-bind:text="'Изображение визы'"
                                v-bind:is-loading="photoMigrationVisaIsLoading"
                                v-bind:file="photoMigrationVisa"
                                v-bind:given-error="photoMigrationVisaError"
                                v-on:change="updatePhoto($event, 'photoMigrationVisa')"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import config from '~/config';
import forms from '~/forms';

export default {
    name: 'page-cabinet-verification-profile-foreigner',
    props: {
        isTouched: {
            type: Boolean,
        },
    },
    data: () => ({
        photoMigrationCardIsLoading: false,
        photoMigrationVisaIsLoading: false,
    }),
    validations() {
        const result = {
            fieldsMigrationCard: {},
            fieldsMigrationDocument: {},
        };
        result.fieldsMigrationCard.migrationCardNumber = { ...forms.validations.genericRequired };
        result.fieldsMigrationCard.migrationCardDateStart = {
            value: {
                ...forms.validations.date.value,
                isBeforeEnd: (value) => {
                    if (!value || !this.fieldsMigrationCard.migrationCardDateEnd.value) {
                        return true;
                    }
                    return moment(this.fieldsMigrationCard.migrationCardDateStart.value, config.formats.dates.frontend)
                        .isBefore(moment(this.fieldsMigrationCard.migrationCardDateEnd.value, config.formats.dates.frontend));
                },
            },
        };
        result.fieldsMigrationCard.migrationCardDateEnd = {
            value: {
                ...forms.validations.date.value,
                isAfterNow: (value) => {
                    if (!value) {
                        return true;
                    }
                    return moment(this.fieldsMigrationCard.migrationCardDateEnd.value, config.formats.dates.frontend)
                        .isAfter(Date.now());
                },
            },
        };
        if (!this.fieldsVisaRequirement.isNoVisaRequired.value) {
            result.fieldsMigrationDocument.migrationDocumentNumber = { ...forms.validations.genericRequired };
            result.fieldsMigrationDocument.migrationDocumentDateStart = {
                value: {
                    ...forms.validations.date.value,
                    isBeforeEnd: (value) => {
                        if (!value || !this.fieldsMigrationDocument.migrationDocumentDateEnd.value) {
                            return true;
                        }
                        return moment(this.fieldsMigrationDocument.migrationDocumentDateStart.value, config.formats.dates.frontend)
                            .isBefore(moment(this.fieldsMigrationDocument.migrationDocumentDateEnd.value, config.formats.dates.frontend));
                    },
                    isBeforeOrNow: (value) => {
                        if (!value) {
                            return true;
                        }
                        return moment(this.fieldsMigrationDocument.migrationDocumentDateStart.value, config.formats.dates.frontend)
                            .isBefore(Date.now());
                    },
                },
            };
            result.fieldsMigrationDocument.migrationDocumentDateEnd = {
                value: {
                    ...forms.validations.date.value,
                    isAfterNow: (value) => {
                        if (!value) {
                            return true;
                        }
                        return moment(this.fieldsMigrationDocument.migrationDocumentDateEnd.value, config.formats.dates.frontend)
                            .isAfter(Date.now());
                    },
                },
            };
        }
        return result;
    },
    computed: {
        ...mapState('users', [
            'user',

            'getUserPhotosResult',
        ]),
        ...mapState('verification/profile', [
            'foreigner',
        ]),
        photoMigrationCard() {
            return this.getUserPhotosResult?.photoMigrationCard;
        },
        photoMigrationVisa() {
            return this.getUserPhotosResult?.photoMigrationVisa;
        },
        photoMigrationCardError() {
            return this.isTouched && !this.photoMigrationCard ? 'Загрузите фото' : null;
        },
        photoMigrationVisaError() {
            return this.isTouched && !this.photoMigrationVisa ? 'Загрузите фото' : null;
        },
        fieldsMigrationCard() {
            const result = {
                migrationCardNumber: { ...forms.fields.user.migration.migrationCardNumber },
                migrationCardDateStart: { ...forms.fields.user.migration.migrationCardDateStart },
                migrationCardDateEnd: { ...forms.fields.user.migration.migrationCardDateEnd },
            };
            Object.keys(result).forEach(key => {
                result[key].value = this.foreigner[key] === null ? '' : this.foreigner[key];
            });
            return result;
        },
        fieldsVisaRequirement() {
            const result = {
                isNoVisaRequired: { ...forms.fields.user.migration.migrationNoVisaRequired },
            };
            result.isNoVisaRequired.value = this.foreigner.isNoVisaRequired;
            return result;
        },
        fieldsMigrationDocument() {
            const result = {
                migrationDocumentNumber: { ...forms.fields.user.migration.migrationDocumentNumber },
                migrationDocumentDateStart: { ...forms.fields.user.migration.migrationDocumentDateStart },
                migrationDocumentDateEnd: { ...forms.fields.user.migration.migrationDocumentDateEnd },
            };
            Object.keys(result).forEach(key => {
                result[key].value = this.foreigner[key] === null ? '' : this.foreigner[key];
            });
            return result;
        },
    },
    methods: {
        updateFormData(newData) {
            const result = {};
            Object.keys(newData).forEach(key => {
                result[key] = newData[key].value === '' ? null : newData[key].value;
            });
            this.$store.state.verification.profile.foreigner = {
                ...this.$store.state.verification.profile.foreigner,
                ...result,
            };
        },
        updatePhoto(file, type) {
            if (this[type + 'IsLoading']) {
                return;
            }
            if (file !== null) {
                this[type + 'IsLoading'] = true;
                const formData = new FormData();
                formData.append('file', file);
                this.$store.dispatch('users/updateUserPhoto', {
                    userId: this.user.id,
                    type,
                    isBackground: true,
                    formData,
                    onSuccess: () => {
                        this.$store.dispatch('users/getUserPhotos', {
                            userId: this.user.id,
                            onSuccess: () => {
                                this[type + 'IsLoading'] = false;
                            },
                        });
                    },
                });
            }
        },
        submit() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            this.$emit('submit');
        },
    },
    watch: {
        isTouched: {
            handler(newVal) {
                if (newVal) {
                    this.$v.$touch();
                }
            },
            immediate: true,
        },
        '$v.$dirty': {
            handler(newVal) {
                if (newVal) {
                    this.$emit('touch');
                }
            },
            immediate: true,
        },
        '$v.$invalid': {
            handler(newVal) {
                this.$emit('validation-update', { entityName: 'isForeignerDataValid', value: !newVal });
            },
            immediate: true,
        },
        'fieldsVisaRequirement.isNoVisaRequired.value'(newVal) {
            if (this.isTouched && !newVal) {
                this.$v.$touch();
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-verification-profile-foreign {
    &__title {
        .typography-heading();

        max-width: 413px;
        margin-bottom: 24px;
    }
    &__form {
        margin-bottom: 40px;
    }
    &__container {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        &-left {
            flex: 1 1 auto;
            margin-right: 60px;
        }
        &-right {
            flex: 0 0 377px;
        }
    }
    &__subtitle {
        .typography-body();

        margin-bottom: 16px;
    }
    &__loaders {
        &-item {
            height: 96px;
            & ~ & {
                margin-top: 16px;
            }
        }
    }
    @media @media-md-down {
        &__container {
            flex-direction: column;
            align-items: stretch;
            &-left,
            &-right {
                flex: 1 1 100%;
                margin: 0;
            }
        }
        &__loaders {
            margin-bottom: 40px;
        }
    }
}
</style>
