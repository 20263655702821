<template>
    <div class="page-cabinet-settings-form-auth">
        <div class="page-cabinet-settings-form-auth__texts">
            <div class="page-cabinet-settings-form-auth__texts-title">
                Двухфакторная аутентификация
            </div>
            <div class="page-cabinet-settings-form-auth__texts-subtitle"
                v-if="isEnabled"
                >
                Ваши данные защищены. Аутентификация включена.
            </div>
            <div class="page-cabinet-settings-form-auth__texts-subtitle"
                v-else
                >
                Ваши данные под угрозой. Аутентификация выключена.
            </div>
        </div>
        <div class="page-cabinet-settings-form-auth__switch">
            <ui-input-base-switch
                v-bind:value="isEnabled"
                v-bind:is-loading="isLoading"
                v-on:change="onChangeHandler"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import utils from '~/utils';

export default {
    name: 'page-cabinet-settings-form-auth',
    computed: {
        ...mapState('users', [
            'user',

            'getTwoFactorResult',
            'getTwoFactorIsLoading',
            'getTwoFactorError',

            'disableTwoFactorResult',
            'disableTwoFactorIsLoading',
            'disableTwoFactorError',

            'enableTwoFactorResult',
            'enableTwoFactorIsLoading',
            'enableTwoFactorError',
        ]),
        isEnabled() {
            return this.getTwoFactorResult?.isEnabled || false;
        },
        isLoading() {
            return !this.user ||
                this.user === 'guest' ||
                this.getTwoFactorIsLoading ||
                this.disableTwoFactorIsLoading ||
                this.enableTwoFactorIsLoading;
        },
    },
    methods: {
        onChangeHandler() {
            if (this.isLoading || !this.user?.phone) return;
            this.$store.commit('modals/push', {
                name: 'sms-input',
                props: {
                    smsVerificationProps: {
                        phone: this.user.phone,
                        isRequestRequired: true,
                        customText: `Мы выслали код подтверждения на номер ${utils.formats.formatPhone(this.user.phone)} для ${this.isEnabled ? 'отключения' : 'включения'} двухфакторной аутентификации`,
                    },
                },
                on: {
                    submit: this.setTwoFactor,
                },
            });
        },
        setTwoFactor(code) {
            this.$store.dispatch('users/' + (this.isEnabled ? 'disableTwoFactor' : 'enableTwoFactor'), {
                code,
                value: this.user.phone,
            });
        },
    },
    mounted() {
        this.$store.dispatch('users/getTwoFactor');
    },
    watch: {
        disableTwoFactorResult() {
            this.$store.dispatch('users/getTwoFactor');
        },
        enableTwoFactorResult() {
            this.$store.dispatch('users/getTwoFactor');
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-settings-form-auth {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    &__texts {
        flex: 1 1 auto;
        &-title {
            .typography-body();
        }
        &-subtitle {
            .typography-caption();

            color: @color-gray-main;
        }
    }
    &__switch {
        flex: 0 0 auto;
    }
}
</style>
