import client from '../_client';
import config from '~/config';
import transforms from './transforms';
// import utils from '~/utils';

export const getNotices = (params = {}) => {
    return client.request({
        url: config.api.cabinet.notice,
        method: 'GET',
        params,
        transformData: transforms.responses.notice,
    });
};
