export const marketApplicationType = {
    name: 'marketApplicationType',
    label: 'Тип заявки',
    value: '',
    initialValue: '',
    type: 'select',
    options: [
        { text: 'Продажа', value: 'sale' },
        { text: 'Покупка', value: 'buy' },
    ],
    errors: {
        required: 'Выберите тип заявки',
        isTypeValid: 'Вы не можете продавать акции, которых у вас нет',
    },
};

export const marketApplicationSharesAmount = {
    name: 'marketApplicationSharesAmount',
    label: 'Количество акций',
    value: '',
    initialValue: '',
    type: 'numeric',
    firstZeroInavailable: true,
    errors: {
        required: 'Укажите количество акций',
        isAmountValid: 'У вас недостаточно акций для продажи',
        isAmountPositive: 'Количество акций не может быть равно нулю',
        isSaleLimitValid: (model) => `Вы не можете продать пользователю больше акций, чем он запрашивает (${model.limit})`,
        isBuyLimitValid: (model) => `Вы не можете купить у пользователя больше акций, чем он предлагает (${model.limit})`,
        isBuyTotalIssuedValid: (model) => `Вы не можете купить больше акций, чем было выпущено (${model.totalIssuedQuantity})`,
        isSaleUserSharesAvailableValid: (model) => `Вы не можете продать больше акций, чем вам доступно (${model.userSharesAvailable})`,
    },
};

export const isPackage = {
    name: 'isPackage',
    label: 'Реализовывать только пакетом',
    type: 'checkbox',
    value: false,
    hint: 'Количество акций во встречной заявке должно быть равно количеству акций в вашей заявке',
};

export const marketApplicationSharesPrice = {
    name: 'marketApplicationSharesPrice',
    label: 'Цена за акцию в руб.',
    value: '',
    initialValue: '',
    type: 'currency',
    errors: {
        required: 'Укажите цену за акцию',
        isLessThanMax: (model) => `Данные акции нельзя продать по цене выше ${model.maxTradePriceFormatted}`,
        isMoreThanMin: (model) => `Данные акции нельзя продать по цене ниже ${model.minTradePriceFormatted}`,
    },
};
