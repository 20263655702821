<template>
    <ui-input-base-text
        v-bind:formatter="formatDown"
        v-bind:is-invalid="isInvalid"
        v-bind="formattedModel"
        v-bind:type="'tel'"
        v-on:input="inputHandler"
        v-on:focus="focusHandler"
        v-on:blur="blurHandler"
        inputmode="numeric"
        pattern="\\d*"
    />
</template>

<script>
import utils from '~/utils';

export default {
    name: 'ui-input-phone',
    props: {
        model: {
            type: Object,
        },
        isInvalid: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [ String, Number ],
        },
    },
    model: {
        prop: 'value',
        event: 'input',
    },
    computed: {
        formattedModel() {
            return { ...this.model, value: this.formatDown(this.model.value) };
        },
    },
    methods: {
        formatDown(value, position = null) {
            if (
                value &&
                this.value &&
                position !== null &&
                position > 0 &&
                value.length < utils.formats.formatPhone(this.value).length &&
                this.formatUp(value).length === this.value.length
            ) {
                const cleanPosition = this.formatUp(value.substring(0, position)).length;
                const newResult = this.value.substring(0, cleanPosition - 1) + this.value.substring(cleanPosition);
                this.inputHandler(newResult);
                return utils.formats.formatPhone(newResult, cleanPosition - 1);
            }
            return utils.formats.formatPhone(value, position);
        },
        formatUp(value) {
            return value.replace(/[^+0-9]/g, '');
        },
        inputHandler(newValue) {
            this.$emit('input', this.formatUp(newValue));
        },
        focusHandler(data) {
            this.$emit('focus', data);
        },
        blurHandler(data) {
            this.$emit('blur', data);
        },
    },
    mounted() {
        if (this.value?.toString() !== this.formatUp(this.formattedModel.value)) {
            this.$emit('input', this.formatUp(this.formattedModel.value));
        }
    },
};
</script>

<style scoped lang="less">
@import "~theme";
</style>
