<template>
    <div class="plugin-referral-how-it-works-item"
        v-bind:class="{
            _reversed: variant === 'share',
            _visible: isVisible,
        }"
        ref="root"
        >
        <div v-bind:class="`plugin-referral-how-it-works-item__images _${variant}`">
            <img v-bind:src="images.bg"
                 alt="bg-image"
                 class="plugin-referral-how-it-works-item__images-bg"
            />
            <img v-bind:src="images.front"
                 alt="front-image"
                 class="plugin-referral-how-it-works-item__images-front"
            />
        </div>
        <div class="plugin-referral-how-it-works-item__info">
            <div class="plugin-referral-how-it-works-item__info-subtitle"
                 v-if="subtitle"
                 v-html="subtitle"
            />
            <div class="plugin-referral-how-it-works-item__info-title"
                 v-if="title"
                 v-html="title"
            />
            <div class="plugin-referral-how-it-works-item__info-description"
                 v-if="description"
                 v-html="description"
            />
        </div>
    </div>
</template>

<script>
const imagesVariants = {
    register: {
        bg: '/static/images/referral/how-it-works/register-bg.png',
        front: '/static/images/referral/how-it-works/register-front.png',
    },
    share: {
        bg: '/static/images/referral/how-it-works/share-bg.png',
        front: '/static/images/referral/how-it-works/share-front.png',
    },
    use: {
        bg: '/static/images/referral/how-it-works/use-bg.png',
        front: '/static/images/referral/how-it-works/use-front.png',
    },
};

export default {
    name: 'plugin-referral-how-it-works-item',
    props: {
        title: {
            type: String,
            default: null,
        },
        subtitle: {
            type: String,
            default: null,
        },
        description: {
            type: String,
            default: null,
        },
        variant: {
            type: String,
            default: null,
        },
    },
    data: () => ({
        isVisible: false,
        visibilityThreshold: window.innerWidth >= 480 ? 0.25 : 0.33,
    }),
    computed: {
        images() {
            return imagesVariants[this.variant || 'register'];
        },
    },
    methods: {
        checkVisibility(entries) {
            if (entries[0].intersectionRatio >= this.visibilityThreshold) {
                this.isVisible = true;
                this.removeObserver();
            }
        },
        removeObserver() {
            this.intersectionObserver.disconnect();
            this.intersectionObserver = null;
        },
    },
    mounted() {
        this.intersectionObserver = new IntersectionObserver(this.checkVisibility, { threshold: this.visibilityThreshold });
        this.intersectionObserver.observe(this.$refs.root);
    },
    beforeDestroy() {
        this.removeObserver();
    },
};
</script>

<style lang="less">
@import '~theme';

.plugin-referral-how-it-works-item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    justify-items: center;

    &._reversed > &__images {
        grid-column: 2;
        grid-row: 1;
    }

    &._reversed > &__info {
        grid-column: 1;
        grid-row: 1;
    }
    &._visible &__images {
        &-bg,
        &-front {
            opacity: 1;

            transform: translate(-50%, -50%);
        }
    }

    &._visible &__info-title,
    &._visible &__info-subtitle,
    &._visible &__info-description {
        opacity: 1;

        transform: translateY(0) scale(1);
    }

    &:first-child,
    &:nth-child(3){
        margin-bottom: 90px;
    }

    &__images {
        --offset: 10vw;

        position: relative;
        width: 100%;

        &-bg,
        &-front {
            position: absolute;

            transition: all @duration-normal ease-out;
        }
        &-bg {
            opacity: 0;

            transform: translate(calc(-50% - var(--offset)), calc(-50% + var(--offset)));
            ._share > & {
                transform: translate(calc(-50% - var(--offset)), calc(-45% + var(--offset)));
            }
            ._use > & {
                transform: translate(calc(-50% + var(--offset)), calc(-50% - var(--offset)));
            }
        }
        &-front {
            opacity: 0;

            transform: translate(calc(-50% + var(--offset)), calc(-50% - var(--offset)));
            ._share > & {
                transform: translate(calc(-50% + var(--offset)), calc(-49% - var(--offset)));
            }
            ._use > & {
                transform: translate(calc(-50% - var(--offset)), calc(-50% + var(--offset)));
            }
        }

        &._register {
            max-width: 660px;
            &:before {
                content: "";
                display: block;

                padding-top: 90.16%;
            }
        }
        &._register & {
            &-bg {
                top: 50%;
                left: 40%;

                height: 100%;
            }
            &-front {
                top: 28%;
                left: 68%;

                width: 56.7%;
            }
        }

        &._share {
            align-self: start;

            max-width: 560px;
            &:before {
                content: "";
                display: block;
                padding-top: 115%;
            }
        }

        &._share & {
            &-bg {
                top: 50%;
                left: 40%;

                width: 80%;
            }
            &-front {
                top: 28%;
                left: 71%;

                width: 60%;
            }
        }

        &._use {
            max-width: 660px;
            &:before {
                content: "";
                display: block;
                padding-top: 97%;
            }
        }

        &._use & {
            &-bg {
                top: 50%;
                left: 50%;

                height: 100%;
            }
            &-front {
                top: 77%;
                left: 22%;

                width: 42.28%;
            }
        }
    }

    &__info {
        align-self: center;

        &-title,
        &-subtitle,
        &-description {
            opacity: 0;

            transform: translateY(1em) scale(0.85);

            transition: all @duration-normal ease-out;
        }
        &-subtitle {
            .typography-medium();

            margin-bottom: 32px;

            color: @color-gray-main;
        }

        &-title {
            .typography-landing-heading();

            margin-bottom: 30px;
            max-width: 510px;

            opacity: 0;
        }

        &-description {
            max-width: 550px;

            font-size: 28px;
            line-height: 32px;

            opacity: 0;
        }
    }

    @media @media-lg-down {
        &__info {
            &-title {
                .typography-extra-large();
            }
        }
    }

    @media @media-md-down {
        grid-template-columns: 1fr;
        grid-template-rows: auto;

        &._reversed > &__images,
        &__images {
            grid-column: 1;
            grid-row: 2;
        }

        &._reversed > &__info,
        &__info {
            grid-column: 1;
            grid-row: 1;

            &-title {
                max-width: 100%;
            }

            &-description {
                margin-bottom: 36px;
                max-width: 100%;
            }
        }
    }

    @media @media-sm-down {
        &__images {
            &._use & {
                &-bg {
                    left: 60%;
                }
                &-front {
                    left: 30%;
                }
            }
        }

        &__info {
            &-subtitle {
                margin-bottom: 24px;
            }

            &-title {
                .typography-large();

                margin-bottom: 24px;
            }

            &-description {
                .typography-small();
            }
        }
    }
}
</style>
