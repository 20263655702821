<template>
    <form class="page-cabinet-settings-form-notifications"
        v-on:submit.prevent="submitHandler"
        v-on:keydown.enter.prevent="submitHandler"
        >
        <div class="page-cabinet-settings-form-notifications__fields">
            <div class="page-cabinet-settings-form-notifications__fields-item">
                <ui-checkbox-with-text
                    v-model="sms"
                    v-bind:text="'SMS-оповещение об операциях'"
                />
            </div>
            <div class="page-cabinet-settings-form-notifications__fields-item">
                <ui-checkbox-with-text
                    v-model="email"
                    v-bind:text="'Email-оповещение об операциях'"
                />
            </div>
        </div>
        <div class="page-cabinet-settings-form-notifications__buttons">
            <div class="page-cabinet-settings-form-notifications__buttons-item">
                <ui-button type="submit">
                    Сохранить
                </ui-button>
            </div>
        </div>
    </form>
</template>

<script>
export default {
    name: 'page-cabinet-settings-form-notifications',
    data: () => ({
        sms: false,
        email: true,
    }),
    methods: {
        submitHandler() {
            // console.log('submit notifications');
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-settings-form-notifications {
    font-size: 1.4rem;
    line-height: 2rem;
    &__fields {
        margin-bottom: 24px;
        &-item {
            margin-bottom: 10px;
        }
    }
    &__buttons {
        display: flex;
        align-items: baseline;
        justify-content: flex-end;
        flex-wrap: wrap;
        width: ~'calc(100% + 24px)';
        margin: 0 -12px;
        &-item {
            .transition-fade();

            flex: 0 0 auto;
            margin: 0 12px;
        }
    }
    @media @media-md-down {
        &__buttons {
            flex-direction: column-reverse;
            flex-wrap: nowrap;
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            &-item {
                width: 100%;
                margin: 0;
                & ~ & {
                    margin-bottom: 16px;
                }
            }
        }
    }
}
</style>
