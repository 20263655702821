<template>
    <div class="page-cabinet-verification-profile-general">
        <ui-form
            v-bind:model="fields"
            v-bind:validation="$v"
            v-bind:submit-handler="submit"
            v-on:update="updateFormData"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import forms from '~/forms';

const isScreenLarge = () => window.innerWidth >= 768;

export default {
    name: 'page-cabinet-verification-profile-general',
    props: {
        isTouched: {
            type: Boolean,
        },
    },
    data: () => ({
        isLargeScreen: isScreenLarge(),
        observer: null,
    }),
    validations() {
        const result = { fields: {} };
        if (this.isSnilsNeeded) {
            result.fields.snils = { ...forms.validations.snils };
        }
        if (this.getUserProfileResult.legalType === 'J') {
            result.fields.inn = { ...forms.validations.inn10 };
        } else {
            result.fields.inn = { ...forms.validations.inn12 };
        }
        const nameValidation = {
            value: {
                isValid: forms.validations.isCyrillic.value.isValid,
                required: forms.validations.genericRequired.value.required,
            },
        };
        result.fields.lastName = nameValidation;
        result.fields.firstName = nameValidation;
        if (!this.fields.noSecondName.value) {
            result.fields.secondName = nameValidation;
        } else {
            result.fields.secondName = {};
        }
        result.fields.isRussianCitizen = {
            value: {
                isValid: value => !!value,
            },
        };
        result.fields.phone = { ...forms.validations.phone };
        result.fields.passportSeries = { ...forms.validations.passportSeries };
        result.fields.passportNumber = { ...forms.validations.passportNumber };
        return result;
    },
    computed: {
        ...mapState('users', [
            'getUserProfileResult',
        ]),
        ...mapState('verification/profile', [
            'general',
            'ip',
            'juridical',
        ]),
        isSnilsNeeded() {
            return this.getUserProfileResult && (this.getUserProfileResult.legalType === 'F' || this.getUserProfileResult.legalType === 'I');
        },
        fields() {
            let innFieldBase;
            if (this.getUserProfileResult.legalType === 'J') {
                innFieldBase = forms.fields.inn10dadata;
            } else if (this.getUserProfileResult.legalType === 'I') {
                innFieldBase = forms.fields.inn12dadata;
            } else {
                innFieldBase = forms.fields.inn12;
            }
            if (
                this.general.inn &&
                (
                    (
                        this.getUserProfileResult.legalType === 'I' &&
                        (!this.ip.ogrnip || !this.ip.ipName || !this.ip.ipAddress)
                    ) ||
                    (
                        this.getUserProfileResult.legalType === 'J' &&
                        (
                            !this.juridical.ogrn ||
                            !this.juridical.okved ||
                            !this.juridical.kpp ||
                            !this.juridical.organizationName ||
                            !this.juridical.organizationNameFull ||
                            !this.juridical.organizationAddress ||
                            !this.juridical.organizationRegistrationAddress ||
                            !this.juridical.organizationPostalAddress ||
                            !this.juridical.organisationRegistrationDate
                        )
                    )
                )
            ) {
                innFieldBase.isInitialRequestRequired = true;
            }
            let result = {
                inn: { ...innFieldBase },
            };
            if (this.isSnilsNeeded) {
                result = {
                    ...result,
                    snils: { ...forms.fields.snils },
                };
            }
            result = {
                ...result,
                lastName: { ...forms.fields.lastName, placeholder: 'Как в паспорте' },
                firstName: { ...forms.fields.firstName, placeholder: 'Как в паспорте' },
                secondName: { ...forms.fields.secondName, placeholder: 'При наличии' },
                noSecondName: { ...forms.fields.noSecondName },
                isRussianCitizen: { ...forms.fields.isRussianCitizen },
                phone: { ...forms.fields.phone },
                passportSeries: { ...forms.fields.passportSeries },
                passportNumber: { ...forms.fields.passportNumber },
            };
            if (this.getUserProfileResult.legalType === 'J') {
                result.firstName.label = result.firstName.label + ' ген. директора';
                result.secondName.label = result.secondName.label + ' ген. директора';
                result.lastName.label = result.lastName.label + ' ген. директора';
            }
            if (this.isLargeScreen) {
                result.phone.isFill = true;
                result.passportNumber.isHalf = true;
                result.passportSeries.isHalf = true;
            }
            Object.keys(result).forEach(key => {
                result[key].value = this.general[key] === null ? '' : this.general[key];
            });
            return result;
        },
    },
    methods: {
        submit() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            this.$emit('submit');
        },
        updateFormData(newData) {
            const resultGeneral = {};
            const resultIp = {};
            const resultJuridical = {};
            Object.keys(newData).filter(x => x !== 'inn').forEach(key => {
                resultGeneral[key] = newData[key].value === '' ? null : newData[key].value;
            });
            if (this.getUserProfileResult.legalType !== 'F' && typeof newData.inn?.value === 'object') {
                const data = newData.inn.value.data;
                resultGeneral.inn = data.inn;
                if (this.getUserProfileResult.legalType === 'I') {
                    if (data.fio?.name) {
                        resultGeneral.firstName = data.fio.name;
                    }
                    if (data.fio?.surname) {
                        resultGeneral.lastName = data.fio.surname;
                    }
                    if (data.fio?.patronymic) {
                        resultGeneral.secondName = data.fio.patronymic;
                    }
                    if (data.ogrn) {
                        resultIp.ogrnip = data.ogrn;
                    }
                    // eslint-disable-next-line camelcase
                    if (data.name?.short_with_opf) {
                        // eslint-disable-next-line camelcase
                        resultIp.ipName = data.name.short_with_opf;
                    }
                    // eslint-disable-next-line camelcase
                    if (data.address?.unrestricted_value) {
                        // eslint-disable-next-line camelcase
                        resultIp.ipAddress = data.address.unrestricted_value;
                    }
                } else if (this.getUserProfileResult.legalType === 'J') {
                    if (
                        data.management?.name &&
                        data.management?.post?.toLowerCase() === 'генеральный директор'
                    ) {
                        const [ lastName, firstName, secondName ] = data.management.name.split(' ');
                        if (firstName) {
                            resultGeneral.firstName = firstName;
                        }
                        if (lastName) {
                            resultGeneral.lastName = lastName;
                        }
                        if (secondName) {
                            resultGeneral.secondName = secondName;
                        }
                    }
                    if (data.ogrn) {
                        resultJuridical.ogrn = data.ogrn;
                    }
                    if (data.okved) {
                        resultJuridical.okved = data.okved;
                    }
                    if (data.kpp) {
                        resultJuridical.kpp = data.kpp;
                    }
                    // eslint-disable-next-line camelcase
                    if (data.name?.short_with_opf) {
                        // eslint-disable-next-line camelcase
                        resultJuridical.organizationName = data.name.short_with_opf;
                    }
                    // eslint-disable-next-line camelcase
                    if (data.name?.full_with_opf) {
                        // eslint-disable-next-line camelcase
                        resultJuridical.organizationNameFull = data.name.full_with_opf;
                    }
                    // eslint-disable-next-line camelcase
                    if (data.address?.unrestricted_value) {
                        // eslint-disable-next-line camelcase
                        resultJuridical.organizationAddress = data.address.unrestricted_value;
                        resultJuridical.organizationRegistrationAddress = data.address.unrestricted_value;
                        resultJuridical.organizationPostalAddress = data.address.unrestricted_value;
                    }
                    // eslint-disable-next-line camelcase
                    if (data.state?.registration_date) {
                        // eslint-disable-next-line camelcase
                        resultJuridical.organisationRegistrationDate = moment(data.state?.registration_date).format('DD.MM.YYYY');
                    }
                }
            } else {
                resultGeneral.inn = newData.inn.value;
            }
            this.$store.state.verification.profile.general = {
                ...this.$store.state.verification.profile.general,
                ...resultGeneral,
            };
            if (Object.keys(resultIp).length > 0) {
                this.$store.state.verification.profile.ip = {
                    ...this.$store.state.verification.profile.ip,
                    ...resultIp,
                };
            }
            if (Object.keys(resultJuridical).length > 0) {
                this.$store.state.verification.profile.juridical = {
                    ...this.$store.state.verification.profile.juridical,
                    ...resultJuridical,
                };
            }
        },
    },
    mounted() {
        this.observer = new ResizeObserver(() => {
            this.isLargeScreen = isScreenLarge();
        });
        this.observer.observe(document.body);
    },
    beforeDestroy() {
        this.observer.disconnect();
    },
    watch: {
        isTouched: {
            handler(newVal) {
                if (newVal) {
                    this.$v.$touch();
                }
            },
            immediate: true,
        },
        '$v.$dirty': {
            handler(newVal) {
                if (newVal) {
                    this.$emit('touch');
                }
            },
            immediate: true,
        },
        '$v.$invalid': {
            handler(newVal) {
                this.$emit('validation-update', { entityName: 'isGeneralDataValid', value: !newVal });
            },
            immediate: true,
        },
        'fields.secondName.value': {
            handler(newVal, oldVal) {
                if (newVal && !oldVal) {
                    this.$store.state.verification.profile.general = {
                        ...this.$store.state.verification.profile.general,
                        noSecondName: false,
                    };
                }
            },
            immediate: true,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-verification-profile-general {
}
</style>
