<template>
    <div class="ui-input-radio-group">
        <div class="ui-input-radio-group__item"
            v-for="(option, i) in model.options"
            v-bind:key="i"
            >
            <ui-input-radio-item
                v-bind:model="{ ...option }"
                v-bind:value="value"
                v-bind:name="_uid + '-' + i"
                v-on:change="changeHandler"
                v-on:focus="focusHandler"
                v-on:blur="blurHandler"
            />
            <!-- v-bind:is-invalid="isInvalid" -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'ui-input-radio-group',
    props: {
        model: {
            type: Object,
        },
        isInvalid: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [ String, Number, Boolean ],
        },
    },
    model: {
        prop: 'value',
        event: 'change',
    },
    methods: {
        changeHandler(newValue) {
            this.$emit('change', newValue);
        },
        focusHandler(data) {
            this.$emit('focus', data);
        },
        blurHandler(data) {
            this.$emit('blur', data);
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-input-radio-group {
    &__item {
        & ~ & {
            margin-top: 10px;
        }
    }
}
</style>
