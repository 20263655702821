<template>
    <section class="plugin-home-about">
        <div class="plugin-home-about__image"
            v-bind:style="backgroundStyle"
            >
        </div>
        <div class="plugin-home-about__content">
            <h2 class="plugin-home-about__title"
                v-text="title"
            />
            <h3 class="plugin-home-about__subtitle"
                v-text="subtitle"
            />
            <div class="plugin-home-about__text">
                <span
                    v-text="text"
                />
            </div>
            <div class="plugin-home-about__button">
                <a class="plugin-home-about__button-link"
                    v-bind:href="buttonUrl"
                    >
                    <ui-button
                        v-bind:text="buttonText"
                        theme="inverted"
                        size="lg"
                    />
                </a>
            </div>
        </div>
    </section>
</template>

<script>
import utils from '~/utils';

export default {
    name: 'plugin-home-about',
    props: {
        image: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
        subtitle: {
            type: String,
            default: null,
        },
        text: {
            type: String,
            default: null,
        },
        buttonUrl: {
            type: String,
            default: null,
        },
        buttonText: {
            type: String,
            default: null,
        },
    },
    computed: {
        backgroundStyle() {
            if (!this.image) {
                return null;
            }
            const resize = utils.common.getResize({
                1920: 43,
                1023: 100,
            });
            return { backgroundImage: `url(${utils.common.injectResize(this.image, resize)})` };
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-home-about {
    .container();

    position: relative;
    &__image {
        position: absolute;
        top: 80px;
        right: 40px;
        bottom: -80px;

        width: 42.71%;
        max-width: 100%;
        border-radius: @border-radius-lg;

        background-color: @color-gray-main;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    &__content {
        position: relative;

        width: 57.574%;
        padding-top: 80px;
        padding-right: calc(@grid-gap + @grid-column);
        padding-bottom: 80px;
        padding-left: calc(@grid-gap + @grid-column);
        border-radius: @border-radius-lg;

        color: @color-gray-lightest;

        background-color: @color-gray-darker;
    }
    &__title {
        .typography-extra-large();

        margin: 0 0 40px;
    }
    &__subtitle {
        .typography-large();

        margin: 0 0 24px;

        font-weight: 400;
    }
    &__text {
        .typography-small();

        margin-bottom: 60px;

        white-space: pre-line;
    }
    &__button {
        display: flex;
        justify-content: flex-start;
        &-link {
            display: block;

            text-decoration: none;
        }
    }
    @media @media-md-down {
        padding: 0 0 304px;

        &__image {
            top: auto;
            right: @grid-gap;
            bottom: 0;
            left: @grid-gap;
            z-index: 2;

            width: auto;
            height: 360px;
        }
        &__content {
            .container();

            width: 100%;
            padding-top: 62px;
            padding-bottom: 96px;
            border-radius: 0;
        }
        &__title {
            .typography-large();

            margin-bottom: 24px;
        }
        &__subtitle {
            .typography-small();

            margin-bottom: 24px;
        }
        &__text {
            .typography-body();

            margin-bottom: 40px;
        }
        &__button {
            &-link {
                width: 100%;
                max-width: 320px;
            }
        }
    }
}
</style>
