<template>
    <div class="common-footer-contacts">
        <span class="common-footer-contacts__item">
            Если у вас возникли вопросы или есть предложение о сотрудничестве, свяжитесь с нами:
        </span>
<!--        <br />-->
<!--        <a class="common-footer-contacts__item"-->
<!--            href="#"-->
<!--            v-on:click.prevent="showFeedbackModal"-->
<!--            v-text="'Форма обратной связи'"-->
<!--        />-->
        <br />
        <a class="common-footer-contacts__item"
            v-bind:href="'tel:' + contacts.phone.value"
            v-text="contacts.phone.valueFormatted"
        />
        <br />
        <a class="common-footer-contacts__item"
            v-bind:href="'mailto:' + contacts.email.value"
            v-text="contacts.email.value"
        />
        <br />
        <a class="common-footer-contacts__item"
            v-bind:href="urls.telegram"
            v-text="'Наш Telegram'"
        />
        <!-- <div class="common-footer-contacts__socials">
            <a class="common-footer-contacts__socials-item"
                v-bind:href="urls.facebook"
                target="_blank"
                >
                <icon name="facebook" />
            </a>
            <a class="common-footer-contacts__socials-item"
                v-bind:href="urls.instagram"
                target="_blank"
                >
                <icon name="instagram" />
            </a>
            <a class="common-footer-contacts__socials-item"
                v-bind:href="urls.telegram"
                target="_blank"
                >
                <icon name="telegram" />
            </a>
        </div> -->
    </div>
</template>

<script>
export default {
    name: 'common-footer-contacts',
    computed: {
        contacts() {
            return this.$store.getters['site/contacts'];
        },
        urls() {
            return {
                // facebook: this.$store.state.site.contacts.socials.facebook.href,
                // instagram: this.$store.state.site.contacts.socials.instagram.href,
                telegram: this.$store.state.site.contacts.socials.telegram.href,
            };
        },
    },
    methods: {
        showFeedbackModal() {
            this.$store.commit('modals/push', 'feedback');
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.common-footer-contacts {
    .typography-body();
    &__item {
        display: inline-block;
        margin-top: 16px;
    }
    // &__socials {
    //     display: flex;
    //     align-items: center;
    //     justify-content: flex-start;
    //     margin: 4px -12px -12px;
    //     &-item {
    //         flex: 0 0 auto;
    //         padding: 6px;
    //         margin: 6px;

    //         font-size: 1.8rem;
    //     }
    // }
    @media @media-md-down {
    }
}
</style>
