// import moment from 'moment';
import { date as dateTransform } from '~/network/mainClient/common/transforms/requests';

export const calculate = (data) => {
    return {
        ...data,
    };
};

export const issue = (data) => {
    return {
        quantity: data.quantity,
        price: data.priceMarket,
        nominal_price: data.priceNominal,
        registration_number: data.registrationNumber,
        registration_date: dateTransform(data.registrationDate),
    };
};
