<template>
    <div>
        plugin-map
    </div>
</template>

<script>
export default {
    name: 'plugin-map',
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-map {
    position: relative;

    display: block;
    width: 100%;
    height: 368px;
    border-radius: 10px;
    overflow: hidden;

    filter: grayscale(1);

    iframe {
        width: 100% !important;
        height: 100% !important;
    }
}
</style>
