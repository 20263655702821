import { mainClient } from '../../network';

const moduleTitle = 'estates';

const state = {
    items: {
        state: 'initial',
        data: null,
        count: 0,
    },

    getItemResult: null,
    getItemIsLoading: null,
    getItemError: null,

    getEstateTypesResult: null,
    getEstateTypesIsLoading: null,
    getEstateTypesError: null,

    getChartSharesDataResult: null,
    getChartSharesDataIsLoading: false,
    getChartSharesDataError: null,

    getChartDividendsDataResult: null,
    getChartDividendsDataIsLoading: false,
    getChartDividendsDataError: null,
};

const getters = {
    items(state) {
        return state.items.data;
    },
    count(state) {
        return state.items.count;
    },
    isLoading(state) {
        return state.items.state === 'loading';
    },
};

const actions = {
    async getItem({ state }, payload = {}) {
        if (state.getItemIsLoading) {
            return;
        }
        state.getItemIsLoading = true;
        const { data, error } = await mainClient[moduleTitle].requests.getItem(payload);
        if (error) {
            state.getItemResult = null;
            state.getItemError = error;
        } else if (data) {
            state.getItemResult = data;
            state.getItemError = null;
        }
        state.getItemIsLoading = false;
    },
    async getItems({ state, commit }, payload = {}) {
        if (state.items.state === 'loading') {
            return;
        }
        commit('setItemsState', 'loading');
        const { data, error, status } = await mainClient[moduleTitle].requests.getItems(payload);
        if (error) {
            console.error(`Error in action ${moduleTitle}/getItems (${status})`);
            commit('setItemsData', []);
            commit('setItemsState', 'error');
        } else if (data) {
            commit('setItemsData', data.items);
            commit('setItemsCount', data.paging.count);
            commit('setItemsState', 'loaded');
        }
    },
    async getEstateTypes({ state }, payload = {}) {
        if (state.getEstateTypesIsLoading) {
            return;
        }
        state.getEstateTypesIsLoading = true;
        const { data, error, status } = await mainClient[moduleTitle].requests.getTypes(payload);
        if (error) {
            console.error(`Error in action ${moduleTitle}/getTypes (${status})`);
            state.getEstateTypesResult = null;
            state.getEstateTypesError = error;
        } else if (data) {
            state.getEstateTypesResult = data.items;
            state.getEstateTypesError = null;
        }
        state.getEstateTypesIsLoading = false;
    },
    async getChartSharesData({ state }, { id } = {}) {
        if (state.getChartSharesDataIsLoading) {
            return;
        }
        state.getChartSharesDataIsLoading = true;
        const { data, error } = await mainClient[moduleTitle].requests.getChartSharesData({ id });
        if (error) {
            state.getChartSharesDataResult = null;
            state.getChartSharesDataError = error;
        } else if (data) {
            state.getChartSharesDataResult = data;
            state.getChartSharesDataError = null;
        }
        state.getChartSharesDataIsLoading = false;
    },
    async getChartDividendsData({ state }, { id } = {}) {
        if (state.getChartDividendsDataIsLoading) {
            return;
        }
        state.getChartDividendsDataIsLoading = true;
        const { data, error } = await mainClient[moduleTitle].requests.getChartDividendsData({ id });
        if (error) {
            state.getChartDividendsDataResult = null;
            state.getChartDividendsDataError = error;
        } else if (data) {
            state.getChartDividendsDataResult = data;
            state.getChartDividendsDataError = null;
        }
        state.getChartDividendsDataIsLoading = false;
    },
};

const mutations = {
    setItemsState(state, value) {
        state.items.state = value;
    },
    setItemsData(state, value) {
        state.items.data = value;
    },
    setItemsCount(state, value) {
        state.items.count = value;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
