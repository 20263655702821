<template>
    <section class="page-admin-estates-item-legal">
        <div class="page-admin-estates-item-legal__block"
            v-for="(item, i) in blocks"
            v-bind:key="i"
            >
            <page-admin-estates-item-legal-block
                v-bind:title="item.title"
                >
                <component
                    v-bind:is="'page-admin-estates-item-legal-' + item.component"
                    v-bind:fields="item.fields"
                    v-bind:validation="$v"
                    v-on:update="updateFormData"
                    v-on:submit="submitFormData"
                />
            </page-admin-estates-item-legal-block>
        </div>
    </section>
</template>

<script>
import { mapState } from 'vuex';
import forms from '~/forms';

export default {
    name: 'page-admin-estates-item-legal',
    validations() {
        const result = {
            fields: {
                // general
                orgTitle: { ...forms.validations.genericRequired },
                orgAddress: { ...forms.validations.genericRequired },
                orgJurAddress: { ...forms.validations.genericRequired },
                orgMailAddress: { ...forms.validations.genericRequired },
                ogrn: { ...forms.validations.ogrnEstate },
                okved: { ...forms.validations.genericRequired },
                inn: { ...forms.validations.inn10 },
                registrationDate: { ...forms.validations.date },
                // banking
                bankTitle: { ...forms.validations.genericRequired },
                kpp: { ...forms.validations.kppEstate },
                bik: { ...forms.validations.bik },
                bankAccount: { ...forms.validations.genericRequired },
                cadastralAccount: { ...forms.validations.genericRequired },
            },
        };
        return result;
    },
    computed: {
        ...mapState('admin/estates', {
            getEstateInfoResult: state => state.getEstateInfoResult,
            // general
            orgTitle: state => state.getEstateInfoResult.orgTitle,
            orgAddress: state => state.getEstateInfoResult.orgAddress,
            orgJurAddress: state => state.getEstateInfoResult.orgJurAddress,
            orgMailAddress: state => state.getEstateInfoResult.orgMailAddress,
            ogrn: state => state.getEstateInfoResult.ogrn,
            okved: state => state.getEstateInfoResult.okved,
            inn: state => state.getEstateInfoResult.inn,
            registrationDate: state => state.getEstateInfoResult.registrationDate,
            // banking
            bankTitle: state => state.getEstateInfoResult.bankTitle,
            kpp: state => state.getEstateInfoResult.kpp,
            bik: state => state.getEstateInfoResult.bik,
            bankAccount: state => state.getEstateInfoResult.bankAccount,
            cadastralAccount: state => state.getEstateInfoResult.cadastralAccount,
        }),
        fields: {
            get() {
                const fields = {
                    // general
                    orgTitle: { ...forms.fields.admin.estates.orgTitle, value: this.orgTitle, isHalf: this.isDesktop },
                    orgAddress: { ...forms.fields.admin.estates.orgAddress, value: this.orgAddress },
                    orgJurAddress: { ...forms.fields.admin.estates.orgJurAddress, value: this.orgJurAddress },
                    orgMailAddress: { ...forms.fields.admin.estates.orgMailAddress, value: this.orgMailAddress },
                    ogrn: { ...forms.fields.admin.estates.ogrn, value: this.ogrn, isHalf: this.isDesktop },
                    okved: { ...forms.fields.admin.estates.okved, value: this.okved, isHalf: this.isDesktop },
                    inn: { ...forms.fields.inn10, value: this.inn, isHalf: this.isDesktop },
                    registrationDate: { ...forms.fields.admin.estates.registrationDate, value: this.registrationDate, isHalf: this.isDesktop },
                    // banking
                    bankTitle: { ...forms.fields.admin.estates.bankTitle, value: this.bankTitle },
                    kpp: { ...forms.fields.admin.estates.kpp, value: this.kpp, isHalf: this.isDesktop },
                    bik: { ...forms.fields.admin.estates.bik, value: this.bik, isHalf: this.isDesktop },
                    bankAccount: { ...forms.fields.admin.estates.bankAccount, value: this.bankAccount, isHalf: this.isDesktop },
                    cadastralAccount: { ...forms.fields.admin.estates.cadastralAccount, value: this.cadastralAccount, isHalf: this.isDesktop },
                };
                return fields;
            },
            set(newData) {
                const data = { ...this.getEstateInfoResult };
                for (const [key, field] of Object.entries(newData)) {
                    data[key] = field.value;
                }
                this.$store.commit('admin/estates/setUpdateEstateInfoIsSaved', false);
                this.$store.commit('admin/estates/setUpdateEstateInfoInfoIsButtonActive', true);
                this.$store.commit('admin/estates/setEstateInfo', data);
            },
        },
        isDesktop() {
            return window.innerWidth >= 768;
        },
        blocks() {
            return [
                {
                    component: 'general',
                    title: 'Основная информация',
                    fields: {
                        orgTitle: this.fields.orgTitle,
                        orgAddress: this.fields.orgAddress,
                        orgJurAddress: this.fields.orgJurAddress,
                        orgMailAddress: this.fields.orgMailAddress,
                        ogrn: this.fields.ogrn,
                        okved: this.fields.okved,
                        inn: this.fields.inn,
                        registrationDate: this.fields.registrationDate,
                    },
                },
                {
                    component: 'banking',
                    title: 'Банковские данные',
                    fields: {
                        bankTitle: this.fields.bankTitle,
                        kpp: this.fields.kpp,
                        bik: this.fields.bik,
                        bankAccount: this.fields.bankAccount,
                        cadastralAccount: this.fields.cadastralAccount,
                    },
                },
            ];
        },
        isInvalid() {
            return this.$v.$invalid;
        },
        isTouched() {
            return this.$v.$dirty;
        },
        isSaved() {
            return this.$store.state.admin.estates.updateEstateInfoIsSaved;
        },
        isTouchNeeded() {
            return this.$store.state.admin.estates.updateEstateInfoIsTouchNeeded;
        },
    },
    methods: {
        updateFormData(newData) {
            this.fields = { ...this.fields, ...newData };
        },
        submitFormData() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.$store.dispatch('admin/estates/updateEstateInfo');
            }
        },
    },
    watch: {
        isInvalid: {
            immediate: true,
            handler(newVal) {
                this.$emit('set-validity', !newVal);
                this.$store.commit('admin/estates/setUpdateEstateInfoIsInvalid', newVal);
            },
        },
        isTouched: {
            immediate: true,
            handler(newVal) {
                this.$store.commit('admin/estates/setUpdateEstateInfoIsTouched', newVal);
            },
        },
        isTouchNeeded: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.$v.$touch();
                    this.$store.commit('admin/estates/setUpdateEstateInfoIsTouchNeeded', false);
                }
            },
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-admin-estates-item-legal {
    &__block {
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 40px;
        }
    }
}
</style>
