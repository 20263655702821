// import config from '~/config';
// import { mainClient } from '~/network';
// import utils from '../../utils';

const state = {
    general: {
        inn: null,
        firstName: null,
        lastName: null,
        secondName: null,
        noSecondName: false,
        // citizenship: null,
        isRussianCitizen: true,
        phone: null,
        passportNumber: null,
        passportSeries: null,
        snils: null,
    },
    photos: {
        first: null,
        registration: null,
    },
    foreigner: {
        migrationCardNumber: null,
        migrationCardDateStart: null,
        migrationCardDateEnd: null,
        isNoVisaRequired: true,
        migrationDocumentNumber: null,
        migrationDocumentDateStart: null,
        migrationDocumentDateEnd: null,
    },
    ip: {
        ogrnip: null,
        ipName: null,
        ipAddress: null,
    },
    individual: {
        hasIp: null,
    },
    juridical: {
        organizationName: null,
        organizationNameFull: null,
        organizationAddress: null,
        organizationRegistrationAddress: null,
        organizationPostalAddress: null,
        bankName: null,
        ogrn: null,
        okved: null,
        kpp: null,
        bik: null,
        corrAccount: null,
        organisationRegistrationDate: null,
    },
};

const getters = {
};

const actions = {
    // async getUserProfile({ state }) {
    //     if (state.getUserProfileIsLoading) {
    //         return;
    //     }

    //     state.getUserProfileIsLoading = true;

    //     const { data, error, status } = await mainClient.users.requests.getUserProfile();
    //     if (error) {
    //         console.error(`Error in action ${moduleTitle}/getUserProfile (${status})`);
    //     } else {
    //         state.getUserProfileResult = data;
    //     }
    //     state.getUserProfileIsLoading = false;
    // },
};

const mutations = {
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
