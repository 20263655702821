<template>
    <section class="plugin-about-video-slider">
        <div class="plugin-about-video-slider__container">
            <div class="plugin-about-video-slider__wrapper">
                <div class="swiper-slide plugin-about-video-slider__title"
                    v-if="title"
                    v-bind:class="{ _centred: centred }"
                    v-text="title"
                />
                <div class="swiper-slide plugin-about-video-slider__slider">
                    <div class="swiper-container"
                        ref="slider"
                        >
                        <div class="swiper-wrapper"
                            v-bind:class="{ _list: isDesktop }"
                            >
                            <div class="swiper-slide plugin-about-video-slider__item"
                                v-for="(slide, index) in slidesResized"
                                v-bind:key="index"
                                >
                                <div class="plugin-about-video-slider__item-image swiper-lazy"
                                    v-bind:style="isDesktop ? { backgroundImage: `url(${slide.image}` } : null"
                                    v-bind:data-background="slide.image"
                                    v-on:click="showVideoModal(slide)"
                                    >
                                    <div class="plugin-about-video-slider__item-image-loader"
                                        v-if="!isDesktop"
                                        >
                                        <ui-loader
                                            v-bind:fixed="50"
                                            v-bind:centered="true"
                                            >
                                        </ui-loader>
                                    </div>
                                </div>
                                <div class="plugin-about-video-slider__item-caption"
                                    v-if="slide.caption"
                                    v-text="slide.caption"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="plugin-about-video-slider__more"
                        v-if="isDesktop && isMoreAvailable"
                        >
                        <ui-trigger
                            v-on:click="toggleMore"
                            v-bind:text="isDesktopExpanded ? 'Свернуть' : 'Показать все'"
                            theme="secondary"
                            is-underlined
                        />
                    </div>
                    <div class="plugin-about-video-slider__navigation">
                        <div class="plugin-about-video-slider__navigation-item _prev"
                            ref="prev"
                            >
                            <icon name="arrow-left" />
                        </div>
                        <div class="plugin-about-video-slider__navigation-item _next"
                            ref="next"
                            >
                            <icon name="arrow-right" />
                        </div>
                    </div>
                    <div class="plugin-about-video-slider__pagination"
                        ref="pagination"
                        >
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Swiper from 'swiper/dist/js/swiper';
import utils from '~/utils';

export default {
    name: 'plugin-about-video-slider',
    props: {
        title: {
            type: String,
            default: null,
        },
        centred: {
            type: Boolean,
            default: false,
        },
        slides: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        slider: null,
        isDesktop: false,
        slidesDesktopLimit: 9,
        isDesktopExpanded: false,
    }),
    computed: {
        slidesResized() {
            const resize = utils.common.getResize({
                1920: '355',
            }, 0.5634);
            const slides = this.isDesktop
                ? this.slides.slice(0, this.isDesktopExpanded ? this.slides.length : this.slidesDesktopLimit)
                : this.slides;
            return slides.map(x => ({
                ...x,
                image: utils.common.injectResize(x.image, resize),
            }));
        },
        isMoreAvailable() {
            return this.slides.length > this.slidesDesktopLimit;
        },
    },
    methods: {
        toggleMore() {
            this.isDesktopExpanded = !this.isDesktopExpanded;
        },
        initSwiper() {
            if (this.slider !== null) {
                this.slider.update();
                return;
            }
            const container = this.$refs.slider;
            const paginationEl = this.$refs.pagination;
            const prevEl = this.$refs.prev;
            const nextEl = this.$refs.next;
            this.slider = new Swiper(container, {
                speed: 600,
                slidesPerView: 3,
                spaceBetween: 32,
                centerInsufficientSlides: true,
                loop: this.slides.length > 3,
                preloadImages: false,
                watchSlidesVisibility: true,
                lazy: {
                    loadPrevNext: true,
                },
                navigation: {
                    prevEl,
                    nextEl,
                },
                mousewheel: {
                    forceToAxis: true,
                },
                pagination: {
                    el: paginationEl,
                    type: 'bullets',
                    bulletClass: 'plugin-about-video-slider__pagination-item',
                    bulletActiveClass: '_active',
                    clickable: true,
                },
                breakpoints: {
                    1023: {
                        slidesPerView: 2,
                    },
                    767: {
                        slidesPerView: 1,
                        spaceBetween: 24,
                    },
                },
            });
        },
        showVideoModal(slide) {
            if (!slide.videoCode) {
                return;
            }
            this.$store.commit('modals/push', {
                name: 'video',
                props: {
                    code: slide.videoCode,
                },
            });
        },
        destroySwiper() {
            if (this.slider === null) {
                return;
            }
            this.slider.destroy();
            this.slider = null;
        },
        checkSize() {
            this.isDesktop = window.innerWidth >= 1024;
            if (this.isDesktop) {
                this.destroySwiper();
            } else {
                this.initSwiper();
            }
        },
    },
    mounted() {
        this.checkSize();
        window.addEventListener('resize', this.checkSize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkSize);
        this.destroySwiper();
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-about-video-slider {
    &__container {
        .container();
    }
    &__wrapper {
        position: relative;

        padding: 120px calc(@grid-column + @grid-gap);
    }
    &__title {
        .typography-extra-large();

        margin-bottom: 40px;
        &._centred {
            text-align: center;
        }
    }
    &__slider {
        position: relative;
    }
    .swiper-wrapper._list {
        flex-wrap: wrap;
        align-items: stretch;
        width: auto;
        margin: -16px;
    }
    .swiper-container {
        width: 100%;
    }
    &__item {
        .swiper-wrapper._list & {
            flex: 0 0 auto;
            width: 33.33%;
            padding: 16px;
        }
        &-image {
            position: relative;

            width: 100%;
            padding-top: 51.65%;
            border-radius: @border-radius-lg;

            background-color: @color-gray-lightest;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;

            background-color: @color-gray-lighter;

            cursor: pointer;
            &-loader {
                position: absolute;
                top: 50%;
                left: 50%;

                transform: translate(-50%, -50%);

                transition: opacity @duration-fast ease-in-out;
                .swiper-lazy-loaded & {
                    opacity: 0;
                }
            }
        }
        &-caption {
            .typography-medium();

            margin-top: 24px;

            color: @color-gray-darkest;
        }
    }
    &__more {
        display: flex;
        align-items: center;
        justify-content: center;
        width: min-content;
        padding: 24px;
        margin: 0 auto;
    }
    &__navigation {
        position: absolute;
        top: auto;
        bottom: 0;
        left: 0;

        display: none;
        width: 100%;

        pointer-events: none;
        &-item {
            position: absolute;
            top: auto;
            bottom: 0;
            z-index: 1;

            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: 1px solid @color-gray-light;

            font-size: 1.6rem;
            text-align: center;

            background: fade(@color-gray-lightest, 0%);

            pointer-events: all;
            cursor: pointer;
            outline: none;

            transition: background-color @duration-fast ease-in-out;

            &:hover {
                background: fade(@color-gray-lightest, 66%);
            }
            &._prev {
                left: 0;
            }
            &._next {
                right: 0;
            }
        }
    }
    &__pagination {
        position: absolute;
        bottom: 0;
        left: 0;

        display: none;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 40px;
        padding: 8px;
        /deep/ &-item {
            position: relative;

            display: block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            margin: 4px;

            background-color: @color-gray-darker;

            cursor: pointer;
            outline: none;
            opacity: 0.4;

            transition: opacity @duration-fast ease-in-out;
            &:before {
                content: '';

                position: absolute;
                top: -4px;
                right: -4px;
                bottom: -4px;
                left: -4px;

                display: block;
            }
            &:hover {
                opacity: 1;
            }
            &._active {
                opacity: 1;
            }
        }
    }
    @media @media-md-down {
        &__wrapper {
            padding: 60px 0;
        }
        &__title {
            .typography-large();

            margin-bottom: 24px;
            &._centred {
                text-align: left;
            }
        }
        &__slider {
            padding-bottom: 64px;
        }
        &__pagination {
            display: flex;
        }
        &__navigation {
            display: block;
        }
    }
}
</style>
