<template>
    <div class="ui-icon">
        <icon v-bind:name="icon" />
    </div>
</template>

<script>
export default {
    name: 'ui-icon',
    props: {
        icon: {
            type: String,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-icon {
}
</style>
