<template>
    <div class="page-cabinet-verification-finance">
        <transition name="fade">
            <div class="page-cabinet-verification-finance__loader"
                v-if="isLoading"
                >
                <div class="page-cabinet-verification-finance__loader-container">
                    <ui-loader />
                </div>
            </div>
        </transition>
        <page-cabinet-verification-finance-general
            v-bind:fields="fields"
            v-bind:validation="$v"
            v-on:update="updateFormData"
            v-on:submit="submitFormData"
        />
        <div class="page-cabinet-verification-finance__buttons">
            <div class="page-cabinet-verification-finance__buttons-item">
                <ui-button
                    v-bind:disabled="!isValid || isLoading"
                    v-on:click="submitFormData"
                    type="button"
                    theme="primary"
                    size="lg"
                    >
                    Далее
                </ui-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import forms from '~/forms';

export default {
    name: 'page-cabinet-verification-finance',
    props: {
        isFilled: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        initialValidation: false,
        windowSizeThreshold: 768,
        requiredValidation: { ...forms.validations.genericRequired },
        innerData: {
            additionalIncomeSource: { ...forms.fields.user.additional.additionalIncomeSource },
            additionalIncomeSourceCustom: { ...forms.fields.user.additional.additionalIncomeSourceCustom, isHalf: true },
            additionalBusinessReputation: { ...forms.fields.user.additional.additionalBusinessReputation },
        },
    }),
    validations() {
        const result = {
            fields: {
                additionalIncomeSource: this.requiredValidation,
            },
        };
        const options = this.innerData.additionalIncomeSource.options;
        if (this.innerData.additionalIncomeSource.value === options[options.length - 1].value) {
            result.fields.additionalIncomeSourceCustom = this.requiredValidation;
        }
        return result;
    },
    computed: {
        ...mapState('users', [
            'getUserProfileResult',
            'getUserProfileIsLoading',

            'getUserAdditionalInfoResult',
            'getUserAdditionalInfoIsLoading',
        ]),
        fields: {
            get() {
                return this.innerData;
            },
            set(newFields) {
                for (let key in newFields) {
                    if (this.innerData[key] !== undefined && this.innerData[key].value !== newFields[key].value) {
                        this.innerData[key].value = newFields[key].value;
                    }
                }
            },
        },
        isLoading() {
            return this.getUserProfileIsLoading ||
                this.getUserAdditionalInfoIsLoading;
        },
        storeAdditionalInfo() {
            return this.getUserAdditionalInfoResult;
        },
        user() {
            return this.$store.getters['users/user'];
        },
        isValid() {
            return !this.$v.$invalid;
        },
        isLargeScreen() {
            return window.innerWidth >= this.windowSizeThreshold;
        },
    },
    methods: {
        updateFormData(newData) {
            if (this.isFilled) {
                this.$emit('unfilled', 'finance');
            }
            this.fields = newData;
        },
        submitFormData() {
            if (!this.isValid || this.isLoading) {
                this.$v.$touch();
                return;
            }
            const result = {};
            for (let key in this.innerData) {
                result[key] = this.innerData[key].value;
            }
            this.$store.dispatch('users/updateUserAdditionalInfo', result);
            this.$emit('filled', 'finance');
            this.$emit('submitted');
            this.$v.$reset();
        },
    },
    beforeMount() {
        this.$store.dispatch('users/getUserProfile');
        this.$store.dispatch('users/getUserAdditionalInfo');
    },
    watch: {
        storeAdditionalInfo(newVal, oldVal) {
            if (newVal !== null) {
                const result = {};
                for (let key in newVal) {
                    result[key] = {};
                    result[key].value = newVal[key];
                }
                this.fields = result;
                if (oldVal === null) {
                    this.initialValidation = true;
                }
            }
        },
        isValid: {
            immediate: true,
            handler(newVal) {
                if (this.initialValidation) {
                    this.initialValidation = false;
                    if (newVal) {
                        this.$emit('filled', 'finance');
                    } else {
                    }
                }
            },
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-verification-finance {
    position: relative;
    &__loader {
        .transition-fade();

        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        background-color: fade(@color-gray-lightest, 50%);
        &-container {
            width: 100px;
            margin: auto;
        }
    }
    &__subtitle {
        .typography-medium();

        margin: 40px 0 24px;
    }
    &__buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        &-item {
            width: 100%;
            max-width: 290px;
            padding: 12px;
        }
    }
    @media @media-md-down {
        &__top {
            flex-direction: column;
            &-info {
                width: 100%;
                margin-right: 0;
            }
            &-photo {
                width: 100%;
            }
        }
    }
}
</style>
