<template>
    <article class="plugin-card-rental-stream">
        <div class="plugin-card-rental-stream__container">
            <ui-graph
                v-bind:data="data"
                v-bind:categories="categories"
                is-stacked
                >
            </ui-graph>
        </div>
        <div class="plugin-card-rental-stream__comment"
            v-if="comment"
            v-text="comment"
        />
    </article>
</template>

<script>
export default {
    name: 'plugin-card-rental-stream',
    props: {
        topTitle: String,
        bottomTitle: String,
        comment: String,
        items: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        data() {
            return [
                {
                    name: this.bottomTitle,
                    data: this.items.map(x => x.bottomValue),
                },
                {
                    name: this.topTitle,
                    data: this.items.map(x => x.topValue),
                },
            ];
        },
        categories() {
            return this.items.map(x => x.label);
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-card-rental-stream {
    &__container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        min-height: 225px;
    }
    &__comment {
        flex: 0 0 auto;
        .typography-body();

        white-space: pre-line;
    }
}
</style>
