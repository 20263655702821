<template>
    <div class="ui-table-cell-date"
        v-text="dateFormatted"
    />
</template>

<script>
import moment from 'moment';

export default {
    name: 'ui-table-cell-date',
    props: {
        date: {
            default: () => new Date(),
        },
    },
    computed: {
        dateFormatted() {
            return moment(this.date).format('DD.MM.YYYY');
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-table-cell-date {
    .typography-body();

    color: @color-gray-main;
}
</style>
