import { amount } from '~/forms/fields/other';

export const document = {
    name: 'document',
    text: 'Инвестиционное предложение в форматe .docx',
    type: 'file',
    value: null,
    initialValue: null,
    accept: '.docx',
    errors: {
        required: 'Загрузите файл',
    },
};

export const issueDate = {
    name: 'issueDate',
    label: 'Дата регистрации выпуска',
    value: '',
    initialValue: '',
    type: 'date',
    errors: {
        required: 'Введите дату',
        isValid: 'Введите корректную дату',
    },
};

export const issueNumber = {
    name: 'issueNumber',
    label: 'Регистрационный номер/государственный регистрационный номер выпуска (дополнительного выпуска)',
    value: '',
    initialValue: '',
    type: 'text',
    errors: {
        required: 'Введите номер',
    },
};

export const investmentExpireDate = {
    name: 'investmentExpireDate',
    label: 'Инвестиционное предложение действует до',
    value: '',
    initialValue: '',
    type: 'date',
    errors: {
        required: 'Введите дату',
        isValid: 'Введите корректную дату',
    },
};

export const purpose = {
    name: 'purpose',
    label: 'Назначение платежа',
    value: '',
    initialValue: '',
    type: 'text',
    errors: {
        required: 'Введите назначение платежа',
    },
};

export const preferenceSharesAmount = {
    name: 'preferenceSharesAmount',
    label: 'Общее кол-во привилегированных акций',
    value: '',
    initialValue: '',
    placeholder: '',
    type: 'numeric',
    errors: {
        required: 'Введите количество',
    },
};

export const investmentRequestAmount = {
    ...amount,
    label: 'Сумма',
    errors: {
        required: amount.errors.required,
        numeric: amount.errors.numeric,
    },
};
