<template>
    <section class="page-estates-item">
        <div class="page-estates-item--container">
            <section class="page-estates-item__headline">
                <div class="page-estates-item__headline-cover">
                    <page-estates-item-cover
                        v-bind:estate="estate"
                    />
                </div>
                <div class="page-estates-item__headline-info">
                    <div class="page-estates-item__headline-info-content">
                        <div class="page-estates-item__headline-info-content-label"
                            v-if="estate.state === 'collecting'"
                            >
                            <ui-label
                                title="Идет сбор средств"
                                theme="gradient"
                                icon-left="clock"
                                is-uppercase
                            />
                        </div>
                        <h1 class="page-estates-item__headline-info-content-title"
                            v-text="estate.title"
                        />
                        <div class="page-estates-item__headline-info-content-lines">
                            <div class="page-estates-item__headline-info-content-lines-item">
                                <div class="page-estates-item__headline-info-content-lines-item-icon">
                                    <icon v-bind:name="estate.type.icon" />
                                </div>
                                <div class="page-estates-item__headline-info-content-lines-item-text"
                                    v-text="estate.type.title"
                                />
                            </div>
                            <div class="page-estates-item__headline-info-content-lines-item">
                                <div class="page-estates-item__headline-info-content-lines-item-icon">
                                    <icon name="pin" />
                                </div>
                                <div class="page-estates-item__headline-info-content-lines-item-text"
                                    v-text="estate.address"
                                />
                            </div>
                        </div>
                        <div class="page-estates-item__headline-info-content-progress"
                            v-if="estate.state === 'collecting'"
                            >
                            <common-investment-campaign-progress
                                v-bind:items="estate.investmentCampaigns"
                            />
                        </div>
                        <div class="page-estates-item__headline-info-content-status"
                            v-if="estate.state === 'collected'"
                            >
                            <div class="page-estates-item__headline-info-content-status-text">
                                Сумма собрана, а сам объект приобретен SimpleEstate.
                                <br />
                                Вскоре он будет доступен на рынке акций
                            </div>
                            <div class="page-estates-item__headline-info-content-status-icon">
                                <icon name="check-thin" />
                            </div>
                        </div>
                        <div class="page-estates-item__headline-info-content-status"
                            v-if="estate.state === 'round-completed'"
                            >
                            <div class="page-estates-item__headline-info-content-status-text">
                                Идёт подготовка к следующему раунду инвестиций
                            </div>
                            <div class="page-estates-item__headline-info-content-status-icon">
                                <icon name="check-thin" />
                            </div>
                        </div>
                        <div class="page-estates-item__headline-info-content-status _completed"
                            v-if="estate.state === 'completed'"
                            >
                            <div class="page-estates-item__headline-info-content-status-text">
                                Сумма собрана, а сам объект приобретен SimpleEstate.
                                <br />
                                Инвестировать в него теперь можно на вторичном рынке
                            </div>
                            <div class="page-estates-item__headline-info-content-status-icon">
                                <icon name="check-thin" />
                            </div>
                        </div>
                        <div class="page-estates-item__headline-info-content-status"
                            v-if="estate.state === 'sold'"
                            >
                            <div class="page-estates-item__headline-info-content-status-text">
                                Сумма собрана, а сам объект уже куплен и продан.
                                <br />
                                Инвестировать в этот объект теперь нельзя
                            </div>
                            <div class="page-estates-item__headline-info-content-status-icon">
                                <icon name="check-document" />
                            </div>
                        </div>
                        <div class="page-estates-item__headline-info-content-description"
                            v-text="estate.description"
                        />
                        <template v-if="estate.status === 'sold'">
                            <div class="page-estates-item__headline-info-content-buttons">
                                <div class="page-estates-item__headline-info-content-buttons-item"
                                    v-if="estate.document"
                                    >
                                    <ui-button
                                        v-bind:href="estate.document.url"
                                        type="button"
                                        size="lg"
                                        >
                                        {{ estate.document.title }}
                                    </ui-button>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="page-estates-item__headline-info-content-links">
                                <div class="page-estates-item__headline-info-content-links-item"
                                    v-if="estate.document"
                                    >
                                    <ui-action v-bind:href="estate.document.url"
                                        v-bind:text="estate.document.title"
                                        icon-left="document"
                                        theme="secondary"
                                    />
                                </div>
                                <div class="page-estates-item__headline-info-content-links-item"
                                    v-if="quarterlyReportsOptions.length > 0"
                                    >
                                    <ui-link-list
                                        v-bind:options="quarterlyReportsOptions"
                                    />
                                </div>
                            </div>
                            <div class="page-estates-item__headline-info-content-buttons">
                                <div class="page-estates-item__headline-info-content-buttons-item"
                                    v-if="estate.state === 'completed' || estate.state === 'collected'"
                                    >
                                    <ui-button
                                        v-bind:href="urls.market"
                                        type="button"
                                        size="lg"
                                        >
                                        {{ estate.state === 'completed' ? 'Инвестировать' : 'Вторичный рынок' }}
                                    </ui-button>
                                </div>
                                <div class="page-estates-item__headline-info-content-buttons-item"
                                    v-if="estate.state === 'collecting'"
                                    >
                                    <ui-button
                                        v-bind:is-disabled="isLoading"
                                        v-on:click="investButtonHandler"
                                        type="button"
                                        size="lg"
                                        >
                                        Инвестировать
                                    </ui-button>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </section>
            <section class="page-estates-item__highlights">
                <div class="page-estates-item__highlights-container">
                    <div class="page-estates-item__highlights-item">
                        <page-estates-item-highlight
                            v-bind:title="'Инвестиции'"
                            v-bind:lines="[
                                {
                                    title: 'Общая доходность (факт./прогн.)',
                                    value: profitabilityFormatted,
                                },
                                {
                                    title: 'Дивидендная доходность (факт./прогн.)',
                                    value: dividendProfitabilityFormatted,
                                },
                                {
                                    title: 'Минимальный размер инвестиции',
                                    value: formatMoney(estate.minimumInvestment),
                                },
                            ]"
                        />
                    </div>
                    <div class="page-estates-item__highlights-item">
                        <page-estates-item-highlight
                            v-bind:title="'Объект'"
                            v-bind:lines="[
                                {
                                    title: 'Площадь',
                                    value: formatNumber(estate.gba) + ' м2',
                                },
                                {
                                    title: 'Стоимость 1 акции',
                                    value: formatNumber(estate.sharePrice) + ' ₽',
                                },
                                {
                                    title: 'Текущая загрузка',
                                    value: formatNumber(estate.occupancy || 0) + '%',
                                },
                            ]"
                            >
                        </page-estates-item-highlight>
                    </div>
                    <div class="page-estates-item__highlights-item">
                        <page-estates-item-highlight
                            v-bind:title="'Цена'"
                            v-bind:lines="[
                                {
                                    title: 'Общая стоимость',
                                    value: formatMoney(estate.totalCost),
                                },
                                {
                                    title: 'Стоимость за 1 кв.м',
                                    value: formatMoney(parseInt(estate.totalCost / estate.gla, 10)),
                                },
                                {
                                    title: 'Cap Rate',
                                    value: formatNumber(estate.capRate.toFixed(2)) + '%',
                                },
                            ]"
                            >
                        </page-estates-item-highlight>
                    </div>
                </div>
            </section>
            <section class="page-estates-item__content"
                v-if="estate.status !== 'sold'"
                >
                <slot />
            </section>
        </div>
        <div class="page-estates-item__prefooter"
            v-if="estate.status !== 'sold'"
            >
            <page-estates-item-prefooter
                v-bind:estate="estate"
                v-bind:is-disabled="isLoading"
                v-on:invest="investButtonHandler"
            />
        </div>
    </section>
</template>

<script>
import { mapState } from 'vuex';
import config from '~/config';
import { mainClient } from '~/network';
import utils from '~/utils';

export default {
    name: 'page-estates-item',
    props: {
        estateData: {
            type: Object,
        },
    },
    data: () => ({
        urls: {
            market: config.urls.market.index,
            estatesList: config.urls.estates.list,
        },
    }),
    computed: {
        ...mapState('users', ['isLoading']),
        estate() {
            return mainClient.estates.transforms.responses.item(this.estateData);
        },
        currency() {
            return this.$store.getters['site/currency'];
        },
        user() {
            return this.$store.getters['users/user'];
        },
        profitabilityFormatted() {
            if (this.estate.state === 'completed' || this.estate.state === 'collected') {
                return this.formatNumber(this.estate.currentProfitability) + '% / ' + this.formatNumber(this.estate.targetProfitability) + '%';
            }
            return '– / ' + this.formatNumber(this.estate.targetProfitability) + '%';
        },
        dividendProfitabilityFormatted() {
            if (this.estate.state === 'completed' || this.estate.state === 'collected') {
                return this.formatNumber(this.estate.currentDividendProfitability) + '% / ' + this.formatNumber(this.estate.targetDividendProfitability) + '%';
            }
            return '– / ' + this.formatNumber(this.estate.targetDividendProfitability) + '%';
        },
        quarterlyReportsOptions() {
            return this.estate.quarterlyReports.map(x => ({
                value: x.document,
                text: `Квартальный отчет (${x.quarter} кв. ${x.year})`,
            }));
        },
    },
    methods: {
        back() {
            window.history.back();
            setTimeout(() => {
                window.location.href = this.urls.estatesList;
            }, 1000);
        },
        investButtonHandler() {
            if (!this.user || this.user === 'guest') {
                this.$store.commit('modals/push', 'auth-required');
            } else if (this.user.status === 'unverified') {
                this.$store.commit('modals/push', 'verification-waiting');
            } else if (this.user.status === 'new') {
                this.$store.commit('modals/push', 'verification-warning');
            } else {
                this.$store.commit('modals/push', { name: 'invest', props: { estateId: this.estate.id } });
            }
        },
        formatNumber(rawValue) {
            return utils.formats.formatNumberWithSpaces(rawValue);
        },
        formatMoney(rawValue) {
            return this.formatNumber(rawValue) + ' ' + this.currency.name.short;
        },
    },
    watch: {
        user(newVal) {
            if (newVal === 'guest') {
                window.location.href = config.urls.estates.list;
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-estates-item {
    padding-top: 10px;
    &--container {
        .container();
    }
    &__headline {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        margin-bottom: 24px;
        border-radius: @border-radius-lg;

        background-color: @color-gray-lightest;

        box-shadow: @shadow-primary;
        &-cover {
            flex: 0 0 auto;
            width: 50%;
            border-radius: @border-radius-lg 0 0 @border-radius-lg;
            overflow: hidden;
        }
        &-info {
            .typography-body();

            position: relative;

            flex: 1 1 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
            padding: 40px;
            &-content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                width: 100%;
                max-width: 640px;
                &-label {
                    flex: 0 0 auto;
                    margin-bottom: 16px;
                }
                &-title {
                    .typography-extra-large();

                    flex: 0 0 auto;
                    margin: auto 0 20px;
                }
                &-lines {
                    flex: 0 0 auto;
                    display: flex;
                    align-items: baseline;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    margin-bottom: 16px;
                    &-item {
                        flex: 0 0 auto;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        padding-right: 24px;
                        margin-bottom: 6px;
                        &-icon {
                            flex: 0 0 auto;
                            padding: 7px;
                            border-radius: 50%;
                            margin-right: 8px;

                            font-size: 1.4rem;

                            background-color: @color-gray-light;
                        }
                        &-text {
                            .typography-body();

                            flex: 1 1 auto;
                        }
                    }
                }
                &-status {
                    .typography-heading();

                    flex: 0 0 auto;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    width: 100%;
                    padding: 16px 0;
                    border-top: 1px solid @color-gray-light;
                    border-bottom: 1px solid @color-gray-light;
                    margin-top: 0;
                    margin-bottom: 16px;
                    &-text {
                        flex: 1 1 auto;
                    }
                    &-icon {
                        flex: 0 0 auto;
                        margin-left: 20px;

                        font-size: 3.2rem;
                    }
                    &._completed {
                        color: @color-primary;
                    }
                }
                &-description {
                    .typography-small();

                    flex: 0 0 auto;
                    margin-top: 0;
                    margin-bottom: 24px;

                    white-space: pre-line;
                }
                &-progress {
                    flex: 0 0 auto;
                    width: 100%;
                    padding-top: 8px;
                    margin-bottom: 24px;
                }
                &-links {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    width: calc(100% + 32px);
                    margin: 8px -16px 28px;
                    &-item {
                        flex: 0 0 auto;
                        padding: 8px 16px;
                    }
                }
                &-buttons {
                    flex: 0 0 auto;
                    display: flex;
                    align-items: flex-end;
                    flex-wrap: wrap;
                    width: calc(100% + 12px);
                    padding-bottom: 16px;
                    margin: auto -6px -6px;
                    &-item {
                        flex: 1 1 50%;
                        padding: 6px;
                        &:first-child:last-child {
                            flex: 0 1 50%;
                        }
                    }
                }
            }
        }
    }
    &__highlights {
        margin-bottom: 40px;
        &-container {
            display: flex;
            justify-content: flex-start;
            align-items: stretch;
            margin: -16px;
        }
        &-item {
            flex: 1 1 100%;
            padding: 16px;
        }
    }
    &__prefooter {
        .container();

        padding-top: 90px;
        padding-bottom: 90px;

        background-color: @color-gray-lighter;
    }
    @media @media-lg-down {
        &__headline {
            &-info {
                &-content {
                    &-buttons {
                        &-item {
                            &:first-child:last-child {
                                flex: 1 1 50%;
                            }
                        }
                    }
                }
            }
        }
    }
    @media @media-md-down {
        &__headline {
            &-info {
                padding: 16px 24px 24px;
            }
        }
    }
    @media @media-sm-down {
        padding-top: 57px;
        &__headline {
            display: block;
            padding: 0;
            margin-bottom: 38px;
            margin-left: -24px;
            margin-right: -24px;
            border-radius: 0;
            box-shadow: none;
            &-cover {
                flex: 0 0 auto;
                width: 100%;
                margin-right: 0;

                border-radius: 0;
            }
            &-info {
                width: 100%;
                border-radius: 0 0 4px 4px;

                background-color: @color-gray-lightest;

                &-content {
                    &-title {
                        .typography-medium();

                        margin-bottom: 16px;
                    }
                    &-lines {
                        &-item {
                            &-icon {
                                padding: 6px;

                                font-size: 1.2rem;
                            }
                        }
                    }
                    &-description {
                        .typography-body();

                        margin-bottom: 24px;
                    }
                    &-links {
                        flex-direction: column;
                        align-items: flex-start;
                    }
                    &-buttons {
                        flex-direction: column;
                        flex-wrap: nowrap;
                        padding-bottom: 0;
                        &-item {
                            width: 100%;
                        }
                    }
                }
            }
        }
        &__highlights {
            &-container {
                flex-direction: column;
            }
            &-item {
                & ~ & {
                    margin-top: 8px;
                }
            }
        }
    }
}
</style>
