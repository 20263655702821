<template>
    <section class="page-auth-reset">
        <div class="page-auth-reset__success"
            v-if="step == 'success'"
            >
            <div class="page-auth-reset__success-title"
                v-text="'Пароль изменен'"
            />
            <div class="page-auth-reset__success-text"
                v-text="'Вы уже авторизованы и можете просматривать объекты и управлять своим личным кабинетом'"
            />
            <div class="page-auth-reset__button">
                <ui-button
                    v-bind:href="urls.cabinet.dashboard"
                    target="_self"
                    text="В кабинет"
                />
            </div>
        </div>
        <template v-else>
            <div class="page-auth-reset__header">
                <div class="page-auth-reset__header-back"
                    v-if="step === 'verification'"
                    v-on:click="back"
                    >
                    <icon name="chevron-left" />
                </div>
                <page-auth-switcher
                    v-bind:content="content"
                    v-on:switch="$emit('switch', $event)"
                />
            </div>
            <div class="page-auth-reset__container">
                <template v-if="step === 'form'">
                    <div class="page-auth-reset__text _center">
                        Введите номер телефона, который вы указывали при регистрации. Если вы регистрировались без номера телефона, то обратитесь в <a v-bind:href="urls.support">поддержку</a>
                    </div>
                    <div class="page-auth-reset__form">
                        <ui-form
                            v-bind:model="fields"
                            v-bind:validation="$v"
                            v-bind:submit-handler="getCode"
                            v-on:update="updateFormData"
                        />
                    </div>
                    <div class="page-auth-reset__recaptcha">
                        <vue-recaptcha
                            v-bind:sitekey="recaptchaKey"
                            v-on:verify="recaptchaVerifyHandler"
                            v-on:expired="recaptchaExpiredHandler"
                            load-recaptcha-script
                        />
                        <div class="page-auth-reset__recaptcha-error"
                            v-if="recaptchaError"
                            v-text="recaptchaError"
                        />
                    </div>
                    <div class="page-auth-reset__buttons">
                        <div class="page-auth-reset__buttons-item">
                            <ui-button
                                v-on:click="getCode"
                                v-bind:disabled="$v.$invalid && $v.$dirty"
                                text="Выслать код"
                            />
                        </div>
                    </div>
                </template>
                <template v-else-if="step === 'verification'">
                    <div class="page-auth-reset__text _center">
                        Введите СМС-код, отправленный на указанный вами номер телефона ({{ fields.phone.value }}).
                    </div>
                    <div class="page-auth-reset__verification">
                        <div class="page-auth-reset__verification-password">
                            <ui-input-character-set
                                v-bind:size="codeLength"
                                v-model="code"
                                v-on:submit="resetPassword"
                                v-on:update="resetCodeError"
                                v-bind:is-invalid="isCodeInvalid"
                                v-bind:is-numeric="false"
                                is-autofocus
                            />
                        </div>
                        <div class="page-auth-reset__verification-help"
                            v-if="getPasswordResetCodeRequestTimer === 0"
                            >
                            Не пришел код? <span class="page-auth-reset__verification-help-trigger"
                                v-on:click="back"
                                >Отправить повторно</span>
                        </div>
                        <div class="page-auth-reset__verification-help"
                            v-else
                            >
                            <span v-text="'Повторно отправить код можно через '" /><span
                                class="page-auth-reset__verification-help-timer"
                                v-text="formattedTimerValue"
                            />
                        </div>
                    </div>
                    <div class="page-auth-reset__buttons">
                        <div class="page-auth-reset__buttons-item">
                            <ui-button
                                v-on:click="resetPassword"
                                v-bind:disabled="code.length !== codeLength || isCodeInvalid"
                                text="Подтвердить"
                            />
                        </div>
                    </div>
                </template>
            </div>
        </template>
    </section>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import { mapState } from 'vuex';
import moment from 'moment';
import config from '~/config';
import forms from '~/forms';
import utils from '~/utils';

export default {
    name: 'page-auth-reset',
    components: { VueRecaptcha },
    inject: [ 'image', 'recaptchaKey' ],
    props: {
        content: {
            type: String,
            default: 'login',
        },
    },
    data: () => ({
        step: 'form',
        urls: config.urls,
        recaptchaValue: null,
        code: '',
        codeLength: 6,
        isCodeInvalid: false,
        fields: {
            phone: {
                ...forms.fields.phone,
                placeholder: '+7',
            },
            password1: {
                ...forms.fields.passwordNew,
                label: 'Новый пароль',
            },
            password2: {
                ...forms.fields.passwordRepeat,
            },
        },
    }),
    validations() {
        const result = {
            fields: {
                phone: { ...forms.validations.phone },
                password1: { ...forms.validations.passwordNew },
                password2: { ...forms.validations.passwordRepeat },
            },
            recaptchaValue: forms.validations.genericRequired.value,
        };
        return result;
    },
    computed: {
        ...mapState('sms', [
            'getPasswordResetCodeIsLoading',
            'getPasswordResetCodeError',
            'getPasswordResetCodeResult',
            'getPasswordResetCodeRequestIsAvailable',
            'getPasswordResetCodeRequestDate',
            'getPasswordResetCodeRequestDelay',
            'getPasswordResetCodeRequestTimer',
        ]),
        ...mapState('users', [
            'resetPasswordResult',
            'resetPasswordIsLoading',
            'resetPasswordError',
        ]),
        title() {
            if (this.step === 'success') {
                return 'Пароль изменен';
            }
            return 'Восстановление пароля';
        },
        headerBackground() {
            return window.innerWidth <= 1023
                ? { backgroundImage: `url(${this.image})` }
                : null;
        },
        backTheme() {
            return window.innerWidth <= 1023 ? 'dark' : null;
        },
        recaptchaError() {
            if (this.$v.$invalid && this.$v.$dirty && !this.recaptchaValue) {
                return 'Подтвердите, что вы не робот';
            }
            return null;
        },
        formattedTimerValue() {
            return moment(this.getPasswordResetCodeRequestTimer).format('mm:ss');
        },
        isLoading() {
            return this.getPasswordResetCodeIsLoading || this.resetPasswordIsLoading;
        },
        redirect() {
            const redirectQuery = utils.common.parseUrl().find(x => x.title === 'next');
            if (redirectQuery) {
                return redirectQuery.value;
            }
            return null;
        },
    },
    methods: {
        updateFormData(newData) {
            this.fields = newData;
        },
        recaptchaVerifyHandler(response) {
            this.recaptchaValue = response;
        },
        recaptchaExpiredHandler() {
            this.recaptchaValue = null;
        },
        resetRecaptcha() {
            if (this.$refs.recaptcha) {
                this.$refs.recaptcha.reset();
            }
        },
        back() {
            this.step = 'form';
            this.resetCodeError();
        },
        resetCode() {
            this.code = '';
            this.back();
            this.$nextTick(this.resetRecaptcha);
        },
        resetCodeError() {
            this.isCodeInvalid = false;
        },
        getCode() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            this.step = 'verification';
            this.$store.dispatch('sms/getPasswordResetCode', {
                phone: this.fields.phone.value,
                recaptcha: this.recaptchaValue,
            });
        },
        resetPassword() {
            this.$store.dispatch('users/resetPassword', {
                phone: this.fields.phone.value,
                code: this.code,
                password: this.fields.password1.value,
            });
        },
    },
    watch: {
        isLoading(newVal) {
            this.$parent.$emit('update-loading', newVal);
        },
        getPasswordResetCodeIsLoading(newVal) {
            if (newVal) {
                this.$v.$reset();
                this.resetRecaptcha();
            }
        },
        resetPasswordIsLoading(newVal, oldVal) {
            if (!newVal && oldVal && this.resetPasswordResult) {
                this.step = 'success';
            }
            if (this.resetPasswordError === ' Неверный ключ сброса') {
                this.isCodeInvalid = true;
            } else {
                this.isCodeInvalid = false;
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-auth-reset {
    flex: 1 1 auto;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-direction: column;
    &__header {
        position: relative;

        flex: 0 0 auto;
        padding-bottom: 8px;
        &-back {
            position: absolute;
            top: 6px;
            left: 0;

            color: @color-gray-main;
            font-size: 2rem;

            cursor: pointer;
        }
    }
    &__container {
        flex: 1 1 auto;
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        flex-direction: column;
    }
    &__text {
        .typography-body();

        margin-bottom: 16px;
        ._success & {
            .typography-small();
        }
        &._center {
            text-align: center;
        }
    }
    &__form {
        flex: 0 0 auto;
        margin-bottom: 24px;
    }
    &__recaptcha {
        flex: 0 0 auto;
        margin: 0 auto 40px;
        &-error {
            .typography-body();

            margin-top: 16px;

            color: @color-accent-negative;
            text-align: center;
        }
    }
    &__verification {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 40px;
        &-password {
            margin-bottom: 16px;
        }
        &-help {
            .typography-body();

            text-align: center;
            &-trigger {
                cursor: pointer;

                color: @color-primary;
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }
            &-timer {
                display: inline-block;
                min-width: 40px;

                color: @color-primary;
                text-align: left;
            }
        }
    }
    &__success {
        text-align: center;
        &-title {
            .typography-large();

            margin-bottom: 16px;
            @media @media-md-down {
                margin-bottom: 32px;
            }
        }
        &-text {
            .typography-small();

            margin-bottom: 40px;
        }
    }
    &__button {
        flex: 0 0 auto;
        width: 194px;
        margin: 0 auto 32px;
    }
    &__buttons {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 auto 20px;
        &-item {
            flex: 0 0 auto;
            width: 218px;
            padding: 12px;
        }
    }
    @media @media-md-down {
        &__container {
            .container-paddings();

            padding-bottom: 32px;
        }
        &__text {
            .typography-body();
        }
    }
    @media @media-xs-down {
        &__button {
            width: 100%;
        }
    }
}
</style>
