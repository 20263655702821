/* eslint-disable */
require('./arrow-left')
require('./arrow-right-round')
require('./arrow-right')
require('./attention')
require('./bank-registry')
require('./briefcase')
require('./buildings')
require('./burger')
require('./cancel')
require('./caret-left')
require('./check-document')
require('./check-fancy')
require('./check-thin')
require('./check')
require('./chevron-left')
require('./chevron-right')
require('./clock')
require('./close')
require('./copy')
require('./cross-round')
require('./deals-new')
require('./deals')
require('./document')
require('./download')
require('./edit')
require('./estate-type-business-center')
require('./estate-type-store-house')
require('./estate-type-street-retail')
require('./eye')
require('./facebook')
require('./gear')
require('./home')
require('./instagram')
require('./investment-type-direct-gray')
require('./investment-type-direct')
require('./investment-type-loan-gray')
require('./investment-type-loan')
require('./legal-entity-f')
require('./legal-entity-i')
require('./legal-entity-j')
require('./linkedin')
require('./lock')
require('./logo')
require('./magnifier-plus')
require('./notifications')
require('./out')
require('./partner-add')
require('./phone')
require('./pin')
require('./plus')
require('./question')
require('./requests-new')
require('./requests')
require('./sign-check')
require('./sort-desc')
require('./success')
require('./telegram-round')
require('./telegram')
require('./trash')
require('./triangle')
require('./user')
require('./wallet')
require('./warning')
require('./youtube')
