<template>
    <div class="page-cabinet-verification-profile">
        <transition name="fade">
            <div class="page-cabinet-verification-profile__loader"
                v-if="isLoading"
                >
                <div class="page-cabinet-verification-profile__loader-container">
                    <ui-loader />
                </div>
            </div>
        </transition>
        <template v-if="!isLoading">
            <div class="page-cabinet-verification-profile__top">
                <div class="page-cabinet-verification-profile__top-general">
                    <page-cabinet-verification-profile-general
                        v-bind:is-touched="isTouched"
                        v-on:validation-update="validationUpdate"
                        v-on:touch="isTouched = true"
                        v-on:submit="submit"
                    />
                </div>
                <div class="page-cabinet-verification-profile__top-photo">
                    <page-cabinet-verification-profile-photos
                        v-bind:is-touched="isTouched"
                        v-on:validation-update="validationUpdate"
                        v-on:submit="submit"
                    />
                </div>
            </div>
            <template v-if="isIndividualFormNeeded">
                <ui-dots-separator
                    text="Для физических лиц"
                />
                <div class="page-cabinet-verification-profile__ip">
                    <page-cabinet-verification-profile-individual
                        v-bind:is-touched="isTouched"
                        v-on:validation-update="validationUpdate"
                        v-on:touch="isTouched = true"
                        v-on:submit="submit"
                    />
                </div>
            </template>
            <template v-if="isIpFormNeeded">
                <ui-dots-separator
                    text="Для индивидуальных предпринимателей"
                />
                <div class="page-cabinet-verification-profile__ip">
                    <page-cabinet-verification-profile-ip
                        v-bind:is-touched="isTouched"
                        v-on:validation-update="validationUpdate"
                        v-on:touch="isTouched = true"
                        v-on:submit="submit"
                    />
                </div>
            </template>
            <template v-if="isJuridicalFormNeeded">
                <ui-dots-separator
                    text="Для юридических лиц"
                />
                <div class="page-cabinet-verification-profile__juridical">
                    <page-cabinet-verification-profile-juridical
                        v-bind:is-touched="isTouched"
                        v-on:validation-update="validationUpdate"
                        v-on:touch="isTouched = true"
                        v-on:submit="submit"
                    />
                </div>
            </template>
            <!-- <template v-if="isForeignerFormNeeded">
                <ui-dots-separator
                    text="Для иностранных граждан"
                />
                <div class="page-cabinet-verification-profile__foreigner">
                    <page-cabinet-verification-profile-foreigner
                        v-bind:is-touched="isTouched"
                        v-on:validation-update="validationUpdate"
                        v-on:touch="isTouched = true"
                        v-on:submit="submit"
                    />
                </div>
            </template> -->
        </template>
        <div class="page-cabinet-verification-profile__buttons">
            <div class="page-cabinet-verification-profile__buttons-item">
                <ui-button
                    v-bind:disabled="(!isValid && isTouched) || isLoading"
                    v-on:click="submit"
                    theme="primary"
                    type="button"
                    size="lg"
                    >
                    Далее
                </ui-button>
            </div>
        </div>
    </div>
</template>

<script>
// import moment from 'moment';
import { mapState } from 'vuex';
// import config from '~/config';
// import forms from '~/forms';

export default {
    name: 'page-cabinet-verification-profile',
    props: {
        isFilled: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        isTouched: false,
        isGeneralDataValid: true,
        isPhotosDataValid: true,
        // isForeignerDataValid: true,
        isIndividualDataValid: true,
        isIpDataValid: true,
        isJuridicalDataValid: true,
        isValid: true,
    }),
    computed: {
        // ...mapState('geo', [
        //     'getCountriesIsLoading',
        //     'getCountriesResult',
        // ]),
        ...mapState('users', [
            'user',

            'getUserProfileResult',
            'getUserProfileIsLoading',

            'getUserPersonalInfoResult',
            'getUserPersonalInfoIsLoading',

            'getUserPhotosResult',
            'getUserPhotosIsLoading',

            'getUserJuridicalInfoResult',
            'getUserJuridicalInfoIsLoading',
        ]),
        // isForeignerFormNeeded() {
        //     return this.$store.state.verification.profile.general.citizenship &&
        //         this.$store.state.verification.profile.general.citizenship !== 'RU' &&
        //         this.$store.state.verification.profile.general.citizenship !== 'BY';
        // },
        isIndividualFormNeeded() {
            return this.getUserProfileResult?.legalType === 'F';
        },
        isIpFormNeeded() {
            return this.getUserProfileResult?.legalType === 'I';
        },
        isJuridicalFormNeeded() {
            return this.getUserProfileResult?.legalType === 'J';
        },
        isLoading() {
            return this.getUserProfileIsLoading ||
                this.getUserPersonalInfoIsLoading ||
                this.getUserJuridicalInfoIsLoading ||
                this.getUserPhotosIsLoading;
        },
        profile() {
            return this.$store.state.verification.profile;
        },
    },
    methods: {
        validationUpdate({ entityName, value }) {
            this[entityName] = value;
            this.isValid = this.isGeneralDataValid &&
                this.isIndividualDataValid &&
                this.isIpDataValid &&
                this.isJuridicalDataValid &&
                this.isPhotosDataValid;
        },
        submit() {
            if (this.isLoading) {
                return;
            }
            if (!this.isValid) {
                this.isTouched = true;
                return;
            }
            let requestsCounter = 0;
            const requestsCounterTarget = 3;
            const checkRequestsTarget = () => {
                if (requestsCounter === requestsCounterTarget) {
                    this.$emit('filled', 'profile');
                    this.$emit('submitted');
                }
            };
            this.$store.dispatch('users/updateUserProfile', {
                phone: this.profile.general.phone,
                firstName: this.profile.general.firstName,
                secondName: this.profile.general.noSecondName ? null : this.profile.general.secondName,
                lastName: this.profile.general.lastName,
                onSuccess: () => {
                    requestsCounter++;
                    checkRequestsTarget();
                },
            });
            this.$store.dispatch('users/updateUserPersonalInfo', {
                passportSeries: this.profile.general.passportSeries,
                passportNumber: this.profile.general.passportNumber,
                onSuccess: () => {
                    requestsCounter++;
                    checkRequestsTarget();
                },
            });

            const juridicalInfoPayload = {
                organizationName: null,
                organizationAddress: null,
                legalAddress: null,
                mailingAddress: null,
                bankName: null,
                ogrn: null,
                okved: null,
                inn: this.profile.general.inn,
                kpp: null,
                bik: null,
                bankAccount: null,
                cadastralAccount: null,
                dateRegistration: null,
                snils: null,
            };
            if (this.getUserProfileResult.legalType === 'F' || this.getUserProfileResult.legalType === 'I') {
                juridicalInfoPayload.snils = this.profile.general.snils;
            }
            if (this.getUserProfileResult.legalType === 'F' && this.profile.individual.hasIp) {
                juridicalInfoPayload.ogrn = this.profile.ip.ogrnip;
            } else if (this.getUserProfileResult.legalType === 'I') {
                juridicalInfoPayload.organizationName = this.profile.ip.ipName;
                juridicalInfoPayload.organizationAddress = this.profile.ip.ipAddress;
                juridicalInfoPayload.legalAddress = this.profile.ip.ipAddress;
                juridicalInfoPayload.mailingAddress = this.profile.ip.ipAddress;
                juridicalInfoPayload.ogrn = this.profile.ip.ogrnip;
            } else if (this.getUserProfileResult.legalType === 'J') {
                juridicalInfoPayload.organizationName = this.profile.juridical.organizationName;
                juridicalInfoPayload.organizationNameFull = this.profile.juridical.organizationNameFull;
                juridicalInfoPayload.organizationAddress = this.profile.juridical.organizationAddress;
                juridicalInfoPayload.legalAddress = this.profile.juridical.organizationRegistrationAddress;
                juridicalInfoPayload.mailingAddress = this.profile.juridical.organizationPostalAddress;
                juridicalInfoPayload.bankName = this.profile.juridical.bankName;
                juridicalInfoPayload.ogrn = this.profile.juridical.ogrn;
                juridicalInfoPayload.okved = this.profile.juridical.okved;
                juridicalInfoPayload.kpp = this.profile.juridical.kpp;
                juridicalInfoPayload.bik = this.profile.juridical.bik;
                juridicalInfoPayload.corrAccount = this.profile.juridical.corrAccount;
                juridicalInfoPayload.dateRegistration = this.profile.juridical.organisationRegistrationDate;
            }
            this.$store.dispatch('users/updateUserJuridicalInfo', {
                ...juridicalInfoPayload,
                onSuccess: () => {
                    requestsCounter++;
                    checkRequestsTarget();
                },
            });
        },
    },
    beforeMount() {
        this.$store.dispatch('users/getUserPhotos', {
            userId: this.user.id,
        });
        this.$store.dispatch('users/getUserProfile');
        this.$store.dispatch('users/getUserPersonalInfo');
        this.$store.dispatch('users/getUserJuridicalInfo');
    },
    watch: {
        getUserProfileResult: {
            handler(newVal, oldVal) {
                if (newVal && !oldVal) {
                    const result = {};
                    if (newVal.firstName) {
                        result.firstName = newVal.firstName;
                    }
                    if (newVal.lastName) {
                        result.lastName = newVal.lastName;
                    }
                    if (newVal.secondName) {
                        result.secondName = newVal.secondName;
                        result.noSecondName = false;
                    } else {
                        result.noSecondName = true;
                    }
                    if (newVal.phone) {
                        result.phone = newVal.phone;
                    }
                    this.$store.state.verification.profile.general = {
                        ...this.$store.state.verification.profile.general,
                        ...result,
                    };
                }
            },
            immediate: true,
        },
        getUserJuridicalInfoResult: {
            handler(newVal, oldVal) {
                if (newVal && !oldVal) {
                    const resultGeneral = {};
                    const resultIp = {};
                    const resultJuridical = {};
                    if (newVal.inn) {
                        resultGeneral.inn = newVal.inn;
                    }
                    if (newVal.snils) {
                        resultGeneral.snils = newVal.snils;
                    }
                    if (newVal.ogrn) {
                        resultIp.ogrnip = newVal.ogrn;
                        resultJuridical.ogrn = newVal.ogrn;
                    }
                    if (newVal.organizationName) {
                        resultIp.ipName = newVal.organizationName;
                        resultJuridical.organizationName = newVal.organizationName;
                    }
                    if (newVal.organizationNameFull) {
                        resultJuridical.organizationNameFull = newVal.organizationNameFull;
                    }
                    if (newVal.organizationAddress) {
                        resultIp.ipAddress = newVal.organizationAddress;
                        resultJuridical.organizationAddress = newVal.organizationAddress;
                    }
                    if (newVal.legalAddress) {
                        resultJuridical.organizationRegistrationAddress = newVal.legalAddress;
                    }
                    if (newVal.mailingAddress) {
                        resultJuridical.organizationPostalAddress = newVal.mailingAddress;
                    }
                    if (newVal.okved) {
                        resultJuridical.okved = newVal.okved;
                    }
                    if (newVal.kpp) {
                        resultJuridical.kpp = newVal.kpp;
                    }
                    if (newVal.bik) {
                        resultJuridical.bik = newVal.bik;
                    }
                    if (newVal.bankName) {
                        resultJuridical.bankName = newVal.bankName;
                    }
                    if (newVal.corrAccount) {
                        resultJuridical.corrAccount = newVal.corrAccount;
                    }
                    if (newVal.dateRegistration) {
                        resultJuridical.organisationRegistrationDate = newVal.dateRegistration;
                    }
                    this.$store.state.verification.profile.general = {
                        ...this.$store.state.verification.profile.general,
                        ...resultGeneral,
                    };
                    this.$store.state.verification.profile.ip = {
                        ...this.$store.state.verification.profile.ip,
                        ...resultIp,
                    };
                    this.$store.state.verification.profile.juridical = {
                        ...this.$store.state.verification.profile.juridical,
                        ...resultJuridical,
                    };
                }
            },
            immediate: true,
        },
        getUserPersonalInfoResult: {
            handler(newVal, oldVal) {
                if (newVal && !oldVal) {
                    const resultGeneral = {};
                    if (newVal.passportNumber) {
                        resultGeneral.passportNumber = newVal.passportNumber;
                    }
                    if (newVal.passportSeries) {
                        resultGeneral.passportSeries = newVal.passportSeries;
                    }
                    this.$store.state.verification.profile.general = {
                        ...this.$store.state.verification.profile.general,
                        ...resultGeneral,
                    };
                }
            },
            immediate: true,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-verification-profile {
    position: relative;
    &__loader {
        .transition-fade();

        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        background-color: fade(@color-gray-lightest, 50%);
        &-container {
            width: 100px;
            margin: auto;
        }
    }
    &__top {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 40px;
        &-general {
            flex: 1 1 auto;
            margin-right: 60px;
        }
        &-photo {
            flex: 0 0 auto;
            width: 377px;
        }
    }
    &__foreigner {
        margin-top: 24px;
        margin-bottom: 40px;
    }
    &__juridical {
        margin-top: 24px;
        margin-bottom: 40px;
    }
    &__ip {
        width: calc(100% - 437px);
        margin-top: 24px;
        margin-bottom: 40px;
    }
    &__buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        &-item {
            width: 100%;
            max-width: 290px;
            padding: 12px;
        }
    }
    @media @media-md-down {
        &__top {
            flex-direction: column;
            &-general {
                width: 100%;
                margin-right: 0;
            }
            &-photo {
                width: 100%;
            }
        }
        &__juridical {
            width: 100%;
        }
        &__ip {
            width: 100%;
        }
    }
}
</style>
