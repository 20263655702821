import { date as dateTransform } from '~/network/mainClient/common/transforms/requests';

const phone = (data) => data.replace(/[^0-9+]/g, '');

export const update = (data) => {
    const result = {
        email: data.email || '',
        first_name: data.firstName || '',
        last_name: data.lastName || '',
        phone: phone(data.phone) || '',
    };
    return result;
};

export const userProfile = (data) => {
    const result = {};
    if (data.firstName) {
        result.first_name = data.firstName;
    }
    if (data.lastName) {
        result.last_name = data.lastName;
    }
    if (data.secondName) {
        result.second_name = data.secondName;
    }
    if (data.phone) {
        result.phone = phone(data.phone);
    }
    if (data.legalType) {
        result.legal_type = data.legalType;
    } else if (data.isIndividualEntrepreneur !== undefined) {
        if (data.isIndividualEntrepreneur) {
            result.legal_type = 'I';
        } else if (data.isIndividualEntrepreneur === false) {
            result.legal_type = 'F';
            // result.legal_type = 'J';
        }
    }
    if (data.email) {
        result.email = data.email;
    }
    if (data.username) {
        result.username = data.username;
    }
    return result;
};

export const userPersonalInfo = (data) => {
    const result = {
        inn: data.inn || '',
        ogrnip: data.ogrnip || '',
        passport_number: data.passportNumber || '',
        passport_series: data.passportSeries || '',
        organization_name: data.organizationName || '',
        organization_address: data.organizationAddress || '',
    };
    if (data.birthDate) { result.birth_date = data.birthDate; }
    if (data.birthPlace) { result.birth_place = data.birthPlace; }
    if (data.nationality) { result.nationality = data.nationality; }
    if (data.citizenship) { result.citizenship = data.citizenship; }
    if (data.subdivisionCode) { result.subdivision_code = data.subdivisionCode; }
    if (data.subdivisionName) { result.subdivision_name = data.subdivisionName; }
    if (data.passportDate) { result.passport_date = data.passportDate; }
    if (data.registrationAddress) { result.registration_address = data.registrationAddress; }
    return result;
};

export const userJuridicalInfo = ({
    organizationName,
    organizationNameFull,
    organizationAddress,
    legalAddress,
    mailingAddress,
    bankName,
    ogrn,
    okved,
    inn,
    kpp,
    bik,
    bankAccount,
    corrAccount,
    dateRegistration,
    snils,
}) => {
    const result = {
        organization_name: organizationName || null,
        organization_name_full: organizationNameFull || null,
        organization_address: organizationAddress || null,
        legal_address: legalAddress || null,
        mailing_address: mailingAddress || null,
        bank_name: bankName || null,
        ogrn: ogrn || null,
        okved: okved || null,
        inn: inn || null,
        kpp: kpp || null,
        bik: bik || null,
        bank_account: bankAccount || null,
        cadastral_account: corrAccount || null,
        date_registration: dateRegistration ? dateTransform(dateRegistration) : null,
        snils: snils || null,
    };
    return result;
};

export const userMigrationInfo = (data) => {
    const result = {
        migration_card_date_end: dateTransform(data.migrationCardDateEnd || null),
        migration_card_date_start: dateTransform(data.migrationCardDateStart || null),
        migration_card_number: data.migrationCardNumber || null,
        migration_document_date_end: dateTransform(data.migrationDocumentDateEnd || null),
        migration_document_date_start: dateTransform(data.migrationDocumentDateStart || null),
        migration_document_number: data.migrationDocumentNumber || null,
    };
    return result;
};

export const userAdditionalInfo = (data) => {
    const fields = {
        is_financial_non_performance: data.additionalIsFinancialNonPerformance || null,
        is_beneficiary_owner: data.additionalIsBeneficiaryOwner || null,
        has_other_beneficiary: data.additionalHasOtherBeneficiary || null,
        income_source: data.additionalIncomeSource || null,
        income_source_additional: data.additionalIncomeSourceCustom || null,
        financial_position: data.additionalFinancialPosition || null,
        financial_position_additional: data.additionalFinancialPositionCustom || null,
        business_reputation: data.additionalBusinessReputation || null,
        ip_fin_has_annual_accounting_statement: data.additionalIpFinHasAnnualAccountingStatement || null,
        ip_fin_has_regular_tax_return: data.additionalIpFinHasRegularTaxReturn || null,
        ip_fin_has_auditor_report: data.additionalIpFinHasAuditorReport || null,
        ip_fin_has_tax_fullfilment_report: data.additionalIpFinHasTaxFullfilmentReport || null,
        ip_fin_has_bankrupt_certificate: data.additionalIpFinHasBankruptCertificate || null,
        ip_fin_bankrupt_step: data.additionalIpFinBankruptStep || null,
        public_official: data.additionalPublicOfficial || null,
    };
    const validKeys = Object.keys(fields).filter(key => fields[key] !== null && fields[key] !== undefined);
    const result = {};
    validKeys.forEach(key => {
        result[key] = fields[key];
    });
    return result;
};

export const registration = (data) => {
    const result = {
        code: data.code || '',
        phone: data.phone || '',
        password: data.password || '',
        email: data.email || '',
        first_name: data.firstName || '',
    };
    if (data.referralCode) {
        result.referral_code = data.referralCode;
    }
    return result;
};

export const userPhotoType = (data) => {
    if (data === 'photoPassportFirst') {
        return 'passport-first';
    } else if (data === 'photoPassportRegistration') {
        return 'passport-registration';
    } else if (data === 'photoMigrationCard') {
        return 'migration-card';
    } else if (data === 'photoMigrationVisa') {
        return 'migration-visa';
    } else {
        return data;
    }
};

export const withdraw = ({ bik, bankAccount, ...data }) => {
    return {
        ...data,
        account: bankAccount,
        bank_code: bik,
    };
};
