<template>
    <div class="page-cabinet-verification-finance-ip">
        <ui-form
            v-bind:model="fieldsFiltered"
            v-bind:validation="validation"
            v-bind:submit-handler="submitFormData"
            v-on:update="updateFormData"
            gap="wide"
        />
    </div>
</template>

<script>
export default {
    name: 'page-cabinet-verification-finance-ip',
    props: {
        fields: {
            type: Object,
        },
        validation: {
            type: Object,
        },
    },
    computed: {
        fieldsFiltered() {
            const relatedFields = [
                'additionalIpFinHasAnnualAccountingStatement',
                'additionalIpFinHasRegularTaxReturn',
                'additionalIpFinHasAuditorReport',
                'additionalIpFinHasTaxFullfilmentReport',
                'additionalIpFinHasBankruptCertificate',
                'additionalPublicOfficial',
            ];
            // добавляем поле additionalIpFinBankruptStep,
            // если значение additionalIpFinHasBankruptCertificate === true
            if (this.fields.additionalIpFinHasBankruptCertificate.value === true) {
                relatedFields.splice(relatedFields.indexOf('additionalIpFinHasBankruptCertificate') + 1, 0, 'additionalIpFinBankruptStep');
            }
            const result = {};
            relatedFields.forEach(x => {
                if (this.fields[x] !== undefined) {
                    result[x] = this.fields[x];
                }
            });
            return result;
        },
    },
    methods: {
        submitFormData() {
            this.$emit('submit');
        },
        updateFormData(newData) {
            this.$emit('update', newData);
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-verification-finance-ip {
}
</style>
