<template>
    <div class="ui-logo-text"
        v-bind:class="[ `_variant_${variant}`, {
            _colored: useCurrentColor,
            _light: light,
        }]"
        >
        <span class="ui-logo-text__simple"
            v-bind:style="firstColor ? { color: firstColor } : null"
            v-text="'Simple'"
        /><span class="ui-logo-text__estate"
            v-bind:style="secondColor ? { color: secondColor } : null"
            v-text="'Estate'"
        />
    </div>
</template>

<script>
export default {
    name: 'ui-logo-text',
    props: {
        variant: {
            type: String,
            default: 'default',
        },
        useCurrentColor: {
            type: Boolean,
            default: false,
        },
        light: {
            type: Boolean,
            default: false,
        },
        firstColor: {
            type: String,
            default: null,
        },
        secondColor: {
            type: String,
            default: null,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-logo-text {
    font-size: 1em;
    font-weight: bold;
    line-height: 1.25em;
    &__simple {
        color: @color-gray-darker;
    }
    &__estate {
        color: @color-primary;
    }
    &._variant_inverse & {
        &__simple {
            color: @color-gray-lightest;
        }
        &__estate {
            color: @color-primary-3;
        }
    }
    &._colored > &__simple,
    &._colored > &__estate {
        color: currentColor;
    }
    &._light {
        font-weight: normal;
    }
}
</style>
