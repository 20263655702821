<template>
    <section class="layout-minimal">
        <section class="layout-minimal__content">
            <slot />
        </section>
    </section>
</template>

<script>
export default {
    name: 'layout-minimal',
};
</script>

<style scoped lang="less">
@import '~theme';

.layout-minimal {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 135px;

    background-color: @color-gray-lighter;

    &__content {
        flex: 1 1 auto;
        width: ~'calc(100vw - 231px)';
    }
    @media @media-md-down {
        &__content {
            width: 100%;
        }
    }
}
</style>
