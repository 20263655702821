<template>
    <div class="modal-status-form-image">
        <div class="modal-status-form-image__title"
            v-html="title"
        />
        <div class="modal-status-form-image__description"
            v-if="field.maxFiles"
            v-text="`Максимум файлов: ${field.maxFiles}`"
        />
        <div class="modal-status-form-image__file"
            v-for="(file, index) in files"
            v-bind:key="index"
            >
            <div class="modal-status-form-image__file-number"
                v-text="index + 1"
            />
            <a class="modal-status-form-image__file-link"
                v-bind:href="file.src"
                target="_blank"
                v-text="file.title"
            />
        </div>
        <!-- <div class="modal-status-form-image__loader"
            v-if="isLoading"
            >
            loader
        </div> -->
        <div class="modal-status-form-image__input"
            v-if="files.length < field.maxFiles"
            >
            <!-- <ui-form-image
                v-bind="inputData"
                v-on:update="inputHandler"
            /> -->
            <ui-input-base-file
                v-bind:key="files.length"
                v-bind="{ title: 'Загрузите файл', value: null }"
                v-on:change="inputHandler"
                v-bind:is-loading="isLoading"
                v-bind:is-invalid="$v.$invalid && isTouched"
            />
        </div>
    </div>
</template>

<script>
import utils from '~/utils';
import { mainClient } from '~/network';

export default {
    name: 'modal-status-form-image',
    props: {
        field: {
            type: Object,
        },
    },
    data: () => ({
        isLoading: false,
    }),
    validations() {
        const result = {
            value: {},
        };
        result.value.required = () => this.value.length > 0;
        return result;
    },
    computed: {
        errors() {
            const result = {};
            if (this.field.validations) {
                Object.keys(this.field.validations).forEach((key) => {
                    result[key] = this.field.validations[key].error;
                });
            }
            return result;
        },
        isTitleNeeded() {
            return this.title && this.field.type === 'TextViewField';
        },
        isDescriptionNeeded() {
            return this.field.legend && this.field.type === 'TextViewField';
        },
        isTouched() {
            return this.$store.state.status.isTouched;
        },
        title() {
            const text = this.field.text || this.field.label;
            return text ? utils.common.parseBBCode(text) : text;
        },
        defaultValue() {
            return this.field.type === 'CheckboxField' ? false : '';
        },
        currentValue() {
            return this.field.value;
        },
        newValue() {
            return this.$store.state.status.newValues[this.field.id];
        },
        value() {
            if (this.newValue !== undefined) {
                return this.newValue;
            }
            if (this.currentValue !== null) {
                return this.currentValue;
            }
            return this.defaultValue;
        },
        files() {
            return this.value.map((src) => ({
                src,
                title: src.split('/').pop(),
            }));
        },
        inputData() {
            if (this.field.value === 'valueless') {
                return null;
            }
            const result = {
                value: null,
                model: {
                    errors: this.errors,
                    label: this.title || this.field.placeholder,
                    value: null,
                    isDisabled: this.field.isDisabled,
                    type: 'image',
                },
            };
            if (this.field.bbcode) {
                result.model.labelIsHtml = true;
            }
            return result;
        },
        mutations() {
            return this.field.mutateFields;
        },
    },
    methods: {
        async inputHandler(value) {
            this.isLoading = true;
            const formData = new FormData();
            formData.append('file', value);
            const { data, error } = await mainClient.status.requests.uploadFormFile({ formData });
            if (data) {
                const newValue = [
                    ...this.value,
                    data.url,
                ];
                this.$store.state.status.newValues = {
                    ...this.$store.state.status.newValues,
                    [this.field.id]: newValue,
                };
            } else {
                console.error('upload image error:', error);
            }
            this.isLoading = false;
        },
    },
    watch: {
        value: {
            handler(newValue) {
                if (this.mutations) {
                    const relatedMutations = this.mutations.filter((mutation) => mutation.value === newValue);
                    // const newFields = [];
                    relatedMutations.forEach((mutation) => {
                        this.$store.getters['status/currentStep'].fields.forEach((field) => {
                            const { id } = mutation;
                            const { value, ...rest } = mutation.mutate ? mutation.mutate : { visible: !newValue };
                            if (field.id === id) {
                                if (value) {
                                    this.$store.state.status.newValues = {
                                        ...this.$store.state.status.newValues,
                                        [field.id]: value,
                                    };
                                }
                                this.$store.getters['status/currentStep'].fields =
                                    this.$store.getters['status/currentStep'].fields
                                        .map((field) => {
                                            if (field.id === id) {
                                                return {
                                                    ...field,
                                                    ...rest,
                                                };
                                            }
                                            return field;
                                        });
                            }
                        });
                    });
                }
            },
            immediate: true,
        },
        '$v.$invalid': {
            handler(value) {
                this.$emit('validation-update', {
                    id: this.field.id,
                    isValid: !value,
                });
            },
            immediate: true,
        },
        isTouched: {
            handler(value) {
                if (value) {
                    this.$v.$touch();
                }
            },
            immediate: true,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-status-form-image {
    &__title {
        .typography-heading();

        margin-bottom: 8px;
    }
    &__description {
        .typography-body();

        margin-bottom: 8px;
    }
    &__title + &__description {
        margin-top: -8px;
    }
    &__file {
        display: flex;
        align-items: center;
        margin-top: 8px;
        &-number {
            .typography-body();
            margin-right: 8px;
            &::after {
                content: '.';
            }
        }
        &-link {
            .typography-body();
            color: @color-primary;
            text-decoration: underline;
        }
    }
    &__input {
        height: 70px;
        margin-top: 16px;
    }
}
</style>
