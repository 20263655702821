<template>
    <div class="page-cabinet-referral-info-list-cards">
        <div class="page-cabinet-referral-info-list-cards__item"
            v-for="(item, index) in items"
            v-bind:key="index"
            >
            <ui-card-base>
                <div class="page-cabinet-referral-info-list-cards__item-status">
                    <page-cabinet-referral-info-list-status v-bind:code="item.status" />
                </div>
                <div class="page-cabinet-referral-info-list-cards__item-line"
                    v-for="(line, index) in item.lines"
                    v-bind:key="index"
                    >
                    <div class="page-cabinet-referral-info-list-cards__item-line-title"
                        v-text="line.title + ':'"
                    />
                    <div class="page-cabinet-referral-info-list-cards__item-line-value"
                        v-text="line.value"
                    />
                </div>
            </ui-card-base>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'page-cabinet-referral-info-list-cards',
    computed: {
        ...mapState('referral', [
            'getReferralsResult',
        ]),
        items() {
            return this.getReferralsResult.map(x => ({
                status: x.status,
                lines: [
                    { title: 'ФИО', value: x.name },
                    { title: 'Сумма инвестиции', value: x.investment > 0 ? x.investmentFormatted : '–' },
                    { title: 'Комиссия', value: x.investment > 0 ? x.feeFormatted : '–' },
                ],
            }));
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-referral-info-list-cards {
    &__item {
        & ~ & {
            margin-top: 8px;
        }
        &-status {
            margin-bottom: 24px;
        }
        &-line {
            & ~ & {
                margin-top: 4px;
            }
            &-title {
                .typography-caption();

                display: inline-block;
                margin-right: 12px;

                color: @color-gray-main;
                font-weight: bold;
                text-transform: uppercase;
            }
            &-value {
                .typography-body();

                display: inline-block;
            }
        }
    }
}
</style>
