<template>
    <ui-input-base-text
        v-bind:formatter="formatDown"
        v-bind:is-invalid="isInvalid"
        v-bind="formattedModel"
        v-bind:type="'tel'"
        v-on:input="inputHandler"
        v-on:focus="focusHandler"
        v-on:blur="blurHandler"
        inputmode="numeric"
        pattern="\\d*"
    />
</template>

<script>
export default {
    name: 'ui-input-numeric-alpahbetic',
    props: {
        model: {
            type: Object,
        },
        isInvalid: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [ String, Number ],
        },
    },
    model: {
        prop: 'value',
        event: 'input',
    },
    computed: {
        formattedModel() {
            return { ...this.model, value: this.formatDown(this.model.value) };
        },
    },
    methods: {
        formatDown(value) {
            let result = value ? value.toString() : '';
            if (result.length === 0) {
                return '';
            }
            if (this.model.maxLength) {
                result = result.substring(0, this.model.maxLength);
            }
            return result.toUpperCase().replace(/[^0-9A-ZА-Я]/g, '');
        },
        formatUp(value) {
            return value;
        },
        inputHandler(newValue) {
            this.$emit('input', this.formatUp(newValue));
        },
        focusHandler(data) {
            this.$emit('focus', data);
        },
        blurHandler(data) {
            this.$emit('blur', data);
        },
    },
    watch: {
        'model.maxLength'() {
            if (this.value) {
                this.inputHandler(this.formatDown(this.value));
            }
        },
    },
    mounted() {
        if (this.value?.toString() !== this.formatUp(this.formattedModel.value)) {
            this.$emit('input', this.formatUp(this.formattedModel.value));
        }
    },
};
</script>

<style scoped lang="less">
@import "~theme";
</style>
