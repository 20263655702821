<template>
    <section class="page-cabinet-deals">
        <div class="page-cabinet-deals__title">
            Сделки по акциям
        </div>
        <div class="page-cabinet-deals__list"
            v-if="!isLoading && items && items.length > 0"
            >
            <div class="page-cabinet-deals__list-item"
                v-for="(item, i) in items"
                v-bind:key="i"
                >
                <page-cabinet-deals-item
                    v-bind:item="item"
                />
            </div>
        </div>
        <div class="page-cabinet-deals__empty"
            v-if="!isLoading && !error && items && items.length === 0"
            v-text="'Записей не найдено'"
        />
        <div class="page-cabinet-deals__error"
            v-if="error && !isLoading"
            v-text="'Ошибка: ' + error"
        />
        <div class="page-cabinet-deals__loader"
            v-if="isLoading"
            >
            <div class="page-cabinet-deals__loader-container">
                <ui-loader />
            </div>
        </div>
    </section>
</template>

<script>
import { mapState } from 'vuex';
// import config from '~/config';
import utils from '~/utils';

export default {
    name: 'page-cabinet-deals',
    computed: {
        ...mapState('market', [
            'getDealsResult',
            'getDealsError',
            'getDealsIsLoading',

            'signOrderResult',
            'signOrderError',
            'signOrderIsLoading',

            'cancelOrderResult',
            'cancelOrderError',
            'cancelOrderIsLoading',
        ]),
        items() {
            if (!this.getDealsResult) {
                return null;
            }
            return this.getDealsResult;
        },
        isLoading() {
            return this.getDealsIsLoading ||
                this.signOrderIsLoading ||
                this.cancelOrderIsLoading;
        },
        error() {
            return this.getDealsError && utils.network.parseError(this.getDealsError, true);
        },
    },
    mounted() {
        if (this.getDealsResult === null && !this.getDealsIsLoading) {
            this.$store.dispatch('market/getDeals');
        }
    },
    watch: {
        cancelOrderIsLoading(newVal, oldVal) {
            if (!newVal && oldVal && this.cancelOrderResult) {
                this.$store.dispatch('market/getDeals');
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-deals {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    min-height: 100%;
    padding-bottom: 40px;
    &__title {
        .typography-heading();

        padding: 20px 24px 16px;
    }
    &__list {
        &-item {
            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }
    }
    &__empty {
        .typography-body();

        margin: auto;

        text-align: center;
    }
    &__error {
        .typography-body();

        margin: auto;

        color: @color-accent-negative;
        text-align: center;
    }
    &__loader {
        flex: 0 0 auto;
        padding: 50px;
        margin: auto;
        &-container {
            width: 50px;
        }
    }
    @media @media-md-down {
        &__empty {
            .typography-small();

            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            min-height: 100%;
            margin: auto;

            color: @color-gray-main;
        }
    }
    @media @media-sm-down {
        &__title {
            padding: 16px;
        }
    }
}
</style>
