<template>
    <section class="page-cabinet-estates-list">
        <div class="page-cabinet-estates-list__items"
            v-if="items.length > 0 && !isLoading"
            >
            <component
                v-bind:is="mode === 'table' ? 'page-cabinet-estates-list-table' : 'page-cabinet-estates-list-cards'"
                v-bind:items="items"
                v-bind:fields="fields"
            />
        </div>
        <div class="page-cabinet-estates-list__empty"
            v-if="items.length === 0 && !isLoading && !error"
            v-text="'Записей не найдено'"
        />
        <div class="page-cabinet-estates-list__error"
            v-if="error && !isLoading"
            v-text="'Ошибка: ' + error"
        />
        <div class="page-cabinet-estates-list__loader"
            v-if="isLoading"
            >
            <div class="page-cabinet-estates-list__loader-container">
                <ui-loader />
            </div>
        </div>
    </section>
</template>

<script>
import { mapState } from 'vuex';
// import config from '~/config';
import utils from '~/utils';

export default {
    name: 'page-cabinet-estates-list',
    data: () => ({
        mode: 'table',
    }),
    computed: {
        ...mapState('investments', [
            'getUserInvestmentsResult',
            'getUserInvestmentsError',
            'getUserInvestmentsIsLoading',

            'getUserSharesResult',
            'getUserSharesError',
            'getUserSharesIsLoading',
        ]),
        ...mapState('market', [
            'getAllSharesResult',
            'getAllSharesError',
            'getAllSharesIsLoading',
        ]),
        items() {
            if (!this.getUserSharesResult || !this.getAllSharesResult) {
                return [];
            }
            let result = this.$store.state.investments.getUserSharesResult.reduce((acc, curr) => {
                const result = {
                    ...curr.shareData.estate,
                    marketPrice: curr.marketPrice,
                    marketPriceFormatted: curr.marketPriceFormatted,
                    quantity: curr.quantity,
                    shares: [
                        {
                            id: curr.id,
                            currentValue: curr.currentValue,
                            currentValueFormatted: curr.currentValueFormatted,
                            currency: curr.currency,
                            investments: curr.investments,
                            dividends: curr.dividends,
                            income: curr.income,
                            irr: curr.irr,
                            irrFormatted: curr.irrFormatted,
                            mm: curr.mm,
                            share: curr.share,
                            shareFormatted: curr.shareFormatted,
                            quantity: curr.quantity,
                            quantityFormatted: curr.quantityFormatted,
                            investmentsFormatted: curr.investmentsFormatted,
                            dividendsFormatted: curr.dividendsFormatted,
                            incomeFormatted: curr.incomeFormatted,
                        },
                    ],
                    idForSale: this.getAllSharesResult.find(x => x.estate.id === curr.shareData.estate.id)?.id || null,
                };
                if (acc[result.id]) {
                    acc[result.id].quantity += result.shares[0].quantity;
                    acc[result.id].shares.push(result.shares[0]);
                } else {
                    acc[result.id] = result;
                }
                return acc;
            }, {});
            result = Object.keys(result).map(x => result[x]);
            return result;
        },
        isLoading() {
            return this.getUserInvestmentsIsLoading || this.getUserSharesIsLoading || this.getAllSharesIsLoading;
        },
        error() {
            const error = this.getUserInvestmentsError || this.getUserSharesError;
            if (!error) {
                return null;
            }
            return utils.network.parseError(error, true);
        },
        fields() {
            return [
                { title: 'Количество', valueCode: 'quantityFormatted' },
                { title: 'Инвестиции', valueCode: 'investmentsFormatted' },
                { title: 'Текущая Стоимость', valueCode: 'investmentsFormatted' },
                { title: 'Чистый доход', valueCode: 'incomeFormatted' },
                { title: 'Доходность IRR', valueCode: 'irrFormatted' },
                { title: 'Доля в портфеле', valueCode: 'shareFormatted' },
            ];
        },
    },
    methods: {
        updateMode() {
            if (window.innerWidth < 1024 && this.mode === 'table') {
                this.mode = 'cards';
            } else if (window.innerWidth >= 1024 && this.mode !== 'table') {
                this.mode = 'table';
            }
        },
    },
    mounted() {
        this.updateMode();
        window.addEventListener('resize', this.updateMode);
        this.$store.dispatch('investments/getUserShares');
        this.$store.dispatch('market/getAllShares');
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateMode);
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-estates-list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    min-height: 100%;
    &__empty {
        .typography-body();

        margin: auto;

        text-align: center;
    }
    &__error {
        .typography-body();

        margin: auto;

        color: @color-accent-negative;
        text-align: center;
    }
    &__loader {
        flex: 0 0 auto;
        padding: 50px;
        margin: auto;
        &-container {
            width: 50px;
        }
    }
    @media @media-md-down {
        &__table {
            display: none;
        }
        &__empty {
            .typography-small();

            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            min-height: 100%;
            margin: auto;

            color: @color-gray-main;
        }
    }
}
</style>
