<template>
    <ui-card-base>
        <article class="page-cabinet-documents-list-card">
            <div class="page-cabinet-documents-list-card__topline">
                <div class="page-cabinet-documents-list-card__topline-title"
                    v-text="title"
                />
            </div>
            <div class="page-cabinet-documents-list-card__date"
                v-text="dateFormatted"
            />
            <div class="page-cabinet-documents-list-card__document">
                <ui-table-cell-document
                    v-bind="document"
                />
            </div>
        </article>
    </ui-card-base>
</template>

<script>
import moment from 'moment';

export default {
    name: 'page-cabinet-documents-list-card',
    props: {
        title: {
            type: String,
            default: '',
        },
        date: {
            type: Date || String,
            default: new Date(),
        },
        document: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        dateFormatted() {
            return moment(this.date).format('DD.MM.YYYY');
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-documents-list-card {
    .typography-body();

    &__topline {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: baseline;
        &-title {
            padding-right: 20px;
        }
    }
    &__date {
        margin-bottom: 19px;

        color: @color-gray-main;
    }
}
</style>
