/* eslint-disable camelcase */
import { date as dateTransform } from '~/network/mainClient/common/transforms/responses';

export const item = (data) => {
    const result = {
        id: data.id || -1,
        email: data.email || '',
        dateRegistered: new Date(data.registered) || new Date(),
        phone: data.phone || '',
        firstName: data.first_name || '',
        lastName: data.last_name || '',
        secondName: data.second_name || '',
        fullName: [ data.last_name, data.first_name, data.second_name ].filter(x => !!x).join(' '),
        status: data.status || 'authorized',
        isIndividualEntrepreneur: data.legal_type === 'I',
        legalType: data.legal_type,
        isQualifiedInvestor: data.is_qualified_investor || false,
        isLimitedTo600k: data.is_limited_to_600k,
        qiRequestedDate: data.last_qualification_request || null,
        hasAnalytics: data.has_analytics || false,
        referrerCode: data.referrer_code,
    };
    return result;
};

export const userProfile = (data) => {
    const result = {
        accountCode: data.account_code || '',
        username: data.username || '',
        email: data.email || '',
        firstName: data.first_name || '',
        lastName: data.last_name || '',
        secondName: data.second_name || '',
        fullName: [ data.last_name, data.first_name, data.second_name ].filter(x => !!x).join(' '),
        phone: data.phone || '',
        isIndividualEntrepreneur: data.legal_type === 'I',
        legalType: data.legal_type,
        isQualifiedInvestor: data.is_qualified_investor || false,
        isLimitedTo600k: data.is_limited_to_600k,
        organizationName: data.organization_name || '',
    };
    return result;
};

export const userPersonalInfo = (data) => {
    const result = {
        inn: data.inn || '',
        ogrnip: data.ogrnip || '',
        passportNumber: data.passport_number || '',
        passportSeries: data.passport_series || '',
        passportDate: data.passport_date || null,
        subdivisionCode: data.subdivision_code || '',
        subdivisionName: data.subdivision_name || '',
        birthDate: data.birth_date || '',
        birthPlace: data.birth_place || '',
        nationality: data.nationality || '',
        citizenship: data.citizenship || '',
        organizationName: data.organization_name || '',
        organizationAddress: data.organization_address || '',
        registrationAddress: data.registration_address || '',
    };
    return result;
};

export const userJuridicalInfo = ({
    organization_name,
    organization_name_full,
    organization_address,
    legal_address,
    mailing_address,
    bank_name,
    ogrn,
    okved,
    inn,
    kpp,
    bik,
    bank_account,
    cadastral_account,
    date_registration,
    snils,
}) => {
    const result = {
        organizationName: organization_name || null,
        organizationNameFull: organization_name_full || null,
        organizationAddress: organization_address || null,
        legalAddress: legal_address || null,
        mailingAddress: mailing_address || null,
        bankName: bank_name || null,
        ogrn: ogrn || null,
        okved: okved || null,
        inn: inn || null,
        kpp: kpp || null,
        bik: bik || null,
        bankAccount: bank_account || null,
        corrAccount: cadastral_account || null,
        dateRegistration: date_registration ? dateTransform(date_registration) : null,
        snils: snils || null,
    };
    return result;
};

export const userMigrationInfo = (data) => {
    const result = {
        migrationCardDateEnd: dateTransform(data.migration_card_date_end),
        migrationCardDateStart: dateTransform(data.migration_card_date_start),
        migrationCardNumber: data.migration_card_number,
        migrationDocumentDateEnd: dateTransform(data.migration_document_date_end),
        migrationDocumentDateStart: dateTransform(data.migration_document_date_start),
        migrationDocumentNumber: data.migration_document_number,
    };
    return result;
};

export const userAdditionalInfo = (data) => {
    const result = {
        additionalIsFinancialNonPerformance: data.is_financial_non_performance,
        additionalIsBeneficiaryOwner: data.is_beneficiary_owner === null ||
            data.is_beneficiary_owner === '' ||
            data.is_beneficiary_owner === true,
        additionalHasOtherBeneficiary: data.has_other_beneficiary,
        additionalIncomeSource: data.income_source,
        additionalIncomeSourceCustom: data.income_source_additional,
        additionalFinancialPosition: data.financial_position,
        additionalFinancialPositionCustom: data.financial_position_additional,
        additionalBusinessReputation: data.business_reputation,
        additionalIpFinHasAnnualAccountingStatement: data.ip_fin_has_annual_accounting_statement,
        additionalIpFinHasRegularTaxReturn: data.ip_fin_has_regular_tax_return,
        additionalIpFinHasAuditorReport: data.ip_fin_has_auditor_report,
        additionalIpFinHasTaxFullfilmentReport: data.ip_fin_has_tax_fullfilment_report,
        additionalIpFinHasBankruptCertificate: data.ip_fin_has_bankrupt_certificate,
        additionalIpFinBankruptStep: data.ip_fin_bankrupt_step,
        additionalPublicOfficial: data.public_official === null ||
            data.public_official === '' ||
            data.public_official === 'no' ||
            data.public_official === false ? 'no' : 'yes',
        isBorrower: data.is_borrower || false,
    };
    return result;
};

export const userPhotos = (data) => {
    const photoPassportFirst = data.results.find(x => x.image_type === 'passport-first');
    const photoPassportRegistration = data.results.find(x => x.image_type === 'passport-registration');
    const photoMigrationCard = data.results.find(x => x.image_type === 'migration-card');
    const photoMigrationVisa = data.results.find(x => x.image_type === 'migration-visa');
    const result = {
        photoPassportFirst: photoPassportFirst?.url || null,
        photoPassportRegistration: photoPassportRegistration?.url || null,
        photoMigrationCard: photoMigrationCard?.url || null,
        photoMigrationVisa: photoMigrationVisa?.url || null,
    };
    return result;
};

export const dashboardHighlights = (data) => {
    const result = {
        balance: data.balance - data.reserved_balance || 0,
        reserved: data.reserved_balance || 0,
        investments: data.investment_summary || 0,
        dividends: data.dividends_stat || 0,
        income: data.pure_income || 0,
        portfolio: data.current_portfolio || 0,
        irr: data.irr_income ? data.irr_income : 0,
        mm: data.mm_income || 0,
    };
    return result;
};

export const dashboardIncome = (data) => {
    const absolute = {
        dividends: {
            year: data.absolute ? data.absolute[0][0] : 0,
            all: data.absolute ? data.absolute[0][1] : 0,
        },
        portfolio: {
            year: data.absolute ? data.absolute[1][0] : 0,
            all: data.absolute ? data.absolute[1][1] : 0,
        },
    };
    const percent = {
        dividends: {
            year: data.percent ? data.percent[0][0] * 100 : 0,
            all: data.percent ? data.percent[0][1] * 100 : 0,
        },
        portfolio: {
            year: data.percent ? data.percent[1][0] * 100 : 0,
            all: data.percent ? data.percent[1][1] * 100 : 0,
        },
    };
    const result = {
        absolute,
        percent,
    };
    return result;
};

export const announcementsItem = (data) => {
    return {
        dateFormatted: dateTransform(data.created),
        html: data.text,
    };
};

export const announcements = (data) => {
    const result = {
        items: data.results.map(announcementsItem),
        count: data.count,
    };
    return result;
};

export const twoFactor = ({ enabled }) => {
    return { isEnabled: enabled };
};
