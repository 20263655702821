import { mainClient } from '~/network';

const moduleTitle = 'geo';

const state = {
    getCountriesIsLoading: false,
    getCountriesError: null,
    getCountriesResult: null,
};

const getters = {
};

const actions = {
    async getCountries({ state }, payload = {}) {
        if (state.getCountriesIsLoading) {
            return;
        }
        state.getCountriesIsLoading = true;

        const { data, error, status } = await mainClient[moduleTitle].requests.getCountries(payload);
        if (error) {
            console.error(`Error in action ${moduleTitle}/getCountries (${status})`);
            state.getCountriesError = error;
            state.getCountriesResult = null;
        } else if (data) {
            state.getCountriesResult = data.items;
            state.getCountriesError = null;
        }

        state.getCountriesIsLoading = false;
    },
};

const mutations = {
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
