import client from '../_client';
import config from '~/config';
import transforms from './transforms';
import utils from '~/utils';

export const calculate = (data = {}) => {
    const id = data.id;
    delete data.id;
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.estatesAdmin.loan.share.calculate.replace('{id}', id),
        method: 'POST',
        data: transforms.requests.calculate(data),
        transformData: transforms.responses.calculate,
    });
};

export const issue = (data = {}) => {
    const id = data.id;
    delete data.id;
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.estatesAdmin.loan.share.issue.replace('{id}', id),
        method: 'POST',
        data: transforms.requests.issue(data),
    });
};
