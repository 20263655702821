<template>
    <section class="page-cabinet-transactions-list">
        <template v-if="cardsData && cardsData.length > 0">
            <div class="page-cabinet-transactions-list__table">
                <ui-table v-bind="tableData" />
            </div>
            <div class="page-cabinet-transactions-list__cards">
                <div class="page-cabinet-transactions-list__cards-header">
                    <div class="page-cabinet-transactions-list__cards-header-item">
                        Транзакция
                    </div>
                    <div class="page-cabinet-transactions-list__cards-header-item _sortable"
                        v-on:click="sort"
                        >
                        Дата
                        <div class="page-cabinet-transactions-list__cards-header-item-icon">
                            <span class="icon icon-sort-desc" />
                        </div>
                    </div>
                </div>
                <div class="page-cabinet-transactions-list__cards-list">
                    <div class="page-cabinet-transactions-list__cards-list-item"
                        v-for="(card, index) in cardsData"
                        v-bind:key="index"
                        >
                        <page-cabinet-transactions-list-card
                            v-bind="card"
                        />
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="page-cabinet-transactions-list__empty"
                v-text="'Записей не найдено'"
            />
        </template>
    </section>
</template>

<script>
import utils from '~/utils';

export default {
    name: 'page-cabinet-transactions-list',
    computed: {
        items() {
            const items = this.$store.getters['transfers/items'];
            if (!items) {
                return null;
            }
            return items;
        },
        currency() {
            return this.$store.getters['site/currency'];
        },
        tableData() {
            const columns = [
                {
                    title: 'Транзакция',
                    style: { flex: '1 1 647px' },
                },
                {
                    title: 'Тип',
                    style: { flex: '0 0 200px' },
                },
                {
                    title: 'Сумма',
                    style: { flex: '0 0 170px' },
                },
                {
                    title: 'Дата',
                    style: { flex: '0 0 80px' },
                },
            ];
            if (!this.items) {
                return {
                    columns,
                    rows: [],
                };
            }
            const rows = [];
            if (this.items) {
                this.items.forEach(item => {
                    rows.push([
                        {
                            props: {
                                text: item.description,
                            },
                        },
                        {
                            props: {
                                text: item.typeName,
                            },
                        },
                        {
                            props: {
                                text: item.amount ? `${utils.formats.formatNumberWithSpaces(item.amount)} ${this.currency.name.short}` : null,
                            },
                        },
                        {
                            type: 'component',
                            component: 'ui-table-cell-date',
                            props: {
                                date: item.date,
                            },
                        },
                    ]);
                });
            }
            return {
                columns,
                rows,
            };
        },
        cardsData() {
            if (this.items) {
                return this.items.map(item => {
                    return {
                        title: item.description,
                        type: item.typeName,
                        date: item.date,
                        sum: item.amount ? `${utils.formats.formatNumberWithSpaces(item.amount)} ${this.currency.name.short}` : null,
                    };
                });
            } else {
                return [];
            }
        },
    },
    methods: {
        sort() {
            // console.log('sort');
        },
    },
    mounted() {
        this.$store.dispatch('transfers/getItems', { is_transaction: true });
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-transactions-list {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    &__table {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: ~'calc(100vh - 77px)'; // 77px - common-header height
    }
    &__cards {
        display: none;
    }
    &__empty {
        .typography-small();

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 100%;
        padding-right: 135px;
        margin: auto;

        color: @color-gray-main;
    }
    @media @media-md-down {
        &__table {
            display: none;
        }
        &__cards {
            display: block;
            &-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                height: 42px;
                padding-top: 10px;
                &-item {
                    .typography-caption();

                    flex: 0 0 auto;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding: 0 16px;

                    font-weight: bold;
                    text-transform: uppercase;
                    &._sortable {
                        cursor: pointer;
                    }
                    &-icon {
                        margin-left: 6px;
                        font-size: 1.8rem;
                    }
                }
            }
            &-list {
                width: ~'calc(100% + 18px)';
                height: ~'calc(100vh - 60px - 56px - 42px)';    // 60px - common-header (mobile)
                                                                // 56px - layout-cabinet-sideblock-menu
                                                                // 42px - page-cabinet-transactions-list__cards-header
                padding-right: 18px;
                padding-bottom: 40px;
                margin-right: -18px;
                overflow: auto;
                &-item {
                    margin-bottom: 8px;
                }
            }
        }
        &__empty {
            padding-right: 0;
        }
    }
}
</style>
