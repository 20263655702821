import client from '../_client';
import config from '~/config';
import utils from '~/utils';
import transforms from './transforms';

export const login = (data = {}) => {
    return client.request({
        url: config.api.user.login,
        method: 'POST',
        data,
        // transformData: (data) => transforms.responses.item(data.result),
        transformError: error => utils.network.parseError(error, true),
    });
};

export const registration = (data = {}) => {
    return client.request({
        url: config.api.user.register,
        method: 'POST',
        data: transforms.requests.registration(data),
        // transformData: (data) => transforms.responses.item(data.result),
        transformError: error => utils.network.parseError(error, true),
    });
};

// Get Current User
export const getUser = (params = {}) => {
    return client.request({
        url: config.api.user.login,
        method: 'GET',
        params,
        transformData: (data) => transforms.responses.item(data.result),
    });
};

export const logout = (data = {}) => {
    return client.request({
        url: config.api.user.logout,
        method: 'GET',
        data,
    });
};

export const updatePassword = (data = {}) => {
    return client.request({
        url: config.api.user.password,
        method: 'POST',
        data,
    });
};

// Complete Reset Password (set new password)
export const resetPassword = (data = {}) => {
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.user.resetComplete,
        method: 'POST',
        data,
        transformError: error => utils.network.parseError(error, true),
    });
};

// Update User Info
export const updateUser = (data = {}) => {
    return client.request({
        url: config.api.user.update,
        method: 'PATCH',
        data: transforms.requests.update(data),
        transformData: transforms.responses.item,
        transformError: error => utils.network.parseError(error, true),
    });
};

export const getDashboardHighlights = (params = {}) => {
    return client.request({
        url: config.api.user.dashboard.highlights,
        method: 'GET',
        params,
        transformData: transforms.responses.dashboardHighlights,
        transformError: error => utils.network.parseError(error, true),
    });
};

export const getAnnouncements = (params = {}) => {
    return client.request({
        url: config.api.user.announcements,
        method: 'GET',
        params,
        transformData: transforms.responses.announcements,
        transformError: error => utils.network.parseError(error, true),
    });
};

export const getTwoFactor = (params = {}) => {
    return client.request({
        url: config.api.user.twoFactor,
        method: 'GET',
        params,
        transformData: transforms.responses.twoFactor,
    });
};

export const enableTwoFactor = (data = {}) => {
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.user.twoFactor,
        method: 'POST',
        data,
    });
};

export const disableTwoFactor = (data = {}) => {
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.user.twoFactor,
        method: 'DELETE',
        data,
    });
};

// Get dashboard devidends (chart-data)
export const getDashboardDividends = (params = {}) => {
    return client.request({
        url: config.api.user.dashboard.dividends,
        method: 'GET',
        params,
        transformError: error => utils.network.parseError(error, true),
    });
};

// Get dashboard portfolio (chart-data)
export const getDashboardPortfolio = (params = {}) => {
    return client.request({
        url: config.api.user.dashboard.portfolio,
        method: 'GET',
        params,
        transformError: error => utils.network.parseError(error, true),
    });
};

export const getDashboardIncome = (params = {}) => {
    return client.request({
        url: config.api.user.dashboard.income,
        method: 'GET',
        params,
        transformData: transforms.responses.dashboardIncome,
        transformError: error => utils.network.parseError(error, true),
    });
};

export const getUserProfile = (params = {}) => {
    return client.request({
        url: config.api.user.profile,
        method: 'GET',
        params,
        transformData: transforms.responses.userProfile,
    });
};

export const updateUserProfile = (data = {}) => {
    return client.request({
        url: config.api.user.profile,
        method: 'PATCH',
        data: transforms.requests.userProfile(data),
        transformData: transforms.responses.userProfile,
    });
};

export const getUserPersonalInfo = (params = {}) => {
    return client.request({
        url: config.api.user.personalInfo,
        method: 'GET',
        params,
        transformData: transforms.responses.userPersonalInfo,
    });
};

export const updateUserPersonalInfo = (data = {}) => {
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.user.personalInfo,
        method: 'PATCH',
        data: transforms.requests.userPersonalInfo(data),
        transformData: transforms.responses.userPersonalInfo,
    });
};

export const getUserJuridicalInfo = (params = {}) => {
    return client.request({
        url: config.api.user.juridicalInfo,
        method: 'GET',
        params,
        transformData: transforms.responses.userJuridicalInfo,
    });
};

export const updateUserJuridicalInfo = (data = {}) => {
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.user.juridicalInfo,
        method: 'PATCH',
        data: transforms.requests.userJuridicalInfo(data),
        transformData: transforms.responses.userJuridicalInfo,
    });
};

export const patchUserJuridicalInfo = (data = {}) => {
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.user.juridicalInfo,
        method: 'PATCH',
        data: transforms.requests.userJuridicalInfo(data),
        transformData: transforms.responses.userJuridicalInfo,
    });
};

export const getUserMigrationInfo = (params = {}) => {
    return client.request({
        url: config.api.user.migrationInfo,
        method: 'GET',
        params,
        transformData: transforms.responses.userMigrationInfo,
    });
};

export const updateUserMigrationInfo = (data = {}) => {
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.user.migrationInfo,
        method: 'PATCH',
        data: transforms.requests.userMigrationInfo(data),
        transformData: transforms.responses.userMigrationInfo,
    });
};

export const getUserAdditionalInfo = (params = {}) => {
    return client.request({
        url: config.api.user.additionalInfo,
        method: 'GET',
        params,
        transformData: transforms.responses.userAdditionalInfo,
    });
};

export const updateUserAdditionalInfo = (data = {}) => {
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.user.additionalInfo,
        method: 'PATCH',
        data: transforms.requests.userAdditionalInfo(data),
        transformData: transforms.responses.userAdditionalInfo,
    });
};

export const getUserPhotos = (params = {}) => {
    return client.request({
        url: config.api.user.photo + params.userId + '/' + `?timestamp=${new Date().getTime()}`,
        method: 'GET',
        transformData: transforms.responses.userPhotos,
    });
};

export const updateUserPhoto = (data = {}) => {
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.user.photo + `${data.userId}/${transforms.requests.userPhotoType(data.type)}/`,
        method: 'POST',
        data: data.formData,
    });
};

export const verificationApprove = (data = {}) => {
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.user.verification.approve,
        method: 'POST',
        data,
    });
};

export const verificationReset = (data = {}) => {
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.user.verification.reset,
        method: 'POST',
        data,
    });
};

export const getWithdrawInfo = (params = {}) => {
    return client.request({
        url: config.api.user.withdraw.getInfo,
        method: 'GET',
        // transformData: transforms.responses.userPhotos,
    });
};

export const requestWithdraw = (data = {}) => {
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.user.withdraw.requestWithdraw,
        method: 'POST',
        data: transforms.requests.withdraw(data),
        // transformData: transforms.responses.userPhotos,
    });
};

export const updateUserAnalytics = (data = {}) => {
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.user.analytics,
        method: 'POST',
        data,
        // transformData: transforms.responses.userPhotos,
    });
};
