<template>
    <div class="modal-withdrawal-success">
        <div class="modal-withdrawal-success__title">
            Запрос на вывод средств успешно отправлен
        </div>
        <div class="modal-withdrawal-success__subtitle">
            Ожидайте поступление средств в течение суток
        </div>
    </div>
</template>

<script>
export default {
    name: 'modal-withdrawal-success',
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-withdrawal-success {
    .modal();

    position: relative;

    max-width: 438px;
    width: calc(100vw - 48px);
    padding: 40px;
    border-radius: @border-radius-lg;
    overflow: hidden;

    background-color: @color-gray-lightest;

    text-align: center;
    &__title {
        .typography-large();

        margin-bottom: 8px;
    }
    &__subtitle {
        .typography-small()
    }
    @media @media-sm-down {
        padding: 40px 16px;
        &__title {
            .typography-medium();
        }
        &__subtitle {
            .typography-body();
        }
    }
}
</style>
