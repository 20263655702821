<template>
    <div class="page-market-item-orders-table">
        <div class="page-market-item-orders-table__headline">
            <div class="page-market-item-orders-table__headline-item">
                ДАТА
            </div>
            <div class="page-market-item-orders-table__headline-item">
                Тип заявки
            </div>
            <div class="page-market-item-orders-table__headline-item">
                Стоимость акции
            </div>
            <div class="page-market-item-orders-table__headline-item">
                Кол-во акций
            </div>
            <div class="page-market-item-orders-table__headline-item">
                Сумма
            </div>
            <div class="page-market-item-orders-table__headline-item">
            </div>
        </div>
        <div class="page-market-item-orders-table__list">
            <div class="page-market-item-orders-table__list-item"
                v-for="(item, index) in items"
                v-bind:key="index"
                >
                <page-market-item-orders-table-item
                    v-bind:item="item"
                    v-bind:is-user-shareless="isUserShareless"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'page-market-item-orders-table',
    props: {
        items: {
            type: Array,
        },
        isUserShareless: {
            type: Boolean,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-market-item-orders-table {
    &__headline {
        display: flex;
        align-items: baseline;
        justify-content: flex-start;
        width: 100%;
        padding: 0 12px;
        margin-bottom: 16px;
        &-item {
            .typography-body();

            flex: 1 1 auto;
            padding: 0 12px;

            color: @color-gray-main;
            &:nth-child(1) {
                width: 195px;

                text-transform: uppercase;
            }
            &:nth-child(2) {
                width: 144px;
            }
            &:nth-child(3) {
                width: 256px;
            }
            &:nth-child(4) {
                width: 292px;
            }
            &:nth-child(5) {
                width: 210px;
            }
            &:nth-child(6) {
                width: 180px;
            }
        }
    }
    &__list {
        &-item {
            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }
    }
}
</style>
