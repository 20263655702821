<template>
    <article class="page-cabinet-dashboard-balance">
        <div class="page-cabinet-dashboard-balance__headline">
            <div class="page-cabinet-dashboard-balance__headline-title">
                Баланс
            </div>
            <div class="page-cabinet-dashboard-balance__headline-withdrawal"
                v-if="balance > 0"
                >
                <a class="page-cabinet-dashboard-balance__headline-withdrawal-link"
                    v-on:click.prevent="showModal"
                    >
                    Вывод средств
                </a>
            </div>
        </div>
        <div>
            <ui-card-base with-border>
                <div class="page-cabinet-dashboard-balance__content">
                    <div class="page-cabinet-dashboard-balance__content-stats">
                        <div class="page-cabinet-dashboard-balance__content-stats-item">
                            <div class="page-cabinet-dashboard-balance__content-stats-item-title">
                                Свободно
                            </div>
                            <div class="page-cabinet-dashboard-balance__content-stats-item-info">
                                <div class="page-cabinet-dashboard-balance__content-stats-item-info-value"
                                    v-text="balanceFormatted"
                                />
                                <div class="page-cabinet-dashboard-balance__content-stats-item-info-units">
                                    /руб
                                </div>
                            </div>
                        </div>
                        <div class="page-cabinet-dashboard-balance__content-stats-item">
                            <div class="page-cabinet-dashboard-balance__content-stats-item-title">
                                В резерве
                            </div>
                            <div class="page-cabinet-dashboard-balance__content-stats-item-info">
                                <div class="page-cabinet-dashboard-balance__content-stats-item-info-value"
                                    v-text="reservedFormatted"
                                />
                                <div class="page-cabinet-dashboard-balance__content-stats-item-info-units">
                                    /руб
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="page-cabinet-dashboard-balance__content-buttons"
                        v-if="user !== null && user.status !== 'new'"
                        >
                        <div class="page-cabinet-dashboard-balance__content-buttons-item">
                            <ui-button
                                v-bind:href="urls.refill"
                                v-bind:disabled="user.status !== 'verified'"
                                theme="primary"
                                >
                                Пополнить счет
                            </ui-button>
                        </div>
                        <div class="page-cabinet-dashboard-balance__content-buttons-item _withdrawal">
                            <ui-button
                                v-bind:disabled="balance === 0"
                                v-on:click="showModal"
                                theme="secondary-1"
                                >
                                Вывод средств
                            </ui-button>
                        </div>
                    </div>
                </div>
            </ui-card-base>
        </div>
    </article>
</template>

<script>
import { mapState } from 'vuex';
import config from '~/config';
import utils from '~/utils';

export default {
    name: 'page-cabinet-dashboard-balance',
    data: () => ({
        urls: {
            refill: config.urls.cabinet.refill,
        },
    }),
    computed: {
        ...mapState('dashboard', {
            getHighlightsResult: state => state.getHighlightsResult,
            getHighlightsError: state => state.getHighlightsError,
            getHighlightsIsLoading: state => state.getHighlightsIsLoading,
        }),
        user() {
            return this.$store.getters['users/user'];
        },
        balance() {
            return this.getHighlightsResult ? this.getHighlightsResult.balance : 0;
        },
        balanceFormatted() {
            return utils.formats.formatNumberWithSpaces(this.balance);
        },
        reserved() {
            return this.getHighlightsResult ? this.getHighlightsResult.reserved : 0;
        },
        reservedFormatted() {
            return utils.formats.formatNumberWithSpaces(this.reserved);
        },
    },
    methods: {
        showModal() {
            this.$store.commit('modals/push', 'withdrawal');
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-dashboard-balance {
    margin-top: 20px;
    margin-bottom: 4px;
    &__headline {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 7px 16px;
        &-title {
            .typography-heading();

            flex: 0 0 auto;
            padding-right: 24px;
        }
        &-withdrawal {
            display: none;
        }
    }
    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-stats {
            flex: 0 1 66%;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            &-item {
                flex: 1 1 auto;
                &-title {
                    .typography-body();

                    margin-bottom: 8px;
                }
                &-info {
                    display: flex;
                    justify-content: flex-start;
                    align-items: baseline;
                    width: 100%;
                    &-value {
                        .typography-large();

                        color: @color-gray-darker;
                    }
                    &-units {
                        .typography-caption();

                        margin-left: 4px;

                        color: @color-gray-main;
                        text-transform: uppercase;
                    }
                }
            }
        }
        &-buttons {
            flex: 0 1 33%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin: -8px;
            &-item {
                display: block;
                padding: 8px;
                min-width: 174px;

                text-decoration: none;
            }
        }
    }
    @media @media-md-down {
        &__headline {
            &-withdrawal {
                display: block;
                &-link {
                    .typography-body();

                    text-decoration: underline;
                }
            }
        }
        &__content {
            flex-direction: column;
            align-items: stretch;
            &-stats {
                margin-bottom: 20px;
            }
            &-buttons {
                justify-content: center;
                &-item {
                    flex: 1 1 100%;
                    &._withdrawal {
                        display: none;
                    }
                }
            }
        }
    }
}
</style>
