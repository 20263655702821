<template>
    <div class="modal-verification-waiting">
        <div class="modal-verification-waiting__sign">
            <icon name="warning" />
        </div>
        <div class="modal-verification-waiting__text">
            Дождитесь подтверждения верфикации
        </div>
    </div>
</template>

<script>
import config from '~/config';

export default {
    name: 'modal-verification-waiting',
    data: () => ({
        verificationUrl: config.urls.cabinet.verification,
    }),
    methods: {
        close() {
            this.$store.commit('modals/pop');
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-verification-waiting {
    .modal();
    &__sign {
        margin: 0 auto 16px;
        width: fit-content;

        color: @color-primary;
        font-size: 3.2rem;
    }
    &__text {
        .typography-small();

        margin-bottom: 24px;

        text-align: center;
    }
    &__buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        &-item {
            flex: 0 0 204px;
            display: block;

            text-decoration: none;
        }
    }
}
</style>
