<template>
    <article class="common-card-dictionary">
        <ui-card-base v-bind="cardProps">
            <div class="common-card-dictionary__item"
                v-for="(item, index) in items"
                v-bind:key="index"
                >
                <div class="common-card-dictionary__item-title"
                    v-text="item.title"
                />
                <div class="common-card-dictionary__item-value"
                    v-text="item.value"
                />
            </div>
        </ui-card-base>
    </article>
</template>

<script>
export default {
    name: 'common-card-dictionary',
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        cardProps: {
            type: Object,
            default: null,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.common-card-dictionary {
    .typography-body();

    &__item {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        width: 100%;
        & ~ & {
            margin-top: 8px;
        }
        &-title {
            flex: 0 1 auto;
            padding-right: @grid-gap-half;
        }
        &-value {
            flex: 0 1 auto;
            padding-left: @grid-gap-half;

            text-align: right;
        }
    }
    @media @media-md-down {
        .typography-caption();
        &__item {
            flex-wrap: wrap;
        }
    }
}
</style>
