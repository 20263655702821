import client from '../_client';
import config from '~/config';
import utils from '~/utils';

export const sendFeedback = (data = {}) => {
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.feedback.send,
        method: 'POST',
        data,
        transformError: error => utils.network.parseError(error, true),
    });
};
