<template>
    <header class="common-admin-header">
        <div class="common-admin-header__container">
            <a class="common-admin-header__logo"
                v-bind:href="urls.index"
                >
                <ui-logo-text />
            </a>
            <div class="common-admin-header__logout"
                v-on:click="logout"
                >
                <div class="common-admin-header__logout-text"
                    v-text="'Выйти'"
                />
                <div class="common-admin-header__logout-icon">
                    <icon name="out" />
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import config from '~/config';

export default {
    name: 'common-admin-header',
    data: () => ({
        urls: {
            index: config.urls.adminDashboard.index,
        },
    }),
    methods: {
        logout() {
            this.$store.dispatch('users/logout');
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.common-admin-header {
    display: flex;
    align-items: center;
    width: 100%;
    height: @container-header-height;

    background-color: @color-gray-lightest;

    box-shadow: 0 0 3px 0 fade(@color-primary, 10%);
    &__container {
        .container();

        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__logo {
        flex: 0 0 auto;
        display: block;
        padding: 16px 0;
        margin-right: 49px;

        font-size: 2.4rem;
        text-decoration: none;

        cursor: pointer;
    }
    &__logout {
        padding: 10px 0 10px 10px;

        cursor: pointer;
        &-text {
            .typography-caption();

            font-weight: bold;
            text-transform: uppercase;
        }
        &-icon {
            display: none;
        }
    }
    @media @media-md-down {
        box-shadow: 0 0 3px 0 fade(@color-primary, 10%);
    }
    @media @media-sm-down {
        height: @container-header-height-mobile;
        &__logo {
            font-size: 1.8rem;
        }
        &__logout {
            cursor: pointer;
            &-text {
                display: none;
            }
            &-icon {
                display: block;

                font-size: 1.8rem;
            }
        }
    }
}
</style>
