import admin from './admin';
import blog from './blog';
import cabinet from './cabinet';
import common from './common';
import dashboard from './dashboard';
import documents from './documents';
import estates from './estates';
import feedback from './feedback';
import geo from './geo';
import investments from './investments';
import market from './market';
import modals from './modals';
import news from './news';
import notifications from './notifications';
import qualification from './qualification';
import referral from './referral';
import site from './site';
import sms from './sms';
import status from './status';
import subscription from './subscription';
import subscriptions from './subscriptions';
import transfers from './transfers';
import users from './users';
import verification from './verification';

export default {
    admin,
    blog,
    cabinet,
    common,
    dashboard,
    documents,
    estates,
    feedback,
    geo,
    investments,
    market,
    modals,
    news,
    notifications,
    qualification,
    referral,
    site,
    sms,
    status,
    subscription,
    subscriptions,
    transfers,
    users,
    verification,
};
