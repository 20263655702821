import client from '../_client';
import config from '~/config';
import utils from '~/utils';

export const getQuestions = (params = {}) => {
    return client.request({
        url: config.api.questionary.questions,
        method: 'GET',
        params,
    });
};

export const getAnswers = (params = {}) => {
    return client.request({
        url: config.api.questionary.answers,
        method: 'GET',
        params,
    });
};

export const updateAnswers = (data = {}) => {
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.questionary.answers,
        method: 'POST',
        data,
    });
};
