import client from '../_client';
import config from '~/config';
import transforms from './transforms';
import utils from '~/utils';

export const getItems = (params = {}) => {
    return client.request({
        url: config.api.estates.items,
        method: 'GET',
        params,
        transformData: transforms.responses.list,
    });
};

export const getItem = (params = { id: -1 }) => {
    const id = params.id;
    delete params.id;
    return client.request({
        url: config.api.estates.items + id + '/',
        method: 'GET',
        params,
        transformData: transforms.responses.item,
    });
};

export const getTypes = (params = {}) => {
    return client.request({
        url: config.api.estates.types,
        method: 'GET',
        params,
        transformData: transforms.responses.typesList,
    });
};

export const getChartSharesData = ({ id, ...params } = {}) => {
    return client.request({
        url: config.api.estates.chartData.shares.replace('{id}', id),
        method: 'GET',
        params,
        transformData: transforms.responses.chartData,
    });
};

export const getChartDividendsData = ({ id, ...params } = {}) => {
    return client.request({
        url: config.api.estates.chartData.dividends.replace('{id}', id),
        method: 'GET',
        params,
        transformData: transforms.responses.chartData,
    });
};

export const getAdminEstateInfo = (params = { id: -1 }) => {
    const id = params.id;
    delete params.id;
    return client.request({
        url: config.api.estatesAdmin.estate + id + '/',
        method: 'GET',
        params,
        transformData: transforms.responses.adminEstateItem,
    });
};

export const getAdminEstateShares = (params = { id: -1 }) => {
    const id = params.id;
    delete params.id;
    return client.request({
        url: config.api.estatesAdmin.shares.replace('{id}', id),
        method: 'GET',
        params,
        transformData: transforms.responses.adminEstateSharesList,
    });
};

export const createEstate = (data = {}) => {
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.estatesAdmin.estate,
        method: 'POST',
        data: transforms.requests.adminEstateItem(data),
        transformData: transforms.responses.adminEstateItem,
    });
};

export const removeEstate = (data = { id: -1 }) => {
    const id = data.id;
    delete data.id;
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.estatesAdmin.estate + id + '/',
        method: 'DELETE',
        // data: transforms.requests.adminEstateItem(data),
        // transformData: transforms.responses.adminEstateItem,
    });
};

export const updateAdminEstateInfo = (data = { id: -1 }) => {
    const id = data.id;
    delete data.id;
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.estatesAdmin.estate + id + '/',
        method: 'PATCH',
        data: transforms.requests.adminEstateItem(data),
        transformData: transforms.responses.adminEstateItem,
    });
};

// export const patchAdminEstateInfo = (data = { id: -1 }) => {
//     const id = data.id;
//     delete data.id;
//     return client.request({
//         headers: {
//             'X-CSRFToken': utils.network.getCSRFToken(),
//         },
//         url: config.api.estatesAdmin.estate + id + '/',
//         method: 'PATCH',
//         data: transforms.requests.adminEstateItem(data),
//         transformData: transforms.responses.adminEstateItem,
//     });
// };

export const getAdminEstateRounds = ({ id, ...params } = {}) => {
    const url = config.api.estatesAdmin.rounds.replace('{id}', id);
    return client.request({
        url,
        method: 'GET',
        params,
        transformData: transforms.responses.adminEstateRoundsList,
    });
};

export const getAdminEstateRequests = (params = { id: -1 }) => {
    const id = params.id;
    delete params.id;
    const url = config.api.estatesAdmin.requests.replace('{id}', id);
    return client.request({
        url,
        method: 'GET',
        params: transforms.requests.adminEstateRequestsList(params),
        transformData: transforms.responses.adminEstateRequestsList,
    });
};

export const cancelEstateInvestRequest = (params = { estateId: -1, requestId: -1 }) => {
    const estateId = params.estateId;
    const requestId = params.requestId;
    delete params.estateId;
    delete params.requestId;
    const url = config.api.estatesAdmin.cancelRequest.replace('{estateId}', estateId).replace('{requestId}', requestId);
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url,
        method: 'POST',
        params,
        transformData: transforms.responses.adminEstateRequestsListItem,
    });
};

export const editEstateInvestRequest = (data = { estateId: -1, requestId: -1 }) => {
    const estateId = data.estateId;
    const requestId = data.requestId;
    delete data.estateId;
    delete data.requestId;
    const url = config.api.estatesAdmin.editRequest.replace('{estateId}', estateId).replace('{requestId}', requestId);
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url,
        method: 'PATCH',
        data,
    });
};

export const renderLoanOffer = (data = {}) => {
    const id = data.id;
    delete data.id;
    return client.request({
        responseType: 'arraybuffer',
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.estatesAdmin.renderLoanOffer.replace('{id}', id),
        method: 'POST',
        data: data.formData,
    });
};

export const createLoan = (data = {}) => {
    // const id = data.id;
    // delete data.id;
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.estatesAdmin.loan.create,
        // .loansList.replace('{id}', id),
        method: 'POST',
        data,
        // data: data.formData,
    });
};

export const getLoan = (params = {}) => {
    const id = params.id;
    delete params.id;
    return client.request({
        url: config.api.estatesAdmin.lastLoan.replace('{id}', id),
        method: 'GET',
        params,
    });
};

export const cancelLoan = (data = {}) => {
    const id = data.id;
    delete data.id;
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.estatesAdmin.loan.cancel.replace('{id}', id),
        method: 'POST',
        data,
    });
};

export const resolveLoan = (data = {}) => {
    const id = data.id;
    delete data.id;
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.estatesAdmin.loan.resolve.replace('{id}', id),
        method: 'POST',
        data,
    });
};

export const getLoanInvestmentRequests = (params = {}) => {
    const id = params.id;
    delete params.id;
    return client.request({
        url: config.api.estatesAdmin.loan.investmentRequests.replace('{id}', id),
        method: 'GET',
        params,
        transformData: transforms.responses.adminEstateRequestsList,
    });
};

export const uploadDocument = (payload = {}) => {
    const url = payload.document.title === 'ДСУР'
        ? config.api.estatesAdmin.uploadDocument.dsur.replace('{id}', payload.estate.id)
        : config.api.estatesAdmin.uploadDocument.template.replace('{id}', payload.document.id).replace('{estateId}', payload.estate.id);
    const data = new FormData();
    data.append('file', payload.file);
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url,
        method: 'POST',
        data,
        transformData: transforms.responses.adminEstateItemDocument,
    });
};
