<template>
    <div class="page-cabinet-verification-finance-general">
        <ui-form
            v-bind:model="fieldsFiltered"
            v-bind:validation="validation"
            v-bind:submit-handler="submitFormData"
            v-on:update="updateFormData"
            gap="wide"
        />
    </div>
</template>

<script>
export default {
    name: 'page-cabinet-verification-finance-general',
    props: {
        fields: {
            type: Object,
        },
        validation: {
            type: Object,
        },
    },
    computed: {
        fieldsFiltered() {
            const relatedFields = [
                'additionalIncomeSource',
                'additionalIsBeneficiaryOwner',
                'additionalHasOtherBeneficiary',
                'additionalPublicOfficial',
                // 'additionalFinancialPosition',
                // 'additionalBusinessReputation',
            ];
            // если значение additionalIncomeSource === 1, 2 или последней опции из модели этого поля
            // добавляем за ним текстовое поле additionalIncomeSourceCustom,
            const options = this.fields.additionalIncomeSource.options;
            if (this.fields.additionalIncomeSource.value === options[options.length - 1].value) {
                relatedFields.splice(relatedFields.indexOf('additionalIncomeSource') + 1, 0, 'additionalIncomeSourceCustom');
            }
            // // если значение additionalFinancialPosition === 'other'
            // // добавляем за ним текстовое поле additionalFinancialPositionOther,
            // if (this.fields.additionalFinancialPosition.value === 'other') {
            //     relatedFields.splice(relatedFields.indexOf('additionalFinancialPosition') + 1, 0, 'additionalFinancialPositionCustom');
            // }
            const result = {};
            relatedFields.forEach(x => {
                if (this.fields[x] !== undefined) {
                    result[x] = this.fields[x];
                }
            });
            return result;
        },
    },
    methods: {
        submitFormData() {
            this.$emit('submit');
        },
        updateFormData(newData) {
            this.$emit('update', newData);
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-verification-finance-general {
}
</style>
