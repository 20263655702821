<template>
    <a class="page-cabinet-requests-item-content-document"
        v-bind:href="document.url"
        target="_blank"
        >
        <div class="page-cabinet-requests-item-content-document__icon">
            <icon name="document" />
        </div>
        <div class="page-cabinet-requests-item-content-document__title"
            v-text="document.title"
        />
    </a>
</template>

<script>
export default {
    name: 'page-cabinet-requests-item-content-document',
    props: {
        document: {
            type: Object,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-requests-item-content-document {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: fit-content;

    text-decoration: none;
    &:hover &__title {
        text-decoration: underline;
    }
    &__icon {
        flex: 0 0 auto;
        width: 3.2rem;
        height: 3.2rem;
        padding: 0.7rem;
        border-radius: 50%;
        margin-right: 8px;

        font-size: 1.8rem;

        background-color: @color-gray-light;
    }
    &__title {
        .typography-heading();

        padding-top: 4px;
    }
    @media @media-sm-down {
    }
}
</style>
