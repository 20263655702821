<template>
    <div class="modal-status-form-files">
        <div class="modal-status-form-files__item"
            v-for="(file, index) in tempFiles"
            :key="index"
            >
            <div class="modal-status-form-files__item-field">
                <ui-input-base-file
                    v-bind="{ title: file.title, value: file.value }"
                    v-on:change="(file) => changeHandler({ index, file })"
                />
            </div>
            <div class="modal-status-form-files__item-controls">
                <div class="modal-status-form-files__item-controls-title"
                    v-text="file.title"
                />
                <div class="modal-status-form-files__item-controls-remove">
                    <ui-trigger
                        v-on:click="changeHandler({ index, file: null })"
                        >
                        Удалить
                    </ui-trigger>
                </div>
            </div>
        </div>
        <div class="modal-status-form-files__item">
            <ui-input-base-file
                v-bind:key="tempFiles.length"
                v-bind="{ title: 'Загрузите файл', value: null }"
                v-on:change="(file) => changeHandler({ index: null, file })"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'modal-status-form-files',
    props: {
        formData: {
            type: Object,
        },
    },
    data: () => ({
        tempFiles: [],
    }),
    methods: {
        changeHandler({ index, file }) {
            const fileProcessed = file ? { title: file.name, value: file } : null;
            if (index === null) {
                if (fileProcessed) {
                    this.tempFiles.push(fileProcessed);
                }
            } else {
                if (fileProcessed) {
                    this.tempFiles[index] = fileProcessed;
                } else {
                    this.tempFiles.splice(index, 1);
                }
            }
        },
    },
    beforeMount() {
        this.$emit('validation-update', false);
    },
    watch: {
        tempFile(file) {
            this.$emit('validation-update', !!this.temp.files.length);
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-status-form-files {
    &__item {
        & + & {
            margin-top: 24px;
        }
        &-field {
            width: 100%;
        }
        &-controls {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 8px;
            &-title {
                .typography-body();
            }
            &-remove {
                .typography-body();
            }
        }
    }
}
</style>
