import { render, staticRenderFns } from "./trigger.vue?vue&type=template&id=6cbbb81f&scoped=true&"
import script from "./trigger.vue?vue&type=script&lang=js&"
export * from "./trigger.vue?vue&type=script&lang=js&"
import style0 from "./trigger.vue?vue&type=style&index=0&id=6cbbb81f&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cbbb81f",
  null
  
)

export default component.exports