<template>
    <div class="page-cabinet-settings-card">
        <div class="page-cabinet-settings-card__title"
            v-text="title"
        />
        <ui-card-base>
            <div class="page-cabinet-settings-card__container">
                <slot />
            </div>
        </ui-card-base>
    </div>
</template>

<script>
export default {
    name: 'page-cabinet-settings-card',
    props: {
        title: {
            type: String,
            default: '',
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-settings-card {
    width: 100%;
    &__title {
        .typography-heading();

        margin-bottom: 16px;
        margin-left: 24px;
    }
    &__container {
        padding: 8px;
    }
    @media @media-md-down {
        &__title {
            margin-left: 16px;
        }
        &__container {
            padding: 0;
        }
    }
}
</style>
