<template>
    <article class="common-card-estate"
        v-bind:class="{ _sold: item.state === 'sold' }"
        >
        <template v-if="!item">
            no item
        </template>
        <template v-else>
            <component class="common-card-estate__wrapper"
                v-bind:is="user === 'guest' ? 'div' : 'a'"
                v-bind:href="user === 'guest' ? null : item.url"
                v-on:click="user === 'guest' ? showRegisterModal() : null"
                >
                <div class="common-card-estate__cover">
                    <common-image
                        v-bind:image="coverImage"
                        v-bind:border-radius="'0'"
                        is-absolute
                    />
                </div>
                <div class="common-card-estate__container">
                    <div class="common-card-estate__status">
                        <ui-label
                            v-bind:theme="status.theme"
                            v-bind:icon-left="status.icon"
                            is-uppercase
                            >
                            {{ status.text }}
                        </ui-label>
                    </div>
                    <div class="common-card-estate__title"
                        v-text="item.title"
                    />
                    <div class="common-card-estate__lines">
                        <div class="common-card-estate__lines-item">
                            <div class="common-card-estate__lines-item-icon">
                                <icon v-bind:name="item.type.icon" />
                            </div>
                            <div class="common-card-estate__lines-item-text"
                                v-text="item.type.title"
                            />
                        </div>
                        <div class="common-card-estate__lines-item">
                            <div class="common-card-estate__lines-item-icon">
                                <icon name="pin" />
                            </div>
                            <div class="common-card-estate__lines-item-text"
                                v-text="item.address"
                            />
                        </div>
                    </div>
                    <div class="common-card-estate__progress"
                        >
                        <common-investment-campaign-progress
                            v-if="item.investmentCampaigns"
                            v-bind:items="item.investmentCampaigns"
                            v-bind:is-visually-completed="isVisuallyCompleted"
                        />
                        <common-investment-progress
                            v-else
                            v-bind:current="isVisuallyCompleted ? item.target : item.balance"
                            v-bind:target="item.target"
                            v-bind:theme="item.state === 'sold' ? 'disabled' : 'primary'"
                            v-bind:is-target-visible="false"
                        />
                    </div>
                    <div class="common-card-estate__info">
                        <div class="common-card-estate__info-item">
                            <div class="common-card-estate__info-item-title">
                                Цель
                            </div>
                            <div class="common-card-estate__info-item-value"
                                v-text="formatNumber(item.investmentCampaigns.reduce((sum, campaign) => sum + campaign.target, 0) || item.target) + currency"
                            />
                        </div>
                        <div class="common-card-estate__info-item">
                            <div class="common-card-estate__info-item-title">
                                Общая доходность
                            </div>
                            <div class="common-card-estate__info-item-row">
                                <div class="common-card-estate__info-item-row-title"
                                    v-text="'Фактическая / Прогнозная'"
                                />
                                <div class="common-card-estate__info-item-row-value"
                                    v-text="profitabilityFormatted"
                                />
                            </div>
                        </div>
                        <div class="common-card-estate__info-item">
                            <div class="common-card-estate__info-item-title">
                                Дивидендная доходность
                            </div>
                            <div class="common-card-estate__info-item-row">
                                <div class="common-card-estate__info-item-row-title"
                                    v-text="'Фактическая / Прогнозная'"
                                />
                                <div class="common-card-estate__info-item-row-value"
                                    v-text="dividendProfitabilityFormatted"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </component>
        </template>
    </article>
</template>

<script>
import config from '~/config';
import utils from '~/utils';

export default {
    name: 'common-card-estate',
    props: {
        item: {
            type: Object,
            default: null,
        },
        buttonText: {
            type: String,
            default: 'Подробнее',
        },
        resizes: {
            type: [ Number, String, Object ],
            default: null,
        },
    },
    computed: {
        user() {
            return this.$store.getters['users/user'];
        },
        currency() {
            return this.$store.state.site.currency.symbol;
        },
        status() {
            if (this.item.state === 'sold') {
                return {
                    text: 'Объект продан',
                    icon: 'check-document',
                    theme: 'inversed',
                };
            } else if (this.item.state === 'round-completed') {
                return {
                    text: 'Раунд завершен',
                    icon: 'check-thin',
                    theme: 'primary',
                };
            } else if (
                this.item.state === 'completed' ||
                this.item.state === 'collected'
            ) {
                return {
                    text: 'Объект приобретен SimpleEstate',
                    icon: 'check-thin',
                    theme: 'primary',
                };
            } else {
                return {
                    text: 'Идет сбор средств',
                    icon: 'clock',
                    theme: 'gradient',
                };
            }
        },
        isVisuallyCompleted() {
            return this.item.state !== 'collecting';
        },
        coverImage() {
            const resize = this.resizes ? utils.common.getResize(this.resizes) : utils.common.getResize();
            return utils.common.injectResize(this.item.image, resize);
        },
        profitabilityFormatted() {
            if (this.item.state === 'completed' || this.item.state === 'collected' || this.item.state === 'sold') {
                return this.formatNumber(this.item.currentProfitability) + '% / ' + this.formatNumber(this.item.targetProfitability) + '%';
            }
            return '– / ' + this.formatNumber(this.item.targetProfitability) + '%';
        },
        dividendProfitabilityFormatted() {
            if (this.item.state === 'completed' || this.item.state === 'collected' || this.item.state === 'sold') {
                return this.formatNumber(this.item.currentDividendProfitability) + '% / ' + this.formatNumber(this.item.targetDividendProfitability) + '%';
            }
            return '– / ' + this.formatNumber(this.item.targetDividendProfitability) + '%';
        },
    },
    methods: {
        formatNumber(rawValue) {
            return utils.formats.formatNumberWithSpaces(rawValue);
        },
        showRegisterModal() {
            window.location.href = config.urls.auth.login + '?next=' + window.location.pathname;
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.common-card-estate {
    position: relative;
    z-index: 1;

    height: 100%;
    &:hover {
        z-index: 2;
    }
    &:hover &__wrapper {
        box-shadow: @shadow-primary-hover;
    }
    &__wrapper {
        min-height: 100%;
        display: flex;
        flex-direction: column;
        border-radius: @border-radius-lg;
        overflow: hidden;

        text-decoration: none;

        background-color: @color-gray-lightest;

        cursor: pointer;
        box-shadow: @shadow-primary;

        transition: box-shadow @duration-fast @easing-default;
    }
    &__cover {
        position: relative;
        z-index: 2;

        flex: 0 0 auto;
        padding-top: 56.29%;
        margin-bottom: -4px;
        ._sold & {
            filter: grayscale(100);
        }
    }
    &__progress {
        margin-bottom: 16px;
    }
    &__container {
        position: relative;
        z-index: 1;

        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        width: 100%;
        padding: @grid-gap;
    }
    &__status {
        margin-bottom: 12px;
    }
    &__title {
        .typography-medium();

        flex: 0 0 auto;
        margin-bottom: 12px;
    }
    &__lines {
        flex: 0 0 auto;
        margin-bottom: 12px;
        &-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            & ~ & {
                margin-top: 8px;
            }
            &-icon {
                flex: 0 0 auto;
                padding: 4px;
                border-radius: 50%;
                margin-right: 8px;

                font-size: 1rem;

                background-color: @color-gray-light;
            }
            &-text {
                .typography-body();

                flex: 1 1 auto;
            }
        }
    }
    &__target {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        width: 100%;
        margin-bottom: 8px;
        &-title {
            .typography-heading();
        }
        &-value {
            .typography-small();
        }
    }
    &__info {
        margin-bottom: 8px;
        &-item {
            .typography-body();

            display: flex;
            justify-content: space-between;
            align-items: baseline;
            flex-wrap: wrap;
            width: 100%;
            & ~ & {
                margin-top: 8px;
            }
            &-title {
                .typography-heading();

                padding-right: @grid-gap-half;
            }
            &-value {
                padding-left: @grid-gap-half;

                text-align: right;
            }
            &-row {
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                width: 100%;
                margin-top: 4px;
            }
        }
    }
}
</style>
