<template>
    <section class="page-cabinet-dashboard">
        <section class="page-cabinet-dashboard__block _welcome"
            v-if="user !== null && (user.status === 'new' || user.status === 'declined' || user.status === 'reset')"
            >
            <page-cabinet-dashboard-welcome v-if="user.status === 'new'" />
            <page-cabinet-dashboard-reverificate v-else-if="user.status === 'declined' || user.status === 'reset'"/>
        </section>
        <section class="page-cabinet-dashboard__block"
            v-if="getAnnouncementsResult && getAnnouncementsResult.count > 0"
            >
            <page-cabinet-dashboard-announcements />
        </section>
        <section class="page-cabinet-dashboard__block _qualification"
            v-if="isQualificationLinkVisible"
            >
            <common-qualification-link />
        </section>
        <section class="page-cabinet-dashboard__block">
            <page-cabinet-dashboard-balance />
        </section>
        <section class="page-cabinet-dashboard__block">
            <page-cabinet-dashboard-highlights />
        </section>
        <section class="page-cabinet-dashboard__block _half">
            <page-cabinet-dashboard-dividends />
        </section>
        <section class="page-cabinet-dashboard__block _half">
            <page-cabinet-dashboard-estimation />
        </section>
        <section class="page-cabinet-dashboard__block _half">
            <page-cabinet-dashboard-income />
        </section>
        <section class="page-cabinet-dashboard__block _half">
            <page-cabinet-dashboard-transactions />
        </section>
    </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'page-cabinet-dashboard',
    computed: {
        ...mapState('users', [
            'getAnnouncementsResult',
            'getAnnouncementsIsLoading',
            'getAnnouncementsError',
        ]),
        user() {
            return this.$store.getters['users/user'];
        },
        isQualificationLinkVisible() {
            return this.user !== null &&
            this.user.status !== 'declined' &&
            this.user.status !== 'reset' &&
            this.user.status !== 'new' &&
            !this.user.isIndividualEntrepreneur &&
            !this.user.isQualifiedInvestor && this.user.qiRequestedDate === null;
        },
    },
    mounted() {
        this.$store.dispatch('dashboard/getHighlights');
        this.$store.dispatch('users/getAnnouncements');
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-dashboard {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: stretch;
    margin: 0 -12px;
    &__block {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        width: 100%;
        padding: 0 12px;
        &._half {
            width: 50%;
        }
        &._welcome ~ & {
            opacity: 0.5;
            filter: grayscale(100);
            pointer-events: none;
        }
        &._qualification {
            margin-top: 20px;
        }
    }
    @media @media-md-down {
        &__block {
            &._half {
                width: 100%;
            }
        }
    }
}
</style>
