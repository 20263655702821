<template>
    <div class="page-cabinet-requests-item-content">
        <template v-if="investment.status === 'wait_sign'">
            <div class="page-cabinet-requests-item-content__title"
                v-text="'Подтвердить заявку и подписать документы'"
            />
            <div class="page-cabinet-requests-item-content__description"
                v-text="'Подтверждая данную инвестиционную заявку вы принимаете условия инвестиционного предложения и договора инвестирования.'"
            />
            <div class="page-cabinet-requests-item-content__documents"
                v-if="documents.length > 0"
                >
                <div class="page-cabinet-requests-item-content__documents-item"
                    v-for="(document, i) in documents"
                    v-bind:key="i"
                    >
                    <page-cabinet-requests-item-content-document
                        v-bind:document="document"
                    />
                </div>
            </div>
            <template v-if="user.phone">
                <div class="page-cabinet-requests-item-content__verification"
                    v-if="isCodeCheckInitiated"
                    >
                    <common-sms-verification
                        v-bind:phone="user.phone"
                        v-on:submit="onSubmit"
                        is-request-required
                    />
                </div>
                <div class="page-cabinet-requests-item-content__verification"
                    v-else
                    >
                    <div class="page-cabinet-requests-item-content__verification-title"
                        v-text="'Код будет выслан на номер:'"
                    />
                    <div class="page-cabinet-requests-item-content__verification-phone"
                        v-text="user.phone"
                    />
                    <div class="page-cabinet-requests-item-content__verification-button">
                        <ui-button v-on:click="requestCode">
                            Отправить СМС-код
                        </ui-button>
                    </div>
                </div>
            </template>
            <div class="page-cabinet-requests-item-content__verification"
                v-else
                >
                <div class="page-cabinet-requests-item-content__verification-title"
                    v-text="'Для подтверждения заявки необходим номер телефона.'"
                />
                <div class="page-cabinet-requests-item-content__verification-title"
                    v-text="'Вы можете добавить его в профиле.'"
                />
                <div class="page-cabinet-requests-item-content__verification-phone"
                    v-text="'Телефон не указан'"
                />
                <div class="page-cabinet-requests-item-content__verification-button">
                    <ui-button
                        v-bind:href="profileUrl"
                        target="_blank"
                        >
                        Редактировать профиль
                    </ui-button>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="page-cabinet-requests-item-content__title"
                v-text="'Подпись не требуется'"
            />
            <div class="page-cabinet-requests-item-content__description"
                v-text="'На данный момент статус заявки не требует ее подтверждения и подписи'"
            />
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import config from '~/config';

export default {
    name: 'page-cabinet-requests-item-content',
    props: {
        investment: {
            type: Object,
        },
        user: {
            type: Object,
        },
        documents: {
            type: Array,
        },
    },
    data: () => ({
        profileUrl: config.urls.cabinet.personalData,
        isCodeCheckInitiated: false,
    }),
    computed: {
        ...mapState('investments', [ 'verifyUserInvestmentRequestResult' ]),
    },
    methods: {
        requestCode() {
            this.isCodeCheckInitiated = true;
        },
        onSubmit(code) {
            this.$store.dispatch('investments/verifyUserInvestmentRequest', { code, value: this.user.phone, id: this.investment.id });
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-requests-item-content {
    padding: 8px;
    &__title {
        .typography-large();

        margin-bottom: 16px;
    }
    &__description {
        .typography-body();

        max-width: 760px;
        margin-bottom: 16px;
    }
    &__documents {
        margin-bottom: 40px;
        &-item {
            &:not(:last-child) {
                margin-bottom: 12px;
            }
        }
    }
    &__verification {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        max-width: 720px;
        margin: 0 auto;

        text-align: center;
        &-title {
            .typography-body();

            margin-bottom: 8px;
        }
        &-phone {
            .typography-heading();

            margin-bottom: 24px;
        }
    }
    @media @media-sm-down {
        padding: 0;
        &__title {
            .typography-medium();

            margin-bottom: 8px;
        }
    }
}
</style>
