<template>
    <article class="plugin-files-with-preview">
        <div class="plugin-files-with-preview__item"
            v-for="(item, index) in items"
            v-bind:key="index"
            >
            <div class="plugin-files-with-preview__item-title"
                v-text="item.title"
            />
            <div class="plugin-files-with-preview__item-preview"
                v-bind:style="getBackgroundStyle(item.preview)"
                >
                <div class="plugin-files-with-preview__item-preview-button"
                    v-on:click="openPreview(item)"
                    >
                    <div class="plugin-files-with-preview__item-preview-button-icon">
                        <icon name="magnifier-plus" />
                    </div>
                </div>
                <a class="plugin-files-with-preview__item-preview-button"
                    v-bind:href="item.file"
                    target="_blank"
                    >
                    <div class="plugin-files-with-preview__item-preview-button-icon">
                        <icon name="download" />
                    </div>
                </a>
            </div>
        </div>
    </article>
</template>

<script>
import utils from '~/utils';

export default {
    name: 'plugin-files-with-preview',
    props: {
        items: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        openPreview(item) {
            this.$store.commit('modals/push', { name: 'preview', props: { previewUrl: item.preview, fileUrl: item.file, title: item.title || null } });
        },
        getBackgroundStyle(image) {
            if (!image) {
                return null;
            }
            const resize = utils.common.getResize({
                1920: '160',
                1023: 50,
            });
            return { backgroundImage: `url(${utils.common.injectResize(image, resize)})` };
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-files-with-preview {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    margin: 0 -23px;
    &__item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        width: 160px;
        margin: 0 23px;

        &-title {
            .typography-caption();

            padding-bottom: 10px;

            font-weight: bold;
            text-transform: uppercase;
        }
        &-preview {
            display: flex;
            justify-content: flex-start;
            align-items: stretch;
            width: 100%;
            height: 62px;
            border-radius: @border-radius-lg;
            overflow: hidden;

            color: @color-gray-lightest;
            font-size: 3rem;

            background-color: @color-gray-main;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;

            cursor: pointer;
            &-button {
                flex: 1 1 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;

                background-color: fade(@color-gray-darker, 50%);

                opacity: 0;

                transition: opacity @duration-fast ease-in-out, background-color @duration-fast ease-in-out;
                &:hover {
                    background-color: fade(@color-gray-darker, 90%);
                }
                &-icon {
                    transition: transform @duration-fast ease-in-out;
                }
                &:first-child &-icon {
                    transform: translateX(-50px);
                }
                &:last-child &-icon {
                    transform: translateX(50px);
                }
            }
            &:hover &-button {
                opacity: 1;
                &-icon {
                    transform: translate(0);
                }
            }
        }
    }

    @media @media-md-down {
        margin: -15px -4px 0;
        &__item {
            width: ~'calc(50% - 8px)';
            margin: 0 4px;
            &-title {
                padding-bottom: 5px;
                margin-top: 15px;
            }
            &-preview {
                &-button {
                    &:last-child {
                        display: none;
                    }
                    &:first-child &-icon {
                        transform: scale(2);
                    }
                }
                &:hover &-button {
                    &-icon {
                        transform: scale(1);
                    }
                }
            }
        }
    }
}
</style>
