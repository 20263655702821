export const shareQuantity = {
    name: 'shareQuantity',
    label: 'Общее кол-во акций',
    value: '',
    initialValue: '',
    placeholder: 'Введите количество акций',
    type: 'numeric',
    firstZeroInavailable: true,
    warning: `Акции будут распределены по портфелям инвесторов, в соответствии с их долями займа`,
    errors: {
        required: 'Введите количество',
        isValid: 'Введите только цифры',
    },
};

export const sharePriceNominal = {
    name: 'sharePriceNominal',
    label: 'Номинальная стоимость акции',
    value: '',
    initialValue: '',
    placeholder: '10 000',
    type: 'currency-float',
    units: '₽',
    errors: {
        required: 'Введите стоимость',
        isValid: 'Введите только цифры',
    },
};

export const sharePriceCalculation = {
    name: 'sharePriceCalculation',
    label: 'Рассчетная стоимость акции',
    value: '',
    initialValue: '',
    placeholder: '10 000',
    type: 'currency-float',
    units: '₽',
    errors: {
        required: 'Введите стоимость',
        isValid: 'Введите только цифры',
    },
};

export const sharePriceMarket = {
    name: 'sharePriceMarket',
    label: 'Рыночная стоимость акции',
    value: '',
    initialValue: '',
    placeholder: '10 000',
    type: 'currency-float',
    units: '₽',
    errors: {
        required: 'Введите стоимость',
        isValid: 'Введите только цифры',
    },
};

export const shareRegistrationNumber = {
    name: 'shareRegistrationNumber',
    label: 'Государственный регистрационный номер выпуска акций',
    value: '',
    initialValue: '',
    placeholder: '1-01-80177-N',
    type: 'text',
    errors: {
        required: 'Введите стоимость',
        isValid: 'Введите только цифры',
    },
};

export const shareInvestmentAgreement = {
    name: 'shareInvestmentAgreement',
    label: '№ договора инвестирования',
    value: '',
    initialValue: '',
    placeholder: 'АО6-4',
    maxLength: 50,
    type: 'text',
    errors: {
        required: 'Введите номер договора инвестирования',
    },
};

export const shareRegistrationDate = {
    name: 'shareRegistrationDate',
    label: 'Дата государственной регистрации выпуска ценных бумаг',
    value: '',
    initialValue: '',
    type: 'date',
    errors: {
        required: 'Введите дату',
        isValid: 'Введите корректную дату',
    },
};
