<template>
    <div class="page-estates-list-subscription">
        <div class="page-estates-list-subscription__title"
            v-text="`Подпишитесь на наши каналы на Youtube и в Telegram или на Email рассылку, чтобы ничего не пропустить`"
        />
        <div class="page-estates-list-subscription__channels">
            <a class="page-estates-list-subscription__channels-item"
                v-bind:href="telegramUrl"
                target="_blank"
                >
                <div class="page-estates-list-subscription__channels-item-icon">
                    <icon name="telegram" />
                </div>
                <div class="page-estates-list-subscription__channels-item-title"
                    v-text="'Telegram'"
                />
            </a>
            <a class="page-estates-list-subscription__channels-item"
                v-bind:href="youtubeUrl"
                target="_blank"
                >
                <div class="page-estates-list-subscription__channels-item-icon">
                    <icon name="youtube" />
                </div>
                <div class="page-estates-list-subscription__channels-item-title"
                    v-text="'Youtube'"
                />
            </a>
        </div>
        <form class="page-estates-list-subscription__form"
            v-if="!subscribeResult"
            v-on:submit.prevent="submit"
            v-bind:class="{ _loading: subscribeIsLoading }"
            >
            <input class="page-estates-list-subscription__form-input"
                placeholder="Введите ваш Email"
                v-model="email"
                type="email"
            />
            <button class="page-estates-list-subscription__form-button"
                type="submit"
                >
                <div class="page-estates-list-subscription__form-button-icon">
                    <icon name="arrow-right" />
                </div>
            </button>
            <div class="page-estates-list-subscription__form-loader">
                <ui-loader />
            </div>
        </form>
        <div class="page-estates-list-subscription__error"
            v-if="!subscribeIsLoading && error"
            >
            <div class="page-estates-list-subscription__error-icon">
                <icon name="warning" />
            </div>
            <div class="page-estates-list-subscription__error-text">
                {{ error }}
            </div>
        </div>
        <div class="page-estates-list-subscription__success"
            v-if="!subscribeIsLoading && subscribeResult"
            >
            <div class="page-estates-list-subscription__success-icon">
                <icon name="check" />
            </div>
            <div class="page-estates-list-subscription__success-text">
                Вы подписаны на рассылку
            </div>
        </div>
        <div class="page-estates-list-subscription__disclaimer"
            v-else
            >
            Отправляя данные, я{{'\xa0'}}даю согласие на{{'\xa0'}}получение новостной и{{'\xa0'}}рекламной рассылки.
        </div>
        <button class="page-estates-list-subscription__button"
            v-if="!subscribeResult"
            v-on:click.prevent="submit"
            type="submit"
            >
            <icon name="arrow-right" />
        </button>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import forms from '~/forms';
import utils from '~/utils';

export default {
    name: 'page-estates-list-subscription',
    data: () => ({
        email: '',
    }),
    validations() {
        return { email: forms.validations.email.value };
    },
    computed: {
        ...mapState('subscription', [ 'subscribeIsLoading', 'subscribeError', 'subscribeResult' ]),
        error() {
            if (this.subscribeError) {
                return 'Ошибка: ' + utils.network.parseError(this.subscribeError, true);
            }
            if (this.$v.$invalid && this.$v.$dirty) {
                return 'Введите корректный email';
            }
            return null;
        },
        telegramUrl() {
            return this.$store.state.site.contacts.socials.telegram.href;
        },
        youtubeUrl() {
            return this.$store.state.site.contacts.socials.youtube.href;
        },
    },
    methods: {
        submit() {
            if (this.subscribeIsLoading) {
                return;
            }
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            this.$store.dispatch('subscription/subscribe', {
                email: this.email,
            });
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-estates-list-subscription {
    .typography-body();

    height: 100%;
    min-height: 525px;
    padding: 50px 40px;
    border-radius: @border-radius-lg;
    overflow: hidden;

    color: @color-gray-lightest;

    background-color: @color-gray-main;
    background-image: url('/static/images/page-estates-list-subscription.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    &__title {
        .typography-large();

        margin-bottom: 24px;
    }
    &__channels {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 100%;
        gap: 8px;
        margin-bottom: 20px;

        &-item {
            flex: 0 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 110px;
            height: 34px;
            border: 1px solid @color-gray-lightest;
            border-radius: 17px;

            text-decoration: none;
            &-icon {
                margin-right: 12px;

                color: @color-gray-lightest;
                font-size: 1.2rem;
            }
            &-title {
                .typography-body();
            }
        }
    }
    &__form {
        position: relative;

        display: flex;
        align-items: center;
        width: 100%;
        height: 48px;
        border-radius: 48px;
        margin-bottom: 12px;
        overflow: hidden;

        background-color: transparent;

        box-shadow: inset 0 0 0 1px @color-gray-light;
        &._loading {
            pointer-events: none;
        }
        &-input {
            .typography-body();

            flex: 1 1 auto;
            padding: 13px 16px 13px 24px;

            color: @color-gray-lightest;

            transition: opacity @duration-fast @easing-default;
            &::placeholder {
                color: @color-gray-light;
            }
            ._loading > & {
                opacity: 0;
            }
        }
        &-button {
            flex: 0 0 auto;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 24px;

            color: @color-gray-lightest;

            transition: opacity @duration-fast @easing-default;
            &-icon {
                font-size: 2.4rem;
            }
            ._loading > & {
                opacity: 0;
            }
        }
        &-loader {
            position: absolute;
            top: 50%;
            left: 50%;

            width: 32px;
            height: 32px;

            transform: translate(-50%, -50%);

            pointer-events: none;
            opacity: 0;

            transition: opacity @duration-fast @easing-default;
            ._loading > & {
                opacity: 1;
            }
        }
    }
    &__error {
        .typography-small();

        display: flex;
        align-items: center;
        justify-content: flex-start;
        &-icon {
            padding: 6px 0 0 8px;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            margin-right: 12px;

            color: @color-gray-lightest;
            font-size: 1.6rem;

            background-color: @color-accent-negative;
        }
    }
    &__success {
        .typography-small();

        display: flex;
        align-items: center;
        justify-content: flex-start;
        &-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            margin-right: 12px;

            color: @color-gray-lightest;
            font-size: 1.6rem;

            background-color: @color-primary;
        }
    }
    &__disclaimer {
        .typography-body();

        margin-bottom: 16px;
    }
    &__button {
        display: none;
    }
    @media @media-lg-down {
        padding: 24px;
    }
    @media @media-md-down {

    }
    @media @media-sm-down {
        min-height: 400px;
        &__title {
            .typography-medium();

            margin-bottom: 24px;
        }
        &__form {
            margin-bottom: 16px;
            &-button {
                display: none;
            }
        }
        &__error {
            margin-bottom: 16px;
        }
        &__success {
            margin-bottom: 16px;
        }
        &__button {
            display: block;
            width: 48px;
            height: 48px;
            padding: 12px;
            border-radius: 50%;
            margin: 0 auto;

            color: @color-gray-darkest;
            font-size: 2.4rem;

            background-color: @color-gray-lightest;
        }
    }
}
</style>
