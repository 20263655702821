<template>
    <common-file-loader
        v-bind:is-loading="isLoading"
        v-bind:progress="loadingProgress"
        v-bind:file="file"
        v-bind:text="isFirst ? 'Загрузить файл' : 'Загрузить еще'"
        v-on:change="updateFile"
    />
</template>

<script>
import { mainClient } from '~/network';

export default {
    name: 'page-cabinet-qualification-files-item',
    props: {
        file: {
            type: [ File, String ],
        },
        isFirst: {
            type: Boolean,
            default: false,
        },
        variantId: {
            type: Number,
        },
    },
    model: {
        prop: 'file',
        event: 'change',
    },
    data: () => ({
        isLoading: false,
        loadingProgress: 0,
        uploadId: null,
    }),
    computed: {
        documents() {
            return this.$store.getters['qualification/files'][this.variantId] || [];
        },
    },
    methods: {
        updateFile(file) {
            // if (file !== null) {
            //     const formData = new FormData();
            //     formData.append('file', file);
            //     this.$store.dispatch('users/updatePassportPhoto', { userId: this.user.id, page, formData });
            // }
            this.loadingProgress = 0;
            if (this.uploadId !== null) {
                this.deleteFile();
            }
            if (file === null) {
                this.$emit('change', null);
                return;
            }
            this.uploadFile(file);
            // const newFiles = this.files.map((x, xi) => xi === i ? file : x);
            // const isMoreAvailable = this.files.length < this.maxAmount && this.files.every((x, xi) => x !== null || xi === i);
        },
        async uploadFile(file) {
            if (this.isLoading) {
                return;
            }
            this.isLoading = true;
            this.loadingProgress = 0;
            this.$emit('change', file);

            const formData = new FormData();
            formData.append('file', file);
            const progressHandler = (e) => {
                this.loadingProgress = Math.round((e.loaded * 100) / e.total);
            };
            const { data, error, status } = await mainClient.qualification.requests.uploadFile(formData, progressHandler);
            if (error) {
                console.error(error, status);
                console.error(`Error in component page-cabinet-qualification-files uploadFile method`);
                this.reset();
            } else {
                this.uploadId = data.result[0].id;
                this.$store.commit('qualification/toggleFile', { variantId: this.variantId, documentId: data.result[0].id });
            }
            this.isLoading = false;
        },
        async deleteFile() {
            const params = { id: this.uploadId };
            this.uploadId = null;
            await mainClient.qualification.requests.deleteFile(params);
            this.$store.commit('qualification/toggleFile', { variantId: this.variantId, documentId: params.id });
        },
    },
};
</script>
