<template>
    <ui-card-base
        v-bind:padding-top="'7px'"
        v-bind:padding-bottom="'7px'"
        >
        <article class="page-cabinet-transactions-list-card">
            <div class="page-cabinet-transactions-list-card__title"
                v-text="title"
            />
            <div class="page-cabinet-transactions-list-card__date"
                v-text="dateFormatted"
            />
            <div class="page-cabinet-transactions-list-card__type"
                >
                <span class="page-cabinet-transactions-list-card__type-caption"
                    v-text="'Тип:'"
                /> <span class="page-cabinet-transactions-list-card__type-value"
                    v-text="type"
                />
            </div>
            <div class="page-cabinet-transactions-list-card__sum"
                v-if="sum"
                >
                <span class="page-cabinet-transactions-list-card__sum-caption"
                    v-text="'Сумма:'"
                /> <span class="page-cabinet-transactions-list-card__sum-value"
                    v-text="sum"
                />
            </div>
        </article>
    </ui-card-base>
</template>

<script>
import moment from 'moment';

export default {
    name: 'page-cabinet-transactions-list-card',
    props: {
        title: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: '',
        },
        date: {
            type: [Date, String],
            default: () => new Date(),
        },
        sum: {
            type: String,
            default: '0',
        },
    },
    computed: {
        dateFormatted() {
            return moment(this.date).format('DD.MM.YYYY');
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-transactions-list-card {
    .typography-body();

    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
    width: 100%;
    &__title {
        width: ~'calc(100% - 82px)';
    }
    &__date {
        width: 82px;
        padding-left: 12px;

        color: @color-gray-main;
        text-align: right;
    }
    &__type {
        width: 100%;
        margin-top: 22px;
        &-caption {
            color: @color-gray-main;
        }
    }
    &__sum {
        width: 100%;
        &-caption {
            color: @color-gray-main;
        }
    }
}
</style>
