export const api = {
    estatesAdmin: {
        login: '/api/dashboard/login/',
        estate: '/api/dashboard/estate/',
        shares: '/api/dashboard/estate-user-shares/{id}/',
        renderLoanOffer: '/api/dashboard/estate/{id}/loan/render/', // to remove
        lastLoan: '/api/dashboard/estate/{id}/loan/',
        legal: '/api/dashboard/user/', // + id
        requests: '/api/dashboard/estate/{id}/investment/',
        rounds: '/api/dashboard/estate/{id}/rounds/',
        cancelRequest: '/api/dashboard/estate/{estateId}/investment/{requestId}/reverse/',
        editRequest: '/api/dashboard/estate/{estateId}/investment/{requestId}/',
        investments: {
            generateInvestmentOfferDocuments: '/api/dashboard/estate/{estateId}/generate_documents/',
            issueInvestmentOffer: '/api/dashboard/estate/{estateId}/make_offer/',
            cancelExpiredInvestmentRequests: '/api/dashboard/estate/{estateId}/cancel_expired_investment_requests/',
            calculateShares: '/api/dashboard/estate/{estateId}/calculate_shares/',
            transferMoney: '/api/dashboard/estate/{estateId}/transfer_money/',
            convertEstateToShares: '/api/dashboard/estate/{estateId}/issue_shares/',
        },
        loan: { // to remove
            create: '/api/dashboard/loan/',
            list: '/api/dashboard/loan/',
            cancel: '/api/dashboard/loan/{id}/cancel/',
            resolve: '/api/dashboard/loan/{id}/loan-issue/',
            investmentRequests: '/api/dashboard/loan/{id}/investment/',
            share: {
                calculate: '/api/dashboard/loan/{id}/calculate-share/',
                issue: '/api/dashboard/loan/{id}/issue-share/',
            },
        },
        transferOrdersArchive: '/api/dashboard/estate/{id}/transfer-order/',
        investmentAgreementsArchive: '/api/dashboard/estate/{id}/investment-agreement/',
        investmentProposalsArchive: '/api/dashboard/estate/{id}/investment-proposal/',
        investmentAcceptArchive: '/api/dashboard/estate/{id}/investment-accept/',
        uploadDocument: {
            dsur: '/api/dashboard/estate/{id}/dsur-document/',
            template: '/api/dashboard/estate/{estateId}/template/{id}/',
        },
    },
    blog: {
        items: '/api/blog/article/',
    },
    cabinet: {
        notice: '/api/cabinet/notice/',
    },
    dadata: {
        inn: '/api/inn-suggest/',
    },
    documents: {
        items: '/api/document/document/',
    },
    estates: {
        items: '/api/estate/',
        types: '/api/estate/type/',
        chartData: {
            dividends: '/api/estate/{id}/dividends/',
            // dividends: '/api/estate/{id}/shares/',
            shares: '/api/estate/{id}/shares/',
        },
    },
    feedback: {
        send: '/api/user/feedback/',
    },
    geo: {
        countries: '/api/geo/country/',
    },
    subscription: {
        subscribe: '/api/user/subscribe/',
    },
    subscriptions: {
        get: '/api/subscriptions/new-estate/',
        subscribe: '/api/subscriptions/new-estate/{id}/subscribe',
    },
    investments: {
        userInvestments: '/api/investment/investment/',
        verifyRequest: '/api/investment/request/{id}/verify/',
        userShares: '/api/investment/user-shares/',
        invest: '/api/investment/request/',
    },
    news: {
        items: '/api/news/news/',
    },
    market: {
        shares: {
            list: '/api/trade/shares/', // GET
            all: '/api/trade/all-shares/', // GET
        },
        orders: {
            list: '/api/trade/order/', // GET
            create: '/api/trade/order/', // POST
            accept: '/api/trade/order/{id}/accept/', // POST
            cancel: '/api/trade/order/{id}/cancel/', // POST
            sign: '/api/trade/order/{id}/sign/', // POST
        },
        deals: {
            accept: '/api/trade/deal/{id}/accept/', // GET
            cancel: '/api/trade/deal/{id}/cancel/', // GET
            list: '/api/trade/deal/', // GET
            info: '/api/trade/deal/{id}/', // GET
        },
        subscriptions: '/api/subscriptions/subscribe/',
    },
    qualification: {
        apply: '/api/user/qualification/request/',
        files: '/api/user/qualification/document/',
        variants: '/api/user/qualification/variant/',
    },
    questionary: {
        questions: '/api/questionary/question/',
        answers: '/api/questionary/answers/',
    },
    referral: {
        checkCode: '/api/referral-program/check-code/',
        createReferrer: '/api/referral-program/create-referrer/',
        getReferrals: '/api/referral-program/user-referrals/',
        agreement: '/api/referral-program/referrer-agreement-preview.docx',
    },
    sms: {
        getCode: '/api/user/verification/sms/',
        checkCode: '/api/user/verification/sms/check/',
        getAuthCode: '/api/user/verification/password/',
    },
    status: {
        getFormData: '/api/status/form/{id}/',
        createFormData: '/api/status/form/',
        updateFormData: '/api/status/form/{id}/',
        uploadFile: '/api/status/file/',
    },
    transfers: {
        items: '/api/user/activity/',
    },
    user: {
        analytics: '/api/user/analytics/',
        announcements: '/api/user/announcement/',
        login: '/api/user/login/',
        logout: '/api/user/logout/',
        update: '/api/user/update/',
        password: '/api/user/password/',
        register: '/api/user/register/',
        reset: '/api/user/reset/', // password reset
        resetComplete: '/api/user/reset_complete/', // set new password
        profile: '/api/user/profile/',
        personalInfo: '/api/user/personal-info/',
        juridicalInfo: '/api/user/juridical-info/',
        migrationInfo: '/api/user/migration-info/',
        additionalInfo: '/api/user/additional-info/',
        photo: '/api/user/photo/',
        dashboard: {
            highlights: '/api/user/dashboard/',
            dividends: '/api/user/dashboard/dividends-chart/',
            portfolio: '/api/user/dashboard/portfolio-chart/',
            income: '/api/user/dashboard/income-stat/',
        },
        verification: {
            reset: '/api/user/reset-verification/',
            approve: '/api/user/verification/approve/',
        },
        withdraw: {
            getInfo: '/api/account/balance/leave/', // GET
            requestWithdraw: '/api/account/balance/leave/', // POST
        },
        twoFactor: '/api/user/two-factor/',
    },
};

export const bodyAttributes = {
    loaded: 'data-app-loaded',
};

export const common = {
    annualInvestmentLimit: 600000,
    passportNumberLength: 6,
    passportSeriesLength: 4,
    snilsLength: 11,
    kppLength: 9,
    ogrnipLength: 15,
    ogrnLength: 13,
    bikLength: 9,
    minPasswordLength: 8,
    referralQuery: 'referral',
    transactionsTypesToShow: [
        'payment',
        'balance_reserve',
        'balance_unlock_reserve',
        'balance_update',
        'investment',
        'dividend',
        'investor_leaves',
        'loan_issue',
        'user_share_sale_purchase',
    ],
    investmentTypes: [
        { code: 'direct', title: 'Прямой выпуск акций', id: 2 },
        { code: 'loan', title: 'Через выпуск займа', id: 1 },
    ],
};

export const formats = {
    numbers: {
        default: {
            thousandSeparated: true,
            mantissa: 2,
            trimMantissa: true,
        },
    },
    dates: {
        api: 'YYYY-MM-DD',
        frontend: 'DD.MM.YYYY',
    },
};

export const localStorage = {
    keyPrefix: 'simpleEstate',
    keyAffixies: {
        personalDataWarning: 'PersonalDataWarningDate',
        holidaysWarning: 'HolidaysWarningDate',
        referralCode: 'ReferralCode',
        sms: {
            lastRequestDate: 'SmsAuthLastRequestDate',
        },
    },
};

export const translations = [
    {
        code: 'ru',
        title: 'Рус',
    },
];

export const urls = {
    prodRoot: 'https://simpleestate.ru',
    home: '/',
    auth: {
        login: '/user/login/',
        registration: '/user/register/',
        reset: '/user/reset/',
    },
    cabinet: {
        dashboard: '/cabinet/',
        deals: '/cabinet/deals/',
        documents: '/cabinet/documents/',
        estates: '/cabinet/estates/',
        personalData: '/cabinet/personal-data/',
        qualification: '/cabinet/qualification/',
        qualificationSuccess: '/cabinet/qualification/success/',
        qualificationFailure: '/cabinet/qualification/failure/',
        referral: '/cabinet/referral/',
        refill: '/cabinet/refill/',
        requests: '/cabinet/invest-requests/',
        signRequest: '/cabinet/sign-request/',
        settings: '/cabinet/settings/',
        transactions: '/cabinet/transactions/',
        verification: '/cabinet/verification/',
        verificationSuccess: '/cabinet/verification/success/',
    },
    adminDashboard: {
        index: '/dashboard/estates/',
        estates: '/dashboard/estates/',
    },
    estates: {
        item: '/estate/', // + item id
        list: '/estate/',
    },
    blog: {
        item: '/blog/article/', // +item.id
        list: '/blog/article/',
    },
    news: {
        item: '/news/news/', // +item.id
        list: '/news/news/',
    },
    policies: {
        personalData: '/api/document/files/personal-data-processing-policy/',
        risks: '/api/document/files/investor-risk-statement/',
        rules: '/api/document/files/platform-rules/',
        service: '/api/document/files/investment-service-agreement/',
        userAgreement: '/api/document/files/user-agreement/',
        oferta: '/api/document/files/oferta-1631106133-226523/Агентскии_договор_на_саите_SimpleEstate_публичная_оферта_1_1631106133.pdf',
    },
    market: {
        index: '/secondary-market/',
    },
    footer: {
        bank: 'http://www.cbr.ru/rbr/rbr_fr/doc/?id=28532',
        register: 'https://cbr.ru/finorg/foinfo/?ogrn=1197746322289',
    },
    referralLanding: '/referral-system/',
    support: 'mailto:info@simpleestate.ru',
};

export const vue = {
    delimiters: ['[[', ']]'],
    rootSelector: '[data-app]',
};

const config = {
    api,
    bodyAttributes,
    common,
    formats,
    localStorage,
    translations,
    urls,
    vue,
};

export default config;
