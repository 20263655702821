export const variant = (data) => {
    const result = {
        id: data.id,
        text: data.text,
        isAttachmentRequired: data.is_required_attachment,
        isPositive: data.is_confirm_qualification,
        description: data.description,
    };
    return result;
};

export const getVariants = (data) => {
    const result = {
        items: data.results.map(variant),
        paging: {
            count: data.count || 0,
            page: data.page || 1,
        },
    };
    return result;
};
