import { mainClient } from '~/network';

const state = {
    feedbackIsLoading: false,
    feedbackError: null,
    feedbackResult: null,
};

const getters = {
};

const actions = {
    async feedback({ state }, payload = {}) {
        if (state.feedbackIsLoading) {
            return;
        }
        state.feedbackIsLoading = true;
        const { data, error } = await mainClient.feedback.requests.sendFeedback(payload);
        if (error) {
            state.feedbackError = error;
            state.feedbackResult = null;
        } else if (data) {
            state.feedbackResult = data;
            state.feedbackError = null;
        }
        state.feedbackIsLoading = false;
    },
};

const mutations = {
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
