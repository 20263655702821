<template>
    <ui-input-base-text
        v-bind:formatter="formatDown"
        v-bind:is-invalid="isInvalid"
        v-bind="formattedModel"
        v-on:input="inputHandler"
        v-on:focus="focusHandler"
        v-on:blur="blurHandler"
    />
</template>

<script>
import utils from '~/utils';

export default {
    name: 'ui-input-username',
    props: {
        model: {
            type: Object,
        },
        isInvalid: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [ String, Number ],
        },
    },
    model: {
        prop: 'value',
        event: 'input',
    },
    computed: {
        formattedModel() {
            return { ...this.model, value: this.formatDown(this.model.value) };
        },
    },
    methods: {
        formatDown(value, position = null) {
            if (!value) {
                if (position !== null) {
                    return { value: '', position: 0 };
                }
                return '';
            }
            if (value.length > 0 && value[0] !== '+' && this.value.length > 0 && this.value[0] === '+') {
                this.inputHandler('');
                if (position !== null) {
                    return { value: '', position: 0 };
                }
                return '';
            }
            if (value === '+' && this.value === '+7') {
                this.inputHandler('');
                if (position !== null) {
                    return { value: '', position: 0 };
                }
                return '';
            }
            if (value[0] !== '+') {
                if (position !== null) {
                    return { value, position };
                }
                return value;
            } else {
                if (
                    value &&
                    this.value &&
                    position !== null &&
                    position > 0 &&
                    value.length < utils.formats.formatPhone(this.value).length &&
                    this.formatUp(value).length === this.value.length
                ) {
                    const cleanPosition = this.formatUp(value.substring(0, position)).length;
                    const newResult = this.value.substring(0, cleanPosition - 1) + this.value.substring(cleanPosition);
                    this.inputHandler(newResult);

                    return utils.formats.formatPhone(newResult, cleanPosition - 1);
                }
                return utils.formats.formatPhone(value, position);
            }
        },
        formatUp(value) {
            if (!value) {
                return '';
            }
            if (value[0] !== '+') {
                return value;
            }
            return value.replace(/[^+0-9]/g, '');
        },
        inputHandler(newValue) {
            this.$emit('input', this.formatUp(newValue));
        },
        focusHandler(data) {
            this.$emit('focus', data);
        },
        blurHandler(data) {
            this.$emit('blur', data);
        },
    },
    mounted() {
        if (this.value?.toString() !== this.formatUp(this.formattedModel.value)) {
            this.$emit('input', this.formatUp(this.formattedModel.value));
        }
    },
};
</script>

<style scoped lang="less">
@import "~theme";
</style>
