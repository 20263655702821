<template>
    <section class="page-cabinet-requests-list">
        <div class="page-cabinet-requests-list__modes">
            <div class="page-cabinet-requests-list__modes-item"
                v-for="(mode, i) in modes"
                v-bind:key="i"
                v-bind:class="{
                    _active: mode.code === activeMode,
                    _accented: mode.isAccented,
                }"
                v-text="mode.title"
                v-on:click="modeHandler(mode.code)"
            />
        </div>
        <div class="page-cabinet-requests-list__content">
            <component v-bind:is="currentComponent" />
        </div>
    </section>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'page-cabinet-requests-list',
    data: () => ({
        activeMode: 'new',
        modes: [
            { code: 'new', title: 'Новые объекты', component: 'page-cabinet-requests-list-new' },
            { code: 'secondary', title: 'Вторичный рынок', component: 'page-cabinet-requests-list-secondary' },
        ],
    }),
    computed: {
        ...mapState('cabinet', [
            'getNoticesIsLoading',
            'getNoticesError',
            'getNoticesResult',
        ]),
        currentComponent() {
            return this.modes.find(x => x.code === this.activeMode).component;
        },
    },
    methods: {
        modeHandler(newMode) {
            this.activeMode = newMode;
            if (newMode === 'secondary') {
                window.location.hash = 'market';
            } else {
                window.location.hash = '';
            }
        },
    },
    mounted() {
        if (window.location.hash === '#market') {
            this.modeHandler('secondary');
        }
    },
    watch: {
        getNoticesResult(newVal) {
            this.modes = this.modes.map(x => {
                if (newVal && newVal.investments && x.code === 'new') {
                    return { ...x, isAccented: true };
                } else {
                    return { ...x, isAccented: false };
                }
            });
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-requests-list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    min-height: 100%;
    padding-bottom: 40px;
    &__modes {
        flex: 0 0 auto;
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        justify-content: flex-start;
        &-item {
            .typography-heading();

            position: relative;

            padding: 20px 0 16px;

            color: @color-gray-main;

            cursor: pointer;

            &:not(:last-child) {
                margin-right: 32px;
            }
            &._active {
                color: @color-gray-darkest;

                pointer-events: none;
            }
            &._accented {
                padding-right: 12px;
                &:before {
                    content: '';

                    position: absolute;
                    top: 20px;
                    right: 0;

                    display: block;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;

                    background: @color-accent-negative;
                }
            }
        }
    }
    &__content {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
    }
    @media @media-sm-down {
        &__modes {
            &-item {
                &:not(:last-child) {
                    margin-right: 24px;
                }
            }
        }
    }
}
</style>
