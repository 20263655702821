<template>
    <div class="plugin-about-partners-item">
        <a class="plugin-about-partners-item__container"
            v-bind:href="url"
            target="_blank"
            >
            <div class="plugin-about-partners-item__image"
                v-bind:style="backgroundStyle"
            />
        </a>
    </div>
</template>

<script>
import utils from '~/utils';

export default {
    name: 'plugin-about-partners-item',
    props: {
        image: {
            type: String,
            default: '',
        },
        url: {
            type: String,
            default: '',
        },
    },
    computed: {
        backgroundStyle() {
            return { backgroundImage: `url(${utils.common.injectResize(this.image, '316xAUTO')})` };
        },
    },
};
</script>

<style lang="less">
@import '~theme';

.plugin-about-partners-item {
    flex: 0 0 auto;
    width: 230px;
    height: 174px;
    padding: 12px;
    &__container {
        display: block;
        width: 100%;
        height: 100%;
        padding: 24px;
        border-radius: @border-radius-lg;
        border: 1px solid @color-gray-light;

        text-decoration: none;

        background-color: @color-gray-lightest;
    }
    &__image {
        width: 100%;
        height: 100%;

        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    @media @media-md-down {
        width: 160px;
        height: 118px;
        padding: 4px;
        &__container {
            padding: 16px;
        }
    }
    @media @media-xs-down {
        width: 50%;
    }
}
</style>
