<template>
    <div>
        layout-default
    </div>
</template>

<script>
export default {
    name: 'layout-default',
};
</script>

<style scoped lang="less">
@import '~theme';

.layout-default {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    &__header {
        position: sticky;
        top: 0;

        z-index: 3;

        flex: 0 0 auto;
        .cms-toolbar-expanded & {
            top: @container-cms-height;
        }
        &._transparent {
            margin-bottom: -@container-header-height;
            .cms-toolbar-expanded & {

            }
        }
    }
    &__main {
        position: relative;
        z-index: 2;

        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
    }
    &__footer {
        position: relative;
        z-index: 1;

        flex: 0 0 auto;
    }
}
</style>
