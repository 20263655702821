<template>
    <section class="page-cabinet-requests-list-secondary">
        <div class="page-cabinet-requests-list-secondary__loader"
            v-if="getOrdersIsLoading"
            >
            <div class="page-cabinet-requests-list-secondary__loader-container">
                <ui-loader />
            </div>
        </div>
        <div class="page-cabinet-requests-list-secondary__list"
            v-if="!getOrdersIsLoading && getOrdersResult && getOrdersResult.length > 0"
            >
            <div class="page-cabinet-requests-list-secondary__list-item"
                v-for="(item, i) in getOrdersResult"
                v-bind:key="i"
                >
                <page-cabinet-requests-list-secondary-item
                    v-bind:item="item"
                />
            </div>
        </div>
        <div class="page-cabinet-requests-list-secondary__empty"
            v-if="getOrdersResult && getOrdersResult.length === 0"
            v-text="'Заявок не найдено'"
        />
        <div class="page-cabinet-requests-list-secondary__error"
            v-if="error"
            v-text="error"
        />
    </section>
</template>

<script>
import { mapState } from 'vuex';
import utils from '~/utils';

export default {
    name: 'page-cabinet-requests-list-secondary',
    computed: {
        ...mapState('market', [
            'getOrdersResult',
            'getOrdersError',
            'getOrdersIsLoading',

            'editOrderIsLoading',
            'editOrderResult',

            'cancelOrderIsLoading',
            'cancelOrderResult',
        ]),
        error() {
            return this.getOrdersError ? utils.network.parseError(this.getOrdersError, true) : null;
        },
    },
    mounted() {
        if (!this.getOrdersResult && !this.getOrdersisLoading) {
            this.$store.dispatch('market/getOrders', { is_owner: true, status: 'active' });
        }
    },
    watch: {
        editOrderIsLoading(newVal) {
            if (!newVal && this.editOrderResult) {
                this.$store.dispatch('market/getOrders', { is_owner: true, status: 'active' });
            }
        },
        cancelOrderIsLoading(newVal) {
            if (!newVal && this.cancelOrderResult) {
                this.$store.dispatch('market/getOrders', { is_owner: true, status: 'active' });
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-requests-list-secondary {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    &__modes {
        display: flex;
        align-items: baseline;
        justify-content: flex-start;
        &-item {
            .typography-heading();

            padding: 20px 0 16px;

            color: @color-gray-main;

            pointer-events: none;
            &:not(:last-child) {
                margin-right: 24px;
            }
            &._active {
                color: @color-gray-darkest;

                pointer-events: all;
                cursor: pointer;
            }
        }
    }
    &__list {
        margin: -4px;
        &-item {
            padding: 4px;
        }
    }
    &__empty {
        .typography-small();

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 100%;
        margin: auto;

        color: @color-gray-main;
    }
    &__error {
        .typography-body();

        margin: auto;

        color: @color-accent-negative;
        text-align: center;
    }
    &__loader {
        flex: 0 0 auto;
        padding: 50px;
        margin: auto;
        &-container {
            width: 50px;
        }
    }
    @media @media-sm-down {
        &__title {
            padding: 8px 16px;
        }
    }
}
</style>
