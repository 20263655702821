<template>
    <section class="page-cabinet-referral-info">
        <section class="page-cabinet-referral-info__link">
            <page-cabinet-referral-info-link />
        </section>
        <section class="page-cabinet-referral-info__list"
            v-if="getReferralsResult && getReferralsResult.length > 0"
            >
            <page-cabinet-referral-info-list />
        </section>
    </section>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'page-cabinet-referral-info',
    computed: {
        ...mapState('referral', [
            'getReferralsResult',
        ]),
    },
    mounted() {
        this.$store.dispatch('referral/getReferrals');
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-referral-info {
    &__link {
        margin-top: 20px;
        margin-bottom: 40px;
    }
}
</style>
