<template>
    <div class="modal-feedback">
        <template v-if="isSuccessful">
            <div class="modal-feedback__title">
                Форма обратной связи
            </div>
            <div class="modal-feedback__success">
                <div class="modal-feedback__success-icon">
                    <icon name="check-fancy" original />
                </div>
                <div class="modal-feedback__success-text">
                    Обращение успешно отправлено
                </div>
            </div>
            <div class="modal-feedback__subtitle"
                v-text="'Мы рассмотрим ваше обращение и свяжемся с вами в течение 15 рабочих дней'"
            />
            <div class="modal-feedback__buttons">
                <div class="modal-feedback__buttons-item">
                    <ui-button
                        v-on:click="close"
                        theme="primary"
                        size="lg"
                        >
                        Вернуться на сайт
                    </ui-button>
                </div>
            </div>
        </template>
        <template v-else>
            <transition name="fade">
                <div class="modal-feedback__loader"
                    v-if="isLoading"
                    >
                    <div class="modal-feedback__loader-container">
                        <ui-loader />
                    </div>
                </div>
            </transition>
            <template v-if="!isLoading">
                <div class="modal-feedback__title">
                    Форма обратной связи
                </div>
                <div class="modal-feedback__subtitle">
                    Напишите нам, если у вас возникли вопросы или предложения:
                </div>
                <div class="modal-feedback__form">
                    <ui-form
                        v-bind:model="fields"
                        v-bind:validation="$v"
                        v-bind:submit-handler="submitHandler"
                        v-on:update="updateFormData"
                    />
                </div>
                <div class="modal-feedback__files">
                    <div class="modal-feedback__files-title">
                        Дополнительные файлы
                    </div>
                    <div class="modal-feedback__files-selected"
                        v-for="(file, index) in files"
                        :key="index"
                        >
                        <div class="modal-feedback__files-selected-title">
                            {{ index + 1 }}. {{ file.name }}
                        </div>
                        <div class="modal-feedback__files-selected-remove">
                            <icon name="close"
                                v-on:click="files.splice(index, 1)"
                            />
                        </div>
                    </div>
                    <div class="modal-feedback__files-uploader"
                        v-if="files.length < filesCountLimit"
                        >
                        <ui-input-base-file
                            v-bind:key="files.length"
                            v-on:change="appendHandler"
                            v-bind:label="`Прикрепить файлы (${files.length}/${filesCountLimit})`"
                        />
                    </div>
                </div>
                <template v-if="!recaptchaValue">
                    <div class="modal-feedback__recaptcha">
                        <vue-recaptcha
                            v-bind:sitekey="recaptchaKey"
                            v-on:verify="recaptchaVerifyHandler"
                            v-on:expired="recaptchaExpiredHandler"
                            load-recaptcha-script
                            ref="recaptcha"
                        />
                        <div class="modal-feedback__recaptcha-error"
                            v-if="recaptchaError"
                            v-text="recaptchaError"
                        />
                    </div>
                </template>
                <div class="modal-feedback__buttons">
                    <div class="modal-feedback__buttons-item">
                        <ui-button
                            v-on:click="submitHandler"
                            v-bind:disabled="$v.$invalid"
                            theme="primary"
                            size="lg"
                            >
                            отправить
                        </ui-button>
                    </div>
                </div>
                <div class="modal-feedback__disclaimer"
                    v-text="'Нажимая кнопку «Отправить», вы подтверждаете свое согласие на обработку персональных данных'"
                />
            </template>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import VueRecaptcha from 'vue-recaptcha';
import forms from '~/forms';

export default {
    name: 'modal-feedback',
    components: { VueRecaptcha },
    inject: [ 'recaptchaKey' ],
    data: () => ({
        innerFields: {
            fullName: { ...forms.fields.fullName, label: 'Ваше имя (ФИО)', placeholder: 'Иванов Иван Васильевич' },
            phone: { ...forms.fields.phone, placeholder: '+7' },
            email: { ...forms.fields.email, placeholder: 'Email', label: 'Эл.почта' },
            feedback: { ...forms.fields.feedback.feedback },
        },
        files: [],
        filesCountLimit: 5,
        isSuccessful: false,
        recaptchaValue: null,
        recaptchaError: null,
    }),
    validations() {
        const result = {
            fields: {
                fullName: { ...forms.validations.registrationFullName },
                phone: { ...forms.validations.phone },
                email: { ...forms.validations.email },
                feedback: { ...forms.validations.genericRequired },
            },
        };
        return result;
    },
    computed: {
        ...mapState('users', [
            'user',
        ]),
        ...mapState('feedback', [
            'feedbackResult',
            'feedbackError',
            'feedbackIsLoading',
        ]),
        isLoading() {
            return this.feedbackIsLoading;
        },
        fields() {
            return this.innerFields;
        },
    },
    methods: {
        updateFormData(newData) {
            this.innerFields = { ...this.innerFields, ...newData };
        },
        recaptchaVerifyHandler(response) {
            this.recaptchaValue = response;
        },
        recaptchaExpiredHandler() {
            this.recaptchaValue = null;
        },
        close() {
            this.$store.commit('modals/pop');
        },
        appendHandler(file) {
            this.files.push(file);
        },
        submitHandler() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            const formData = new FormData();
            formData.append('name', this.fields.fullName.value);
            formData.append('phone', this.fields.phone.value);
            formData.append('email', this.fields.email.value);
            formData.append('message', this.fields.feedback.value);

            if (!this.recaptchaValue) {
                this.recaptchaError = 'Подтвердите, что вы не робот';
                return;
            } else {
                formData.append('recaptcha', this.recaptchaValue);
            }
            if (this.files.length > 0) {
                this.files.forEach((file) => {
                    formData.append('files', file);
                });
            }
            this.$store.dispatch('feedback/feedback', formData);
        },
    },
    watch: {
        user: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.innerFields.fullName.value = newVal.fullName;
                    this.innerFields.phone.value = newVal.phone;
                    this.innerFields.email.value = newVal.email;
                }
            },
        },
        feedbackResult: {
            handler(newVal) {
                if (newVal) {
                    this.isSuccessful = true;
                }
            },
        },
        recaptchaValue(newVal) {
            if (newVal) {
                this.recaptchaError = null;
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-feedback {
    .modal();

    position: relative;
    &__success {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        margin-bottom: 16px;
        &-icon {
            font-size: 1.8rem;
            margin-right: 12px;
        }
        &-text {
            .typography-heading();
        }
    }
    &__loader {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        background-color: fade(@color-gray-lightest, 50%);
        &-container {
            width: 100px;
        }
    }
    &__title {
        .typography-large();

        width: fit-content;
        padding-bottom: 8px;
        border-bottom: 2px solid @color-gray-darkest;
        margin: 0 auto 16px;

        text-align: center;
    }
    &__subtitle {
        .typography-body();

        margin-bottom: 16px;

        text-align: center;
    }
    &__form {
        margin-bottom: 40px;
    }
    &__files {
        margin-bottom: 40px;
        &-title {
            .typography-body();

            margin-bottom: 8px;
        }
        &-selected {
            .typography-body();
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 8px;
            &-remove {
                margin-left: 8px;
                cursor: pointer;
            }
        }
    }
    &__recaptcha {
        flex: 0 0 auto;
        margin: 0 auto 40px;
        width: min-content;
        &-error {
            .typography-body();

            margin-top: 16px;

            color: @color-accent-negative;
            text-align: center;
        }
    }
    &__buttons {
        &-item {
            width: fit-content;
            margin: 40px auto 0;
        }
    }
    &__disclaimer {
        .typography-body();

        margin-top: 16px;

        text-align: center;
        color: @color-gray-main;
    }
}
</style>
