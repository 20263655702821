<template>
    <section class="page-cabinet-documents-list">
        <div class="page-cabinet-documents-list__table">
            <ui-table v-bind="tableData" />
        </div>
        <div class="page-cabinet-documents-list__cards"
            v-if="cardsData.length > 0"
            >
            <div class="page-cabinet-documents-list__cards-item"
                v-for="(card, index) in cardsData"
                v-bind:key="index"
                >
                <page-cabinet-documents-list-card
                    v-bind="card"
                />
            </div>
        </div>
        <div class="page-cabinet-documents-list__empty"
            v-else
            v-text="'Записей не найдено'"
        />
    </section>
</template>

<script>
export default {
    name: 'page-cabinet-documents-list',
    computed: {
        items() {
            const items = this.$store.getters['documents/items'];
            if (!items) {
                return null;
            }
            return items;
        },
        tableData() {
            if (!this.items) {
                return [];
            }

            const columns = [
                {
                    title: 'Дата',
                    style: { flex: '0 0 118px' },
                },
                {
                    title: 'Действие',
                    style: { flex: '1 1 703px' },
                },
                {
                    title: 'Документ',
                    style: { flex: '0 0 260px' },
                },
            ];

            const rows = this.items.map(item => {
                return [
                    {
                        type: 'component',
                        component: 'ui-table-cell-date',
                        props: {
                            date: item.date,
                        },
                    },
                    {
                        props: {
                            text: item.title,
                        },
                    },
                    {
                        type: 'component',
                        component: 'ui-table-cell-document',
                        props: {
                            title: item.filename,
                            href: item.url,
                        },
                    },
                ];
            });

            return {
                columns,
                rows,
            };
        },
        cardsData() {
            if (!this.items) {
                return [];
            }

            const cards = this.items.map(item => {
                const card = {
                    title: item.title,
                    date: item.date,
                    document: {
                        title: item.filename,
                        href: item.url,
                    },
                };
                return card;
            });
            return cards;
        },
    },
    mounted() {
        if (this.items === null) {
            this.$store.dispatch('documents/getItems');
        }
    },
    methods: {
        sort() {
            // TODO
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-documents-list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    min-height: 100%;
    &__table {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: ~'calc(100vh - 77px)';
        // 77px - common-header height
    }
    &__cards {
        display: none;
    }
    &__empty {
        display: none;
    }
    @media @media-md-down {
        &__table {
            display: none;
        }
        &__cards {
            display: block;
            width: ~'calc(100% + 18px)';
            height: ~'calc(100vh - 60px - 56px)';
            // 60px - common-header (mobile)
            // 56px - layout-cabinet-sideblock-menu
            padding-right: 18px;
            padding-bottom: 40px;
            padding-top: 16px;
            margin-right: -18px;
            overflow: auto;
            &-item {
                margin-bottom: 8px;
            }
        }
        &__empty {
            .typography-small();

            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            min-height: 100%;
            margin: auto;

            color: @color-gray-main;
        }
    }
}
</style>
