<template>
    <div class="modal-video">
        <common-video
            v-bind:video="code"
        />
    </div>
</template>

<script>
export default {
    name: 'modal-video',
    props: {
        code: {
            type: String,
            default: '',
        },
    },
    methods: {
        close() {
            this.$store.commit('modals/pop');
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-video {
    .modal();

    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    max-height: 100%;
    padding-top: 56px;
    padding-bottom: 56px;
    @media @media-sm-down {
        height: calc(100vh - @container-header-height-mobile - @container-padding-mobile);
        padding: 0;
        .cms-toolbar-expanded & {
            height: calc(100vh - @container-header-height-mobile - @container-padding-mobile - @container-cms-height);
        }
    }
}
</style>
