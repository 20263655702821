<template>
    <label class="ui-input-base-file"
        v-on:drop.stop.prevent="onDrop"
        v-on:dragover.prevent
        v-bind:class="{
            _loaded: isLoaded,
            _loading: isLoading,
            _error: error !== null || isInvalid,
        }"
        v-bind:for="inputId"
        >
        <template v-if="!isLoaded && !isLoading && error === null">
            <div class="ui-input-base-file__icon">
                <icon name="plus" />
            </div>
            <div class="ui-input-base-file__title"
                v-if="text"
                v-text="text"
            />
        </template>
        <template v-if="!isLoaded && isLoading && error === null">
            <div class="ui-input-base-file__loader">
                <div class="ui-input-base-file__loader-container">
                    <ui-loader />
                </div>
            </div>
        </template>
        <template v-if="!isLoaded && !isLoading && error !== null">
            <div class="ui-input-base-file__icon">
                <icon name="warning" />
            </div>
            <div class="ui-input-base-file__title"
                v-text="error"
            />
        </template>
        <template v-if="isLoaded && !isLoading && error === null">
            <div class="ui-input-base-file__icon">
                <icon name="success" />
            </div>
            <div class="ui-input-base-file__title"
                v-text="'Загружено!'"
            />
        </template>
        <input class="ui-input-base-file__input"
            v-bind:id="inputId"
            v-bind:accept="accept"
            v-on:change="onFileChange"
            v-bind:disabled="isLoading"
            type="file"
        />
    </label>
</template>

<script>
export default {
    name: 'ui-input-base-file',
    props: {
        accept: {
            type: String,
            default: '*.*',
        },
        givenError: {
            type: String,
            default: null,
        },
        isInvalid: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        limit: {
            type: Number,
            default: 8 * 1024 * 1024, // 8 Mb
        },
        onInput: {
            type: Function,
            default: null,
        },
        onFocus: {
            type: Function,
            default: null,
        },
        onBlur: {
            type: Function,
            default: null,
        },
        text: {
            type: String,
            default: null,
        },
        type: {
            type: String,
            default: 'file',
        },
        validFileTypes: {
            type: Array,
            default: null,
        },
        value: {
            type: [ File, String ],
            default: null,
        },
        // withPreview: {
        //     type: Boolean,
        //     default: true,
        //     // default: false,
        // },
    },
    model: {
        prop: 'value',
        event: 'change',
    },
    data: () => ({
        internalError: null,
        imageTempSrc: null,
    }),
    computed: {
        error() {
            return this.internalError || this.givenError || null;
        },
        isLoaded() {
            return !this.error && !this.isLoading && this.value !== null;
        },
        inputId() {
            return 'ui-input-base-file-' + this._uid;
        },
        // preview() {
        //     if (this.type !== 'image' || this.value === null) {
        //         return null;
        //     }
        //     preview
        //     return ;
        // },
    },
    methods: {
        isValidFileType(file) {
            return this.validFileTypes === null || this.validFileTypes.indexOf(file.type) > -1;
        },
        onDrop(e) {
            const files = e.dataTransfer.files;
            if (files.length > 0) {
                this.setFile(files[0]);
            } else {
                this.$emit('change', null);
            }
        },
        onFileChange(e) {
            if (e.target.files.length > 0) {
                this.setFile(e.target.files[0]);
            } else {
                this.$emit('change', null);
            }
        },
        async setFile(file) {
            this.internalError = null;
            this.imageTempSrc = null;
            if (!this.isValidFileType(file)) {
                this.internalError = `Тип файла не ${this.validFileTypes.join(', ')}`;
                return;
            }
            if (file.size > this.limit) {
                this.internalError = 'Файл превышает 8Mb';
                return;
            }
            if (file.size <= 0) {
                this.internalError = 'Вы пытаетесь загрузить пустой файл';
                return;
            }
            this.$emit('change', file);
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-input-base-file {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 17px 24px;
    width: 100%;
    height: 100%;
    border-radius: @border-radius-lg;
    border: 1px dashed currentColor;
    overflow: hidden;

    color: @color-gray-main;

    background-color: @color-gray-lighter;

    cursor: pointer;
    &._error,
    &._loaded {
        border-style: solid;
    }
    &._error &,
    &._loaded & {
        &__icon {
            font-size: 3.2rem;
        }
    }
    &._error {
        color: @color-accent-negative;

        background-color: fade(@color-accent-negative, 10%);
    }
    &._loading {
        pointer-events: none;
    }
    &._loaded {
        color: @color-primary;

        background-color: fade(@color-primary, 10%);
    }
    &__icon {
        padding: 7px 0;

        position: relative;
        z-index: 1;

        font-size: 2rem;
    }
    &__title {
        .typography-heading();

        position: relative;
        z-index: 1;

        padding: 7px 0;

        text-align: center;
    }
    &__loader {
        position: absolute;
        top: 0;
        left: 0;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        background-color: @color-primary;

        opacity: 0.1;
        &-container {
            width: 50px;
        }
    }
    &__input {
        position: absolute;
        z-index: -1;

        opacity: 0;
    }
}
</style>
