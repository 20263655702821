<template>
    <div class="modal-market-application-create-form">
        <div class="modal-market-application-create-form__headline">
            <modal-market-application-create-headline
                v-bind:mode="mode"
                v-bind:variant="orderId ? 'apply' : selectedShare ? 'normal' : 'empty'"
            />
        </div>
        <div class="modal-market-application-create-form__wrapper">
            <template v-if="selectedShare">
                <div class="modal-market-application-create-form__fields">
                    <ui-form
                        v-bind:model="fieldsFiltered"
                        v-bind:validation="$v"
                        v-bind:validation-path="'fieldsFiltered'"
                        v-bind:submit-handler="submitHandler"
                        v-on:update="updateFormData"
                    />
                </div>
                <div class="modal-market-application-create-form__changes"
                    v-if="mode === 'edit'"
                    >
                    <div class="modal-market-application-create-form__changes-values">
                        <div class="modal-market-application-create-form__changes-values-item _accented"
                            v-text="initialSumWithCommissionFormatted"
                        />
                        <div class="modal-market-application-create-form__changes-values-item"
                            v-text="'Сумма: ' + initialSumFormatted"
                        />
                        <div class="modal-market-application-create-form__changes-values-item"
                            v-text="`Комиссия (${commissionFormatted}): ` + initialCommissionSumFormatted"
                        />
                        <div class="modal-market-application-create-form__changes-values-item"
                            v-text="'Количество: ' + initialAmountFormatted"
                        />
                        <div class="modal-market-application-create-form__changes-values-item"
                            v-text="'Цена: ' + initialPriceFormatted"
                        />
                    </div>
                    <div class="modal-market-application-create-form__changes-arrow">
                        <icon name="arrow-right" height="auto" />
                    </div>
                    <div class="modal-market-application-create-form__changes-values">
                        <div class="modal-market-application-create-form__changes-values-item _accented"
                            v-text="sumWithCommissionFormatted + (sumWithCommissionChange ? ` (${sumWithCommissionChangeFormatted})` : '')"
                        />
                        <div class="modal-market-application-create-form__changes-values-item"
                            v-text="sumFormatted + (sumChange ? ` (${sumChangeFormatted})` : '')"
                        />
                        <div class="modal-market-application-create-form__changes-values-item"
                            v-text="commissionSumFormatted + (commissionSumChange ? ` (${commissionSumChangeFormatted})` : '')"
                        />
                        <div class="modal-market-application-create-form__changes-values-item"
                            v-text="amountFormatted + (amountChange ? ` (${amountChangeFormatted})` : '')"
                        />
                        <div class="modal-market-application-create-form__changes-values-item"
                            v-text="priceFormatted + (priceChange ? ` (${priceChangeFormatted})` : '')"
                        />
                    </div>
                </div>
                <div class="modal-market-application-create-form__additional"
                    v-else
                    >
                    <div class="modal-market-application-create-form__additional-sum">
                        <div v-if="sumChange !== null">
                            <span class="modal-market-application-create-form__additional-sum-title"
                                v-text="'Изменение суммы:'"
                            /> <span class="modal-market-application-create-form__additional-sum-value"
                                v-text="sumChangeFormatted"
                            />
                        </div>
                        <span class="modal-market-application-create-form__additional-sum-title"
                            v-text="mode === 'edit' ? 'Новая сумма:' : 'Сумма:'"
                        /> <span class="modal-market-application-create-form__additional-sum-value"
                            v-text="sumWithCommissionFormatted"
                        />
                        <div v-if="commissionSum && commissionSum > 0">
                            <span class="modal-market-application-create-form__additional-comission"
                                v-text="`Включая коммиссию ${commissionSumFormatted} (${commissionFormatted})`"
                            />
                        </div>
                    </div>
                </div>
                <div class="modal-market-application-create-form__balance"
                    v-if="fields.marketApplicationType.value === 'buy'"
                    v-bind:class="{ _clickable: isAutoCalculationAvailable }"
                    v-on:click="isAutoCalculationAvailable ? calculatePriceForBalance() : null"
                    >
                    <ui-label
                        v-bind:theme="$v.isEnoughBalance ? 'primary' : 'fade'"
                        v-bind:value="balanceFormatted"
                        title="Ваш баланс"
                        is-uppercase
                    />
                </div>
                <div class="modal-market-application-create-form__error"
                    v-if="error"
                    v-text="error"
                />
                <div class="modal-market-application-create-form__button"
                    v-if="!$v.isEnoughBalance"
                    >
                    <ui-button
                        v-bind:href="refillHref"
                        v-bind:text="'Пополнить счет'"
                        theme="secondary-1"
                    />
                </div>
                <div class="modal-market-application-create-form__button"
                    v-else
                    >
                    <ui-button
                        v-on:click="submitHandler"
                        v-bind:disabled="$v.$dirty && !isValid"
                        v-bind:text="mode === 'edit' ? 'Сохранить' : 'Создать'"
                        theme="primary"
                    />
                </div>
                <div class="modal-market-application-create-form__cancel"
                    v-if="mode === 'edit'"
                    >
                    <div class="modal-market-application-create-form__cancel-trigger"
                        v-on:click="cancelHandler"
                        v-text="fields.marketApplicationType.value === 'sale' ? 'Отменить продажу' : 'Отменить покупку'"
                    />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import config from '~/config';
import forms from '~/forms';
import utils from '~/utils';

export default {
    name: 'modal-market-application-create-form',
    props: [
        'selectedShare',
        'orderId',
        'givenType',
        'givenAmountLimit',
        'givenPrice',
        'isPriceDisabled',
        'isAmountDisabled',
        'mode',
    ],
    data: () => ({
        initialPrice: null,
        initialAmount: null,
        initialSum: null,
        refillHref: config.urls.cabinet.refill,
        fields: {
            marketApplicationType: { ...forms.fields.market.marketApplicationType },
            marketApplicationSharesAmount: { ...forms.fields.market.marketApplicationSharesAmount },
            isPackage: { ...forms.fields.market.isPackage },
            marketApplicationSharesPrice: { ...forms.fields.market.marketApplicationSharesPrice },
        },
    }),
    validations() {
        const result = {
            isEnoughBalance: () => {
                if (this.fields.marketApplicationType.value !== 'buy') {
                    return true;
                }
                if (this.mode === 'edit') {
                    return this.sumWithCommissionChange <= this.balance;
                }
                return this.sumWithCommission <= this.balance;
            },
            isMinimumInvestmentAmountValid: () => this.sum >= this.selectedShare.minimumInvestmentAmount,
            fieldsFiltered: {
                marketApplicationSharesAmount: {
                    value: {
                        ...forms.validations.genericRequired.value,
                        // валидация количества в случае недостатка у пользователя этих акций
                        isAmountPositive: (value) => parseInt(value, 10) > 0,
                        isAmountValid: (value) => this.fields.marketApplicationType.value !== 'sale' || parseInt(value, 10) <= this.selectedShare.userShares,
                    },
                },
                marketApplicationSharesPrice: {
                    value: {
                        ...forms.validations.genericRequired.value,
                        isMini: (value) => parseInt(value, 10) > 0,
                    },
                },
            },
        };
        if (this.selectedShare.maxTradePrice) {
            result.fieldsFiltered.marketApplicationSharesPrice.value.isLessThanMax = (value) => value <= this.selectedShare.maxTradePrice;
        }
        if (this.selectedShare.minTradePrice) {
            result.fieldsFiltered.marketApplicationSharesPrice.value.isMoreThanMin = (value) => value >= this.selectedShare.minTradePrice;
        }
        if (this.mode !== 'edit') {
            result.fieldsFiltered.marketApplicationType = {
                value: {
                    ...forms.validations.genericRequired.value,
                    // валидация типа 'sale' в случае отсутствия у пользователя этих акций
                    isTypeValid: (value) => value !== 'sale' || this.selectedShare.userShares !== 0,
                },
            };
        }
        if (this.givenAmountLimit !== null && this.mode !== 'edit') {
            if (this.fields.marketApplicationType.value === 'sale') {
                result.fieldsFiltered.marketApplicationSharesAmount.value.isSaleLimitValid = value => value <= this.givenAmountLimit;
            } else {
                result.fieldsFiltered.marketApplicationSharesAmount.value.isBuyLimitValid = value => value <= this.givenAmountLimit;
            }
        }
        if (this.fields.marketApplicationType.value === 'sale') {
            result.fieldsFiltered.marketApplicationSharesAmount.value.isSaleUserSharesAvailableValid = value => value <= this.fieldsFiltered.marketApplicationSharesAmount.userSharesAvailable;
        }
        if (this.fields.marketApplicationType.value === 'buy' &&
            this.fieldsFiltered.marketApplicationSharesAmount.totalIssuedQuantity) {
            result.fieldsFiltered.marketApplicationSharesAmount.value.isBuyTotalIssuedValid = value => value <= this.fieldsFiltered.marketApplicationSharesAmount.totalIssuedQuantity;
        }
        return result;
    },
    computed: {
        ...mapState('dashboard', [
            'getHighlightsResult',
            'getHighlightsError',
            'getHighlightsIsLoading',
        ]),
        ...mapState('market', [
            'acceptOrderResult',
            'acceptOrderIsLoading',
            'acceptOrderError',
        ]),
        fieldsFiltered: {
            get() {
                const fields = [
                    'marketApplicationSharesAmount',
                ];
                if (!this.orderId && parseInt(this.fields.marketApplicationSharesAmount.value, 10) > 1) {
                    fields.push('isPackage');
                }
                fields.push('marketApplicationSharesPrice');
                if (this.mode !== 'edit') {
                    fields.unshift('marketApplicationType');
                }
                const result = fields.reduce((acc, curr) => {
                    acc[curr] = this.fields[curr];
                    return acc;
                }, {});
                if (this.fields.marketApplicationType.value === 'sale') {
                    result.marketApplicationSharesAmount.labels = [
                        {
                            title: 'Можно продать',
                            value: utils.formats.formatNumberWithSpaces(this.selectedShare.userSharesAvailable),
                            theme: 'primary',
                            action: () => {
                                this.$set(this.fields.marketApplicationSharesAmount, 'value', parseInt(this.selectedShare.userSharesAvailable, 10));
                            },
                        },
                        {
                            title: 'Уже продается',
                            value: {
                                text: utils.formats.formatNumberWithSpaces(this.selectedShare.userShares - this.selectedShare.userSharesAvailable),
                                isBold: false,
                            },
                            theme: 'primary',
                        },
                    ];
                } else {
                    result.marketApplicationSharesAmount.labels = null;
                }
                return result;
            },
            set(newVal) {
                this.fields = {
                    ...this.fields,
                    ...newVal,
                };
            },
        },
        error() {
            if (!this.$v.isEnoughBalance) {
                return 'Недостаточно средств';
            }
            if (this.$v.$dirty && !this.$v.isMinimumInvestmentAmountValid) {
                return `Минимальная сумма заявки для данного объекта – ${this.selectedShare.minimumInvestmentAmountFormatted} ₽ (без учета комиссии)`;
            }
            const error = this.createOrderError || this.acceptOrderError || null;
            if (error) {
                return utils.network.parseError(error, true);
            }
            return null;
        },
        isSumInvalid() {
            return this.fields.marketApplicationType.value === 'buy' && this.sum > this.balance;
        },
        balance() {
            return this.getHighlightsResult?.balance || 0;
        },
        balanceFormatted() {
            return utils.formats.formatNumberWithSpaces(this.balance, true);
        },
        initialSumFormatted() {
            if (this.initialSum === null) {
                return null;
            }
            return utils.formats.formatNumberWithSpaces(this.initialSum, true);
        },
        initialPriceFormatted() {
            if (this.initialPrice === null) {
                return null;
            }
            return utils.formats.formatNumberWithSpaces(this.initialPrice, true);
        },
        initialAmountFormatted() {
            if (this.initialAmount === null) {
                return null;
            }
            return utils.formats.formatNumberWithSpaces(this.initialAmount) + ' шт.';
        },
        initialCommissionSum() {
            if (this.initialSum === null) {
                return null;
            }
            return this.initialSum * this.commission;
        },
        initialCommissionSumFormatted() {
            if (this.initialCommissionSum === null) {
                return null;
            }
            return utils.formats.formatNumberWithSpaces(this.initialCommissionSum, true);
        },
        initialSumWithCommission() {
            if (this.initialSum === null) {
                return null;
            }
            return this.initialSum + this.initialCommissionSum;
        },
        initialSumWithCommissionFormatted() {
            if (this.initialSumWithCommission === null) {
                return null;
            }
            return utils.formats.formatNumberWithSpaces(this.initialSumWithCommission, true);
        },
        price() {
            let result = this.fields.marketApplicationSharesPrice.value || 0;
            return result;
        },
        priceFormatted() {
            return utils.formats.formatNumberWithSpaces(this.price, true);
        },
        priceChange() {
            if (this.initialPrice) {
                return this.price - this.initialPrice;
            }
            return null;
        },
        priceChangeFormatted() {
            if (this.priceChange === null) {
                return null;
            }
            return (this.priceChange > 0 ? '+' : '') + utils.formats.formatNumberWithSpaces(this.priceChange);
        },
        amount() {
            let result = this.fields.marketApplicationSharesAmount.value || 0;
            return result;
        },
        amountFormatted() {
            return utils.formats.formatNumberWithSpaces(this.amount) + ' шт.';
        },
        amountChange() {
            if (this.initialAmount) {
                return this.amount - this.initialAmount;
            }
            return null;
        },
        amountChangeFormatted() {
            if (this.amountChange === null) {
                return null;
            }
            return (this.amountChange > 0 ? '+' : '') + utils.formats.formatNumberWithSpaces(this.amountChange);
        },
        commission() {
            if (!this.selectedShare) {
                return null;
            }
            let commission = null;
            if (this.fields.marketApplicationType.value === 'buy') {
                commission = this.selectedShare.commissionForBuyer;
            } else {
                commission = this.selectedShare.commissionForSeller;
            }
            return commission || null;
        },
        commissionFormatted() {
            if (!this.commission) {
                return null;
            }
            return `${this.commission * 100}%`;
        },
        commissionSum() {
            if (!this.commission) {
                return null;
            }
            const commission = this.sum * this.commission;
            return commission;
        },
        commissionSumFormatted() {
            if (!this.commissionSum) {
                return null;
            }
            return utils.formats.formatNumberWithSpaces(this.commissionSum, true);
        },
        commissionSumChange() {
            if (this.initialCommissionSum) {
                return this.commissionSum - this.initialCommissionSum;
            }
            return null;
        },
        commissionSumChangeFormatted() {
            if (this.commissionSumChange === null) {
                return null;
            }
            return (this.commissionSumChange > 0 ? '+' : '') + utils.formats.formatNumberWithSpaces(this.commissionSumChange);
        },
        sum() {
            const sum = (this.fields.marketApplicationSharesAmount.value || 0) * this.price;
            return sum;
        },
        sumFormatted() {
            return utils.formats.formatNumberWithSpaces(this.sum, true);
        },
        sumChange() {
            if (this.mode === 'edit') {
                return this.sum - this.initialSum;
            }
            return null;
        },
        sumChangeFormatted() {
            if (this.sumChange === null) {
                return null;
            }
            return (this.sumChange > 0 ? '+' : '') + utils.formats.formatNumberWithSpaces(this.sumChange);
        },
        sumWithCommission() {
            const sum = this.sum + (this.commissionSum || 0);
            return sum;
        },
        sumWithCommissionFormatted() {
            return utils.formats.formatNumberWithSpaces(this.sumWithCommission, true);
        },
        sumWithCommissionChange() {
            if (this.initialSumWithCommission) {
                return this.sumWithCommission - this.initialSumWithCommission;
            }
            return null;
        },
        sumWithCommissionChangeFormatted() {
            if (this.sumWithCommissionChange === null) {
                return null;
            }
            return (this.sumWithCommissionChange > 0 ? '+' : '') + utils.formats.formatNumberWithSpaces(this.sumWithCommissionChange);
        },
        isValid() {
            // return !this.isSumInvalid && !this.$v.$invalid;
            return !this.$v.$invalid;
        },
        isAutoCalculationAvailable() {
            return this.fields.marketApplicationSharesAmount.value > 0 &&
            (this.fields.marketApplicationType.value === 'sell' || this.balance > 0);
        },
    },
    methods: {
        calculatePriceForBalance() {
            if (!this.isAutoCalculationAvailable) {
                return;
            }
            this.fields.marketApplicationSharesPrice.value = Math.floor((this.balance * (1 - this.commission || 0)) / this.fields.marketApplicationSharesAmount.value);
        },
        updateFormData(newData) {
            this.fieldsFiltered = newData;
        },
        cancelHandler() {
            this.$store.dispatch('market/cancelOrder', {
                id: this.orderId,
            });
        },
        submitHandler() {
            if (!this.isValid) {
                this.$v.$touch();
                return;
            }
            if (this.mode === 'edit') {
                this.$store.dispatch('market/editOrder', {
                    id: this.orderId,
                    price: this.fields.marketApplicationSharesPrice.value,
                    quantity: this.fields.marketApplicationSharesAmount.value,
                    is_partial: parseInt(this.fields.marketApplicationSharesAmount.value, 10) === 1 || !(this.fields.isPackage.value || false),
                });
                return;
            }
            if (this.orderId) {
                this.$store.dispatch('market/acceptOrder', {
                    id: this.orderId,
                    quantity: this.fields.marketApplicationSharesAmount.value,
                });
            } else {
                this.$emit('set-sum', this.sumWithCommissionFormatted);
                this.$store.dispatch('market/createOrder', {
                    share: this.selectedShare.id,
                    order_type: 'market',
                    direction: this.fields.marketApplicationType.value,
                    price: this.fields.marketApplicationSharesPrice.value,
                    quantity: this.fields.marketApplicationSharesAmount.value,
                    is_partial: parseInt(this.fields.marketApplicationSharesAmount.value, 10) === 1 || !(this.fields.isPackage.value || false),
                    status: 'active',
                });
            }
        },
    },
    beforeMount() {
        if (this.givenType !== null) {
            let newType = this.givenType;
            if (this.mode !== 'edit') {
                newType = this.givenType === 'sale' ? 'buy' : 'sale';
            }
            this.$set(this.fields.marketApplicationType, 'options', this.fields.marketApplicationType.options.filter(x => x.value === newType));
            this.$set(this.fields.marketApplicationType, 'value', newType);
        } else if (this.mode !== 'edit') {
            this.$set(this.fields.marketApplicationType, 'value', this.fields.marketApplicationType.options[0].value);
        }
        if (this.givenAmountLimit !== null) {
            this.$set(this.fields.marketApplicationSharesAmount, 'value', this.givenAmountLimit);
            this.$set(this.fields.marketApplicationSharesAmount, 'limit', this.givenAmountLimit);
        }
        if (this.givenPrice !== null) {
            this.$set(this.fields.marketApplicationSharesPrice, 'value', this.givenPrice);
        }
        if (this.mode === 'edit' && this.givenPrice !== null && this.givenAmountLimit !== null) {
            this.initialPrice = this.givenPrice;
            this.initialAmount = this.givenAmountLimit;
            this.initialSum = this.givenPrice * this.givenAmountLimit;
        }
        if (this.isPriceDisabled) {
            this.$set(this.fields.marketApplicationSharesPrice, 'isDisabled', true);
        }
        if (this.isAmountDisabled) {
            this.$set(this.fields.marketApplicationSharesAmount, 'isDisabled', true);
        }
    },
    beforeDestroy() {
        this.$store.commit('market/resetCreateTradeShareOrder');
    },
    watch: {
        selectedShare(newVal, prevVal) {
            this.$v.$reset();
            if (newVal) {
                if (newVal.maxTradePrice) {
                    this.$set(this.fields.marketApplicationSharesPrice, 'maxTradePriceFormatted', newVal.maxTradePriceFormatted);
                } else {
                    this.$set(this.fields.marketApplicationSharesPrice, 'maxTradePriceFormatted', undefined);
                }
                if (newVal.minTradePrice) {
                    this.$set(this.fields.marketApplicationSharesPrice, 'minTradePriceFormatted', newVal.minTradePriceFormatted);
                } else {
                    this.$set(this.fields.marketApplicationSharesPrice, 'minTradePriceFormatted', undefined);
                }
                if (this.mode === 'edit') {
                    this.$set(this.fields.marketApplicationSharesAmount, 'userSharesAvailable', (this.selectedShare.userSharesAvailable || 0) + (this.givenAmountLimit || 0));
                } else {
                    this.$set(this.fields.marketApplicationSharesAmount, 'userSharesAvailable', this.selectedShare.userSharesAvailable || 0);
                }
                this.$set(this.fields.marketApplicationSharesAmount, 'totalIssuedQuantity', this.selectedShare.totalIssuedQuantity || 0);
                if ((!this.givenPrice && this.fields.marketApplicationSharesPrice.value === '') ||
                    (prevVal && parseInt(this.fields.marketApplicationSharesPrice.value, 10) === prevVal.price)) {
                    this.$set(this.fields.marketApplicationSharesPrice, 'value', newVal.price);
                }
                if (!this.isPriceDisabled) {
                    this.$set(this.fields.marketApplicationSharesPrice, 'labels', [{
                        title: 'Рекомендуемая',
                        value: `${this.selectedShare.priceFormatted} ₽`,
                        action: () => {
                            this.$set(this.fields.marketApplicationSharesPrice, 'value', this.selectedShare.price);
                        },
                    }]);
                }
            } else {
                this.$set(this.fields.marketApplicationSharesAmount, 'totalIssuedQuantity', null);
                this.$set(this.fields.marketApplicationSharesAmount, 'userSharesAvailable', null);
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-market-application-create-form {
    flex: 1 1 auto;
    padding: 40px;
    &__headline {
        max-width: 464px;
    }
    &__wrapper {
        max-width: 437px;
    }
    &__fields {
        margin-top: auto;
        margin-bottom: 24px;
    }
    &__additional {
        display: flex;
        align-items: baseline;
        justify-content: center;
        margin-bottom: 40px;
        &-sum {
            .typography-heading();

            text-align: center;
            &-title {
                color: @color-gray-main;
            }
        }
        &-comission {
            .typography-caption();

            color: @color-gray-main;
        }
    }
    &__changes {
        .typography-medium();
        margin-bottom: 24px;

        padding-left: 16px;
        border-left: 2px solid @color-primary;

        display: flex;
        align-items: center;
        justify-content: flex-start;

        white-space: nowrap;
        &-values {
            flex: 0 0 auto;
            &-item {
                .typography-body();
                &._accented {
                    .typography-medium();
                }
            }
        }
        &-arrow {
            flex: 0 0 auto;
            padding: 0 2ch;
        }
    }
    &__balance {
        width: fit-content;
        margin: 0 auto 24px;

        &._clickable {
            cursor: pointer;
        }
    }
    &__error {
        .typography-heading();

        margin-bottom: 24px;

        text-align: center;
        color: @color-accent-negative;
    }
    &__button {
        width: 206px;
        margin: auto auto 0;
    }
    &__cancel {
        width: fit-content;
        margin: 24px auto 0;
        &-trigger {
            .typography-caption();

            color: @color-accent-negative;
            font-weight: bold;
            text-transform: uppercase;
            text-decoration: underline;

            cursor: pointer;
            &:hover {
                text-decoration: none;
            }
        }
    }
    @media @media-sm-down {
        max-width: unset;
        padding: 16px 16px 0;
        &__headline {
            display: none;
        }
        &__subtitle {
            display: none;
        }
        &__additional {
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 24px;
            &-sum {
                margin-bottom: 16px;
            }
        }
        &__changes {
            flex-direction: column;
            align-items: flex-start;
            &-arrow {
                padding: 2ch 0;
                transform: rotate(90deg);
            }
        }
        &__button {
            width: 100%;
            max-width: 320px;
        }
    }
}
</style>
