export const updateDjangoScriptTags = () => {
    const cmsScripts = document.querySelectorAll('script[data-cms]');
    for (let i = 0; i < cmsScripts.length; i++) {
        const script = cmsScripts[i];
        if (!script.hasAttribute('type')) {
            script.setAttribute('type', 'application/javascript');
        }
    }
};

export class updateDjangoTamplateTags {
    constructor(instance) {
        this.instance = instance;
        this.init();
    };

    _canBePatched() {
        return window.CMS !== undefined && window.CMS.config.mode === 'draft';
    };

    _replaceTemplateTags() {
        var templates = document.querySelectorAll('template.cms-plugin');
        for (var i = 0; i < templates.length; i++) {
            var template = templates[i];
            var cmsTemplate = document.createElement('cms-template');
            cmsTemplate.className = template.className;
            template.parentNode.insertBefore(cmsTemplate, template);
            template.parentNode.removeChild(template);
        }
    };

    _moveScriptTags() {
        var scripts = this.instance.$options.el.querySelectorAll('script[data-cms]');
        for (var i = 0; i < scripts.length; i++) {
            scripts[i].parentNode.removeChild(scripts[i]);
            document.body.appendChild(scripts[i]);
        }
    };

    _cleanTemplateTags() {
        var cmsTemplates = document.querySelectorAll('cms-template');
        for (var i = 0; i < cmsTemplates.length; i++) {
            cmsTemplates[i].parentNode.removeChild(cmsTemplates[i]);
        }
    };

    refresh() {
        if (this._canBePatched()) {
            this.instance.$destroy();
            delete this.instance.$options.render; // Force re-render.
            this.instance = new this.instance.constructor(this.instance.$options);
            window.CMS.Plugin._initializeTree();
        }
    };

    patch() {
        if (this._canBePatched()) {
            this._replaceTemplateTags();
            this._moveScriptTags();
            window.CMS.$(document).on('ready', this._cleanTemplateTags.bind(this));
            // window.CMS.$(window).on('cms-content-refresh', this.refresh.bind(this));
        }
    };

    init() {
        if (this._canBePatched() && !this.instance.$options._cmsPatched) {
            this.patch();
            this.instance.$options._cmsPatched = true;
        }
    };
};

export const toggleFullscreen = (element, callback = function() { }) => {
    function onFullScreenChange() {
        var fullScreenElement =
            document.fullscreenElement ||
            document.msFullscreenElement ||
            document.mozFullScreenElement ||
            document.webkitFullscreenElement;
        // eslint-disable-next-line standard/no-callback-literal
        callback(!!fullScreenElement);
    };

    if (document.onfullscreenchange === null) {
        document.onfullscreenchange = onFullScreenChange;
    } else if (document.onmsfullscreenchange === null) {
        document.onmsfullscreenchange = onFullScreenChange;
    } else if (document.onmozfullscreenchange === null) {
        document.onmozfullscreenchange = onFullScreenChange;
    } else if (document.onwebkitfullscreenchange === null) {
        document.onwebkitfullscreenchange = onFullScreenChange;
    }

    if (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement
    ) {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    } else {
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
        }
    }
};

export const injectResize = (url, resize) => {
    if (!url) {
        return url;
    }
    const lastSlashIndex = url.lastIndexOf('/');
    if (lastSlashIndex < 0) {
        return;
    }
    const result = url.slice(0, lastSlashIndex) + '/' + resize + url.slice(lastSlashIndex);
    return result;
};

const queries = [
    320,
    375,
    576,
    768,
    1024,
    1280,
    1440,
    1600,
    1920,
    2560,
    3840,
];

export const getResizeByElement = ({ element, byWidthOnly = true, isRounded = true }) => {
    if (!element) {
        return '1920xAUTO';
    }
    const bounds = element.getBoundingClientRect();
    let width = Math.round(bounds.width * (window.devicePixelRatio || 1));
    width = queries.find(x => x >= width) || queries[queries.length - 1];
    if (byWidthOnly) {
        return `${width}xAUTO`;
    }
    let height = Math.round(bounds.height * (window.devicePixelRatio || 1));
    height = queries.find(x => x >= height) || queries[queries.length - 1];
    return `${width}x${height}`;
};
export const getResize = (screenWidthPercent = 100, aspectRatio = null) => {
    // screenWidthPercent can be Number (straight percent of screen width)
    // can be String (treated as a fixed pixel value)
    // or dictionary of breakpoints:
    // {
    //     1920: 43, => 1024+: 43%
    //     1023: '200', 768~1023: 200px
    //     767: 100, 767-: 100%
    // }
    let targetWidth;
    if (typeof screenWidthPercent === 'object' && screenWidthPercent !== null) {
        targetWidth = Object.keys(screenWidthPercent).map(x => parseInt(x, 10)).sort((a, b) => a > b ? 1 : -1);
        targetWidth = targetWidth.find(key => key >= window.innerWidth) || targetWidth[targetWidth.length - 1];
        targetWidth = screenWidthPercent[targetWidth];
    } else {
        targetWidth = screenWidthPercent;
    }
    if (typeof targetWidth === 'string') {
        targetWidth = parseInt(targetWidth, 10);
    } else if (typeof targetWidth === 'number') {
        targetWidth = targetWidth * window.innerWidth / 100;
    } else {
        targetWidth = window.innerWidth;
    }
    targetWidth *= window.devicePixelRatio || 1;
    const width = queries.find(x => x >= targetWidth) || queries[queries.length - 1];
    if (aspectRatio) {
        const height = Math.round(width * aspectRatio);
        return `${width}x${height}`;
    }
    return `${width}xAUTO`;
};

export const getCSRFToken = () => {
    const token = document.querySelector('[name="csrfmiddlewaretoken"]');
    if (!token) {
        console.error('CSRF token not found');
        return '';
    }
    return document.querySelector('[name="csrfmiddlewaretoken"]').value;
};

export const getDeclension = (number, wordForms) => {
    // getDeclension(1, ['минута', 'минуты', 'минут'])
    const lastTwoDigits = Math.abs(number) % 100;
    const lastDigit = Math.abs(number) % 10;
    if (lastTwoDigits > 10 && lastTwoDigits < 20) {
        return wordForms[2];
    }
    if (lastDigit > 1 && lastDigit < 5) {
        return wordForms[1];
    }
    if (lastDigit === 1) {
        return wordForms[0];
    }
    return wordForms[2];
};

export const getObjectValueByPath = (objToSearh = {}, path = '', forceNewObject = false) => {
    let obj = forceNewObject ? JSON.parse(JSON.stringify(objToSearh)) : objToSearh;
    if (!path || path === '.') {
        return obj;
    }
    for (var i = 0, levels = path.split('.'); i < levels.length; i++) {
        const nextLevel = obj[levels[i]];
        if (nextLevel === undefined) {
            return undefined;
        }
        obj = nextLevel;
    };
    return obj;
};

export const parseUrl = ({ url = null, onlyWithValues = false } = {}) => {
    if (url === null) {
        url = decodeURI(window.location.href);
    }
    const searchIndex = url.indexOf('?');
    if (searchIndex < 0) {
        return [];
    }
    let search = url.substring(searchIndex + 1);
    const complexFiltersSignature = 'complex_filters';
    const complexFiltersStart = search.indexOf(complexFiltersSignature);
    const complexFiltersEnd = search.lastIndexOf(')') + 1;
    const result = [];
    if (complexFiltersStart > -1 && complexFiltersEnd > -1) {
        const complexFilters = search.substring(complexFiltersStart + complexFiltersSignature.length + 1, complexFiltersEnd);
        search = search.substring(0, complexFiltersStart) + search.substring(complexFiltersEnd);
        result.push({ title: complexFiltersSignature, value: complexFilters });
    }
    const queries = search.split('&');
    if (queries) {
        queries.forEach(q => {
            const [ query, value ] = q.split('=');
            if (value) {
                result.push({ title: query, value });
            } else if (!onlyWithValues) {
                result.push({ title: query });
            }
        });
    }
    return result;
};

export const parseBBCode = (bbcode, remove = false) => {
    let html = bbcode;

    // Replace [br] with <br />
    html = html.replace(/\[br\]/g, remove ? '' : '<br />');

    // Replace [b]...[/b] with <strong>...</strong>
    html = html.replace(/\[b\](.*?)\[\/b\]/g, remove ? '' : '<strong>$1</strong>');

    // Replace [i]...[/i] with <em>...</em>
    html = html.replace(/\[i\](.*?)\[\/i\]/g, remove ? '' : '<em>$1</em>');

    // Replace [u]...[/u] with <u>...</u>
    html = html.replace(/\[u\](.*?)\[\/u\]/g, remove ? '' : '<u>$1</u>');

    // Replace [url]...[/url] with <a href="...">...</a>
    html = html.replace(/\[url\](.*?)\[\/url\]/g, remove ? '' : '<a href="$1">$1</a>');

    // Replace [url=...]...[/url] with <a href="...">...</a>
    html = html.replace(/\[url="(.*?)"\](.*?)\[\/url\]/g, remove ? '' : '<a href="$1">$2</a>');

    // Replace [img]...[/img] with <img src="..." />
    html = html.replace(/\[img\](.*?)\[\/img\]/g, remove ? '' : '<img src="$1" />');

    // Replace [list]...[/list] with <ul>...</ul> and [*] with <li></li>
    html = html.replace(/\[list\](.*?)\[\/list\]/gs, function(match, content) {
        const li = content.replace(/\[\*\](.*?)(?=\[\*\]|$)/gs, remove ? '' : '<li>$1</li>');
        return `<ul>${li}</ul>`;
    });
    html = html.replace(/\[\*\](.*?)\[\/\*\]/g, remove ? '' : '<br/><strong>$1</strong>');

    return html;
};

export default {
    getResize,
    getResizeByElement,
    injectResize,
    getCSRFToken,
    getDeclension,
    getObjectValueByPath,
    parseUrl,
    parseBBCode,
    toggleFullscreen,
    updateDjangoScriptTags,
    updateDjangoTamplateTags,
};
