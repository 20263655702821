<template>
    <ui-card-base
        v-bind:padding-left="'24px'"
        v-bind:padding-right="'24px'"
        with-shadow
        >
        <article class="page-estates-item-highlight">
            <div class="page-estates-item-highlight__title"
                v-text="title"
            />
            <div class="page-estates-item-highlight__separator" />
            <div class="page-estates-item-highlight__lines">
                <div class="page-estates-item-highlight__lines-item"
                    v-for="(line, index) in lines"
                    v-bind:key="index"
                    >
                    <div class="page-estates-item-highlight__lines-item-title"
                        v-text="line.title"
                    />
                    <div class="page-estates-item-highlight__lines-item-value"
                        v-text="line.value"
                    />
                </div>
            </div>
        </article>
    </ui-card-base>
</template>

<script>
export default {
    name: 'page-estates-item-highlight',
    props: {
        title: {
            type: String,
            default: '',
        },
        lines: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-estates-item-highlight {
    .typography-caption();

    &__title {
        margin-bottom: 6px;

        text-transform: uppercase;
        font-weight: bold;
    }
    &__separator {
        width: 30px;
        height: 2px;
        margin-bottom: 8px;

        background-color: currentColor;
    }
    &__lines {
        &-item {
            .typography-body();

            display: flex;
            justify-content: space-between;
            align-items: baseline;

            & ~ & {
                margin-top: 4px;
            }
            &-title {
                padding-right: @grid-gap-half;
            }
            &-value {
                padding-left: @grid-gap-half;

                text-align: right;
            }
        }
    }
}
</style>
