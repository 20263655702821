import { render, staticRenderFns } from "./holidays-warning.vue?vue&type=template&id=479e8c75&scoped=true&"
import script from "./holidays-warning.vue?vue&type=script&lang=js&"
export * from "./holidays-warning.vue?vue&type=script&lang=js&"
import style0 from "./holidays-warning.vue?vue&type=style&index=0&id=479e8c75&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "479e8c75",
  null
  
)

export default component.exports