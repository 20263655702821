<template>
    <div class="common-investment-progress"
        v-bind:class="[
            `_theme_${theme}`,
            {
                _empty: target > 0 && (!current || current <= 0),
                _full: current >= target,
                _with_target: isTargetVisible,
            },
        ]"
        >
        <div class="common-investment-progress__bar">
            <ui-progress
                v-bind:value="target > 0 ? current / target : 1"
                v-bind:theme="theme"
            />
            <div class="common-investment-progress__bar-percent"
                v-bind:style="percentStyle"
                v-text="percent + '%'"
            />
        </div>
        <div class="common-investment-progress__target"
            v-if="isTargetVisible"
            v-text="targetFormatted"
        />
    </div>
</template>

<script>
import utils from '~/utils';
export default {
    name: 'common-investment-progress',
    props: {
        target: {
            type: Number,
            default: 1,
        },
        current: {
            type: Number,
            default: 0,
        },
        isTargetVisible: {
            type: Boolean,
            default: true,
        },
        theme: {
            type: String,
            default: 'primary',
        },
    },
    computed: {
        percent() {
            if (this.target === 0) {
                return 100;
            }
            return Math.min(Math.max(0, Math.floor(this.current / (this.target || 1) * 100)), 100);
        },
        percentStyle() {
            return {
                marginLeft: this.percent + '%',
                transform: `translateX(calc(-${this.percent}%${this.percent >= 99 ? ' + 1px' : ''}))`,
            };
        },
        targetFormatted() {
            return utils.formats.formatNumberWithSpaces(this.target) + this.$store.state.site.currency.symbol;
        },
    },
    methods: {
        formatNumber(rawValue) {
            return utils.formats.formatNumberWithSpaces(rawValue);
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.common-investment-progress {
    font-weight: bold;
    &__bar {
        position: relative;

        flex: 1 1 auto;
        padding: 6px 0;
        &-percent {
            .typography-caption();

            position: absolute;
            bottom: 0;
            left: 0;

            padding: 2px 8px;
            border-radius: 4px;

            color: @color-primary;

            background-color: @color-secondary;
        }
        ._empty > & > &-percent {
            color: @color-gray-main;

            background-color: @color-gray-light;
        }
    }
    &__target {
        .typography-heading();

        flex: 0 0 auto;
        padding-left: 16px;

        color: @color-gray-main;
    }
    &._with_target {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
    }
    &._theme_disabled &__bar-percent {
        color: @color-gray-darkest;

        background-color: @color-gray-light;
    }
}
</style>
