// eslint-disable-next-line camelcase
export const subscriptionsListItem = ({ id, is_subscribed }) => {
    const result = {
        id,
        isSubscribed: is_subscribed,
    };
    return result;
};

export const subscriptionsList = (data) => {
    const result = {
        items: data.results.map(x => subscriptionsListItem(x)),
        paging: {
            count: data.count || 0,
            page: data.page || 1,
        },
        ...data,
    };
    return result;
};
