<template>
    <transition name="fade">
        <div class="notification-root"
            v-if="isActive"
            >
            <div class="notification-root__item"
                v-for="(notification, index) in list"
                v-bind:key="index + notification.name"
                >
                <component
                    v-bind:is="'notification-' + notification.name"
                    v-bind="notification.props"
                    v-on="notification.on ? notification.on : null"
                />
                <div class="notification-root__item-close"
                    v-if="!notification.props.noClose"
                    v-on:click="close(index)"
                    >
                    <icon name="close" />
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'notification-root',
    data: () => ({
        isActive: false,
        defaults: {},
    }),
    computed: {
        list() {
            return this.$store.state.notifications.list.map(x => {
                if (this.defaults[x.name] !== undefined) {
                    x.props = {
                        ...this.defaults[x.name],
                        ...x.props,
                    };
                }
                return x;
            });
        },
    },
    methods: {
        close(index) {
            this.$store.commit('notifications/popByIndex', index);
        },
        deactivate() {
            if (this.list.length === 0) {
                this.isActive = false;
            }
        },
    },
    watch: {
        list(newList) {
            if (newList.length === 0) {
                this.isActive = false;
            } else {
                this.isActive = true;
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.notification-root {
    .transition-fade();

    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: @z-index-notification;

    display: flex;
    flex-direction: column-reverse;
    align-items: stretch;
    justify-content: flex-end;
    width: 100%;
    &__item {
        .transition-scale-fade();

        position: relative;

        background-color: @color-gray-lightest;

        box-shadow: 0px -4px 10px rgba(33, 28, 64, 0.1);
        & ~ & {
            border-bottom: 1px solid @color-gray-light;
        }
        &-close {
            position: absolute;
            top: 50%;
            right: 24px;

            padding: 16px;

            color: @color-gray-main;
            font-size: 1.6rem;

            cursor: pointer;

            transform: translateY(-50%);
        }
    }
    @media @media-md-down {
        &__item {
            &-close {
                right: 8px;
            }
        }
    }
}
</style>
