<template>
    <section class="plugin-home-advantages">
        <h2 class="plugin-home-advantages__title"
            v-text="title"
        />
        <div class="plugin-home-advantages__slider">
            <div class="swiper-container"
                ref="container"
                >
                <div class="swiper-wrapper">
                    <div class="swiper-slide"
                        v-for="(item, index) in items"
                        v-bind:key="index"
                        >
                        <div class="plugin-home-advantages__slider-item"
                            v-bind:style="getBackgroundStyle(item.image)"
                            >
                            <div class="plugin-home-advantages__slider-item-content">
                                <h3 class="plugin-home-advantages__slider-item-content-title"
                                    v-text="item.title"
                                />
                                <div class="plugin-home-advantages__slider-item-content-text">
                                    <span v-html="item.text" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="plugin-home-advantages__slider-pagination"
                    ref="pagination"
                    >
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Swiper from 'swiper/dist/js/swiper';
import utils from '~/utils';

export default {
    name: 'plugin-home-advantages',
    props: {
        title: {
            type: String,
            default: '',
        },
        items: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        slider: null,
    }),
    methods: {
        checkScreen() {
            if (window.innerWidth < 1024) {
                if (this.slider === null) {
                    this.initSlider();
                }
            } else {
                if (this.slider !== null) {
                    this.slider.destroy();
                    this.slider = null;
                }
            }
        },
        initSlider() {
            const container = this.$refs.container;
            const pagination = this.$refs.pagination;

            this.slider = new Swiper(container, {
                speed: 600,
                slidesPerView: 'auto',
                spaceBetween: 16,
                pagination: {
                    el: pagination,
                },
            });
        },
        getBackgroundStyle(image) {
            if (!image) {
                return null;
            }
            const resize = utils.common.getResize({
                1920: 25,
                1279: 33,
                1023: '288',
            });
            return { backgroundImage: `url(${utils.common.injectResize(image, resize)})` };
        },
    },
    mounted() {
        this.checkScreen();
        utils.dom.addLoadedCallback(this.checkScreen);
        window.addEventListener('resize', this.checkScreen);
    },
    beforeDestroy() {
        utils.dom.removeLoadedCallback(this.checkScreen);
        window.removeEventListener('resize', this.checkScreen);
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-home-advantages {
    .container();

    &__title {
        .typography-extra-large();

        margin: 0 0 40px;

        text-align: center;
    }
    &__slider {
        .swiper-container {
            .swiper-wrapper {
                flex-wrap: wrap;
                width: auto;
                margin: -@grid-gap-half;
                .swiper-slide {
                    width: 25%;
                    padding: @grid-gap-half;
                }
            }
        }
        &-pagination {
            display: none;
        }
        &-item {
            position: relative;

            padding-top: 111.8%;
            border-radius: @border-radius-lg;
            overflow: hidden;

            color: @color-gray-lightest;

            background-color: @color-gray-main;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            &:before {
                content: '';

                position: absolute;
                top: 0;
                left: 0;

                display: block;
                width: 100%;
                height: 100%;

                background-image: linear-gradient(180deg, rgba(39, 36, 45, 0.9) 0%, rgba(39, 36, 45, 0.1) 100%);
            }
            &:after {
                content: '';

                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;

                display: block;
                width: 100%;
                height: 100%;

                background-color: rgba(39, 36, 45, 0.85);

                opacity: 0;

                transition: opacity @duration-fast ease-in-out;
            }
            &:hover {
                &:after {
                    opacity: 1;
                }
            }
            &-content {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;

                display: flex;
                flex-direction: column;
                align-items: stretch;
                justify-content: flex-start;
                width: 100%;
                height: 100%;
                padding: @grid-gap @grid-gap 20px;

                &-title {
                    .typography-large();

                    flex: 0 0 auto;
                    margin: 0 0 12px;

                    line-height: 3.4rem;
                    text-align: center;
                }
                &-text {
                    .typography-body();

                    flex: 1 1 auto;
                    overflow: auto;

                    white-space: pre-line;

                    opacity: 0;
                    pointer-events: none;

                    transform: translateY(-30px);

                    transition: opacity @duration-fast ease-in-out, transform @duration-fast ease-in-out;
                }
                &:hover &-text {
                    opacity: 1;
                    pointer-events: all;

                    transform: translateY(0);
                }
            }
        }
    }
    @media @media-lg-down {
        &__slider {
            .swiper-container {
                .swiper-wrapper {
                    .swiper-slide {
                        width: 33.33%;
                    }
                }
            }
        }
    }
    @media @media-md-down {
        padding: 0;

        &__title {
            .container();
            .typography-large();

            margin-bottom: 17px;

            text-align: left;
        }
        &__slider {
            .swiper-container {
                .container();

                .swiper-wrapper {
                    flex-wrap: nowrap;
                    width: 100%;
                    margin: 0;
                    .swiper-slide {
                        width: 288px;
                        padding: 0;
                    }
                }
            }
            &-item {
                padding-top: 125%;
                &:after {
                    opacity: 1;
                }
                &-content {
                    padding: 16px;
                    &-title {
                        .typography-medium();
                    }
                    &-text {
                        opacity: 1;
                        pointer-events: all;

                        transform: translateY(0);
                    }
                }
            }
            &-pagination {
                .container();

                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                margin-top: 40px;
                /deep/ .swiper-pagination-bullet {
                    background-color: @color-gray-darker;

                    opacity: 0.4;

                    transition: opacity @duration-fast ease-in-out;
                    &-active.swiper-pagination-bullet-active {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
</style>
