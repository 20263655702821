import client from '../_client';
import config from '~/config';
import utils from '~/utils';
import transforms from './transforms';

export const getVariants = (params = {}) => {
    return client.request({
        url: config.api.qualification.variants,
        method: 'GET',
        params,
        transformData: transforms.responses.getVariants,
    });
};

export const apply = (data = {}) => {
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.qualification.apply,
        method: 'POST',
        data,
        // transformData: transforms.responses.getVariants,
    });
};

export const uploadFile = (data = {}, progressHandler = null) => {
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.qualification.files,
        method: 'POST',
        // data: data.formData,
        data,
        // transformData: transforms.responses.uploadFile,
        onUploadProgress: progressHandler,
    });
};

export const getFile = (data = {}) => {
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.qualification.files + `${data.page}/`,
        method: 'GET',
        data,
        // transformData: transforms.responses.getFile,
    });
};

export const deleteFile = (data = {}) => {
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.qualification.files + `${data.id}/`,
        method: 'DELETE',
        // transformData: transforms.responses.getFile,
    });
};
