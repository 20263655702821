import { mainClient } from '../../network';

const moduleTitle = 'market';

const state = {
    getAllSharesResult: null,
    getAllSharesIsLoading: false,
    getAllSharesError: null,
    getAllSharesCount: 0,

    getActiveSharesResult: null,
    getActiveSharesIsLoading: false,
    getActiveSharesError: null,
    getActiveSharesCount: 0,

    getShareById: {},

    getOrdersByShare: {},

    createOrderResult: null,
    createOrderIsLoading: false,
    createOrderError: null,

    acceptOrderResult: null,
    acceptOrderIsLoading: false,
    acceptOrderError: null,

    signOrderResult: null,
    signOrderIsLoading: false,
    signOrderError: null,

    cancelOrderResult: null,
    cancelOrderIsLoading: false,
    cancelOrderError: null,

    getOrdersResult: null,
    getOrdersIsLoading: false,
    getOrdersError: null,
    getOrdersCount: 0,

    editOrderResult: null,
    editOrderIsLoading: false,
    editOrderError: null,

    getDealsResult: null,
    getDealsIsLoading: false,
    getDealsError: null,

    getDealResult: null,
    getDealIsLoading: false,
    getDealError: null,

    getSubscriptionsItemResult: null,
    getSubscriptionsItemIsLoading: false,
    getSubscriptionsItemError: null,

    updateSubscriptionsItemResult: null,
    updateSubscriptionsItemIsLoading: false,
    updateSubscriptionsItemError: null,
};

const getters = {
    // items(state) {
    //     return state.items.data;
    // },
};

const actions = {
    async getAllShares({ state }, payload = {}) {
        if (state.getAllSharesIsLoading) {
            return;
        }
        state.getAllSharesIsLoading = true;
        const { data, error, status } = await mainClient[moduleTitle].requests.getAllShares(payload);
        if (error) {
            console.error(`Error in action ${moduleTitle}/getAllShares (${status})`);
            state.getAllSharesResult = null;
            state.getAllSharesError = error;
        } else if (data) {
            state.getAllSharesError = null;
            state.getAllSharesResult = data.items;
            state.getAllSharesCount = data.paging.count;
        }
        state.getAllSharesIsLoading = false;
    },
    async getActiveShares({ state }, payload = {}) {
        if (state.getActiveSharesIsLoading) {
            return;
        }
        state.getActiveSharesIsLoading = true;
        const { data, error, status } = await mainClient[moduleTitle].requests.getActiveShares(payload);
        if (error) {
            console.error(`Error in action ${moduleTitle}/getActiveShares (${status})`);
            state.getActiveSharesResult = null;
            state.getActiveSharesError = error;
        } else if (data) {
            state.getActiveSharesError = null;
            state.getActiveSharesResult = data.items;
            state.getActiveSharesCount = data.paging.count;
        }
        state.getActiveSharesIsLoading = false;
    },
    async getShareById({ state, commit }, payload = {}) {
        if (!state.getShareById[payload.id]) {
            commit('initializeSharesStorage', payload.id);
        }
        if (state.getShareById[payload.id].isLoading) {
            return;
        }
        state.getShareById[payload.id] = {
            ...state.getShareById[payload.id],
            isLoading: true,
        };
        const { data, error, status } = await mainClient[moduleTitle].requests.getShareById(payload);
        if (error) {
            console.error(`Error in action ${moduleTitle}/getShareById for share ${payload.id} (${status})`);
            state.getShareById[payload.id] = {
                result: null,
                isLoading: false,
                error,
            };
        } else if (data) {
            state.getShareById[payload.id] = {
                result: data,
                isLoading: false,
                error: null,
            };
        }
    },
    async getOrdersByShare({ state, commit }, payload = {}) {
        if (!state.getOrdersByShare[payload.share]) {
            commit('initializeTradeShareOffer', payload.share);
        }
        if (state.getOrdersByShare[payload.share].isLoading) {
            return;
        }
        state.getOrdersByShare[payload.share] = {
            ...state.getOrdersByShare[payload.share],
            isLoading: true,
        };
        const { data, error, status } = await mainClient[moduleTitle].requests.getOrders(payload);
        if (error) {
            console.error(`Error in action ${moduleTitle}/getOrdersByShare for share ${payload.share} (${status})`);
            state.getOrdersByShare[payload.share] = {
                ...state.getOrdersByShare[payload.share],
                result: null,
                isLoading: false,
                error: error,
                count: 0,
            };
        } else if (data) {
            state.getOrdersByShare[payload.share] = {
                ...state.getOrdersByShare[payload.share],
                result: data.items,
                isLoading: false,
                error: null,
                count: data.paging.count,
            };
        }
    },
    async getOrders({ state }, payload = {}) {
        if (state.getOrdersIsLoading) {
            return;
        }
        state.getOrdersIsLoading = true;
        const { data, error, status } = await mainClient[moduleTitle].requests.getOrders(payload);
        if (error) {
            console.error(`Error in action ${moduleTitle}/getOrders (${status})`);
            state.getOrdersResult = null;
            state.getOrdersError = error;
        } else if (data) {
            state.getOrdersError = null;
            state.getOrdersResult = data.items;
            state.getOrdersCount = data.paging.count;
        }
        state.getOrdersIsLoading = false;
    },
    async createOrder({ state }, payload = {}) {
        if (state.createOrderIsLoading) {
            return;
        }
        state.createOrderIsLoading = true;
        const { data, error, status } = await mainClient[moduleTitle].requests.createOrder(payload);
        if (error) {
            console.error(`Error in action ${moduleTitle}/createOrder for share ${payload.share} (${status})`);
            state.createOrderResult = null;
            state.createOrderError = error;
        } else if (data) {
            state.createOrderResult = data;
            state.createOrderError = null;
        }
        state.createOrderIsLoading = false;
    },
    async editOrder({ state }, payload = {}) {
        if (state.editOrderIsLoading) {
            return;
        }
        state.editOrderIsLoading = true;
        const { data, error, status } = await mainClient[moduleTitle].requests.editOrder(payload);
        if (error) {
            console.error(`Error in action ${moduleTitle}/editOrder (${status})`);
            state.editOrderResult = null;
            state.editOrderError = error;
        } else if (data) {
            state.editOrderError = null;
            state.editOrderResult = data;
        }
        state.editOrderIsLoading = false;
    },
    async cancelOrder({ state }, payload = {}) {
        if (state.cancelOrderIsLoading) {
            return;
        }
        state.cancelOrderIsLoading = true;
        const { data, error, status } = await mainClient[moduleTitle].requests.cancelOrder(payload);
        if (error) {
            console.error(`Error in action ${moduleTitle}/cancelOrder (${status})`);
            state.cancelOrderResult = null;
            state.cancelOrderError = error;
        } else {
            state.cancelOrderResult = data;
            state.cancelOrderError = null;
        }
        state.cancelOrderIsLoading = false;
    },
    async acceptOrder({ state }, payload = {}) {
        if (state.acceptOrderIsLoading) {
            return;
        }
        state.acceptOrderIsLoading = true;
        const { data, error, status } = await mainClient[moduleTitle].requests.acceptOrder(payload);
        if (error) {
            console.error(`Error in action ${moduleTitle}/acceptOrder (${status})`);
            state.acceptOrderResult = null;
            state.acceptOrderError = error;
        } else {
            state.acceptOrderResult = data;
            state.acceptOrderError = null;
        }
        state.acceptOrderIsLoading = false;
    },
    async signOrder({ state }, payload = {}) {
        if (state.signOrderIsLoading) {
            return;
        }
        state.signOrderIsLoading = true;
        const { data, error, status } = await mainClient[moduleTitle].requests.signOrder(payload);
        if (error) {
            console.error(`Error in action ${moduleTitle}/signOrder (${status})`);
            state.signOrderResult = null;
            state.signOrderError = error;
        } else if (data) {
            state.signOrderError = null;
            state.signOrderResult = data;
        }
        state.signOrderIsLoading = false;
    },
    async getDeals({ state }, payload = {}) {
        if (state.getDealsIsLoading) {
            return;
        }
        state.getDealsIsLoading = true;
        const { data, error, status } = await mainClient[moduleTitle].requests.getDeals(payload);
        if (error) {
            console.error(`Error in action ${moduleTitle}/getDeals (${status})`);
            state.getDealsResult = null;
            state.getDealsError = error;
        } else if (data) {
            state.getDealsError = null;
            state.getDealsResult = data.items;
            state.getDealsCount = data.paging.count;
        }
        state.getDealsIsLoading = false;
    },
    async getDeal({ state }, payload = {}) {
        if (state.getDealIsLoading) {
            return;
        }
        state.getDealIsLoading = true;
        const { data, error, status } = await mainClient[moduleTitle].requests.getDeal(payload);
        if (error) {
            console.error(`Error in action ${moduleTitle}/getDeal (${status})`);
            state.getDealResult = null;
            state.getDealError = error;
        } else if (data) {
            state.getDealError = null;
            state.getDealResult = data;
        }
        state.getDealIsLoading = false;
    },
    async getSubscriptionsItem({ state }, payload = {}) {
        if (state.getSubscriptionsItemIsLoading) {
            return;
        }
        state.getSubscriptionsItemIsLoading = true;
        const { data, error } = await mainClient[moduleTitle].requests.getSubscriptionsItem(payload);
        if (error) {
            state.getSubscriptionsItemResult = null;
            state.getSubscriptionsItemError = error;
        } else if (data) {
            state.getSubscriptionsItemError = null;
            state.getSubscriptionsItemResult = data;
        }
        state.getSubscriptionsItemIsLoading = false;
    },
    async updateSubscriptionsItem({ state }, { payload, onSuccess = null } = {}) {
        if (state.updateSubscriptionsItemIsLoading) {
            return;
        }
        state.updateSubscriptionsItemIsLoading = true;
        const { data, error } = await mainClient[moduleTitle].requests.updateSubscriptionsItem(payload);
        if (error) {
            state.updateSubscriptionsItemResult = null;
            state.updateSubscriptionsItemError = error;
        } else if (data) {
            state.updateSubscriptionsItemError = null;
            state.updateSubscriptionsItemResult = data;
            if (onSuccess) {
                onSuccess();
            }
        }
        state.updateSubscriptionsItemIsLoading = false;
    },
};

const mutations = {
    initializeTradeShareOffer(state, id) {
        state.getOrdersByShare = {
            ...state.getOrdersByShare,
            [id]: {
                result: null,
                isLoading: false,
                error: null,
                count: 0,
            },
        };
    },
    initializeSharesStorage(state, id) {
        state.getShareById = {
            ...state.getShareById,
            [id]: {
                result: null,
                isLoading: false,
                error: null,
            },
        };
    },
    resetCreateTradeShareOrder(state) {
        state.createOrderResult = null;
        state.createOrderIsLoading = false;
        state.createOrderError = null;
    },
    resetAcceptTradeShareOrder(state) {
        state.acceptOrderResult = null;
        state.acceptOrderIsLoading = false;
        state.acceptOrderError = null;
    },
    resetSignOrder(state) {
        state.signOrderResult = null;
        state.signOrderIsLoading = false;
        state.signOrderError = null;
    },
    resetGetShareById(state, payload) {
        if (!payload) {
            state.getShareById = {};
            return;
        }
        state.getShareById[payload.id].result = null;
        state.getShareById[payload.id].isLoading = false;
        state.getShareById[payload.id].error = null;
    },
    resetGetOrdersByShare(state, payload) {
        if (!payload) {
            state.getOrdersByShare = {};
            return;
        }
        state.getOrdersByShare[payload.share].result = null;
        state.getOrdersByShare[payload.share].isLoading = false;
        state.getOrdersByShare[payload.share].error = null;
        state.getOrdersByShare[payload.share].count = 0;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
