import client from '../_client';
import config from '~/config';
import utils from '~/utils';
// import transforms from './transforms';

export const subscribe = (data = {}) => {
    return client.request({
        url: config.api.subscription.subscribe,
        method: 'POST',
        data,
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        // transformData: transforms.responses.countriesList,
    });
};
