<template>
    <article class="page-cabinet-dashboard-estimation">
        <div class="page-cabinet-dashboard-estimation__headline">
            <div class="page-cabinet-dashboard-estimation__headline-title">
                Стоимость портфеля
            </div>
            <div class="page-cabinet-dashboard-estimation__headline-switchers">
                <div class="page-cabinet-dashboard-estimation__headline-switchers-item"
                    v-for="(switcher, index) in modes"
                    v-bind:key="index"
                    v-bind:class="{ _active: index === activeIndex }"
                    v-on:click="setActiveIndex(index)"
                    v-text="switcher.title"
                />
            </div>
        </div>
        <div class="page-cabinet-dashboard-estimation__graph">
            <ui-card-base with-border>
                <div class="page-cabinet-dashboard-estimation__graph-container">
                    <ui-graph
                        v-bind:type="'area'"
                        v-bind:data="data"
                        v-bind:categories="categories"
                    />
                </div>
            </ui-card-base>
            <transition name="fade">
                <div class="page-cabinet-dashboard-estimation__graph-loader"
                    v-if="isLoading"
                    >
                    <ui-loader
                        v-bind:fixed="50"
                        v-bind:centered="true"
                    />
                </div>
            </transition>
        </div>
    </article>
</template>

<script>
import moment from 'moment';
import { mainClient } from '~/network';

export default {
    name: 'page-cabinet-dashboard-estimation',
    props: {
        currentYear: {
            type: String,
            default: moment().format('YY'),
        },
    },
    data: () => ({
        activeIndex: 0,
        data: [0, 0, 0, 0],
        isLoading: false,
    }),
    computed: {
        user() {
            return this.$store.getters['users/user'];
        },
        categories() {
            if (!this.activeMode?.params) return [];
            const year = this.activeMode.params.yearShort;
            return [ `1кв'${year}`, `2кв'${year}`, `3кв'${year}`, `4кв'${year}` ];
        },
        activeMode() {
            return this.modes[this.activeIndex];
        },
        modes() {
            const result = [];
            if (this.user === null || this.user === 'guest') {
                result.push({
                    title: moment().format('YYYY'),
                    params: {
                        year: moment().format('YYYY'),
                        yearShort: moment().format('YY'),
                    },
                });
            } else {
                const currentYear = new Date().getFullYear();
                const registrationYear = this.user.dateRegistered.getFullYear();
                const yearsDiff = currentYear - registrationYear;
                for (let i = 0; i < yearsDiff + 1; i++) {
                    result.push({
                        title: registrationYear + i,
                        params: {
                            year: registrationYear + i,
                            yearShort: moment((registrationYear + i).toString()).format('YY'),
                        },
                    });
                }
                this.setActiveIndex(yearsDiff);
            }
            return result;
        },
    },
    methods: {
        async getData() {
            if (!this.activeMode?.params) {
                return;
            }
            this.isLoading = true;
            const { data, error, status } = await mainClient.users.requests.getDashboardPortfolio({
                points_count: 4,
                year: this.activeMode.params.year,
            });
            if (error) {
                console.error(error, status);
            } else {
                this.data = data.points;
            }
            this.isLoading = false;
        },
        setActiveIndex(index) {
            this.activeIndex = index;
        },
    },
    watch: {
        activeIndex: {
            handler() {
                this.getData();
            },
            immediate: true,
        },
        user() {
            this.getData();
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-dashboard-estimation {
    margin-bottom: 40px;
    &__headline {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        width: 100%;
        padding: 0 16px;
        &-title {
            .typography-heading();

            flex: 0 0 auto;
            padding-right: 24px;
        }
        &-switchers {
            .typography-body();

            flex: 0 0 auto;
            display: flex;
            align-items: baseline;
            justify-content: flex-end;
            margin: 0 -12px;
            &-item {
                padding: 8px 12px;

                color: @color-gray-main;

                cursor: pointer;

                transition: color @duration-fast ease-in-out;
                &._active {
                    color: @color-gray-darker;
                }
            }
        }
    }
    &__graph {
        position: relative;
        &-container {
            width: 100%;
            height: 350px;
        }
        &-loader {
            .transition-fade();

            position: absolute;
            top: 5px;
            right: 5px;
            bottom: 5px;
            left: 5px;
            z-index: 1;

            display: flex;
            align-items: center;
            justify-content: center;

            background-color: fade(@color-gray-lightest, 75%);
        }
    }
}
</style>
