import client from '../_client';
import config from '~/config';
import utils from '~/utils';
import transforms from './transforms';

export const getUserInvestments = (params = {}) => {
    return client.request({
        url: config.api.investments.userInvestments,
        method: 'GET',
        params,
        transformData: transforms.responses.userInvestmentsList,
    });
};

export const verifyUserInvestmentRequest = (data = {}) => {
    const id = data.id;
    delete data.id;
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.investments.verifyRequest.replace('{id}', id),
        method: 'POST',
        data,
    });
};

export const getUserShares = (params = {}) => {
    return client.request({
        url: config.api.investments.userShares,
        method: 'GET',
        params,
        transformData: transforms.responses.userSharesList,
    });
};

export const invest = (data = {}) => {
    return client.request({
        url: config.api.investments.invest,
        method: 'POST',
        data,
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
    });
};

export const editRequest = (data = {}) => {
    const id = data.id;
    delete data.id;
    return client.request({
        url: config.api.investments.invest + id + '/',
        method: 'PATCH',
        data,
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
    });
};

export const getRequests = (params = {}) => {
    return client.request({
        url: config.api.investments.invest,
        method: 'GET',
        params,
        transformData: transforms.responses.requestsList,
    });
};
