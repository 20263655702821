import { mainClient } from '~/network';

const YMID = 73437139;

export const ym = {
    registr: () => {
        window.ym(YMID, 'reachGoal', 'registr');
    },
    register: () => {
        console.warn("disabled analytics trigger: window.ym(73437139, 'reachGoal', 'registration');");
        // window.ym(YMID, 'reachGoal', 'registration');
    },
    login: () => {
        console.warn("disabled analytics trigger: window.ym(73437139, 'reachGoal', 'login_send');");
        // window.ym(YMID, 'reachGoal', 'login_send');
    },
};

export const gtag = {
    register: () => {
        window.gtag('event', 'send', { 'event_category': 'registration' });
    },
    login: () => {
        window.gtag('event', 'send', { 'event_category': 'login' });
    },
};

export const checkUserData = (userData) => {
    if (!userData.hasAnalytics) {
        console.warn('user analytics is EMPTY');
        fillUserData();
    }
};

export const sendUserId = (id) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'userId',
        'user_id': id,
    });
    window.ga && window.ga('set', 'dimension1', id);
};

export const fillUserData = () => {
    const analytics = [
        {
            cookies: document.cookie.split('; '),
        },
        {
            'user-agent': window.navigator.userAgent,
        },
    ];
    const formData = new FormData();
    formData.append('data', JSON.stringify(analytics));
    mainClient.users.requests.updateUserAnalytics(formData);
};

export default {
    checkUserData,
    fillUserData,
    sendUserId,
    gtag,
    ym,
};
