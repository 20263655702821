<template>
    <div class="modal-create-estate">
        <template v-if="isAnyLoading">
            <div class="modal-create-estate__loader">
                <ui-loader />
            </div>
        </template>
        <template v-else-if="createEstateError">
            <span v-text="createEstateError" />
        </template>
        <template v-else-if="createEstateResult">
            <div class="modal-create-estate__success">
                <div class="modal-create-estate__success-icon">
                    <icon name="success" />
                </div>
                <div class="modal-create-estate__success-text"
                    v-text="'Объект ' + fields.title.value + ' успешно создан. Не забудьте заполнить данные по этому объекту'"
                />
                <a class="modal-create-estate__success-button"
                    v-bind:href="editUrl"
                    >
                    <ui-button theme="secondary-1">
                        Перейти к редактированию
                    </ui-button>
                </a>
            </div>
        </template>
        <template v-else>
            <div class="modal-create-estate__content">
                <div class="modal-create-estate__title"
                    v-text="'Новый объект'"
                />
                <div class="modal-create-estate__form">
                    <div class="modal-create-estate__form-fields">
                        <ui-form
                            v-bind:model="fields"
                            v-bind:validation="$v"
                            v-bind:submit-handler="submitHandler"
                            v-on:update="updateFormData"
                        />
                    </div>
                    <div class="modal-create-estate__form-error"
                        v-bind:class="{ _active: createEstateError }"
                        >
                        <transition name="fade">
                            <span class="modal-create-estate__form-error-text"
                                v-if="createEstateError"
                                v-text="createEstateError"
                            />
                        </transition>
                    </div>
                    <div class="modal-create-estate__form-buttons">
                        <div class="modal-create-estate__form-buttons-item">
                            <ui-button
                                v-bind:disabled="($v.$dirty && $v.$invalid) || isAnyLoading"
                                v-on:click="submitHandler"
                                theme="primary"
                                >
                                Создать объект
                            </ui-button>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import config from '~/config';
import forms from '~/forms';

export default {
    name: 'modal-create-estate',
    data: () => ({
        fields: {
            image: { ...forms.fields.admin.estates.image },
            title: { ...forms.fields.admin.estates.title },
            type: { ...forms.fields.admin.estates.type },
        },
    }),
    validations() {
        return {
            fields: {
                image: { ...forms.validations.genericRequired },
                title: { ...forms.validations.genericRequired },
                type: { ...forms.validations.genericRequired },
            },
        };
    },
    computed: {
        ...mapState('admin/estates', {
            createEstateResult: state => state.createEstateResult,
            createEstateError: state => state.createEstateError,
            createEstateIsLoading: state => state.createEstateIsLoading,
        }),
        ...mapState('estates', {
            getEstateTypesResult: state => state.getEstateTypesResult,
            getEstateTypesError: state => state.getEstateTypesError,
            getEstateTypesIsLoading: state => state.getEstateTypesIsLoading,
        }),
        isAnyLoading() {
            return this.createEstateIsLoading || this.getEstateTypesIsLoading;
        },
        editUrl() {
            return `${config.urls.adminDashboard.estates}${this.createEstateResult?.id}`;
        },
    },
    methods: {
        updateFormData(newData) {
            this.fields = newData;
        },
        close() {
            this.$store.commit('modals/pop');
        },
        submitHandler() {
            if (this.isAnyLoading) {
                return;
            }
            if (this.$v.$invalid) {
                if (!this.$v.$dirty) {
                    this.$v.$touch();
                }
                return;
            }
            this.$store.dispatch('admin/estates/createEstate', {
                image: this.fields.image.value,
                title: this.fields.title.value,
                type: this.getEstateTypesResult.find(x => x.id === this.fields.type.value),
                availableInvestmentTypes: [ 2 ],
            });
        },
    },
    mounted() {
        if (!this.getEstateTypesResult && !this.getEstateTypesIsLoading) {
            this.$store.dispatch('estates/getEstateTypes');
        }
    },
    watch: {
        getEstateTypesResult: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.fields.type.options = newVal.map(x => ({ value: x.id, text: x.title }));
                }
            },
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-create-estate {
    .modal();
    &__loader {
        width: 100px;
        margin: 100px auto;
    }
    &__content {
    }
    &__success {
        &-icon {
            width: fit-content;
            margin: 0 auto 16px;

            color: @color-primary;
            font-size: 3.2rem;
        }
        &-text {
            .typography-small();

            margin-bottom: 24px;

            text-align: center;
        }
        &-button {
            display: block;
            height: 48px;
            width: 100%;
            max-width: 231px;
            margin: 0 auto;

            text-decoration: none;
        }
    }
    &__title {
        .typography-large();

        margin-bottom: 25px;

        text-align: center;
    }
    &__form {
        .typography-body();

        margin-bottom: 16px;

        &-fields {
            margin-bottom: 60px;
            &-checkbox {
                margin-top: 24px;
            }
        }
        &-error {
            margin-bottom: 25px;
            &-text {
                .transition-fade();

                color: @color-accent-negative;
            }
        }
        &-buttons {
            &-item {
                display: block;
                height: 56px;
                width: 207px;
                margin: 0 auto;

                text-decoration: none;
                & ~ & {
                    margin-top: 24px;
                }
            }
        }
    }
    @media @media-md-down {
        &__success {
            padding: 40px 16px;
        }
        &__title {
            .typography-medium();
        }
    }
}
</style>
