<template>
    <div class="modal-status-form-personal">
        <div class="modal-status-form-personal__group"
            v-for="group in groups"
            :key="group.code"
            >
            <div class="modal-status-form-personal__group-row"
                v-for="row in group.rows"
                :key="row.title"
                >
                <div class="modal-status-form-personal__group-row-title"
                    v-text="row.title"
                />
                <div class="modal-status-form-personal__group-row-value"
                    v-text="row.value"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'modal-status-form-personal',
    props: {
        formData: {
            type: Object,
        },
    },
    data: () => ({
        groups: [{
            code: 'emitent',
            rows: [{
                title: 'Полное официальное наименование эмитента ценных бумаг',
                value: 'Публичное акционерное общество "Сбербанк России"',
            }],
        }, {
            code: 'person',
            rows: [{
                title: 'Фамилия',
                value: 'Венедиктов',
            }, {
                title: 'Имя',
                value: 'Игорь',
            }, {
                title: 'Отчество',
                value: 'Натанович',
            }, {
                title: 'Гражданство',
                value: 'РФ',
            }, {
                title: 'Дата рождения',
                value: '17.11.1988',
            }, {
                title: 'Место рождения',
                value: 'Москва',
            }],
        }, {
            code: 'passport',
            rows: [{
                title: 'Номер паспорта',
                value: '776589',
            }, {
                title: 'Дата выдачи паспорта',
                value: '17.11.2003',
            }, {
                title: 'Наименование органа, выдавшего документ',
                value: 'МВД Москва',
            }, {
                title: 'Код подразделения',
                value: '000-000',
            }],
        }, {
            code: 'inn',
            rows: [{
                title: 'ИНН',
                value: '1234567890',
            }],
        }],
    }),
    beforeMount() {
        this.$emit('validation-update', true);
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-status-form-personal {
    &__group {
        & + & {
            padding-top: 8px;
            border-top: 1px solid @color-gray-light-secondary;
            margin-top: 8px;
        }
        &-row {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 16px;
            & + & {
                margin-top: 8px;
            }
            &-title {
                .typography-caption();

                flex: 1 1 276px;

                font-weight: bold;
                text-transform: uppercase;
            }
            &-value {
                .typography-body();

                flex: 1 1 558px;
            }
        }
    }
}
</style>
