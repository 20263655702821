<template>
    <div class="modal-market-application-success">
        <div class="modal-market-application-success__title"
            v-text="'Заявка создана'"
        />
        <div class="modal-market-application-success__subtitle">
            Ваша заявка на {{ application.direction === 'sale' ? 'продажу' : 'покупку' }} акций в объекте <span class="modal-market-application-success__subtitle-estate">{{ share.estate.title }}</span> на сумму <b>{{ givenSum || sumFormatted }}</b> успешно создана!
            <br />
            <br />
            Ожидайте отклика {{ application.direction === 'sale' ? 'покупателя' : 'продавца' }}
        </div>
        <div class="modal-market-application-success__buttons">
            <div class="modal-market-application-success__buttons-item">
                <ui-button
                    v-bind:href="cabinetLink"
                    text="В личный кабинет"
                    theme="secondary-1"
                />
            </div>
            <div class="modal-market-application-success__buttons-item">
                <ui-button
                    v-on:click="close"
                    text="Готово"
                />
            </div>
        </div>
    </div>
</template>

<script>
import config from '~/config';
import utils from '~/utils';

export default {
    name: 'modal-market-application-success',
    props: {
        application: {
            type: Object,
        },
        share: {
            type: Object,
        },
        givenSum: {
            type: String,
            default: null,
        },
    },
    computed: {
        cabinetLink() {
            return config.urls.cabinet.requests + '#market';
        },
        sumFormatted() {
            return utils.formats.formatNumberWithSpaces(this.application.price * this.application.quantity, true);
        },
    },
    methods: {
        close() {
            this.$store.commit('modals/pop');
        },
    },
};
</script>

<style lang="less">
@import '~theme';

.modal-market-application-success {
    .modal();

    position: relative;

    display: flex;
    flex-direction: column;
    min-height: 212px;
    &__title {
        .typography-large();

        margin-bottom: 8px;

        text-align: center;
    }
    &__subtitle {
        .typography-small();

        margin-bottom: 24px;

        text-align: center;
        &-estate {
            font-weight: bold;
            white-space: nowrap;
        }
    }
    &__buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: -6px;
        flex-wrap: wrap;
        &-item {
            flex: 0 0 207px;
            padding: 6px;
        }
    }
    @media @media-sm-down {
        &__title {
            .typography-medium();
        }
        &__subtitle {
            .typography-body();
        }
    }
}
</style>
