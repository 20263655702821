<template>
    <div class="plugin-about-video">
        <div class="plugin-about-video__container">
            <div class="plugin-about-video__wrapper">
                <div class="plugin-about-video__player">
                    <div class="plugin-about-video__player-content">
                        <common-video v-bind:video="video" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'plugin-about-video',
    props: { video: String },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-about-video {
    position: relative;
    &::before {
        content: '';

        position: absolute;
        top: 60px;
        right: 0;
        bottom: 60px;
        left: 0;
        z-index: 0;

        background-color: @color-gray-lighter;
    }
    &__container {
        .container();
        .container-paddings();
    }
    &__wrapper {
        margin: 0 calc(@grid-column + @grid-gap);
    }
    &__player {
        position: relative;
        z-index: 1;

        padding-bottom: 56.25%; /* 16:9 */
        border-radius: @border-radius-lg;
        overflow: hidden;
        &-content {
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;
        }
    }
    @media @media-md-down {
        &::before {
            top: 24px;
            bottom: 24px;
        }
        &__wrapper {
            margin: 0;
        }
    }
}
</style>
