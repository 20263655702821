<template>
    <div class="ui-table" role="table"
        v-bind:class="[
            `_theme_${theme}`,
            { _autosize: !sizesProvided }
        ]"
        >
        <template v-if="rows.length > 0">
            <div class="ui-table__container">
                <div class="ui-table__header"
                    v-if="columns && columns.length > 0"
                    v-bind:style="{
                        paddingLeft: layout && layout.rowPaddingLeft,
                        paddingRight: layout && layout.rowPaddingRight,
                    }"
                    >
                    <div class="ui-table__column"
                        v-for="(column, index) in columns"
                        v-bind:key="index"
                        v-bind:class="{ _wide: column.wide }"
                        v-bind:style="{
                            minWidth: column.minWidth || (layout && layout.minWidth),
                            flex: '1 1 ' + column.width,
                            ...column.style,
                        }"
                        >
                        <div class="ui-table__header-cell">
                            <span v-text="column.title" />
                            <span class="ui-table__header-cell-icon"
                                v-if="column.sortable"
                                >
                                <span class="icon icon-sort-desc" />
                            </span>
                        </div>
                    </div>
                </div>
                <div class="ui-table__body">
                    <div class="ui-table__body-row"
                        v-for="(row, index) in rows"
                        v-bind:key="index"
                        v-bind:style="{
                            paddingLeft: layout && layout.rowPaddingLeft,
                            paddingRight: layout && layout.rowPaddingRight,
                            marginBottom: layout && layout.rowMarginBottom,
                        }"
                        >
                        <div class="ui-table__column"
                            v-for="(cell, index) in row"
                            v-bind:key="index"
                            v-bind:class="{
                                _wide: columns && columns[index] && columns[index].wide,
                                _fixed: columns && columns[index] && columns[index].fixed,
                            }"
                            v-bind:style="{
                                minWidth: (columns && columns[index] && columns[index].minWidth) || (layout && layout.minWidth),
                                flex: '1 1 ' + (columns && columns[index] && columns[index].width),
                                ...(columns && columns[index] && columns[index].style)
                            }"
                            >
                            <template v-if="cell.type === 'component'">
                                <component v-bind:is="cell.component"
                                    v-bind="cell.props"
                                />
                            </template>
                            <template v-else>
                                <span v-text="cell.props.text" />
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="ui-table__empty"
                v-text="'Записей не найдено'"
            />
        </template>
    </div>
</template>

<script>
export default {
    name: 'ui-table',
    props: {
        layout: {
            type: Object,
        },
        columns: {
            type: Array,
            default: null,
        },
        rows: {
            type: Array,
            default: () => [],
        },
        theme: {
            type: String,
            default: 'default',
        },
    },
    data: () => ({
        sorting: {
            by: null,
            order: 'desc',
        },
    }),
    computed: {
        sizesProvided() {
            return this.columns && (this.columns.find(x => x.wide) || this.columns.every(x => x.width));
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-table {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    overflow: auto;
    &._theme_dark &__body-row {
        background-color: @color-gray-lighter;
    }
    &._theme_secondary &__body-row {
        border: 1px solid @color-gray-light;
    }
    &._theme_secondary &__header-cell {
        color: @color-gray-main;
    }
    &__container {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        width: 100%;
    }
    &__header {
        .typography-caption();

        flex: 0 0 auto;
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        padding: 31px 16px 12px;

        text-transform: uppercase;
        &-cell {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            font-weight: bold;
            &-icon {
                display: inline-block;
                margin-left: 6px;

                font-size: 1.8rem;

                cursor: pointer;
            }
        }
    }
    &__body {
        flex: 1 1 100%;
        &-row {
            .typography-body();

            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            padding: 16px 16px 15px;
            border-radius: @border-radius-lg;
            margin-bottom: 8px;

            background-color: @color-gray-lightest;
        }
    }
    &__column {
        flex: 1 1 auto;
        padding: 0 8px;
        min-width: 0;
        ._autosize &:first-child,
        &._wide {
            flex: 1 1 100%;
        }
        &._fixed {
            flex: 0 0 auto;
        }
    }
    &__empty {
        .typography-small();

        padding-right: 135px;
        margin: auto;

        color: @color-gray-main;
    }
}
</style>
