<template>
    <div class="ui-hint"
        v-bind:class="{ _wide: isWide }"
        v-on:mouseenter="mouseEnterHandler"
        v-on:mouseleave="mouseLeaveHandler"
        >
        <div v-if="$slots.default"
            class="ui-hint__children"
            >
            <slot />
        </div>
        <div v-else
            class="ui-hint__icon"
            >
            <icon name="question" />
        </div>
        <transition name="fade">
            <div class="ui-hint__content"
                v-if="isHovered"
                v-text="!html ? text : null"
                v-html="html ? html : text"
            />
        </transition>
    </div>
</template>

<script>
export default {
    name: 'ui-hint',
    props: {
        html: {
            type: String,
            default: null,
        },
        text: {
            type: String,
            default: '',
        },
        isWide: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        isHovered: false,
    }),
    methods: {
        mouseEnterHandler() {
            this.isHovered = true;
        },
        mouseLeaveHandler() {
            this.isHovered = false;
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-hint {
    position: relative;

    display: inline-block;
    padding-top: 1px;
    &__children {
        display: inline-block;

        cursor: pointer;
    }
    &__icon {
        font-size: 1.2rem;

        cursor: pointer;
    }
    &__content {
        .transition-fade();
        .typography-caption();

        position: absolute;
        top: calc(100% + 20px);
        left: 50%;
        z-index: 1;

        width: 192px;
        min-height: 36px;
        padding: 9px 15px;
        border-radius: @border-radius-lg;

        color: @color-gray-lightest;
        text-align: center;
        white-space: pre-line;

        background-color: @color-gray-darkest;

        transform: translateX(-50%);
        &:before {
            content: '';

            position: absolute;
            top: -8px;
            left: 50%;
            z-index: -1;

            width: 30px;
            height: 30px;
            border-radius: @border-radius-lg;

            background-color: @color-gray-darkest;

            transform: translateX(-50%) rotate(45deg);
        }
        &:after {
            content: '';

            position: absolute;
            top: -20px;
            left: 0;
            z-index: -1;

            width: 100%;
            height: 30px;
        }
        ._wide > & {
            width: 320px;
            max-width: 66vw;
        }
    }
    @media @media-md-down {
        &__content {
            width: 140px;
        }
    }
}
</style>
