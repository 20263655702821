export const subscribe = ({ name, phone }) => {
    const result = {};
    if (name) {
        result.guest_name = name;
    }
    if (phone) {
        result.guest_phone = phone;
    }
    return result;
};
