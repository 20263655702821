<template>
    <div class="page-cabinet-requests-item-header">
        <a class="page-cabinet-requests-item-header__image"
            v-bind:href="investment.estate.url"
            v-bind:style="backgroundImage"
            target="_blank"
        />
        <div class="page-cabinet-requests-item-header__info">
            <div class="page-cabinet-requests-item-header__info-title"
                v-text="investment.estate.title"
            />
            <div class="page-cabinet-requests-item-header__info-sum">
                <div class="page-cabinet-requests-item-header__info-sum-title"
                    v-text="'Сумма:'"
                />
                <div class="page-cabinet-requests-item-header__info-sum-value"
                    v-text="investment.investmentsFormatted"
                />
            </div>
        </div>
    </div>
</template>

<script>
import utils from '~/utils';

export default {
    name: 'page-cabinet-requests-item-header',
    props: {
        investment: {
            type: Object,
        },
    },
    computed: {
        backgroundImage() {
            return { backgroundImage: `url(${utils.common.injectResize(this.investment.estate.image, window.innerWidth >= 1024 ? '600xAUTO' : '132x132')})` };
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-requests-item-header {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    padding: 8px;
    &__image {
        flex: 0 0 auto;
        display: block;
        width: 300px;
        min-height: 122px;
        border-radius: @border-radius-lg;
        margin-right: 24px;

        text-decoration: none;

        background-size: cover;
        background-position: center;
        background-color: @color-gray-light;
        background-repeat: no-repeat;
    }
    &__info {
        flex: 1 1 auto;
        padding-top: 16px;
        &-title {
            .typography-medium();

            margin-bottom: 12px;
        }
        &-sum {
            .typography-body();

            display: flex;
            align-items: baseline;
            justify-content: flex-start;
            &-title {
                flex: 0 0 auto;
                margin-right: 4px;

                color: @color-gray-main;
            }
            &-value {

            }
        }
    }
    @media @media-sm-down {
        align-items: center;
        padding: 0;
        &__image {
            width: 66px;
            height: 66px;
            min-height: auto;
            margin-right: 10px;
        }
        &__info {
            padding-top: 0;
            &-title {
                .typography-small();

                margin-bottom: 0;
            }
        }
    }
}
</style>
