<template>
    <div class="page-cabinet-referral-info-list-status"
        v-bind:class="{
            _accented: code === 'invested',
        }"
        >
        <div class="page-cabinet-referral-info-list-status__text"
            v-text="statusText"
        />
    </div>
</template>

<script>
export default {
    name: 'page-cabinet-referral-info-list-status',
    props: {
        code: {
            type: String,
            default: 'new',
        },
    },
    computed: {
        statusText() {
            const statuses = {
                new: 'Зарегистрировался',
                verified: 'Верифицирован',
                invested: 'Проинвестировал',
            };
            return statuses[this.code] || statuses.new;
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-referral-info-list-status {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    color: @color-gray-main;
    &._accented {
        color: @color-positive;
    }
    &:before {
        content: '';

        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 8px;

        background-color: currentColor;
    }
    &__text {
        .typography-body();
    }
}
</style>
