<template>
    <section class="plugin-home-philosophy">
        <div class="plugin-home-philosophy__container">
            <h2 class="plugin-home-philosophy__title"
                v-text="title"
            />
            <div class="plugin-home-philosophy__list">
                <div class="plugin-home-philosophy__list-item"
                    v-for="(item, index) in items"
                    v-bind:key="index"
                    >
                    <div class="plugin-home-philosophy__list-item-image"
                        v-bind:style="getBackgroundStyle(item.image)"
                    />
                    <h3 class="plugin-home-philosophy__list-item-title"
                        v-text="item.title"
                    />
                    <div class="plugin-home-philosophy__list-item-text">
                        <div class="common-ckeditor"
                            v-html="item.text"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import utils from '~/utils';

export default {
    name: 'plugin-home-philosophy',
    props: {
        title: {
            type: String,
            default: '',
        },
        items: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        getBackgroundStyle(image) {
            if (!image) {
                return null;
            }
            const resize = utils.common.getResize('64');
            return { backgroundImage: `url(${utils.common.injectResize(image, resize)})` };
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-home-philosophy {
    .container();
    &__container {
        padding: 0 calc(@grid-gap + @grid-column);
    }
    &__title {
        .typography-extra-large();

        margin: 0 0 40px;
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        align-content: stretch;
        justify-content: flex-start;
        width: 100%;
        &-item {
            flex: 0 0 auto;
            width: 50%;
            padding-bottom: 32px;
            &:nth-child(odd) {
                padding-right: 11%;
            }
            &:nth-child(even) {
                padding-left: 11%;
                border-left: 2px solid @color-gray-light;
            }
            &:nth-child(2) ~ & {
                padding-top: 32px;
                border-top: 2px solid @color-gray-light;
            }
            &:nth-last-child(-n+2) {
                padding-bottom: 0;
            }
            &-image {
                width: 64px;
                height: 64px;
                border-radius: 50%;
                margin-bottom: 16px;

                background-size: contain;
                background-position: left bottom;
                background-repeat: no-repeat;
            }
            &-title {
                .typography-medium();

                margin: 0 0 16px;
            }
        }
    }
    @media @media-md-down {
        &__container {
            padding: 0;
        }
        &__title {
            .typography-large();

            margin-bottom: 24px;

            line-height: 3.4rem;
        }
        &__list {
            &-item {
                width: 100%;
                padding-bottom: 24px;
                &:nth-child(odd) {
                    padding-right: 0;
                }
                &:nth-child(even) {
                    padding-left: 0;
                    border-left: none;
                }
                &:nth-child(1) ~ & {
                    padding-top: 24px;
                    border-top: 2px solid @color-gray-light;
                }
                &:nth-last-child(-n+2) {
                    padding-bottom: 24px;
                }
                &:last-child {
                    padding-bottom: 0;
                }
            }
        }
    }
    @media @media-sm-down {
        &__item {
            width: 288px;
        }
    }
}
</style>
