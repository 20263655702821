<template>
    <section class="page-admin-estates-item-requests">
        <template v-if="rounds && !getLoanIsLoading">
            <div class="page-admin-estates-item-requests__empty"
                v-if="rounds.length === 0"
                >
                Нет раундов
            </div>
            <template v-else>
                <div class="page-admin-estates-item-requests__round"
                    v-for="round in rounds"
                    v-bind:key="round.index"
                    v-bind:class="{
                        _expanded: expandedRounds.includes(round.index),
                        _disabled: !round.isActive && !round.isCompleted,
                    }"
                    >
                    <div class="page-admin-estates-item-requests__round-title"
                        v-on:click="toggleRound(round.index)"
                        >
                        <div v-text="round.title" />
                        <div class="page-admin-estates-item-requests__round-title-icon">
                            <icon name="triangle" />
                        </div>
                    </div>
                    <div class="page-admin-estates-item-requests__round-list">
                        <page-admin-estates-item-requests-round
                            v-if="expandedRounds.includes(round.index)"
                            v-bind:round="round"
                            v-bind:estate-id="estateId"
                        />
                    </div>
                </div>
            </template>
        </template>
    </section>
</template>

<script>
import { mapState } from 'vuex';
import utils from '~/utils';

export default {
    name: 'page-admin-estates-item-requests',
    props: {
        estateId: {
            type: Number,
        },
    },
    data: () => ({
        expandedRounds: [],
    }),
    methods: {
        toggleRound(round) {
            if (this.expandedRounds.includes(round)) {
                this.expandedRounds = this.expandedRounds.filter((item) => item !== round);
            } else {
                this.expandedRounds.push(round);
            }
        },
    },
    computed: {
        ...mapState('admin/estates', [
            'getEstateRoundsIsLoading',
            'getEstateRoundsError',
            'getEstateRoundsResult',
        ]),
        ...mapState('admin/loans', [
            'getLoanIsLoading',
            'getLoanError',
            'getLoanResult',
        ]),
        rounds() {
            if (!this.getEstateRoundsResult) {
                return null;
            }
            return this.getEstateRoundsResult.map(round => {
                const result = {
                    ...round,
                };
                result.title = (round.index + 1) + ' раунд';
                if (round.status === 'not-started') {
                    result.title += ' (не запущен)';
                } else {
                    result.title += ` – ${round.investorsCount} ${utils.common.getDeclension(round.investorsCount, ['инвестор', 'инвестора', 'инвесторов'])}`;
                    if (round.investorsCount > 0) {
                        result.title += ` - ${round.displayMode === 'collecting' ? 'собрано' : 'подписано'} на сумму ${utils.formats.formatNumberWithSpaces(round.balanceToShow, true)}`;
                    }
                }
                return result;
            });
        },
        activeRound() {
            if (!this.rounds || this.rounds.length === 0) {
                return null;
            }
            return this.rounds.find((item) => item.isActive) || this.rounds[this.rounds.length - 1];
        },
    },
    watch: {
        activeRound: {
            handler(newVal) {
                if (newVal) {
                    this.expandedRounds = [ this.activeRound.index ];
                }
            },
            immediate: true,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-admin-estates-item-requests {
    padding-bottom: 80px;
    &__empty {
        .typography-body();

        color: @color-gray-main;
    }
    &__round {
        transition: opacity @duration-fast @easing-default;
        & + & {
            margin-top: 24px;
        }
        &._disabled & {
            &-title {
                color: @color-gray-main;
            }
        }
        &-title {
            .typography-medium();

            display: flex;
            align-items: center;
            width: fit-content;
            padding-bottom: 8px;

            cursor: pointer;
            &-icon {
                margin-left: 8px;

                font-size: 1.8rem;

                transform: rotate(-180deg);

                transition: transform @duration-fast ease-in-out;
                ._expanded & {
                    transform: rotate(0);
                }
            }
        }
    }
}
</style>
