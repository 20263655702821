import moment from 'moment';
import numbro from 'numbro';
import utils from '~/utils';
import config from '~/config';

export const formatNumberWithSpaces = (number, isCurrencySignNeeded = false) => {
    if (typeof number !== 'number') {
        number = parseFloat(number);
    }
    if (isNaN(number)) {
        return number;
    }
    const formattedNumber = numbro(number).format(config.formats.numbers.default);
    const initialCharacter = numbro.languageData().delimiters.thousands;
    const resultCharacter = ' '; // \xa0 ?
    const replacer = new RegExp(initialCharacter, 'g');
    return formattedNumber.replace(replacer, resultCharacter) + (isCurrencySignNeeded ? ' ₽' : '');
};

export const formatDuration = (duration) => {
    const momentDuration = moment.duration(duration);
    const hours = momentDuration.hours();
    const minutes = momentDuration.minutes();
    let result = '';

    if (hours > 0) {
        result += `${hours} ${utils.common.getDeclension(hours, ['час', 'часа', 'часов'])}`;
    }
    if (minutes > 0) {
        result += ` ${minutes} ${utils.common.getDeclension(minutes, ['минута', 'минуты', 'минут'])}`;
    }
    return result;
};

export const formatDate = (date = new Date(), variant = 'long') => {
    const formats = {
        long: 'D MMMM YYYY',
        short: 'DD.MM.YYYY',
    };
    return moment(date).format(formats[variant] || formats.long);
};

export const formatDatesRange = (dateFrom, dateTo) => {
    if (dateFrom === null || dateTo === null) {
        return '';
    }
    let dateFromFormatted = moment(dateFrom).utcOffset(3).format('DD MMMM YYYY');
    const dateToFormatted = moment(dateTo).utcOffset(3).format('DD MMMM YYYY');
    if (dateFromFormatted === dateToFormatted) {
        return dateFromFormatted;
    }
    if (moment(dateFrom).utcOffset(3).format('YYYY') === moment(dateTo).utcOffset(3).format('YYYY')) {
        dateFromFormatted = moment(dateFrom).utcOffset(3).format('DD MMMM');
    }
    return `${dateFromFormatted} ‑ ${dateToFormatted}`;
};

export const formatTimesRange = (timeFrom, timeTo) => {
    if (timeFrom === null || timeTo === null) {
        return '';
    }
    const timeFromFormatted = moment(timeFrom).utcOffset(3).format('HH:mm');
    const timeToFormatted = moment(timeTo).utcOffset(3).format('HH:mm');

    if (timeFromFormatted === timeToFormatted) {
        return timeFromFormatted;
    } else {
        return `${timeFromFormatted} ‑ ${timeToFormatted}`;
    }
};

export const formatPhone = (value, position = null) => {
    if (!value) {
        return position === null ? '' : { value: '', position: 0 };
    }
    let skipPositionAvailable = null;
    let result = value;
    let resultPosition = position;
    if (position !== null) {
        let resultLeft = value.substring(0, position);
        let resultRight = value.substring(position);
        resultLeft = resultLeft.replace(/[^0-9]/g, '');
        resultRight = resultRight.replace(/[^0-9]/g, '');

        result = resultLeft + resultRight;
        if (resultLeft.length === 0) {
            skipPositionAvailable = 'start';
        }
        if (resultRight.length === 0) {
            skipPositionAvailable = 'end';
        }
        resultPosition = resultLeft.length;
    } else {
        result = result.replace(/[^0-9]/g, '');
    }

    while (result.length > 0 && (result[0] === '8' || result[0] === '7')) {
        result = result.substring(1);
        if (skipPositionAvailable === null && resultPosition !== null) {
            resultPosition -= 1;
            if (resultPosition <= 0) {
                resultPosition = 0;
                skipPositionAvailable = 'start';
            }
        }
    }

    result = '+7' + result;

    if (skipPositionAvailable === null && resultPosition !== null) {
        resultPosition += 2;
        if (resultPosition >= result.length) {
            resultPosition = result.length;
            skipPositionAvailable = 'end';
        }
    }

    result = result.substring(0, 12);
    if (skipPositionAvailable === null && resultPosition !== null) {
        if (resultPosition >= 12) {
            resultPosition = 12;
            skipPositionAvailable = 'end';
        }
    }

    let positionOffsetAccumulator = 0;
    const parts = [
        result.substring(0, 2),
        result.substring(2, 5),
        result.substring(5, 8),
        result.substring(8, 10),
        result.substring(10, 12),
    ]
        .filter(x => !!x)
        .map((x, i) => {
            let part = x;
            if (i === 0) {
                return part;
            }
            if (i === 1) {
                part = ' (' + part;
                if (
                    skipPositionAvailable === null &&
                    resultPosition !== null &&
                    resultPosition >= 2
                ) {
                    positionOffsetAccumulator += 2;
                }
                return part;
            }
            if (i === 2) {
                part = ') ' + part;
                if (
                    skipPositionAvailable === null &&
                    resultPosition !== null &&
                    resultPosition >= 5
                ) {
                    positionOffsetAccumulator += 2;
                }
                return part;
            }
            if (i > 2) {
                if (
                    skipPositionAvailable === null &&
                    resultPosition !== null &&
                    resultPosition >= 8 + (i - 3) * 2
                ) {
                    positionOffsetAccumulator += 1;
                }
                part = '-' + part;
                return part;
            }
        });
    result = parts.join('');
    if (skipPositionAvailable === null && resultPosition !== null) {
        resultPosition += positionOffsetAccumulator;
    }

    if (resultPosition === null) {
        return result;
    } else if (skipPositionAvailable === 'start') {
        return { value: result, position: 0 };
    } else if (skipPositionAvailable === 'end') {
        return { value: result, position: result.length };
    }
    return { value: result, position: resultPosition };
};

export default {
    formatNumberWithSpaces,
    formatDuration,
    formatDate,
    formatDatesRange,
    formatPhone,
    formatTimesRange,
};
