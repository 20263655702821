<template>
    <div class="modal-download">
        <div class="modal-download__title"
            v-text="title"
        />
        <div class="modal-download__description"
            v-text="description"
        />
        <div class="modal-download__actions">
            <div class="modal-download__actions-item"
                v-for="(item, index) in actions"
                v-bind:key="index"
                >
                <ui-button
                    v-bind:href="item.link"
                    v-bind:icon-left="'download'"
                    v-bind:is-disabled="!!item.isDisabled"
                    >
                    {{ item.title }}
                </ui-button>
            </div>
        </div>
    </div>
</template>

<script>
import config from '~/config';
import utils from '~/utils';

export default {
    name: 'modal-download',
    props: {
        dataToDownload: {
            type: [ Array, Object ],
        },
        mode: {
            type: String,
            default: 'single-investment-request',
        },
    },
    computed: {
        title() {
            if (this.mode === 'single-investment-request') {
                return `Документы инвестиционной заявки ${this.dataToDownload.id}`;
            }
            if (this.mode === 'investment-round') {
                return `Документы по заявкам раунда ${this.dataToDownload.round}`;
            }
            return 'Скачать документы';
        },
        description() {
            if (this.mode === 'single-investment-request') {
                return `Инвестор: ${this.dataToDownload.applicant.fullName}
Сумма инвестирования: ${this.dataToDownload.amountFormatted}
Дата: ${this.dataToDownload.created}
Счет: ${this.dataToDownload.account}`;
            }
            if (this.mode === 'investment-round') {
                if (this.dataToDownload.investmentAgreementCount === this.dataToDownload.investmentProposalCount &&
                    this.dataToDownload.investmentAgreementCount === this.dataToDownload.transferOrderCount &&
                    this.dataToDownload.investmentAgreementCount === this.dataToDownload.investmentAcceptCount) {
                    return `Всего доступно по ${this.dataToDownload.investmentAgreementCount} ${utils.common.getDeclension(this.dataToDownload.investmentAgreementCount, [ 'документ', 'документа', 'документов' ])} каждого типа:`;
                } else {
                    return `Всего доступно:
Договоры инвестирования: ${this.dataToDownload.investmentAgreementCount} ${utils.common.getDeclension(this.dataToDownload.investmentAgreementCount, [ 'документ', 'документа', 'документов' ])}
Инвестиционные предложения: ${this.dataToDownload.investmentProposalCount} ${utils.common.getDeclension(this.dataToDownload.investmentProposalCount, [ 'документ', 'документа', 'документов' ])}
Передаточные распоряжения: ${this.dataToDownload.transferOrderCount} ${utils.common.getDeclension(this.dataToDownload.transferOrderCount, [ 'документ', 'документа', 'документов' ])}
Акцепты Инвестиционного предложения: ${this.dataToDownload.investmentAcceptCount} ${utils.common.getDeclension(this.dataToDownload.investmentAcceptCount, [ 'документ', 'документа', 'документов' ])}`;
                }
            }
            return 'Скачать все доступные документы';
        },
        actions() {
            const result = [];
            if (this.mode === 'single-investment-request') {
                if (this.dataToDownload.investmentAgreement) {
                    result.push({
                        title: 'Договор инвестирования',
                        link: this.dataToDownload.investmentAgreement,
                    });
                }
                if (this.dataToDownload.investmentProposal) {
                    result.push({
                        title: 'Инвестиционное предложение',
                        link: this.dataToDownload.investmentProposal,
                    });
                }
                if (this.dataToDownload.transferOrder) {
                    result.push({
                        title: 'Передаточное распоряжение',
                        link: this.dataToDownload.transferOrder,
                    });
                }
                if (this.dataToDownload.investmentAccept) {
                    result.push({
                        title: 'Акцепт Инвестиционного предложения',
                        link: this.dataToDownload.investmentAccept,
                    });
                }
            }
            if (this.mode === 'investment-round') {
                result.push({
                    title: 'Договоры инвестирования',
                    link: `${config.api.estatesAdmin.investmentAgreementsArchive.replace('{id}', this.dataToDownload.estate)}${this.dataToDownload.round}/`,
                    isDisabled: !this.dataToDownload.investmentAgreementCount,
                });
                result.push({
                    title: 'Инвестиционные предложения',
                    link: `${config.api.estatesAdmin.investmentProposalsArchive.replace('{id}', this.dataToDownload.estate)}${this.dataToDownload.round}/`,
                    isDisabled: !this.dataToDownload.investmentProposalCount,
                });
                result.push({
                    title: 'Передаточные распоряжения',
                    link: `${config.api.estatesAdmin.transferOrdersArchive.replace('{id}', this.dataToDownload.estate)}${this.dataToDownload.round}/`,
                    isDisabled: !this.dataToDownload.transferOrderCount,
                });
                result.push({
                    title: 'Акцепты Инвестиционного предложения',
                    link: `${config.api.estatesAdmin.investmentAcceptArchive.replace('{id}', this.dataToDownload.estate)}${this.dataToDownload.round}/`,
                    isDisabled: !this.dataToDownload.investmentAcceptCount,
                });
            }
            return result;
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-download {
    .modal();

    &__title {
        .typography-large();

        margin-bottom: 16px;

        text-align: center;
    }
    &__description {
        .typography-small();

        margin-bottom: 24px;

        white-space: pre-line;
    }
    &__actions {
        display: flex;
        width: 100%;
        gap: 8px;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        &-item {
            flex: 0 0 auto;
        }
    }
    @media @media-md-down {
        width: auto;
        max-width: ~'calc(100vw - 40px)';
        max-height: ~'calc(100vh - 80px)';
        padding: 40px 20px;
    }
}
</style>
