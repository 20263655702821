<template>
    <div class="plugin-about-contacts-item">
        <a class="plugin-about-contacts-item__link"
            v-text="text"
            v-bind:href="url"
        />
    </div>
</template>

<script>
export default {
    name: 'plugin-about-contacts-item',
    props: {
        text: {
            type: String,
            default: '',
        },
        url: {
            type: String,
            default: '',
        },
    },
};
</script>

<style lang="less">
@import '~theme';

.plugin-about-contacts-item {
    &:not(:last-child) {
        margin-bottom: 16px;
    }
    &__link {
        .typography-large();

        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    @media @media-sm-down {
        &__link {
            .typography-medium();
        }
    }
}
</style>
