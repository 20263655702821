<template>
    <div>
        plugin-container
    </div>
</template>

<script>
export default {
    name: 'plugin-container',
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-container {
    .container();
}
</style>
