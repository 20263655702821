<template>
    <ui-card-base>
        <article class="page-cabinet-estates-list-cards-item"
            v-bind:class="{ _expanded: isExpanded }"
            >
            <div class="page-cabinet-estates-list-cards-item__header">
                <div class="page-cabinet-estates-list-cards-item__header-image">
                    <common-image
                        v-bind:image="image"
                        v-bind:href="item.url"
                    />
                </div>
                <div class="page-cabinet-estates-list-cards-item__header-info">
                    <div class="page-cabinet-estates-list-cards-item__header-info-title"
                        v-text="item.name"
                    />
                </div>
                <div class="page-cabinet-estates-list-cards-item__header-toggler"
                    v-on:click="toggleCard"
                    >
                    <div class="page-cabinet-estates-list-cards-item__header-toggler-icon">
                        <icon name="triangle" />
                    </div>
                </div>
            </div>
            <div class="page-cabinet-estates-list-cards-item__body"
                v-if="isExpanded"
                >
                <div class="page-cabinet-estates-list-cards-item__body-main">
                    <div class="page-cabinet-estates-list-cards-item__body-main-item"
                        v-for="(share, index) in item.shares"
                        v-bind:key="index"
                        >
                        <page-cabinet-estates-list-cards-item-share
                            v-bind:fields="fields"
                            v-bind:item="share"
                        />
                    </div>
                </div>
            </div>
            <div class="page-cabinet-estates-list-cards-item__additional">
                <div class="page-cabinet-estates-list-cards-item__additional-item"
                    v-for="(stat, i) in stats"
                    v-bind:key="i"
                    >
                    <div class="page-cabinet-estates-list-cards-item__additional-item-title"
                        v-text="stat.title + ':'"
                    />
                    <div class="page-cabinet-estates-list-cards-item__additional-item-value"
                        v-text="stat.value"
                    />
                    <div class="page-cabinet-estates-list-cards-item__additional-item-delta"
                        v-if="stat.delta"
                        v-bind:class="{ _negative: stat.delta < 0 }"
                        v-text="stat.delta + '%'"
                    />
                </div>
            </div>
            <div class="page-cabinet-estates-list-cards-item__actions"
                v-if="isSaleAvailable"
                >
                <div class="page-cabinet-estates-list-cards-item__actions-sale">
                    <ui-button
                        v-on:click="saleHandler"
                        text="Выставить на продажу"
                        theme="secondary-1"
                    />
                </div>
            </div>
        </article>
    </ui-card-base>
</template>

<script>
import utils from '~/utils';

export default {
    name: 'page-cabinet-estates-list-cards-item',
    props: {
        item: {
            type: Object,
        },
        fields: {
            type: Array,
        },
    },
    data: () => ({
        isExpanded: false,
    }),
    computed: {
        image() {
            return utils.common.injectResize(this.item.image, '104x104');
        },
        stats() {
            const result = [
                {
                    title: 'Рыночная стоимость акции',
                    value: this.item.marketPriceFormatted,
                    delta: null,
                },
            ];
            if (this.item.dividends > 0) {
                result.push({
                    title: 'Выплачено дивидендов',
                    value: utils.formats.formatNumberWithSpaces(this.item.dividends),
                    delta: null,
                });
            }
            return result;
        },
        isSaleAvailable() {
            return this.item.quantity > 0 && this.item.idForSale !== null;
        },
    },
    methods: {
        toggleCard() {
            this.isExpanded = !this.isExpanded;
        },
        saleHandler() {
            this.$store.commit('modals/push', {
                name: 'market-application-create',
                props: {
                    givenShareId: this.item.idForSale,
                },
            });
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-estates-list-cards-item {
    &__header {
        position: relative;

        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        &-image {
            flex: 0 0 auto;
            width: 52px;
            height: 52px;
            margin-right: 16px;
        }
        &-info {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding-right: 34px;
            &-type {
                .typography-caption();

                padding: 5px 8px 3px;
                margin-bottom: 4px;
                border-radius: @border-radius-lg;

                font-weight: 700;
                text-transform: uppercase;

                background-color: @color-gray-light;
            }
            &-title {
                .typography-heading();
            }
        }
        &-toggler {
            position: absolute;
            top: -16px;
            right: -16px;

            display: flex;
            align-items: center;
            justify-content: center;
            padding: 16px;

            cursor: pointer;
            &-icon {
                font-size: 1.8rem;

                transform: rotate(-180deg);

                transition: transform @duration-fast ease-in-out;
                ._expanded & {
                    transform: rotate(0);
                }
            }
        }
    }
    &__body {
        margin-top: 16px;
        &-main {
            &-item {
                & ~ & {
                    padding-top: 16px;
                    border-top: 1px solid @color-gray-light;
                    margin-top: 16px;
                }
            }
        }
    }
    &__additional {
        padding-top: 24px;
        border-top: 1px solid @color-gray-light;
        margin-top: 24px;
        &-item {
            .typography-body();

            display: flex;
            align-items: baseline;
            justify-content: flex-start;
            &:not(:last-child) {
                margin-bottom: 4px;
            }
            &-title {
                color: @color-gray-main;
                margin-right: 4px;
            }
            &-value {
                margin-right: 4px;
            }
            &-delta {
                .typography-caption();

                padding: 3px 4px 1px;
                margin-bottom: 4px;
                border-radius: @border-radius-lg;

                color: @color-positive;
                font-weight: 700;
                text-transform: uppercase;

                background-color: fade(@color-positive, 10%);
                &._negative {
                    color: @color-accent-negative;

                    background-color: fade(@color-accent-negative, 10%);
                }
            }
        }
    }
    &__actions {
        margin-top: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        &-sale {
            flex: 0 0 auto;
        }
    }
}
</style>
