<template>
    <div class="ui-input-password"
        v-bind:class="{ _focused: isFocused }"
        >
        <div class="ui-input-password__input">
            <ui-input-base-text
                v-bind:is-invalid="isInvalid"
                v-bind="modelEnriched"
                v-bind:formatter="formatter"
                v-on:input="inputHandler"
                v-on:focus="focusHandler"
                v-on:blur="blurHandler"
            />
        </div>
        <div class="ui-input-password__visibility"
            v-on:click="isVisible = !isVisible"
            >
            <icon name="eye" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ui-input-password',
    props: {
        model: {
            type: Object,
        },
        isInvalid: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [ String, Number ],
        },
    },
    model: {
        prop: 'value',
        event: 'input',
    },
    data: () => ({
        isFocused: false,
        isVisible: false,
    }),
    computed: {
        formatter() {
            if (this.model.maxLength) {
                return (newValue) => newValue.slice(0, this.model.maxLength);
            }
            return null;
        },
        modelEnriched() {
            if (!this.isVisible) {
                return this.model;
            } else {
                return {
                    ...this.model,
                    type: 'text',
                };
            }
        },
    },
    methods: {
        inputHandler(newValue) {
            this.$emit('input', newValue);
        },
        focusHandler(data) {
            this.isFocused = true;
            this.$emit('focus', data);
        },
        blurHandler(data) {
            this.isFocused = false;
            this.$emit('blur', data);
        },
    },
};
</script>

<style scoped lang="less">
@import "~theme";

.ui-input-password {
    position: relative;
    width: 100%;
    &__input {
        flex: 1 1 auto;
    }
    &__visibility {
        position: absolute;
        bottom: 8px;
        right: 0;

        flex: 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 0 2px 2px;

        font-size: 1.8rem;

        background-color: @color-gray-lightest;

        cursor: pointer;
        // border: 1px solid @color-light;
        // border-radius: 0.25rem;
        // cursor: pointer;
        // &:hover {
        //     background-color: @color-light-hover;
        //     border-color: @color-light-hover;
        // }
    }
    &._focused {
        .ui-input-password__visibility {
            color: @color-primary;
        }
    }
}
</style>
