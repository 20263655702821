import config from '~/config';
import utils from '~/utils';
import client from '../_client';
import transforms from './transforms';

export const checkCode = (data = {}) => {
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.referral.checkCode,
        method: 'POST',
        data,
    });
};

export const createReferrer = (data = {}) => {
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.referral.createReferrer,
        method: 'POST',
        data,
    });
};

export const getReferrals = (params = {}) => {
    return client.request({
        url: config.api.referral.getReferrals,
        method: 'GET',
        params,
        transformData: transforms.responses.getReferrals,
    });
};
