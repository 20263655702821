<template>
    <ui-input-base-text
        v-bind:formatter="formatDown"
        v-bind:is-invalid="isInvalid"
        v-bind="formattedModel"
        v-bind:type="'tel'"
        v-on:input="inputHandler"
        v-on:focus="focusHandler"
        v-on:blur="blurHandler"
        inputmode="numeric"
        pattern="\\d*"
    />
</template>

<script>
export default {
    name: 'ui-input-float',
    props: {
        model: {
            type: Object,
        },
        isInvalid: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [ String, Number ],
        },
    },
    model: {
        prop: 'value',
        event: 'input',
    },
    computed: {
        formattedModel() {
            return { ...this.model, value: this.formatDown(this.model.value) };
        },
    },
    methods: {
        formatDown(value) {
            let result = value ? value.toString() : '';
            if (result.length === 0) {
                return '';
            }
            result = result.replace(/[^0-9.]/g, '');
            result = result.replace(/\./g, (c, i, text) => text.indexOf(c) === i ? c : '');
            result = result.replace(/^(0)\1+/g, '0');
            let floatPointIndex = result.indexOf('.');
            if (floatPointIndex === 0) {
                result = '0' + result;
                floatPointIndex++;
            }
            const isDotNeeded = result[result.length - 1] === '.';
            if (result[0] === '0' && result.length > 1) {
                if (floatPointIndex > 1) {
                    result = result.substring(1);
                    floatPointIndex--;
                } else if (floatPointIndex === -1) {
                    result = '0.' + result.substring(1);
                    floatPointIndex = 1;
                }
            }
            let integerPart = floatPointIndex > -1 ? result.substring(0, floatPointIndex) : result;
            let floatPart = floatPointIndex > -1 ? result.substring(floatPointIndex + 1) : '';
            // backend restriction: no more than 10 digits before '.'
            if (integerPart.length > 10 && floatPointIndex !== 10) {
                floatPart = integerPart.substring(10) + floatPart;
                integerPart = integerPart.substring(0, 10);
            }
            // backend restriction: no more than 2 digits after '.'
            if (floatPart.length > 2) {
                floatPart = floatPart.substring(0, 2);
            }
            result = `${integerPart}${floatPart || isDotNeeded ? '.' : ''}${floatPart}`;
            if (this.model.maxLength) {
                result = result.substring(0, this.model.maxLength);
            }
            return result;
        },
        formatUp(value) {
            return value;
        },
        inputHandler(newValue) {
            this.$emit('input', this.formatUp(newValue));
        },
        focusHandler(data) {
            this.$emit('focus', data);
        },
        blurHandler(data) {
            this.$emit('blur', data);
        },
    },
    mounted() {
        if (this.value?.toString() !== this.formatUp(this.formattedModel.value)) {
            this.$emit('input', this.formatUp(this.formattedModel.value));
        }
    },
};
</script>

<style scoped lang="less">
@import "~theme";
</style>
