<template>
    <div class="modal-market-application-create-objects-item"
        v-bind:class="{ _active: isActive }"
        >
        <div class="modal-market-application-create-objects-item__image">
            <common-image
                v-bind:image="image"
                v-bind:href="item.estate.url"
            />
        </div>
        <div class="modal-market-application-create-objects-item__info">
            <div class="modal-market-application-create-objects-item__info-title"
                v-text="item.estate.title"
            />
            <div class="modal-market-application-create-objects-item__info-available"
                v-if="item.userSharesAvailable > 0"
                v-text="'Можно продать: ' + item.userSharesAvailable"
            />
        </div>
    </div>
</template>

<script>
import utils from '~/utils';

export default {
    name: 'modal-market-application-create-objects-item',
    props: [ 'item', 'isActive' ],
    computed: {
        image() {
            return utils.common.injectResize(this.item.estate.image, '96x96');
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-market-application-create-objects-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &:not(._active) {
        cursor: pointer;
    }
    &__image {
        flex: 0 0 auto;
        width: 48px;
        height: 48px;
        border-radius: @border-radius-md;
        margin-right: 16px;
        filter: grayscale(100);
        ._active > & {
            box-shadow: 0 0 0 2px @color-gray-darkest;
            filter: grayscale(0);
        }
    }
    &__info {
        flex: 1 1 auto;

        opacity: 0.5;
        ._active > & {
            opacity: 1;
        }
        &-title {
            .typography-heading();
        }
        &-available {
            .typography-caption();

            font-weight: bold;
            ._active & {
                color: @color-gray-main;
            }
        }
    }
}
</style>
