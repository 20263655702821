<template>
    <div class="plugin-referral-offers">
        <div class="plugin-referral-offers__title"
             v-if="title"
             v-html="title"
        />
        <div class="plugin-referral-offers__items">
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'plugin-referral-offers',
    props: {
        title: {
            type: String,
            default: null,
        },
    },
};
</script>

<style lang="less">
@import '~theme';

.plugin-referral-offers {
    &__title {
        .typography-medium();

        color: rgba(249,249,252,0.5);

        margin-bottom: 40px;
        margin-top: 160px;
    }

    &__items {
        display: grid;

        grid-template-columns: repeat(2, minmax(530px, 1fr));
        grid-template-rows: repeat(2, minmax(300px, 1fr));

        column-gap: 64px;
        row-gap: 56px;
    }

    @media @media-lg-down {
        &__items {
            grid-template-columns: repeat(2, minmax(420px, 1fr));
            column-gap: 40px;
        }
    }

    @media @media-md-down {
        &__items {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr auto auto;
            column-gap: 0;
            row-gap: 32px;
        }
    }

    @media @media-sm-down {

    }
}
</style>
