<template>
    <section class="layout-documents">
        <h1 class="layout-documents__headline"
            v-text="title"
        />
        <section class="layout-documents__content">
            <slot />
        </section>
    </section>
</template>

<script>
export default {
    name: 'layout-documents',
    props: {
        title: {
            type: String,
            default: 'Документы',
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.layout-documents {
    flex: 1 1 auto;
    &__headline {
        .container();
        .container-paddings();
        .typography-extra-large();

        padding-top: 40px;
        padding-bottom: 40px;
        margin: 0;

        background-color: @color-gray-lighter;
    }

    &__content {
        .container();
        .container-paddings();

        padding-top: 40px;
    }
    @media @media-md-down {
        &__headline {
            .typography-large();

            padding-top: 24px;
            padding-bottom: 24px;
        }
        &__content {
            padding-top: 24px;
        }
    }
}
</style>
