<template>
    <div class="page-cabinet-requests-item-success">
        <div class="page-cabinet-requests-item-success__title">
            Документы успешно подписаны!
        </div>
        <div class="page-cabinet-requests-item-success__description">
            Теперь осталось дождаться, когда все инвесторы примут инвестиционное предложение и объект будет куплен.
            <br />
            Вы можете отслеживать статус объекта во вкладке "Заявки".
            <br />
            Все документы сохранены в личном кабинете во вкладке "Документы".
        </div>
        <div class="page-cabinet-requests-item-success__buttons">
            <div class="page-cabinet-requests-item-success__buttons-item">
                <ui-button
                    v-bind:href="urls.documents"
                    theme="secondary-2"
                    >
                    Просмотреть Документы
                </ui-button>
            </div>
            <div class="page-cabinet-requests-item-success__buttons-item">
                <ui-button
                    v-bind:href="urls.requests"
                    theme="primary"
                    >
                    К моим заявкам
                </ui-button>
            </div>
        </div>
    </div>
</template>

<script>
import config from '~/config';

export default {
    name: 'page-cabinet-requests-item-success',
    data: () => ({
        urls: {
            documents: config.urls.cabinet.documents,
            requests: config.urls.cabinet.requests,
        },
    }),
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-requests-item-success {
    margin: 40px auto auto;

    text-align: center;
    &__title {
        .typography-large();

        margin-bottom: 16px;
    }
    &__description {
        .typography-body();

        max-width: 440px;
        margin: 0 auto 40px;
    }
    &__buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        &-item {
            flex: 0 0 auto;
            padding: 0 12px;
        }
    }
    @media @media-xs-down {
        &__buttons {
            flex-direction: column;
            align-items: stretch;
            &-item {
                padding: 8px 0;
            }
        }
    }
}
</style>
