<template>
    <section class="page-market">
        <section class="page-market__headline">
            <h1 class="page-market__headline-title">
                Вторичный рынок
            </h1>
        </section>
        <section class="page-market__list">
            <div class="page-market__list-content">
                <div class="page-market__list-content-item"
                    v-for="(item, index) in accumulatedItems"
                    v-bind:key="index"
                    >
                    <page-market-item
                        v-bind:item="item"
                        v-on:reload="reloadShares"
                    />
                </div>
            </div>
            <div class="page-market__list-loader"
                v-if="isLoading">
                <ui-loader
                    v-bind:fixed="50"
                    v-bind:centered="true"
                />
            </div>
            <div class="page-market__list-buttons"
                v-if="!isLoading && pagingTotal > pagingCurrent"
                >
                <div class="page-market__list-buttons-item"
                    v-on:click="getMore"
                    >
                    <ui-trigger variant="dark" is-uppercase>
                        Показать еще
                    </ui-trigger>
                </div>
            </div>
        </section>
        <section class="page-market__commit">
            <div class="page-market__commit-content">
                <div class="page-market__commit-content-title">
                    Не нашли интересующий вас объект?
                </div>
                <div class="page-market__commit-content-subtitle">
                    Оставьте заявку, и когда подходящий объект появится, вы узнаете об этом первым
                </div>
                <div class="page-market__commit-content-button">
                    <ui-button theme="primary"
                        v-on:click="showPopup"
                        >
                        Оставить заявку
                    </ui-button>
                </div>
            </div>
        </section>
    </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'page-market',
    data: () => ({
        pagingSize: 1000,
        pagingCurrent: 1,
        accumulatedItems: [],
    }),
    computed: {
        ...mapState('market', {
            items: state => state.getActiveSharesResult,
            isLoading: state => state.getActiveSharesIsLoading,
            error: state => state.getActiveSharesError,
            totalItems: state => state.getActiveSharesCount,
        }),
        ...mapState('market', [
            'createOrderResult',
            'createOrderIsLoading',
        ]),
        pagingTotal() {
            return this.totalItems / this.pagingSize;
        },
        isAuthorized() {
            return this.$store.getters['users/isAuthorized'];
        },
        isVerified() {
            return this.$store.getters['users/isVerified'];
        },
    },
    methods: {
        getItems() {
            this.$store.dispatch('market/getActiveShares', {
                page: this.pagingCurrent,
                page_size: this.pagingSize,
            });
        },
        getMore() {
            this.pagingCurrent += 1;
            this.getItems();
        },
        showPopup() {
            if (this.isAuthorized) {
                if (this.isVerified) {
                    this.showPopupCreateOrder();
                } else {
                    this.showPopupVerification();
                }
            } else {
                this.showPopupAuth();
            }
        },
        showPopupAuth() {
            this.$store.commit('modals/push', 'auth-required');
        },
        showPopupVerification() {
            this.$store.commit('modals/push', 'verification-required');
        },
        showPopupCreateOrder() {
            this.$store.commit('modals/push', 'market-application-create');
        },
        reloadShares() {
            this.pagingCurrent = 1;
            this.accumulatedItems = [];
            this.getItems();
            this.$store.commit('market/resetGetOrdersByShare');
            this.$store.commit('market/resetGetShareById');
        },
    },
    mounted() {
        if (!this.items) {
            this.getItems();
        }
    },
    watch: {
        items(newItems) {
            if (newItems) {
                this.accumulatedItems.push(...newItems);
            }
        },
        createOrderIsLoading(newVal) {
            // if an application appears for some share not presented yet
            // trigger reloading all shares
            if (!newVal && this.createOrderResult && this.items &&
                !this.items.some(item => item.id === this.createOrderResult.share)) {
                this.reloadShares();
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-market {
    padding-top: 10px;
    padding-bottom: 40px;

    background-color: @color-gray-lighter;
    &__headline {
        padding: 40px 0;
        margin-bottom: 40px;

        background-color: @color-gray-lightest;
        &-title {
            .container();
            .typography-extra-large();

            margin: 0;
        }
    }
    &__list {
        .container();
        &-content {
            margin-bottom: 24px;
            &-item {
                & ~ & {
                    margin-top: 24px;
                }
            }
        }
        &-buttons {
            display: flex;
            align-content: center;
            justify-content: center;
            &-item {
                .typography-body();
            }
        }
    }
    &__commit {
        .container();
        .container-paddings();
        &-content {
            padding: 60px 115px 64px;
            border-radius: @border-radius-lg;

            background-color: @color-gray-lightest;
            background-image: url('/static/images/page-market-create-bg.jpg');
            background-size: auto 100%;
            background-position-x: calc(100% - 115px);
            background-position-y: bottom;
            background-repeat: no-repeat;
            &-title {
                .typography-large();

                max-width: 550px;
                margin-bottom: 16px;
            }
            &-subtitle {
                .typography-small();

                max-width: 550px;
                margin-bottom: 40px;
            }
            &-button {
                width: 207px;
            }
        }
    }
    @media @media-lg-down {
        &__commit {
            &-content {
                &-title {
                    max-width: 420px;
                }
                &-subtitle {
                    max-width: 420px;
                }
            }
        }
    }
    @media @media-md-down {
        &__headline {
            padding: 24px 0;
            margin-bottom: 24px;
            &-title {
                .container();
                .typography-large();

                text-align: center;
            }
        }
        &__commit {
            &-content {
                padding: 24px 16px 194px;

                background-size: auto 230px;
                background-position-x: center;
                background-position-y: bottom;
                &-title {
                    .typography-medium();
                }
                &-subtitle {
                    margin-bottom: 24px;
                }
                &-button {
                    width: 100%;
                    max-width: 320px;
                    margin: 0 auto;
                }
            }
        }
    }
}
</style>
