<template>
    <section class="page-admin-estates-item-shareholders">
        <div class="page-admin-estates-item-shareholders__empty"
            v-if="!isLoading && items.length === 0"
            v-text="'Заявок не найдено'"
        />
        <div class="page-admin-estates-item-shareholders__header"
            v-if="!isLoading && items.length > 0"
            >
            <div class="page-admin-estates-item-shareholders__header-date"
                v-text="'Дата'"
            />
            <div class="page-admin-estates-item-shareholders__header-info"
                v-text="'ФИО / номер счета'"
            />
            <div class="page-admin-estates-item-shareholders__header-quantity"
                v-text="'Акции, шт.'"
            />
            <!-- <div class="page-admin-estates-item-shareholders__header-file" /> -->
        </div>
        <div class="page-admin-estates-item-shareholders__list"
            v-bind:class="{ _loading: isLoading }"
            >
            <div class="page-admin-estates-item-shareholders__list-item"
                v-for="(item, index) in items"
                v-bind:key="index"
                >
                <page-admin-estates-item-shareholders-item
                    v-bind:item="item"
                />
            </div>
        </div>
    </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'page-admin-estates-item-shareholders',
    computed: {
        ...mapState('admin/estates', [
            'getEstateSharesIsLoading',
            'getEstateSharesResult',
            'getEstateSharesError',
        ]),
        items() {
            if (this.isLoading) {
                return [];
            }
            return this.getEstateSharesResult.items;
        },
        isLoading() {
            return this.getEstateSharesIsLoading;
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-admin-estates-item-shareholders {
    &__empty {
        .typography-body();

        color: @color-gray-main;
    }
    &__header {
        .typography-caption();

        display: flex;
        align-items: center;
        padding: 16px;

        color: @color-gray-main;

        text-transform: uppercase;
        &-date {
            flex: 0 0 100px;
        }
        &-info {
            flex: 1 1 auto;
        }
        &-quantity {
            flex: 0 0 173px;
        }
        &-file {
            flex: 0 0 18px;
        }
    }
    &__list {
        transition: opacity @duration-fast @easing-default;
        &._loading {
            opacity: 0.3;
            pointer-events: none;
        }
        &-item {
            margin-bottom: 8px;
        }
    }
    @media @media-sm-down {
        &__header {
            display: none;
        }
    }
}
</style>
