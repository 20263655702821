<template>
    <div class="plugin-referral-solutions-item">
        <div class="plugin-referral-solutions-item__title"
             v-if="title"
             v-html="title"
        />
        <div class="plugin-referral-solutions-item__description"
             v-if="description"
             v-html="description"
        />
    </div>
</template>

<script>
export default {
    name: 'plugin-referral-solutions-item',
    props: {
        title: {
            type: String,
            default: null,
        },
        description: {
            type: String,
            default: null,
        },
    },
};
</script>

<style lang="less">
@import '~theme';

.plugin-referral-solutions-item {
    display: grid;

    &__title {
        .typography-extra-large();

        color: rgba(255, 255, 255, 0.6);

        margin-bottom: 16px;

        span {
            color: @color-gray-lightest;
        }
    }

    &__description {
        .typography-small();

        color: rgba(255, 255, 255, 0.6);

        p {
            margin: 0;
        }
    }

    @media @media-md-down {
        padding: 0;

        &__title {
            .typography-large();
        }

        &__description {
            .typography-body();
        }
    }
}
</style>
