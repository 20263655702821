var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"page-estates-item-prefooter"},[_c('div',{staticClass:"page-estates-item-prefooter__title",domProps:{"textContent":_vm._s(_vm.estate.title)}}),_c('div',{staticClass:"page-estates-item-prefooter__cards"},[_c('div',{staticClass:"page-estates-item-prefooter__cards-item"},[_c('common-card-dictionary',{attrs:{"card-props":{ withShadow: true },"items":[
                    {
                        title: 'Общая доходность (факт./прогн.)',
                        value: _vm.profitabilityFormatted,
                    },
                    {
                        title: 'Дивидендная доходность (факт./прогн.)',
                        value: _vm.dividendProfitabilityFormatted,
                    },
                    {
                        title: 'Минимальная сумма инвестиций',
                        value: _vm.formatNumber(_vm.estate.minimumInvestment) + ' ' + _vm.currency.name.short,
                    } ]}})],1),(_vm.estate.state !== 'sold')?_c('div',{staticClass:"page-estates-item-prefooter__cards-item"},[_c('ui-card-base',{attrs:{"with-shadow":""}},[_c('div',{staticClass:"page-estates-item-prefooter__progress"},[_c('common-investment-campaign-progress',{attrs:{"items":_vm.estate.investmentCampaigns,"is-visually-completed":_vm.estate.state === 'completed'}})],1)])],1):_vm._e()]),_c('div',{staticClass:"page-estates-item-prefooter__button"},[(_vm.estate.state === 'collecting')?_c('ui-button',{attrs:{"theme":"primary","size":"lg"},on:{"click":function($event){return _vm.$emit('invest')}}},[_vm._v(" Оставить заявку ")]):_c('ui-button',{attrs:{"href":_vm.urls.estatesList,"type":"button","size":"lg"}},[_vm._v(" К списку объектов ")])],1),_c('div',{staticClass:"page-estates-item-prefooter__phone"},[_c('a',{staticClass:"page-estates-item-prefooter__phone-link",attrs:{"href":'tel:' + _vm.phone.value},domProps:{"textContent":_vm._s(_vm.phone.valueFormatted)}}),_c('div',{staticClass:"page-estates-item-prefooter__phone-hint"},[_vm._v(" По всем вопросам звоните ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }