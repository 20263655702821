<template>
    <label class="ui-input-base-select"
        v-bind:class="{
            _active: isActive,
            _focused: isFocused,
            _invalid: isInvalid,
            _disabled: isDisabled,
            _list: isList,
        }"
        tabindex="0"
        v-on:focus="customFocusHandler"
        v-on:blur="customBlurHandler"
        v-on:keydown="keydownHandler"
        >
        <div class="ui-input-base-select__custom">
            <div class="ui-input-base-select__custom-trigger"
                v-on:click="toggle"
                >
                <div class="ui-input-base-select__custom-trigger-icon"
                    v-if="icon"
                    >
                    <icon v-bind:name="icon" />
                </div>
                <div class="ui-input-base-select__custom-trigger-text"
                    v-text="triggerText"
                />
                <div class="ui-input-base-select__custom-trigger-triangle">
                    <icon name="triangle" />
                </div>
            </div>
            <div class="ui-input-base-select__custom-options">
                <div class="ui-input-base-select__custom-options-item"
                    v-for="(option, index) in options"
                    v-bind:class="{ _active: option.value === value }"
                    v-bind:key="index"
                    v-bind:value="option.value"
                    v-text="option.text"
                    v-on:click="option.isDisabled ? null : customOptionClickHandler(option.value)"
                    v-bind:disabled="option.isDisabled"
                    >
                </div>
            </div>
        </div>
        <div class="ui-input-base-select__native">
            <select class="ui-input-base-select__native-select"
                v-bind:value="value"
                v-bind:placeholder="placeholder"
                v-bind:autofocus="isAutofocus"
                v-bind:disabled="isDisabled"
                v-on:change="changeHandler"
                ref="input"
                tabindex="-1"
                v-on:focus="focusHandler"
                v-on:blur="blurHandler"
                >
                <option
                    v-if="placeholder !== undefined"
                    v-bind:text="placeholder"
                    value=""
                    disabled
                />
                <!-- v-for="(option, index) in (sortingKey !== null ? sortedOptions : options)" -->
                <option
                    v-for="(option, index) in options"
                    v-bind:key="index"
                    v-bind:value="option.value"
                    v-text="option.text"
                />
            </select>
            <div class="ui-input-base-select__native-triangle">
                <icon name="triangle" />
            </div>
        </div>
    </label>
</template>

<script>
export default {
    name: 'ui-input-base-select',
    props: {
        value: {
            type: [ String, Number ],
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        isAutofocus: {
            type: Boolean,
            default: false,
        },
        isInvalid: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        isList: {
            type: Boolean,
            default: false,
        },
        onChange: {
            type: Function,
            default: null,
        },
        onFocus: {
            type: Function,
            default: null,
        },
        onBlur: {
            type: Function,
            default: null,
        },
        errors: {
            type: Object,
            default: null,
        },
        options: {
            type: Array,
            dafault: () => [],
        },
        icon: {
            type: String,
            default: null,
        },
    },
    model: {
        prop: 'value',
        event: 'change',
    },
    data: () => ({
        isFocused: false,
        isActive: false,
        // sortingKey: null,
    }),
    computed: {
        triggerText() {
            if (this.value && this.options) {
                const option = this.options.find(x => x.value === this.value);
                return option ? option.text : '';
            } else {
                return this.placeholder ? this.placeholder : '';
            }
        },
        // sortedOptions() {
        //     if (this.sortingKey === null) {
        //         return this.options;
        //     }
        //     const result = [ ...this.options ];
        //     result.sort((a, b) => b.text.toLowerCase().indexOf(this.sortingKey) - a.text.toLowerCase().indexOf(this.sortingKey));
        //     console.log(this.sortingKey, result);
        //     return result;
        // },
    },
    methods: {
        close() {
            this.isActive = false;
        },
        open() {
            if (this.isDisabled) {
                return;
            }
            this.isActive = true;
            let stid = setTimeout(() => {
                document.addEventListener('click', this.close);
                clearTimeout(stid);
                stid = null;
            }, 100);
        },
        toggle() {
            if (this.isActive) {
                this.close();
            } else {
                this.open();
            }
        },
        customOptionClickHandler(optionCode) {
            if (this.isDisabled) {
                return;
            }
            this.$emit('change', optionCode);
        },
        keydownHandler(e) {
            if (this.isDisabled) {
                return;
            }
            const key = e.key.toLowerCase();
            if (key === 'tab') {
                this.close();
            }
            // if (e.key.length === 1 && (this.isActive || this.isFocused)) {
            //     this.sortingKey = e.key.toLowerCase();
            // }
        },
        focusHandler(e) {
            if (this.isDisabled) {
                return;
            }
            if (this.onFocus !== null) {
                this.onFocus(e);
            }
            this.$emit('focus', e);
            this.isFocused = true;
        },
        blurHandler(e) {
            if (this.isDisabled) {
                return;
            }
            if (this.onBlur !== null) {
                this.onBlur(e);
            }
            this.$emit('blur', e);
            this.isFocused = false;
        },
        changeHandler(e) {
            if (this.isDisabled) {
                return;
            }
            if (this.onChange !== null) {
                this.onChange(e);
            }
            this.$emit('change', e.target.value);
        },
        customFocusHandler() {
            if (this.isDisabled) {
                return;
            }
            this.focusHandler({ target: { value: this.value } });
        },
        customBlurHandler() {
            if (this.isDisabled) {
                return;
            }
            this.blurHandler({ target: { value: this.value } });
        },
    },
    mounted() {
        if (this.isDisabled) {
            return;
        }
        if (this.isAutofocus) {
            this.$refs.input.focus();
        }
    },
    watch: {
        value() {
            this.isActive = false;
            // this.sortingKey = null;
        },
        isActive(newVal) {
            if (!newVal) {
                document.removeEventListener('click', this.close);
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-input-base-select {
    .typography-body();

    position: relative;

    display: block;
    --base-height: 32px;
    width: 100%;
    height: var(--base-height);

    outline: none;
    &._disabled {
        opacity: 0.5;
        pointer-events: none;
    }
    &__custom {
        position: relative;

        width: 100%;
        height: 100%;

        white-space: nowrap;
        &-trigger {
            position: absolute;
            top: 0;
            left: 0;

            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            height: 100%;
            padding: 6px 0 3px;
            border-bottom: 1px solid @color-gray-main;

            color: @color-gray-darker;
            text-decoration: none;

            background: @color-gray-lightest;

            appearance: none;
            resize: none;

            transition-property: border-color, color;
            transition-duration: @duration-fast;
            transition-timing-function: ease-in-out;

            cursor: pointer;
            .ui-input-base-select._list & {
                border-bottom-color: @color-gray-lightest;
                padding-right: 26px;
            }
            &-icon {
                margin-right: 8px;

                font-size: 1.6rem;
            }
            &-text {
                margin-right: 18px;
                overflow: hidden;

                text-overflow: ellipsis;
                .ui-input-base-select._list & {
                    margin-right: 0;
                    overflow: unset;

                    text-decoration: underline;
                }
            }
            &-triangle {
                position: absolute;
                right: 0;
                top: 9px;

                font-size: 18px;

                transform: scaleY(-1);
            }
        }
        &-options {
            position: absolute;
            top: 100%;
            left: 0;

            display: none;
            min-width: 100%;
            max-height: calc(var(--base-height) * 10);
            border-radius: 0 0 @border-radius-lg @border-radius-lg;
            flex-direction: column;
            align-items: stretch;
            justify-self: start;
            overflow: auto;

            background: @color-gray-lightest;

            box-shadow: 0px 4px 10px rgba(33, 28, 64, 0.1);
            &-item {
                flex: 1 0 auto;
                height: var(--base-height);
                padding: 4px 11px;

                line-height: 30px;

                cursor: pointer;
                &:hover {
                    background-color: @color-gray-lighter;
                }
                &._active {
                    background-color: @color-gray-light;
                }
            }
        }
        :focus > & &-trigger,
        ._active > & &-trigger,
        ._focused > & &-trigger {
            border-color: @color-primary;
        }
        ._positive > & &-trigger {
            border-color: @color-positive;

            color: @color-positive;
        }
        ._invalid > & &-trigger {
            border-color: @color-accent-negative;
        }
        ._active > & &-options {
            display: flex;
        }
        ._active > & &-trigger-icon {
            transform: scaleY(1);
        }
    }
    &__native {
        .typography-body();

        position: relative;

        width: 100%;
        height: 100%;

        opacity: 0;
        pointer-events: none;
        .ui-input-base-select._list & {
            padding-left: 24px;
            padding-right: 26px;
        }
        &-select {
            width: 100%;
            height: 100%;

            appearance: none;
            border-radius: 0;
            & > option {
                .typography-body();
            }
        }
        &-triangle {
            position: absolute;
            right: 0;
            top: 9px;

            font-size: 18px;

            transform: scaleY(-1);
            pointer-events: none;
        }
        &-select:focus:active + &-triangle {
            transform: scaleY(1);
        }
    }
    &._transparent {
        background: transparent;
    }
    @media @media-sm-down {
        &._list {
            width: calc(100% + 50px);
        }
        &._list & {
            &__custom {
                position: absolute;
                top: 0;
                left: 0;

                pointer-events: none;
            }
            &__native {
                pointer-events: all;
                cursor: pointer;
                padding: 0;
                &-triangle {
                    display: none;
                }
            }
        }
        &:not(._list) & {
            &__custom {
                z-index: 2;

                opacity: 0;
                pointer-events: none;
                &-options,
                ._active > & &-options {
                    display: none;
                }
                ._active > & &-trigger-icon {
                    transform: scaleY(-1);
                }
            }
            &__native {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;

                opacity: 1;
                pointer-events: all;
                cursor: pointer;
                &-select {
                    border: none;
                    border-bottom: 1px solid @color-gray-main;
                }
            }
        }
    }
}
</style>
