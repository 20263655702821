<template>
    <section class="page-estates-list">
        <section class="page-estates-list__headline">
            <h1 class="page-estates-list__headline-title">
                Объекты SimpleEstate
            </h1>
        </section>
        <section class="page-estates-list__list">
            <div class="page-estates-list__list-content">
                <div class="page-estates-list__list-content-item"
                    v-if="isAnySubscriptionAvailable"
                    >
                    <page-estates-list-form />
                </div>
                <div class="page-estates-list__list-content-item"
                    v-for="(item, index) in accumulatedItems"
                    v-bind:key="index"
                    >
                    <page-estates-list-subscription
                        v-if="item.isSubscription"
                    />
                    <common-card-estate
                        v-else
                        v-bind:item="item"
                        v-bind:resizes="{
                            1920: 24,
                            1599: 35,
                            1279: 30,
                            1023: 46,
                            767: 100,
                        }"
                    />
                </div>
            </div>
            <div class="page-estates-list__list-loader"
                v-if="isLoading"
                >
                <ui-loader
                    v-bind:fixed="50"
                    v-bind:centered="true"
                />
            </div>
            <div class="page-estates-list__list-buttons"
                v-if="!isLoading && pagingTotal > pagingCurrent"
                >
                <div class="page-estates-list__list-buttons-item"
                    v-on:click="getMore"
                    >
                    <ui-button>
                        Показать еще
                    </ui-button>
                </div>
            </div>
        </section>
    </section>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'page-estates-list',
    data: () => ({
        pagingSize: 6,
        pagingCurrent: 1,
        accumulatedItems: [],
        isFirstLoad: true,
    }),
    computed: {
        ...mapState('subscriptions', [
            'getSubscriptionsIsLoading',
            'getSubscriptionsError',
            'getSubscriptionsResult',

            'subscribeIsLoading',
            'subscribeError',
            'subscribeResult',
        ]),
        items() {
            return this.$store.getters['estates/items'];
        },
        totalItems() {
            return this.$store.getters['estates/count'];
        },
        isLoading() {
            return this.$store.getters['estates/isLoading'] || this.getSubscriptionsIsLoading;
        },
        pagingTotal() {
            return this.totalItems / this.pagingSize;
        },
        isAnySubscriptionAvailable() {
            return this.getSubscriptionsResult && this.getSubscriptionsResult.items.filter(x => !x.isSubscribed).length > 0;
        },
    },
    mounted() {
        this.$store.dispatch('subscriptions/getSubscriptions');
        if (!this.items) {
            this.getItems();
        }
    },
    methods: {
        getItems() {
            this.$store.dispatch('estates/getItems', {
                page: this.pagingCurrent,
                page_size: this.pagingSize,
            });
        },
        getMore() {
            this.pagingCurrent += 1;
            this.getItems();
        },
    },
    watch: {
        items(newItems) {
            if (newItems) {
                const result = [ ...newItems ];
                if (this.isFirstLoad) {
                    this.isFirstLoad = false;
                    result.push({ isSubscription: true });
                }
                this.accumulatedItems.push(...result);
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-estates-list {
    padding-bottom: 120px;
    &__headline {
        .container();

        margin-top: 10px;
        margin-bottom: 40px;
        padding-top: 40px;
        padding-bottom: 40px;

        background-color: @color-gray-lighter;
        &-title {
            .typography-extra-large();

            margin: 0;
            overflow: hidden;
        }
    }
    &__list {
        .container();

        &-content {
            display: flex;
            flex-wrap: wrap;
            align-content: stretch;
            justify-content: flex-start;
            padding-top: 20px;
            padding-bottom: 20px;
            margin: -20px -16px;
            &-item {
                flex: 0 0 auto;
                width: calc(100% / 4);
                padding: 20px 16px;
            }
        }
        &-buttons {
            display: flex;
            align-content: center;
            justify-content: center;
        }
    }
    @media @media-xxl-down {
        &__list {
            &-content {
                &-item {
                    width: calc(100% / 3);
                }
            }
        }
    }
    @media @media-md-down {
        &__headline {
            padding-top: 24px;
            padding-bottom: 24px;
            margin-top: 0;
            margin-bottom: 24px;
            &-title {
                .typography-body-lg();

                text-align: center;
            }
        }
        &__list {
            &-content {
                &-item {
                    width: calc(100% / 2);
                }
            }
        }
    }
    @media @media-sm-down {
        &__list {
            &-content {
                &-item {
                    width: 100%;
                }
            }
        }
    }
}
</style>
