<template>
    <div class="plugin-about-partners__wrapper">
        <div class="plugin-about-partners">
            <div class="plugin-about-partners__title"
                v-if="title"
                v-text="title"
            />
            <div class="plugin-about-partners__items">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'plugin-about-partners',
    props: {
        title: {
            type: String,
            default: '',
        },
    },
};
</script>

<style lang="less">
@import '~theme';

.plugin-about-partners {
    .container();
    .container-paddings();

    position: relative;

    padding-top: 100px;
    padding-bottom: 85px;
    &__wrapper {
        position: relative;
        &::before {
            content: '';

            position: absolute;
            top: 0;
            bottom: 0;
            left: 50%;
            right: 0;
            z-index: -1;

            display: block;

            background-color: @color-gray-lighter;
        }
    }
    &::after {
        content: '';

        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: -1;

        display: block;
        width: calc(@container-padding + @grid-column * 8 + @grid-gap * 7);
        border-radius: @border-radius-lg 0 0 @border-radius-lg;

        background-color: @color-gray-lighter;
    }
    &__title {
        .typography-extra-large();

        margin-bottom: 40px;

        text-align: center;
    }
    &__items {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin: -12px;
    }
    @media @media-md-down {
        padding-top: 0;
        padding-bottom: 40px;
        &::after {
            top: 98px;
        }
        &__title {
            .typography-large();

            margin-bottom: 24px;

            text-align: left;
        }
        &__items {
            margin: -4px;
        }
    }
}
</style>
