<template>
    <div class="common-footer-info">
        <span v-html="complaintInfo"/><br />
        <span v-text="companyJurAddress"/><br />
        <span v-text="companyEmail"/><br />
<!--        <span v-text="companyName"/><br />-->
<!--        <span v-text="companyTin"/><br />-->
<!--        <span v-text="companyOgrn"/><br />-->
    </div>
</template>

<script>
export default {
    name: 'common-footer-info',
    computed: {
        contacts() {
            return this.$store.getters['site/contacts'];
        },
        company() {
            return this.$store.getters['site/company'];
        },
        complaintInfo() {
            return 'Направить жалобу или обращение возможно следующими способами:';
        },
        companyJurAddress() {
            return `По юридическому адресу ${this.company.type} «${this.company.name}» - ${this.company.jur_address}`;
        },
        companyEmail() {
            return 'По адресу электронной почты help@simpleestate.ru';
        },
        // companyName() {
        //     return `${this.company.type} «${this.company.name}»`;
        // },
        // companyTin() {
        //     return `ИНН: ${this.company.tin}`;
        // },
        // companyOgrn() {
        //     return `ОГРН: ${this.company.ogrn}`;
        // },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.common-footer-info {
    .typography-body();

    margin-top: 16px;
    @media @media-md-down {
    }
}
</style>
