<template>
    <component class="ui-button"
        v-bind:is="component"
        v-bind:href="href ? href : null"
        v-bind:target="href ? target || '_blank' : null"
        v-bind:type="href ? null : type"
        v-bind:disabled="isDisabled || disabled || isLoading"
        v-bind:class="[
            `_theme_${theme}`,
            `_size_${size}`,
            {
                _free_width: isWidthFree,
                _loading: isLoading,
                _disabled: isDisabled || disabled,
                _icon_only: !text && !$slots.default && (iconLeft || iconRight),
            },
        ]"
        v-on:click="(href || isDisabled || isLoading) ? null : $emit('click')"
        >
        <ui-trigger
            v-bind="$props"
            v-bind:type="null"
            v-bind:href="null"
            v-bind:is-loading="false"
            is-inherited
            is-uppercase
            >
            <slot v-if="$slots.default" />
            <span v-else-if="text">{{ text }}</span>
        </ui-trigger>
    </component>
</template>

<script>
export default {
    name: 'ui-button',
    props: {
        text: {
            type: String,
            default: null,
        },
        href: {
            type: String,
            default: null,
        },
        target: {
            type: String,
            default: null,
        },
        type: {
            type: String,
            default: null,
        },
        iconLeft: {
            type: String,
            default: null,
        },
        iconRight: {
            type: String,
            default: null,
        },
        theme: {
            type: String,
            default: 'primary',
        },
        size: {
            type: String,
            default: 'md',
        },
        isInlined: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        isWidthFree: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        component() {
            if (this.href) {
                return 'a';
            }
            if (this.type === 'button' || this.type === 'submit') {
                return 'button';
            }
            return 'div';
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 130px;
    height: 100%;
    min-height: 40px;
    border-radius: 100px;
    border: none;

    text-decoration: none;

    appearance: none;
    outline: none;
    cursor: pointer;
    user-select: none;

    transition: background-color @duration-fast ease-in-out,
        color @duration-fast ease-in-out,
        box-shadow @duration-fast ease-in-out,
        opacity @duration-fast ease-in-out;
    &._size_xxs {
        min-height: 32px;
        min-width: 110px;
        padding: 8px 16px;
    }
    &._size_xs {
        min-height: 36px;
        min-width: 120px;
        padding: 8px 18px;
    }
    &._size_sm {
        min-height: 40px;
        padding: 10px 24px;
    }
    &._size_md {
        min-height: 48px;
        padding: 10px 32px;
    }
    &._size_lg {
        min-height: 60px;
        min-width: 160px;
        padding: 10px 40px;
    }
    &._free_width {
        width: auto;
        min-width: 0;
    }
    &._icon_only._size_xxs {
        padding: 8px;
    }
    &._icon_only._size_xs {
        padding: 10px;
    }
    &._icon_only._size_sm {
        padding: 10px;
    }
    &._icon_only._size_md {
        padding: 10px;
    }
    &._icon_only._size_lg {
        padding: 10px;
    }
    &[disabled] {
        pointer-events: none;
    }
    &._theme_primary {
        color: @color-gray-lightest;

        background-color: @color-primary;

        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
        &._disabled {
            color: @color-gray-main;

            background-color: @color-gray-light;

            box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
        }
        &:hover {
            opacity: 0.8;
        }
        &:active {
            opacity: 0.9;
            box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
        }
    }
    &._theme_secondary-1 {
        color: @color-primary;

        background-color: @color-secondary;

        box-shadow: inset 0px 0px 0px 0px @color-gray-light;
        &._disabled {
            color: @color-gray-main;

            background-color: @color-gray-lightest;

            box-shadow: inset 0px 0px 0px 1px @color-gray-light;
        }
        &:hover {
            color: @color-gray-lightest;

            background-color: @color-primary;
        }
        &:active {
            color: @color-gray-lightest;

            background-color: @color-primary;

            opacity: 0.9;
            box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
        }
    }
    &._theme_secondary-2 {
        color: @color-gray-darkest;

        background-color: @color-gray-light;

        box-shadow: inset 0px 0px 0px 0px @color-gray-light;
        &._disabled {
            color: @color-gray-main;

            background-color: @color-gray-lightest;

            box-shadow: inset 0px 0px 0px 1px @color-gray-light;
        }
        &:hover {
            color: @color-gray-lightest;

            background-color: @color-gray-darkest;
        }
        &:active {
            color: @color-gray-lightest;

            background-color: @color-gray-darkest;

            opacity: 0.9;
        }
    }
    &._theme_secondary-3 {
        color: @color-gray-lightest;

        background-color: @color-positive;

        box-shadow: 0px 6px 10px fade(@color-positive, 50%);
        &._disabled {
            color: @color-gray-main;

            background-color: @color-gray-light;

            box-shadow: 0px 0px 0px 1px @color-gray-light;
        }
        &:hover {
            opacity: 0.8;
        }
        &:active {
            opacity: 0.9;

            box-shadow: 0px 0px 0px @color-positive;
        }
    }
    &._theme_transparent {
        color: @color-primary;

        background-color: transparent;

        box-shadow: none;
        &._disabled {
            color: @color-gray-main;
        }
        &:hover {
            opacity: 0.8;
        }
        &:active {
            opacity: 0.9;
        }
    }
    &._theme_bordered {
        color: @color-gray-lightest;

        background-color: transparent;

        box-shadow: 0px 0px 0px 1px @color-gray-lightest;
        &._disabled {
            color: @color-gray-main;

            box-shadow: 0px 0px 0px 1px @color-gray-main;
        }
        &:hover {
            opacity: 0.8;
        }
        &:active {
            opacity: 0.9;
        }
    }
    &._theme_inverted {
        color: @color-gray-darkest;

        background-color: @color-gray-light;

        box-shadow: inset 0px 0px 0px @color-gray-main;
        &._disabled {
            color: @color-gray-main;

            background-color: transparent;

            box-shadow: inset 0px 0px 0px 1px @color-gray-main;
        }
        &:hover {
            opacity: 0.8;
        }
        &:active {
            opacity: 0.9;
        }
    }
    &._theme_white {
        color: @color-primary;

        background-color: @color-gray-lightest;

        box-shadow: none;
        &._disabled {
            color: @color-gray-main;

            background-color: @color-gray-lighter;
        }
        &:hover {
            opacity: 0.8;
        }
        &:active {
            opacity: 0.9;
        }
    }
    &._theme_landing-inverted {
        color: @color-primary;

        background-color: @color-gray-light;

        box-shadow: inset 0px 0px 0px @color-gray-main;
        &._disabled {
            color: @color-gray-main;

            background-color: transparent;

            box-shadow: inset 0px 0px 0px 1px @color-gray-main;
        }
        &:hover {
            opacity: 0.8;
        }
        &:active {
            opacity: 0.9;
        }
    }
    &._disabled {
        color: @color-gray-main;

        background-color: @color-gray-light;

        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    }
}
</style>
