<template>
    <div class="modal-development">
        <div class="modal-development__title">
            Этот раздел в разработке
        </div>
        <div class="modal-development__text">
            Приносим извинения за доставленные неудобства. Вы можете написать о своей проблеме нам на почту
        </div>
    </div>
</template>

<script>
export default {
    name: 'modal-development',
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-development {
    .modal();

    text-align: center;
    &__title {
        .typography-large();

        margin-bottom: 8px;
    }
    &__text {
        .typography-small();
    }
    @media @media-md-down {
        width: auto;
        max-width: ~'calc(100vw - 40px)';
        max-height: ~'calc(100vh - 80px)';
        padding: 40px 20px;
    }
}
</style>
