<template>
    <div class="modal-market-application-confirm-confirmation">
        <div class="modal-market-application-confirm-confirmation__title">
            Подтверждение
        </div>
        <div class="modal-market-application-confirm-confirmation__description">
            На ваш номер телефона мы выслали код подтверждения - это ваша подпись. Вы можете скачать договор купли-продажи акций.
        </div>
        <div class="modal-market-application-confirm-confirmation__form">
            <common-sms-verification
                v-bind:phone="user.phone"
                v-on:submit="submit"
                is-request-required
                no-text
            />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'modal-market-application-confirm-confirmation',
    props: {
        deal: {
            type: Object,
        },
    },
    computed: {
        ...mapState('users', [
            'user',
        ]),
    },
    methods: {
        submit(code) {
            this.$store.dispatch('market/signOrder', {
                id: this.deal.ownerOrder.id,
                code,
                value: this.user.phone,
            });
            this.$emit('step-change', 'info');
        },
    },
};
</script>

<style lang="less">
@import '~theme';

.modal-market-application-confirm-confirmation {
    &__title {
        .typography-large();

        margin-bottom: 8px;
    }
    &__description {
        .typography-body();

        margin-bottom: 24px;
    }
    @media @media-sm-down {
        &__title {
            .typography-medium();
        }
        &__description {
            .typography-caption();
        }
    }
}
</style>
