<template>
    <component class="ui-back"
        v-bind:class="[
            `_theme_${theme}`,
            { _no_text: noText },
        ]"
        v-bind:is="href ? 'a' : 'div'"
        v-bind:href="href ? href : null"
        v-on:click="clickHandler"
        >
        <div class="ui-back__icon">
            <icon name="caret-left" />
        </div>
        <div class="ui-back__text"
            v-if="!noText"
            v-text="text"
        />
    </component>
</template>

<script>
import config from '~/config';

export default {
    name: 'ui-back',
    props: {
        noText: {
            type: Boolean,
            default: false,
        },
        href: {
            type: String,
            default: null,
        },
        text: {
            type: String,
            default: 'Назад',
        },
        action: {
            type: Function,
            default: null,
        },
        theme: {
            type: String,
            default: 'primary',
        },
    },
    methods: {
        clickHandler() {
            if (this.href) {
                return null;
            } else if (this.action) {
                this.action();
            } else {
                this.back();
            }
        },
        back() {
            if (this.href) {
                window.location.href = this.href;
                return;
            }
            window.history.back();
            setTimeout(() => {
                window.location.href = config.urls.home;
            }, 1000);
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-back {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    color: @color-gray-main;
    text-decoration: none;

    cursor: pointer;
    &__icon {
        flex: 0 0 auto;
        border-radius: 50%;
        margin-right: 8px;

        font-size: 2rem;

        background-color: fade(@color-gray-darker, 10%);
        ._theme_dark > & {
            background-color: @color-gray-light;
        }
        ._no_text > & {
            margin-right: 0;
        }
    }
    &__text {
        flex: 1 1 auto;
        ._theme_primary > & {
            .typography-heading();
        }
        ._theme_dark > & {
            .typography-body();

            color: @color-gray-lightest;
        }
    }
}
</style>
