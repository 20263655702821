<template>
    <div class="plugin-about-hero">
        <div class="plugin-about-hero__info">
            <h1 class="plugin-about-hero__info-title"
                v-text="title"
            />
            <div class="plugin-about-hero__info-text"
                v-text="text"
            />
        </div>
        <div class="plugin-about-hero__image"
            v-bind:style="{ backgroundImage }"
        />
    </div>
</template>

<script>
import utils from '~/utils';

export default {
    name: 'plugin-about-hero',
    props: {
        title: {
            type: String,
            default: '',
        },
        text: {
            type: String,
            default: '',
        },
        image: {
            type: String,
            default: '',
        },
    },
    computed: {
        backgroundImage() {
            if (!this.image) {
                return null;
            }
            return `url(${utils.common.injectResize(this.image, '1100xAUTO')})`;
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-about-hero {
    .container();
    .container-paddings();

    display: flex;
    align-items: stretch;
    justify-content: flex-start;

    &__info {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 40px 24px;
        background: linear-gradient(91.22deg, #F5F5FD 0.99%, #F7F8FD 48.13%, #F8F9FD 98.12%);
        &-title {
            .typography-extra-large();

            width: 68.27%;
            margin: 0 0 24px;
        }
        &-text {
            .typography-small();

            width: 68.27%;
        }
    }
    &__image {
        flex: 0 0 auto;
        width: 40.66%;
        padding-top: 30.73%;

        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: @color-gray-light;
    }
    @media @media-sm-down {
        position: relative;

        padding: 0;
        &__image {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;

            width: 100%;
            height: 100%;
        }
        &__info {
            z-index: 2;

            width: 100%;

            background: fade(#F7F8FD, 90%);
            &-title {
                .typography-large();

                width: 100%;
                margin-bottom: 8px;
            }
            &-text {
                .typography-body();

                width: 100%;
            }
        }
    }
}
</style>
