<template>
    <div class="modal-market-application-create">
        <transition name="fade">
            <div class="modal-market-application-create__loader"
                v-if="isLoading"
                >
                <div class="modal-market-application-create__loader-container">
                    <ui-loader />
                </div>
            </div>
        </transition>
        <div class="modal-market-application-create__headline">
            <modal-market-application-create-headline
                v-bind:variant="orderId ? 'apply' : selectedShare ? 'normal' : 'empty'"
                v-bind:mode="mode"
            />
        </div>
        <div class="modal-market-application-create__objects">
            <modal-market-application-create-objects
                v-if="items.length > 0 && (givenShareId === null ? true : shareId !== null)"
                v-on:share-id-change="setShareId"
                v-bind:items="items"
                v-bind:share-id="shareId"
            />
        </div>
        <div class="modal-market-application-create__form">
            <modal-market-application-create-form
                v-bind:selected-share="selectedShare"
                v-bind:order-id="orderId"
                v-bind:given-type="givenType"
                v-bind:given-amount-limit="givenAmountLimit"
                v-bind:given-price="givenPrice"
                v-bind:is-price-disabled="isPriceDisabled"
                v-bind:is-amount-disabled="isAmountDisabled"
                v-bind:mode="mode"
                v-on:set-sum="setSum"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import config from '~/config';

export default {
    props: {
        givenShareId: {
            type: [ Number, String ],
            default: null,
        },
        orderId: {
            type: [ Number, String ],
            default: null,
        },
        givenType: {
            type: String,
            default: null,
        },
        givenAmountLimit: {
            type: Number,
            default: null,
        },
        givenPrice: {
            type: Number,
            default: null,
        },
        givenIsPackageValue: {
            type: Boolean,
            default: null,
        },
        isPriceDisabled: {
            type: Boolean,
            default: false,
        },
        isAmountDisabled: {
            type: Boolean,
            default: false,
        },
        isLocked: {
            type: Boolean,
            default: false,
        },
        availableShares: {
            type: Array,
            default: null,
        },
        mode: {
            type: String,
            default: 'create',
        },
    },
    name: 'modal-market-application-create',
    data: () => ({
        shareId: null,
        sumToShowOnSuccess: null,
    }),
    computed: {
        ...mapState('dashboard', [
            'getHighlightsResult',
            'getHighlightsError',
            'getHighlightsIsLoading',
        ]),
        ...mapState('market', [
            'getAllSharesResult',
            'getAllSharesError',
            'getAllSharesIsLoading',

            'createOrderResult',
            'createOrderError',
            'createOrderIsLoading',

            'editOrderResult',
            'editOrderError',
            'editOrderIsLoading',

            'acceptOrderResult',
            'acceptOrderError',
            'acceptOrderIsLoading',

            'cancelOrderResult',
            'cancelOrderError',
            'cancelOrderIsLoading',
        ]),
        items() {
            if (!this.getAllSharesResult) {
                return [];
            }
            if (this.isLocked && this.givenShareId) {
                return this.getAllSharesResult.filter(x => x.id === this.givenShareId);
            }
            if (this.availableShares) {
                return this.getAllSharesResult.filter(x => this.availableShares.indexOf(x.id) > -1);
            }
            return this.getAllSharesResult;
        },
        isLoading() {
            return !this.getHighlightsResult || this.getHighlightsIsLoading ||
                !this.getAllSharesResult || this.getAllSharesIsLoading ||
                this.createOrderIsLoading ||
                this.editOrderIsLoading;
        },
        selectedShare() {
            return this.shareId ? this.items.find(x => x.id === this.shareId) || null : null;
        },
    },
    methods: {
        setShareId(newId) {
            this.shareId = newId;
        },
        setSum(sum) {
            this.sumToShowOnSuccess = sum;
        },
    },
    mounted() {
        this.$store.commit('market/resetAcceptTradeShareOrder');
        if (this.givenShareId !== null) {
            this.setShareId(this.givenShareId);
        }
        this.$store.dispatch('dashboard/getHighlights');
        this.$store.dispatch('market/getAllShares');
    },
    watch: {
        createOrderIsLoading(newVal, oldVal) {
            if (!newVal && oldVal && this.createOrderResult) {
                this.$store.commit('modals/pop');
                this.$store.commit('modals/push', {
                    name: 'market-application-success',
                    props: {
                        application: this.createOrderResult,
                        share: this.selectedShare,
                        givenSum: this.sumToShowOnSuccess,
                    },
                });
            }
        },
        cancelOrderIsLoading(newVal) {
            if (!newVal && this.cancelOrderResult) {
                this.$store.commit('modals/pop');
            }
        },
        editOrderIsLoading(newVal) {
            if (!newVal && this.editOrderResult) {
                this.$store.commit('modals/pop');
            }
        },
        acceptOrderIsLoading(newVal) {
            if (!newVal && this.acceptOrderResult) {
                this.$store.commit('modals/pop');
                window.location.href = config.urls.cabinet.deals;
                // this.$store.commit('modals/push', {
                //     name: 'market-application-success',
                //     props: {
                //         mode: 'accept',
                //     },
                // });
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-market-application-create {
    .modal();

    position: relative;

    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    padding: 0 0 0 ~'max(31%, 346px)';
    &__loader {
        .transition-fade();

        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        background-color: @color-gray-lightest;
        &-container {
            width: 50px;
        }
    }
    &__headline {
        display: none;
    }
    &__objects {
        position: absolute;
        top: 0;
        left: 0;

        width: ~'max(31%, 346px)';
        height: 100%;
        border-right: 1px solid @color-gray-light;
        overflow: auto;
    }
    &__form {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    @media @media-md-down {
        padding: 0 0 0 ~'max(31%, 346px)';
    }
    @media @media-sm-down {
        width: calc(100vw - 48px);
        max-width: calc(100vw - 48px);
        padding: 16px 0 24px;
        overflow: auto;
        &__headline {
            display: block;
            padding: 0 16px;
        }
        &__objects {
            position: static;

            width: 100%;
            height: auto;
            border-right: none;
        }
    }
}
</style>
