<template>
    <div class="layout-cabinet-sideblock-menu"
        ref="menu"
        >
        <a class="layout-cabinet-sideblock-menu__item"
            v-for="(item, index) in availableItems"
            v-bind:key="index"
            v-bind:href="item.href"
            v-bind:class="{
                _active: item.href === pathname || '/ru' + item.href === pathname
            }"
            >
            <div class="layout-cabinet-sideblock-menu__item-icon">
                <icon
                    v-bind:name="(item.isAccented && item.iconAccented) || item.icon"
                    v-bind:original="item.isAccented"
                />
            </div>
            <div class="layout-cabinet-sideblock-menu__item-title"
                v-text="item.title"
            />
            <div class="layout-cabinet-sideblock-menu__item-indicator">
            </div>
        </a>
        <div class="layout-cabinet-sideblock-menu__item"
            v-on:click="logout"
            >
            <div class="layout-cabinet-sideblock-menu__item-icon">
                <icon name="out" />
            </div>
            <div class="layout-cabinet-sideblock-menu__item-title">
                Выйти
            </div>
            <div class="layout-cabinet-sideblock-menu__item-indicator" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import config from '~/config';

export default {
    name: 'layout-cabinet-sideblock-menu',
    data: () => ({
        pathname: window.location.pathname,
        items: [
            {
                title: 'Сводка',
                href: config.urls.cabinet.dashboard,
                icon: 'briefcase',
            },
            {
                title: 'Объекты',
                href: config.urls.cabinet.estates,
                icon: 'buildings',
            },
            {
                title: 'Сделки по акциям',
                code: 'deals',
                href: config.urls.cabinet.deals,
                icon: 'deals',
                isAccented: false,
                iconAccented: 'deals-new',
            },
            {
                title: 'Заявки',
                code: 'requests',
                href: config.urls.cabinet.requests,
                icon: 'requests',
                isAccented: false,
                iconAccented: 'requests-new',
            },
            {
                title: 'Транзакции',
                href: config.urls.cabinet.transactions,
                icon: 'wallet',
            },
            {
                title: 'Документы',
                href: config.urls.cabinet.documents,
                icon: 'document',
            },
            {
                title: 'Пройти верификацию',
                href: config.urls.cabinet.verification,
                icon: 'lock',
                onlyFor: ['new'],
            },
            {
                title: 'Персональные данные',
                href: config.urls.cabinet.personalData,
                icon: 'lock',
                onlyFor: ['unverified', 'verified'],
            },
            {
                title: 'Приведи друга',
                href: config.urls.cabinet.referral,
                icon: 'partner-add',
                code: 'referral',
            },
            {
                title: 'Настройки',
                href: config.urls.cabinet.settings,
                icon: 'gear',
            },
        ],
    }),
    computed: {
        ...mapState('cabinet', [
            'getNoticesIsLoading',
            'getNoticesError',
            'getNoticesResult',
        ]),
        user() {
            return this.$store.getters['users/user'];
        },
        availableItems() {
            const result = this.items.filter(x => {
                return !x.onlyFor || (this.user !== null && x.onlyFor.indexOf(this.user.status) > -1);
            });
            if (this.getNoticesResult) {
                return result.map(x => {
                    if ((x.code === 'requests' && this.getNoticesResult.investments) ||
                        (x.code === 'deals' && this.getNoticesResult.deals)) {
                        return { ...x, isAccented: true };
                    } else {
                        return x;
                    }
                });
            }
            return result;
        },
    },
    methods: {
        checkScroll() {
            const menuRef = this.$refs.menu;
            if (window.innerWidth < 1023 && menuRef && menuRef.scrollWidth > menuRef.offsetWidth) {
                this.updateMenuScroll();
            }
        },
        updateMenuScroll() {
            const menuRef = this.$refs.menu;
            const activeMenuItem = menuRef.querySelector('._active');
            if (!activeMenuItem) {
                return;
            }
            const activeLeft = activeMenuItem.offsetLeft;
            const offset = 16;
            const scrollValue = activeLeft - offset;
            menuRef.scrollTo(scrollValue, 0);
        },
        logout() {
            this.$store.dispatch('users/logout');
        },
    },
    mounted() {
        this.checkScroll();
        // TODO: replace this line with something better
        setTimeout(this.checkScroll, 100);
        window.addEventListener('resize', this.checkScroll);
        window.addEventListener('load', this.checkScroll);
        this.$store.dispatch('cabinet/getNotices');
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkScroll);
        window.removeEventListener('load', this.checkScroll);
    },
    watch: {
        user(newVal) {
            if (newVal.referrerCode) {
                this.items = this.items.map(x => {
                    if (x.code === 'referral') {
                        x.title = 'Партнерская программа';
                    }
                    return x;
                });
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.layout-cabinet-sideblock-menu {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-content: stretch;
    justify-items: flex-start;
    &__item {
        position: relative;
        z-index: 1;

        display: flex;
        width: fit-content;
        padding-bottom: 26px;

        color: @color-gray-main;
        text-decoration: none;

        transition: color @duration-fast ease-in-out;

        cursor: pointer;
        &._active {
            color: @color-primary;
        }
        &:last-child {
            margin-top: auto;
            margin-bottom: 0;
        }
        &-icon {
            margin-top: 2px;
            margin-right: 10px;

            font-size: 2rem;
        }
        &-title {
            .typography-heading();

            white-space: nowrap;
        }
        &-indicator {
            position: absolute;
            bottom: 17px;
            left: 30px;
            z-index: 1;

            width: 3px;
            height: 3px;
            border-radius: 3px;

            background-color: currentColor;

            opacity: 0;

            transition: opacity @duration-fast ease-in-out, width @duration-fast ease-in-out;
        }
        &._active &-indicator,
        &:hover &-indicator {
            width: 24px;
            opacity: 1;
        }
    }
    @media @media-md-down {
        flex-direction: row;
        width: 100%;
        overflow: auto;

        background-color: @color-gray-lightest;
        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
        }
        &::-webkit-scrollbar-track {
            border-radius: 2px;
            box-shadow: inset 0 0 0 10px rgba(0,0,0,0);
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 2px;
            box-shadow: inset 0 0 0 10px rgba(0,0,0,0);
        }
        &:hover::-webkit-scrollbar-thumb,
        &:hover::-webkit-scrollbar-track {
            background-color: rgba(0,0,0,0.1);
        }
        &__item {
            padding: 16px 8px;
            &:first-child {
                padding-left: 24px;
            }
            &:last-child {
                padding-right: 24px;
                margin-left: auto;
                margin-right: 0;
            }
            &-icon {
                margin-right: 6px;
            }
            &-indicator {
                display: none;
            }
        }
    }
}
</style>
