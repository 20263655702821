<template>
    <div class="ui-table-cell-title"
        v-text="text"
    />
</template>

<script>
export default {
    name: 'ui-table-cell-title',
    props: {
        text: {
            type: String,
            default: '',
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-table-cell-title {
    .typography-heading();
}
</style>
