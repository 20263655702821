<template>
    <div class="plugin-about-numbers">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'plugin-about-numbers',
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-about-numbers {
    .container();
    .container-paddings();

    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    @media @media-md-down {
        flex-direction: column;
    }
}
</style>
