import { mainClient } from '~/network';

const moduleTitle = 'subscription';

const state = {
    subscribeIsLoading: false,
    subscribeError: null,
    subscribeResult: null,
};

const getters = {
};

const actions = {
    async subscribe({ state }, payload = {}) {
        if (state.subscribeIsLoading) {
            return;
        }
        state.subscribeIsLoading = true;
        const { data, error, status } = await mainClient[moduleTitle].requests.subscribe(payload);
        if (error) {
            console.error(`Error in action ${moduleTitle}/subscribe (${status})`);
            state.subscribeError = error;
            state.subscribeResult = null;
        } else if (data) {
            state.subscribeResult = data;
            state.subscribeError = null;
        }
        state.subscribeIsLoading = false;
    },
};

const mutations = {
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
