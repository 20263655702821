<template>
    <div class="plugin-referral-statistics">
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'plugin-referral-statistics',
};
</script>

<style lang="less">
@import '~theme';

.plugin-referral-statistics {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    margin: 100px auto 0;
}
</style>
