<template>
    <div class="plugin-illustration"
        ref="illustration"
        v-bind:style="{ backgroundImage, paddingTop: `${heightFactor}%` }"
    />
</template>

<script>
import utils from '~/utils';

export default {
    name: 'plugin-illustration',
    props: {
        image: {
            type: String,
            default: null,
        },
        heightFactor: {
            type: Number,
            default: 50,
        },
    },
    data: () => ({
        backgroundImage: null,
    }),
    mounted() {
        const element = this.$refs.illustration;
        const resize = utils.common.getResizeByElement({ element });
        this.backgroundImage = `url(${utils.common.injectResize(this.image, resize)})`;
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-illustration {
    display: block;
    width: 100%;
    border-radius: @border-radius-lg;

    background-color: @color-gray-lightest;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @media @media-md-down {
    }
}
</style>
