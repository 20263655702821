export const item = (data) => {
    const result = {
        date: data.created || new Date(),
        title: data.title || 'Документ',
        filename: data.title || 'file.txt',
        url: data.file || '/',
    };
    return result;
};

export const list = (data) => {
    const result = {
        items: data.results.map(item),
        paging: {
            count: data.count || 0,
            page: data.page || 1,
        },
        ...data,
    };
    return result;
};
