<template>
    <modal-generic v-bind="$attrs" />
</template>

<script>
export default {
    name: 'modal-verification-required',
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-verification-required {
}
</style>
