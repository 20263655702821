<template>
    <label class="ui-radio-with-text">
        <div class="ui-radio-with-text__radio">
            <ui-input-radio
                v-bind:name="name"
                v-model="checkedInner"
            />
        </div>
        <div class="ui-radio-with-text__text"
            v-text="text"
        />
    </label>
</template>

<script>
export default {
    name: 'ui-radio-with-text',
    props: {
        checked: {
            type: Boolean,
            default: false,
        },
        text: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
    },
    model: {
        prop: 'checked',
        event: 'change',
    },
    methods: {
        updateModel(newValue) {
            this.$emit('change', newValue);
        },
    },
    computed: {
        checkedInner: {
            get() {
                return this.checked;
            },
            set(newValue) {
                this.updateModel(newValue);
            },
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-radio-with-text {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    width: fit-content;

    cursor: pointer;
    &__radio {
        flex: 0 0 auto;
        margin-top: 2px;
    }
    &__text {
        .typography-body();

        flex: 1 1 auto;
        margin-left: 8px;
    }
}
</style>
