<template>
    <div class="page-cabinet-qualification-files">
        <div class="page-cabinet-qualification-files__item"
            v-for="(file, i) in files"
            v-bind:key="i"
            >
            <page-cabinet-qualification-files-item
                v-bind:file="file"
                v-bind:variant-id="variantId"
                v-bind:is-first="i === 0"
                v-on:change="updateFile($event, i)"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'page-cabinet-qualification-files',
    props: {
        files: {
            type: Array,
        },
        variantId: {
            type: Number,
        },
    },
    model: {
        prop: 'files',
        event: 'update',
    },
    data: () => ({
        maxAmount: 5,
    }),
    methods: {
        updateFile(file, i) {
            const newFiles = this.files.map((x, xi) => xi === i ? file : x);
            // if (file === null) {
            //     // const newFiles = this.files.filter((x, xi) => xi !== i);
            //     this.$emit('update', newFiles.length === 0 ? [ null ] : newFiles);
            //     return;
            // }
            const isMoreAvailable = file !== null && this.files.length < this.maxAmount && this.files.every((x, xi) => x !== null || xi === i);
            this.$emit('update', isMoreAvailable ? newFiles.concat([ null ]) : newFiles);
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-qualification-files {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: -8px;
    &__item {
        flex: 1 1 auto;
        width: 100%;
        max-width: 50%;
        height: 76px;
        padding: 8px;
        &:first-child:last-child {
            max-width: 100%;
        }
        &-add {
            position: relative;

            display: flex;
            align-items: center;
            justify-content: center;
            padding: 4px;
            width: 100%;
            height: 100%;
            border-radius: @border-radius-lg;
            border: 1px dashed currentColor;
            overflow: hidden;

            color: @color-gray-main;
            text-align: center;

            background-color: @color-gray-lighter;

            cursor: pointer;
            &-icon {
                font-size: 1rem;
            }
            &-text {
                .typography-body();

                margin-left: 8px;
            }
        }
    }
    @media @media-sm-down {
        margin: 12px -4px;
        &__item {
            flex: 1 1 auto;
            max-width: 100%;
            height: 60px;
            padding: 4px;
        }
    }
}
</style>
