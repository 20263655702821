<template>
    <div class="page-market-item-orders">
        <div class="page-market-item-orders__content"
            v-if="accumulatedItems.length > 0"
            >
            <component
                v-bind:is="mode === 'table' ? 'page-market-item-orders-table' : 'page-market-item-orders-cards'"
                v-bind:items="accumulatedItems"
                v-bind:is-user-shareless="shareData.userSharesAvailable === 0"
            />
        </div>
        <div class="page-market-item-orders__empty"
            v-else-if="!isLoading"
            v-text="'Не найдено заявок данного типа'"
        />
        <div class="page-market-item-orders__more"
            v-if="!error && pagingTotal > 0 && pagingTotal > pagingCurrent"
            >
            <ui-button
                v-bind:is-disabled="isLoading"
                v-on:click="getItems"
                size="sm"
                >
                Загрузить еще
            </ui-button>
        </div>
        <transition name="fade">
            <div class="page-market-item-orders__loader"
                v-if="isLoading"
                >
                <div class="page-market-item-orders__loader-container">
                    <ui-loader />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'page-market-item-orders',
    props: {
        filter: {
            type: String,
        },
        shareId: {
            type: [ Number, String ],
        },
        isUserShareless: {
            type: Boolean,
            default: true,
        },
    },
    provide: function() {
        return {
            shareId: this.shareId,
        };
    },
    data: () => ({
        mode: 'table',
        pagingSize: 10,
        pagingCurrent: 0,
        accumulatedItems: [],
        isCanceling: false,
    }),
    computed: {
        ...mapState('market', [
            'createOrderIsLoading',
            'acceptOrderIsLoading',
            'cancelOrderIsLoading',
        ]),
        orders() {
            return this.$store.state.market.getOrdersByShare[this.shareId];
        },
        share() {
            return this.$store.state.market.getShareById[this.shareId];
        },
        shareData() {
            return this.share?.result;
        },
        isLoading() {
            return !this.orders || this.orders.isLoading ||
                !this.share || this.share.isLoading ||
                this.createOrderIsLoading ||
                this.acceptOrderIsLoading ||
                this.cancelOrderIsLoading;
        },
        error() {
            return this.orders?.error;
        },
        count() {
            return this.orders ? this.orders.count : 0;
        },
        pagingTotal() {
            return Math.ceil(this.count / this.pagingSize);
        },
    },
    methods: {
        updateMode() {
            if (window.innerWidth < 1024 && this.mode === 'table') {
                this.mode = 'cards';
            } else if (window.innerWidth >= 1024 && this.mode !== 'table') {
                this.mode = 'table';
            }
        },
        getItems() {
            this.pagingCurrent++;
            if (this.pagingTotal !== 0 && this.pagingCurrent > this.pagingTotal) {
                return;
            }
            this.$store.dispatch('market/getShareById', { id: this.shareId });
            const ordering = this.filter === 'all' ? 'direction,price' : this.filter === 'sale' ? 'price' : '-price';
            const direction = this.filter === 'all' ? undefined : this.filter === 'sale' ? 'sale' : 'buy';
            this.$store.dispatch('market/getOrdersByShare', {
                share: this.shareId,
                status: 'active',
                ordering,
                direction,
                page: this.pagingCurrent,
                page_size: this.pagingSize,
            });
        },
    },
    mounted() {
        this.updateMode();
        window.addEventListener('resize', this.updateMode);
        if (!this.orders?.result && !this.orders?.isLoading) {
            this.getItems();
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateMode);
    },
    watch: {
        filter: {
            handler() {
                this.pagingCurrent = 0;
                this.accumulatedItems = [];
                this.getItems();
            },
            immediate: true,
        },
        orders(newVal) {
            if (!newVal?.result || newVal?.isLoading) {
                return;
            }
            if (this.pagingCurrent === 1) {
                this.accumulatedItems = newVal.result;
            } else {
                this.accumulatedItems = this.accumulatedItems.concat(newVal.result);
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-market-item-orders {
    position: relative;

    min-height: 94px;
    &__content {
        // position: relative;
        // &-loader {
        //     .transition-fade();

        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     z-index: 2;

        //     display: flex;
        //     align-items: center;
        //     justify-content: center;

        //     background-color: fade(@color-gray-lightest, 50%);
        //     &-container {
        //         width: 50px;
        //     }
        // }
    }
    &__loader {
        .transition-fade();

        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        background-color: fade(@color-gray-lightest, 50%);
        &-container {
            width: 50px;
        }
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // height: 94px;
        // &-container {
        //     width: 50px;
        // }
    }
    &__empty {
        .typography-body();

        padding-top: 37px;
    }
    &__more {
        margin-top: 24px;
    }
    @media @media-md-down {
        &__loader {
            height: 220px;
        }
    }
}
</style>
