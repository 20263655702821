<template>
    <header class="common-header"
        v-bind:class="{
            _transparent: !isScrolled && isTransparent,
            _with_shadow: isShadowNeeded,
            _gray: isGray,
        }"
        >
        <div class="common-header__container">
            <div class="common-header__burger"
                v-if="!isAuthPage"
                v-on:click="toggleModal"
                >
                <icon name="burger" />
            </div>
            <a class="common-header__logo"
                href="/"
                >
                <ui-logo-text v-bind:variant="(!isScrolled && isTransparent) ? 'inverse' : 'default'" />
            </a>
            <template v-if="!isAuthPage">
                <div class="common-header__menu">
                    <a class="common-header__menu-item"
                        v-for="(item, i) in items"
                        v-bind:key="i"
                        v-bind:href="item.url"
                        v-bind:target="item.target || null"
                        v-text="item.title"
                    />
                </div>
                <a class="common-header__phone"
                    v-bind:href="'tel:' + contacts.phone.value"
                    >
                    <span class="common-header__phone-icon">
                        <icon name="phone" />
                    </span>
                    <span class="common-header__phone-number"
                        v-text="contacts.phone.valueFormatted"
                    />
                </a>
                <div class="common-header__home">
                    <ui-auth v-bind:is-inverted="!isScrolled && isTransparent" />
                </div>
                <section class="common-header__modal"
                    v-bind:class="{ _active: modal.isOpen }"
                    >
                    <div class="common-header__modal-header">
                        <div class="common-header__modal-header-close"
                            v-on:click="toggleModal"
                            >
                            <icon name="close" />
                        </div>
                        <div class="common-header__modal-header-info"
                            v-if="isAuthorized"
                            >
                            <div class="common-header__modal-header-info-name">
                                <span v-text="user.firstName" /> <span v-text="user.lastName" />
                            </div>
                            <div class="common-header__modal-header-info-email">
                                <span v-text="user.email" />
                            </div>
                        </div>
                        <div class="common-header__modal-header-home">
                            <ui-auth is-inverted is-mobile />
                        </div>
                    </div>
                    <div class="common-header__modal-menu">
                        <div class="common-header__modal-menu-item"
                            v-for="(item, i) in items"
                            v-bind:key="i"
                            >
                            <a class="common-header__modal-menu-item-text"
                                v-bind:href="item.url"
                                v-bind:target="item.target || null"
                                v-text="item.title"
                            />
                        </div>
                    </div>
                    <div class="common-header__modal-contact">
                        <div class="common-header__modal-contact-title">
                            E-mail
                        </div>
                        <div class="common-header__modal-contact-content">
                            <a class="common-header__modal-contact-content-text"
                                v-bind:href="'mailto:' + contacts.email.value"
                                v-text="contacts.email.value"
                            />
                        </div>
                    </div>
                    <div class="common-header__modal-contact">
                        <div class="common-header__modal-contact-title">
                            Телефон
                        </div>
                        <div class="common-header__modal-contact-content">
                            <a class="common-header__modal-contact-content-text"
                                v-bind:href="'tel:' + contacts.phone.value"
                                v-text="contacts.phone.valueFormatted"
                            />
                        </div>
                    </div>
                </section>
            </template>
        </div>
    </header>
</template>

<script>
import config from '~/config';

export default {
    name: 'common-header',
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        isTransparent: {
            type: Boolean,
            default: false,
        },
        isShadowNeeded: {
            type: Boolean,
            default: false,
        },
        isGray: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        modal: {
            isOpen: false,
        },
        isScrolled: false,
    }),
    computed: {
        contacts() {
            return this.$store.getters['site/contacts'];
        },
        isAuthPage() {
            return window.location.href.indexOf(config.urls.auth.login) > -1;
        },
        isAuthorized() {
            return this.$store.getters['users/isAuthorized'];
        },
        user() {
            return this.$store.getters['users/user'];
        },
    },
    methods: {
        toggleModal() {
            const {
                isOpen,
            } = this.modal;

            if (isOpen) {
                this.$store.commit('common/decreaseBodyFixedCounter');
            } else {
                this.$store.commit('common/increaseBodyFixedCounter');
            }

            this.modal.isOpen = !isOpen;
        },
        checkScroll() {
            if (window.scrollY > 0) {
                this.isScrolled = true;
            } else {
                this.isScrolled = false;
            }
        },
    },
    mounted() {
        if (this.isTransparent) {
            window.addEventListener('scroll', this.checkScroll);
        }
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.checkScroll);
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.common-header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 77px;

    background-color: @color-gray-lightest;

    transition: background-color @duration-fast @easing-default;
    &._with_shadow {
        box-shadow: 0 0 3px 0 fade(@color-primary, 10%);
    }
    &._gray {
        background-color: @color-gray-lighter;
    }
    &._transparent {
        color: @color-gray-lightest;

        background-color: transparent;
    }
    &__container {
        .container();

        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    &__burger {
        display: none;
    }
    &__logo {
        flex: 0 0 auto;
        display: block;
        padding: 16px 0;
        margin-right: 32px;

        font-size: 2.4rem;
        text-decoration: none;

        cursor: pointer;
    }
    &__menu {
        .typography-body();

        flex: 1 1 auto;
        &-item {
            margin-right: 21px;

            text-decoration: none;
            white-space: nowrap;
        }
    }
    &__phone {
        flex: 0 0 auto;
        justify-self: flex-end;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 36px;
        padding-right: 16px;
        border-right: 1px solid @color-gray-light;
        margin-right: 16px;

        text-align: right;
        text-decoration: none;
        &-icon {
            display: inline-block;
            padding: 4px;

            font-size: 1.6rem;
        }
        &-number {
            .typography-body();
        }
    }
    &__modal {
        display: none;
    }
    @media @media-lg-down {
        &__burger {
            position: relative;
            z-index: 1;

            display: block;

            font-size: 2.2rem;

            cursor: pointer;
            &:before {
                content: '';

                position: absolute;
                top: -12px;
                right: -12px;
                bottom: -12px;
                left: -12px;
                z-index: 1;

                display: block;
            }
        }
        &__logo {
            padding: 20px 0 18px 13px;
            margin: 0 auto;

            font-size: 1.8rem;
        }
        &__menu,
        &__phone {
            display: none;
        }
        &__modal {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: @z-index-header-menu;

            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: flex-start;
            padding: 8px 40px 40px;

            color: @color-gray-lightest;

            background-color: @color-primary;
            background-image: @color-gradient-background;
            opacity: 0;
            pointer-events: none;

            transition: opacity @duration-fast ease-in-out;

            .cms-toolbar-expanded & {
                padding-top: @container-cms-height;
            }
            &._active {
                opacity: 1;
                pointer-events: all;
            }
            &-header {
                flex: 0 0 auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 60px;
                &-close {
                    position: relative;
                    z-index: 1;

                    flex: 0 0 auto;
                    display: block;

                    font-size: 2.2rem;

                    cursor: pointer;

                    &:before {
                        content: '';

                        position: absolute;
                        top: -12px;
                        right: -12px;
                        bottom: -12px;
                        left: -12px;
                        z-index: 1;

                        display: block;
                    }
                }
                &-info {
                    flex: 1 1 auto;
                    display: block;
                    padding-right: 8px;

                    text-align: right;
                    &-name {
                        .typography-heading();

                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    &-email {
                        .typography-caption();

                        margin-top: -1px;

                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
                &-home {
                    flex: 0 0 auto;
                }
            }
            &-menu {
                flex: 1 1 auto;
                padding: 31px 0;
                overflow: auto;
                &-item {
                    display: block;
                    &-text {
                        .typography-medium();

                        display: inline-block;
                        padding: 11px 0;

                        text-decoration: none;
                    }
                }
            }
            &-contact {
                flex: 0 0 auto;
                & + & {
                    margin-top: 24px;
                }
                &-title {
                    .typography-body();
                }
                &-content {
                    &-text {
                        .typography-large();

                        display: inline-block;

                        text-decoration: none;
                    }
                }
            }
        }
    }
    @media @media-md-down {
        height: 60px;
        &__modal {
            padding: 0 24px 40px;
        }
    }
}
</style>
