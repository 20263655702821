<template>
    <section class="page-referral-landing">
        <div class="page-referral-landing--container">
            <main class="page-referral-landing__content">
                <slot />
            </main>
            <plugin-referral-footer v-bind:description="`* С подробными условиями о вознаграждении Партнера и иными условиями сотрудничества с Партнерами можно ознакомиться в Договоре возмездного оказания услуг (стандартная форма договора присоединения).
Договор с Партнерами заключается ООО «ПНК Девелопмент» (ОГРН 1216600009922), являющимся агентом по привлечению Партнеров ООО УК «А класс капитал» (ОГРН 1177746042836), оказывающим финансовые услуги на основании лицензии профессионального участника рынка ценных бумаг на осуществление деятельности по управлению ценными бумагами от 12.08.2019 г. № 045-14079-001000.
PNK rental (ПНК аренда, с английского) - маркетинговое наименование стандартной стратегии доверительного управления, предполагающей инвестирование в паевой инвестиционный фонд, доход по инвестиционным паям которого формируется за счет арендных платежей (на сайте, в том числе, представлена информация об объектах индустриальной недвижимости, которые согласно своим характеристикам могут входить в указанный паевой инвестиционный фонд).
Размещенная на данном сайте информация носит исключительно информационный характер, представленная информация не является индивидуальной инвестиционной рекомендацией, не является гарантией (или обещанием) эффективности деятельности, доходности вложений инвесторов. Под ожидаемой целевой доходностью подразумевается прогнозируемая доходность по арендным платежам на индустриальные здания в РФ, рассчитанная по средним рыночным показателям, при этом размер и/или наличие арендных платежей зависит от наличия арендаторов, размера арендных ставок, стоимости объектов недвижимости и т.п. Указание примеров ожиданий доходности является предположительным, ООО УК «А класс капитал» не гарантирует доходность инвестиций.
Информация не может рассматриваться как публичная оферта, предложения или приглашения приобрести, или продать какие-либо ценные бумаги или финансовые инструменты, иные объекты, совершить с ними сделки. Не является рекламой ценных бумаг.
Сайт использует файлы cookie. Подробная информация в Политике в отношении обработки персональных данных. Вы можете запретить сохранение cookie в настройках своего браузера.`" />
        </div>
    </section>
</template>

<script>
export default {
    name: 'page-referral-landing',
};
</script>

<style scoped lang="less">
@import '~theme';

.page-referral-landing {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    overflow: hidden;

    color: @color-gray-lightest;

    background-color: @color-gray-darker;
    background-size: 720px;
    background-position: center top;
    background-repeat: repeat;
    background-image: url(/static/images/page-referral-landing-bg.jpg);
    &--container {
        .container();
        .container-paddings();

        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        margin-top: 80px;
    }
    &__content {
        flex: 1 1 auto;
        padding: 0 115px;

        @media @media-lg-down {
            padding: 0 75px;
        }

        @media @media-md-down {
            padding: 0 35px;
        }

        @media @media-sm-down {
            padding: 0;
        }
    }
}
</style>
