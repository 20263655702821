<template>
    <div class="plugin-referral-statistics-item">
        <img v-bind:src="image"
             class="plugin-referral-statistics-item__image"
             alt="statistic-image"
        />
        <div class="plugin-referral-statistics-item__title"
             v-if="title"
             v-html="title"
        />
        <div class="plugin-referral-statistics-item__description"
             v-if="description"
             v-html="description"
        />
    </div>
</template>

<script>
const imagesVariants = {
    medal: '/static/images/referral/statistics/medal.png',
    check: '/static/images/referral/statistics/calc.png',
    investors: '/static/images/referral/statistics/wallet.png',
};

export default {
    name: 'plugin-referral-statistics-item',
    props: {
        title: {
            type: String,
            default: null,
        },
        description: {
            type: String,
            default: null,
        },
        variant: {
            type: String,
            default: null,
        },
    },
    computed: {
        image() {
            return imagesVariants[this.variant || 'medal'];
        },
    },
};
</script>

<style lang="less">
@import '~theme';

.plugin-referral-statistics-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-basis: 200px;

    min-width: 280px;

    margin-bottom: 30px;

    &__image {
        margin-bottom: 16px;
    }

    &__title {
        .typography-extra-large();

        color: rgba(255, 255, 255, 0.6);

        margin-bottom: 16px;

        span {
            color: @color-gray-lightest;
        }
    }

    &__description {
        .typography-small();

        color: rgba(255, 255, 255, 0.6);

        p {
            margin: 0;
        }
    }

    @media @media-md-down {
        &__title {
            .typography-body-lg();
        }
    }
}
</style>
