import config from '~/config';
import {
    ogrn as commonOgrn,
    okved as commonOkved,
} from '~/forms/fields/other';

export const ogrnip = {
    ...commonOgrn,
    name: 'ogrnip',
    label: 'ОГРНИП',
    errors: {
        required: `Введите ОГРНИП`,
        isValid: `Введите ${config.common.ogrnipLength} цифр ОГРНИП`,
    },
};

export const ipName = {
    name: 'ipName',
    label: 'Название ИП',
    value: '',
    initialValue: '',
    placeholder: '',
    type: 'text',
    errors: {
        required: `Введите наименование организации (ФИО ИП)`,
    },
};

export const ipAddress = {
    name: 'ipAddress',
    label: 'Юридический адрес ИП',
    value: '',
    initialValue: '',
    placeholder: '',
    type: 'text',
    errors: {
        required: 'Введите юридический адрес организации',
    },
};

export const juridicalOgrn = {
    ...commonOgrn,
    name: 'juridicalOgrn',
};

export const juridicalOkved = {
    ...commonOkved,
    name: 'juridicalOkved',
};

export const juridicalName = {
    name: 'juridicalName',
    label: 'Название организации',
    value: '',
    initialValue: '',
    placeholder: '',
    type: 'text',
    errors: {
        required: 'Введите название юридического лица',
    },
};

export const juridicalNameFull = {
    name: 'juridicalNameFull',
    label: 'Полное название организации',
    value: '',
    initialValue: '',
    placeholder: '',
    type: 'text',
    errors: {
        required: 'Введите полное название юридического лица',
    },
};

export const juridicalAddress = {
    name: 'juridicalAddress',
    label: 'Юридический адрес организации',
    value: '',
    initialValue: '',
    placeholder: '',
    type: 'text',
    errors: {
        required: 'Введите юридический адрес юридического лица',
    },
};

export const juridicalRegistrationAddress = {
    name: 'juridicalRegistrationAddress',
    label: 'Адрес регистрации организации',
    value: '',
    initialValue: '',
    placeholder: '',
    type: 'text',
    errors: {
        required: 'Введите адрес регистрации юридического лица',
    },
};

export const juridicalPostalAddress = {
    name: 'juridicalPostalAddress',
    label: 'Почтовый адрес организации',
    value: '',
    initialValue: '',
    placeholder: '',
    type: 'text',
    errors: {
        required: 'Введите почтовый адрес юридического лица',
    },
};

export const juridicalBankName = {
    name: 'juridicalBankName',
    label: 'Название банка',
    value: '',
    initialValue: '',
    placeholder: '',
    type: 'text',
    errors: {
        required: 'Введите название банка',
    },
};

export const juridicalKpp = {
    name: 'juridicalKpp',
    label: 'КПП',
    value: '',
    initialValue: '',
    type: 'numeric',
    maxLength: config.common.kppLength,
    errors: {
        required: 'Введите КПП',
        isValid: `Введите ${config.common.kppLength} цифр КПП`,
    },
};

export const juridicalBik = {
    name: 'juridicalBik',
    label: 'БИК',
    value: '',
    initialValue: '',
    type: 'numeric',
    maxLength: config.common.bikLength,
    errors: {
        required: 'Введите БИК',
        isValid: `Введите ${config.common.bikLength} цифр БИК`,
    },
};

export const juridicalCorrAccount = {
    name: 'juridicalCorrAccount',
    label: 'Корреспондентский счет',
    value: '',
    initialValue: '',
    type: 'numeric',
    errors: {
        required: 'Введите корреспондентский счет',
    },
};

export const juridicalRegistrationDate = {
    name: 'juridicalRegistrationDate',
    label: 'Дата регистрации',
    value: '',
    initialValue: '',
    type: 'date',
    errors: {
        required: 'Введите дату регистрации',
        isValid: 'Введите корректную дату в формате ДД.ММ.ГГГГ',
    },
};

export const juridicalBankAccount = {
    name: 'juridicalBankAccount',
    label: 'Номер счета',
    value: '',
    initialValue: '',
    type: 'numeric',
    errors: {
        required: 'Введите номер счета',
        isValid: 'Введите только цифры',
    },
};
