<template>
    <div class="page-admin-estates-item-remove">
        <div class="page-admin-estates-item-remove__loader"
            v-if="removeEstateIsLoading"
            >
            <ui-loader />
        </div>
        <template v-else>
            <div class="page-admin-estates-item-remove__options"
                v-if="isRemoveInitiated"
                >
                <div class="page-admin-estates-item-remove__options-item">
                    <ui-button
                        v-bind:variant="isSmallScreen ? 'large' : 'small'"
                        v-on:click="cancel"
                        theme="secondary-2"
                        >
                        Отмена
                    </ui-button>
                </div>
                <div class="page-admin-estates-item-remove__options-item">
                    <ui-button
                        v-bind:variant="isSmallScreen ? 'large' : 'small'"
                        v-on:click="remove"
                        theme="primary"
                        >
                        Подтвердить удаление
                    </ui-button>
                </div>
            </div>
            <div class="page-admin-estates-item-remove__initializer"
                v-else
                v-on:click="initialize"
                >
                <div class="page-admin-estates-item-remove__initializer-icon">
                    <icon name="trash" />
                </div>
                <div class="page-admin-estates-item-remove__initializer-text">
                    Удалить объект
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import config from '~/config';

export default {
    name: 'page-admin-estates-item-remove',
    props: [ 'estateId' ],
    data: () => ({
        isRemoveInitiated: false,
    }),
    computed: {
        ...mapState('admin/estates', {
            removeEstateResult: state => state.removeEstateResult,
            removeEstateError: state => state.removeEstateError,
            removeEstateIsLoading: state => state.removeEstateIsLoading,
        }),
        isSmallScreen() {
            return window.innerWidth < 768;
        },
    },
    methods: {
        initialize() {
            this.isRemoveInitiated = true;
        },
        cancel() {
            this.isRemoveInitiated = false;
        },
        remove() {
            this.$store.dispatch('admin/estates/removeEstate', { id: this.estateId });
        },
    },
    watch: {
        removeEstateResult(newVal, oldVal) {
            if (newVal && !oldVal) {
                window.location.href = config.urls.adminDashboard.index;
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-admin-estates-item-remove {
    &__loader {
        width: 40px;

        line-height: 0;
    }
    &__options {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &-item {
            flex: 0 0 auto;
            padding: 0 8px;
        }
    }
    &__initializer {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        color: @color-gray-main;

        cursor: pointer;
        &-icon {
            flex: 0 0 auto;
            margin-right: 10px;

            font-size: 2rem;
        }
        &-text {
            .typography-heading();
        }
    }
    @media @media-xs-down {
        &__loader {
            width: 56px;
        }
        &__options {
            flex-wrap: wrap;
            justify-content: center;
            &-item {
                padding: 8px;
            }
        }
    }
}
</style>
