<template>
    <div class="page-cabinet-profile-personal">
        <ui-infolist
            v-bind:info="info"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'page-cabinet-profile-personal',
    computed: {
        ...mapState('users', [
            'getUserProfileResult',
            'getUserPersonalInfoResult',
        ]),
        info() {
            return [
                { title: 'ФИО', value: this.getUserProfileResult.fullName, isCopyAllowed: true },
                { title: 'Email', value: this.getUserProfileResult.email, isCopyAllowed: true },
                { title: 'Телефон', value: this.getUserProfileResult.phone, isCopyAllowed: true },
                {
                    title: 'Паспорт',
                    value: `${this.getUserPersonalInfoResult.passportSeries} ${this.getUserPersonalInfoResult.passportNumber}`,
                    isCopyAllowed: true,
                },
                {
                    title: 'Тип аккаунта',
                    value: this.getUserProfileResult.legalType === 'F'
                        ? 'Физическое лицо'
                        : this.getUserProfileResult.legalType === 'J'
                            ? 'Юридическое лицо'
                            : 'Индивидуальный предприниматель',
                },
                { title: 'Внутренний счет', value: this.getUserProfileResult.accountCode, isCopyAllowed: true },
            ];
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-profile-personal {}
</style>
