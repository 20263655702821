// import moment from 'moment';
import { date as dateTransform } from '~/network/mainClient/common/transforms/requests';

export const issueInvestmentOffer = (data) => {
    if (!data) {
        return null;
    }
    return {
        value: data.value,
        code: data.code,
        id: data.id,
        issue_date: dateTransform(data.issueDate),
        issue_number: data.issueNumber,
        investment_expire_date: dateTransform(data.investmentExpireDate),
        investment_agreement: data.investmentAgreement,
        price: data.price,
        purpose: data.purpose,
        preference_shares: data.preferenceSharesAmount,
    };
};

// admin estate info field
export const info = (data) => {
    if (!data) {
        return null;
    }
    return {
        id: data.id,
        max_loan_term: dateTransform(data.maxLoanTerm),
        share_capital: data.shareCapital,
    };
};

export const adminEstateItem = (data) => {
    const fields = {
        name: data.title,
        address: data.address,
        description: data.description,
        total_cost: data.totalValue,
        current_dividend_profitability: data.currentDividendProfitability,
        target_profitability: data.targetDividendProfitability,
        target: data.target,
        year_percent: data.yearPercent,
        cap_rate: data.capRate,
        gba: data.totalSquare,
        gla: data.rentableSquare,
        occupancy: data.occupancy,
        minimum_investment_amount: data.minimalInvestmentAmount,
        annual_indexing: data.annualIndexing,
        is_active: data.isActive,
        status: data.status,
        investment_types: data.availableInvestmentTypes,
        juridical_info: {
            organization_name: data.orgTitle,
            organization_address: data.orgAddress,
            legal_address: data.orgJurAddress,
            mailing_address: data.orgMailAddress,
            ogrn: data.ogrn,
            okved: data.okved,
            inn: data.inn,
            bank_name: data.bankTitle,
            kpp: data.kpp,
            bik: data.bik,
            bank_account: data.bankAccount,
            cadastral_account: data.cadastralAccount,
            date_registration: dateTransform(data.registrationDate),
        },
    };
    // passport_number: data.passportNumber,
    // passport_date: moment(data.passportDate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
    // subdivision_name: data.passportIssuer,
    // subdivision_code: data.passportIssuerCode,
    // registration_address: data.passportAddress,
    // birth_date: moment(data.passportBirthDate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
    if (data.type?.id && parseInt(data.type.id, 10) !== -1) {
        fields.type = data.type.id;
    }
    if (data.info) {
        fields.info = info(data.info);
    }
    const hasImage = data.image && typeof data.image !== 'string';
    const hasDocument = data.document && typeof data.document !== 'string';
    if (hasImage) {
        fields.image = data.image;
    }
    if (hasDocument) {
        fields.document_title = data.documentTitle;
        fields.document = data.document;
    }
    if (hasImage || hasDocument) {
        const result = new FormData();
        // fields.juridical_info = JSON.stringify(fields.juridical_info);
        for (const key in fields) {
            if (fields[key]) {
                result.append(key, fields[key]);
            }
        }
        return result;
    } else {
        return fields;
    }
};
