<template>
    <div class="page-admin-estates-item-shareholders-item">
        <div class="page-admin-estates-item-shareholders-item__content">
            <div class="page-admin-estates-item-shareholders-item__content-date"
                v-text="item.date"
            />
            <div class="page-admin-estates-item-shareholders-item__content-info"
                v-text="`${item.name} / ${item.account}`"
            />
            <div class="page-admin-estates-item-shareholders-item__content-quantity"
                v-text="quantity"
            />
            <!-- <div class="page-admin-estates-item-shareholders-item__content-file">
                <icon name="document" />
            </div> -->
        </div>
    </div>
</template>

<script>
import utils from '~/utils';

export default {
    name: 'page-admin-estates-item-shareholders-item',
    props: {
        item: {
            type: Object,
        },
    },
    computed: {
        quantity() {
            return utils.formats.formatNumberWithSpaces(this.item.quantity);
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-admin-estates-item-shareholders-item {
    .typography-body();

    position: relative;

    padding: 16px;
    border-radius: @border-radius-lg;

    background-color: @color-gray-lightest;
    &__content {
        display: flex;
        align-items: center;
        &-date {
            flex: 0 0 100px;

            color: @color-gray-main;
        }
        &-info {
            flex: 1 1 auto;
            padding-right: 16px;
        }
        &-quantity {
            flex: 0 0 173px;
        }
        // &-file {
        //     flex: 0 0 auto;

        //     font-size: 1.8rem;
        //     color: @color-gray-main;

        //     cursor: pointer;
        //     transition: color @duration-fast @easing-default;
        //     &:hover {
        //         color: @color-gray-darkest;
        //     }
        // }
    }
    @media @media-sm-down {
        &__content {
            display: block;
            &-date {
                // padding-right: 80px;
                margin: 0 0 4px;
            }
            &-info {
                margin: 0 0 4px;
            }
            // &-file {
            //     position: absolute;
            //     top: 3px;
            //     right: 0;
            //     z-index: 1;

            //     padding: 16px;
            // }
        }
    }
}
</style>
