<template>
    <form class="ui-form"
        v-on:submit.prevent
        v-on:keydown.enter="submitHandler"
        v-bind:class="{
            _gap_wide: gap === 'wide',
        }"
        >
        <div class="ui-form__fields">
            <div class="ui-form__fields-item"
                v-for="(field, key, i) in model"
                v-bind:key="key"
                v-bind:class="{
                    _indent: field.withIndent,
                    _half: field.isHalf,
                    _fill: field.isFill,
                    _fill_left: field.isFillLeft,
                }"
                v-bind:style="{ zIndex: field.type === 'select' ? Object.keys(model).length - i : null }"
                >
                <ui-form-field
                    v-bind:model="field"
                    v-bind:validation="getFieldValidation(key)"
                    v-bind:value="field.value"
                    v-on:update="val => modelUpdate(val, key)"
                />
            </div>
        </div>
    </form>
</template>

<script>
import utils from '~/utils';

export default {
    name: 'ui-form',
    props: {
        model: {
            type: Object,
            default: () => ({}),
        },
        validation: {
            type: Object,
            default: null,
        },
        validationPath: {
            type: String,
            default: 'fields',
        },
        submitHandler: {
            type: Function,
            default: () => ({}),
        },
        gap: {
            type: String,
            default: 'normal',
        },
    },
    model: {
        prop: 'model',
        event: 'update',
    },
    methods: {
        modelUpdate(val, key) {
            const result = {
                ...this.model,
                [key]: {
                    ...this.model[key],
                    value: val,
                },
            };
            // this.$emit('before-update', { key, value: val });
            this.$emit('update', result);
            // this.$emit('after-update', { key, value: val });
        },
        getFieldValidation(key) {
            if (!this.validation) {
                return null;
            }
            return utils.common.getObjectValueByPath(this.validation, this.validationPath + '.' + key + '.value');
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';
.ui-form {
    &__fields {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin: 0 -12px 7px;
        ._gap_wide > & {
            margin-left: -28px;
            margin-right: -28px;
        }
        ._gap_wide > & &-item {
            padding-left: 28px;
            padding-right: 28px;
        }
        &-item {
            flex: 1 1 auto;
            width: 100%;
            padding: 0 12px;
            &._half {
                flex: 0 1 auto;
                width: 50%;
            }
            &._fill {
                padding-right: calc(50% + 12px);
            }
            &._fill_left {
                padding-left: calc(50% + 12px);
            }
            &:not(:last-child) {
                margin-bottom: 25px;
            }
        }
    }
    @media @media-xs-down {
        &__fields {
            margin: 0 0 7px;
            &-item {
                padding: 0;
                &._half {
                    width: 100%;
                }
                &._fill {
                    padding-right: 0;
                }
                &._fill_left {
                    padding-left: 0;
                }
            }
        }
    }
}
</style>
