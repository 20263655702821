export const additionalHasOtherBeneficiary = {
    name: 'additionalHasOtherBeneficiary',
    label: 'Наличие выгодоприобретателей',
    value: '',
    type: 'radio-group',
    options: [
        { value: 'no', text: 'Нет' },
        { value: 'yes_individual', text: 'Да, является физическим лицом' },
        { value: 'yes_juridical', text: 'Да, является юридическим лицом' },
        { value: 'yes_individual_entrepreneur', text: 'Да, является индивидуальным предпринимателем' },
    ],
    errors: {
        required: 'Выберите один из вариантов',
    },
    labelHint: {
        text: `Выбирая вариант “Нет”, вы подтверждаете, что действуете только к своей собственной выгоде и от собственного лица.\nВыгодоприобретатель - лицо, не участвующее в операции, к выгоде которого действует Клиент, в том числе, на основании агентского договора, договоров поручения, комиссии и доверительного управления, при проведении операций с денежными средствами и иным имуществом`,
        isWide: true,
    },
};

export const additionalIncomeSource = {
    name: 'additionalIncomeSource',
    label: 'Информация об источниках происхождения денежных средств/имущества',
    value: '',
    type: 'radio-group',
    options: [
        { value: 'main_place_work', text: 'Доход по основному месту работы' },
        { value: 'part_time_work', text: 'Доход от работы по совместительству' },
        { value: 'deposits', text: 'Доход от вкладов, открытых на мое имя' },
        { value: 'securities_transactions', text: 'Доходы от совершения сделок с ценными бумагами и производными финансовыми инструментами' },
        { value: 'commercial_dividends', text: 'Дивиденды от участия в коммерческих организациях' },
        { value: 'accumulations', text: 'Накопления прошлых лет' },
        { value: 'assets_sale', text: 'Доходы от реализации движимого/недвижимого имущества, иных активов' },
        { value: 'borrowed_funds', text: 'Кредитные, заемные средства' },
        { value: 'inheritance', text: 'Наследство' },
        { value: 'pension', text: 'Пенсия' },
        { value: 'other', text: 'Другое' },
    ],
    errors: {
        required: 'Выберите один из вариантов',
    },
};

export const additionalIncomeSourceCustom = {
    name: 'additionalIncomeSourceCustom',
    value: '',
    initialValue: '',
    placeholder: 'Место работы / иной источник',
    type: 'text',
    errors: {
        required: 'Укажите место источник средств',
    },
};

export const additionalFinancialPosition = {
    name: 'additionalFinancialPosition',
    label: 'Финансовое положение',
    value: '',
    type: 'radio-group',
    options: [
        { value: 'Имею постоянный доход' },
        { value: 'Не имею доход' },
        { value: 'other', text: 'Иное' },
    ],
    errors: {
        required: 'Выберите один из вариантов',
    },
};

export const additionalIsBeneficiaryOwner = {
    name: 'additionalIsBeneficiaryOwner',
    label: 'Бенефициарное владение',
    value: 'Являюсь бенефициарным владельцем',
    type: 'radio-group',
    options: [
        { value: true, text: 'Являюсь бенефициарным владельцем' },
        { value: false, text: 'Не являюсь бенефициарным владельцем' },
    ],
    errors: {
        required: 'Выберите один из вариантов',
    },
    labelHint: {
        text: 'Выбирая вариант “Являюсь бенефициарным владельцем”, вы подтверждаете, что действуете от своего имени и иные физические лица, имеющие возможность контролировать мои действия (определять решения), отсутствуют',
        isWide: true,
    },
};

// export const additionalFinancialPositionCustom = {
//     name: 'additionalFinancialPositionCustom',
//     value: '',
//     initialValue: '',
//     placeholder: 'Финансовое положение',
//     type: 'text',
//     errors: {
//         required: 'Укажите ваше финансовое положение',
//     },
// };

// export const additionalBusinessReputation = {
//     name: 'additionalBusinessReputation',
//     label: 'Деловая репутация',
//     value: '',
//     type: 'radio-group',
//     options: [
//         { value: 'Положительная' },
//         { value: 'Отрицательная' },
//     ],
//     errors: {
//         required: 'Выберите один из вариантов',
//     },
// };

// IP fields
// export const additionalIpFinHasAnnualAccountingStatement = {
//     name: 'additionalIpFinHasAnnualAccountingStatement',
//     label: 'Годовая отчетность',
//     value: '',
//     type: 'radio-group',
//     options: [
//         { value: true, text: 'Да' },
//         { value: false, text: 'Нет' },
//     ],
//     errors: {
//         required: 'Выберите один из вариантов',
//     },
// };

// export const additionalIpFinHasRegularTaxReturn = {
//     name: 'additionalIpFinHasRegularTaxReturn',
//     label: 'Годовая / квартальная налоговая декларация',
//     value: '',
//     type: 'radio-group',
//     options: [
//         { value: true, text: 'Да' },
//         { value: false, text: 'Нет' },
//     ],
//     errors: {
//         required: 'Выберите один из вариантов',
//     },
// };

// export const additionalIpFinHasAuditorReport = {
//     name: 'additionalIpFinHasAuditorReport',
//     label: 'Аудиторское заключение на годовой отчет за прошедший год',
//     value: '',
//     type: 'radio-group',
//     options: [
//         { value: true, text: 'Да' },
//         { value: false, text: 'Нет' },
//     ],
//     errors: {
//         required: 'Выберите один из вариантов',
//     },
// };

// export const additionalIpFinHasTaxFullfilmentReport = {
//     name: 'additionalIpFinHasTaxFullfilmentReport',
//     label: 'Справка об исполнении обязанности по уплате налогов, сборов, пеней, штрафов',
//     value: '',
//     type: 'radio-group',
//     options: [
//         { value: true, text: 'Да' },
//         { value: false, text: 'Нет' },
//     ],
//     errors: {
//         required: 'Выберите один из вариантов',
//     },
// };

// export const additionalIpFinHasBankruptCertificate = {
//     name: 'additionalIpFinHasBankruptCertificate',
//     label: 'Сведения о наличии производства по делу о несостоятельности (банкротстве), вступивших в силу решений судебных органов о признании несостоятельным (банкротом), проведении процедур ликвидации',
//     value: '',
//     type: 'radio-group',
//     options: [
//         { value: true, text: 'Да' },
//         { value: false, text: 'Нет' },
//     ],
//     errors: {
//         required: 'Выберите один из вариантов',
//     },
// };

// export const additionalIpFinBankruptStep = {
//     name: 'additionalIpFinBankruptStep',
//     label: 'Стадия процедуры банкротства',
//     value: '',
//     type: 'radio-group',
//     options: [
//         { value: 'observation', text: 'Наблюдение' },
//         { value: 'financial_recovery', text: 'Финансовое оздоровление' },
//         { value: 'external_control', text: 'Внешнее управление' },
//         { value: 'bankruptcy_proceedings', text: 'Конкурсное производство' },
//         { value: 'settlement_agreement', text: 'Мировое соглашение' },
//     ],
//     errors: {
//         required: 'Выберите один из вариантов',
//     },
// };

export const additionalPublicOfficial = {
    name: 'additionalPublicOfficial',
    label: 'Принадлежность к ПДЛ',
    value: 'Не принадлежу к ПДЛ',
    type: 'radio-group',
    options: [
        { value: 'no', text: 'Не принадлежу к ПДЛ' },
        { value: 'yes', text: 'Принадлежу к ПДЛ' },
    ],
    errors: {
        required: 'Выберите один из вариантов',
    },
    labelHint: {
        text: 'Выберите пункт "Принадлежу к ПДЛ", если являетесь ИПДЛ/ДЛПМО/РПДЛ или родственником, супругом(ами), близким(и) родственником(ками) (родственником по прямой восходящей и нисходящей линии (родителем и ребенком, дедушкой, бабушкой и внуком), полнородным(и) и неполнородным(и) (имеющим(и) общих отца и мать) братом(тьями) и сестрой(ами), усыновителем(ями) и усыновленным(и)) иностранного публичного должностного лица или лицом(ами), действующим(и) от имени иностранного публичного должностного лица',
        isWide: true,
    },
};
