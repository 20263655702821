<template>
    <section class="page-cabinet-settings">
        <div class="page-cabinet-settings__container">
            <div class="page-cabinet-settings__block">
                <page-cabinet-settings-card
                    v-bind:title="'Сменить пароль'"
                    >
                    <page-cabinet-settings-form-password />
                </page-cabinet-settings-card>
            </div>
            <div class="page-cabinet-settings__block">
                <page-cabinet-settings-card
                    v-bind:title="'Безопасность'"
                    >
                    <page-cabinet-settings-form-auth />
                </page-cabinet-settings-card>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'page-cabinet-settings',
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-settings {
    width: 100%;
    padding: 20px 0 0;
    &__container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        width: ~'calc(100% + 20px)';
        margin: 0 -10px;
    }
    &__block {
        flex: 1 1 auto;
        width: calc(50% - 20px);
        min-width: 420px;
        margin: 0 10px 40px;
        &._small {
            max-width: 370px;
            width: 370px;
        }
    }
    @media @media-sm-down {
        &__block {
            order: -1;
            min-width: 100%;
        }
    }
}
</style>
