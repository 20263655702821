import { mainClient } from '~/network';

const moduleTitle = 'dashboard';

// initial state
const state = {
    getHighlightsResult: null,
    getHighlightsError: null,
    getHighlightsIsLoading: false,

    getWithdrawInfoResult: null,
    getWithdrawInfoError: null,
    getWithdrawInfoIsLoading: false,

    requestWithdrawResult: null,
    requestWithdrawError: null,
    requestWithdrawIsLoading: false,
};

// getters
const getters = {
};

// actions
const actions = {
    async getHighlights({ state, commit }, payload = {}) {
        if (state.getHighlightsIsLoading) {
            return;
        }
        state.getHighlightsIsLoading = true;
        const { data, error, status } = await mainClient.users.requests.getDashboardHighlights(payload);
        if (error) {
            console.error(`Error in action ${moduleTitle}/getHighlights (${status})`);
            console.error(error, status);
            state.getHighlightsResult = null;
            state.getHighlightsError = error;
        } else {
            state.getHighlightsError = null;
            state.getHighlightsResult = data;
        }
        state.getHighlightsIsLoading = false;
    },
    async requestWithdraw({ state }, payload = {}) {
        if (state.requestWithdrawIsLoading) {
            return;
        }
        state.requestWithdrawIsLoading = true;
        const { data, error, status } = await mainClient.users.requests.requestWithdraw(payload);
        if (error) {
            console.error(`Error in action ${moduleTitle}/requestWithdraw (${status})`);
            console.error(error, status);
            state.requestWithdrawResult = null;
            state.requestWithdrawError = error;
        } else {
            state.requestWithdrawError = null;
            state.requestWithdrawResult = data;
        }
        state.requestWithdrawIsLoading = false;
    },
    async getWithdrawInfo({ state }, payload = {}) {
        if (state.getWithdrawInfoIsLoading) {
            return;
        }
        state.getWithdrawInfoIsLoading = true;
        const { data, error, status } = await mainClient.users.requests.getWithdrawInfo(payload);
        if (error) {
            console.error(`Error in action ${moduleTitle}/getWithdrawInfo (${status})`);
            console.error(error, status);
            state.getWithdrawInfoResult = null;
            state.getWithdrawInfoError = error;
        } else {
            state.getWithdrawInfoError = null;
            state.getWithdrawInfoResult = data;
        }
        state.getWithdrawInfoIsLoading = false;
    },
};

const mutations = {
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
