<template>
    <main class="page-admin-login">
        <div class="page-admin-login__overlay" />
        <div class="page-admin-login__content">
            <transition name="fade">
                <div class="page-admin-login__content-loader"
                    v-if="loginIsLoading || (loginResult && !loginError)"
                    >
                    <div class="page-admin-login__content-loader-container">
                        <ui-loader />
                    </div>
                </div>
            </transition>
            <div class="page-admin-login__content-title"
                v-text="'Вход в личный кабинет ЛПИ'"
            />
            <div class="page-admin-login__content-form">
                <page-admin-login-form />
            </div>
        </div>
    </main>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'page-admin-login',
    computed: {
        ...mapState('users', [
            'loginResult',
            'loginIsLoading',
            'loginError',
        ]),
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-admin-login {
    .container-paddings();

    position: relative;

    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;

    background-image: url('/static/images/page-password-background.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    &__overlay {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background-color: @color-gray-darkest;

        opacity: 0.8;
    }
    &__content {
        position: relative;

        max-width: 438px;
        width: calc(100vw - 130px);
        padding: 40px;
        border-radius: @border-radius-lg;
        overflow: hidden;

        background-color: @color-gray-lightest;
        &-loader {
            .transition-fade();

            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;

            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;

            background-color: @color-gray-lightest;
            &-container {
                width: 100px;
            }
        }
        &-title {
            .typography-large();

            margin-bottom: 24px;

            color: @color-primary;
        }
        &-form {
        }
    }
    @media @media-sm-down {
        padding: 0;

        background-image: none;
        &__content {
            max-width: 100%;
            width: 100%;
            min-height: 100%;
            border-radius: 0;
        }
    }
}
</style>
