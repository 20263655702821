import { mainClient } from '~/network';

const state = {
    createReferrerIsLoading: false,
    createReferrerError: null,
    createReferrerResult: null,

    getReferralsIsLoading: false,
    getReferralsError: null,
    getReferralsResult: null,
};

const getters = {
};

const actions = {
    async createReferrer({ state }, payload) {
        if (state.createReferrerIsLoading) {
            return;
        }
        state.createReferrerIsLoading = true;

        const { data, error, status } = await mainClient.referral.requests.createReferrer(payload);
        if (error) {
            console.error(error, status);
            state.createReferrerError = error;
            state.createReferrerResult = null;
        } else {
            state.createReferrerError = null;
            state.createReferrerResult = data;
        }
        state.createReferrerIsLoading = false;
    },
    async getReferrals({ state }, payload) {
        if (state.getReferralsIsLoading) {
            return;
        }
        state.getReferralsIsLoading = true;

        const { data, error, status } = await mainClient.referral.requests.getReferrals({ page_size: 100 });
        if (error) {
            console.error(error, status);
            state.getReferralsError = error;
            state.getReferralsResult = null;
        } else {
            state.getReferralsError = null;
            state.getReferralsResult = data;
        }
        state.getReferralsIsLoading = false;
    },
};

const mutations = {
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
