<template>
    <component class="ui-label"
        v-bind:class="[
            `_theme_${theme}`,
            `_weight_${weight}`,
            {
                _pointer: href || action,
                _for_form: isInForm,
                _uppercase: isUppercase,
                _inline: isInline,
            }
        ]"
        v-bind:is="href ? 'a' : 'div'"
        v-bind:href="href ? href : null"
        v-on:click="clickHandler"
        >
        <div class="ui-label__icon _left"
            v-if="iconLeft"
            >
            <icon v-bind:name="iconLeft" />
        </div>
        <slot v-if="$slots.default" />
        <div class="ui-label__text" v-else>
            <span class="ui-label__text-title"
                v-if="title"
                v-bind:class="{
                    _bold: title && title.isBold,
                }"
                v-text="titleParsed + (valueParsed ? ': ' : '')"
            /><span class="ui-label__text-value"
                v-if="valueParsed"
                v-bind:class="{
                    _bold: value && (value.isBold === undefined || value.isBold),
                }"
                v-text="valueParsed"
            />
        </div>
        <div class="ui-label__icon _right"
            v-if="iconRight"
            >
            <icon v-bind:name="iconRight" />
        </div>
    </component>
</template>

<script>
export default {
    name: 'ui-label',
    props: {
        href: {
            type: String,
            default: null,
        },
        title: {
            type: [ String, Number, Object ],
            default: null,
        },
        value: {
            type: [ String, Number, Object ],
            default: null,
        },
        action: {
            type: Function,
            default: null,
        },
        theme: {
            type: String,
            default: 'default',
        },
        weight: {
            type: String,
            default: 'bold',
        },
        iconLeft: {
            type: String,
            default: null,
        },
        iconRight: {
            type: String,
            default: null,
        },
        isInForm: {
            type: Boolean,
            default: false,
        },
        isUppercase: {
            type: Boolean,
            default: false,
        },
        isInline: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        titleParsed() {
            if (!this.title) {
                return null;
            }
            let result = this.title;
            if (typeof result === 'object') {
                result = result.text;
            }
            if (typeof result === 'number') {
                return result.toString();
            }
            return result;
        },
        valueParsed() {
            if (!this.value) {
                return null;
            }
            let result = this.value;
            if (typeof result === 'object') {
                result = result.text;
            }
            if (typeof result === 'number') {
                return result.toString();
            }
            return result;
        },
    },
    methods: {
        clickHandler() {
            if (this.href) {
                return null;
            } else if (this.action) {
                this.action();
            } else {
                return null;
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-label {
    .typography-caption();

    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 12px;
    border-radius: @border-radius-sm;

    color: @color-gray-darkest;
    text-decoration: none;

    background-color: @color-gray-light;
    &._pointer {
        cursor: pointer;
    }
    &._for_form {
        padding: 2px 6px;
    }
    &._uppercase {
        text-transform: uppercase;
    }
    &._inline {
        display: inline-flex;
    }
    &._weight_normal {
        font-weight: normal;
    }
    &._weight_bold {
        font-weight: bold;
    }
    &._theme_gradient {
        color: @color-gray-lightest;

        background-image: linear-gradient(180deg, #7830B1 0.4%, @color-primary-2 100%);;
    }
    &._theme_primary {
        color: @color-primary;

        background-color: @color-secondary;
    }
    &._theme_positive {
        color: @color-gray-lightest;

        background-color: @color-positive;
    }
    &._theme_warning {
        color: @color-gray-darkest;

        background-color: @color-accent-neutral;
    }
    &._theme_negative {
        color: @color-gray-lightest;

        background-color: @color-accent-negative;
    }
    &._theme_inversed {
        color: @color-gray-lightest;

        background-color: @color-gray-main;
    }
    &._theme_fade {
        color: @color-gray-main;
    }
    &__icon {
        font-size: 1.3rem;
        &._left {
            margin-right: 8px;
        }
        &._right {
            margin-left: 8px;
        }
    }
}
</style>
