<template>
    <div class="modal-issue-invest-offer">
        <transition name="fade">
            <div class="modal-issue-invest-offer__loader"
                v-if="isLoading"
                >
                <div class="modal-issue-invest-offer__loader-container">
                    <ui-loader />
                </div>
            </div>
        </transition>
        <div class="modal-issue-invest-offer__title">
            Инвестиционное предложение успешно отправлено!
        </div>
        <div class="modal-issue-invest-offer__subtitle">
            Ожидайте подписания предложения инвесторами
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'modal-issue-invest-offer',
    props: {
        estate: {
            type: Object,
        },
    },
    computed: {
        ...mapState('admin/investments', [
            'issueInvestmentOfferResult',
            'issueInvestmentOfferIsLoading',
            'issueInvestmentOfferError',
        ]),
        isLoading() {
            return this.issueInvestmentOfferIsLoading;
        },
    },
    beforeMount() {
        this.$store.dispatch('admin/investments/issueInvestmentOffer', {
            id: this.estate.id,
        });
    },
    watch: {
        issueInvestmentOfferResult(newVal) {
            if (newVal) {
                this.$store.dispatch('admin/estates/getEstateInfo', { id: this.estate.id });
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-issue-invest-offer {
    .modal();

    position: relative;

    max-width: 960px;
    width: 100%;
    padding: 40px 24px 32px;
    border-radius: @border-radius-lg;
    overflow: hidden;

    background-color: @color-gray-lightest;
    &__loader {
        .transition-fade();

        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        background: fade(@color-gray-lightest, 75%);
        &-container {
            width: 50px;
            padding-top: 8px;
        }
    }
    &__title {
        .typography-large();

        margin: 0 auto 8px;

        text-align: center;
    }
    &__subtitle {
        .typography-small();

        margin: 0 auto 24px;

        text-align: center;
    }
    @media @media-md-down {
        &__title {
            .typography-medium();
        }
    }
}
</style>
