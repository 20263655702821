<template>
    <div class="modal-withdrawal-error">
        <div class="modal-withdrawal-error__sign">
            <icon name="warning" />
        </div>
        <div class="modal-withdrawal-error__subtitle">
            Произошла ошибка. Попробуйте снова
        </div>
        <div class="modal-withdrawal-error__button">
            <ui-button
                theme="secondary-2"
                v-on:click="withdrawHandler"
                >
                Вывод средств
            </ui-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'modal-withdrawal-error',
    data: () => ({
        onCloseCallback: null,
    }),
    methods: {
        close() {
            this.$store.commit('modals/pop');
        },
        withdrawHandler() {
            this.onCloseCallback = () => { this.$store.commit('modals/push', 'withdrawal'); };
            this.close();
        },
    },
    beforeDestroy() {
        if (this.onCloseCallback !== null) {
            this.onCloseCallback();
        }
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-withdrawal-error {
    .modal();

    position: relative;

    max-width: 438px;
    width: calc(100vw - 48px);
    padding: 32px 24px;
    border-radius: @border-radius-lg;
    overflow: hidden;

    background-color: @color-gray-lightest;
    &__sign {
        margin: 0 auto 16px;
        width: fit-content;

        color: @color-accent-negative;
        font-size: 3.2rem;
    }
    &__subtitle {
        .typography-body();

        margin-bottom: 24px;

        text-align: center;
    }
    &__button {
        max-width: 205px;
        margin: 0 auto;
    }
    @media @media-sm-down {
        padding: 24px 16px;
        &__button {
            max-width: 280px;
        }
    }
}
</style>
