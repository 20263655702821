<template>
    <div class="plugin-home-estates-item">
        <div class="plugin-home-estates-item__image"
            v-bind:style="backgroundStyle"
        />
        <h3 class="plugin-home-estates-item__title"
            v-text="item.title"
        />
        <div class="plugin-home-estates-item__description">
            <span
                v-html="item.description"
            />
        </div>
    </div>
</template>

<script>
import utils from '~/utils';

export default {
    name: 'plugin-home-estates-item',
    props: {
        item: {
            type: Object,
        },
    },
    computed: {
        backgroundStyle() {
            if (!this.item.image) {
                return null;
            }
            const resize = utils.common.getResize({
                1920: 23,
                1279: 37,
                1023: '480',
                767: '288',
            });
            return { backgroundImage: `url(${utils.common.injectResize(this.item.image, resize)})` };
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-home-estates-item {
    &__image {
        padding-top: 64%;
        border-radius: @border-radius-lg;
        margin-bottom: 24px;

        background-color: @color-gray-dark;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    &__title {
        .typography-large();

        margin: 0 0 20px;

        line-height: 3.4rem;
    }
    &__description {
        .typography-body();

        white-space: pre-line;
    }
    @media @media-md-down {
        &__title {
            .typography-medium();

            margin-bottom: 16px;

            opacity: 0;

            transition: opacity @duration-normal ease-in-out;
            .swiper-slide-visible & {
                opacity: 1;
            }
        }
        &__description {
            opacity: 0;

            transition: opacity @duration-normal ease-in-out;
            .swiper-slide-visible & {
                opacity: 1;
            }
        }
    }
}
</style>
