<template>
    <div class="ui-action">
        <div class="ui-action__icon _left"
            v-if="iconLeft"
            >
            <icon v-bind:name="iconLeft" />
        </div>
        <component class="ui-action__text"
            v-bind:is="href ? 'a' : 'div'"
            v-bind:href="href ? href : null"
            v-bind:target="href ? '_blank' : null"
            v-bind:disabled="isDisabled"
            v-bind:class="`_theme_${theme}`"
            v-on:click="(href || isDisabled) ? null : $emit('click')"
            >
            <template v-if="text">
                {{ text }}
            </template>
            <slot v-else />
        </component>
        <div class="ui-action__icon _right"
            v-if="iconRight"
            >
            <icon v-bind:name="iconRight" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ui-action',
    props: {
        text: {
            type: String,
            default: null,
        },
        href: {
            type: String,
            default: null,
        },
        iconLeft: {
            type: String,
            default: null,
        },
        iconRight: {
            type: String,
            default: null,
        },
        theme: {
            type: String,
            default: 'primary',
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        isBreakable: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-action {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    min-width: 100%;
    min-height: 100%;

    transition:
        opacity @duration-faster ease-in-out,
        color @duration-faster ease-in-out;
    &[disabled] {
        pointer-events: none;
    }
    &._breakable {
        white-space: normal;
    }
    &__text {
        .typography-body();

        flex: 0 1 auto;
        display: block;

        transition: opacity @duration-faster ease-in-out;
    }
    &__icon {
        flex: 0 0 auto;

        font-size: 1.6rem;

        transform: translateY(0.25em);

        transition: opacity @duration-faster ease-in-out;
        &._left {
            margin-right: 8px;
        }
        &._right {
            margin-left: 8px;
        }
    }
    &._theme_primary {
        color: @color-primary;

        font-weight: bold;
        text-transform: uppercase;
    }
    &._theme_secondary {
        color: @color-gray-darkest;
    }
    &._theme_danger {
        color: @color-accent-negative;
    }
    &._disabled {
        color: @color-gray-main;

        pointer-events: none;
    }
}

</style>
