<template>
    <section class="plugin-estate-charts">
        <div class="plugin-estate-charts__item">
            <plugin-estate-charts-item
                v-bind:type="'shares'"
                v-bind:provided-data="providedSharesData"
            />
        </div>
        <div class="plugin-estate-charts__item">
            <plugin-estate-charts-item
                v-bind:type="'dividends'"
                v-bind:provided-data="providedDividendsData"
            />
        </div>
    </section>
</template>

<script>

export default {
    name: 'plugin-estate-charts',
    props: {
        providedData: {
            type: Array,
        },
    },
    computed: {
        providedSharesData() {
            return this.providedData.find(item => item.type === 'shares');
        },
        providedDividendsData() {
            return this.providedData.find(item => item.type === 'dividends');
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-estate-charts {
    &__item {
        & + & {
            margin-top: 32px;
        }
    }
}
</style>
