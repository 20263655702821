<template>
    <div class="common-video"
        v-html="youtubeIframeCode"
    />
</template>

<script>
export default {
    name: 'common-video',
    props: { video: String },
    computed: {
        youtubeIframeCode() {
            if (!this.video) {
                return null;
            }
            let id = null;
            if (this.video.indexOf('/watch?v=') > -1) {
                id = this.video.match(/(?=\/watch\?v=).*(?=[?&/; ]|$)/)?.[0].substring(9);
            } else if (this.video.indexOf('/youtu.be/') > -1) {
                id = this.video.match(/(?=\/youtu.be\/).*(?=[?&/; ]|$)/)?.[0].substring(10);
            } else if (this.video.indexOf('/embed/') > -1) {
                id = this.video.match(/(?=\/embed\/).*?(?=["'?])/)?.[0].substring(7);
            } else {
                return null;
            }
            const allows = [
                'accelerometer',
                'loop',
                'clipboard-write',
                'encrypted-media',
                'gyroscope',
                'picture-in-picture',
            ];
            return `<iframe
                src="https://www.youtube.com/embed/${id}?playlist=${id}"
                allow="${allows.join('; ')}"
                allowfullscreen
                ></iframe>`;
        },
    },
};
</script>

<style scoped lang="less">
@import "~theme";

.common-video {
    display: block;
    width: 100%;
    height: 100%;
    & /deep/ iframe {
        width: 100%;
        height: 100%;
        border: none;
    }
}
</style>
