// import moment from 'moment';

export const createOrder = (data) => {
    return data;
};

export const updateSubscriptionsItem = ({ id, isSubscribedToSale, isSubscribedToBuy }) => {
    return {
        estate: id,
        is_subscribed_to_sale: isSubscribedToSale,
        is_subscribed_to_buy: isSubscribedToBuy,
    };
};
