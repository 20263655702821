<template>
    <section class="page-cabinet-verification-success">
        <div class="page-cabinet-verification-success__title">
            Спасибо!
        </div>
        <div class="page-cabinet-verification-success__subtitle">
            Теперь вы можете начать инвестировать
        </div>
        <div class="page-cabinet-verification-success__buttons">
            <a class="page-cabinet-verification-success__buttons-item"
                v-bind:href="urls.refill"
                >
                <ui-button theme="primary">
                    Пополнить счет
                </ui-button>
            </a>
            <a class="page-cabinet-verification-success__buttons-item"
                v-bind:href="urls.estates"
                >
                <ui-button
                    theme="secondary-1"
                    size="lg"
                    >
                    Выбрать объект
                </ui-button>
            </a>
        </div>
    </section>
</template>

<script>
import config from '~/config';

export default {
    name: 'page-cabinet-verification-success',
    data: () => ({
        urls: {
            refill: config.urls.cabinet.refill,
            estates: config.urls.estates.list,
        },
    }),
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-verification-success {
    margin: auto;
    padding: 40px 24px;

    text-align: center;
    &__title {
        .typography-extra-large();

        margin-bottom: 16px;
    }
    &__subtitle {
        .typography-small();

        margin-bottom: 40px;
    }
    &__buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: -12px;
        &-item {
            flex: 0 0 auto;
            display: block;
            min-width: 230px;
            padding: 12px;

            text-decoration: none;
        }
    }
    @media @media-md-down {
        &__subtitle {
            margin-bottom: 16px;
        }
        &__buttons {
            flex-direction: column;
            align-items: stretch;
            &-item {
                flex: 1 1 auto;
            }
        }
    }
}
</style>
