import { mainClient } from '../../network';

const moduleTitle = 'investments';

const state = {
    getUserInvestmentsResult: null,
    getUserInvestmentsError: null,
    getUserInvestmentsIsLoading: false,
    getUserInvestmentsCount: 0,

    verifyUserInvestmentRequestResult: null,
    verifyUserInvestmentRequestError: null,
    verifyUserInvestmentRequestIsLoading: false,

    getUserSharesResult: null,
    getUserSharesError: null,
    getUserSharesIsLoading: false,
    getUserSharesCount: 0,

    getRequestsResult: null,
    getRequestsIsLoading: false,
    getRequestsError: null,

    createRequestResult: null,
    createRequestIsLoading: false,
    createRequestError: null,

    editRequestResult: null,
    editRequestIsLoading: false,
    editRequestError: null,
};

const getters = {
};

const actions = {
    async getUserInvestments({ state }) {
        if (state.getUserInvestmentsIsLoading) {
            return;
        }

        state.getUserInvestmentsIsLoading = true;
        const { data, error } = await mainClient[moduleTitle].requests.getUserInvestments();
        if (error) {
            state.getUserInvestmentsResult = null;
            state.getUserInvestmentsError = error;
        } else if (data) {
            state.getUserInvestmentsResult = data.items;
            state.getUserInvestmentsError = error;
        }
        state.getUserInvestmentsIsLoading = false;
    },
    async verifyUserInvestmentRequest({ state }, payload) {
        if (state.verifyUserInvestmentRequestIsLoading) {
            return;
        }

        state.verifyUserInvestmentRequestIsLoading = true;
        const { data, error } = await mainClient[moduleTitle].requests.verifyUserInvestmentRequest(payload);
        if (error) {
            state.verifyUserInvestmentRequestResult = null;
            state.verifyUserInvestmentRequestError = error;
        } else if (data) {
            state.verifyUserInvestmentRequestResult = data;
            state.verifyUserInvestmentRequestError = error;
        }
        state.verifyUserInvestmentRequestIsLoading = false;
    },
    async getUserShares({ state }) {
        if (state.getUserSharesIsLoading) {
            return;
        }

        state.getUserSharesIsLoading = true;
        const { data, error } = await mainClient[moduleTitle].requests.getUserShares();
        if (error) {
            state.getUserSharesResult = null;
            state.getUserSharesError = error;
        } else if (data) {
            state.getUserSharesResult = data.items;
            state.getUserSharesError = error;
        }
        state.getUserSharesIsLoading = false;
    },
    async getRequests({ state }, payload) {
        if (state.getRequestsIsLoading) {
            return;
        }
        state.getRequestsIsLoading = true;

        const { data, error } = await mainClient[moduleTitle].requests.getRequests(payload);
        if (error) {
            state.getRequestsResult = null;
            state.getRequestsError = error;
        } else if (data) {
            state.getRequestsResult = data.items;
            state.getRequestsError = null;
        }
        state.getRequestsIsLoading = false;
    },
    async createRequest({ state }, payload) {
        if (state.createRequestIsLoading) {
            return;
        }
        state.createRequestIsLoading = true;

        const { data, error } = await mainClient[moduleTitle].requests.invest(payload);
        if (error) {
            state.createRequestResult = null;
            state.createRequestError = error;
        } else if (data) {
            state.createRequestResult = data;
            state.createRequestError = null;
        }
        state.createRequestIsLoading = false;
    },
    async editRequest({ state }, payload) {
        if (state.editRequestIsLoading) {
            return;
        }
        state.editRequestIsLoading = true;

        const { data, error } = await mainClient[moduleTitle].requests.editRequest(payload);
        if (error) {
            state.editRequestResult = null;
            state.editRequestError = error;
        } else if (data) {
            state.editRequestResult = data;
            state.editRequestError = null;
        }
        state.editRequestIsLoading = false;
    },
};

const mutations = {
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
