<template>
    <div class="plugin-blog-image"
        v-bind:style="{ backgroundImage, paddingTop }"
    />
</template>

<script>
import utils from '~/utils';

export default {
    name: 'plugin-blog-image',
    props: {
        image: {
            type: String,
            default: null,
        },
        heightFactor: {
            type: Number,
            default: 50,
        },
    },
    computed: {
        backgroundImage() {
            if (this.image === null) {
                return 'none';
            }
            const resize = utils.common.getResize({
                1920: '900',
                1023: 100,
            });
            return `url(${utils.common.injectResize(this.image, resize)})`;
        },
        paddingTop() {
            return `${this.heightFactor}%`;
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-blog-image {
    display: block;
    width: 100%;
    border-radius: @border-radius-lg;
    margin-bottom: 40px;

    background-color: @color-gray-main;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @media @media-md-down {
        margin-bottom: 24px;
    }
}
</style>
