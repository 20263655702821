<template>
    <div class="page-admin-estates-item-legal-general">
        <ui-form
            v-bind:model="fields"
            v-bind:validation="validation"
            v-bind:submit-handler="submitHandler"
            v-on:update="updateFormData"
            gap="wide"
        />
    </div>
</template>

<script>
export default {
    name: 'page-admin-estates-item-legal-general',
    props: [ 'fields', 'validation' ],
    methods: {
        submitHandler() {
            this.$emit('submit');
        },
        updateFormData(newData) {
            this.$emit('update', newData);
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-admin-estates-item-legal-general {
}
</style>
