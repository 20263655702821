<template>
    <div class="modal-status-form-location">
        <div class="modal-status-form-location__info">
            <div class="modal-status-form-location__info-row"
                v-for="item in info"
                v-bind:key="item.title"
                >
                <div class="modal-status-form-location__info-row-title"
                    v-text="item.title"
                />
                <div class="modal-status-form-location__info-row-value"
                    v-text="item.value"
                />
            </div>
        </div>
        <div class="modal-status-form-location__checkbox">
            <ui-checkbox-with-text
                v-model="isSameAddress"
                >
                Адрес проживания совпадает с адресом регистрации
            </ui-checkbox-with-text>
        </div>
        <div class="modal-status-form-location__form"
            v-if="isFormRequired"
            >
            <div class="modal-status-form-location__form-title"
                v-text="'Адрес проживания'"
            />
            <ui-form
                v-bind:model="fields"
                v-bind:validation="$v"
                v-bind:submit-handler="submitHandler"
                v-on:update="updateFormData"
            />
        </div>
    </div>
</template>

<script>
import forms from '~/forms';

export default {
    name: 'modal-status-form-location',
    props: {
        formData: {
            type: Object,
        },
    },
    data: () => ({
        info: [{
            title: 'Область',
            value: 'Московская',
        }, {
            title: 'Город места регистрации',
            value: 'Москва',
        }, {
            title: 'Адрес места регистрации',
            value: 'Ломоносовский проспект, 31к1',
        }, {
            title: 'Индекс места регистрации',
            value: '124567',
        }],
        innerFields: {
            area: { ...forms.fields.status.locationArea, isHalf: true },
            city: { ...forms.fields.status.locationCity, isHalf: true },
            address: { ...forms.fields.status.locationAddress, isHalf: true },
            postcode: { ...forms.fields.status.locationPostcode, isHalf: true },
        },
    }),
    validations() {
        if (!this.isFormRequired) {
            return {};
        }
        const result = {
            fields: {
                area: { ...forms.validations.genericRequired },
                city: { ...forms.validations.genericRequired },
                address: { ...forms.validations.genericRequired },
                postcode: { ...forms.validations.genericRequired },
            },
        };
        return result;
    },
    computed: {
        isSameAddress: {
            get() {
                return this.formData.location?.isSameAddress || false;
            },
            set(value) {
                this.$emit('data-update', {
                    ...this.formData,
                    location: {
                        ...(this.formData.location || {}),
                        isSameAddress: value,
                    },
                });
            },
        },
        isFormRequired() {
            return this.formData.location?.isSameAddress !== true;
        },
        fields() {
            if (!this.isFormRequired) {
                return {};
            }
            const result = {
                area: this.innerFields.area,
                city: this.innerFields.city,
                address: this.innerFields.address,
                postcode: this.innerFields.postcode,
            };
            result.area.value = this.formData.location?.area;
            result.city.value = this.formData.location?.city;
            result.address.value = this.formData.location?.address;
            result.postcode.value = this.formData.location?.postcode;
            return result;
        },
        isValid() {
            return !this.$v.$invalid;
        },
    },
    methods: {
        submitHandler() {
            if (this.isValid) {
                this.$emit('submit');
            } else {
                this.$v.$touch();
            }
        },
        updateFormData(newData) {
            this.$emit('data-update', {
                ...this.formData,
                location: {
                    ...(this.formData.location || {}),
                    area: newData.area.value,
                    city: newData.city.value,
                    address: newData.address.value,
                    postcode: newData.postcode.value,
                },
            });
        },
    },
    watch: {
        isValid: {
            handler(value) {
                this.$emit('validation-update', value);
            },
            immediate: true,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-status-form-location {
    &__info {
        margin-bottom: 24px;
        &-row {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 16px;
            & + & {
                margin-top: 8px;
            }
            &-title {
                .typography-caption();

                flex: 1 1 276px;

                font-weight: bold;
                text-transform: uppercase;
            }
            &-value {
                .typography-body();

                flex: 1 1 558px;
            }
        }
    }
    &__checkbox {
        margin-bottom: 24px;
    }
    &__form {
        &-title {
            .typography-heading();

            margin-bottom: 24px;
        }
    }
}
</style>
