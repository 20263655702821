<template>
    <div class="page-cabinet-profile-form-general">
        <div class="page-cabinet-profile-form-general__fields">
            <ui-form
                v-bind:model="fields"
                v-bind:validation="$v"
                v-bind:submit-handler="submitHandler"
                v-on:update="updateFormData"
            />
        </div>
        <div class="page-cabinet-profile-form-general__banking">
            <div class="page-cabinet-profile-form-general__banking-title"
                v-text="'Банковские реквизиты'"
            />
            <div class="page-cabinet-profile-form-general__banking-fields">
                <ui-form
                    v-bind:model="banking"
                    v-bind:validation="$v"
                    v-bind:validation-path="'banking'"
                    v-bind:submit-handler="submitHandler"
                    v-on:update="updateBanking"
                />
            </div>
        </div>
        <div class="page-cabinet-profile-form-general__error"
            v-if="error"
            v-text="error"
        />
        <div class="page-cabinet-profile-form-general__buttons">
            <div class="page-cabinet-profile-form-general__buttons-item">
                <ui-button
                    type="button"
                    v-on:click="submitHandler"
                    v-bind:disabled="$v.$invalid || !$v.$dirty || isLoading"
                    >
                    Сохранить
                </ui-button>
            </div>
            <transition name="fade">
                <div class="page-cabinet-profile-form-general__buttons-item"
                    v-if="$v.$dirty"
                    >
                    <ui-trigger v-on:click="resetHandler" is-uppercase>
                        Отменить изменения
                    </ui-trigger>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import forms from '~/forms';

export default {
    name: 'page-cabinet-profile-form-general',
    data: () => ({
        fields: {
            lastName: { ...forms.fields.lastName },
            firstName: { ...forms.fields.firstName, isHalf: true },
            secondName: { ...forms.fields.secondName, placeholder: 'При наличии', isHalf: true },
            passportSeries: { ...forms.fields.passportSeries, isHalf: true },
            passportNumber: { ...forms.fields.passportNumber, isHalf: true },
            phone: { ...forms.fields.phone, isHalf: true },
            // email: { ...forms.fields.email, isHalf: true },
            juridicalInn: { ...forms.fields.inn, isHalf: true },
        },
        banking: {
            juridicalBankAccount: { ...forms.fields.user.juridical.juridicalBankAccount },
            juridicalBik: { ...forms.fields.user.juridical.juridicalBik },
        },
        error: null,
        lastPhone: '',
        // lastEmail: '',
    }),
    validations: {
        fields: {
            lastName: { ...forms.validations.requiredName },
            firstName: { ...forms.validations.requiredName },
            secondName: { ...forms.validations.singleName },
            phone: { ...forms.validations.phone },
            passportSeries: { ...forms.validations.passportSeries },
            passportNumber: { ...forms.validations.passportNumber },
            juridicalInn: { ...forms.validations.inn12 },
        },
        banking: {
            juridicalBankAccount: { ...forms.validations.genericRequired },
            juridicalBik: { ...forms.validations.bik },
        },
    },
    computed: {
        ...mapState('users', [
            'getUserProfileResult',
            'getUserProfileIsLoading',

            'personalInfo',
            'personalInfoIsLoading',

            'juridicalInfo',
            'juridicalInfoIsLoading',
        ]),
        isLoading() {
            return this.getUserProfileIsLoading ||
                this.personalInfoIsLoading ||
                this.juridicalInfoIsLoading;
        },
        profileComposed() {
            return { ...this.profile, ...this.personalInfo, ...this.juridicalInfo };
        },
    },
    methods: {
        getData() {
            if (this.isLoading) {
                return;
            }
            this.$store.dispatch('users/getUserProfile');
            this.$store.dispatch('users/getUserPersonalInfo');
            this.$store.dispatch('users/getUserJuridicalInfo');
        },
        updateFormData(newData) {
            this.$v.$touch();
            this.fields = newData;
        },
        updateBanking(newData) {
            this.$v.$touch();
            this.banking = newData;
        },
        async submitHandler() {
            this.$v.$touch();
            if (this.$v.$invalid || this.isLoading) {
                return;
            }

            const payload = { ...this.profileComposed };
            delete payload.email;
            // let ignoreEmail = false;
            for (const field in this.fields) {
                // if (field === 'email' && this.fields[field].value === this.profileComposed.email) {
                //     ignoreEmail = true;
                //     continue;
                // }
                payload[field] = this.fields[field].value;
            }
            for (const field in this.banking) {
                payload[field] = this.banking[field].value;
            }
            this.$store.dispatch('users/updateUserProfileFull', payload);
            this.lastPhone = payload.phone;
            // if (!ignoreEmail) {
            //     this.lastEmail = payload.email;
            // }
            this.$v.$reset();
        },
        setValues(data) {
            let result = { ...this.fields };
            for (let key in result) {
                if (data[key] !== null && result[key] !== undefined) {
                    result[key].value = data[key];
                    result[key].initialValue = data[key];
                }
            }
            this.fields = result;
            result = { ...this.banking };
            for (let key in result) {
                if (data[key] !== null && result[key] !== undefined) {
                    result[key].value = data[key];
                    result[key].initialValue = data[key];
                }
            }
            this.banking = result;
            this.$v.$reset();
        },
        resetHandler() {
            for (const field in this.fields) {
                this.fields[field].value = this.fields[field].initialValue;
            }
            for (const field in this.banking) {
                this.banking[field].value = this.banking[field].initialValue;
            }
            this.$v.$reset();
        },
    },
    mounted() {
        this.getData();
    },
    watch: {
        profileComposed(newVal) {
            const filtredData = { ...newVal };
            delete filtredData.email;
            this.setValues(newVal);
        },
        profileError(newVal) {
            if (newVal === ' Пользователь с таким Номер телефона уже существует.') {
                this.error = `Пользователь с таким номером телефона (${this.lastPhone}) уже существует`;
            } else if (newVal === ' Email exist') {
                this.error = `Пользователь с таким адресом электронной почты (${this.lastEmail}) уже существует`;
            } else {
                this.error = null;
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-profile-form-general {
    font-size: 1.4rem;
    line-height: 2rem;
    &__fields {
        margin-bottom: 40px;
    }
    &__banking {
        &-title {
            .typography-heading();

            margin-bottom: 16px;
        }
    }
    &__error {
        .typography-body();

        margin-bottom: 16px;

        color: @color-accent-negative;
    }
    &__buttons {
        display: flex;
        align-items: baseline;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: ~'calc(100% + 24px)';
        margin: 33px -12px 0;
        &-item {
            .transition-fade();

            flex: 0 0 auto;
            margin: 0 12px;
        }
    }
    @media @media-md-down {
        &__buttons {
            flex-direction: column-reverse;
            flex-wrap: nowrap;
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            &-item {
                width: 100%;
                margin: 0;
                & ~ & {
                    margin-bottom: 16px;
                }
            }
        }
    }
}
</style>
