<template>
    <div class="page-cabinet-referral-info-list-table">
        <ui-table v-bind="tableData" theme="secondary" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'page-cabinet-referral-info-list-table',
    computed: {
        ...mapState('referral', [
            'getReferralsResult',
        ]),
        tableData() {
            const columns = [
                {
                    title: 'ФИО',
                    style: { flex: '1 1 469px' },
                },
                {
                    title: 'Статус',
                    style: { flex: '1 1 291px' },
                },
                {
                    title: 'Сумма инвестиции',
                    style: { flex: '0 0 180px' },
                },
                {
                    title: 'Комиссия',
                    style: { flex: '1 1 155px' },
                },
            ];
            const rows = this.getReferralsResult.map(x => [
                {
                    props: {
                        text: x.name,
                    },
                },
                {
                    type: 'component',
                    component: 'page-cabinet-referral-info-list-status',
                    props: {
                        code: x.status,
                    },
                },
                {
                    props: {
                        text: x.investment > 0 ? x.investmentFormatted : '–',
                    },
                },
                {
                    props: {
                        text: x.investment > 0 ? x.feeFormatted : '–',
                    },
                },
            ]);
            rows.push();
            return {
                columns,
                rows,
            };
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-referral-info-list-table {
}
</style>
