<template>
    <div class="plugin-documents-item">
        <div class="plugin-documents-item__icon">
            <icon name="document" />
        </div>
        <div class="plugin-documents-item__title"
            v-text="item.title"
        />
    </div>
</template>

<script>
export default {
    name: 'plugin-documents-item',
    props: {
        item: {
            type: Object,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-documents-item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    &__icon {
        flex: 0 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        margin-right: 8px;
        border-radius: 50%;

        font-size: 1.8rem;
        text-align: center;

        background-color: fade(@color-gray-darker, 10%);
    }
    &__title {
        .typography-heading();

        flex: 1 1 auto;
        margin-top: 4px;
    }
    &:hover &__title {
        text-decoration: underline;
    }
}
</style>
