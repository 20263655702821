<template>
    <ui-card-base>
        <div class="page-admin-estates-item-info-block">
            <h2 class="page-admin-estates-item-info-block__title"
                v-if="title"
                v-text="title"
            />
            <slot />
        </div>
    </ui-card-base>
</template>

<script>
export default {
    name: 'page-admin-estates-item-info-block',
    props: {
        title: {
            type: String,
            default: null,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-admin-estates-item-info-block {
    padding: 8px;
    &__title {
        .typography-medium();

        margin: 0 0 24px;
    }
    @media @media-md-down {
        padding: 0;
    }
}
</style>
