<template>
    <div class="modal-investment-type-option"
        v-bind:class="{
            _active: isActive,
            _inactive: isInactive,
        }"
        v-on:click="submit"
        v-on:keydown.space="submit"
        >
        <div class="modal-investment-type-option__icon">
            <div class="modal-investment-type-option__icon-active">
                <icon v-bind:name="icon" original />
            </div>
            <div class="modal-investment-type-option__icon-inactive">
                <icon v-bind:name="icon + '-gray'" original />
            </div>
        </div>
        <div class="modal-investment-type-option__title"
            v-text="title"
        />
        <div class="modal-investment-type-option__description"
            v-text="description"
        />
        <a class="modal-investment-type-option__about"
            v-bind:href="url"
            v-text="'Подробнее'"
        />
    </div>
</template>

<script>
export default {
    name: 'modal-investment-type-option',
    props: {
        type: {
            type: Object,
        },
        activeType: {
            type: Object,
            default: null,
        },
    },
    computed: {
        isActive() {
            return this.activeType && this.type.id === this.activeType.id;
        },
        isInactive() {
            return this.activeType && this.type.id !== this.activeType.id;
        },
        icon() {
            return 'investment-type-' + this.type.code;
        },
        title() {
            return this.type.code === 'loan'
                ? 'Займ'
                : 'Акции';
        },
        description() {
            return this.type.code === 'loan'
                ? `Описание инвестирования.
                Инвестирование с помощью выпуска займа`
                : `Описание инвестирования.
                Прямой выпуск акций`;
        },
        url() {
            return '#';
        },
    },
    methods: {
        submit() {
            if (this.isActive) {
                return;
            }
            this.$emit('select', this.type);
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-investment-type-option {
    .modal();

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100%;
    padding: 24px 24px 36px;

    cursor: pointer;
    box-shadow:
        0 0 0 1px @color-secondary,
        0 0 0 0 rgba(103, 55, 175, 0),
        inset 0 0 0 0 rgba(103, 55, 175, 0);

    transition:
        box-shadow @duration-fast @easing-default,
        transform @duration-fast @easing-default,
        opacity @duration-fast @easing-default;
    &:hover {
        box-shadow: 0 0 0 2px @color-secondary,
        0 5px 20px 0 rgba(103, 55, 175, 0.2),
        inset 0 0 0 0 rgba(103, 55, 175, 0);

        transform: translateY(-5px);
    }
    &:active {
        box-shadow: 0 0 0 2px @color-secondary,
        0 0 0 0 rgba(103, 55, 175, 0),
        inset 0 5px 20px -5px rgba(103, 55, 175, 0.2);

        transform: translateY(5px);
    }
    &._active {
        box-shadow: 0 0 0 2px @color-primary,
        0 0 0 0 rgba(103, 55, 175, 0),
        inset 0 0 0 0 rgba(103, 55, 175, 0);

        transform: translateY(0);
    }
    &._inactive {
        opacity: 0.66;
    }
    &__icon {
        position: relative;

        margin-bottom: 24px;

        font-size: 8rem;
        &-active {
            position: absolute;
            top: 0;
            left: 0;

            transition: opacity @duration-fast @easing-default;
        }
        ._inactive > & > &-active {
            opacity: 0;
        }
    }
    &__title {
        .typography-medium();

        margin-bottom: 16px;
    }
    &__description {
        .typography-body();

        margin-bottom: 16px;

        white-space: pre-line;
    }
    &__about {
        .typography-caption();

        font-weight: bold;
        text-transform: uppercase;
        text-decoration: none;
        ._active > & {
            color: @color-primary;
        }
    }
    @media @media-sm-down {
        padding: 16px;
        &:hover {
            box-shadow: 0 0 0 2px @color-secondary,
            0 0 0 0 rgba(103, 55, 175, 0.2),
            inset 0 0 0 0 rgba(103, 55, 175, 0);

            transform: translateY(0);
        }
        &:active {
            box-shadow: 0 0 0 2px @color-secondary,
            0 0 0 0 rgba(103, 55, 175, 0),
            inset 0 0 0 0 rgba(103, 55, 175, 0);

            transform: translateY(0);
        }
        &._active {
            box-shadow: 0 0 0 2px @color-primary,
            0 5px 20px 0 rgba(103, 55, 175, 0.2),
            inset 0 0 0 0 rgba(103, 55, 175, 0);

            transform: translateY(0);
        }
        &__icon {
            margin-bottom: 4px;

            font-size: 3.2rem;
        }
        &__title {
            .typography-heading();

            margin-bottom: 12px;
        }
        &__description {
            .typography-caption();

            margin-bottom: 12px;
        }
    }
}
</style>
