<template>
    <article class="page-cabinet-dashboard-transactions">
        <div class="page-cabinet-dashboard-transactions__headline">
            <div class="page-cabinet-dashboard-transactions__headline-title">
                Последние транзакции
            </div>
            <a class="page-cabinet-dashboard-transactions__headline-link"
                href="/cabinet/transactions/"
                >
                Смотреть все
            </a>
        </div>
        <div class="page-cabinet-dashboard-transactions__data-wrapper">
            <ui-card-base with-border>
                <div class="page-cabinet-dashboard-transactions__data">
                    <template v-if="items && items.length > 0">
                        <div class="page-cabinet-dashboard-transactions__data-group"
                            v-for="(item, index) in items"
                            v-bind:key="index"
                            >
                            <div class="page-cabinet-dashboard-transactions__data-group-title"
                                v-text="item.description"
                            />
                            <div class="page-cabinet-dashboard-transactions__data-group-type"
                                >
                                <span class="page-cabinet-dashboard-transactions__data-group-type-title"
                                    v-text="'Тип:'"
                                /> <span class="page-cabinet-dashboard-transactions__data-group-type-value"
                                    v-text="item.type"
                                />
                            </div>
                            <div class="page-cabinet-dashboard-transactions__data-group-date"
                                v-text="getFormattedDate(item.date)"
                            />
                            <div class="page-cabinet-dashboard-transactions__data-group-amount">
                                <span class="page-cabinet-dashboard-transactions__data-group-amount-title"
                                    v-text="'Сумма:'"
                                /> <span class="page-cabinet-dashboard-transactions__data-group-amount-value"
                                    v-text="getFormattedValue(item.amount)"
                                />
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="page-cabinet-dashboard-transactions__data-group">
                            <div class="page-cabinet-dashboard-transactions__data-group-title"
                                v-text="'Записей не найдено'"
                            />
                        </div>
                    </template>
                </div>
            </ui-card-base>
        </div>
    </article>
</template>

<script>
import moment from 'moment';
import utils from '~/utils';

export default {
    name: 'page-cabinet-dashboard-transactions',
    computed: {
        currency() {
            return this.$store.getters['site/currency'];
        },
        items() {
            const items = this.$store.getters['transfers/items'];
            if (!items) {
                return null;
            }
            return items.slice(0, 3);
        },
    },
    methods: {
        getFormattedValue(value) {
            return `${utils.formats.formatNumberWithSpaces(value)} ${this.currency.name.short}`;
        },
        getFormattedDate(value) {
            return moment(value).format('DD.MM.YYYY');
        },
    },
    mounted() {
        this.$store.dispatch('transfers/getItems', { is_transaction: true });
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-dashboard-transactions {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin-bottom: 40px;
    &__headline {
        flex: 0 0 auto;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        width: 100%;
        padding: 0 16px 11px;
        &-title {
            .typography-heading();

            flex: 0 0 auto;
        }
        &-link {
            .typography-body();

            flex: 0 0 auto;

            color: @color-primary;
            &:hover {
                text-decoration: none;
            }
        }
    }
    &__data {
        &-wrapper {
            flex: 1 1 auto;
        }
        &-group {
            .typography-body();

            display: flex;
            align-items: baseline;
            justify-content: flex-start;
            width: 100%;
            & ~ & {
                margin-top: 14px;
            }
            &-title {
                flex: 1 1 60%;
            }
            &-type {
                display: none;
                flex: 0 0 20%;
            }
            &-date {
                flex: 0 0 15%;
                padding-left: 24px;

                color: @color-gray-main;
            }
            &-amount {
                flex: 0 0 25%;
                padding-left: 24px;

                text-align: right;

                &-title {
                    display: none;
                }
            }
        }
    }
    @media @media-md-down {
        &__data {
            &-group {
                flex-wrap: wrap;
                & ~ & {
                    padding-top: 12px;
                    border-top: 1px solid fade(@color-gray-darkest, 5%);
                    margin-top: 12px;
                }
                &-title {
                    order: -2;
                    flex: 0 0 70%;
                }
                &-type {
                    display: block;
                    order: 0;
                    flex: 0 0 100%;
                    margin-top: 24px;
                    &-title {
                        display: inline-block;

                        color: @color-gray-main;
                    }
                }
                &-date {
                    order: -1;
                    flex: 0 0 30%;

                    text-align: right;
                }
                &-amount {
                    flex: 0 0 100%;
                    padding-left: 0;

                    text-align: left;

                    &-title {
                        display: inline-block;

                        color: @color-gray-main;
                    }
                }
            }
        }
    }
}
</style>
