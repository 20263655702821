<template>
    <div class="ui-input-inn">
        <div class="ui-input-inn__input">
            <ui-input-numeric
                v-bind:is-invalid="isInvalid || isEmptyOrError || isIncorrectInn"
                v-bind:model="enrichedModel"
                v-on:input="debouncedRequest"
            />
            <div class="ui-input-inn__input-options"
                v-if="options"
                >
                <template v-if="options.length > 0">
                    <div class="ui-input-inn__input-options-item"
                        v-for="(item, index) in options"
                        v-bind:key="index"
                        v-on:click="setValue(item)"
                        >
                        <div class="ui-input-inn__input-options-item-texts">
                            <div class="ui-input-inn__input-options-item-texts-title"
                                v-text="item.suggestTitle"
                            />
                            <div class="ui-input-inn__input-options-item-texts-region"
                                v-if="item.suggestRegion && item.showRegion"
                                v-text="item.suggestRegion"
                            />
                        </div>
                    </div>
                </template>
                <div class="ui-input-inn__input-options-item _exceptional"
                    v-else-if="model.exceptionalCallback"
                    v-on:click="setValue(enrichedModel.value, true)"
                    >
                    {{ enrichedModel.value }}
                    <div class="ui-input-inn__input-options-item-warning">
                        Не удалось распознать введенный ИНН!<br />
                        Если вы уверены, что ввели данные верно, выберите этот пункт.
                    </div>
                </div>
            </div>
        </div>
        <div class="ui-input-inn__loader"
            v-if="isLoading"
            >
            <div class="ui-input-inn__loader-container">
                <ui-loader />
            </div>
        </div>
        <div class="ui-input-inn__error"
            v-if="isEmptyOrError"
            >
            Не удалось найти информацию по данному ИНН
        </div>
        <div class="ui-input-inn__error"
            v-if="isIncorrectInn"
            >
            Введите корректный ИНН {{ model.minLength === 10 ? 'юридического лица (10 цифр)' : 'ИП (12 цифр)' }}
        </div>
    </div>
</template>

<script>
import { mainClient } from '~/network';
import forms from '~/forms';

export default {
    name: 'ui-input-inn',
    props: {
        model: {
            type: Object,
        },
        isInvalid: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
        },
    },
    model: {
        prop: 'value',
        event: 'input',
    },
    data: () => ({
        toid: null,
        isLoading: false,
        options: null,
        error: null,
        innerValue: '',
        isIncorrectInn: false,
        isInitialRequestDone: false,
        isRequestedOnce: false,
    }),
    computed: {
        isEmptyOrError() {
            return !this.isInvalid && this.error;
        },
        enrichedModel() {
            const result = {
                ...this.model,
                value: this.innerValue,
            };
            return result;
        },
        isDisabled() {
            return this.isLoading || this.model.disabled;
        },
        validator() {
            return forms.validations[this.model.minLength === 10 ? 'inn10' : 'inn12'].value.isValid;
        },
        isInitialRequestRequired() {
            return this.model.isInitialRequestRequired && !this.isInitialRequestDone && !!this.value;
        },
    },
    methods: {
        debouncedRequest(newData) {
            this.isIncorrectInn = false;
            this.innerValue = newData;
            this.isRequestedOnce = false;
            if (this.toid !== null) {
                clearTimeout(this.toid);
            }
            if (!newData) {
                this.options = null;
                this.error = null;
                return;
            }
            if (!this.validator(newData)) {
                this.isIncorrectInn = true;
                this.options = null;
                return;
            }
            this.toid = setTimeout(() => {
                this.requestDadata(newData);
                clearTimeout(this.toid);
                this.toid = null;
            }, 700);
        },
        async requestDadata(newData) {
            if (this.value === newData && this.isRequestedOnce && !this.isInitialRequestRequired) {
                return;
            }
            if (this.isLoading || (newData.length !== this.model.minLength)) {
                return;
            }
            const payload = { query: newData };
            this.isLoading = true;
            this.isRequestedOnce = true;
            const { data, error, status } = await mainClient.dadata.requests.getInnSuggest(payload);
            if (error) {
                console.error(error, status);
                this.options = null;
                this.error = true;
            } else if (data) {
                this.options = this.model.dadataTransform
                    ? this.model.dadataTransform(data)
                    : data.map(x => ({
                        ...x,
                        suggestTitle: x.value,
                    }));
                this.error = null;
            }
            if (!this.isInitialRequestDone) {
                this.isInitialRequestDone = true;
            }
            this.isLoading = false;
        },
        setValue(value, isExceptional = false) {
            this.tempValue = null;
            this.options = null;
            this.error = null;
            if (isExceptional) {
                this.model.exceptionalCallback(value);
                return;
            } else if (this.model.resetExceptionCallback) {
                this.model.resetExceptionCallback(value);
            }
            this.$emit('input', this.model.dadataInputParser ? this.model.dadataInputParser(value) : value);
        },
    },
    watch: {
        value: {
            handler(newValue) {
                this.innerValue = newValue;
            },
            immediate: true,
        },
        isInitialRequestRequired: {
            handler(newValue) {
                if (newValue) {
                    this.debouncedRequest(this.value);
                }
            },
            immediate: true,
        },
    },
};
</script>

<style scoped lang="less">
@import "~theme";
.ui-input-inn {
    position: relative;
    &__input {
        position: relative;
        &-options {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            z-index: 2;

            border-right: 1px solid @color-primary;
            border-bottom: 1px solid @color-primary;
            border-left: 1px solid @color-primary;

            background-color: @color-gray-lightest;
            &-item {
                width: 100%;
                min-height: 40px;
                padding: 12px 12px 8px;

                cursor: pointer;
                &:hover {
                    background-color: fade(@color-primary-3, 50%);
                }
                &:not(:last-child) {
                    border-bottom: 1px solid @color-gray-main;
                }
                &-texts {
                    display: flex;
                    align-items: baseline;
                    justify-content: space-between;
                    height: 100%;
                    &-title {
                        flex: 0 0 auto;
                    }
                    &-region {
                        flex: 0 1 auto;
                        overflow: hidden;
                        padding-left: 2ch;

                        color: @color-gray-dark;
                        font-style: italic;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
                &-warning {
                    color: @color-accent-negative;
                    white-space: pre-line;
                }
            }
        }
    }
    &__loader {
        position: absolute;
        top: 1px;
        right: 1px;
        left: 1px;
        z-index: 2;

        display: flex;
        align-items: center;
        justify-content: center;
        height: 38px;

        background-color: @color-gray-lightest;
        &-container {
            width: 28px;
        }
    }
    &__error {
        margin-top: 8px;

        color: @color-accent-negative;
    }
}
</style>
