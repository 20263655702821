<template>
    <div class="modal-status-form-restrictions">
        <div class="modal-status-form-restrictions__form">
            <ui-form
                v-bind:model="fields"
                v-bind:validation="$v"
                v-bind:submit-handler="submitHandler"
                v-on:update="updateFormData"
            />
        </div>
        <div class="modal-status-form-restrictions__checkboxes">
            <div class="modal-status-form-restrictions__checkboxes-title"
                v-text="'Подтверждаю, что:'"
            />
            <div class="modal-status-form-restrictions__checkboxes-item"
                v-for="(checkbox, index) in checkboxes"
                :key="index"
                >
                <ui-checkbox-with-text
                    v-model="checkbox.value"
                    >
                    {{ checkbox.text }}
                </ui-checkbox-with-text>
            </div>
        </div>
    </div>
</template>

<script>
import forms from '~/forms';

export default {
    name: 'modal-status-form-restrictions',
    props: {
        formData: {
            type: Object,
        },
    },
    data: () => ({
        checkboxes: [{
            text: 'НЕ являюсь иностранным публичным должностным лицом / супругой (-ом), близким родственником',
            value: false,
        }, {
            text: 'НЕ являюсь должностным лицом публичной международной организации',
            value: false,
        }, {
            text: 'НЕ являюсь российским публичным должностным лицом',
            value: false,
        }, {
            text: 'НЕ являюсь руководителем или учредителем некоммерческой организации, иностранной некоммерческой неправительственной организации, ее отделения, филиала или представительства, осуществляющих свою деятельность на территории Российской Федерации',
            value: false,
        }, {
            text: 'выгодоприобретателем НЕ является иное лицо',
            value: false,
        }, {
            text: 'бенефициарным владельцем НЕ является иное лицо',
            value: false,
        }],
        innerFields: {
            purpose: { ...forms.fields.status.restrictionsPurpose },
            source: { ...forms.fields.status.restrictionsSource },
        },
    }),
    validations() {
        const result = {
            fields: {
                purpose: { ...forms.validations.genericRequired },
                source: { ...forms.validations.genericRequired },
            },
        };
        return result;
    },
    computed: {
        isAllChecked() {
            return this.checkboxes.every(x => x.value);
        },
        fields() {
            const result = {
                purpose: this.innerFields.purpose,
                source: this.innerFields.source,
            };
            result.purpose.value = this.formData.restrictions?.purpose;
            result.source.value = this.formData.restrictions?.source;
            return result;
        },
        isValid() {
            return !this.$v.$invalid && this.isAllChecked;
        },
    },
    methods: {
        submitHandler() {
            if (this.$v.$invalid) {
                this.$v.$touch();
            }
            if (this.isValid) {
                this.$emit('submit');
            }
        },
        updateFormData(newData) {
            this.$emit('data-update', {
                ...this.formData,
                restrictions: {
                    purpose: newData.purpose.value,
                    source: newData.source.value,
                },
            });
        },
    },
    watch: {
        isValid: {
            handler(value) {
                this.$emit('validation-update', value);
            },
            immediate: true,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-status-form-restrictions {
    &__form {
        margin-bottom: 24px;
    }
    &__checkboxes {
        &-title {
            .typography-heading();
        }
        &-item {
            margin-top: 16px;
        }
    }
}
</style>
