export const item = (data) => {
    let typeName = data.translated_activity_type || 'Прочее';
    if (typeName === 'Выдача займа') {
        typeName = 'Покупка акций';
    }
    const result = {
        description: data.description || 'Описание отсутствует',
        type: data.activity_type || 'other',
        typeName,
        amount: data.value ? parseFloat(data.value) : 0,
        date: data.created || new Date(),
    };
    return result;
};

export const list = (data) => {
    const result = {
        items: data.results.map(item),
        paging: {
            count: data.count || 0,
            page: data.page || 1,
        },
        ...data,
    };
    return result;
};
