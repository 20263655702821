<template>
    <input class="ui-input-text_old"
        v-bind:type="type"
        v-bind:value="value"
        v-on:input="updateSelf($event.target.value)"
        v-on:blur="$emit('blur')"
        v-bind:autofocus="isAutofocus"
    />
</template>

<script>
export default {
    name: 'ui-input-text_old',
    props: {
        value: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'text',
        },
        noPaddings: {
            type: Boolean,
            default: false,
        },
        isAutofocus: {
            type: Boolean,
            default: false,
        },
    },
    model: {
        prop: 'value',
        event: 'input',
    },
    methods: {
        updateSelf(value) {
            this.$emit('input', value);
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-input-text {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 9px 0 6px;
    border-bottom: 1px solid @color-gray-main;

    color: @color-gray-darker;
    font-size: inherit;
    line-height: inherit;
    text-decoration: none;

    background: @color-gray-lightest;

    appearance: none;
    resize: none;

    transition-property: border-color, color;
    transition-duration: @duration-fast;
    transition-timing-function: ease-in-out;
    &::placeholder {
        color: @color-gray-main;

        transition: opacity @duration-fast ease-in-out;
    }
    &:focus::placeholder {
        opacity: 0;
    }
    &:focus {
        border-color: @color-gray-darker;
    }
    &._positive {
        border-color: @color-positive;

        color: @color-positive;
    }
    &._invalid {
        border-color: @color-accent-negative;
    }
    &._transparent {
        background: transparent;
    }
}
</style>
