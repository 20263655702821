<template>
    <div class="page-cabinet-verification-type">
        <transition name="fade">
            <div class="page-cabinet-verification-type__loader"
                v-if="isLoading"
                >
                <div class="page-cabinet-verification-type__loader-container">
                    <ui-loader />
                </div>
            </div>
        </transition>
        <div class="page-cabinet-verification-type__list">
            <div class="page-cabinet-verification-type__list-item"
                v-for="type in types"
                :key="type.code"
                :class="{ _active: type.code === legalType }"
                v-on:click="selectType(type.code)"
                >
                <div class="page-cabinet-verification-type__list-item-icon">
                    <icon :name="type.icon" />
                </div>
                <div class="page-cabinet-verification-type__list-item-title"
                    v-text="type.title"
                />
            </div>
        </div>
        <div class="page-cabinet-verification-type__button">
            <ui-button
                v-bind:is-disabled="!legalType || isLoading"
                v-on:click="submitHandler"
                >
                Далее
            </ui-button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'page-cabinet-verification-type',
    data: () => ({
        types: [
            {
                code: 'F',
                title: 'Физическое лицо',
                icon: 'legal-entity-f',
            },
            {
                code: 'I',
                title: 'ИП',
                icon: 'legal-entity-i',
            },
            {
                code: 'J',
                title: 'Компания',
                icon: 'legal-entity-j',
            },
        ],
    }),
    computed: {
        ...mapState('verification/type', [
            'legalType',
        ]),
        ...mapState('users', [
            'getUserProfileResult',
            'getUserProfileIsLoading',

            'updateUserProfileResult',
            'updateUserProfileIsLoading',

            'personalInfoIsLoading',
        ]),
        isLoading() {
            return this.getUserProfileIsLoading ||
                this.updateUserProfileIsLoading;
        },
    },
    methods: {
        submitHandler() {
            if (this.isLoading || !this.legalType) {
                return;
            }
            this.$store.dispatch('users/updateUserProfile', {
                legalType: this.legalType,
                onSuccess: () => {
                    this.$store.dispatch('users/getUserProfile', {
                        onSuccess: () => {
                            this.$emit('filled', 'type');
                            this.$emit('submitted');
                        },
                    });
                },
            });
        },
        selectType(type) {
            this.$store.state.verification.type.legalType = type;
        },
    },
    beforeMount() {
        this.$store.dispatch('users/getUserProfile');
    },
    watch: {
        getUserProfileResult(newVal) {
            if (newVal?.legalType) {
                this.$store.state.verification.type.legalType = newVal.legalType;
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-verification-type {
    position: relative;
    &__loader {
        .transition-fade();

        position: absolute;
        top: -2px;
        right: -2px;
        bottom: -2px;
        left: -2px;
        z-index: 2;

        display: flex;
        align-items: center;
        justify-content: center;

        background-color: @color-gray-lightest;
        &-container {
            width: 50px;
            margin: auto;
        }
    }
    &__list {
        display: flex;
        align-items: stretch;
        justify-content: center;
        gap: 16px;
        margin-bottom: 40px;
        &-item {
            flex: 1 1 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 8px;
            padding: 16px 24px;
            border-radius: @border-radius-lg;

            cursor: pointer;
            box-shadow: 0 0 0 1px @color-secondary;

            transition: all @duration-fast ease-in-out;
            &-icon {
                flex: 0 0 auto;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 12px;
                border-radius: 50%;

                font-size: 2rem;

                background-color: @color-gray-light;

                transition: all @duration-fast ease-in-out;
            }
            &-title {
                .typography-body();

                text-align: center;
            }
            &._active {
                color: @color-primary;

                box-shadow: 0 0 0 2px @color-primary;
            }
            &._active &-icon {
                background-color: @color-secondary;
            }
        }
    }
    &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        margin: 0 auto;
    }
    @media @media-md-down {
        &__list {
            flex-direction: column;
            margin-bottom: 32px;
        }
    }
}
</style>
