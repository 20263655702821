<template>
    <section class="plugin-gallery">
        <div class="swiper-container"
            ref="plugin-gallery-slider"
            >
            <div class="swiper-wrapper">
                <div class="swiper-slide plugin-gallery__item"
                    v-for="(slide, index) in slidesResized"
                    v-bind:key="index"
                    >
                    <div class="plugin-gallery__item-image swiper-lazy"
                        v-bind:data-background="slide.image"
                        >
                        <div class="plugin-gallery__item-image-loader">
                            <ui-loader
                                v-bind:fixed="100"
                                v-bind:centered="true"
                                >
                            </ui-loader>
                        </div>
                    </div>
                    <div class="plugin-gallery__item-caption"
                        v-if="slide.caption"
                        v-text="slide.caption"
                    />
                </div>
            </div>
        </div>
        <div class="plugin-gallery__navigation">
            <div class="plugin-gallery__navigation-item _prev"
                ref="plugin-gallery-prev"
                >
                <icon name="chevron-left" />
            </div>
            <div class="plugin-gallery__navigation-item _next"
                ref="plugin-gallery-next"
                >
                <icon name="chevron-right" />
            </div>
        </div>
        <div class="plugin-gallery__pagination"
            ref="plugin-gallery-pagination"
            >
        </div>
    </section>
</template>

<script>
import Swiper from 'swiper/dist/js/swiper';
import utils from '~/utils';

export default {
    name: 'plugin-gallery',
    props: {
        slides: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        slider: null,
    }),
    computed: {
        slidesResized() {
            const resize = utils.common.getResize({
                1920: 75,
                767: 95,
            });
            return this.slides.map(x => ({
                ...x,
                image: utils.common.injectResize(x.image, resize),
            }));
        },
    },
    methods: {
        initSwiper() {
            if (this.slider !== null) {
                this.slider.update();
                return;
            }
            const container = this.$refs['plugin-gallery-slider'];
            const paginationEl = this.$refs['plugin-gallery-pagination'];
            const prevEl = this.$refs['plugin-gallery-prev'];
            const nextEl = this.$refs['plugin-gallery-next'];
            this.slider = new Swiper(container, {
                speed: 600,
                slidesPerView: 1,
                loop: true,
                preloadImages: false,
                watchSlidesVisibility: true,
                lazy: {
                    loadPrevNext: true,
                },
                navigation: {
                    prevEl,
                    nextEl,
                },
                mousewheel: {
                    forceToAxis: true,
                },
                pagination: {
                    el: paginationEl,
                    type: 'bullets',
                    bulletClass: 'plugin-gallery__pagination-item',
                    bulletActiveClass: '_active',
                    clickable: true,
                },

            });
        },
    },
    mounted() {
        this.initSwiper();
        utils.dom.addLoadedCallback(this.initSwiper);
    },
    beforeDestroy() {
        utils.dom.removeLoadedCallback(this.initSwiper);
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-gallery {
    position: relative;
    .swiper-container {
        width: 100%;
    }
    &__item {
        &-image {
            position: relative;

            width: 100%;
            padding-top: 51.65%;
            border-radius: @border-radius-lg;

            background-color: @color-gray-lightest;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            &-loader {
                position: absolute;
                top: 50%;
                left: 50%;

                transform: translate(-50%, -50%);

                transition: opacity @duration-fast ease-in-out;
                .swiper-lazy-loaded & {
                    opacity: 0;
                }
            }
        }
        &-caption {
            .typography-caption();

            margin-top: 10px;

            color: @color-gray-main;
            text-align: center;
        }
    }
    &__navigation {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        padding-top: 51.65%;

        pointer-events: none;
        &-item {
            position: absolute;
            top: 50%;
            z-index: 1;

            display: flex;
            align-items: center;
            justify-content: center;
            width: 52px;
            height: 52px;
            border-radius: 50%;

            font-size: 2.8rem;
            text-align: center;

            background: fade(@color-gray-lightest, 0%);

            pointer-events: all;
            cursor: pointer;
            outline: none;

            transform: translateY(-50%);

            transition: background-color @duration-fast ease-in-out;

            &:hover {
                background: fade(@color-gray-lightest, 66%);
            }
            &._prev {
                left: 12px;

                padding-right: 2px;
            }
            &._next {
                right: 12px;

                padding-left: 5px;
            }
        }
    }
    &__pagination {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        padding: 12px 8px 16px;
        /deep/ &-item {
            position: relative;

            display: block;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            margin: 6px 8px;

            background-color: @color-gray-light;

            cursor: pointer;
            outline: none;

            transition: background-color @duration-fast ease-in-out;
            &:before {
                content: '';

                position: absolute;
                top: -6px;
                right: -8px;
                bottom: -6px;
                left: -8px;

                display: block;
            }
            &:hover {
                background-color: fade(@color-primary-2, 50%);
            }
            &._active {
                background-color: @color-primary-2;
            }
        }
    }
    @media @media-md-down {
    }
}
</style>
