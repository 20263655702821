import { mainClient } from '~/network';

const moduleTitle = 'subscriptions';

const state = {
    getSubscriptionsIsLoading: false,
    getSubscriptionsError: null,
    getSubscriptionsResult: null,

    subscribeIsLoading: false,
    subscribeError: null,
    subscribeResult: null,
};

const getters = {
};

const actions = {
    async getSubscriptions({ state }, payload = {}) {
        if (state.getSubscriptionsIsLoading) {
            return;
        }
        state.getSubscriptionsIsLoading = true;
        const { data, error } = await mainClient[moduleTitle].requests.getSubscriptions(payload);
        if (error) {
            state.getSubscriptionsError = error;
            state.getSubscriptionsResult = null;
        } else if (data) {
            state.getSubscriptionsResult = data;
            state.getSubscriptionsError = null;
        }
        state.getSubscriptionsIsLoading = false;
    },
    async subscribe({ state }, payload = {}) {
        if (state.subscribeIsLoading) {
            return;
        }
        state.subscribeIsLoading = true;
        const { data, error, status } = await mainClient[moduleTitle].requests.subscribe(payload);
        if (error) {
            console.error(`Error in action ${moduleTitle}/subscribe (${status})`);
            state.subscribeError = error;
            state.subscribeResult = null;
        } else if (data) {
            state.subscribeResult = data;
            state.subscribeError = null;
        }
        state.subscribeIsLoading = false;
    },
};

const mutations = {
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
