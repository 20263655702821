<template>
    <transition name="fade">
        <div class="modal-root"
            v-if="isActive"
            >
            <div class="modal-root__headline">
                <a class="modal-root__headline-logo"
                    href="/"
                    >
                    <ui-logo-text />
                </a>
            </div>
            <transition-group name="scale-fade"
                v-on:leave="deactivate"
                appear
                >
                <div class="modal-root__item"
                    v-for="(modal, index) in list"
                    v-bind:key="index + modal.name"
                    v-bind:style="{ zIndex: index }"
                    >
                    <!-- <div class="modal-root__item-overlay"
                        v-if="!modal.props.noOverlay || list.length > 1"
                        v-bind:class="{
                            _clickable: !modal.props.noOverlayAction,
                        }"
                        v-on:click="!modal.props.noOverlay && !modal.props.noOverlayAction && close()"
                    /> -->
                    <div class="modal-root__item-container">
                        <div class="modal-root__item-container-content">
                            <component
                                v-bind:is="'modal-' + modal.name"
                                v-bind="modal.props"
                                v-on="modal.on ? modal.on : null"
                            />
                        </div>
                    </div>
                    <div class="modal-root__item-close"
                        v-if="!modal.props.noClose"
                        v-on:click="close"
                        >
                        <div class="modal-root__item-close-icon">
                            <icon name="close" />
                        </div>
                    </div>
                </div>
            </transition-group>
        </div>
    </transition>
</template>

<script>
import config from '~/config';

export default {
    name: 'modal-root',
    data: () => ({
        isActive: false,
        defaults: {
            register: {
                noClose: true,
                // noOverlayAction: true,
            },
            login: {
                noClose: true,
                // noOverlayAction: true,
            },
            'sms-input': {
                isOverlayVisible: true,
            },
            'auth-required': {
                title: 'Необходима авторизация',
                text: 'Для выполнения данной операции необходимо авторизоваться',
                buttons: [
                    {
                        text: 'Вход',
                        href: config.urls.auth.login + '?next=' + window.location.pathname,
                    },
                    {
                        text: 'Регистрация',
                        href: config.urls.auth.registration + '?next=' + window.location.pathname,
                    },
                ],
            },
            'verification-required': {
                title: 'Необходима верификация',
                text: 'Для выполнения данной операции необходимо пройти верификацию',
                buttons: [
                    {
                        text: 'Личный кабинет',
                        href: config.urls.cabinet.dashboard,
                    },
                ],
            },
        },
    }),
    computed: {
        backgroundLayerOffset() {
            if (this.list.length <= 1) {
                return null;
            }
            const lastModalWithOverlayReversedIndex = this.list.map(x => !!x.props.isOverlayVisible).reverse().findIndex(x => x);
            return lastModalWithOverlayReversedIndex > -1 ? this.list.length - 1 - lastModalWithOverlayReversedIndex : null;
        },
        list() {
            return this.$store.getters['modals/list'].map(m => {
                if (this.defaults[m.name] !== undefined) {
                    m.props = {
                        ...this.defaults[m.name],
                        ...m.props,
                    };
                }
                return m;
            });
        },
    },
    methods: {
        close() {
            this.$store.commit('modals/pop');
        },
        deactivate() {
            if (this.list.length === 0) {
                this.isActive = false;
            }
        },
    },
    watch: {
        list(newList, oldList) {
            const modalsAmountDifference = newList.length - oldList.length;
            if (modalsAmountDifference > 0) {
                this.$store.commit('common/modifyBodyFixedCounter', modalsAmountDifference);
                this.isActive = true;
            } else if (modalsAmountDifference < 0) {
                this.$store.commit('common/modifyBodyFixedCounter', modalsAmountDifference);
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-root {
    .transition-fade();

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: @z-index-modal;

    background-color: @color-gray-lighter;
    &__headline {
        .container-paddings();

        position: absolute;
        top: 0;
        right: 0;
        left: 0;

        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: @container-header-height;
        .cms-toolbar-expanded & {
            top: @container-cms-height;
        }
        &-logo {
            font-size: 2.4rem;
            text-decoration: none;
        }
    }
    &__item {
        .transition-scale-fade();

        position: absolute;
        top: @container-header-height;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 0 @container-padding @container-padding;

        background-color: @color-gray-lighter;
        .cms-toolbar-expanded & {
            top: @container-header-height + @container-cms-height;
        }
        &-container {
            width: 100%;
            height: 100%;
            border-radius: @border-radius-lg;
            overflow: auto;

            background: @color-gray-lightest;
            &-content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                width: 100%;
                height: 100%;
            }
        }
        &-close {
            position: absolute;
            top: 0;
            right: @container-padding;
            z-index: 2;

            padding: 16px;

            cursor: pointer;

            transition: filter @duration-fast @easing-default;
            &-icon {
                padding: 9px;
                border-radius: 50%;

                font-size: 1.3rem;

                background-color: @color-gray-light;
            }
            &:hover {
                filter: invert(1);
            }
        }
    }
    @media @media-md-down {
        &__item {
            padding: 0 @container-padding-mobile @container-padding-mobile;
        }
    }
    @media @media-sm-down {
        &__headline {
            height: @container-header-height-mobile;
            &-logo {
                font-size: 1.8rem;
            }
        }
        &__item {
            top: @container-header-height-mobile;
            .cms-toolbar-expanded & {
                top: @container-header-height-mobile + @container-cms-height;
            }
            &-container {
                height: auto;
                max-height: 100%;
            }
            &-close {
                top: auto;
                right: 0;
                bottom: 100%;

                padding: 18px 24px;
                &-icon {
                    padding: 7px;

                    font-size: 1rem;
                }
            }
        }
    }
}
</style>
