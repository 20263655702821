<template>
    <section class="page-error">
        <div class="page-error__info">
            <div class="page-error__info-content">
                <div class="page-error__info-content-title">
                    Что-то пошло не так...
                </div>
                <div class="page-error__info-content-subtitle">
                    Нам очень жаль, попробуйте вернуться на главную
                </div>
                <div class="page-error__info-content-button">
                    <ui-button
                        v-bind:href="urlHome"
                        icon-right="arrow-right"
                        >
                        На главную
                    </ui-button>
                </div>
            </div>
        </div>
        <div class="page-error__image" />
    </section>
</template>

<script>
import config from '~/config';

export default {
    name: 'page-error',
    data: () => ({
        urlHome: config.urls.home,
    }),
};
</script>

<style scoped lang="less">
@import '~theme';

.page-error {
    .container();
    .container-paddings();

    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    padding-top: 10px;
    padding-bottom: 40px;
    min-height: calc(100vh - @container-header-height);
    &__info {
        .container-paddings();

        flex: 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        padding-top: 40px;
        padding-bottom: 40px;

        background-color: @color-gray-lighter;
        &-content {
            max-width: 450px;
            width: 100%;
            &-title {
                .typography-medium();

                margin-bottom: 16px;
            }
            &-subtitle {
                .typography-small();

                margin-bottom: 40px;
            }
            &-button {
                width: 184px;
            }
        }
    }
    &__image {
        flex: 0 0 auto;
        width: 50%;

        background-color: #b597df;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url('/static/images/page-error-bg.jpg');
    }
    @media @media-md-down {
        min-height: calc(100vh - @container-header-height-mobile);
        padding: 0;
        &__info {
            width: 100%;

            background-color: #f3eff9;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-image: url('/static/images/page-error-bg-mobile.jpg');
            &-content {
                padding-bottom: 40vh;

                text-align: center;
                &-button {
                    margin: 0 auto;
                }
            }
        }
        &__image {
            display:none;
        }
    }
}
</style>
