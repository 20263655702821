import { mainClient } from '~/network';

const moduleTitle = 'blog';

const state = {
    items: {
        state: 'initial',
        data: null,
        count: 0,
    },
};

const getters = {
    items(state) {
        return state.items.data;
    },
    count(state) {
        return state.items.count;
    },
    isLoading(state) {
        return state.items.state === 'loading';
    },
};

const actions = {
    async getItems({ state, commit }, payload = {}) {
        if (state.items.state === 'loading') {
            return;
        }

        commit('setItemsState', 'loading');
        const { data, error, status } = await mainClient[moduleTitle].requests.getItems(payload);
        if (error) {
            console.error(`Error in action ${moduleTitle}/getItems (${status})`);
            commit('setItemsData', []);
            commit('setItemsState', 'error');
        } else if (data) {
            commit('setItemsData', data.items);
            commit('setItemsCount', data.paging.count);
            commit('setItemsState', 'loaded');
        }
    },
};

const mutations = {
    setItemsState(state, value) {
        state.items.state = value;
    },
    setItemsData(state, value) {
        state.items.data = value;
    },
    setItemsCount(state, value) {
        state.items.count = value;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
