// import config from '~/config';
// import { mainClient } from '~/network';
// import utils from '../../utils';

const state = {
    legalType: null,
};

const getters = {
};

const actions = {
    // async getUserProfile({ state }) {
    //     if (state.getUserProfileIsLoading) {
    //         return;
    //     }

    //     state.getUserProfileIsLoading = true;

    //     const { data, error, status } = await mainClient.users.requests.getUserProfile();
    //     if (error) {
    //         console.error(`Error in action ${moduleTitle}/getUserProfile (${status})`);
    //     } else {
    //         state.getUserProfileResult = data;
    //     }
    //     state.getUserProfileIsLoading = false;
    // },
};

const mutations = {
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
