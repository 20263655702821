<template>
    <div class="common-footer-collapsable"
        v-bind:class="{ _collapsed: isCollapsed }"
        >
        <div class="common-footer-collapsable__title">
            <div class="common-footer-collapsable__title-text"
                v-text="title"
                v-on:click="titleClickHandler"
            />
            <div class="common-footer-collapsable__title-icon"
                v-on:click="titleClickHandler"
                >
                <icon name="triangle" />
            </div>
        </div>
        <div class="common-footer-collapsable__content">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'common-footer-collapsable',
    props: {
        title: {
            type: String,
        },
        isCollapsed: {
            type: Boolean,
            default: true,
        },
    },
    model: {
        prop: 'isCollapsed',
        event: 'update',
    },
    methods: {
        titleClickHandler() {
            if (window.innerWidth < 1024) {
                this.$emit('update', !this.isCollapsed);
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.common-footer-collapsable {
    &__title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &-text {
            .typography-caption();

            font-weight: 700;
            text-transform: uppercase;
        }
        &-icon {
            display: none;

            font-size: 1.8rem;
            vertical-align: middle;
        }
    }
    @media @media-xs-down {
        &__title {
            justify-content: center;
            &-text {
                margin-right: 8px;

                cursor: pointer;
            }
            &-icon {
                display: block;

                cursor: pointer;
                transform: rotate(0);

                transition: transform @duration-fast @easing-default;
            }
            ._collapsed > & &-icon {
                transform: rotate(180deg);
            }
        }
        &__content {
            ._collapsed > & {
                display: none;
            }
        }
    }
}
</style>
