<template>
    <section class="page-admin-estates-item-modes">
        <div class="page-admin-estates-item-modes__item"
            v-for="(item, i) in modes"
            v-bind:key="i"
            v-bind:class="{
                _active: item.code === activeMode,
                _invalid: item.isInvalid,
            }"
            v-on:click="$emit('update', item.code)"
            v-text="item.title"
        />
    </section>
</template>

<script>
export default {
    name: 'page-admin-estates-item-modes',
    props: {
        modes: {
            type: Array,
        },
        activeMode: {
            type: String,
        },
    },
    model: {
        prop: 'activeMode',
        event: 'update',
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-admin-estates-item-modes {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    &__item {
        .typography-heading();

        position: relative;
        z-index: 1;

        display: flex;
        width: fit-content;
        padding-bottom: 26px;

        color: @color-gray-main;
        text-decoration: none;
        white-space: nowrap;

        transition: color @duration-fast ease-in-out;

        cursor: pointer;
        &._active {
            color: @color-gray-darkest;
        }
        &._invalid {
            color: @color-accent-negative;
        }
        &:after {
            content: '';

            position: absolute;
            bottom: 17px;
            left: 0;
            z-index: 1;

            display: block;
            width: 3px;
            height: 3px;
            border-radius: 3px;

            background-color: currentColor;

            opacity: 0;

            transition: opacity @duration-fast ease-in-out, width @duration-fast ease-in-out;
        }
        &._active:after,
        &:hover:after {
            width: 24px;
            opacity: 1;
        }
    }
    @media @media-md-down {
        flex-direction: row;
        &__item {
            padding: 8px;
            &:after {
                display: none;
            }
            &:last-child {
                padding-right: 24px;
            }
        }
    }
}
</style>
