<template>
    <div class="plugin-about-iframe">
        <div class="plugin-about-iframe__container">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'plugin-about-iframe',
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-about-iframe {
    .container();
    .container-paddings();
    &__container {
        width: calc(@grid-column * 10 + @grid-gap * 9);
        margin: 0 auto;
        & > iframe {
            width: 100%;
        }
    }
    @media @media-sm-down {
        &__container {
            width: 100%;
        }
    }
}
</style>
