<template>
    <div>
        plugin-estates-navigable-container
    </div>
</template>

<script>
export default {
    name: 'plugin-estates-navigable-container',
    props: [ 'estateId' ],
    provide: function() {
        return {
            estateId: this.estateId,
        };
    },
    data: () => ({
        activeItem: null,
    }),
    computed: {
        user() {
            return this.$store.getters['users/user'];
        },
        isInvestAvailable() {
            return this.user !== null && this.user.status !== 'new';
        },
    },
    methods: {
        setActiveItem(id) {
            this.activeItem = id;
        },
        investButtonHandler() {
            if (this.isInvestAvailable) {
                this.$store.commit('modals/push', { name: 'invest', props: { estateId: this.estateId } });
            } else {
                this.$store.commit('modals/push', 'verification-warning');
            }
        },
        checkItems() {
            for (const ref in this.$refs) {
                const item = this.$refs[ref];
                const bottom = item.getBoundingClientRect().bottom;
                // if ((window.innerWidth > 767 && bottom > 5) || (window.innerWidth <= 767 && bottom > 117)) {
                if (bottom > 5) {
                // if (top >= 0) {
                    const id = parseInt(ref.substring(4));
                    this.setActiveItem(id);
                    break;
                }
            }
        },
    },
    mounted() {
        this.checkItems();
        window.addEventListener('scroll', this.checkItems);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.checkItems);
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-estates-navigable-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    &__menu {
        position: sticky;
        top: 97px;

        flex: 0 0 auto;
        width: calc((@grid-gap + @grid-column) * 3);
        padding-left: calc(@grid-gap * 2 + @grid-column);
        margin-top: 80px;
        margin-bottom: 24px;
        &-item {
            &-link {
                .typography-heading();

                position: relative;
                z-index: 1;

                display: inline-block;
                padding-bottom: 26px;

                color: @color-gray-main;

                transition: color @duration-fast ease-in-out;
                &:before {
                    content: '';

                    position: absolute;
                    bottom: 17px;
                    left: 0;
                    z-index: 1;

                    display: block;
                    width: 3px;
                    height: 3px;
                    border-radius: 3px;

                    background-color: currentColor;

                    opacity: 0;

                    transition: opacity @duration-fast ease-in-out, width @duration-fast ease-in-out;
                }
                &._active {
                    color: @color-gray-darker;
                    &:before {
                        width: 24px;

                        opacity: 1;
                    }
                }
                &:hover {
                    &:before {
                        width: 24px;

                        opacity: 1;
                    }
                }

                text-decoration: none;
            }
        }
        &-button {
            .typography-body();

            display: block;
            margin-top: 6px;

            text-decoration: none;
        }
    }
    &__content {
        flex: 1 1 auto;
        width: calc(100% - (@grid-gap + @grid-column) * 3);
        &-item {
            padding-top: 80px;
        }
    }
    @media @media-md-down {
        &__menu {
            padding-left: @grid-gap;
        }
    }
    @media @media-sm-down {
        &__menu {
            .container-paddings();

            position: fixed;
            top: 60px;
            left: 0;

            display: flex;
            justify-content: flex-start;
            align-items: stretch;
            width: 100%;
            height: 57px;
            margin-top: 0;
            margin-bottom: 0;
            overflow: auto;

            background: @color-gray-lightest;

            box-shadow: inset 0 3px 3px -3px fade(@color-gray-darkest, 20%);

            &:after {
                content: '';

                display: block;
                width: 20px;
                flex: 0 0 auto;
            }
            &-item {
                .typography-heading();

                display: flex;
                justify-content: flex-start;
                align-items: center;

                white-space: nowrap;
                & ~ & {
                    margin-left: 16px;
                }
                &-link {
                    padding-bottom: 0;
                    &:before {
                        display: none;
                    }
                }
            }
            &-button {
                display: none;
            }
        }
        &__content {
            width: 100%;
            &-item {
                padding-top: 57px;
            }
        }
    }
}
</style>
