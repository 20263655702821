<template>
    <div class="modal-invest">
        <template v-if="isAnyLoading">
            <div class="modal-invest__loader">
                <ui-loader/>
            </div>
        </template>
        <template v-else-if="error">
            <ui-card-base>
                <span v-text="error"/>
            </ui-card-base>
        </template>
        <template v-else-if="success">
            <div class="modal-invest__success"
                v-if="isRequestCreationAvailable"
                >
                <div class="modal-invest__success-title"
                    v-text="'Ваша заявка принята!'"
                />
                <div class="modal-invest__success-subtitle"
                    v-text="'Ожидайте сбора оставшейся суммы.'"
                />
            </div>
            <div class="modal-invest__success"
                v-else
                >
                <div class="modal-invest__success-title"
                    v-text="'Сумма вашей заявки увеличена!'"
                />
                <div class="modal-invest__success-subtitle"
                    v-text="'Ожидайте сбора оставшейся суммы.'"
                />
            </div>
        </template>
        <template v-else-if="getItemResult">
            <div class="modal-invest__cover">
                <common-image
                    v-bind:image="image"
                    border-radius="0"
                    is-absolute
                />
            </div>
            <div class="modal-invest__content">
                <div class="modal-invest__balance">
                    <ui-label
                        v-bind:value="balanceFormatted + ' ₽'"
                        title="Баланс"
                        is-uppercase
                    />
                </div>
                <div class="modal-invest__title"
                    v-text="getItemResult.title"
                />
                <div class="modal-invest__warning"
                    v-if="lastEditableRequest"
                    >
                    <div class="modal-invest__warning-icon">
                        <icon name="warning" />
                    </div>
                    Вы уже оставляли инвестиционную заявку для данного объекта.
                    <br />
                    Параллельное создание новой заявки невозможно.
                    <br />
                    Вы можете увеличить сумму существующей заявки.
                    <br />
                    <br />
                    Ваша заявка:
                    <div class="modal-invest__warning-sum"
                        v-bind:class="{ _changed: oldRequestAmount !== newRequestAmount }"
                        >
                        <div class="modal-invest__warning-sum-value"
                            v-text="oldRequestAmountFormatted"
                        />
                        <div class="modal-invest__warning-sum-arrow">
                            <icon name="arrow-right" height="auto" />
                        </div>
                        <div class="modal-invest__warning-sum-value"
                            v-text="newRequestAmountFormatted"
                        />
                    </div>
                </div>
                <div class="modal-invest__disclaimer"
                    v-else
                    v-text="disclaimer"
                />
                <div class="modal-invest__form">
                    <div class="modal-invest__form-fields">
                        <ui-form
                            v-bind:model="fields"
                            v-bind:validation="$v"
                            v-bind:submit-handler="submitHandler"
                            v-on:update="updateFormData"
                        />
                        <div class="modal-invest__form-fields-checkbox">
                            <ui-input-checkbox
                                v-model="policy.value"
                                v-bind:model="policy"
                                v-bind:validation="$v.policy.value"
                            />
                        </div>
                    </div>
                    <div class="modal-invest__form-error"
                        v-bind:class="{ _active: error }"
                        >
                        <transition name="fade">
                            <span class="modal-invest__form-error-text"
                                v-if="error"
                                v-text="error"
                            />
                        </transition>
                    </div>
                    <div class="modal-invest__form-buttons">
                        <a class="modal-invest__form-buttons-item"
                            v-bind:href="qualificationHref"
                            v-if="$v.$dirty && !$v.fields.amount.value.isValidLimit"
                            target="_blank"
                            >
                            <ui-button theme="primary">
                                Подтвердить статус
                            </ui-button>
                        </a>
                        <a class="modal-invest__form-buttons-item"
                            v-bind:href="refillHref"
                            v-if="$v.$dirty && !$v.fields.amount.value.isValidBalance"
                            target="_blank"
                            >
                            <ui-button theme="primary">
                                Пополнить счет
                            </ui-button>
                        </a>
                        <div class="modal-invest__form-buttons-item">
                            <ui-button
                                v-bind:disabled="$v.$dirty && $v.$invalid"
                                v-on:click="submitHandler"
                                theme="primary"
                                >
                                {{ isRequestCreationAvailable ? 'Оставить заявку' : 'Увеличить заявку' }}
                            </ui-button>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import config from '~/config';
import forms from '~/forms';
import utils from '~/utils';

export default {
    name: 'modal-invest',
    props: {
        availableInvestment: {
            type: Number,
            default: null,
        },
        estateId: {
            type: [ Number, String ],
            default: null,
        },
    },
    data: () => ({
        success: false,
        fields: {
            // investmentType: { ...forms.fields.investmentType },
            sharesQuantity: { ...forms.fields.sharesQuantity, isSeparatorNeeded: true },
            amount: { ...forms.fields.amount },
        },
        policy: { ...forms.fields.policy },
        smsCode: null,
        refillHref: config.urls.cabinet.refill,
        qualificationHref: config.urls.cabinet.qualification,
    }),
    validations() {
        const amount = { ...forms.validations.genericRequired };
        amount.value = {
            ...amount.value,
            isValidMin: (value) => parseFloat(value) >= this.minInvestment,
            isValidLimit: (value) => this.availableInvestment === null || parseFloat(value) <= this.availableInvestment,
            isValidRest: (value) => this.restInvestment === null || parseFloat(value) <= this.restInvestment,
            isValidBalance: (value) => !value || parseFloat(value) <= this.balance,
        };
        return {
            fields: {
                // investmentType: { ...forms.validations.genericRequired },
                amount,
            },
            policy: {
                value: {
                    isValid: (value) => value,
                },
            },
        };
    },
    computed: {
        ...mapState('dashboard', [
            'getHighlightsResult',
            'getHighlightsError',
            'getHighlightsIsLoading',
        ]),
        ...mapState('estates', [
            'getItemResult',
            'getItemError',
            'getItemIsLoading',
        ]),
        ...mapState('investments', [
            'getRequestsResult',
            'getRequestsError',
            'getRequestsIsLoading',

            'createRequestResult',
            'createRequestError',
            'createRequestIsLoading',

            'editRequestResult',
            'editRequestError',
            'editRequestIsLoading',
        ]),
        currentRoundRequests() {
            if (!this.getItemResult || !this.getRequestsResult) {
                return null;
            }
            const currentRound = (this.getItemResult.investmentCampaigns.findIndex((round) => round.isActive) || 0) + 1;
            const result = this.getRequestsResult.filter((request) => request.round === currentRound);
            return result;
        },
        lastEditableRequest() {
            if (!this.currentRoundRequests) {
                return null;
            }
            return this.currentRoundRequests.find(x => x.status === 'new') || null;
        },
        correction() {
            let correction = 0;
            if (this.lastEditableRequest && this.getItemResult) {
                correction = this.lastEditableRequest.amount - Math.floor(this.lastEditableRequest.amount / this.getItemResult.sharePrice) * this.getItemResult.sharePrice;
            }
            return correction;
        },
        isRequestCreationAvailable() {
            if (!this.currentRoundRequests) {
                return false;
            }
            return this.currentRoundRequests.every(x => x.status !== 'new');
        },
        oldRequestAmount() {
            return this.lastEditableRequest ? parseFloat(this.lastEditableRequest.amount) : 0;
        },
        oldRequestAmountFormatted() {
            return utils.formats.formatNumberWithSpaces(this.oldRequestAmount, true);
        },
        newRequestAmount() {
            return this.oldRequestAmount ? this.oldRequestAmount + parseFloat(this.fields.amount.value || 0) : 0;
        },
        newRequestAmountFormatted() {
            return utils.formats.formatNumberWithSpaces(this.newRequestAmount, true);
        },
        isQualificationLinkVisible() {
            return this.$v.$dirty && this.$v.$invalid && !this.$v.fields.amount.value.isValidLimit &&
                this.$v.fields.amount.value.isValidMin &&
                this.$v.fields.amount.value.isValidRest &&
                this.$v.fields.amount.value.isValidBalance;
        },
        restInvestment() {
            if (this.getItemResult === null) {
                return 0;
            }
            return Math.round(this.getItemResult.target * this.getItemResult.targetThresholdMultiplier - this.getItemResult.balance);
        },
        maxInvestment() {
            return this.availableInvestment === null ? this.restInvestment : Math.min(this.availableInvestment, this.restInvestment);
        },
        minInvestment() {
            if (!this.getItemResult) {
                return 0;
            }
            if (this.oldRequestAmount > 0) {
                return Math.max(this.getItemResult.minimumInvestment - this.oldRequestAmount, 1);
            }
            return this.getItemResult.minimumInvestment;
        },
        isInitialDataLoading() {
            return this.getHighlightsIsLoading ||
                this.getRequestsIsLoading ||
                this.getItemIsLoading;
        },
        isAnyLoading() {
            return this.isInitialDataLoading ||
                this.createRequestIsLoading ||
                this.editRequestIsLoading;
        },
        currency() {
            return this.$store.getters['site/currency'];
        },
        user() {
            return this.$store.getters['users/user'];
        },
        balance() {
            return this.getHighlightsResult ? this.getHighlightsResult.balance : -1;
        },
        balanceFormatted() {
            return utils.formats.formatNumberWithSpaces(this.balance);
        },
        userId() {
            return this.user ? this.user.id : -1;
        },
        image() {
            return utils.common.injectResize(this.getItemResult.image, utils.common.getResize('458'));
        },
        disclaimer() {
            let result = 'Введите сумму, которую хотите инвестировать. После обработки заявки, наш менеджер свяжется с вами для уточнения деталей.';
            if (this.minInvestment !== null && this.minInvestment > 0) {
                result += `Минимальная сумма инвестиции для этого объекта - ${utils.formats.formatNumberWithSpaces(this.minInvestment)} ${this.currency.name.short}`;
            }
            return result;
        },
        placeholder() {
            let placeholder = `Мин. ${utils.formats.formatNumberWithSpaces(this.minInvestment)} ${this.currency.name.short}`;
            if (this.maxInvestment !== null && this.maxInvestment > 0) {
                placeholder += ` и макс. ${utils.formats.formatNumberWithSpaces(this.maxInvestment)} ${this.currency.name.short}`;
            }
            return placeholder;
        },
        error() {
            if (
                this.getHighlightsError ||
                this.getItemError ||
                this.getRequestsError ||
                this.createRequestError ||
                this.editRequestError
            ) {
                return 'Ошибка загрузки данных, попробуйте позже';
            }
            return null;
        },
    },
    methods: {
        updateFormData(newData) {
            const trigger = newData.amount.value !== this.fields.amount.value ? 'amount' : 'sharesQuantity';
            this.fields = newData;
            if (trigger === 'amount') {
                this.fields.sharesQuantity.value = Math.floor(parseFloat(this.fields.amount.value || 0) / this.getItemResult.sharePrice);
                this.fields.amount.value = this.fields.sharesQuantity.value * this.getItemResult.sharePrice - this.correction;
            } else if (trigger === 'sharesQuantity') {
                this.fields.amount.value = parseInt(this.fields.sharesQuantity.value || 0) * this.getItemResult.sharePrice - this.correction;
                this.fields.sharesQuantity.value = Math.floor((this.fields.amount.value + this.correction) / this.getItemResult.sharePrice);
            }
        },
        close() {
            this.$store.commit('modals/pop');
        },
        setCode(code) {
            this.smsCode = code;
            if (this.isRequestCreationAvailable) {
                const payload = {
                    user: this.userId,
                    estate: this.estateId,
                    amount: this.fields.amount.value,
                    code: this.smsCode,
                    value: this.user.phone,
                    // type: this.fields.investmentType.value,
                    type: this.getItemResult.availableInvestmentTypes[0].id,
                };
                this.$store.dispatch('investments/createRequest', payload);
            } else if (this.lastEditableRequest) {
                this.$store.dispatch('investments/editRequest', {
                    id: this.lastEditableRequest.id,
                    code: this.smsCode,
                    value: this.user.phone,
                    amount: parseFloat(this.fields.amount.value) + parseFloat(this.lastEditableRequest.amount),
                });
            }
        },
        submitHandler() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            if (this.smsCode === null) {
                this.$store.commit('modals/push', {
                    name: 'sms-input',
                    props: {
                        smsVerificationProps: {
                            phone: this.user.phone,
                            isRequestRequired: true,
                        },
                    },
                    on: {
                        submit: this.setCode,
                    },
                });
            }
        },
        checkUser() {
            if (this.user === null) {
                return;
            }
            if (this.user === 'guest') {
                window.location.href = config.urls.auth.login + '?next=' + window.location.pathname;
            }
        },
    },
    beforeMount() {
        if (this.availableInvestment === null && this.user && this.user.isLimitedTo600k) {
            this.$store.commit('modals/push', { name: 'pre-invest', props: { estateId: this.estateId } });
            this.$store.commit('modals/popByIndex', this.$store.getters['modals/list'].length - 2);
            return;
        }
        this.$store.dispatch('investments/getRequests', { estate: this.estateId });
        this.$store.dispatch('estates/getItem', { id: this.estateId });
        this.$store.dispatch('dashboard/getHighlights');
        if (this.availableInvestment !== null) {
            this.fields.amount.errors.isValidLimit = `Превышен лимит в ${utils.formats.formatNumberWithSpaces(this.availableInvestment)} руб. для неквалифицированных инвесторов. Чтобы инвестировать без ограничений, подтвердите статус квалифицированного инвестора`;
        }
    },
    beforeDestroy() {
        if (this.success) {
            window.location.reload();
        }
    },
    watch: {
        user: {
            immediate: true,
            handler() {
                this.checkUser();
            },
        },
        placeholder: {
            immediate: true,
            handler(newVal) {
                this.fields.amount.placeholder = newVal;
                // this.fields.investmentType.options = this.fields.investmentType.options
                //     .filter(option => !!this.getItemResult.availableInvestmentTypes
                //         .find(type => type.code === option.code));
                // if (this.getItemResult.availableInvestmentTypes.length === 1) {
                //     this.fields.investmentType.value = this.getItemResult.availableInvestmentTypes[0].id + '';
                //     this.fields.investmentType.labelHint = {
                //         html: `<span>Для данного объекта доступен только один тип инвестирования: <br />${this.getItemResult.availableInvestmentTypes[0].title}</span>`,
                //     };
                //     this.fields.investmentType.isDisabled = true;
                // }
            },
        },
        createRequestIsLoading(newVal) {
            if (!newVal && this.createRequestResult) {
                this.success = true;
            }
        },
        editRequestIsLoading(newVal) {
            if (!newVal && this.editRequestResult) {
                this.success = true;
            }
        },
        isInitialDataLoading(newVal, oldVal) {
            if (!newVal && oldVal) {
                if (this.lastEditableRequest) {
                    this.fields.sharesQuantity.value = 1;
                    this.fields.amount.value = this.getItemResult.sharePrice - this.correction;
                } else {
                    const newFormData = JSON.parse(JSON.stringify(this.fields));
                    newFormData.amount.value = Math.max(this.getItemResult.sharePrice, this.minInvestment);
                    this.updateFormData(newFormData);
                }
            }
            if (!newVal &&
                this.getRequestsResult &&
                this.getRequestsResult.length > 0 &&
                !this.lastEditableRequest &&
                !this.isRequestCreationAvailable
            ) {
                this.$store.commit('modals/pop');
                this.$store.commit('modals/push', {
                    name: 'generic',
                    props: {
                        title: 'Действие недоступно',
                        text: `У вас уже есть подписанная инвестиционная заявка для данного объекта, ее изменение или параллельное создание новой заявки невозможно.`,
                        theme: 'error',
                    },
                });
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-invest {
    .modal();
    &__loader {
        width: 100px;
        margin: 100px auto;
    }
    &__cover {
        position: relative;

        padding-top: 50%;
        border-radius: @border-radius-lg;
        overflow: hidden;
    }
    &__content {
        padding: 16px 0 35px;
    }
    &__success {
        padding: 0 0 80px;

        text-align: center;
        &-title {
            .typography-large();

            margin-bottom: 8px;
        }
        &-subtitle {
            .typography-body();
        }
    }
    &__balance {
        width: fit-content;
        margin: 0 0 8px auto;
    }
    &__title {
        .typography-large();

        margin-bottom: 8px;
    }
    &__disclaimer {
        .typography-body();

        margin-bottom: 12px;
    }
    &__warning {
        .typography-body();

        margin-bottom: 24px;

        padding-left: 16px;
        border-left: 2px solid @color-primary;
        &-icon {
            margin-bottom: 16px;

            color: @color-primary;
            font-size: 2.4rem;
        }
        &-sum {
            .typography-medium();

            display: flex;
            align-items: center;
            justify-content: flex-start;

            white-space: nowrap;
            &-value {
                flex: 0 0 auto;
            }
            &-arrow {
                flex: 0 0 auto;
                padding: 0 2ch;
            }
            &-value:not(:first-child),
            &-arrow {
                opacity: 0;
                pointer-events: none;

                transition: opacity @duration-fast @easing-default;
            }
            &._changed & {
                &-value,
                &-arrow {
                    opacity: 1;
                    pointer-events: all;
                }
            }
        }
    }
    &__form {
        .typography-body();

        margin-bottom: 16px;

        &-fields {
            margin-bottom: 60px;
            &-checkbox {
                margin-top: 24px;
            }
        }
        &-error {
            margin-bottom: 25px;
            &-text {
                .transition-fade();

                color: @color-accent-negative;
            }
        }
        &-qualification {
            margin-bottom: 24px;

            text-align: center;
            &-link {
                .typography-body();

                display: inline-flex;
                align-items: center;

                text-decoration: none;
                &-text {
                    text-decoration: underline;
                }
                &-icon {
                    margin-left: 8px;
                }
                &:hover &-text {
                    text-decoration: none;
                }
            }
        }
        &-loader {
            height: 56px;
            width: 56px;
            margin: auto;
        }
        &-buttons {
            &-item {
                display: block;
                height: 56px;
                width: 206px;
                margin: auto;

                text-decoration: none;
                & ~ & {
                    margin-top: 24px;
                }
            }
        }
    }
    @media @media-md-down {
        &__title {
            .typography-medium();
        }
    }
    @media @media-sm-down {
        padding: 0;
        &__cover {
            border-radius: 0;
        }
        &__success {
            padding: 40px 16px;
        }
        &__content {
            padding: 12px 16px 35px;
        }
    }
    @media @media-xs-down {
        &__warning {
            &-sum {
                .typography-body();
                &-arrow {
                    padding: 0 1ch;
                }
            }
        }
    }
}
</style>
