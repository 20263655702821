<template>
    <article class="page-cabinet-dashboard-announcements">
        <div class="page-cabinet-dashboard-announcements__headline">
            Уведомления
        </div>
        <div>
            <ui-card-base with-border>
                <div class="page-cabinet-dashboard-announcements__content">
                    <div class="page-cabinet-dashboard-announcements__content-count"
                        v-text="countText"
                    />
                    <div class="page-cabinet-dashboard-announcements__content-slider swiper-container"
                        ref="slider"
                        >
                        <div class="page-cabinet-dashboard-announcements__content-slider-wrapper swiper-wrapper">
                            <div class="page-cabinet-dashboard-announcements__content-slider-item swiper-slide"
                                v-for="(announcement, index) in items"
                                v-html="announcement.html"
                                v-bind:key="index"
                            />
                        </div>
                    </div>
                    <div class="page-cabinet-dashboard-announcements__content-navigation"
                        v-if="true"
                        >
                        <div class="page-cabinet-dashboard-announcements__content-navigation-prev"
                            ref="prev"
                            >
                            <icon name="chevron-left" />
                        </div>
                        <div class="page-cabinet-dashboard-announcements__content-navigation-pagination"
                            v-if="count > 2"
                            ref="pagination"
                        />
                        <div class="page-cabinet-dashboard-announcements__content-navigation-next"
                            ref="next"
                            >
                            <icon name="chevron-right" />
                        </div>
                    </div>
                </div>
            </ui-card-base>
        </div>
    </article>
</template>

<script>
import { mapState } from 'vuex';
import Swiper from 'swiper';
import utils from '~/utils';

export default {
    name: 'page-cabinet-dashboard-announcements',
    data: () => ({
        currentAnnouncement: 1,
        slider: null,
    }),
    computed: {
        ...mapState('users', [
            'getAnnouncementsResult',
            'getAnnouncementsIsLoading',
            'getAnnouncementsError',
        ]),
        count() {
            return this.getAnnouncementsResult.count;
        },
        countText() {
            return `${this.currentAnnouncement}/${this.count}`;
        },
        items() {
            return this.getAnnouncementsResult.items;
        },
    },
    methods: {
        initSwiper() {
            if (this.slider !== null) {
                this.slider.update();
                return;
            }
            const container = this.$refs.slider;
            const paginationEl = this.$refs.pagination;
            const prevEl = this.$refs.prev;
            const nextEl = this.$refs.next;
            const vue = this;
            const params = {
                speed: 600,
                slidesPerView: 1,
                loop: true,
                lazy: {
                    loadPrevNext: true,
                },
                navigation: {
                    prevEl,
                    nextEl,
                },
                on: {
                    slideChange: function() {
                        vue.currentAnnouncement = this.realIndex + 1;
                    },
                },
            };
            if (this.count > 2) {
                params.pagination = {
                    el: paginationEl,
                    type: 'bullets',
                    bulletClass: 'page-cabinet-dashboard-announcements__content-navigation-pagination-item',
                    bulletActiveClass: '_active',
                    clickable: true,
                };
            }
            this.slider = new Swiper(container, params);
        },
    },
    mounted() {
        this.initSwiper();
        utils.dom.addLoadedCallback(this.initSwiper);
    },
    beforeDestroy() {
        utils.dom.removeLoadedCallback(this.initSwiper);
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-dashboard-announcements {
    margin-top: 20px;
    margin-bottom: 4px;
    &__headline {
        .typography-heading();

        padding: 7px 16px;
    }
    &__content {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        &-count {
            .typography-body();
            flex: 0 0 auto;
            min-width: 44px;
            padding-right: 24px;

            font-weight: bold;
        }
        &-slider {
            flex: 1 1 auto;
            &-item {
                padding-right: 24px;
                /deep/ p {
                    .typography-body();

                    max-width: 610px;
                    margin: 0;

                    white-space: pre-line;
                }
            }
        }
        &-navigation {
            flex: 0 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto 0;
            &-prev,
            &-next {
                flex: 0 0 auto;
                padding: 8px;

                color: @color-gray-main;
                font-size: 2rem;

                cursor: pointer;
            }
            &-pagination {
                flex: 0 0 auto;
                display: flex;
                margin: 0 12px;
                /deep/ &-item {
                    flex: 0 0 auto;
                    padding: 4px;

                    opacity: 0.4;
                    cursor: pointer;

                    transition: opacity @duration-fast @easing-default;
                    &._active,
                    &:hover {
                        opacity: 1;
                    }
                    &:before {
                        content: '';

                        display: block;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;

                        background-color: @color-gray-main;
                    }
                }
            }
        }
    }
    @media @media-md-down {
        &__content {
            display: block;
            &-count {
                display: none;
            }
            &-navigation {
                margin: 16px auto 0;
            }
        }
    }
}
</style>
