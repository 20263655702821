<template>
    <div>
        plugin-spacer
    </div>
</template>

<script>
export default {
    name: 'plugin-spacer',
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-spacer {
    &._desktop {
        display: block;
    }
    &._mobile {
        display: none;
    }
    @media @media-md-down {
        &._desktop {
            display: none;
        }
        &._mobile {
            display: block;
        }
    }
}
</style>
