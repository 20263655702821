<template>
    <div class="modal-status-form-notifications">
        <ui-form
            v-bind:model="fields"
            v-bind:validation="$v"
            v-bind:submit-handler="submitHandler"
            v-on:update="updateFormData"
        />
    </div>
</template>

<script>
import forms from '~/forms';

export default {
    name: 'modal-status-form-notifications',
    props: {
        formData: {
            type: Object,
        },
    },
    data: () => ({
        innerFields: {
            accountType: { ...forms.fields.status.notificationsType },
            isActive: { ...forms.fields.status.notificationsIsActive },
            method: { ...forms.fields.status.notificationsMethod },
        },
    }),
    validations() {
        const result = {
            fields: {
                accountType: { ...forms.validations.genericRequired },
                isActive: { ...forms.validations.genericRequired },
            },
        };
        if (this.isMethodNeeded) {
            result.fields.method = { ...forms.validations.genericRequired };
        }
        return result;
    },
    computed: {
        isMethodNeeded() {
            return this.formData.notifications?.isActive === 'yes';
        },
        fields() {
            const result = {
                accountType: this.innerFields.accountType,
                isActive: this.innerFields.isActive,
            };
            result.accountType.value = this.formData.notifications?.accountType;
            result.isActive.value = this.formData.notifications?.isActive;
            if (this.isMethodNeeded) {
                result.method = this.innerFields.method;
                result.method.value = this.formData.notifications?.method;
            }
            return result;
        },
        isValid() {
            return !this.$v.$invalid;
        },
    },
    methods: {
        submitHandler() {
            if (this.isValid) {
                this.$emit('submit');
            }
        },
        updateFormData(newData) {
            this.$emit('data-update', {
                ...this.formData,
                notifications: {
                    accountType: newData.accountType.value,
                    isActive: newData.isActive.value,
                    method: newData.isActive.value ? newData.method?.value : null,
                },
            });
        },
    },
    watch: {
        isValid: {
            handler(value) {
                this.$emit('validation-update', value);
            },
            immediate: true,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-status-form-notifications {
}
</style>
