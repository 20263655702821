<template>
    <div class="common-lazy-background">
        <img class="common-lazy-background__image"
            v-if="isReady"
            v-bind:key="image"
            v-bind:src="image"
            v-on:load="setImage"
        />
        <transition name="fade">
            <div class="common-lazy-background__cover"
                v-if="isLoaded"
                v-bind:style="{ backgroundImage: `url(${image})` }"
            />
        </transition>
    </div>
</template>

<script>
import utils from '~/utils';

export default {
    name: 'common-lazy-background',
    props: {
        image: {
            type: String,
        },
    },
    data: () => ({
        isReady: false,
        isLoaded: false,
    }),
    methods: {
        setReady() {
            this.isReady = true;
        },
        setImage() {
            this.isLoaded = true;
        },
    },
    mounted() {
        utils.dom.addLoadedCallback(this.setReady);
    },
    beforeDestroy() {
        utils.dom.removeLoadedCallback(this.setReady);
    },
    watch: {
        image() {
            this.isLoaded = false;
        },
    },
};
</script>

<style scoped lang="less">
@import "~theme";

.common-lazy-background {
    position: relative;

    width: 100%;
    height: 100%;
    &__image {
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;

        visibility: hidden;
        pointer-events: none;
    }
    &__cover {
        .transition-fade();
        .mixin-background-image();

        width: 100%;
        height: 100%;
    }
}
</style>
