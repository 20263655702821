<template>
    <div class="modal-market-application-confirm">
        <transition name="fade">
            <div class="modal-market-application-confirm__loader"
                v-if="isLoading && !error"
                >
                <div class="modal-market-application-confirm__loader-container">
                    <ui-loader />
                </div>
            </div>
        </transition>
        <div class="modal-market-application-confirm__content"
            v-if="!error"
            >
            <modal-market-application-confirm-info
                v-if="currentStep === 'info'"
                v-bind:deal="deal"
                v-on:step-change="setStep"
            />
            <modal-market-application-confirm-confirmation
                v-else-if="currentStep === 'confirmation'"
                v-bind:deal="deal"
                v-on:step-change="setStep"
            />
            <modal-market-application-confirm-success
                v-else
                v-bind:deal="deal"
                v-bind:is-final="deal.counterOrder.status === 'signed'"
            />
            <!-- <modal-market-application-confirm-success
                v-bind:deal="deal"
                v-bind:is-final="deal.counterOrder.status === 'signed'"
            /> -->
        </div>
        <div class="modal-market-application-confirm__error"
            v-if="error"
            >
            Ошибка загрузки данных
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'modal-market-application-confirm',
    props: {
        deal: {
            type: Object,
        },
    },
    data: () => ({
        currentStep: 'info',
    }),
    computed: {
        ...mapState('market', [
            'signOrderResult',
            'signOrderError',
            'signOrderIsLoading',
        ]),
        isLoading() {
            return this.signOrderIsLoading;
        },
        error() {
            return this.signOrderError;
        },
    },
    methods: {
        setStep(newStep) {
            this.currentStep = newStep;
        },
    },
    watch: {
        signOrderIsLoading(newVal) {
            if (!newVal && this.signOrderResult) {
                this.$store.dispatch('market/getDeals');
                this.setStep('success');
            }
        },
    },
    beforeDestroy() {
        this.$store.commit('market/resetSignOrder');
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-market-application-confirm {
    .modal();

    position: relative;

    display: flex;
    flex-direction: column;
    width: 680px;
    &__loader {
        .transition-fade();

        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        background-color: @color-gray-lightest;
        &-container {
            width: 50px;
        }
    }
    &__content {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    &__error {
        .typography-large();

        color: @color-accent-negative;
    }
    @media @media-md-down {
        width: 100%;
        max-width: 100%;
        padding: 16px 16px 24px;
        overflow: auto;
        &__error {
            .typography-medium();
        }
    }
}
</style>
