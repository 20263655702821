<template>
    <component class="common-image"
        v-bind:is="href ? 'a' : 'div'"
        v-bind:href="href"
        v-bind:target="href ? target : null"
        v-bind:class="{ _absolute: isAbsolute }"
        v-bind:style="borderRadius ? { borderRadius } : null"
        >
        <common-lazy-background
            v-bind:image="image"
        />
    </component>
</template>

<script>
export default {
    name: 'common-image',
    props: {
        image: {
            type: String,
        },
        href: {
            type: String,
            default: null,
        },
        borderRadius: {
            type: String,
            default: null,
        },
        target: {
            type: String,
            default: '_blank',
        },
        isAbsolute: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped lang="less">
@import "~theme";

.common-image {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: @border-radius-md;
    overflow: hidden;

    text-decoration: none;

    background-color: @color-gray-lighter;
    &._absolute {
        position: absolute;
        top: 0;
        left: 0;
    }
}
</style>
