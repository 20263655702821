export const countriesItem = (data) => {
    const result = {
        code: data.code,
        title: data.name,
        language: data.lang,
        isDefault: !!data.is_default,
    };
    return result;
};

export const countriesList = (data) => {
    const result = {
        items: data.results.map(countriesItem),
        paging: {
            count: data.count || 0,
            page: data.page || 1,
        },
        ...data,
    };
    return result;
};
