<template>
    <a class="common-qualification-link"
        v-bind:href="url"
        >
        <div class="common-qualification-link__text">
            Подтвердить статус <b>квалифицированного инвестора</b>, чтобы инвестировать более 600{{ '\xa0' }}000 рублей в{{ '\xa0' }}год
        </div>
        <div class="common-qualification-link__arrow">
            <icon name="arrow-right-round" v-bind:fill="false" />
        </div>
    </a>
</template>

<script>
import config from '~/config';

export default {
    name: 'common-qualification-link',
    data: () => ({
        url: config.urls.cabinet.qualification,
    }),
};
</script>

<style scoped lang="less">
@import '~theme';

.common-qualification-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 21px 24px;
    border-radius: @border-radius-lg;

    color: @color-gray-lightest;
    text-decoration: none;

    background-color: @color-gray-darker;
    background-image: url('/static/images/common-qualification-link.jpg');
    background-size: auto 100%;
    background-position: right;
    background-repeat: no-repeat;
    &__text {
        .typography-body();

        flex: 1 1 auto;
        max-width: 350px;
        margin-right: 27px;
        & > b {
            .typography-heading();
        }
    }
    &__arrow {
        flex: 0 0 auto;

        font-size: 4rem;
    }
    @media @media-sm-down {
        flex-direction: column;
        align-items: flex-start;
        padding: 16px 40px 16px 16px;

        background-image: url('/static/images/common-qualification-link_mobile.jpg');
        &__text {
            margin-right: 0;
            margin-bottom: 8px;
        }
    }
}
</style>
