<template>
    <div class="modal-status-form-output">
        <div class="modal-status-form-output__stage"
                v-for="(stage, stageIndex) in stages"
                :key="stageIndex"
            >
            <div class="modal-status-form-output__stage-title"
                v-if="stage.header"
                v-text="stage.header"
            />
            <div class="modal-status-form-output__stage-description"
                v-if="stage.description"
                v-text="stage.description"
            />
            <div class="modal-status-form-output__stage-field"
                v-for="(field, fieldIndex) in stage.fields"
                :key="fieldIndex"
                >
                <div class="modal-status-form-output__stage-field-title"
                    v-text="`${stageIndex + 1}.${fieldIndex + 1}: ${field.label || field.text}`"
                />
                <div class="modal-status-form-output__stage-field-description"
                    v-if="field.legend"
                    v-text="field.legend"
                />
                <div class="modal-status-form-output__stage-field-value"
                    v-text="`ID: ${field.id}`"
                />
                <div class="modal-status-form-output__stage-field-value"
                    v-text="`Type: ${field.type}`"
                />
                <div class="modal-status-form-output__stage-field-value"
                    v-text="`Value: ${field.value}`"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'modal-status-form-output',
    props: {
        formData: {
            type: Object,
        },
    },
    computed: {
        ...mapState('status', [
            'getFormDataResult',
        ]),
        isValid() {
            return true;
        },
        stages() {
            return this.getFormDataResult.form.template.stages;
        },
    },
    // mounted() {
    //     console.log('getFormDataResult', this.getFormDataResult);
    // },
    watch: {
        isValid: {
            handler(value) {
                this.$emit('validation-update', value);
            },
            immediate: true,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-status-form-output {
    &__stage {
        padding-left: 24px;
        margin-bottom: 40px;
        &-title {
            .typography-medium();
        }
        &-description {
            .typography-body();
        }
        &-title + &-field,
        &-description + &-field {
            margin-top: 12px;
        }
        &-field {
            padding-left: 24px;
            margin-bottom: 24px;
            &-title {
                .typography-heading();
            }
            &-description {
                .typography-body();
                opacity: 0.66;
            }
            &-description + &-value,
            &-title + &-value {
                margin-top: 8px;
            }
            &-value {
                .typography-body();
            }
        }
    }
}
</style>
