import config from '~/config';
import utils from '~/utils';

export const userSharesItem = (data) => {
    const investments = parseFloat(data.amount) || 0;
    const dividends = data.dividends || 0;
    const income = data.pure_income || 0;
    const currency = 'руб.';
    const result = {
        id: data.id,
        title: (data.share.estate && data.share.estate.name) || 'Название объекта',
        image: (data.share.estate && data.share.estate.image) || '',
        currency,
        investments,
        dividends,
        income,
        irr: data.irr_income ? (data.irr_income * 100).toFixed(1) : 0,
        irrFormatted: (data.irr_income ? (data.irr_income * 100).toFixed(1) : 0) + '%',
        mm: data.mm_income || 0,
        share: data.portfolio_share ? (data.portfolio_share * 100).toFixed(1) : 0,
        shareFormatted: (data.portfolio_share ? (data.portfolio_share * 100).toFixed(1) : 0) + '%',
        quantity: data.quantity,
        quantityFormatted: utils.formats.formatNumberWithSpaces(data.quantity),
        investmentsFormatted: utils.formats.formatNumberWithSpaces(investments) + ' ' + currency,
        dividendsFormatted: utils.formats.formatNumberWithSpaces(dividends) + ' ' + currency,
        incomeFormatted: utils.formats.formatNumberWithSpaces(income) + ' ' + currency,
        shareData: data.share,
    };
    if (result.shareData) {
        result.marketPrice = parseFloat(result.shareData.price);
        result.marketPriceFormatted = utils.formats.formatNumberWithSpaces(result.marketPrice) + ' ' + currency;
        result.currentValue = result.quantity * result.marketPrice;
        result.currentValueFormatted = utils.formats.formatNumberWithSpaces(result.currentValue) + ' ' + currency;
    }
    const estate = {
        title: result.title,
        id: -1,
        ...data.estate,
    };
    result.estate = estate;
    return result;
};

export const userSharesList = (data) => {
    const result = {
        items: data.results.map(userSharesItem),
        paging: {
            count: data.count || 0,
            page: data.page || 1,
        },
        ...data,
    };
    return result;
};

export const userInvestmentsItem = (data) => {
    const investments = parseFloat(data.amount) || 0;
    const dividends = data.dividends || 0;
    const income = data.pure_income || 0;
    const currency = 'руб.';
    const result = {
        id: data.id || -1,
        title: (data.estate && data.estate.name) || 'Название объекта',
        image: (data.estate && data.estate.image) || '',
        currency,
        investments,
        dividends,
        income,
        irr: data.irr_income ? (data.irr_income * 100).toFixed(1) : 0,
        mm: data.mm_income || 0,
        share: data.portfolio_share ? (data.portfolio_share * 100).toFixed(1) : 0,
        investmentsFormatted: utils.formats.formatNumberWithSpaces(investments) + ' ' + currency,
        dividendsFormatted: utils.formats.formatNumberWithSpaces(dividends) + ' ' + currency,
        incomeFormatted: utils.formats.formatNumberWithSpaces(income) + ' ' + currency,
        status: data.status || 'new',
        round: data.investment_round || 0,
    };
    const estate = {
        title: result.title,
        id: -1,
        ...data.estate,
    };
    result.estate = estate;
    return result;
};

export const userInvestmentsList = (data) => {
    const result = {
        items: data.results.map(userInvestmentsItem),
        paging: {
            count: data.count || 0,
            page: data.page || 1,
        },
        ...data,
    };
    return result;
};

export const request = (data) => {
    let statusFormatted = 'Идет сбор средств'; // if status === 'new', default
    // if (data.is_status_form_required) {
    // eslint-disable-next-line no-constant-condition
    if (false) {
        statusFormatted = 'STATUS_FORM_REQUIRED';
    } else if (data.status === 'wait_sign') {
        statusFormatted = 'Требует подписи';
    } else if (data.status === 'signed') {
        statusFormatted = 'Подписана';
    }
    const result = {
        id: data.id,
        amount: parseFloat(data.amount),
        current: data.estate.balance,
        image: data.estate.image,
        status: data.status,
        statusFormatted,
        isStatusFormRequired: !!data.is_status_form_required,
        target: data.estate.target,
        title: data.estate.name,
        url: `${config.urls.estates.item}${data.estate.id}/`,
        round: data.investment_round || 0,
        estate: {
            id: data.estate.id,
            url: data.estate.url,
            title: data.estate.name,
            image: data.estate.image,
        },
    };
    return result;
};

export const requestsList = (data) => {
    const result = {
        items: data.results.map(request),
        paging: {
            count: data.count || 0,
            page: data.page || 1,
        },
        ...data,
    };
    return result;
};
