<template>
    <section class="page-cabinet-referral">
        <div class="page-cabinet-referral__content"
            v-if="user"
            >
            <page-cabinet-referral-info
                v-if="user.referrerCode"
            />
            <page-cabinet-referral-create
                v-else
            />
        </div>
        <transition name="fade">
            <div class="page-cabinet-referral__loader"
                v-if="isLoading"
                >
                <div class="page-cabinet-referral__loader-container">
                    <ui-loader />
                </div>
            </div>
        </transition>
    </section>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'page-cabinet-referral',
    computed: {
        ...mapState('referral', [
            'getReferralsIsLoading',
            'createReferrerIsLoading',
        ]),
        user() {
            return this.$store.getters['users/user'];
        },
        isLoading() {
            return !this.user || this.getReferralsIsLoading || this.createReferrerIsLoading;
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-referral {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    min-height: 100%;
    &__content {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        width: 100%;
    }
    &__loader {
        .transition-fade();

        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        background-color: fade(@color-gray-lighter, 50%);
        &-container {
            width: 100px;
            margin: auto;
        }
    }
}
</style>
