<template>
    <div class="ui-auth"
        v-bind:class="{
            _inverted: isInverted,
            _mobile: isMobile,
        }"
        >
        <a class="ui-auth__user"
            v-if="isAuthorized"
            v-bind:href="urls.dashboard"
            >
            <icon name="user" v-bind:original="isInverted ? false : true" />
        </a>
        <div class="ui-auth__login"
            v-else
            >
            <div class="ui-auth__login-item">
                <ui-button
                    v-bind:href="urls.login"
                    v-bind:size="isMobile ? 'xxs' : 'xs'"
                    v-bind:theme="isMobile ? 'bordered' : 'transparent'"
                    target="_self"
                    is-width-free
                    >
                    Вход
                </ui-button>
            </div>
            <div class="ui-auth__login-item">
                <ui-button
                    v-bind:href="urls.register"
                    v-bind:size="isMobile ? 'xxs' : 'xs'"
                    v-bind:theme="isMobile ? 'white' : 'secondary-1'"
                    target="_self"
                    >
                    Регистрация
                </ui-button>
            </div>
        </div>
    </div>
</template>

<script>
import config from '~/config';

export default {
    name: 'ui-auth',
    props: {
        isInverted: {
            type: Boolean,
            default: false,
        },
        isMobile: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        urls: {
            dashboard: config.urls.cabinet.dashboard,
            login: config.urls.auth.login + '?next=' + window.location.pathname,
            register: config.urls.auth.login + '?next=' + window.location.pathname + '&tab=register',
        },
    }),
    computed: {
        user() {
            return this.$store.getters['users/user'];
        },
        isAuthorized() {
            return this.$store.getters['users/isAuthorized'];
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-auth {
    &__user {
        display: block;

        font-size: 3.6rem;
        text-decoration: none;

        cursor: pointer;
        ._inverted > & {
            color: @color-gray-lightest;
        }
    }
    &__login {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: -4px;
        &-item {
            padding: 4px;
        }
    }
    @media @media-md-down {
        &:not(._mobile) &__login {
            margin: -4px -@container-padding-mobile;
            &-item:last-child {
                display: none;
            }
        }
    }
}
</style>
