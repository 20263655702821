<template>
    <section class="layout-info"
        v-bind:class="{ _white: isWhite }"
        >
        <div class="layout-info__container">
            <section class="layout-info__sideblock">
                <ui-back
                    v-bind:href="href"
                    v-bind:text="text"
                />
            </section>
            <section class="layout-info__content">
                <slot />
            </section>
        </div>
    </section>
</template>

<script>
export default {
    name: 'layout-info',
    props: {
        text: {
            type: String,
            default: 'Вернуться назад',
        },
        href: {
            type: String,
            default: null,
        },
        isWhite: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.layout-info {
    flex: 1 1 auto;
    width: 100%;

    background-color: @color-gray-lighter;
    &._white {
        background-color: @color-gray-lightest;
    }
    &__container {
        .container();

        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0 271px 0 40px;
    }
    &__sideblock {
        position: sticky;
        top: 77px;

        flex: 0 0 auto;
        width: 231px;
        padding: 23px 40px 72px 0;
        &-back {
        }
    }
    &__content {
        flex: 1 1 auto;
        padding: 0 0 80px;
    }
    @media @media-xl-down {
        &__container {
            padding-right: 40px;
        }
    }
    @media @media-md-down {
        &__container {
            flex-direction: column;
            padding-left: 24px;
            padding-right: 24px;
        }
        &__sideblock {
            position: static;

            width: auto;
            padding: 18px 0 0;
        }
        &__content {
            width: 100%;
            padding: 0 0 80px;
        }
    }
}
</style>
