<template>
    <div class="page-cabinet-estates-list-table">
        <div class="page-cabinet-estates-list-table__header">
            <div class="page-cabinet-estates-list-table__header-item"
            />
            <div class="page-cabinet-estates-list-table__header-item"
                v-for="(field, i) in fields"
                v-bind:key="i"
                v-text="field.title"
            />
        </div>
        <div class="page-cabinet-estates-list-table__body">
            <div class="page-cabinet-estates-list-table__body-row"
                v-for="(item, i) in items"
                v-bind:key="i"
                >
                <page-cabinet-estates-list-table-item
                    v-bind:item="item"
                    v-bind:fields="fields"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'page-cabinet-estates-list-table',
    props: {
        items: {
            type: Array,
        },
        fields: {
            type: Array,
        },
    },
    methods: {
        getFieldText(index) {
            return this.fields[index].title;
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-estates-list-table {
    &__header {
        .typography-caption();

        display: flex;
        align-items: baseline;
        justify-content: flex-start;
        padding: 32px 24px 28px;

        font-weight: 700;
        text-transform: uppercase;
        text-align: right;
        &-item {
            flex: 1 1 100%;
            &:first-child {
                flex: 0 0 auto;
                width: 175px;
            }
            &:not(:last-child) {
                padding-right: 16px;
            }
        }
    }
    &__body {
        &-row {
            margin-bottom: 8px;
        }
    }
    @media @media-lg-down {
        &__header {
            &-item {
                &:first-child {
                    width: 145px;
                }
            }
        }
    }
}
</style>
