<template>
    <div class="page-cabinet-verification-profile-ip">
        <ui-form
            v-bind:model="fields"
            v-bind:validation="$v"
            v-bind:submit-handler="submit"
            v-on:update="updateFormData"
            gap="wide"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import forms from '~/forms';

export default {
    name: 'page-cabinet-verification-profile-ip',
    props: {
        isTouched: {
            type: Boolean,
        },
    },
    validations() {
        const result = {
            fields: {
                ogrnip: { ...forms.validations.ogrnip },
                ipName: { ...forms.validations.genericRequired },
                ipAddress: { ...forms.validations.genericRequired },
            },
        };
        return result;
    },
    computed: {
        ...mapState('verification/profile', [
            'ip',
        ]),
        fields() {
            const result = {
                ogrnip: { ...forms.fields.ogrnip },
                ipName: { ...forms.fields.user.juridical.ipName },
                ipAddress: { ...forms.fields.user.juridical.ipAddress },
            };
            Object.keys(result).forEach(key => {
                result[key].value = this.ip[key] === null ? '' : this.ip[key];
            });
            return result;
        },
    },
    methods: {
        submit() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            this.$emit('submit');
        },
        updateFormData(newData) {
            const result = {};
            Object.keys(newData).forEach(key => {
                result[key] = newData[key].value === '' ? null : newData[key].value;
            });
            this.$store.state.verification.profile.ip = {
                ...this.$store.state.verification.profile.ip,
                ...result,
            };
        },
    },
    watch: {
        isTouched: {
            handler(newVal) {
                if (newVal) {
                    this.$v.$touch();
                }
            },
            immediate: true,
        },
        '$v.$dirty': {
            handler(newVal) {
                if (newVal) {
                    this.$emit('touch');
                }
            },
            immediate: true,
        },
        '$v.$invalid': {
            handler(newVal) {
                this.$emit('validation-update', { entityName: 'isIpDataValid', value: !newVal });
            },
            immediate: true,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-verification-profile-ip {
}
</style>
