<template>
    <div class="notification-personal-data">
        Продолжая находиться на сайте, вы соглашаетесь с <a v-bind:href="urls.policies.personalData">Политикой обработки персональных данных</a>
    </div>
</template>

<script>
import config from '~/config';

export default {
    name: 'notification-personal-data',
    props: [ 'onClose' ],
    data: () => ({
        urls: config.urls,
    }),
};
</script>

<style scoped lang="less">
@import '~theme';

.notification-personal-data {
    .notification();
}
</style>
