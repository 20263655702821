import client from '../_client';
import config from '~/config';
import transforms from './transforms';

// get items
export const getItems = (params = {}) => {
    return client.request({
        url: config.api.blog.items,
        method: 'GET',
        params,
        transformData: transforms.responses.list,
    });
};

// get item
export const getItem = (params = { id: -1 }) => {
    const id = params.id;
    delete params.id;
    return client.request({
        url: config.api.blog.items + id + '/',
        method: 'GET',
        params,
        transformData: transforms.responses.item,
    });
};
