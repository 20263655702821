<template>
    <div class="page-cabinet-settings-form-password">
        <div class="page-cabinet-settings-form-password__form">
            <ui-form
                v-bind:model="fields"
                v-bind:validation="$v"
                v-bind:submit-handler="submitHandler"
                v-on:update="updateFormData"
            />
        </div>
        <transition name="fade">
            <div class="page-cabinet-settings-form-password__loader"
                v-if="passwordIsLoading"
                >
                <div class="page-cabinet-settings-form-password__loader-container">
                    <ui-loader />
                </div>
            </div>
        </transition>
        <div class="page-cabinet-settings-form-password__message _success"
            v-if="isSuccess"
            >
            <div class="page-cabinet-settings-form-password__message-icon">
                <icon name="check-thin" />
            </div>
            <div class="page-cabinet-settings-form-password__message-text">
                Пароль успешно изменен
            </div>
        </div>
        <div class="page-cabinet-settings-form-password__message _error"
            v-else-if="isError"
            v-text="error"
        />
        <div class="page-cabinet-settings-form-password__buttons">
            <transition name="fade">
                <div class="page-cabinet-settings-form-password__buttons-item"
                    v-if="$v.$dirty"
                    >
                    <ui-trigger v-on:click="resetHandler" is-uppercase>
                        Отменить изменения
                    </ui-trigger>
                </div>
            </transition>
            <div class="page-cabinet-settings-form-password__buttons-item">
                <ui-button
                    v-bind:disabled="($v.$dirty && $v.$invalid) || passwordIsLoading"
                    v-on:click="submitHandler"
                    type="button"
                    >
                    Сохранить
                </ui-button>
            </div>
        </div>
    </div>
</template>

<script>
import forms from '~/forms';
import utils from '~/utils';

export default {
    name: 'page-cabinet-settings-form-password',
    data: () => ({
        fields: {
            oldPassword: {
                ...forms.fields.passwordCurrent,
                label: 'Старый пароль',
                hint: null,
            },
            password1: {
                ...forms.fields.passwordNew,
                label: 'Новый пароль',
            },
            password2: {
                ...forms.fields.passwordRepeat,
            },
        },
        isError: false,
        isSuccess: false,
    }),
    validations: {
        fields: {
            oldPassword: {
                ...forms.validations.genericRequired,
            },
            password1: {
                ...forms.validations.passwordNew,
            },
            password2: {
                ...forms.validations.passwordRepeat,
            },
        },
    },
    computed: {
        passwordIsLoading() {
            return this.$store.getters['users/passwordIsLoading'];
        },
        passwordError() {
            return this.$store.getters['users/passwordError'];
        },
        error() {
            if (!this.isError) {
                return null;
            }
            if (!this.passwordError) {
                return null;
            }
            const error = utils.network.parseError(this.passwordError, true);
            if (error.indexOf('Old password incorrect')) {
                return 'Старый пароль введен неверно';
            }
            return error;
        },
    },
    methods: {
        updateFormData(newData) {
            this.fields = newData;
            this.isError = false;
            this.isSuccess = false;
        },
        submitHandler() {
            this.isSuccess = false;
            this.isError = false;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            const payload = {
                old_password: this.fields.oldPassword.value,
                new_password1: this.fields.password1.value,
                new_password2: this.fields.password2.value,
            };
            this.$store.dispatch('users/updatePassword', payload);
        },
        resetHandler() {
            for (const field in this.fields) {
                this.fields[field].value = '';
            }
            this.$v.$reset();
            this.isError = false;
        },
    },
    watch: {
        passwordError(newVal) {
            if (newVal) {
                this.isError = true;
            }
        },
        passwordIsLoading(newVal, oldVal) {
            if (!newVal && oldVal && this.passwordError === null) {
                this.resetHandler();
                this.isError = false;
                this.isSuccess = true;
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-settings-form-password {
    position: relative;

    font-size: 1.4rem;
    line-height: 2rem;
    &__form {
        margin-bottom: 40px;
    }
    &__loader {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.8);
        &-container {
            width: 50px;
            height: 50px;
        }
    }
    &__message {
        .typography-body();

        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 7px;

        &-icon {
            flex: 0 0 auto;

            font-size: 1.4rem;
        }
        &._success {
            color: @color-positive;
        }
        &._error {
            color: @color-accent-negative;
        }
    }
    &__buttons {
        display: flex;
        align-items: baseline;
        justify-content: flex-end;
        flex-wrap: wrap;
        width: ~'calc(100% + 24px)';
        margin: 0 -12px;
        &-item {
            .transition-fade();

            flex: 0 0 auto;
            margin: 0 12px;
        }
    }
    @media @media-md-down {
        &__buttons {
            flex-direction: column-reverse;
            flex-wrap: nowrap;
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            &-item {
                width: 100%;
                margin: 0;
                & ~ & {
                    margin-bottom: 16px;
                }
            }
        }
    }
}
</style>
