<template>
    <div class="plugin-referral-hero"
        v-bind:class="{ _visible: isVisible }"
        ref="root"
        >
        <h1 class="plugin-referral-hero__title"
            v-if="title"
            v-html="title"
        />
        <div class="plugin-referral-hero__subtitle"
            v-if="subtitle"
            v-html="subtitle"
        />
        <div class="plugin-referral-hero__button">
            <ui-button v-bind:href="buttonLink" text="Пригласить клиента"/>
        </div>
        <div class="plugin-referral-hero__images">
            <img
                class="plugin-referral-hero__images__item --coin-front"
                data-speed="-5"
                v-bind:src="`/static/images/referral/hero/coin_front.png`"
                alt="hero_coin_front"
            />
            <img
                class="plugin-referral-hero__images__item --coin-left"
                data-speed="3"
                v-bind:src="`/static/images/referral/hero/coin_left.png`"
                alt="hero_coin_left"
            />
            <img
                class="plugin-referral-hero__images__item --cash-top"
                data-speed="5"
                v-bind:src="`/static/images/referral/hero/cash_top.png`"
                alt="hero_cash_top"
            />
            <img
                class="plugin-referral-hero__images__item --cash-right"
                data-speed="-4"
                v-bind:src="`/static/images/referral/hero/cash_right.png`"
                alt="hero_cash_right"
            />
            <img
                class="plugin-referral-hero__images__item --cash-top-reverse"
                data-speed="2"
                v-bind:src="`/static/images/referral/hero/cash_top_reverse.png`"
                alt="hero_cash_top_reverse"
            />
            <img
                class="plugin-referral-hero__images__item --coin-right"
                data-speed="-3"
                v-bind:src="`/static/images/referral/hero/coin_right.png`"
                alt="hero_coin_right"
            />
        </div>
    </div>
</template>

<script>
import config from '~/config';

function parallax(e) {
    const items = document.querySelectorAll('.plugin-referral-hero__images__item');

    items.forEach(item => {
        const speed = item.getAttribute('data-speed');
        const x = (window.innerWidth - e.pageX * speed) / 100;
        const y = (window.innerHeight - e.pageY * speed) / 100;

        item.style.transform = `translateX(${x}px) translateY(${y}px)`;
    });
}

export default {
    name: 'plugin-referral-hero',
    props: {
        title: {
            type: String,
            default: null,
        },
        subtitle: {
            type: String,
            default: null,
        },
    },
    data: () => ({
        intersectionObserver: null,
        isVisible: false,
        visibilityThreshold: 0,
    }),
    computed: {
        buttonLink() {
            return this.$store.getters['users/isAuthorized']
                ? config.urls.cabinet.referral
                : config.urls.auth.registration;
        },
    },
    methods: {
        checkVisibility(entries) {
            if (entries[0].intersectionRatio >= this.visibilityThreshold) {
                this.isVisible = true;
                this.removeObserver();
            }
        },
        removeObserver() {
            this.intersectionObserver.disconnect();
            this.intersectionObserver = null;
        },
    },
    mounted() {
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent);

        if (!isMobile) {
            document.addEventListener('mousemove', parallax);
        }

        this.intersectionObserver = new IntersectionObserver(this.checkVisibility, { threshold: this.visibilityThreshold });
        this.intersectionObserver.observe(this.$refs.root);
    },
    beforeDestroy() {
        this.removeObserver();
    },
};
</script>

<style lang="less">
@import '~theme';

.plugin-referral-hero {
    padding: 0;
    margin: auto 0 100px;
    color: @color-gray-lightest;

    position: relative;

    &._visible &__images {
        opacity: 1;

        transform: scale(1);
    }
    &._visible &__title,
    &._visible &__subtitle {
        opacity: 1;

        transform: translateY(0) scale(1);
    }
    &__title,
    &__subtitle {
        opacity: 0;

        transform: translateY(1em) scale(0.85);

        transition: all @duration-normal ease-out;
    }
    &__title {
        .typography-landing-title();

        position: relative;
        z-index: 5;

        padding: 65px 0 30px;
        margin: 0;
        max-width: 1000px;

        white-space: pre-line;
    }

    &__subtitle {
        .typography-small();

        position: relative;
        z-index: 5;

        max-width: 400px;

        p {
            margin: 0;
        }

        b, strong, em {
            .typography-medium();
        }
    }

    &__button {
        margin-top: 30px;
        max-width: 205px;

        position: relative;
        z-index: 5;
    }

    &__images {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;

        display: grid;

        grid-template-rows: .5fr 1fr 1fr 1fr;
        grid-template-columns: repeat(5, 1fr);
        margin: 0 -115px;
        max-width: 1700px;

        opacity: 0;

        transform: scale(0.75);

        transition: all @duration-normal 0.1s ease-out;
        &__item {
            position: relative;
            z-index: auto;

            transition: transform 0.2s;
            will-change: transform;

            &.--coin-front {
                grid-column: 1;
                grid-row: 1 / span 2;

                height: 197px;
                width: 197px;

                right: 70px;
                bottom: 40px;
            }

            &.--coin-left {
                grid-column: 3;
                grid-row: 1;

                height: 125px;
                width: 125px;
            }

            &.--cash-top-reverse {
                grid-column: 4 / span 5;
                grid-row: 2 / span 3;
                align-self: end;

                height: 256px;
                width: 256px;

                top: 110px;
            }

            &.--cash-right {
                grid-column: 4 / span 6;
                grid-row: 2;

                height: 186px;
                width: 186px;

                justify-content: center;

                left: 130px;
            }

            &.--cash-top {
                grid-column: 6;
                grid-row: 1;

                height: 125px;
                width: 125px;
            }

            &.--coin-right {
                grid-column: 5 / span 6;
                grid-row: 2 / span 4;

                height: 256px;
                width: 256px;

                align-self: center;
                justify-self: end;
            }
        }
    }

    @media @media-lg-down {
        &__title {
            .typography-extra-large();

            padding: 15px 0 20px;
        }

        &__button {
            margin-top: 40px;
        }

        &__images {
            grid-template-columns: repeat(4, 1fr);

            margin: 0 -75px;
            max-width: 1000px;

            &__item {
                &.--coin-front {
                    height: 100px;
                    width: 100px;

                    right: 25px;
                    bottom: 25px;
                }

                &.--coin-left {
                    grid-column: 3;
                    grid-row: 1;

                    height: 75px;
                    width: 75px;
                }

                &.--cash-top-reverse {
                    grid-column: 3;
                    grid-row: 2;
                    align-self: end;
                    justify-self: end;

                    height: 165px;
                    width: 165px;

                    top: 60px;
                }

                &.--cash-right {
                    grid-column: 4 / span 6;
                    grid-row: 2;

                    height: 120px;
                    width: 120px;

                    justify-content: center;

                    left: 60px;
                }

                &.--cash-top {
                    height: 80px;
                    width: 80px;
                }

                &.--coin-right {
                    height: 164px;
                    width: 164px;
                }
            }
        }
    }

    @media @media-md-down {
        &__images {
            margin: 0 -35px;

            &__item {
                &.--coin-front {
                    right: 5px;
                    bottom: 15px;
                }
            }
        }
    }

    @media @media-sm-down {
        &__title {
            font-weight: bold;
            font-size: 41px;
            line-height: 46px;

            padding: 15px 0 20px;
        }

        &__images {
            grid-template-columns: 1fr 1fr 1fr;

            margin: 0;

            &__item {
                &.--coin-front {
                    grid-column: 1 / span 2;

                    height: 110px;
                    width: 110px;

                    right: 70px;
                    bottom: 60px;
                }

                &.--coin-left {
                    grid-column: 5 / span 6;
                    grid-row: 1;
                    justify-self: center;

                    height: 75px;
                    width: 75px;

                    bottom: 100px;
                }

                &.--cash-top-reverse {
                    display: none;
                }

                &.--cash-right {
                    display: none;
                }

                &.--cash-top {
                    grid-column: 5;
                    grid-row: 4 / span 5;
                    justify-self: end;

                    height: 131px;
                    width: 131px;

                    left: 10px;
                }

                &.--coin-right {
                    justify-self: end;

                    height: 76px;
                    width: 76px;
                }
            }
        }
    }
}
</style>
