<template>
    <div class="modal-status-form-accounts">
        <div class="modal-status-form-accounts__info">
            <div class="modal-status-form-accounts__info-row"
                v-for="row in info"
                :key="row.title"
                >
                <div class="modal-status-form-accounts__info-row-title"
                    v-text="row.title"
                />
                <div class="modal-status-form-accounts__info-row-value"
                    v-text="row.value"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'modal-status-form-accounts',
    props: {
        formData: {
            type: Object,
        },
    },
    data: () => ({
        info: [{
            title: 'Банк получателя/Отделение (наименование), город',
            value: 'Сбербанк, Москва',
        }, {
            title: 'Расчётный счет',
            value: '8958 7687 6987 6729 3239 87',
        }, {
            title: 'Корреспондентский счет',
            value: '8958 7687 6987 6729 3239 87',
        }],
    }),
    beforeMount() {
        this.$emit('validation-update', true);
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-status-form-accounts {
    &__info {
        &-row {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 16px;
            & + & {
                margin-top: 8px;
            }
            &-title {
                .typography-caption();

                flex: 1 1 276px;

                font-weight: bold;
                text-transform: uppercase;
            }
            &-value {
                .typography-body();

                flex: 1 1 558px;
            }
        }
    }
}
</style>
