<template>
    <section class="plugin-documents"
        v-bind:class="{ _archived: isArchived }"
        >
        <h2 class="plugin-documents__title"
            v-if="displayMode === 'list'"
            v-text="title"
        />
        <div class="plugin-documents__list"
            v-bind:class="{ _list: displayMode === 'list' }"
            >
            <a class="plugin-documents__list-item"
                v-for="(item, index) in items"
                v-bind:key="index"
                v-bind:href="item.url"
                target="_blank"
                >
                <ui-card-base
                    v-if="displayMode === 'cards'"
                    v-bind="{
                        withBorder: true,
                        paddingTop: '11px',
                        paddingRight: '24px',
                        paddingBottom: '11px',
                        paddingLeft: '24px',
                    }"
                    >
                    <plugin-documents-item
                        v-bind:item="item"
                    />
                </ui-card-base>
                <plugin-documents-item
                    v-else
                    v-bind:item="item"
                />
            </a>
        </div>
    </section>
</template>

<script>
export default {
    name: 'plugin-documents',
    props: {
        displayMode: {
            type: String,
            default: 'cards',
        },
        title: {
            type: String,
            default: '',
        },
        items: {
            type: Array,
            default: () => [],
        },
        isArchived: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-documents {
    width: 100%;
    overflow: hidden;
    &._archived {
        opacity: 0.4;
    }
    &__title {
        .typography-large();

        margin: 0;
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 -16px;
        &._list {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            margin: 0 0 80px;
        }
        &-item {
            flex: 0 1 50%;
            display: block;
            padding: 8px 16px;

            text-decoration: none;
            ._list > & {
                flex: 1 1 auto;
                width: auto;
                padding: 0;
                margin-top: 32px;
            }
        }
    }

    @media @media-sm-down {
        &__title {
            .typography-medium();
        }
        &__list {
            &._list {
                margin-bottom: 60px;
            }
            &-item:not(._list) {
                flex: 1 1 100%;
            }
            &-item {
                ._list > & {
                    margin-top: 24px;
                }
            }
        }
    }
}
</style>
