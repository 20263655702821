var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.href ? 'a' : 'div',{tag:"component",staticClass:"ui-label",class:[
        ("_theme_" + _vm.theme),
        ("_weight_" + _vm.weight),
        {
            _pointer: _vm.href || _vm.action,
            _for_form: _vm.isInForm,
            _uppercase: _vm.isUppercase,
            _inline: _vm.isInline,
        }
    ],attrs:{"href":_vm.href ? _vm.href : null},on:{"click":_vm.clickHandler}},[(_vm.iconLeft)?_c('div',{staticClass:"ui-label__icon _left"},[_c('icon',{attrs:{"name":_vm.iconLeft}})],1):_vm._e(),(_vm.$slots.default)?_vm._t("default"):_c('div',{staticClass:"ui-label__text"},[(_vm.title)?_c('span',{staticClass:"ui-label__text-title",class:{
                _bold: _vm.title && _vm.title.isBold,
            },domProps:{"textContent":_vm._s(_vm.titleParsed + (_vm.valueParsed ? ': ' : ''))}}):_vm._e(),(_vm.valueParsed)?_c('span',{staticClass:"ui-label__text-value",class:{
                _bold: _vm.value && (_vm.value.isBold === undefined || _vm.value.isBold),
            },domProps:{"textContent":_vm._s(_vm.valueParsed)}}):_vm._e()]),(_vm.iconRight)?_c('div',{staticClass:"ui-label__icon _right"},[_c('icon',{attrs:{"name":_vm.iconRight}})],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }