<template>
    <div class="modal-generic"
        v-bind:class="`_theme_${theme}`"
        >
        <div class="modal-generic__sign"
            v-if="theme !== 'info'"
            >
            <icon v-bind:name="theme === 'error' ? 'warning' : 'success'" />
        </div>
        <div class="modal-generic__title"
            v-if="title"
            v-text="title"
        />
        <div class="modal-generic__text"
            v-if="text"
            v-text="text"
        />
        <div class="modal-generic__buttons"
            v-if="buttons"
            >
            <div class="modal-generic__buttons-item"
                v-for="(button, index) in buttons"
                v-bind:key="index"
                >
                <ui-button
                    v-on="button.href ? null : { click: (button.callback || close) }"
                    v-bind:theme="button.theme || 'primary'"
                    v-bind:href="button.href || null"
                    >
                    {{ button.text }}
                </ui-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'modal-generic',
    props: {
        theme: {
            type: String,
            default: 'info',
        },
        title: {
            type: String,
            default: null,
        },
        text: {
            type: String,
            default: null,
        },
        buttons: {
            type: Array,
            default: null,
        },
    },
    methods: {
        close() {
            this.$store.commit('modals/pop');
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-generic {
    .modal();
    &__sign {
        width: fit-content;
        margin: 0 auto 16px;

        color: @color-primary;
        font-size: 3.2rem;
        ._theme_error > & {
            color: @color-accent-negative;
        }
    }
    &__title {
        .typography-medium();

        margin-bottom: 24px;

        text-align: center;
    }
    &__text {
        .typography-small();

        margin-bottom: 24px;

        text-align: center;
    }
    &__buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: -12px;
        &-item {
            flex: 0 0 204px;
            display: block;
            padding: 12px;

            text-decoration: none;
        }
    }
    @media @media-md-down {
        width: auto;
        max-width: ~'calc(100vw - 40px)';
        max-height: ~'calc(100vh - 80px)';
        padding: 40px 20px;
    }
}
</style>
