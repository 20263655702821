<template>
    <div class="plugin-referral-footer-headline">
        <a class="plugin-referral-footer-headline__logo"
            href="/"
            >
            <ui-logo-text variant="inverse" />
        </a>
        <div class="plugin-referral-footer-headline__info">
            <div class="plugin-referral-footer-headline__info-item"
                v-for="(item, index) in items"
                v-bind:key="index"
                >
                <div class="plugin-referral-footer-headline__info-item-title"
                    v-text="item.title"
                />
                <div class="plugin-referral-footer-headline__info-item-socials"
                    v-if="item.isSocial"
                    >
                    <a class="plugin-referral-footer-headline__info-item-socials-item"
                        v-for="(item, index) in item.items"
                        v-bind:key="index"
                        v-bind:href="item.url"
                        target="_blank"
                        >
                        <icon v-bind:name="item.icon" />
                    </a>
                </div>
                <a class="plugin-referral-footer-headline__info-item-value"
                    v-else
                    v-bind:href="item.url"
                    v-text="item.value"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'plugin-referral-footer-headline',
    computed: {
        contacts() {
            return this.$store.getters['site/contacts'];
        },
        items() {
            return [
                {
                    title: 'Email',
                    value: this.contacts.email.value,
                    url: this.contacts.email.href,
                },
                {
                    title: 'Phone',
                    value: this.contacts.phone.valueFormatted,
                    url: this.contacts.phone.href,
                },
                {
                    title: 'Social',
                    isSocial: true,
                    items: [
                        // {
                        //     icon: 'facebook',
                        //     url: this.$store.state.site.contacts.socials.facebook.href,
                        // },
                        // {
                        //     icon: 'instagram',
                        //     url: this.$store.state.site.contacts.socials.instagram.href,
                        // },
                        {
                            icon: 'telegram',
                            url: this.$store.state.site.contacts.socials.telegram.href,
                        },
                    ],
                },
            ];
        },
    },
};
</script>

<style lang="less">
@import '~theme';

.plugin-referral-footer-headline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &__logo {
        flex: 0 0 auto;
        display: block;
        width: fit-content;
        padding: 32px 0 24px;

        font-size: 3.679rem;
        text-decoration: none;
    }
    &__info {
        flex: 0 0 auto;
        display: flex;
        align-items: baseline;
        justify-content: flex-end;
        &-item {
            padding-left: 88px;
            &-title {
                .typography-body();

                margin-bottom: 8px;

                color: @color-gray-light;
            }
            &-value {
                .typography-large();

                text-decoration: none;
            }
            &-socials {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin: -20px -16px 0;
                &-item {
                    padding: 16px;

                    font-size: 3.2rem;
                }
            }
        }
    }
    @media @media-lg-down {
        display: block;
        &__info {
            justify-content: flex-start;
            &-item {
                &:first-child {
                    padding-left: 0;
                }
                &-value {
                    .typography-medium();
                }
            }
        }
    }
    @media @media-md-down {
        &__info {
            &-item {
                padding-left: 44px;
            }
        }
    }
    @media @media-sm-down {
        &__info {
            display: block;
            &-item {
                padding-left: 0;
                &:not(:first-child) {
                    margin-top: 24px;
                }
            }
        }

    }
}
</style>
