<template>
    <div class="page-cabinet-estates-list-cards">
        <div class="page-cabinet-estates-list-cards__item"
            v-for="(item, i) in items"
            v-bind:key="i"
            >
            <page-cabinet-estates-list-cards-item
                v-bind:item="item"
                v-bind:fields="fields"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'page-cabinet-estates-list-cards',
    props: {
        items: {
            type: Array,
        },
        fields: {
            type: Array,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-estates-list-cards {
    padding: 24px 0 40px;
    &__item {
        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }
}
</style>
