<template>
    <section class="page-admin-estates-item">
        <transition name="fade">
            <div class="page-admin-estates-item__loader"
                v-if="isLoading"
                >
                <div class="page-admin-estates-item__loader-container">
                    <ui-loader />
                </div>
            </div>
        </transition>
        <section class="page-admin-estates-item__main">
            <section class="page-admin-estates-item__main-left">
                <div class="page-admin-estates-item__main-left-back _desktop">
                    <ui-back />
                </div>
                <div class="page-admin-estates-item__main-left-back _mobile">
                    <ui-back no-text />
                </div>
                <div class="page-admin-estates-item__main-left-modes">
                    <page-admin-estates-item-modes
                        v-bind:modes="modesFiltered"
                        v-model="activeMode"
                    />
                </div>
            </section>
            <section class="page-admin-estates-item__main-center">
                <h1 class="page-admin-estates-item__main-center-title"
                    v-if="getEstateInfoResult"
                    v-text="getEstateInfoResult.title"
                />
                <div class="page-admin-estates-item__main-center-content">
                    <page-admin-estates-item-info
                        v-if="activeMode === 'info' && isBaseDataReady"
                        v-on:set-validity="setInfoValidity"
                    />
                    <page-admin-estates-item-legal
                        v-else-if="activeMode === 'legal' && getEstateInfoResult"
                        v-bind:estate-id="estateId"
                        v-on:set-validity="setLegalValidity"
                    />
                    <page-admin-estates-item-requests
                        v-else-if="activeMode === 'requests'"
                        v-bind:estate-id="estateId"
                    />
                    <page-admin-estates-item-shareholders
                        v-else-if="activeMode === 'shareholders' && getEstateSharesResult"
                    />
                </div>
            </section>
            <section class="page-admin-estates-item__main-right">
            </section>
        </section>
        <section class="page-admin-estates-item__botline"
            v-if="getEstateInfoResult && activeMode !== 'shareholders'"
            >
            <div class="page-admin-estates-item__botline-container"
                v-if="activeMode === 'info' || activeMode === 'legal'"
                >
                <div class="page-admin-estates-item__botline-remove">
                    <page-admin-estates-item-remove
                        v-bind:estate-id="estateId"
                    />
                </div>
                <div class="page-admin-estates-item__botline-content">
                    <page-admin-estates-item-info-footer
                        v-if="activeMode === 'info'"
                    />
                    <page-admin-estates-item-legal-footer
                        v-else-if="activeMode === 'legal'"
                    />
                </div>
            </div>
            <page-admin-estates-item-requests-footer
                v-else-if="activeMode === 'requests'"
                v-bind:estate-id="estateId"
                v-bind:is-any-invalid="!isInfoValid || !isLegalValid"
            />
        </section>
    </section>
</template>

<script>
import { mapState } from 'vuex';
import config from '~/config';

export default {
    name: 'page-admin-estates-item',
    props: {
        estateId: {
            type: Number,
        },
    },
    data: () => ({
        urls: {
            list: config.urls.adminDashboard.estates,
        },
        activeMode: window.location.hash?.replace('#', '') || 'requests',
        isInfoValid: true,
        isLegalValid: true,
    }),
    computed: {
        ...mapState('users', [
            'getUserAdditionalInfoIsLoading',
            'getUserAdditionalInfoResult',
        ]),
        ...mapState('estates', [
            'getEstateTypesIsLoading',
            'getEstateTypesError',
            'getEstateTypesResult',
        ]),
        ...mapState('admin/estates', [
            'getEstateInfoIsLoading',
            'getEstateInfoError',
            'getEstateInfoResult',

            'updateEstateInfoIsLoading',
            'updateEstateInfoResult',
            'updateEstateInfoError',

            'getEstateSharesIsLoading',
            'getEstateSharesResult',
            'getEstateSharesError',

            'getEstateRoundsIsLoading',
            'getEstateRoundsResult',
            'getEstateRoundsError',

            'getEstateRequestsIsLoading',
            'getEstateRequestsResult',
            'getEstateRequestsError',

            'cancelEstateInvestRequestIsLoading',
            'cancelEstateInvestRequestResult',
            'cancelEstateInvestRequestError',
        ]),
        ...mapState('admin/loans', [
            'getLoanIsLoading',
            'getLoanError',
            'getLoanResult',

            'getLoanInvestmentRequestsIsLoading',
        ]),
        modes() {
            const result = [
                { code: 'info', title: 'Информация' },
                { code: 'legal', title: 'Юридические данные' },
            ];
            if (this.getEstateInfoResult) {
                if (this.getEstateInfoResult.status === 'loan_converted') {
                    result.unshift({ code: 'shareholders', title: 'Акции' });
                } else {
                    result.unshift({ code: 'requests', title: 'Заявки' });
                }
            }
            return result;
        },
        isBaseDataReady() {
            return this.getEstateTypesResult !== null && this.getEstateInfoResult !== null;
        },
        modesFiltered() {
            if (!this.getUserAdditionalInfoResult || this.getUserAdditionalInfoResult.isBorrower) {
                return this.modes.filter(x => x.code !== 'requests' && x.code !== 'shareholders');
            }
            return this.modes.map(x => ({
                ...x,
                isInvalid: (x.code === 'info' && !this.isInfoValid) || (x.code === 'legal' && !this.isLegalValid),
            }));
        },
        isLoading() {
            return this.getEstateTypesIsLoading ||
            this.getEstateInfoIsLoading ||
            this.removeEstateIsLoading ||
            this.updateEstateInfoIsLoading ||
            this.cancelEstateInvestRequestIsLoading ||
            this.getEstateSharesIsLoading ||
            this.getUserAdditionalInfoIsLoading ||
            this.getLoanInvestmentRequestsIsLoading ||
            this.getLoanIsLoading ||
            this.getEstateRoundsIsLoading;
        },
    },
    methods: {
        setInfoValidity(newVal) {
            this.isInfoValid = newVal;
        },
        setLegalValidity(newVal) {
            this.isLegalValid = newVal;
        },
    },
    mounted() {
        this.$store.dispatch('admin/estates/getEstateInfo', { id: this.estateId });
        this.$store.dispatch('users/getUserAdditionalInfo');
        this.$store.dispatch('estates/getEstateTypes');
    },
    watch: {
        activeMode: {
            handler(newVal) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                if (newVal) {
                    window.location.hash = newVal;
                }
                if (newVal === 'legal' && this.getEstateInfoResult === null && !this.getEstateInfoIsLoading) {
                    this.$store.dispatch('admin/estates/getEstateInfo', { id: this.estateId });
                } else if (newVal === 'requests') {
                    if (!this.getEstateRoundsResult && !this.getEstateRoundsIsLoading) {
                        this.$store.dispatch('admin/estates/getEstateRounds', { id: this.estateId });
                    }
                    if (!this.getLoanResult && !this.getLoanIsLoading) {
                        this.$store.dispatch('admin/loans/getLoan', { id: this.estateId });
                    }
                } else if (newVal === 'shareholders') {
                    if (!this.getEstateSharesResult && !this.getEstateSharesIsLoading) {
                        this.$store.dispatch('admin/estates/getEstateShares', { id: this.estateId });
                    }
                }
            },
            immediate: true,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-admin-estates-item {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    min-height: calc(100vh - @container-header-height);
    padding-top: 24px;

    background-color: @color-gray-lighter;
    &__loader {
        .transition-fade();

        position: absolute;
        top: 0;
        left: 0;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        background-color: fade(@color-gray-lightest, 50%);
        &-container {
            flex: 0 0 auto;
            width: 100px;
        }
    }
    &__main {
        .container();

        flex: 1 1 auto;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        &-left {
            position: sticky;
            top: calc(@container-header-height + 24px);
            z-index: 1;

            flex: 0 0 auto;
            width: 230px;
            &-back {
                margin-bottom: 40px;
                &._mobile {
                    display: none;
                }
            }
            &-modes {
            }
        }
        &-center {
            position: sticky;
            top: calc(@container-header-height + 24px);
            z-index: 1;

            flex: 1 1 auto;
            &-title {
                .typography-large();

                margin: 0 0 24px;
                max-width: 668px;
            }
        }
        &-right {
            position: sticky;
            top: calc(@container-header-height + 24px);
            z-index: 1;

            flex: 0 0 auto;
            display: flex;
            justify-content: flex-end;
            width: 230px;
        }
    }
    &__botline {
        position: sticky;
        bottom: 0;
        z-index: 1;

        flex: 0 0 auto;

        background-color: @color-gray-lighter;
        &-container {
            .container();
            .container-paddings();

            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-top: 15px;
            padding-bottom: 15px;
            border-top: 1px solid @color-gray-light;
        }
        &-remove {
            flex: 0 0 auto;
            padding-right: 16px;
        }
        &-content {
            flex: 1 1 auto;
        }
    }
    @media @media-lg-down {
        &__main {
            &-right {
                display: none;
            }
        }
    }
    @media @media-md-down {
        padding: 0;
        &__main {
            padding: 0;
            flex-direction: column;
            align-items: stretch;
            justify-content: flex-start;
            &-left {
                .container();

                position: sticky;
                top: @container-header-height;
                z-index: 2;

                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-top: 15px;
                padding-bottom: 14px;
                margin-bottom: 16px;

                background-color: @color-gray-lightest;
                &-back {
                    flex: 0 0 auto;
                    margin-bottom: 0;
                }
                &-modes {
                    flex: 0 1 auto;
                    overflow: auto;
                    margin-right: -24px;
                }
            }
            &-center {
                .container();
                &-title {
                    .typography-heading();

                    margin-bottom: 16px;
                }
                &-content {
                    position: sticky;
                    top: calc(@container-header-height-mobile + 56px + 16px);
                    z-index: 1;

                    margin-bottom: 24px;
                }
            }
        }
    }
    @media @media-sm-down {
        min-height: calc(100vh - @container-header-height-mobile);
        &__main {
            &-left {
                top: @container-header-height-mobile;

                box-shadow: 0 0 3px 0 fade(@color-primary, 10%);
                &-back {
                    margin-right: 24px;
                    &._mobile {
                        display: block;
                    }
                    &._desktop {
                        display: none;
                    }
                }
            }
        }
        &__botline {
            &-container {
                flex-direction: column-reverse;
                padding-top: 32px;
                padding-bottom: 32px;
            }
            &-remove {
                padding-right: 0;
                padding-top: 24px;
                min-height: 84px;
            }
        }
    }
}
</style>
