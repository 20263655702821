<template>
    <div class="modal-preview"
        v-bind:class="{ _no_title: !title }"
        >
        <div class="modal-preview__title"
            v-if="title"
            v-text="title"
        />
        <div class="modal-preview__image">
            <img class="modal-preview__image-content"
                v-bind:src="previewUrl"
            />
        </div>
        <a class="modal-preview__download"
            v-if="fileUrl"
            v-bind:href="fileUrl"
            target="_blank"
            >
            <ui-button
                icon-left="download"
                bordered
                >
                Скачать
            </ui-button>
        </a>
    </div>
</template>

<script>
export default {
    name: 'modal-preview',
    props: {
        previewUrl: {
            type: String,
            default: '',
        },
        fileUrl: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: null,
        },
    },
    methods: {
        close() {
            this.$store.commit('modals/pop');
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-preview {
    .modal();

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    max-height: 100%;
    &._no_title {
        padding-top: 68px;
    }
    &__title {
        .typography-medium();

        flex: 0 0 auto;
        margin-bottom: 16px;
    }
    &__image {
        flex: 1 1 auto;
        min-height: 300px;
        margin-bottom: 16px;
        overflow: auto;
        &-content {
            width: auto;
            max-width: none;
            margin: auto;
        }
    }
    &__download {
        flex: 0 0 auto;
        display: block;
        width: 207px;
        margin: 0 auto;

        text-decoration: none;

        cursor: pointer;
    }
    @media @media-md-down {
        &._no-title {
            padding-top: 60px;
        }
    }
    @media @media-sm-down {
        max-height: calc(100vh - @container-header-height-mobile - @container-padding-mobile);
        .cms-toolbar-expanded & {
            max-height: calc(100vh - @container-header-height-mobile - @container-padding-mobile - @container-cms-height);
        }
    }
}
</style>
