<template>
    <section class="page-result">
        <div class="page-result__title">
            Спасибо!
        </div>
        <template v-if="variant === 'verification-success'">
            <div class="page-result__subtitle">
                Теперь вы можете начать инвестировать
            </div>
            <div class="page-result__buttons">
                <a class="page-result__buttons-item"
                    v-bind:href="urls.dashboard"
                    >
                    <ui-button
                        theme="primary"
                        size="lg"
                        >
                        В личный кабинет
                    </ui-button>
                </a>
                <a class="page-result__buttons-item"
                    v-bind:href="urls.estates"
                    >
                    <ui-button
                        theme="secondary-1"
                        size="lg"
                        >
                        Выбрать объект
                    </ui-button>
                </a>
            </div>
        </template>
        <template v-else-if="variant === 'qualification-success'">
            <div class="page-result__subtitle">
                Ваша заявка на подтверждение статуса квалифицированного инвестора принята!
            </div>
            <div class="page-result__buttons">
                <a class="page-result__buttons-item"
                    v-bind:href="urls.dashboard"
                    >
                    <ui-button
                        theme="primary"
                        size="lg"
                        >
                        Перейти в кабинет
                    </ui-button>
                </a>
                <a class="page-result__buttons-item"
                    v-bind:href="urls.estates"
                    >
                    <ui-button
                        theme="secondary-1"
                        size="lg"
                        >
                        Выбрать объект
                    </ui-button>
                </a>
            </div>
        </template>
        <template v-else-if="variant === 'qualification-failure'">
            <div class="page-result__subtitle">
                К сожалению, на данный момент вы не смогли подтвердить статус квалифицированного инвестора. Вы всегда можете попробовать сделать это снова в разделе «Персональные данные»
            </div>
            <div class="page-result__buttons">
                <a class="page-result__buttons-item"
                    v-bind:href="urls.dashboard"
                    >
                    <ui-button
                        theme="primary"
                        size="lg"
                        >
                        Перейти в кабинет
                    </ui-button>
                </a>
                <a class="page-result__buttons-item"
                    v-bind:href="urls.estates"
                    >
                    <ui-button
                        theme="secondary-1"
                        size="lg"
                        >
                        Выбрать объект
                    </ui-button>
                </a>
            </div>
        </template>
    </section>
</template>

<script>
import config from '~/config';

export default {
    name: 'page-result',
    props: {
        variant: {
            type: String,
            default: 'verification-success',
        },
    },
    data: () => ({
        urls: {
            dashboard: config.urls.cabinet.dashboard,
            refill: config.urls.cabinet.refill,
            estates: config.urls.estates.list,
        },
    }),
    methods: {
        showModal() {
            this.$store.commit('modals/push', 'development');
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-result {
    margin: auto;
    padding: 40px 24px;

    text-align: center;
    &__title {
        .typography-extra-large();

        margin-bottom: 16px;
    }
    &__subtitle {
        .typography-small();

        margin: 0 auto 40px;
        max-width: 450px;
    }
    &__buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: -12px;
        &-item {
            flex: 0 0 auto;
            display: block;
            min-width: 230px;
            padding: 12px;

            text-decoration: none;
        }
    }
    @media @media-md-down {
        &__subtitle {
            margin-bottom: 16px;
        }
        &__buttons {
            flex-direction: column;
            align-items: stretch;
            &-item {
                flex: 1 1 auto;
            }
        }
    }
}
</style>
