<template>
    <div class="page-cabinet-requests-item">
        <template v-if="verifyUserInvestmentRequestResult">
            <page-cabinet-requests-item-success />
        </template>
        <template v-else>
            <div class="page-cabinet-requests-item__header">
                <ui-card-base>
                    <page-cabinet-requests-item-header v-bind:investment="investment" />
                </ui-card-base>
            </div>
            <div class="page-cabinet-requests-item__content">
                <ui-card-base>
                    <page-cabinet-requests-item-content
                        v-bind:investment="investment"
                        v-bind:user="user"
                        v-bind:documents="documents"
                    />
                </ui-card-base>
            </div>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { userInvestmentsItem as investmentTransform } from '~/network/mainClient/investments/transforms/responses';
import { userProfile as userTransform } from '~/network/mainClient/users/transforms/responses';

export default {
    name: 'page-cabinet-requests-item',
    props: {
        rawData: {
            type: Object,
        },
    },
    computed: {
        ...mapState('investments', [ 'verifyUserInvestmentRequestResult' ]),
        investment() {
            return investmentTransform(this.rawData);
        },
        user() {
            return userTransform(this.rawData.user);
        },
        documents() {
            return this.rawData.documents;
        },
    },
    // mounted() {
    //     console.warn(this.rawData);
    //     console.warn(this.investment);
    //     console.warn(this.user);
    //     console.warn(this.documents);
    // },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-requests-item {
    max-width: 898px;
    padding-top: 20px;
    margin: 0 auto auto 0;
    &__header {
        margin-bottom: 24px;
    }
    @media @media-sm-down {
    }
}
</style>
