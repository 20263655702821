<template>
    <div class="plugin-about-media">
        <div class="plugin-about-media__title"
            v-if="title"
            v-text="title"
        />
        <div class="plugin-about-media__container swiper-container"
            ref="container"
            >
            <div class="plugin-about-media__wrapper swiper-wrapper">
                <slot />
            </div>
        </div>
        <div class="plugin-about-media__navigation">
            <div class="plugin-about-media__navigation-item _prev"
                ref="prev"
                >
                <icon name="arrow-left" />
            </div>
            <div class="plugin-about-media__navigation-item _next"
                ref="next"
                >
                <icon name="arrow-right" />
            </div>
        </div>
        <div class="plugin-about-media__pagination"
            ref="pagination"
            >
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper/dist/js/swiper';
import utils from '~/utils';

export default {
    name: 'plugin-about-media',
    props: {
        title: {
            type: String,
            default: '',
        },
    },
    data: () => ({
        slider: null,
    }),
    methods: {
        initSwiper() {
            if (this.slider !== null) {
                this.slider.update();
                return;
            }
            const container = this.$refs.container;
            const paginationEl = this.$refs.pagination;
            const prevEl = this.$refs.prev;
            const nextEl = this.$refs.next;
            this.slider = new Swiper(container, {
                speed: 600,
                slidesPerView: 'auto',
                spaceBetween: 24,
                // loop: true,
                // loopFillGroupWithBlank: true,
                // loopedSlides: 5,
                centerInsufficientSlides: true,
                preloadImages: false,
                watchSlidesVisibility: true,
                lazy: {
                    loadPrevNext: true,
                },
                navigation: {
                    prevEl,
                    nextEl,
                },
                pagination: {
                    el: paginationEl,
                    type: 'bullets',
                    bulletClass: 'plugin-about-media__pagination-item',
                    bulletActiveClass: '_active',
                    clickable: true,
                },
                breakpoints: {
                    1023: {
                        spaceBetween: 8,
                    },
                },
            });
        },
    },
    mounted() {
        this.initSwiper();
        utils.dom.addLoadedCallback(this.initSwiper);
    },
    beforeDestroy() {
        utils.dom.removeLoadedCallback(this.initSwiper);
    },
};
</script>

<style lang="less">
@import '~theme';

.plugin-about-media {
    .container();
    .container-paddings();

    position: relative;
    &__title {
        .typography-extra-large();

        margin-bottom: 40px;

        text-align: center;
    }
    &__container.swiper-container {
        width: calc(@grid-column * 10 + @grid-gap * 9);
        margin-bottom: 36px;
    }
    &__navigation {
        position: absolute;
        bottom: 0;
        left: 0;

        width: 100%;

        pointer-events: none;
        &-item {
            position: absolute;
            bottom: 93px;
            z-index: 1;

            display: flex;
            align-items: center;
            justify-content: center;
            width: 64px;
            height: 64px;
            border-radius: 50%;
            border: 1px solid @color-gray-light;

            font-size: 2.56rem;
            text-align: center;

            pointer-events: all;
            cursor: pointer;
            outline: none;

            transition: opacity @duration-fast @easing-default;
            &.swiper-button-disabled {
                opacity: 0;
                pointer-events: none;
            }
            &._prev {
                left: @container-padding;
            }
            &._next {
                right: @container-padding;
            }
        }
    }
    &__pagination {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        &-item {
            position: relative;

            display: block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            margin: 4px;

            background-color: @color-gray-main;

            cursor: pointer;
            outline: none;

            transition: background-color @duration-fast ease-in-out, opacity @duration-fast @easing-default;
            &:before {
                content: '';

                position: absolute;
                top: -4px;
                right: -4px;
                bottom: -4px;
                left: -4px;

                display: block;
            }
            &:hover {
                background-color: @color-gray-dark;
            }
            &._active {
                background-color: @color-gray-darkest;
            }
            &:first-child&:last-child {
                opacity: 0;
            }
        }
    }
    @media @media-md-down {
        &__title {
            .typography-large();

            padding-right: 112px;
            margin-bottom: 24px;

            text-align: left;
        }
        &__container.swiper-container {
            width: 100%;
        }
        &__navigation {
            bottom: auto;
            top: -3px;
            right: @container-padding-mobile;

            width: auto;
            &-item {
                bottom: auto;
                top: 0;

                width: 40px;
                height: 40px;

                font-size: 1.6rem;
                &._prev {
                    right: 56px;
                    left: auto;
                }
                &._next {
                    right: 0;
                }
                &.swiper-button-disabled {
                    opacity: 0.33;
                }
            }
        }
    }
}
</style>
