<template>
    <div class="plugin-blog-accent">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'plugin-blog-accent',
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-blog-accent {
    display: block;
    width: 100%;
    padding: 36px 40px;
    border-top: 4px solid @color-primary;
    border-bottom: 4px solid @color-primary;

    background-color: @color-gray-lighter;
    & p {
        .typography-large();

        margin: 0;

        font-weight: normal;
    }
    @media @media-md-down {
        padding: 12px 16px;
        & p {
            .typography-small();
        }
    }
}
</style>
