<template>
    <div class="modal-status-form-agreements">
        <div class="modal-status-form-agreements__title"
            v-text="'Согласия'"
        />
        <div class="modal-status-form-agreements__item">
            <ui-checkbox-with-text
                v-model="rulesAccepted"
                >
                Подтверждаю, что ознакомился и согласен с <a v-bind:href="urls.rules" target="_blank">Правилами инвестиционной платормы</a>
            </ui-checkbox-with-text>
        </div>
        <div class="modal-status-form-agreements__item">
            <ui-checkbox-with-text
                v-model="risksAccepted"
                >
                Подтверждаю, что ознакомился и согласен с <a v-bind:href="urls.risks" target="_blank">Декларацией о рисках</a>
            </ui-checkbox-with-text>
        </div>
        <div class="modal-status-form-agreements__item">
            <ui-checkbox-with-text
                v-model="privacyAccepted"
                >
                Согласен на <a v-bind:href="urls.personalData" target="_blank">Обработку персональных данных</a>
            </ui-checkbox-with-text>
        </div>
    </div>
</template>

<script>
import config from '~/config';

export default {
    name: 'modal-status-form-agreements',
    props: {
        formData: {
            type: Object,
        },
    },
    data: () => ({
        urls: { ...config.urls.policies },
        rulesAccepted: false,
        risksAccepted: false,
        privacyAccepted: false,
    }),
    computed: {
        isValid() {
            return this.rulesAccepted && this.risksAccepted && this.privacyAccepted;
        },
    },
    beforeMount() {
        this.rulesAccepted = this.formData.agreements?.rulesAccepted || false;
        this.risksAccepted = this.formData.agreements?.risksAccepted || false;
        this.privacyAccepted = this.formData.agreements?.privacyAccepted || false;
    },
    watch: {
        isValid: {
            handler(value) {
                this.$emit('validation-update', value);
                this.$emit('data-update', {
                    ...this.formData,
                    agreements: {
                        rulesAccepted: this.rulesAccepted,
                        risksAccepted: this.risksAccepted,
                        privacyAccepted: this.privacyAccepted,
                    },
                });
            },
            immediate: true,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-status-form-agreements {
    &__title {
        .typography-heading();
    }
    &__item {
        margin-top: 16px;
    }
}
</style>
