<template>
    <div>
        app-root
    </div>
</template>

<script>
import config from '~/config';
import utils from '~/utils';

export default {
    name: 'app-root',
    props: {
        recaptchaKey: {
            type: String,
        },
    },
    provide: function() {
        return {
            recaptchaKey: this.recaptchaKey,
        };
    },
    data: () => ({
        scrollToSave: 0,
    }),
    computed: {
        isBodyFixed() {
            return this.$store.getters['common/isBodyFixed'];
        },
        user() {
            return this.$store.getters['users/user'];
        },
    },
    methods: {
        checkUrl() {
            const queries = utils.common.parseUrl();
            const loginQuery = queries.find(x => x.title === 'login');
            const redirectQuery = queries.find(x => x.title === 'next');
            if (loginQuery) {
                window.location.href = config.urls.auth.login + (redirectQuery ? '?next=' + redirectQuery.value : '');
                return;
            }
            const modalQuery = queries.find(x => x.title === 'modal');
            if (modalQuery) {
                if (modalQuery.value === 'status-form') {
                    this.$store.commit('modals/push', 'status-form');
                }
            }
        },
        checkPersonalDataWarning() {
            const nextShowDelay = 30 * 24 * 60 * 60 * 1000; // 30 days
            const storedValue = JSON.parse(window.localStorage.getItem(config.localStorage.keyPrefix + config.localStorage.keyAffixies.personalDataWarning));
            if (!storedValue || (Date.now() - storedValue) >= nextShowDelay) {
                this.$store.commit('notifications/push', {
                    name: 'personal-data',
                    props: {
                        onClose: () => {
                            window.localStorage.setItem(config.localStorage.keyPrefix + config.localStorage.keyAffixies.personalDataWarning, Date.now());
                        },
                    },
                });
            }
        },
        checkHolidaysWarning() {
            const nextShowDelay = 24 * 60 * 60 * 1000; // 1 day
            const storedValue = JSON.parse(window.localStorage.getItem(config.localStorage.keyPrefix + config.localStorage.keyAffixies.holidaysWarning));
            if (!storedValue || (Date.now() - storedValue) >= nextShowDelay) {
                this.$store.commit('notifications/push', {
                    name: 'holidays-warning',
                    props: {
                        onClose: () => {
                            window.localStorage.setItem(config.localStorage.keyPrefix + config.localStorage.keyAffixies.holidaysWarning, Date.now());
                        },
                    },
                });
            }
        },
    },
    mounted() {
        this.checkUrl();
        this.checkPersonalDataWarning();
        // this.checkHolidaysWarning();
        // setTimeout(() => {
        //     document.querySelector('#content-placeholder').classList.add('_disabled');
        // }, 150);
        this.$store.dispatch('users/getUser');
        // this.$store.commit('modals/push', {
        //     name: 'market-application-confirm-success',
        //     props: {
        //         isFinal: true,
        //         deal: { type: 'buy' },
        //     },
        // });
        // this.$store.commit('modals/push', 'loan-convert'); // ?
        // this.$store.commit('modals/push', 'issue-invest-offer'); // ?

        // this.$store.commit('modals/push', {
        //     name: 'investment-type',
        //     props: {
        //         availableInvestmentTypes: [
        //             { id: 1, code: 'loan' },
        //             { id: 2, code: 'direct' },
        //         ],
        //     },
        // });
        // this.$store.commit('modals/push', {
        //     name: 'generic',
        //     props: {
        //         title: 'Что-то пошло не так...',
        //         text: `Мы свяжемся с вами в ближайшее время`,
        //         theme: 'error',
        //     },
        // });
    },
    watch: {
        isBodyFixed(newValue) {
            const body = document.body;
            const fixedClass = '_fixed';
            if (newValue) {
                this.scrollToSave = window.pageYOffset;
                body.classList.add(fixedClass);
                body.style.top = `${-this.scrollToSave}px`;
            } else {
                body.classList.remove(fixedClass);
                window.scrollTo(0, this.scrollToSave);
                body.style.top = 0;
                this.scrollToSave = 0;
            }
        },
        user: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    if (newVal !== 'guest') {
                        utils.analytics.checkUserData(newVal);
                        utils.analytics.sendUserId(newVal.id);
                    } else {
                        utils.analytics.sendUserId(0);
                    }
                }
            },
        },
    },
};
</script>

<style lang="less">
@import '../node_modules/normalize.css/normalize.css';
@import '../node_modules/swiper/dist/css/swiper.min.css';
@import '~theme';

*,
*:before,
*:after {
    box-sizing: border-box;
}
[v-cloak] {
    display: none !important;
}
html {
    height: 100%;

    // typography styles
    font-family: 'Noah', 'Arial', sans-serif;
    font-weight: 400;
    font-size: 10px;
    line-height: 1.5;
    color: @color-gray-darker;

    background-color: @color-gray-lightest;
    &.cms-toolbar-expanded {
        padding-top: @container-cms-height !important;
        margin-top: 0 !important;
    }
}
body {
    position: static;

    width: 100%;
    height: 100%;
    margin: 0;
    overflow-y: scroll;
    scroll-behavior: smooth;
    &._fixed {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        .cms-toolbar-expanded & {
            padding-top: @container-cms-height;
        }
    }
}
.app-wrapper {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1fr;
    width: 100%;
    min-height: 100%;
}
a {
    cursor: pointer;
    color: inherit;
}
img {
    display: block;
    max-width: 100%;
}
input, textarea {
    display: block;
    max-width: 100%;
    border: none;
    padding: 0;
    margin: 0;
    background: transparent;
    outline: none;
    box-shadow: none;
    appearance: none;
    &::placeholder {
        color: inherit;
    }
}
button {
    display: block;
    max-width: 100%;
    border: none;
    padding: 0;
    margin: 0;

    color: inherit;

    background: transparent;

    outline: none;
    box-shadow: none;
    appearance: none;
    cursor: pointer;
}
.svg-icon {
    display: block;
    width: 16px;
    height: 16px;

    color: inherit;

    fill: none;
    stroke: currentColor;
}
.svg-fill {
    fill: currentColor;
    stroke: none;
}
</style>
