<template>
    <div class="plugin-referral-how-it-works">
        <div class="plugin-referral-how-it-works__items">
            <slot/>
        </div>
        <div class="plugin-referral-how-it-works__button">
            <ui-button v-bind:href="buttonLink" text="Пригласить клиента" />
        </div>
    </div>
</template>

<script>
import config from '~/config';

export default {
    name: 'plugin-referral-how-it-works',

    computed: {
        buttonLink() {
            return this.$store.getters['users/isAuthorized']
                ? config.urls.cabinet.referral
                : config.urls.auth.registration;
        },
    },
};
</script>

<style lang="less">
@import '~theme';

.plugin-referral-how-it-works {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    &__items {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;

        background-image: url('/static/images/referral/how-it-works/how-it-works-line.svg');
        background-repeat: no-repeat;
        background-position: center 250px;
        background-size: auto calc(100% - 250px);
    }

    &__button {
        margin: 30px auto 0;
        max-width: 205px;
        width: 100%;
    }
    @media @media-xxl-down {
        &__items {
            background-position: center 350px;
            background-size: auto calc(100% - 350px);
        }
    }
    @media @media-xl-down {
        &__items {
            background-position: center 300px;
            background-size: auto calc(100% - 300px);
        }
    }
    @media @media-md-down {
        &__items {
            margin: 0 (-@container-padding-mobile - 35px);
            padding: 0 @container-padding-mobile + 35px;

            background-image: url('/static/images/referral/how-it-works/how-it-works-line-mobile.svg');
            background-position: -1.3vw bottom;
            background-size: 100% 82%;
        }
    }
    @media @media-sm-down {
        &__items {
            margin: 0 -@container-padding-mobile;
            padding: 0 @container-padding-mobile;
        }

    }
    @media (max-width: 400px) {
        &__items {
            background-position: 75% bottom;
            background-size: auto 80%;
        }
    }
    @media (max-width: 340px) {
        &__items {
            background-position: 60% bottom;
            background-size: auto 80%;
        }
    }
}
</style>
