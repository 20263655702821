export const notificationsType = {
    name: 'notificationsType',
    label: 'Тип аккаунта',
    value: 'account',
    type: 'radio-group',
    options: [
        { value: 'account', text: 'Лицевой счет владельца' },
        { value: 'account-ods', text: 'Лицевой счет владельца для учета права ОДС', isDisabled: true },
        { value: 'account-management', text: 'Лицевой счет доверительного управляющего', isDisabled: true },
    ],
    errors: {
        required: 'Выберите один из вариантов',
    },
};

export const notificationsIsActive = {
    name: 'notificationsIsActive',
    label: 'Получать уведомления',
    value: '',
    type: 'radio-group',
    options: [
        { value: 'yes', text: 'Да' },
        { value: 'no', text: 'Нет' },
    ],
    errors: {
        required: 'Выберите один из вариантов',
    },
};

export const notificationsMethod = {
    name: 'notificationsMethod',
    label: 'Способ уведомления',
    value: '',
    type: 'radio-group',
    options: [
        { value: 'cabinet', text: 'Через личный кабинет акционера' },
        { value: 'in-person', text: 'Лично/уполномоченным представителем' },
        { value: 'post', text: 'Заказное письмо' },
    ],
    errors: {
        required: 'Выберите один из вариантов',
    },
};

export const locationArea = {
    name: 'locationArea',
    label: 'Область',
    value: '',
    type: 'text',
    errors: {
        required: 'Введите название области',
    },
};

export const locationCity = {
    name: 'locationCity',
    label: 'Город места регистрации',
    value: '',
    type: 'text',
    errors: {
        required: 'Введите название города',
    },
};

export const locationAddress = {
    name: 'locationAddress',
    label: 'Адрес места регистрации',
    value: '',
    type: 'text',
    errors: {
        required: 'Введите адрес',
    },
};

export const locationPostcode = {
    name: 'locationPostcode',
    label: 'Индекс места регистрации',
    value: '',
    type: 'numeric',
    errors: {
        required: 'Введите индекс',
    },
};

export const restrictionsPurpose = {
    name: 'restrictionsPurpose',
    label: 'Информация о целях установления деловых отношений с организацией и предполагаемый характер',
    value: '',
    type: 'radio-group',
    options: [
        { value: 'register', text: 'Осуществление операций в реестре' },
        { value: 'other', text: 'Другое' },
    ],
    errors: {
        required: 'Выберите один из вариантов',
    },
};

export const restrictionsSource = {
    name: 'restrictionsSource',
    label: 'Источники происхождения ценных бумаг',
    value: '',
    type: 'radio-group',
    options: [
        { value: 'donation', text: 'Дарение ценных бумаг' },
        { value: 'inheritance', text: 'Наследование ценных бумаг' },
        { value: 'trade', text: 'Купля/продажа ценных бумаг' },
    ],
    errors: {
        required: 'Выберите один из вариантов',
    },
};
