<template>
    <div class="common-investment-campaign-progress">
        <div class="common-investment-campaign-progress__round"
            v-for="(campaign, index) in itemsEnriched"
            v-bind:key="index"
            v-bind:class="{ _disabled: !campaign.isCompleted && !campaign.isActive }"
            >
            <div class="common-investment-campaign-progress__round-text"
                v-text="'Раунд ' + (index + 1)"
            />
            <div class="common-investment-campaign-progress__round-bar">
                <ui-progress
                    v-bind:value="campaign.value"
                    v-bind:theme="campaign.theme"
                    v-bind:is-enlarged="campaign.isActive && !campaign.isCompleted && !isVisuallyCompleted && (campaign.balance < campaign.target)"
                />
            </div>
            <div class="common-investment-campaign-progress__round-text"
                v-text="campaign.balanceStateText"
            />
        </div>
    </div>
</template>

<script>
import utils from '~/utils';
export default {
    name: 'common-investment-campaign-progress',
    props: {
        items: {
            type: Array,
        },
        isVisuallyCompleted: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        itemsEnriched() {
            return this.items.map(item => {
                let value, theme;
                if (!item.isActive && !item.isCompleted) {
                    value = 0;
                } else {
                    value = item.isCompleted || this.isVisuallyCompleted ? 1 : item.balance / item.target;
                }

                if (!item.isActive && !item.isCompleted) {
                    theme = 'disabled';
                } else {
                    theme = (item.isCompleted || this.isVisuallyCompleted || item.isActive) ? 'primary' : 'disabled';
                }

                return {
                    ...item,
                    value,
                    theme,
                };
            });
        },
        percent() {
            if (this.target === 0) {
                return 100;
            }
            return Math.min(Math.max(0, Math.floor(this.current / (this.target || 1) * 100)), 100);
        },
        percentStyle() {
            return {
                marginLeft: this.percent + '%',
                transform: `translateX(calc(-${this.percent}%${this.percent >= 99 ? ' + 1px' : ''}))`,
            };
        },
        targetFormatted() {
            return utils.formats.formatNumberWithSpaces(this.target) + this.$store.state.site.currency.symbol;
        },
    },
    methods: {
        formatNumber(rawValue) {
            return utils.formats.formatNumberWithSpaces(rawValue);
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.common-investment-campaign-progress {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 -2px;
    &__round {
        flex: 1 1 100%;
        display: flex;
        align-items: stretch;
        justify-content: center;
        flex-direction: column;
        padding: 0 2px;
        &._disabled {
            color: @color-gray-main;
        }
        &-text {
            .typography-caption();

            text-align: center;
        }
        &-bar {
            padding: 4px 0;
        }
    }
}
</style>
