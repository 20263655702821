<template>
    <div class="page-auth-switcher">
        <h1 class="page-auth-switcher__item"
            v-for="item in items"
            v-bind:key="item.code"
            v-bind:class="{ _active: item.code === content }"
            v-on:click="$emit('switch', item.code)"
            v-text="item.title"
        />
    </div>
</template>

<script>

export default {
    name: 'page-auth-switcher',
    props: {
        content: {
            type: String,
            default: null,
        },
    },
    computed: {
        items() {
            if (this.content === 'reset') {
                return [
                    {
                        code: 'reset',
                        title: 'Восстановление пароля',
                    },
                ];
            }
            return [
                {
                    code: 'login',
                    title: 'Вход',
                },
                {
                    code: 'register',
                    title: 'Регистрация',
                },
            ];
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-auth-switcher {
    display: flex;
    align-items: baseline;
    justify-content: center;
    margin: 0 -8px 16px;
    &__item {
        .typography-large();

        padding: 0 8px 8px;
        margin: 0;

        color: @color-gray-main;

        cursor: pointer;
        &:after {
            content: '';

            display: block;
            width: 100%;
            height: 2px;
            border-radius: 1px;

            background-color: currentColor;

            opacity: 0;

            transform: translateY(-8px);

            transition: opacity @duration-fast @easing-default, transform @duration-fast @easing-default;
        }
        &:hover:after {
            opacity: 0.66;

            transform: translateY(0);
        }
        &._active {
            color: @color-gray-darkest;

            cursor: auto;

            &:after {
                opacity: 1;

                transform: translateY(0);
            }
        }
    }
    @media @media-md-down {
        .container-paddings();
    }
}
</style>
