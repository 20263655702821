<template>
    <div class="page-market-item-orders-cards-item"
        v-bind:class="{ _accented: item.type === 'sale' }"
        >
        <div class="page-market-item-orders-cards-item__field">
            <span class="page-market-item-orders-cards-item__field-title">Дата:</span>
            <span class="page-market-item-orders-cards-item__field-value"
                v-text="item.dateFormatted"
            />
            <span class="page-market-item-orders-cards-item__field-flag"
                v-if="item.isOwner"
            >Ваша заявка</span>
        </div>
        <div class="page-market-item-orders-cards-item__field">
            <span class="page-market-item-orders-cards-item__field-title">Тип заявки:</span>
            <span class="page-market-item-orders-cards-item__field-value"
                v-text="item.typeTitle"
            />
        </div>
        <div class="page-market-item-orders-cards-item__field">
            <span class="page-market-item-orders-cards-item__field-title">Стоимость акции:</span>
            <span class="page-market-item-orders-cards-item__field-value"
                v-text="item.priceFormatted"
            />
        </div>
        <div class="page-market-item-orders-cards-item__field _flex">
            <span class="page-market-item-orders-cards-item__field-title">Кол-во акций:</span>
            <span class="page-market-item-orders-cards-item__field-value"
                v-text="item.amountFormatted"
            />
            <div class="page-market-item-orders-cards-item__field-icon"
                v-if="item.isPackage"
                >
                <icon name="lock" />
            </div>
        </div>
        <div class="page-market-item-orders-cards-item__field">
            <span class="page-market-item-orders-cards-item__field-title">Сумма:</span>
            <span class="page-market-item-orders-cards-item__field-value"
                v-text="item.sumFormatted"
            />
        </div>
        <div class="page-market-item-orders-cards-item__action">
            <span class="page-market-item-orders-cards-item__action-item _cancel"
                v-if="item.isOwner"
                v-on:click="cancel"
                >
                Отменить
            </span>
            <span class="page-market-item-orders-cards-item__action-item"
                v-else-if="!(item.type === 'buy' && isUserShareless)"
                v-on:click="apply"
                >
                Принять предложение
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'page-market-item-orders-cards-item',
    inject: [ 'shareId' ],
    props: {
        item: {
            type: Object,
        },
        isUserShareless: {
            type: Boolean,
        },
    },
    computed: {
        isVerified() {
            return this.$store.getters['users/isVerified'];
        },
    },
    methods: {
        cancel() {
            this.$store.dispatch('market/cancelOrder', { id: this.item.id });
        },
        apply() {
            if (this.isVerified) {
                this.$store.commit('modals/push', {
                    name: 'market-application-create',
                    props: {
                        isLocked: true,
                        orderId: this.item.id,
                        givenShareId: this.shareId,
                        givenType: this.item.type,
                        givenAmountLimit: this.item.amount,
                        givenPrice: this.item.price,
                        isPriceDisabled: true,
                        isAmountDisabled: this.item.isPackage,
                    },
                });
            } else {
                this.$store.commit('modals/push', 'verification-required');
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-market-item-orders-cards-item {
    padding: 16px;
    border-radius: @border-radius-lg;
    border: 1px solid @color-gray-light;

    background-color: @color-gray-lightest;
    &._accented {
        background-color: @color-gray-lighter;
    }
    &__field {
        .typography-body();
        &:not(:last-child) {
            margin-bottom: 4px;
        }
        &-title {
            display: inline-block;
            margin-right: 1ch;

            color: @color-gray-main;
        }
        &-value {
            display: inline-block;
            margin-right: 1ch;
        }
        &-flag {
            .typography-caption();

            display: inline-block;
            padding: 2px 4px 1px;
            border-radius: @border-radius-sm;

            color: @color-primary;

            background-color: fade(@color-primary, 10%);
        }
        &:nth-child(1) &-value {
            color: @color-gray-main;
        }
        &:nth-child(1) &-title {
            text-transform: uppercase;
        }
        &._flex {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
    }
    &__action {
        margin-top: 32px;
        &-item {
            .typography-caption();

            color: @color-primary;
            font-weight: 700;
            text-transform: uppercase;

            cursor: pointer;
            &._cancel {
                color: @color-accent-negative;
            }
        }
    }
}
</style>
