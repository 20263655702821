import $client from './_client';
import admin from './admin';
import blog from './blog';
import cabinet from './cabinet';
import dadata from './dadata';
import documents from './documents';
import estates from './estates';
import feedback from './feedback';
import geo from './geo';
import market from './market';
import news from './news';
import investments from './investments';
import qualification from './qualification';
import questionary from './questionary';
import referral from './referral';
import shares from './shares';
import sms from './sms';
import status from './status';
import subscription from './subscription';
import subscriptions from './subscriptions';
import transfers from './transfers';
import users from './users';

export default {
    $client,
    admin,
    blog,
    cabinet,
    dadata,
    documents,
    estates,
    feedback,
    geo,
    investments,
    market,
    news,
    qualification,
    questionary,
    referral,
    shares,
    sms,
    status,
    subscription,
    subscriptions,
    transfers,
    users,
};
