<template>
    <label class="ui-checkbox-with-text">
        <div class="ui-checkbox-with-text__checkbox">
            <ui-input-base-checkbox
                v-model="checkedInner"
            />
        </div>
        <div class="ui-checkbox-with-text__text"
            v-if="text"
            v-text="text"
        />
        <div class="ui-checkbox-with-text__text"
            v-else
            >
            <slot />
        </div>
    </label>
</template>

<script>
export default {
    name: 'ui-checkbox-with-text',
    props: {
        checked: {
            type: Boolean,
            default: false,
        },
        text: {
            type: String,
            default: '',
        },
    },
    model: {
        prop: 'checked',
        event: 'change',
    },
    methods: {
        updateModel(newValue) {
            this.$emit('change', newValue);
        },
    },
    computed: {
        checkedInner: {
            get() {
                return this.checked;
            },
            set(newValue) {
                this.updateModel(newValue);
            },
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-checkbox-with-text {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    width: fit-content;

    cursor: pointer;
    &__checkbox {
        flex: 0 0 auto;
        margin-top: 2px;
    }
    &__text {
        .typography-body();

        flex: 1 1 auto;
        margin-left: 8px;
        white-space: pre-line;
        /deep/ a:hover {
            text-decoration: none;
        }
    }
}
</style>
