<template>
    <section class="page-estates-item-prefooter">
        <div class="page-estates-item-prefooter__title"
            v-text="estate.title"
        />
        <div class="page-estates-item-prefooter__cards">
            <div class="page-estates-item-prefooter__cards-item">
                <common-card-dictionary
                    v-bind:card-props="{ withShadow: true }"
                    v-bind:items="[
                        {
                            title: 'Общая доходность (факт./прогн.)',
                            value: profitabilityFormatted,
                        },
                        {
                            title: 'Дивидендная доходность (факт./прогн.)',
                            value: dividendProfitabilityFormatted,
                        },
                        {
                            title: 'Минимальная сумма инвестиций',
                            value: formatNumber(estate.minimumInvestment) + ' ' + currency.name.short,
                        },
                    ]"
                />
            </div>
            <div class="page-estates-item-prefooter__cards-item"
                v-if="estate.state !== 'sold'"
                >
                <ui-card-base with-shadow>
                    <div class="page-estates-item-prefooter__progress">
                        <common-investment-campaign-progress
                            v-bind:items="estate.investmentCampaigns"
                            v-bind:is-visually-completed="estate.state === 'completed'"
                        />
                    </div>
                </ui-card-base>
            </div>
        </div>
        <div class="page-estates-item-prefooter__button">
            <ui-button
                v-if="estate.state === 'collecting'"
                v-on:click="$emit('invest')"
                theme="primary"
                size="lg"
                >
                Оставить заявку
            </ui-button>
            <ui-button
                v-else
                v-bind:href="urls.estatesList"
                type="button"
                size="lg"
                >
                К списку объектов
            </ui-button>
        </div>
        <div class="page-estates-item-prefooter__phone">
            <a class="page-estates-item-prefooter__phone-link"
                v-bind:href="'tel:' + phone.value"
                v-text="phone.valueFormatted"
                >
            </a>
            <div class="page-estates-item-prefooter__phone-hint">
                По всем вопросам звоните
            </div>
        </div>
    </section>
</template>

<script>
import config from '~/config';
import utils from '~/utils';

export default {
    name: 'page-estates-item-prefooter',
    props: [ 'estate' ],
    data: () => ({
        urls: {
            estatesList: config.urls.estates.list,
        },
    }),
    computed: {
        phone() {
            return this.$store.getters['site/contacts'].phone;
        },
        user() {
            return this.$store.getters['users/user'];
        },
        currency() {
            return this.$store.state.site.currency;
        },
        profitabilityFormatted() {
            if (this.estate.state === 'completed' || this.estate.state === 'collected') {
                return this.formatNumber(this.estate.currentProfitability) + '% / ' + this.formatNumber(this.estate.targetProfitability) + '%';
            }
            return '– / ' + this.formatNumber(this.estate.targetProfitability) + '%';
        },
        dividendProfitabilityFormatted() {
            if (this.estate.state === 'completed' || this.estate.state === 'collected') {
                return this.formatNumber(this.estate.currentDividendProfitability) + '% / ' + this.formatNumber(this.estate.targetDividendProfitability) + '%';
            }
            return '– / ' + this.formatNumber(this.estate.targetDividendProfitability) + '%';
        },
    },
    methods: {
        formatNumber(amount) {
            return utils.formats.formatNumberWithSpaces(amount);
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-estates-item-prefooter {
    &__title {
        .typography-extra-large();

        margin-bottom: 25px;

        text-align: center;
    }
    &__cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: stretch;
        margin: 0 -@grid-gap-half;
        &-item {
            width: calc(@grid-gap * 3 + @grid-column * 4);
            min-width: 360px;
            margin: 0 @grid-gap-half 40px;
        }
    }
    &__progress {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: 100%;
        min-height: 100%;
        justify-content: center;
    }
    &__button {
        display: block;
        width: 100%;
        max-width: 275px;
        margin: 0 auto 32px;

        text-decoration: none;
    }
    &__phone {
        text-align: center;

        &-link {
            .typography-small();

            margin-bottom: 8px;

            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
        &-hint {
            .typography-body();

            color: @color-gray-main;
        }
    }
    @media @media-sm-down {
        padding: 40px 0;

        &__title {
            .typography-medium();
        }
        &__cards {
            margin: 0;
            &-item {
                width: 100%;
                min-width: 0;
                margin: 0;
                & ~ & {
                    margin-top: 8px;
                }
            }
        }
        &__button {
            width: 100%;
            margin-top: 32px;
        }
    }
}
</style>
