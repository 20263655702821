<template>
    <div class="modal-status-form-step">
        <div class="modal-status-form-step__counter">
            {{ currentStepIndex + 1 }}/{{ steps.length }}
        </div>
        <div class="modal-status-form-step__title"
            v-text="currentStep.header"
        />
        <div class="modal-status-form-step__description"
            v-text="currentStep.description"
        />
        <form class="modal-status-form-step__form"
            v-on:submit.prevent="submitHandler"
            >
            <div class="modal-status-form-step__form-field"
                v-for="(field, fieldIndex) in fields"
                :key="fieldIndex + field.id"
                >
                <modal-status-form-image
                    v-if="field.type === 'ImgField'"
                    v-bind:field="field"
                    v-on:validation-update="validationUpdateHandler"
                />
                <modal-status-form-field
                    v-else
                    v-bind:field="field"
                    v-on:validation-update="validationUpdateHandler"
                />
            </div>
        </form>
        <div class="modal-status-form-step__buttons">
            <div class="modal-status-form-step__buttons-item">
                <ui-button
                    v-on:click="submitHandler"
                    v-bind:disabled="$store.state.status.isTouched && !isValid"
                    >
                    Далее
                </ui-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
    name: 'modal-status-form-step',
    data: () => ({
        validFields: [],
    }),
    computed: {
        ...mapGetters('status', [
            'steps',
            'currentStep',
        ]),
        ...mapState('status', [
            'currentStepIndex',
            'estateId',
            'getFormDataIsLoading',
            'createFormDataIsLoading',
        ]),
        isValid() {
            return this.fields.every((field) => this.validFields.some((validField) => validField.id === field.id && validField.isValid));
        },
        fields() {
            return this.currentStep.fields.filter((field) => (field.visible || field.visible === undefined) && !field.isHidden);
        },
    },
    methods: {
        validationUpdateHandler({ id, isValid }) {
            const index = this.validFields.findIndex((field) => field.id === id);
            if (isValid && index === -1) {
                this.validFields.push({ id, isValid });
            } else if (!isValid && index !== -1) {
                this.validFields.splice(index, 1);
            }
        },
        submitHandler() {
            if (!this.isValid) {
                this.$store.state.status.isTouched = true;
                return;
            }
            const formData = {
                values: {},
            };
            this.fields.forEach((field) => {
                const value = this.$store.state.status.newValues[field.id];
                if (value !== undefined) {
                    formData.values[field.id] = value;
                }
            });
            if (Object.keys(formData.values).length === 0) {
                this.goNextHandler();
                return;
            }
            this.$store.dispatch('status/updateFormData', {
                id: this.estateId,
                formData,
                onSuccess: () => {
                    this.$store.dispatch('status/getFormData', {
                        onSuccess: this.goNextHandler,
                    });
                },
            });
        },
        goNextHandler() {
            this.$store.state.status.newValues = {};
            this.$store.state.status.currentStepIndex = Math.min(this.currentStepIndex + 1, this.steps.length - 1);
        },
    },
    watch: {
        currentStepIndex() {
            this.$store.state.status.isTouched = false;
            this.validFields = [];
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-status-form-step {
    &__counter {
        .typography-caption();

        width: fit-content;
        padding: 4px 12px;
        border-radius: 20px;
        margin-bottom: 32px;

        font-weight: bold;

        background-color: @color-gray-light;
    }
    &__title {
        .typography-body-lg();

        margin-bottom: 8px;

        font-weight: bold;
    }
    &__description {
        .typography-small();

        margin-bottom: 32px;
    }
    &__form {
        margin-bottom: 32px;
        &-field {
            & + & {
                margin-top: 32px;
            }
        }
    }
    &__buttons {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
        &-item {
            flex: 0 0 auto;

            min-width: 206px;
        }
    }
    @media @media-lg-down {
        &__counter {
            margin-left: auto;
            margin-right: auto;
        }
    }
}
</style>
