<template>
    <section class="page-cabinet-verification">
        <nav class="page-cabinet-verification__navigation">
            <div class="page-cabinet-verification__navigation-item"
                v-for="step in steps"
                v-bind:key="step.code"
                v-bind:class="{
                    _active: activeStepCode === step.code,
                    _filled: step.isFilled,
                }"
                v-on:click="setActiveStepCode(step.code)"
                >
                <div class="page-cabinet-verification__navigation-item-title"
                    v-text="step.title"
                />
                <div class="page-cabinet-verification__navigation-item-indicator" />
            </div>
        </nav>
        <div class="page-cabinet-verification__content">
            <ui-card-base>
                <div class="page-cabinet-verification__content-container"
                    v-if="activeStep"
                    >
                    <div class="page-cabinet-verification__content-title"
                        v-text="activeStep.innerTitle"
                    />
                    <div class="page-cabinet-verification__content-component">
                        <component
                            v-bind:is="activeStep.component"
                            v-on:filled="fillStep"
                            v-on:unfilled="unfillStep"
                            v-on:submitted="goToNextStep"
                            v-on:update-step="setActiveStepCode"
                        />
                    </div>
                </div>
            </ui-card-base>
        </div>
    </section>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'page-cabinet-verification',
    data: () => ({
        activeStepCode: 'type',
        baseSteps: [
            {
                code: 'type',
                component: 'page-cabinet-verification-type',
                title: 'Тип аккаунта',
                innerTitle: 'Хочу инвестировать как',
                isFilled: false,
            },
            {
                code: 'profile',
                component: 'page-cabinet-verification-profile',
                title: 'Персональные данные',
                innerTitle: 'Чтобы начать инвестировать введите Ваши данные ниже',
                isFilled: false,
            },
            {
                code: 'finance',
                component: 'page-cabinet-verification-finance',
                title: 'Финансовое положение',
                innerTitle: 'Сведения о финансовом положении',
                isFilled: false,
            },
            {
                code: 'questionary',
                component: 'page-cabinet-verification-questionary',
                title: 'Анкета',
                innerTitle: 'Пожалуйста, ответьте на следующие вопросы',
                isFilled: false,
            },
            {
                code: 'check',
                component: 'page-cabinet-verification-check',
                title: 'Проверка данных',
                innerTitle: 'Проверьте введенные персональные данные',
                isFilled: false,
            },
            {
                code: 'confirmation',
                component: 'page-cabinet-verification-confirmation',
                title: 'Подтверждение',
                innerTitle: 'Для подтверждения персональных данных и выбранных ниже пунктов вам будет отправлен СМС код',
                isFilled: false,
            },
        ],
    }),
    computed: {
        ...mapState('verification/type', [
            'legalType',
        ]),
        user() {
            return this.$store.getters['users/user'];
        },
        steps() {
            let result = this.baseSteps;
            if (this.user === null || this.user.status !== 'new') {
                result = result.filter(x => x.code !== 'type');
            }
            if (this.legalType === 'J') {
                result = result.filter(x => x.code !== 'finance');
            }
            return result;
        },
        activeStep() {
            return this.steps.find(x => x.code === this.activeStepCode) || null;
        },
        activeStepIndex() {
            return this.steps.findIndex(x => x.code === this.activeStepCode);
        },
    },
    methods: {
        setActiveStepCode(code) {
            const targetStep = this.steps.find(x => x.code === code);
            if (!targetStep.isFilled) {
                const previousStep = this.steps[Math.max(this.steps.findIndex(x => x.code === code) - 1, 0)];
                if (targetStep.code !== previousStep.code && !previousStep.isFilled) {
                    return;
                }
            }
            this.activeStepCode = code;
        },
        fillStep(code) {
            const result = [ ...this.baseSteps ];
            const stepIndex = this.baseSteps.findIndex(x => x.code === code);
            if (stepIndex < 0) {
                return;
            }
            result[stepIndex].isFilled = true;
            this.baseSteps = result;
        },
        unfillStep(code) {
            const result = [ ...this.baseSteps ];
            const stepIndex = this.baseSteps.findIndex(x => x.code === code);
            if (stepIndex < 0) {
                return;
            }
            result[stepIndex].isFilled = false;
            this.baseSteps = result;
            if (code !== 'check') {
                this.unfillStep('check');
            }
        },
        goToNextStep() {
            if (this.activeStepCode !== this.steps[this.steps.length - 1].code) {
                this.setActiveStepCode(this.steps[this.activeStepIndex + 1].code);
            }
        },
    },
    watch: {
        user(value) {
            if (value !== null && value.status === 'new') {
                this.setActiveStepCode('type');
            } else {
                this.setActiveStepCode('profile');
            }
        },
        activeStepCode() {
            if ('scrollBehavior' in document.documentElement.style) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            } else {
                window.scrollTo(0, 0);
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-verification {
    width: 100%;
    max-width: 898px;
    margin: 0 auto;
    &__navigation {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        width: 100%;
        margin-bottom: 12px;
        padding: 0 53px;
        &-item {
            flex: 0 1 auto;
            padding: 24px 12px 20px;

            color: @color-gray-main;
            text-align: center;

            cursor: pointer;
            opacity: 0.33;
            pointer-events: none;

            transition: color @duration-fast ease-in-out, opacity @duration-fast ease-in-out;
            &._filled,
            &._active,
            &._filled + & {
                opacity: 1;
                pointer-events: all;
            }
            &:not(._filled) ~ &,
            &:not(._filled) ~ &._filled {
                opacity: 0.33;
                pointer-events: none;
            }
            &._active {
                color: @color-primary;
            }
            &:hover &-indicator:before,
            &._active &-indicator:before {
                transform: translateX(0);
            }
            &-title {
                .typography-caption();

                margin-bottom: 4px;

                font-weight: bold;
                text-transform: uppercase;
            }
            &-indicator {
                position: relative;

                width: 100%;
                height: 3px;
                border-radius: 3px;
                overflow: hidden;

                background-color: @color-gray-light;
                &:before {
                    content: '';

                    display: block;
                    width: 100%;
                    height: 100%;

                    background-color: currentColor;

                    transform: translateX(-100%);

                    transition: transform @duration-fast ease-in-out;
                }
            }
        }
    }
    &__content {
        &-container {
            padding: 8px 8px 24px;
            display: block;
        }
        &-title {
            .typography-medium();

            margin-bottom: 24px;
        }
    }
    @media @media-xl-down {
        margin-left: 0;
    }
    @media @media-md-down {
        max-width: 100%;
        &__navigation {
            width: auto;
            justify-content: flex-start;
            margin-right: -24px;
            margin-left: -24px;
            padding: 0 16px;
            overflow: auto;
            &:after {
                content: '';

                flex: 0 0 auto;
                display: block;
                width: 16px;
                height: 1px;
            }
            &-item {
                flex: 1 0 auto;
                padding-right: 8px;
                padding-left: 8px;
                width: auto;
            }
        }
        &__content {
            &-container {
                padding: 0 0 16px;
            }
        }
    }
}
</style>
