<template>
    <div class="page-estates-list-form">
        <transition name="fade">
            <div class="page-estates-list-form__loader"
                v-if="isLoading"
                >
                <div class="page-estates-list-form__loader-container">
                    <ui-loader light />
                </div>
            </div>
        </transition>
        <template v-if="subscribeResult">
            <template v-if="isAuthorized">
                <div class="page-estates-list-form__emoji"
                    v-text="'🔥'"
                />
                <div class="page-estates-list-form__title"
                    v-text="'Новый объект уже совсем скоро'"
                />
                <div class="page-estates-list-form__subtitle"
                    v-text="'Готово! Мы оповестим вас, как только появится новый объект'"
                />
            </template>
            <template v-else>
                <div class="page-estates-list-form__emoji"
                    v-text="'🔥'"
                />
                <div class="page-estates-list-form__title"
                    v-text="'Вы подписались на рассылку по новому объекту!'"
                />
                <div class="page-estates-list-form__description"
                    v-text="'Зарегистрируйтесь, чтобы начать инвестировать уже сейчас!'"
                />
                <div class="page-estates-list-form__button">
                    <ui-button theme="secondary-1"
                        v-bind:href="registerUrl"
                        >
                        Зарегистрироваться
                    </ui-button>
                </div>
            </template>
        </template>
        <template v-else>
            <div class="page-estates-list-form__emoji"
                v-text="'🔥'"
            />
            <div class="page-estates-list-form__title"
                v-text="'Новый объект уже совсем скоро'"
            />
            <input class="page-estates-list-form__input"
                v-bind:class="{ _invalid: $v.$dirty && $v.name.$error }"
                placeholder="Имя"
                v-model="name"
                type="text"
                v-bind:disabled="isAuthorized"
                v-on:keydown.enter="submit"
            />
            <input class="page-estates-list-form__input"
                v-bind:class="{ _invalid: $v.$dirty && $v.phone.$error }"
                placeholder="Номер телефона"
                v-model="phone"
                type="tel"
                v-bind:disabled="isAuthorized"
                v-on:keydown.enter="submit"
            />
            <div class="page-estates-list-form__error"
                v-if="!isLoading && error"
                >
                <div class="page-estates-list-form__error-icon">
                    <icon name="warning" />
                </div>
                <div class="page-estates-list-form__error-text">
                    {{ error }}
                </div>
            </div>
            <div class="page-estates-list-form__button">
                <ui-button theme="secondary-1"
                    v-on:click="submit"
                    >
                    Жду новый объект
                </ui-button>
            </div>
            <div class="page-estates-list-form__disclaimer">
                Нажимая кнопку, вы подтверждаете свое согласие на <a v-bind:href="disclaimerLink" target="_blank">обработку Персональных данных</a>
            </div>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import config from '~/config';
import forms from '~/forms';

export default {
    name: 'page-estates-list-form',
    data: () => ({
        disclaimerLink: config.urls.policies.personalData,
        name: '',
        phone: '',
        registerUrl: config.urls.auth.login + '?next=' + window.location.pathname + '&tab=register',
    }),
    validations() {
        return {
            phone: { ...forms.validations.phone.value },
            name: forms.validations.genericRequired.value,
        };
    },
    computed: {
        ...mapState('subscriptions', [
            'getSubscriptionsIsLoading',
            'getSubscriptionsError',
            'getSubscriptionsResult',

            'subscribeIsLoading',
            'subscribeError',
            'subscribeResult',
        ]),
        ...mapState('users', [
            'user',
        ]),
        error() {
            if (this.$v.$invalid && this.$v.$dirty) {
                return 'Введите корректные данные';
            }
            return null;
        },
        isAuthorized() {
            return this.$store.getters['users/isAuthorized'];
        },
        isLoading() {
            return this.subscribeIsLoading || this.getSubscriptionsIsLoading;
        },
    },
    methods: {
        submit() {
            if (this.isLoading) {
                return;
            }
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            this.$store.dispatch('subscriptions/subscribe', this.isAuthorized
                ? {}
                : { name: this.name, phone: this.phone }
            );
        },
    },
    watch: {
        user: {
            handler(newVal) {
                if (!newVal) {
                    return;
                }
                this.name = this.user.fullName;
                this.phone = this.user.phone;
            },
            immediate: true,
        },
        name(newVal) {
            if (newVal && newVal.length > 32) {
                this.name = newVal.slice(0, 32);
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-estates-list-form {
    .typography-body();

    position: relative;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    height: 100%;
    min-height: 525px;
    padding: 32px 40px;
    border-radius: @border-radius-lg;
    overflow: hidden;

    color: @color-gray-lightest;

    background-color: @color-gray-main;
    background-image: linear-gradient(180deg, #7830B1 0.4%, #4B41AB 100%);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    &__loader {
        .transition-fade();

        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        background-image: linear-gradient(180deg, #7830B1 0.4%, #4B41AB 100%);
        &-container {
            width: 100px;
        }
    }
    &__emoji {
        flex: 0 0 auto;
        margin-bottom: 24px;

        font-size: 42px;
        line-height: 42px;
    }
    &__title {
        .typography-large();

        flex: 0 0 auto;
        margin-bottom: 24px;

        font-size: 42px;
        line-height: 42px;
    }
    &__subtitle {
        .typography-body-lg();

        flex: 0 0 auto;
        padding-top: 16px;
    }
    &__description {
        .typography-small();

        flex: 0 0 auto;
        margin-bottom: 32px;
    }
    &__input {
        .typography-body();

        flex: 0 0 auto;
        display: block;
        width: 100%;
        height: 48px;
        padding: 13px 16px 13px 24px;
        border-radius: 48px;
        margin-bottom: 24px;

        color: @color-gray-lightest;

        background-color: @color-primary;

        box-shadow: inset 0 0 0 1px @color-gray-light;
        &::placeholder {
            color: @color-gray-light;
        }
        &:disabled {
            opacity: 0.5;
        }
        &._invalid {
            box-shadow: inset 0 0 0 1px @color-accent-negative;
        }
    }
    &__button {
        flex: 0 0 auto;
        width: fit-content;
    }
    &__error {
        .typography-small();

        flex: 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 24px;
        &-icon {
            padding: 6px 0 0 8px;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            margin-right: 12px;

            color: @color-gray-lightest;
            font-size: 1.6rem;

            background-color: @color-accent-negative;
        }
    }
    &__disclaimer {
        .typography-caption();

        padding-top: 24px;
        margin-top: auto;
        margin-bottom: 0;

        color: @color-gray-light-secondary;
    }
    &__success {
        .typography-small();

        flex: 1 1 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        &-icon {
            color: @color-gray-lightest;
            font-size: 4.8rem;
        }
    }
    @media @media-lg-down {
        padding: 24px;
    }
    @media @media-md-down {
        &__subtitle {
            padding-top: 0;
        }
        &__description {
            margin-bottom: 24px;
        }
    }
    @media @media-sm-down {
        min-height: 400px;
    }
}
</style>
