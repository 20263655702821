import admin from './admin';
import * as feedback from './feedback';
import * as market from './market';
import * as status from './status';
import * as other from './other';
import user from './user';

export default {
    admin,
    market,
    status,
    user,
    feedback,
    ...other,
};
