import estates from './estates';
import investments from './investments';
import loans from './loans';

const state = {};

const getters = {};

const mutations = {};

const actions = {};

const modules = {
    estates,
    investments,
    loans,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    modules,
};
