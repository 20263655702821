import { mainClient } from '~/network';

const moduleTitle = 'admin/estates';

const state = {
    renderLoanOfferResult: null,
    renderLoanOfferIsLoading: false,
    renderLoanOfferError: null,

    createLoanResult: null,
    createLoanIsLoading: false,
    createLoanError: null,

    getLoanResult: null,
    getLoanIsLoading: false,
    getLoanError: null,

    getLoanInvestmentRequestsResult: null,
    getLoanInvestmentRequestsIsLoading: false,
    getLoanInvestmentRequestsError: null,

    cancelLoanResult: null,
    cancelLoanIsLoading: false,
    cancelLoanError: null,

    resolveLoanResult: null,
    resolveLoanIsLoading: false,
    resolveLoanError: null,

    calculateSharesResult: null,
    calculateSharesIsLoading: false,
    calculateSharesError: null,

    issueSharesResult: null,
    issueSharesIsLoading: false,
    issueSharesError: null,
};

const getters = {};

const actions = {
    async renderLoanOffer({ state }, payload = {}) {
        if (state.renderLoanOfferIsLoading) {
            return;
        }
        state.renderLoanOfferIsLoading = true;
        const { data, error, status } = await mainClient.estates.requests.renderLoanOffer(payload);
        if (error) {
            console.error(`Error in action ${moduleTitle}/renderLoanOffer (${status})`);
            state.renderLoanOfferError = error;
            state.renderLoanOfferResult = null;
        } else if (data) {
            state.renderLoanOfferError = null;
            state.renderLoanOfferResult = data;
        }
        state.renderLoanOfferIsLoading = false;
    },
    async createLoan({ state }, payload = {}) {
        if (state.createLoanIsLoading) {
            return;
        }
        state.createLoanIsLoading = true;
        const { data, error, status } = await mainClient.estates.requests.createLoan(payload);
        if (error) {
            console.error(`Error in action ${moduleTitle}/createLoan (${status})`);
            state.createLoanError = error;
            state.createLoanResult = null;
        } else if (data) {
            state.createLoanError = null;
            state.createLoanResult = data;
        }
        state.createLoanIsLoading = false;
    },
    async getLoan({ state }, payload = {}) {
        if (state.getLoanIsLoading) {
            return;
        }
        state.getLoanIsLoading = true;
        const { data, error, status } = await mainClient.estates.requests.getLoan(payload);
        if (error) {
            console.error(`Error in action ${moduleTitle}/getLoan (${status})`);
            state.getLoanError = error;
            state.getLoanResult = null;
        } else if (data) {
            state.getLoanError = null;
            state.getLoanResult = data;
        }
        state.getLoanIsLoading = false;
    },
    async cancelLoan({ state }, payload = {}) {
        if (state.cancelLoanIsLoading) {
            return;
        }
        state.cancelLoanIsLoading = true;
        const { data, error, status } = await mainClient.estates.requests.cancelLoan(payload);
        if (error) {
            console.error(`Error in action ${moduleTitle}/cancelLoan (${status})`);
            state.cancelLoanError = error;
            state.cancelLoanResult = null;
        } else if (data) {
            state.cancelLoanError = null;
            state.cancelLoanResult = data;
        }
        state.cancelLoanIsLoading = false;
    },
    async resolveLoan({ state }, payload = {}) {
        if (state.resolveLoanIsLoading) {
            return;
        }
        state.resolveLoanIsLoading = true;
        const { data, error, status } = await mainClient.estates.requests.resolveLoan(payload);
        if (error) {
            console.error(`Error in action ${moduleTitle}/resolveLoan (${status})`);
            state.resolveLoanError = error;
            state.resolveLoanResult = null;
        } else if (data) {
            state.resolveLoanError = null;
            state.resolveLoanResult = data;
        }
        state.resolveLoanIsLoading = false;
    },
    async getLoanInvestmentRequests({ state }, payload = {}) {
        if (state.getLoanInvestmentRequestsIsLoading) {
            return;
        }
        state.getLoanInvestmentRequestsIsLoading = true;
        const { data, error, status } = await mainClient.estates.requests.getLoanInvestmentRequests(payload);
        if (error) {
            console.error(`Error in action ${moduleTitle}/getLoanInvestmentRequests (${status})`);
            state.getLoanInvestmentRequestsError = error;
            state.getLoanInvestmentRequestsResult = null;
        } else if (data) {
            state.getLoanInvestmentRequestsError = null;
            state.getLoanInvestmentRequestsResult = data.items;
        }
        state.getLoanInvestmentRequestsIsLoading = false;
    },
    async calculateShares({ state }, payload = {}) {
        if (state.calculateSharesIsLoading) {
            return;
        }
        state.calculateSharesIsLoading = true;
        const { data, error, status } = await mainClient.shares.requests.calculate(payload);
        if (error) {
            console.error(`Error in action ${moduleTitle}/calculateShares (${status})`);
            state.calculateSharesError = error;
            state.calculateSharesResult = null;
        } else if (data) {
            state.calculateSharesError = null;
            state.calculateSharesResult = data;
        }
        state.calculateSharesIsLoading = false;
    },
    async issueShares({ state }, payload = {}) {
        if (state.issueSharesIsLoading) {
            return;
        }
        state.issueSharesIsLoading = true;
        const { data, error, status } = await mainClient.shares.requests.issue(payload);
        if (error) {
            console.error(`Error in action ${moduleTitle}/issueShares (${status})`);
            state.issueSharesError = error;
            state.issueSharesResult = null;
        } else if (data) {
            state.issueSharesError = null;
            state.issueSharesResult = data;
        }
        state.issueSharesIsLoading = false;
    },
};

const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
