<template>
    <div class="modal-market-application-confirm-info-stats">
        <div class="modal-market-application-confirm-info-stats__item"
            v-for="(stat, index) in stats"
            v-bind:key="index"
            >
            <div class="modal-market-application-confirm-info-stats__item-title"
                v-text="stat.title"
            />
            <div class="modal-market-application-confirm-info-stats__item-value"
                v-text="stat.value"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'modal-market-application-confirm-info-stats',
    props: {
        stats: {
            type: Array,
        },
    },
};
</script>

<style lang="less">
@import '~theme';

.modal-market-application-confirm-info-stats {
    &__item {
        .typography-caption();

        display: flex;
        align-items: baseline;
        justify-content: flex-start;
        width: 100%;
        & ~ & {
            margin-top: 4px;
        }
        &-title {
            flex: 0 0 auto;
            margin-right: 8px;

            color: @color-gray-main;
        }
        &-value {
            flex: 1 1 auto;
        }
    }
    @media @media-md-down {
    }
}
</style>
