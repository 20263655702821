import config from '~/config';
import utils from '~/utils';
import client from '../_client';
// import transforms from './transforms';

export const getCode = (data = {}) => {
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.sms.getCode,
        method: 'POST',
        data,
    });
};

export const checkCode = (data = {}) => {
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.sms.checkCode,
        method: 'POST',
        data,
    });
};

export const getAuthCode = (data = {}) => {
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.sms.getAuthCode,
        method: 'POST',
        data,
    });
};

export const getPasswordResetCode = (data = {}) => {
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.user.reset,
        method: 'POST',
        data,
    });
};
