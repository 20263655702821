export const item = (data) => {
    const result = {
        ...data,
    };
    return result;
};

export const list = (data) => {
    const result = data?.map(x => item(x)) || [];
    return result;
};
