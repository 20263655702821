<template>
    <section class="page-admin-estates-item-requests-footer">
        <div class="page-admin-estates-item-requests-footer__indicator">
            <div class="page-admin-estates-item-requests-footer__indicator-bar"
                v-bind:class="{
                    _accented: isFilled,
                    _positive: isSignsFilled && status === 'wait_sign',
                }"
                v-bind:style="{ transform: `translateX(${progress - 100}%)` }"
            />
        </div>
        <div class="page-admin-estates-item-requests-footer__content">
            <div class="page-admin-estates-item-requests-footer__content-balance">
                <div class="page-admin-estates-item-requests-footer__content-balance-item">
                    <div class="page-admin-estates-item-requests-footer__content-balance-item-title"
                        v-text="(status === 'new' || status === 'collected' ) ? 'Всего собрано:' : 'Всего подписано:'"
                    />
                    <div class="page-admin-estates-item-requests-footer__content-balance-item-value">
                        <span class="page-admin-estates-item-requests-footer__content-balance-item-value-number"
                            v-text="getFormattedNumber((status === 'new' || status === 'collected') ? balance : balanceSigned)"
                        /> из <span class="page-admin-estates-item-requests-footer__content-balance-item-value-number"
                            v-text="getFormattedNumber(target) + ' ₽'"
                        />
                    </div>
                </div>
                <div class="page-admin-estates-item-requests-footer__content-balance-item"
                    v-if="activeCampaign"
                    >
                    <div class="page-admin-estates-item-requests-footer__content-balance-item-title"
                        v-text="`В раунде ${activeCampaign.index + 1} ${activeCampaign.displayMode === 'collecting' ? 'собрано' : 'подписано'}:`"
                    />
                    <div class="page-admin-estates-item-requests-footer__content-balance-item-value">
                        <span class="page-admin-estates-item-requests-footer__content-balance-item-value-number"
                            v-text="getFormattedNumber(activeCampaign.displayMode === 'collecting' ? activeCampaign.balance : activeCampaign.balanceSigned)"
                        /> из <span class="page-admin-estates-item-requests-footer__content-balance-item-value-number"
                            v-text="getFormattedNumber(activeCampaign.displayMode === 'collecting' ? activeCampaign.target : activeCampaign.balance) + ' ₽'"
                        />
                    </div>
                </div>
            </div>
            <div class="page-admin-estates-item-requests-footer__content-text _error"
                v-if="isAnyInvalid"
                v-text="'Проверьте данные в разделах Информация и Юридические данные'"
            />
            <div class="page-admin-estates-item-requests-footer__content-buttons"
                v-if="getEstateInfoResult"
                >
                <div class="page-admin-estates-item-requests-footer__content-buttons-item"
                    v-if="isFilled && status === 'new'"
                    >
                    <ui-button
                        v-on:click="stopCollecting"
                        v-bind:variant="isMobile ? 'large' : 'small'"
                        v-bind:disabled="isAnyInvalid"
                        text="Остановить сбор"
                        theme="primary"
                    />
                </div>
                <div class="page-admin-estates-item-requests-footer__content-buttons-item"
                    v-if="isFilled && status === 'collected'"
                    >
                    <ui-button
                        v-on:click="generateInvestmentOfferDocuments"
                        v-bind:variant="isMobile ? 'large' : 'small'"
                        text="Подготовить инвест предложение"
                        theme="primary"
                    />
                </div>
                <div class="page-admin-estates-item-requests-footer__content-buttons-item"
                    v-if="status === 'documents_in_progress'"
                    >
                    <ui-button
                        v-bind:variant="isMobile ? 'large' : 'small'"
                        disabled
                        text="Документы генерируются..."
                        theme="primary"
                    />
                </div>
                <div class="page-admin-estates-item-requests-footer__content-buttons-item"
                    v-if="status === 'documents_ready'"
                    >
                    <ui-button
                        v-on:click="issueInvestOffer"
                        v-bind:variant="isMobile ? 'large' : 'small'"
                        text="Отправить инвест предложение"
                        theme="primary"
                    />
                </div>
                <div class="page-admin-estates-item-requests-footer__content-buttons-item"
                    v-if="status === 'collected'"
                    >
                    <ui-button
                        v-on:click="startCollecting"
                        v-bind:variant="isMobile ? 'large' : 'small'"
                        v-bind:disabled="isAnyInvalid"
                        text="Возобновить сбор"
                        theme="secondary-1"
                    />
                </div>
                <div class="page-admin-estates-item-requests-footer__content-buttons-item"
                    v-if="status === 'wait_sign'"
                    >
                    <ui-button
                        v-on:click="cancelExpiredInvestmentRequests"
                        v-bind:variant="isMobile ? 'large' : 'small'"
                        v-bind:is-disabled="cancelExpiredInvestmentRequestsIsLoading"
                        text="Отменить неподписанные заявки"
                        theme="secondary-2"
                    />
                </div>
                <div class="page-admin-estates-item-requests-footer__content-buttons-item"
                    v-if="status === 'signed'"
                    >
                    <ui-button
                        v-on:click="transferMoney"
                        v-bind:variant="isMobile ? 'large' : 'small'"
                        v-bind:is-disabled="transferMoneyIsLoading"
                        text="Перевести деньги на счет АО"
                        theme="secondary-3"
                    />
                </div>
                <div class="page-admin-estates-item-requests-footer__content-buttons-item"
                    v-if="status === 'loan_processing'"
                    >
                    <ui-button
                        v-bind:variant="isMobile ? 'large' : 'small'"
                        disabled
                        text="Идет перевод средств..."
                        theme="primary"
                    />
                </div>
                <div class="page-admin-estates-item-requests-footer__content-buttons-item"
                    v-if="status === 'loan_issued'"
                    >
                    <ui-button
                        v-on:click="resolveOffer"
                        v-bind:variant="isMobile ? 'large' : 'small'"
                        v-bind:is-disabled="convertEstateToSharesIsLoading"
                        text="Выпустить акции"
                        theme="secondary-3"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapState } from 'vuex';
import utils from '~/utils';

export default {
    name: 'page-admin-estates-item-requests-footer',
    props: {
        estateId: {
            type: Number,
        },
        isAnyInvalid: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        cancelLoanRequired: false,
    }),
    computed: {
        ...mapState('admin/estates', [
            'getEstateRequestsIsLoading',
            'getEstateRequestsResult',
            'getEstateRequestsError',

            'updateEstateInfoIsInvalid',
            'updateEstateInfoIsLoading',

            'getEstateInfoIsLoading',
            'getEstateInfoError',
            'getEstateInfoResult',

            'getEstateRequestsIsLoading',
            'getEstateRequestsResult',
            'getEstateRequestsError',

            'getEstateRoundsIsLoading',
            'getEstateRoundsError',
            'getEstateRoundsResult',
        ]),
        ...mapState('admin/investments', [
            'cancelExpiredInvestmentRequestsIsLoading',
            'cancelExpiredInvestmentRequestsError',
            'cancelExpiredInvestmentRequestsResult',

            'transferMoneyIsLoading',
            'transferMoneyError',
            'transferMoneyResult',

            'convertEstateToSharesIsLoading',
            'convertEstateToSharesError',
            'convertEstateToSharesResult',
        ]),
        ...mapState('admin/loans', [
            'getLoanIsLoading',
            'getLoanError',
            'getLoanResult',

            'cancelLoanIsLoading',
            'cancelLoanError',
            'cancelLoanResult',
        ]),
        balance() {
            return this.getEstateInfoResult.balance;
        },
        balanceSigned() {
            return this.getEstateInfoResult.balanceSigned;
        },
        target() {
            return this.getEstateInfoResult.target;
        },
        progress() {
            if (this.activeCampaign) {
                if (this.activeCampaign.displayMode === 'collecting') {
                    return Math.min(this.activeCampaign.balance / this.activeCampaign.target * 100, 100);
                } else {
                    return Math.min(this.activeCampaign.balanceSigned / this.activeCampaign.balance * 100, 100);
                }
            }
            return Math.min(this.balance / this.target * 100, 100);
        },
        isFilled() {
            return true || this.progress >= 100;
        },
        isSignsFilled() {
            return this.balanceSigned >= this.balance && this.balanceSigned > 0;
        },
        status() {
            return this.getEstateInfoResult?.status;
        },
        isMobile() {
            return window.innerWidth < 768;
        },
        activeCampaign() {
            if (!this.getEstateRoundsResult) {
                return null;
            }
            return this.getEstateRoundsResult.find(x => x.isActive) || null;
        },
    },
    methods: {
        getFormattedNumber(number) {
            return utils.formats.formatNumberWithSpaces(number);
        },
        stopCollecting() {
            this.$store.dispatch('admin/estates/updateEstateInfo', { status: 'collected', isUpdateInfoNeeded: true });
        },
        startCollecting() {
            this.$store.dispatch('admin/estates/updateEstateInfo', { status: 'new', isUpdateInfoNeeded: true });
        },
        cancelExpiredInvestmentRequests() {
            this.$store.commit('modals/push', {
                name: 'cancel-expired-investment-requests',
                props: {
                    estate: this.getEstateInfoResult,
                    requests: this.activeCampaign.requests,
                },
            });
        },
        generateInvestmentOfferDocuments() {
            this.$store.commit('modals/push', {
                name: 'prepare-invest-offer',
                props: {
                    estate: this.getEstateInfoResult,
                },
            });
        },
        issueInvestOffer() {
            this.$store.commit('modals/push', {
                name: 'issue-invest-offer',
                props: {
                    estate: this.getEstateInfoResult,
                },
            });
        },
        transferMoney() {
            // transfer money to bank for loan issue
            this.$store.dispatch('admin/investments/transferMoney', { id: this.getEstateInfoResult.id });
        },
        resolveOffer() {
            // direct shares issue
            this.$store.dispatch('admin/investments/convertEstateToShares', { id: this.getEstateInfoResult.id });
        },
    },
    watch: {
        updateEstateInfoIsLoading(newVal, oldVal) {
            if (!newVal && oldVal) {
                this.$store.dispatch('admin/estates/getEstateInfo', { id: this.estateId });
                this.$store.dispatch('admin/estates/getEstateRounds', { id: this.estateId });
            }
        },
        getLoanResult(newVal) {
            if (this.cancelLoanRequired && newVal && this.getLoanResult !== null) {
                this.$store.dispatch('admin/loans/cancelLoan', { id: this.getLoanResult.id });
                this.cancelLoanRequired = false;
            }
        },
        cancelLoanResult(newVal) {
            if (newVal) {
                if (this.cancelLoanRequired) {
                    this.cancelLoanRequired = false;
                }
                if (this.getEstateInfoResult) {
                    this.$store.dispatch('admin/estates/getEstateInfo', { id: this.getEstateInfoResult.id });
                    this.$store.dispatch('admin/estates/getEstateRounds', { id: this.estateId });
                }
            }
        },
        cancelExpiredInvestmentRequestsResult(newVal) {
            if (newVal) {
                this.$store.dispatch('admin/estates/getEstateInfo', { id: this.estateId });
                this.$store.dispatch('admin/estates/getEstateRounds', { id: this.estateId });
            }
        },
        transferMoneyResult(newVal) {
            if (newVal) {
                this.$store.dispatch('admin/estates/getEstateInfo', { id: this.estateId });
                this.$store.dispatch('admin/estates/getEstateRounds', { id: this.estateId });
            }
        },
        convertEstateToSharesResult(newVal) {
            if (newVal) {
                this.$store.dispatch('admin/estates/getEstateInfo', { id: this.estateId });
                this.$store.dispatch('admin/estates/getEstateRounds', { id: this.estateId });
                this.$store.commit('modals/push', {
                    name: 'generic',
                    props: {
                        title: 'Акции успешно выпущены!',
                    },
                });
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-admin-estates-item-requests-footer {
    background-color: @color-gray-lighter;
    &__indicator {
        height: 2px;

        background-color: fade(@color-gray-darkest, 10%);
        &-bar {
            height: 100%;

            background-color: @color-gray-darkest;
            &._accented {
                background-color: @color-primary;
            }
            &._positive {
                background-color: @color-positive;
            }
        }
    }
    &__content {
        .container();
        .container-paddings();

        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-top: 15px;
        padding-bottom: 15px;
        &-balance {
            flex: 0 0 auto;
            &-item {
                display: flex;
                align-items: baseline;
                &-title {
                    .typography-heading();

                    margin-right: 5px;
                }
                &-value {
                    .typography-caption();
                    &-accent {
                        .typography-body();
                    }
                }
            }
        }
        &-loan {
            flex: 0 0 auto;
            display: flex;
            align-items: baseline;
            &-title {
                .typography-heading();

                margin-right: 5px;
            }
            &-value {
                .typography-body();
            }
        }
        &-text {
            .typography-body();

            flex: 1 1 auto;
            margin: auto 24px;

            text-align: right;
            &._error {
                color: @color-accent-negative;
            }
        }
        &-buttons {
            flex: 1 1 auto;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-wrap: wrap;
            margin: -12px;
            padding-left: 24px;
            &-item {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding: 12px;
                &-text {
                    .typography-body();

                    flex: 1 1 auto;
                    margin: auto 24px;

                    text-align: right;
                    &._error {
                        color: @color-accent-negative;
                    }
                }
            }
        }
    }
    @media @media-sm-down {
        &__content {
            display: block;
            padding-top: 24px;
            padding-bottom: 24px;

            text-align: center;
            &-balance {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                margin-bottom: 16px;
                &-item {
                    flex-wrap: wrap;
                    justify-content: center;
                }
            }
            &-loan {
                display: block;
                margin-bottom: 16px;
                &-title {
                    margin-right: 0;
                    margin-bottom: 8px;
                }
            }
            &-text {
                margin-bottom: 16px;

                text-align: center;
            }
            &-buttons {
                padding-left: 0;
                margin: -8px;
                flex-direction: column;
                justify-content: center;
                &-item {
                    padding: 8px;
                }
            }
        }
    }
}
</style>
