var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.component,{tag:"component",staticClass:"ui-button",class:[
        ("_theme_" + _vm.theme),
        ("_size_" + _vm.size),
        {
            _free_width: _vm.isWidthFree,
            _loading: _vm.isLoading,
            _disabled: _vm.isDisabled || _vm.disabled,
            _icon_only: !_vm.text && !_vm.$slots.default && (_vm.iconLeft || _vm.iconRight),
        } ],attrs:{"href":_vm.href ? _vm.href : null,"target":_vm.href ? _vm.target || '_blank' : null,"type":_vm.href ? null : _vm.type,"disabled":_vm.isDisabled || _vm.disabled || _vm.isLoading},on:{"click":function($event){(_vm.href || _vm.isDisabled || _vm.isLoading) ? null : _vm.$emit('click')}}},[_c('ui-trigger',_vm._b({attrs:{"type":null,"href":null,"is-loading":false,"is-inherited":"","is-uppercase":""}},'ui-trigger',_vm.$props,false),[(_vm.$slots.default)?_vm._t("default"):(_vm.text)?_c('span',[_vm._v(_vm._s(_vm.text))]):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }