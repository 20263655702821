import client from '../_client';
import config from '~/config';
import transforms from './transforms';

export const getCountries = (params = {}) => {
    return client.request({
        url: config.api.geo.countries,
        method: 'GET',
        params: transforms.requests.getCountries(params),
        transformData: transforms.responses.countriesList,
    });
};
