import client from '../_client';
import config from '~/config';
import transforms from './transforms';

// get items
export const getItems = (params = {}) => {
    return client.request({
        url: config.api.transfers.items,
        method: 'GET',
        params,
        transformData: transforms.responses.list,
    });
};
