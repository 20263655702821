<template>
    <div class="modal-status-form-contacts">
        <div class="modal-status-form-contacts__info">
            <div class="modal-status-form-contacts__info-row"
                v-for="row in info"
                :key="row.title"
                >
                <div class="modal-status-form-contacts__info-row-title"
                    v-text="row.title"
                />
                <div class="modal-status-form-contacts__info-row-value"
                    v-text="row.value"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'modal-status-form-contacts',
    props: {
        formData: {
            type: Object,
        },
    },
    data: () => ({
        info: [{
            title: 'Адрес электронной почты',
            value: 'nvt.isst.nute@gmail.com',
        }, {
            title: 'Номер телефона',
            value: '+7 (903) 880-91-85',
        }],
    }),
    beforeMount() {
        this.$emit('validation-update', true);
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-status-form-contacts {
    &__info {
        &-row {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 16px;
            & + & {
                margin-top: 8px;
            }
            &-title {
                .typography-caption();

                flex: 1 1 276px;

                font-weight: bold;
                text-transform: uppercase;
            }
            &-value {
                .typography-body();

                flex: 1 1 558px;
            }
        }
    }
}
</style>
