<template>
    <label class="ui-input-base-switch"
        v-bind:class="{
            _active: !isLoading && value,
            _loading: isLoading,
            _focused: isFocused,
        }"
        v-on:keydown.space.prevent="toggle"
        tabindex="0"
        >
        <input class="ui-input-base-switch__input"
            type="checkbox"
            v-bind:checked="value"
            v-on:focus="focusHandler"
            v-on:blur="blurHandler"
            v-on:change="updateModel($event.target.checked)"
        />
        <div class="ui-input-base-switch__indicator">
            <div class="ui-input-base-switch__indicator-loader">
                <ui-loader is-mini />
            </div>
        </div>
    </label>
</template>

<script>
export default {
    name: 'ui-input-base-switch',
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    model: {
        prop: 'value',
        event: 'change',
    },
    data: () => ({
        isFocused: false,
    }),
    methods: {
        toggle() {
            this.$emit('change', !this.value);
        },
        updateModel(newValue) {
            this.$emit('change', newValue);
        },
        focusHandler() {
            this.isFocused = true;
        },
        blurHandler() {
            this.isFocused = false;
        },
    },
};
</script>

<style scoped lang="less">
@import "~theme";

.ui-input-base-switch {
    position: relative;
    z-index: 0;

    display: block;
    width: 40px;
    height: 20px;
    box-shadow: inset 0 0 0 1px @color-gray-light-secondary;
    border-radius: 10px;

    cursor: pointer;

    transition: all @duration-fast ease-in-out;
    &._active {
        box-shadow: inset 0 0 0 1px @color-primary;
    }
    &._loading {
        background-color: @color-gray-light-secondary;
    }
    &__input {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;

        opacity: 0;
        visibility: hidden;
        appearance: none;
    }
    &__indicator {
        position: absolute;
        top: -1px;
        left: -1px;
        z-index: 2;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        border-radius: 50%;

        background-color: @color-gray-main;

        transition: all @duration-fast ease-in-out;
        &-loader {
            width: 100%;
            height: 100%;

            opacity: 0;

            transition: all @duration-fast ease-in-out;
        }
    }
    &._active &__indicator {
        background-color: @color-primary;

        transform: translateX(21px);
    }
    &._loading &__indicator {
        background-color: transparent;

        transform: translateX(11px) scale(0.6);
    }
    &._loading &__indicator-loader {
        opacity: 1;
    }
}
</style>
