<template>
    <input class="ui-input-base-character"
        v-bind:class="{
            _invalid: isInvalid,
        }"
        v-bind:value="value"
        v-bind:placeholder="placeholder"
        v-bind:autofocus="isAutofocus"
        v-on:keydown.enter="submit"
        v-on:focus="selectAll"
        v-on:click="selectAll"
        v-on:mouseup.prevent
        v-on:keydown.delete="$emit('delete', index)"
        v-on="handler"
        autocomplete="chrome-off"
        type="text"
        ref="input"
        v-bind:inputmode="isNumeric ? 'numeric' : null"
        v-bind:pattern="isNumeric ? '\\d*' : null"
    />
</template>

<script>
export default {
    name: 'ui-input-base-character',
    props: {
        index: {
            type: Number,
            default: 0,
        },
        value: {
            type: [ String, Number ],
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        regexp: {
            type: RegExp,
            default: null,
        },
        isAutofocus: {
            type: Boolean,
            default: false,
        },
        isInvalid: {
            type: Boolean,
            default: false,
        },
        isActive: {
            type: Boolean,
            dafault: false,
        },
        isNumeric: {
            type: Boolean,
            dafault: true,
        },
    },
    computed: {
        handler() {
            return {
                input: this.inputHandler,
                focus: this.selectAll,
            };
        },
    },
    methods: {
        selectAll() {
            if (this.$refs.input) {
                if (this.$refs.input.value) {
                    this.$refs.input.select();
                } else {
                    this.$refs.input.focus();
                }
            }
        },
        deselect() {
            window.getSelection().removeAllRanges();
        },
        inputHandler(e) {
            const clearedValue = e.target.value.replace(this.regexp || /[^0-9]/g, '');
            e.target.value = clearedValue.substring(0, 1);
            this.$emit('update-value', { index: this.index, value: clearedValue });
        },
        submit() {
            this.$emit('submit');
        },
    },
    // mounted() {
    //     if (this.isAutofocus) {
    //         this.$refs.input.focus();
    //     }
    // },
    watch: {
        isActive(newVal) {
            if (newVal) {
                this.selectAll();
            }
        },
        value(newVal) {
            if (this.$refs.input) {
                this.$refs.input.value = newVal;
            }
            this.selectAll();
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-input-base-character {
    .typography-body-lg();

    width: 48px;
    height: 60px;
    border-radius: @border-radius-md;

    text-align: center;

    background-color: @color-gray-lighter;

    box-shadow: inset 0 0 0 1px @color-gray-light;

    transition: box-shadow @duration-fast ease-in-out;
    &::placeholder {
        color: @color-gray-main;

        transition: opacity @duration-fast ease-in-out;
    }
    &:focus::placeholder {
        opacity: 0;
    }
    &:focus {
        box-shadow: inset 0 0 0 1px @color-gray-darker;
    }
    &._positive {
        box-shadow: inset 0 0 0 1px @color-positive;

        color: @color-positive;
    }
    &._invalid {
        box-shadow: inset 0 0 0 1px @color-accent-negative;
    }
    &._transparent {
        background: transparent;
    }
    @media @media-xs-down {
        width: 40px;
        height: 50px;
        ._shrink & {
            width: 32px;
            height: 40px;
        }
    }
    @media @media-xxs-down {
        width: 32px;
        height: 40px;
        ._shrink & {
            width: 28px;
            height: 36px;
        }
    }
}
</style>
