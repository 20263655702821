<template>
    <div class="modal-prepare-invest-offer-form-files">
        <div class="modal-prepare-invest-offer-form-files__item"
            v-for="(document, index) in documents"
            v-bind:key="index"
            >
            <modal-prepare-invest-offer-form-files-item
                v-bind:document="document"
                v-bind:validations="validations"
                v-bind:estate="estate"
                v-on:update-document="updateDocument"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'modal-prepare-invest-offer-form-files',
    props: {
        documents: {
            type: Array,
        },
        estate: {
            type: Object,
        },
        validations: {
            type: Object,
        },
    },
    data: function() {
        return {
            isExpanded: false,
        };
    },
    methods: {
        updateDocument(data) {
            this.$emit('update-document', data);
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-prepare-invest-offer-form-files {
    &__item {
        & + & {
            margin-top: 24px;
        }
    }
}
</style>
