<template>
    <div class="plugin-faq-question"
        v-bind:class="{ _expanded: isExpanded }"
        >
        <div class="plugin-faq-question__headline"
            v-on:click="toggle"
            >
            <div class="plugin-faq-question__headline-arrow">
                <icon name="triangle" />
            </div>
            <div class="plugin-faq-question__headline-title"
                v-text="question.title"
            />
        </div>
        <div class="plugin-faq-question__text">
            <common-ckeditor
                v-html="question.text"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'plugin-faq-question',
    props: {
        question: {
            type: Object,
        },
    },
    data: () => ({
        isExpanded: false,
    }),
    methods: {
        toggle() {
            this.isExpanded = !this.isExpanded;
        },
    },
};
</script>

<style lang="less">
@import '~theme';

.plugin-faq-question {
    border-bottom: 2px solid @color-gray-light;
    &._expanded {
        border-color: @color-primary;
    }
    &__headline {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: fit-content;
        padding-bottom: 16px;

        cursor: pointer;
        &-arrow {
            flex: 0 0 auto;
            margin-right: 24px;

            color: @color-gray-main;
            font-size: 2.4rem;

            transform: translateY(2px) rotate(90deg);
        }
        ._expanded > & > &-arrow {
            color: @color-gray-darkest;

            transform: translateY(2px) rotate(180deg);
        }
        &-title {
            .typography-medium();

            flex: 0 1 auto;
        }
        ._expanded > & > &-title {
            color: @color-primary;
        }
    }
    &__text {
        display: none;

        margin-bottom: 16px;
        ._expanded > & {
            display: block;
        }
        .common-ckeditor p {
            .typography-small();

            margin: 0;
        }
    }
    @media @media-md-down {
        &__headline {
            padding-bottom: 12px;
            &-arrow {
                margin-right: 16px;

                transform: translateY(0) rotate(90deg);
            }
            ._expanded > & > &-arrow {
                transform: translateY(0) rotate(180deg);
            }
            &-title {
                .typography-heading();
            }
        }
        &__text {
            margin-bottom: 12px;
            .common-ckeditor p {
                .typography-body();
            }
        }
    }
}
</style>
