<template>
    <a class="page-blog-list-item"
        v-bind:style="backgroundStyle"
        v-bind:href="href"
        >
        <div class="page-blog-list-item__overlay" />
        <div class="page-blog-list-item__content">
            <div class="page-blog-list-item__content-title"
                v-text="item.title"
            />
            <div class="page-blog-list-item__content-info">
                <span class="page-blog-list-item__content-info-text"
                    v-text="formattedDate"
                />
                <span class="page-blog-list-item__content-info-separator"
                    v-text="'•'"
                />
                <span class="page-blog-list-item__content-info-text"
                    v-text="'Время чтения: ' + item.readingDuration"
                />
            </div>
        </div>
    </a>
</template>

<script>
import config from '~/config';
import utils from '~/utils';

export default {
    name: 'page-blog-list-item',
    props: {
        item: {
            type: Object,
        },
    },
    computed: {
        backgroundStyle() {
            if (!this.item.image) {
                return null;
            }
            const resize = utils.common.getResize({
                1920: 25,
                1599: 33,
                1023: 50,
                767: 100,
            });
            return { backgroundImage: `url(${utils.common.injectResize(this.item.image, resize)})` };
        },
        formattedDate() {
            return utils.formats.formatDate(this.item.date);
        },
        href() {
            return `${config.urls.blog.item}${this.item.id}/`;
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-blog-list-item {
    .mixin-background-image();

    position: relative;

    display: block;
    width: 100%;
    padding-top: 100%;
    border-radius: @border-radius-lg;
    overflow: hidden;

    text-decoration: none;

    box-shadow: @shadow-primary;

    transition: box-shadow @duration-fast @easing-default;
    &:hover {
        box-shadow: @shadow-primary-hover;
    }
    &__overlay {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background-image: linear-gradient(0deg, rgba(39, 36, 45, 0.9) 0%, rgba(39, 36, 45, 0.1) 100%);
    }
    &__content {
        position: absolute;
        top: 0;
        left: 0;

        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-end;
        width: 100%;
        height: 100%;
        padding: 24px;

        color: @color-gray-lightest;
        &-title {
            .typography-medium();

            margin-bottom: 16px;
        }
        &-info {
            .typography-body();

            color: @color-gray-light;
            &-separator {
                display: inline-block;
                margin: 0 8px;
            }
        }
    }
}
</style>
