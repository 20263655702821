<template>
    <div class="page-admin-estates-item-info-activity">
        <div class="page-admin-estates-item-info-activity__title">
            Объект сейчас активен. Уберите галочку, если хотите временно убрать его из списка активных объектов
        </div>
        <div class="page-admin-estates-item-info-activity__form">
            <ui-form
                v-bind:model="isActive"
                v-bind:submit-handler="submitHandler"
                v-on:update="updateFormData"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'page-admin-estates-item-info-activity',
    props: [ 'fields', 'validation' ],
    computed: {
        isActive() {
            return { isActive: this.fields.isActive };
        },
    },
    methods: {
        submitHandler() {
            this.$emit('submit');
        },
        updateFormData(newData) {
            this.$emit('update', newData);
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-admin-estates-item-info-activity {
    &__title {
        .typography-body();

        margin-bottom: 16px;
    }
    &__form {
        width: fit-content;
    }
}
</style>
