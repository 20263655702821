<template>
    <label class="common-photo-loader"
        v-on:drop.stop.prevent="onDrop"
        v-on:dragover.prevent
        v-bind:class="{
            _loaded: isLoaded,
            _loading: isLoading,
            _error: error !== null,
        }"
        v-bind:for="inputId"
        >
        <template v-if="!isLoaded && !isLoading && error === null">
            <div class="common-photo-loader__icon">
                <icon name="plus" />
            </div>
            <div class="common-photo-loader__title"
                v-if="text"
                v-text="text"
            />
        </template>
        <template v-if="!isLoaded && isLoading && error === null">
            <div class="common-photo-loader__loader">
                <div class="common-photo-loader__loader-container">
                    <ui-loader />
                </div>
            </div>
        </template>
        <template v-if="!isLoaded && !isLoading && error !== null">
            <div class="common-photo-loader__icon">
                <icon name="warning" />
            </div>
            <div class="common-photo-loader__title"
                v-text="error"
            />
        </template>
        <template v-if="isLoaded && !isLoading && error === null">
            <div class="common-photo-loader__icon">
                <icon name="success" />
            </div>
            <div class="common-photo-loader__title"
                v-text="'Загружено!'"
            />
        </template>
        <input class="common-photo-loader__input"
            v-bind:id="inputId"
            type="file"
            accept=".jpg, .jpeg, .png, .pdf"
            v-on:change="onFileChange"
            v-bind:disabled="isLoading"
        />
    </label>
</template>

<script>
export default {
    name: 'common-photo-loader',
    props: {
        text: {
            type: String,
            default: null,
        },
        file: {
            type: [ File, String ],
            default: null,
        },
        items: {
            type: Array,
            default: () => [],
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        givenError: {
            type: String,
            default: null,
        },
    },
    model: {
        prop: 'file',
        event: 'change',
    },
    data: () => ({
        validFileTypes: [ 'image/jpeg', 'image/pjpeg', 'image/png', 'application/pdf' ],
        internalError: null,
        imageTempSrc: null,
        limit: 8 * 1024 * 1024, // 8 Mb
    }),
    computed: {
        error() {
            return this.internalError || this.givenError || null;
        },
        isLoaded() {
            return !this.error && !this.isLoading && this.file !== null;
        },
        inputId() {
            return 'upload-photo-' + this._uid;
        },
    },
    methods: {
        isValidFileType(file) {
            return this.validFileTypes.indexOf(file.type) > -1;
        },
        onDrop(e) {
            const files = e.dataTransfer.files;
            if (files.length > 0) {
                this.setImage(files[0]);
            } else {
                this.$emit('change', null);
            }
        },
        onFileChange(e) {
            if (e.target.files.length > 0) {
                this.setImage(e.target.files[0]);
            } else {
                this.$emit('change', null);
            }
        },
        async setImage(file) {
            this.internalError = null;
            this.imageTempSrc = null;
            if (!this.isValidFileType(file)) {
                this.internalError = 'Тип файла не jpeg/png/pdf';
                return;
            }
            if (file.size > this.limit) {
                this.internalError = 'Файл превышает 8Mb';
                return;
            }
            this.$emit('change', file);
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.common-photo-loader {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 17px 24px;
    width: 100%;
    height: 100%;
    border-radius: @border-radius-lg;
    border: 1px dashed currentColor;
    overflow: hidden;

    color: @color-gray-main;

    background-color: @color-gray-lighter;

    cursor: pointer;
    &._error,
    &._loaded {
        border-style: solid;
    }
    &._error &,
    &._loaded & {
        &__icon {
            font-size: 3.2rem;
        }
    }
    &._error {
        color: @color-accent-negative;

        background-color: fade(@color-accent-negative, 10%);
    }
    &._loading {
        pointer-events: none;
    }
    &._loaded {
        color: @color-primary;

        background-color: fade(@color-primary, 10%);
    }
    &__icon {
        padding: 7px 0;

        position: relative;
        z-index: 1;

        font-size: 2rem;
    }
    &__title {
        .typography-heading();

        position: relative;
        z-index: 1;

        padding: 7px 0;
        max-width: 235px;

        text-align: center;
    }
    &__loader {
        position: absolute;
        top: 0;
        left: 0;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        background-color: @color-primary;

        opacity: 0.1;
        &-container {
            width: 50px;
        }
    }
    &__input {
        position: absolute;
        z-index: -1;

        opacity: 0;
    }
    @media @media-md-down {
    }
}
</style>
