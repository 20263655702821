<template>
    <section class="page-article">
        <h1 class="page-article__title">
            {{ item.title }}
        </h1>
        <div class="page-article__info">
            <span class="page-article__info-text"
                v-text="formattedDate"
            />
            <span class="page-article__info-separator"
                v-if="item.readingDuration"
                v-text="'•'"
            />
            <span class="page-article__info-text"
                v-if="item.readingDuration"
                v-text="'Время чтения: ' + item.readingDuration"
            />
        </div>
        <div class="page-article__content">
            <slot />
        </div>
    </section>
</template>

<script>
import network from '~/network/mainClient';
import utils from '~/utils';

export default {
    name: 'page-article',
    props: {
        type: {
            type: String,
            default: 'blog',
        },
        rawData: {
            type: Object,
        },
    },
    computed: {
        item() {
            return network[this.type].transforms.responses.item(this.rawData);
        },
        formattedDate() {
            return utils.formats.formatDate(this.item.date);
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-article {
    max-width: 900px;
    margin: 24px auto 80px;
    &__title {
        .typography-extra-large();

        margin: 0 0 8px;
    }
    &__info {
        .typography-body();

        margin-bottom: 24px;

        color: @color-gray-main;
        &-separator {
            display: inline-block;
            margin: 0 8px;
        }
    }
    &__cover {
        margin-bottom: 40px;
    }
    @media @media-xl-down {
        margin-left: 0;
    }
    @media @media-md-down {
        &__title {
            .typography-large();
        }
        &__info {
            margin-bottom: 16px;
        }
        &__cover {
            margin-bottom: 24px;
        }
    }
}
</style>
