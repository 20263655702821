<template>
    <div class="page-admin-estates-item-requests-round">
        <div class="page-admin-estates-item-requests-round__item"
            v-if="isDownloadable"
            >
            <div class="page-admin-estates-item-requests-round__actions">
                <div class="page-admin-estates-item-requests-round__actions-item">
                    <ui-button
                        v-on:click="download"
                        v-bind:icon-left="'download'"
                        >
                        Скачать все документы раунда
                    </ui-button>
                </div>
            </div>
        </div>
        <transition name="fade">
            <div class="page-admin-estates-item-requests-round__loader"
                v-if="isLoading"
                >
                <div class="page-admin-estates-item-requests-round__loader-container">
                    <ui-loader />
                </div>
            </div>
        </transition>
        <div class="page-admin-estates-item-requests-round__empty"
            v-if="!isLoading && requests.length === 0"
            v-text="'Заявок не найдено'"
        />
        <div class="page-admin-estates-item-requests-round__item"
            v-for="(item, index) in requests"
            v-bind:key="index"
            >
            <page-admin-estates-item-requests-item
                v-bind:item="item"
                v-bind:estate-id="estateId"
                v-on:request-reload="refreshData"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'page-admin-estates-item-requests-round',
    props: {
        round: {
            type: Object,
        },
        estateId: {
            type: Number,
        },
    },
    computed: {
        ...mapState('admin/estates', [
            'getEstateRoundsIsLoading',
            'getEstateRoundsResult',
        ]),
        isLoading() {
            return !this.getEstateRoundsResult || this.getEstateRoundsIsLoading;
        },
        investmentAgreementCount() {
            return this.round.investmentAgreementCount || 0;
        },
        investmentProposalCount() {
            return this.round.investmentProposalCount || 0;
        },
        transferOrderCount() {
            return this.round.transferOrderCount || 0;
        },
        investmentAcceptCount() {
            return this.round.investmentAcceptCount || 0;
        },
        isDownloadable() {
            return this.investmentAgreementCount > 0 ||
                this.investmentProposalCount > 0 ||
                this.transferOrderCount > 0 ||
                this.investmentAcceptCount > 0;
        },
        requests() {
            return this.round.requests.map((x, _, a) => {
                return {
                    ...x,
                    isNamesake: !!a.find(y =>
                        y.applicant.fullName === x.applicant.fullName &&
                        y.applicant.id !== x.applicant.id
                    ),
                };
            });
        },
    },
    methods: {
        download() {
            if (!this.isDownloadable) {
                return;
            }
            this.$store.commit('modals/push', {
                name: 'download',
                props: {
                    mode: 'investment-round',
                    dataToDownload: {
                        round: this.round.index + 1,
                        estate: this.estateId,
                        investmentAgreementCount: this.investmentAgreementCount,
                        investmentProposalCount: this.investmentProposalCount,
                        transferOrderCount: this.transferOrderCount,
                        investmentAcceptCount: this.investmentAcceptCount,
                    },
                },
            });
        },
        refreshData() {
            this.$store.dispatch('admin/estates/getEstateRounds', { id: this.estateId });
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-admin-estates-item-requests-round {
    position: relative;

    min-height: 66px;
    padding-top: 8px;
    &__loader {
        .transition-fade();

        position: absolute;
        top: 8px;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;

        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: @border-radius-lg;

        background-color: fade(@color-gray-lightest, 50%);
        &-container {
            width: 50px;
        }
    }
    &__actions {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 8px;
        margin-bottom: 24px;
        &-item {
            flex: 0 0 auto;
        }
    }
    &__empty {
        .typography-body();

        color: @color-gray-main;
    }
    &__item {
        & + & {
            margin-top: 8px;
        }
    }
}
</style>
