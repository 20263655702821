<template>
    <section class="page-auth-login">
        <div class="page-auth-login__success"
            v-if="step == 'success'"
            >
            <div class="page-auth-login__success-title"
                v-text="'Авторизация прошла успешно'"
            />
            <div class="page-auth-login__success-text"
                v-text="'Вы будете автоматически перенаправлены на следующую страницу'"
            />
            <div class="page-auth-login__button">
                <ui-button
                    v-bind:href="urls.cabinet.dashboard"
                    target="_self"
                    text="В кабинет"
                />
            </div>
        </div>
        <template v-else>
            <div class="page-auth-login__header">
                <div class="page-auth-login__header-back"
                    v-if="step === 'verification'"
                    v-on:click="back"
                    >
                    <icon name="chevron-left" />
                </div>
                <page-auth-switcher
                    v-bind:content="content"
                    v-on:switch="$emit('switch', $event)"
                />
            </div>
            <div class="page-auth-login__container">
                <div class="page-auth-login__text">
                    Введите номер телефона или электронную почту, которые вы указывали при регистрации
                </div>
                <div class="page-auth-login__form">
                    <ui-form
                        v-bind:model="fields"
                        v-bind:validation="$v"
                        v-bind:submit-handler="initialLogin"
                        v-on:update="updateFormData"
                    />
                </div>
                <template v-if="isCodeRequested">
                    <div class="page-auth-login__text _center">
                        Введите код из СМС
                    </div>
                    <div class="page-auth-login__verification">
                        <div class="page-auth-login__verification-code">
                            <ui-input-character-set
                                v-bind:size="codeLength"
                                v-model="code"
                                v-on:submit="login"
                                is-autofocus
                            />
                        </div>
                        <div class="page-auth-login__verification-help"
                            v-if="codeRequestTimer === 0"
                            >
                            Не пришел код? <span class="page-auth-login__verification-help-trigger"
                                v-on:click="getAuthCode"
                                >Отправить повторно</span>
                        </div>
                        <div class="page-auth-login__verification-help"
                            v-else
                            >
                            <span v-text="'Повторно отправить код можно через '" /><span
                                class="page-auth-login__verification-help-timer"
                                v-text="formattedTimerValue"
                            />
                        </div>
                    </div>
                    <div class="page-auth-login__text _error"
                        v-if="formError"
                        v-text="formError"
                    />
                </template>
                <template v-else>
                    <div class="page-auth-login__text _error"
                        v-if="formError"
                        v-text="formError"
                    />
                    <div class="page-auth-login__text _center"
                        v-if="codeRequestTimer > 0"
                        v-text="'Повторно запросить код можно через ' + formattedTimerValue"
                    />
                </template>
                <div class="page-auth-login__recaptcha">
                    <vue-recaptcha
                        v-bind:sitekey="recaptchaKey"
                        v-on:verify="recaptchaVerifyHandler"
                        v-on:expired="recaptchaExpiredHandler"
                        load-recaptcha-script
                        ref="recaptcha"
                    />
                    <div class="page-auth-login__recaptcha-error"
                        v-if="recaptchaError"
                        v-text="recaptchaError"
                    />
                </div>
                <div class="page-auth-login__button">
                    <ui-button
                        v-if="isCodeRequested"
                        v-on:click="login"
                        v-bind:disabled="isLoading || code.length !== codeLength"
                        text="Войти"
                    />
                    <ui-button
                        v-else
                        v-on:click="initialLogin"
                        v-bind:disabled="($v.$invalid && $v.$dirty) || codeRequestTimer > 0"
                        text="Получить код"
                    />
                </div>
            </div>
        </template>
    </section>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import { mapState } from 'vuex';
import moment from 'moment';
import config from '~/config';
import forms from '~/forms';
import utils from '~/utils';

const errorTextWrongAuth = 'Неверный номер телефона / email или пароль';
const errorTextCodeNeeded = 'Необъходим код подтверждения';
const errorTextWrongCode = 'Неверный СМС-код';

export default {
    name: 'page-auth-login',
    components: { VueRecaptcha },
    inject: [ 'image', 'recaptchaKey' ],
    props: {
        content: {
            type: String,
            default: 'login',
        },
    },
    data: () => ({
        step: 'form',
        recaptchaValue: null,
        urls: config.urls,
        codeLength: 6,
        code: '',
        isCodeRequested: false,
        isFormErrorVisible: false,
        fields: {
            username: { ...forms.fields.username },
            password: { ...forms.fields.passwordCurrent, maxLength: 128 },
        },
    }),
    validations() {
        const result = {
            fields: {
                username: { ...forms.validations.username },
                password: { ...forms.validations.genericRequired },
            },
            recaptchaValue: forms.validations.genericRequired.value,
        };
        return result;
    },
    computed: {
        ...mapState('sms', [
            'getAuthCodeIsLoading',
            'getAuthCodeError',
            'getAuthCodeResult',
            'codeRequestTimer',
        ]),
        ...mapState('users', [
            'loginResult',
            'loginIsLoading',
            'loginError',
        ]),
        headerBackground() {
            return window.innerWidth <= 1023
                ? { backgroundImage: `url(${this.image})` }
                : null;
        },
        backTheme() {
            return window.innerWidth <= 1023 ? 'dark' : null;
        },
        recaptchaError() {
            if (this.$v.$invalid && this.$v.$dirty && !this.recaptchaValue) {
                return 'Подтвердите, что вы не робот';
            }
            return null;
        },
        formError() {
            if (this.isFormErrorVisible && this.loginError && typeof this.loginError === 'string') {
                if (this.loginError.indexOf('Пользователь с двухфакторной аутентификацией должен ввести код подтверждения') > -1) {
                    return errorTextCodeNeeded;
                }
                if (this.loginError.indexOf('Не удалось выполнить вход. Вы ввели неверный username или пароль') > -1) {
                    return errorTextWrongAuth;
                }
                if (this.loginError.indexOf('Неверный код подтверждения') > -1) {
                    return errorTextWrongCode;
                }
                return 'Произошла ошибка';
            }
            return null;
        },
        isLoading() {
            return this.getAuthCodeIsLoading || this.loginIsLoading;
        },
        formattedTimerValue() {
            return moment(this.codeRequestTimer).format('mm:ss');
        },
    },
    methods: {
        updateFormData(newData) {
            if (newData.username.value !== this.fields.username.value && this.recaptchaValue && this.isCodeRequested) {
                this.isFormErrorVisible = false;
                this.recaptchaValue = null;
                this.resetRecaptcha();
                this.isCodeRequested = false;
            }
            this.fields = newData;
        },
        recaptchaVerifyHandler(response) {
            this.recaptchaValue = response;
        },
        recaptchaExpiredHandler() {
            this.recaptchaValue = null;
        },
        back() {
            this.step = 'form';
        },
        resetRecaptcha() {
            if (this.$refs.recaptcha) {
                this.$refs.recaptcha.reset();
            }
        },
        getAuthCode() {
            if (this.isLoading) {
                return;
            }
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            this.$store.dispatch('sms/getAuthCode', { value: this.fields.username.value, recaptcha: this.recaptchaValue });
        },
        initialLogin() {
            if (this.isLoading) {
                return;
            }
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            const params = {
                username: this.fields.username.value,
                password: this.fields.password.value,
            };
            this.$store.dispatch('users/login', params);
        },
        login() {
            if (this.code.length !== this.codeLength || this.isLoading) {
                return;
            }
            const params = {
                username: this.fields.username.value,
                password: this.fields.password.value,
                code: this.code,
            };
            this.$store.dispatch('users/login', params);
        },
    },
    watch: {
        isLoading(newVal) {
            this.$parent.$emit('update-loading', newVal);
        },
        getAuthCodeIsLoading(newVal, oldVal) {
            this.isFormErrorVisible = false;
            if (newVal) {
                this.$v.$reset();
            } else if (!newVal && oldVal && this.getAuthCodeResult) {
                this.isCodeRequested = true;
            }
        },
        loginIsLoading(newVal, oldVal) {
            this.isFormErrorVisible = false;
            if (!newVal && oldVal && this.loginResult) {
                utils.analytics.gtag.login();
                utils.analytics.ym.login();
                this.$parent.$emit('update-loading', true);
                const queries = utils.common.parseUrl();
                const redirectQuery = queries.find(x => x.title === 'next');
                if (redirectQuery) {
                    window.location.href = redirectQuery.value;
                } else {
                    window.location.href = config.urls.cabinet.dashboard;
                }
            } if (!newVal && oldVal && this.loginError) {
                this.isFormErrorVisible = true;
            }
        },
        formError(newVal) {
            if (newVal === errorTextWrongAuth) {
                this.isCodeRequested = false;
            } else if (newVal === errorTextCodeNeeded) {
                this.getAuthCode();
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-auth-login {
    flex: 1 1 auto;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-direction: column;
    &__header {
        position: relative;

        flex: 0 0 auto;
        padding-bottom: 8px;
        &-back {
            position: absolute;
            top: 6px;
            left: 0;

            color: @color-gray-main;
            font-size: 2rem;

            cursor: pointer;
        }
    }
    &__container {
        flex: 1 1 auto;
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        flex-direction: column;
    }
    &__text {
        .typography-body();

        margin-bottom: 16px;
        &._center {
            text-align: center;
        }
        &._error {
            color: @color-accent-negative;
            text-align: center;
            & > a {
                color: @color-primary;
                text-decoration: underline;
            }
        }
    }
    &__success {
        text-align: center;
        &-title {
            .typography-large();

            margin-bottom: 16px;
            @media @media-md-down {
                margin-bottom: 32px;
            }
        }
        &-text {
            .typography-small();

            margin-bottom: 40px;
        }
    }
    &__form {
        flex: 0 0 auto;
        margin-bottom: 24px;
    }
    &__recaptcha {
        flex: 0 0 auto;
        margin: 0 auto 40px;
        &-error {
            .typography-body();

            margin-top: 16px;

            color: @color-accent-negative;
            text-align: center;
        }
    }
    &__button {
        flex: 0 0 auto;
        width: 194px;
        margin: 0 auto 32px;
    }
    &__verification {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 40px;

        text-align: center;
        &-password {
            margin-bottom: 16px;
        }
        &-help {
            .typography-body();

            margin-top: 8px;

            text-align: center;
            &-trigger {
                cursor: pointer;

                color: @color-primary;
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }
            &-timer {
                display: inline-block;
                min-width: 40px;

                color: @color-primary;
                text-align: left;
            }
        }
    }
    &__login {
        .typography-body();

        flex: 0 0 auto;
        margin: auto 0 0;

        text-align: center;
        & a {
            color: @color-primary;
        }
    }

    @media @media-md-down {
        &__container {
            .container-paddings();

            padding-bottom: 32px;
        }
    }
    @media @media-xs-down {
        &__button {
            width: 100%;
        }
    }
}
</style>
