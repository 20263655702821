<template>
    <div class="page-cabinet-verification-profile-individual">
        <ui-form
            v-bind:model="fields"
            v-bind:validation="$v"
            v-bind:submit-handler="submit"
            v-on:update="updateFormData"
            gap="wide"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import forms from '~/forms';

export default {
    name: 'page-cabinet-verification-profile-individual',
    props: {
        isTouched: {
            type: Boolean,
        },
    },
    validations() {
        if (this.fields.hasIp.value === true) {
            const result = {
                fields: {
                    ogrnip: { ...forms.validations.ogrnip },
                },
            };
            return result;
        }
        return {};
    },
    computed: {
        ...mapState('verification/profile', [
            'ip',
            'individual',
        ]),
        fields() {
            const result = {
                hasIp: { ...forms.fields.hasIp, value: this.individual.hasIp },
            };
            if (this.individual.hasIp === true) {
                result.ogrnip = {
                    ...forms.fields.ogrnip,
                    value: this.ip.ogrnip,
                };
            }
            return result;
        },
    },
    methods: {
        submit() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            this.$emit('submit');
        },
        updateFormData(newData) {
            if (newData.hasIp.value === true) {
                this.$store.state.verification.profile.individual = {
                    hasIp: true,
                };
                if (newData.ogrnip) {
                    this.$store.state.verification.profile.ip = {
                        ...this.$store.state.verification.profile.ip,
                        ogrnip: newData.ogrnip.value || null,
                    };
                }
            } else {
                this.$store.state.verification.profile.individual = {
                    hasIp: false,
                };
                this.$store.state.verification.profile.ip = {
                    ...this.$store.state.verification.profile.ip,
                    ogrnip: null,
                };
            }
        },
    },
    watch: {
        isTouched: {
            handler(newVal) {
                if (newVal) {
                    this.$v.$touch();
                }
            },
            immediate: true,
        },
        '$v.$dirty': {
            handler(newVal) {
                if (newVal) {
                    this.$emit('touch');
                }
            },
            immediate: true,
        },
        '$v.$invalid': {
            handler(newVal) {
                this.$emit('validation-update', { entityName: 'isIndividualDataValid', value: !newVal });
            },
            immediate: true,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-verification-profile-individual {
}
</style>
