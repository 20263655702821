<template>
    <div class="common-sms-verification">
        <template v-if="isAnonymous && !recaptchaValue">
            <div class="common-sms-verification__text"
                v-bind:style="{ textAlign }"
                >
                Подтвердите, что вы не робот, и мы вышлем код подтверждения на <b v-text="'(' + phone + ')'" />
            </div>
            <div class="common-sms-verification__recaptcha">
                <vue-recaptcha
                    v-bind:sitekey="recaptchaKey"
                    v-on:verify="recaptchaVerifyHandler"
                    v-on:expired="recaptchaExpiredHandler"
                    load-recaptcha-script
                    ref="recaptcha"
                />
                <div class="common-sms-verification__recaptcha-error"
                    v-if="recaptchaError"
                    v-text="recaptchaError"
                />
            </div>
        </template>
        <template v-else>
            <div class="common-sms-verification__text"
                v-bind:style="{ textAlign }"
                >
                <template v-if="customText">
                    {{ customText }}
                </template>
                <template v-if="!noText && !customText">
                    Мы выслали код на Ваш номер телефона <b v-text="'(' + phone + ')'"></b>, введите его для подтверждения заявки
                </template>
            </div>
            <div class="common-sms-verification__input">
                <ui-input-character-set
                    v-bind:size="codeLength"
                    v-model="code"
                    v-on:submit="checkCode"
                    is-autofocus
                />
            </div>
            <div class="common-sms-verification__error"
                v-if="codeRequestError"
                v-text="codeRequestError"
            />
            <div class="common-sms-verification__error"
                v-if="checkCodeError"
                v-text="checkCodeError"
            />
            <div class="common-sms-verification__button">
                <ui-button
                    v-on:click="checkCode"
                    v-bind:disabled="code.length !== codeLength"
                    theme="primary"
                    >
                    Подтвердить
                </ui-button>
            </div>
            <div class="common-sms-verification__help"
                v-if="codeRequestTimer === 0"
                >
                Не пришел код? <span class="common-sms-verification__help-trigger"
                    v-on:click="getCode"
                    >Отправить повторно</span>
            </div>
            <div class="common-sms-verification__help"
                v-else
                >
                <span v-text="'Повторно отправить код можно через '" /><span
                    class="common-sms-verification__help-timer"
                    v-text="formattedTimerValue"
                />
            </div>
        </template>
    </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import VueRecaptcha from 'vue-recaptcha';

export default {
    name: 'common-sms-verification',
    components: { VueRecaptcha },
    inject: [ 'recaptchaKey' ],
    props: {
        phone: {
            type: [ Number, String ],
            default: null,
        },
        isRequestRequired: {
            type: Boolean,
            default: false,
        },
        noText: {
            type: Boolean,
            default: false,
        },
        customText: {
            type: String,
            default: null,
        },
        textAlign: {
            type: String,
            default: 'center',
        },
        isAnonymous: {
            type: Boolean,
            default: false,
        },
        customCodeChecker: {
            type: Function,
            default: null,
        },
    },
    data: () => ({
        code: '',
        codeLength: 6,
        isCodeValid: true,
        recaptchaValue: null,
        recaptchaError: null,
    }),
    computed: {
        ...mapState('sms', [
            'getCodeIsLoading',
            'getCodeResult',
            'getCodeError',

            'codeRequestTimer',

            'getAuthCodeIsLoading',
            'getAuthCodeResult',
            'getAuthCodeError',

            'checkCodeIsLoading',
            'checkCodeError',
            'checkCodeResult',
        ]),
        isLoading() {
            return this.getCodeIsLoading || this.getAuthCodeIsLoading || this.checkCodeIsLoading;
        },
        codeRequestError() {
            return this.getCodeError || this.getAuthCodeError;
        },
        formattedTimerValue() {
            return moment(this.codeRequestTimer).format('mm:ss');
        },
    },
    methods: {
        recaptchaVerifyHandler(response) {
            this.recaptchaValue = response;
        },
        recaptchaExpiredHandler() {
            this.recaptchaValue = null;
        },
        getCode() {
            if (this.isAnonymous && !this.recaptchaValue) {
                this.recaptchaError = 'Подтвердите, что вы не робот';
                return;
            }
            if (this.isAnonymous) {
                this.$store.dispatch('sms/getAuthCode', { value: this.phone, recaptcha: this.recaptchaValue });
                return;
            }
            this.$store.state.sms.checkCodeError = null;
            this.$store.dispatch('sms/getCode', { phone: this.phone });
        },
        close() {
            this.$store.commit('modals/pop');
        },
        checkCode() {
            if (this.code.length !== this.codeLength) {
                return;
            }
            if (this.customCodeChecker) {
                this.customCodeChecker(this.code);
                this.close();
            } else {
                this.$store.state.sms.checkCodeError = null;
                this.$store.dispatch('sms/checkCode', {
                    code: this.code,
                    phone: this.phone,
                });
            }
        },
    },
    mounted() {
        this.$store.dispatch('sms/resetCheckCode');
        if (this.isRequestRequired && !this.isAnonymous) {
            this.getCode();
        }
    },
    watch: {
        checkCodeResult(newVal) {
            if (newVal) {
                this.$emit('submit', this.code);
            }
        },
        recaptchaValue(newVal) {
            if (newVal) {
                this.recaptchaError = null;
                this.getCode();
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.common-sms-verification {
    &__loader {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        display: flex;
        width: 100%;
        height: 100%;

        background: fade(@color-gray-lightest, 50%);
        &-container {
            width: 100px;
            margin: auto;
        }
    }
    &__text {
        .typography-body();

        margin-bottom: 24px;

        text-align: center;
    }
    &__input {
        width: fit-content;
        margin: 0 auto 40px;
    }
    &__error {
        .typography-body();

        margin: 16px 0 40px;

        color: @color-accent-negative;
        text-align: center;
    }
    &__recaptcha {
        flex: 0 0 auto;
        margin: 0 auto 40px;
        width: min-content;
        &-error {
            .typography-body();

            margin-top: 16px;

            color: @color-accent-negative;
            text-align: center;
        }
    }
    &__button {
        width: 205px;
        margin: 0 auto 24px;
    }
    &__help {
        .typography-body();

        text-align: center;
        &-trigger {
            cursor: pointer;

            color: @color-primary;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
        &-timer {
            display: inline-block;
            min-width: 40px;

            color: @color-primary;
            text-align: left;
        }
    }
}
</style>
