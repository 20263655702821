<template>
    <section class="plugin-home-how-it-works">
        <div class="plugin-home-how-it-works__backgrounds">
            <div class="plugin-home-how-it-works__backgrounds-dark" />
            <div class="plugin-home-how-it-works__backgrounds-light" />
        </div>
        <div class="plugin-home-how-it-works__container">
            <div class="plugin-home-how-it-works__content">
                <h2 class="plugin-home-how-it-works__content-title"
                    v-text="title"
                />
                <div class="plugin-home-how-it-works__content-buttons">
                    <div class="plugin-home-how-it-works__content-buttons-item"
                        v-for="(item, index) in items"
                        v-bind:key="index"
                        v-bind:class="{ _active: index === activeIndex }"
                        v-on:click="slideTo(index)"
                        v-text="index + 1"
                    />
                </div>
                <div class="plugin-home-how-it-works__content-texts">
                    <div class="swiper-container"
                        ref="texts"
                        >
                        <div class="swiper-wrapper">
                            <div class="swiper-slide"
                                v-for="(item, index) in items"
                                v-bind:key="index"
                                >
                                <div class="plugin-home-how-it-works__content-texts-item">
                                    <h3 class="plugin-home-how-it-works__content-texts-item-title"
                                        v-text="item.title"
                                    />
                                    <div class="plugin-home-how-it-works__content-texts-item-text">
                                        <div class="common-ckeditor"
                                            v-html="item.text"
                                        />
                                    </div>
                                    <div class="plugin-home-how-it-works__content-texts-item-button"
                                        v-if="item.buttonUrl && item.buttonText"
                                        >
                                        <a class="plugin-home-how-it-works__content-texts-item-button-link"
                                            v-bind:href="item.buttonUrl"
                                            >
                                            <ui-button
                                                v-bind:text="item.buttonText"
                                                theme="primary"
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="plugin-home-how-it-works__images">
                <div class="plugin-home-how-it-works__images-content">
                    <div class="swiper-container"
                        ref="images"
                        >
                        <div class="swiper-wrapper">
                            <div class="swiper-slide"
                                v-for="(item, index) in items"
                                v-bind:key="index"
                                >
                                <div class="plugin-home-how-it-works__images-content-item"
                                    v-bind:style="getBackgroundStyle(item.image)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Swiper from 'swiper/dist/js/swiper';
import utils from '~/utils';

export default {
    name: 'plugin-home-how-it-works',
    props: {
        title: {
            type: String,
            default: '',
        },
        items: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        activeIndex: 0,
        sliderTexts: null,
        sliderImages: null,
    }),
    methods: {
        initSliders() {
            this.initTexts();
            this.initImages();
        },
        initTexts() {
            const container = this.$refs.texts;

            this.sliderTexts = new Swiper(container, {
                speed: 600,
                slidesPerView: 1,
                allowTouchMove: false,
                effect: 'fade',
                fadeEffect: {
                    crossFade: true,
                },
            });
        },
        initImages() {
            const container = this.$refs.images;

            this.sliderImages = new Swiper(container, {
                speed: 600,
                slidesPerView: 1,
                allowTouchMove: false,
                effect: 'fade',
            });
        },
        slideTo(index) {
            this.activeIndex = index;
            this.sliderTexts.slideTo(index);
            this.sliderImages.slideTo(index);
        },
        getBackgroundStyle(image) {
            if (!image) {
                return null;
            }
            const resize = utils.common.getResize({
                1920: 43,
                1279: 30,
                1023: '320',
                575: 100,
            });
            return { backgroundImage: `url(${utils.common.injectResize(image, resize)})` };
        },
    },
    mounted() {
        this.initSliders();
        utils.dom.addLoadedCallback(this.initSliders);
    },
    beforeDestroy() {
        utils.dom.removeLoadedCallback(this.initSliders);
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-home-how-it-works {
    position: relative;

    padding-bottom: 177px;
    &__backgrounds {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        &-dark {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 58.8%;

            border-radius: 4px 0px 0px 4px;

            background-color: @color-gray-darker;
        }
        &-light {
            position: absolute;
            top: 94px;
            right: 18.8%;
            bottom: 60px;
            left: 0;

            border-radius: 0px 4px 4px 0px;

            background-color: @color-gray-lighter;
        }
    }
    &__container {
        .container();

        position: relative;

        display: flex;
    }
    &__content {
        flex: 0 0 auto;
        width: calc(@grid-column * 4 + @grid-gap * 3);
        margin-left: calc(@grid-column + @grid-gap);
        padding: 0 12px;

        &-title {
            .typography-extra-large();

            margin: 0 0 100px;
        }
        &-buttons {
            display: flex;
            margin-bottom: 61px;
            margin-right: -@grid-gap-half;
            margin-left: -@grid-gap-half;

            &-item {
                .typography-medium();

                position: relative;

                flex: 0 0 auto;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;
                padding-top: 1px;
                border-radius: 50%;
                margin-right: @grid-gap-half;
                margin-left: @grid-gap-half;

                color: @color-gray-main;
                text-align: center;

                user-select: none;
                cursor: pointer;

                transition: box-shadow @duration-fast ease-in-out, color @duration-fast ease-in-out;

                &:before {
                    content: '';

                    position: absolute;
                    top: -12px;
                    right: -12px;
                    bottom: -12px;
                    left: -12px;

                    display: block;
                }
                &:hover,
                &._active {
                    box-shadow: 0px 6px 10px rgba(164, 150, 186, 0.5);
                }
                &._active {
                    color: @color-primary;

                    pointer-events: none;
                }
            }
        }
        &-texts {
            & > .swiper-container {
                margin: 0 -12px;
                padding: 0 12px;
            }
            &-item {
                &-title {
                    .typography-large();

                    margin: 0 0 24px;

                    line-height: 3.4rem;
                }
                &-button {
                    margin: 24px 0;
                    &-link {
                        display: inline-block;

                        text-decoration: none;
                    }
                }
            }
        }
    }
    &__images {
        position: relative;

        flex: 0 0 auto;
        width: calc(@grid-column * 5 + @grid-gap * 6);
        margin-top: 174px;
        margin-left: calc(@grid-column + @grid-gap);
        &:before {
            content: '';

            position: absolute;
            top: -7%;
            right: -11%;
            bottom: -15%;
            left: -11%;

            display: block;

            background-image: url('/static/images/plugin-home-how-it-works.png');
            background-size: contain;
            background-position: center top;
            background-repeat: no-repeat;
        }
        &-content {
            position: relative;
            &-item {
                padding-top: 63.73%;

                background-color: @color-gray-main;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
    }
    @media @media-lg-down {
        &__content {
            width: calc(@grid-column * 5 + @grid-gap * 6);
        }
        &__images {
            width: calc(@grid-column * 4 + @grid-gap * 3);
        }
    }
    @media @media-md-down {
        padding-bottom: 131px;
        overflow: hidden;
        &__backgrounds {
            &-dark {
                top: 60px;
                left: 24px;
            }
            &-light {
                top: 0;
                right: 24px;
            }
        }
        &__container {
            flex-direction: column;
            align-items: stretch;
        }
        &__content {
            width: 100%;
            margin-left: 0;
            &-title {
                .typography-large();

                margin-top: 62px;
                margin-bottom: 24px;
            }
            &-buttons {
                margin-bottom: 24px;
            }
            &-texts {
                &-item {
                    padding-right: 24px;
                    &-title {
                        .typography-heading();

                        margin-bottom: 10px;
                    }
                }
            }
        }
        &__images {
            width: 320px;
            margin-top: 36px;
            margin-right: auto;
            margin-left: auto;
        }
    }
    @media @media-xs-down {
        &__images {
            width: 100%;
            margin-top: 36px;
            margin-left: 31px;
        }
    }
}
</style>
