<template>
    <div class="modal-market-application-create-objects">
        <div class="modal-market-application-create-objects__title">
            Объект
        </div>
        <div class="modal-market-application-create-objects__content">
            <div class="modal-market-application-create-objects__content-list">
                <div class="modal-market-application-create-objects__content-list-item"
                    v-for="(item, i) in items"
                    v-bind:key="i"
                    v-bind:class="{ _active: item.id === shareId }"
                    v-on:click="setShareId(item.id)"
                    v-bind:ref="item.id === shareId ? 'active' : null"
                    >
                    <modal-market-application-create-objects-item
                        v-bind:item="item"
                        v-bind:is-active="item.id === shareId"
                    />
                </div>
            </div>
            <div class="modal-market-application-create-objects__content-slider">
                <div class="modal-market-application-create-objects__content-slider-container swiper-container"
                    ref="slider"
                    >
                    <div class="modal-market-application-create-objects__content-slider-wrapper swiper-wrapper">
                        <div class="modal-market-application-create-objects__content-slider-item swiper-slide"
                            v-for="(item, i) in items"
                            v-bind:key="i"
                            >
                            <modal-market-application-create-objects-item
                                v-bind:item="item"
                                v-bind:is-active="item.id === shareId"
                            />
                        </div>
                    </div>
                    <div class="modal-market-application-create-objects__content-slider-pagination swiper-pagination"
                        ref="pagination"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper';
import utils from '~/utils';

export default {
    name: 'modal-market-application-create-objects',
    props: [ 'shareId', 'items' ],
    data: () => ({
        slider: null,
        breakpoint: 768,
    }),
    methods: {
        initSwiper() {
            if (this.slider !== null) {
                this.slider.update();
                return;
            }
            const container = this.$refs['slider'];
            const paginationEl = this.$refs['pagination'];
            const vue = this;
            const targetIndex = this.items.findIndex(x => x.id === this.shareId) || 0;
            this.slider = new Swiper(container, {
                speed: 600,
                slidesPerView: 1,
                watchSlidesVisibility: true,
                initialSlide: targetIndex,
                setWrapperSize: true,
                mousewheel: {
                    forceToAxis: true,
                },
                pagination: {
                    el: paginationEl,
                    type: 'bullets',
                    bulletClass: 'modal-market-application-create-objects__content-slider-pagination-item',
                    bulletActiveClass: '_active',
                    clickable: true,
                },
                on: {
                    init() {
                        if (!vue.shareId) {
                            vue.setShareId(vue.items[0].id);
                        }
                    },
                    slideChange() {
                        const newShareId = vue.items[this.realIndex].id;
                        if (vue.shareId !== newShareId) {
                            vue.setShareId(newShareId);
                        }
                    },
                },
            });
        },
        checkSlider() {
            if (window.innerWidth >= this.breakpoint && this.slider !== null) {
                this.slider.destroy();
                this.slider = null;
                this.scrollToActive();
                return;
            }
            if (window.innerWidth < this.breakpoint && this.slider === null) {
                this.initSwiper();
            }
        },
        setShareId(id) {
            this.$emit('share-id-change', id);
        },
        scrollToActive() {
            if (window.innerWidth < this.breakpoint) {
                return;
            }
            this.$nextTick(() => {
                const activeElement = this.$refs.active;
                if (!activeElement || !activeElement.scrollIntoView) {
                    return;
                }
                activeElement.scrollIntoView({
                    alignToTop: true,
                    block: 'center',
                    behavior: 'smooth',
                });
            });
        },
    },
    mounted() {
        this.checkSlider();
        utils.dom.addLoadedCallback(this.checkSlider);
        window.addEventListener('resize', this.checkSlider);
    },
    beforeDestroy() {
        if (this.slider !== null) {
            this.slider.destroy();
        }
        utils.dom.removeLoadedCallback(this.checkSlider);
        window.removeEventListener('resize', this.checkSlider);
    },
    watch: {
        shareId: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.scrollToActive();
                }
            },
        },
    },
};
</script>

<style lang="less">
@import '~theme';

.modal-market-application-create-objects {
    height: 100%;

    background-color: @color-gray-lightest;
    &__title {
        .typography-heading();

        padding: 24px 24px 16px;

        font-weight: 700;
    }
    &__content {
        position: relative;

        // height: calc(100% - 56px);
        overflow-y: auto;
        &-list {
            &-item {
                padding: 16px 24px;
                &:not(._active) {
                    cursor: pointer;
                }
                &._active {
                    // position: sticky;
                    // top: 0;
                    // bottom: 0;
                    // z-index: 2;
                    background-color: @color-gray-light;
                }
            }
        }
        &-slider {
            display: none;
        }
    }
    @media @media-sm-down {
        background-color: @color-gray-light;
        &__title {
            padding: 16px;
        }
        &__content{
            min-height: 98px;
            &-loader {
                background-color: @color-gray-lighter;
            }
            &-list {
                display: none;
                // &-item {
                //     padding: 16px;
                //     &._active {
                //         background-color: @color-gray-lighter;
                //     }
                // }
            }
            &-slider {
                display: block;
                // width: 100%;
                // display: flex;
                // flex-direction: column;
                // align-items: stretch;
                // justify-content: space-between;
                &-item {
                    width: 100%;
                    padding: 4px 16px;
                }
                &-wrapper {
                    align-items: stretch;
                }
                &-pagination {
                    position: static;
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    padding: 24px 16px 16px;
                    &-item {
                        display: block;
                        width: 16px;
                        height: 16px;
                        padding: 4px;

                        cursor: pointer;
                        opacity: 0.4;
                        outline: none;

                        transition: opacity @duration-fast @easing-default;
                        &:before {
                            content: '';

                            display: block;
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;

                            background-color: @color-gray-darkest;
                        }
                        &._active {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
</style>
