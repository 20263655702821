<template>
    <footer class="common-footer">
        <div class="common-footer__container">
            <div class="common-footer__wrapper">
                <a class="common-footer__logo"
                    href="/"
                    >
                    <ui-logo-text
                        use-current-color
                        second-color="#9155EB"
                    />
                </a>
                <div class="common-footer__subscription">
                    <common-footer-subscription />
                </div>
                <div class="common-footer__info">
                    <common-footer-collapsable
                        title="Информация"
                        v-model="isInfoCollapsed"
                        >
                        <common-footer-info />
                    </common-footer-collapsable>
                </div>
                <div class="common-footer__contacts">
                    <common-footer-collapsable
                        title="Контакты"
                        v-model="isContactsCollapsed"
                        >
                        <common-footer-contacts />
                    </common-footer-collapsable>
                </div>
                <div class="common-footer__documents"
                    v-if="documents"
                    >
                    <common-footer-collapsable
                        title="Документы"
                        v-model="isDocumentsCollapsed"
                        >
                        <common-footer-documents v-bind:documents="documents" />
                    </common-footer-collapsable>
                </div>
                <div class="common-footer__copyright">
                    <p>
                        ООО "СИМПЛ ЭСТЭЙТ", ИНН 7707430579, ОГРН 1197746322289,
                        117342, г. Москва, ул. Бутлерова, д. 17, эт/комн 5/248
                    </p>
                    © {{ new Date().getFullYear() }} Все права защищены
                </div>
                <div class="common-footer__register">
                    <a class="common-footer__register-icon"
                        v-bind:href="urls.bank"
                        target="_blank"
                        >
                        <icon name="bank-registry" width="31px" height="31px" original />
                    </a>
                    <div class="common-footer__register-text">
                        Платформа состоит в <a v-bind:href="urls.register" target="_blank">Реестре операторов инвестиционных платформ РФ</a>
                    </div>
                </div>
                <div class="common-footer__development">
                    Дизайн и разработка: <a class="common-footer__development-link"
                        v-bind:href="urls.initflow"
                        target="_blank"
                        >Initflow</a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import config from '~/config';

export default {
    name: 'common-footer',
    props: {
        documents: {
            type: Array,
            default: null,
        },
    },
    data: () => ({
        isInfoCollapsed: true,
        isContactsCollapsed: true,
        isDocumentsCollapsed: true,
    }),
    computed: {
        urls() {
            return {
                register: config.urls.footer.register,
                bank: config.urls.footer.bank,
                initflow: 'https://initflow.com',
            };
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.common-footer {
    .typography-body();

    color: @color-gray-light;

    background-color: @color-gray-darker;
    &__container {
        .container();
    }
    &__wrapper {
        display: grid;
        column-gap: @grid-gap;
        grid-template-columns: repeat(12, 1fr);
        padding-top: 52px;
        padding-bottom: 40px;
    }
    &__logo {
        .typography-medium();

        grid-row: 1;
        grid-column: ~'1 / 4';
        margin-bottom: 32px;

        text-decoration: none;
    }
    &__subscription {
        grid-row: ~'1 / 3';
        grid-column: ~'9 / 13';
    }
    &__info {
        grid-row: 2;
        grid-column: ~'1 / 4';
        margin-bottom: 28px;
    }
    &__contacts {
        grid-row: 2;
        grid-column: ~'4 / 6';
        margin-bottom: 28px;
    }
    &__documents {
        grid-row: 2;
        grid-column: ~'6 / 8';
        margin-bottom: 28px;
    }
    &__copyright {
        grid-row: 3;
        grid-column: ~'1 / 3';
        align-self: center;
    }
    &__register {
        grid-row: 3;
        grid-column: ~'4 / 9';
        align-self: center;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &-icon {
            flex: 0 0 auto;
            display: block;
            margin-right: 12px;

            filter: grayscale(100%);
        }
        &-text {
            flex: 1 1 auto;
        }
    }
    &__development {
        grid-row: 3;
        grid-column: ~'11 / 13';
        align-self: center;

        text-align: right;
        &-link {
            font-weight: 600;
            text-decoration: none;
            text-transform: uppercase;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    @media @media-lg-down {
        &__documents {
            grid-row: 2;
            grid-column: ~'6 / 9';
        }
    }
    @media @media-md-down {
        &__wrapper {
            grid-template-columns: repeat(7, 1fr);
        }
        &__logo {
            grid-column: ~'1 / 8';
        }
        &__subscription {
            grid-row: ~'3 / 7';
            grid-column: ~'4 / 8';
        }
        &__info {
            grid-column: ~'1 / 4';
        }
        &__contacts {
            grid-column: ~'4 / 6';
        }
        &__documents {
            grid-column: ~'6 / 8';
        }
        &__copyright {
            grid-row: 5;
            grid-column: ~'1 / 4';
            align-self: flex-start;
            margin-bottom: 24px;
        }
        &__register {
            grid-row: 3;
            grid-column: ~'1 / 4';
            align-self: flex-start;
            margin-bottom: 24px;
        }
        &__development {
            grid-row: 6;
            grid-column: ~'1 / 4';
            align-self: flex-start;
            margin-bottom: 16px;

            text-align: left;
        }
    }
    @media @media-xs-down {
        &__wrapper {
            grid-template-columns: 1fr;
            justify-items: center;

            text-align: center;
        }
        &__logo {
            grid-column: 1;
        }
        &__subscription {
            grid-row: 2;
            grid-column: 1;
            margin-bottom: 24px;
        }
        &__info {
            grid-column: 1;
            grid-row: 3;
            margin-bottom: 24px;
        }
        &__contacts {
            grid-column: 1;
            grid-row: 4;
            margin-bottom: 24px;
        }
        &__documents {
            grid-column: 1;
            grid-row: 5;
            margin-bottom: 40px;
        }
        &__copyright {
            grid-column: 1;
            grid-row: 7;
            margin-bottom: 16px;
        }
        &__register {
            grid-column: 1;
            grid-row: 6;
            margin-bottom: 16px;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            &-icon {
                margin-right: 0;
                margin-bottom: 8px;
            }
        }
        &__development {
            grid-column: 1;
            grid-row: 8;
            margin-bottom: 0;
        }
    }
}
</style>
