<template>
    <div class="modal-withdrawal">
        <transition name="fade">
            <div class="modal-withdrawal__loader"
                v-if="isLoading"
                >
                <div class="modal-withdrawal__loader-container">
                    <ui-loader />
                </div>
            </div>
        </transition>
        <template v-if="!isLoading">
            <div class="modal-withdrawal__title">
                Вывод средств
            </div>
            <div class="modal-withdrawal__subtitle"
                v-text="`Введите сумму и подтвердите операцию вывода средств для ИНН ${getUserJuridicalInfoResult.inn}`"
            />
            <div class="modal-withdrawal__available"
                v-on:click="setMaxSum"
                >
                <div class="modal-withdrawal__available-title">
                    Доступно:
                </div>
                <div class="modal-withdrawal__available-value"
                    v-text="balanceFormatted"
                />
            </div>
            <div class="modal-withdrawal__amount">
                <div class="modal-withdrawal__amount-fields">
                    <ui-form
                        v-bind:model="amount"
                        v-bind:validation="$v"
                        v-bind:validation-path="'amount'"
                        v-bind:submit-handler="submitHandler"
                        v-on:update="updateAmount"
                    />
                </div>
            </div>
            <div class="modal-withdrawal__juridical">
                <div class="modal-withdrawal__juridical-title"
                    v-text="'Банковские реквизиты'"
                />
                <div class="modal-withdrawal__juridical-subtitle"
                    v-text="'Убедитесь, что реквизиты указаны корректно, или введите другие реквизиты, по которым вы хотите перевести деньги'"
                />
                <div class="modal-withdrawal__juridical-fields">
                    <ui-form
                        v-bind:model="fields"
                        v-bind:validation="$v"
                        v-bind:submit-handler="submitHandler"
                        v-on:update="updateFormData"
                    />
                </div>
            </div>
            <div class="modal-withdrawal__buttons">
                <div class="modal-withdrawal__buttons-item">
                    <ui-button
                        v-on:click="submitHandler"
                        v-bind:disabled="$v.$dirty && $v.$invalid"
                        theme="primary"
                        >
                        Получить смс-код
                    </ui-button>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import forms from '~/forms';
import utils from '~/utils';

export default {
    name: 'modal-withdrawal',
    data: () => ({
        onCloseCallback: null,
        availableInvestment: 0,
        amount: {
            withdrawalAmount: { ...forms.fields.withdrawalAmount, isAutofocus: true },
        },
        innerFields: {
            juridicalBankAccount: { ...forms.fields.user.juridical.juridicalBankAccount },
            juridicalBik: { ...forms.fields.user.juridical.juridicalBik },
            juridicalKpp: { ...forms.fields.user.juridical.juridicalKpp },
        },
    }),
    validations() {
        const result = {
            amount: {
                withdrawalAmount: { ...forms.validations.withdrawalAmount(this.balance) },
            },
            fields: {
                juridicalBankAccount: { ...forms.validations.genericRequired },
                juridicalBik: { ...forms.validations.bik },
            },
        };
        if (this.isKppRequired) {
            result.fields.juridicalKpp = { ...forms.validations.kpp };
        }
        return result;
    },
    computed: {
        ...mapState('users', [
            'getUserProfileResult',
            'getUserProfileIsLoading',

            'getUserJuridicalInfoResult',
            'getUserJuridicalInfoIsLoading',
        ]),
        ...mapState('dashboard', [
            'getHighlightsResult',
            'getHighlightsError',
            'getHighlightsIsLoading',

            'requestWithdrawResult',
            'requestWithdrawError',
            'requestWithdrawIsLoading',
        ]),
        balance() {
            return this.getHighlightsResult?.balance || 0;
        },
        balanceFormatted() {
            return utils.formats.formatNumberWithSpaces(this.balance) + ' ₽';
        },
        isLoading() {
            return this.getHighlightsIsLoading ||
                this.requestWithdrawIsLoading ||
                this.getUserProfileIsLoading ||
                this.getUserJuridicalInfoIsLoading;
        },
        isKppRequired() {
            return this.getUserProfileResult && this.getUserProfileResult.legalType !== 'F';
        },
        fields() {
            if (this.isKppRequired) {
                return this.innerFields;
            } else {
                return {
                    juridicalBankAccount: this.innerFields.juridicalBankAccount,
                    juridicalBik: this.innerFields.juridicalBik,
                };
            }
        },
    },
    methods: {
        setMaxSum() {
            this.amount.withdrawalAmount.value = Math.floor(this.balance);
        },
        updateAmount(newData) {
            this.amount = newData;
        },
        updateFormData(newData) {
            this.innerFields = { ...this.innerFields, ...newData };
        },
        close() {
            this.$store.commit('modals/pop');
        },
        submitHandler() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            this.$store.commit('modals/push', {
                name: 'sms-input',
                props: {
                    smsVerificationProps: {
                        phone: this.getUserProfileResult.phone,
                        isRequestRequired: true,
                    },
                },
                on: {
                    submit: (code) => {
                        const payload = {
                            value: this.getUserProfileResult.phone,
                            code,
                            amount: this.amount.withdrawalAmount.value,
                            bankAccount: this.fields.juridicalBankAccount.value,
                            bik: this.fields.juridicalBik.value,
                        };
                        if (this.isKppRequired) {
                            payload.kpp = this.fields.juridicalKpp.value;
                        }
                        this.$store.dispatch('dashboard/requestWithdraw', payload);
                    },
                },
            });
        },
    },
    beforeMount() {
        this.$store.dispatch('users/getUserProfile');
        this.$store.dispatch('users/getUserJuridicalInfo');
        this.$store.dispatch('dashboard/getHighlights');
    },
    beforeDestroy() {
        if (this.onCloseCallback !== null) {
            this.onCloseCallback();
        }
    },
    watch: {
        balance: {
            handler(newVal) {
                if (newVal) {
                    this.amount.withdrawalAmount.maxValue = Math.floor(this.balance);
                }
            },
            immediate: true,
        },
        getUserJuridicalInfoResult: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    // this.innerFields.juridicalBankAccount.value = newVal.bankAccount;
                    // this.innerFields.juridicalBik.value = newVal.bik;
                    // this.innerFields.juridicalKpp.value = newVal.kpp;
                }
            },
        },
        requestWithdrawError(newVal) {
            if (newVal) {
                this.onCloseCallback = () => { this.$store.commit('modals/push', 'withdrawal-error'); };
                this.close();
            }
        },
        requestWithdrawResult(newVal) {
            if (newVal) {
                this.onCloseCallback = () => { this.$store.commit('modals/push', 'withdrawal-success'); };
                this.close();
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-withdrawal {
    .modal();

    position: relative;
    &__loader {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        background-color: fade(@color-gray-lightest, 50%);
        &-container {
            width: 100px;
        }
    }
    &__title {
        .typography-large();

        margin-bottom: 8px;
    }
    &__subtitle {
        .typography-body();

        margin-bottom: 8px;
    }
    &__available {
        .typography-caption();

        display: flex;
        align-items: baseline;
        justify-content: flex-start;
        width: fit-content;
        padding: 4px 8px;
        border-radius: @border-radius-lg;
        margin: 0 auto 24px 0;

        font-weight: 700;
        text-transform: uppercase;

        background-color: @color-gray-light;

        cursor: pointer;
        &-title {
            margin-right: 8px;

            color: @color-gray-main;
        }
    }
    &__amount {
        margin-bottom: 40px;
    }
    &__juridical {
        margin-bottom: 40px;
        &-title {
            .typography-heading();

            margin-bottom: 8px;
        }
        &-subtitle {
            .typography-body();

            margin-bottom: 16px;
        }
    }
    &__buttons {
        &-item {
            max-width: 206px;
            margin: 40px auto 0;
        }
    }
    @media @media-sm-down {
        padding: 16px 16px 24px;
        &__title {
            .typography-medium();
        }
    }
}
</style>
