<template>
    <div class="page-market-item-orders-table-item"
        v-bind:class="{ _accented: item.type === 'sale' }"
        >
        <div class="page-market-item-orders-table-item__field">
            <span class="page-market-item-orders-table-item__field-date"
                v-text="item.dateFormatted"
            />
            <ui-label v-if="item.isOwner"
                theme="primary"
                weight="normal"
                is-inline
                >
                Ваша заявка
            </ui-label>
        </div>
        <div class="page-market-item-orders-table-item__field"
            v-text="item.typeTitle"
        />
        <div class="page-market-item-orders-table-item__field"
            v-text="item.priceFormatted"
        />
        <div class="page-market-item-orders-table-item__field _flex">
            <div
                v-text="item.amountFormatted"
            />
            <div class="page-market-item-orders-table-item__field-icon"
                v-if="item.isPackage"
                >
                <ui-hint text="Эти акции можно приобрести только полным пакетом">
                    <icon name="lock" />
                </ui-hint>
            </div>
        </div>
        <div class="page-market-item-orders-table-item__field"
            v-text="item.sumFormatted"
        />
        <div class="page-market-item-orders-table-item__field">
            <span class="page-market-item-orders-table-item__field-action _cancel"
                v-if="item.isOwner"
                v-on:click="cancel"
                >
                Отменить
            </span>
            <span class="page-market-item-orders-table-item__field-action"
                v-else-if="!(item.type === 'buy' && isUserShareless)"
                v-on:click="apply"
                >
                Принять предложение
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'page-market-item-orders-table-item',
    inject: [ 'shareId' ],
    props: {
        item: {
            type: Object,
        },
        isUserShareless: {
            type: Boolean,
        },
    },
    computed: {
        isVerified() {
            return this.$store.getters['users/isVerified'];
        },
    },
    methods: {
        cancel() {
            this.$store.dispatch('market/cancelOrder', { id: this.item.id });
        },
        apply() {
            if (this.isVerified) {
                this.$store.commit('modals/push', {
                    name: 'market-application-create',
                    props: {
                        isLocked: true,
                        orderId: this.item.id,
                        givenShareId: this.shareId,
                        givenType: this.item.type,
                        givenAmountLimit: this.item.amount,
                        givenPrice: this.item.price,
                        isPriceDisabled: true,
                        isAmountDisabled: this.item.isPackage,
                    },
                });
            } else {
                this.$store.commit('modals/push', 'verification-required');
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-market-item-orders-table-item {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    width: 100%;
    padding: 16px 12px;
    border-radius: @border-radius-lg;
    border: 1px solid @color-gray-light;

    background-color: @color-gray-lightest;
    &._accented {
        background-color: @color-gray-lighter;
    }
    &__field {
        .typography-body();

        flex: 1 1 auto;
        padding: 0 12px;
        &:nth-child(1) {
            width: 195px;

            color: @color-gray-main;
        }
        &:nth-child(2) {
            width: 144px;
        }
        &:nth-child(3) {
            width: 256px;
        }
        &:nth-child(4) {
            width: 292px;
        }
        &:nth-child(5) {
            width: 210px;
        }
        &:nth-child(6) {
            width: 180px;

            text-align: right;
        }
        &._flex {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
        &-date {
            margin-right: 12px;
        }
        &-flag {
            .typography-caption();

            display: inline-block;
            padding: 2px 4px 1px;
            border-radius: @border-radius-lg;

            color: @color-primary;

            background-color: fade(@color-primary, 10%);
        }
        &-action {
            .typography-caption();

            color: @color-primary;
            font-weight: 700;
            text-transform: uppercase;

            cursor: pointer;
            &._cancel {
                color: @color-accent-negative;
            }
        }
        &-icon {
            margin-left: 8px;
        }
    }
}
</style>
