<template>
    <div class="plugin-about-person-item">
        <div class="plugin-about-person-item__headline">
            <div class="plugin-about-person-item__headline-logo"
                v-bind:style="imageBackgroundStyle"
            />
            <div class="plugin-about-person-item__headline-info">
                <div class="plugin-about-person-item__headline-info-title"
                    v-if="title"
                    v-text="title"
                />
                <div class="plugin-about-person-item__headline-info-position"
                    v-if="position"
                    v-text="position"
                />
            </div>
        </div>
        <div class="plugin-about-person-item__text"
            v-html="text"
        />
    </div>
</template>

<script>
import utils from '~/utils';

export default {
    name: 'plugin-about-person-item',
    props: {
        title: {
            type: String,
            default: '',
        },
        position: {
            type: String,
            default: '',
        },
        image: {
            type: String,
            default: '',
        },
        text: {
            type: String,
            default: '',
        },
    },
    computed: {
        imageBackgroundStyle() {
            return { backgroundImage: `url(${utils.common.injectResize(this.image, '182xAUTO')})` };
        },
    },
};
</script>

<style lang="less">
@import '~theme';

.plugin-about-person-item {
    &:not(:last-child) {
        margin-bottom: 32px;
    }
    &__headline {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 12px;
        &-logo {
            flex: 0 0 auto;
            width: 91px;
            height: 91px;
            margin-right: 24px;

            background-size: contain;
            background-position: left center;
            background-repeat: no-repeat;
        }
        &-info {
            flex: 1 1 auto;
            &-title {
                .typography-medium();
            }
            &-position {
                .typography-small();
            }
        }
    }
    &__text {
        & > h4 {
            .typography-medium();

            margin: 0 0 16px 0;
        }
        & > ul {
            padding: 0 0 0 32px;

            list-style: none;
            &:not(:last-child) {
                margin: 0 0 32px 0;
            }
            & > li {
                .typography-small();

                position: relative;
                &::before {
                    content: '';

                    position: absolute;
                    left: -16px;
                    top: 14px;

                    width: 0.15em;
                    height: 0.15em;
                    border-radius: 50%;

                    background-color: currentColor;

                    transform: translate(-50%, -50%);
                }
            }
        }
    }
    @media @media-md-down {
        &__headline {
            &-logo {
                width: 64px;
                height: 64px;
                margin-right: 16px;
            }
            &-info {
                &-title {
                    .typography-heading();
                }
                &-position {
                    .typography-body();
                }
            }
        }
        &__text {
            & > h4 {
                .typography-heading();
            }
            & > ul {
                padding-left: 24px;
                &:not(:last-child) {
                    margin-bottom: 24px;
                }
                & > li {
                    .typography-body();
                    &::before {
                        top: 9px;
                        left: -12px;
                    }
                }
            }
        }
    }
}
</style>
