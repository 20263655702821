<template>
    <ui-card-base
        v-bind:is-accented="isAccented"
        v-bind:with-border="!isAccented"
        >
        <article class="page-cabinet-dashboard-highlights-card">
            <div class="page-cabinet-dashboard-highlights-card__title"
                v-text="title"
            />
            <div class="page-cabinet-dashboard-highlights-card__info">
                <div class="page-cabinet-dashboard-highlights-card__info-value"
                    v-text="valueFormatted"
                />
                <div class="page-cabinet-dashboard-highlights-card__info-units"
                    v-if="units && units !== '%' && units !== 'x'"
                    v-text="'/' + units"
                />
                <!-- <div class="page-cabinet-dashboard-highlights-card__info-delta"
                    v-if="delta"
                    v-text="delta.toFixed(1).replace(/\.?0*$/,'') + '%'"
                    v-bind:class="{
                        _positive: delta > 0,
                        _negative: delta <= 0,
                    }"
                /> -->
            </div>
        </article>
    </ui-card-base>
</template>

<script>
import utils from '~/utils';

export default {
    name: 'page-cabinet-dashboard-highlights-card',
    props: {
        isAccented: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
        value: {
            type: [ Number, String ],
            default: 0,
        },
        units: {
            type: String,
            default: null,
        },
        delta: {
            type: [ Number, String ],
            default: null,
        },
    },
    computed: {
        valueFormatted() {
            let result = this.value;
            if (typeof this.value !== 'number') {
                result = parseFloat(this.value);
            }
            return utils.formats.formatNumberWithSpaces(result) + (this.units && (this.units === '%' || this.units === 'x') ? this.units : '');
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-dashboard-highlights-card {
    color: @color-gray-main;
    ._accented & {
        color: currentColor;
    }
    &__title {
        .typography-body();

        margin-bottom: 8px;
    }
    &__info {
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        width: 100%;
        &-value {
            .typography-large();

            color: @color-gray-darker;
            ._accented & {
                color: currentColor;
            }
        }
        &-units {
            .typography-caption();

            margin-left: 4px;

            text-transform: uppercase;
        }
        &-delta {
            .typography-caption();

            padding: 2px 4px 2px 6px;
            border-radius: @border-radius-lg;
            margin-left: auto;
            margin-right: 0;

            font-weight: bold;
            &._positive {
                color: @color-positive;

                background-color: fade(@color-positive, 10%);

                &:before {
                    content: '+';
                }
            }
            &._negative {
                color: @color-accent-negative;

                background-color: fade(@color-accent-negative, 10%);
            }
        }
    }
}
</style>
