// import moment from 'moment';
// import { date as dateTransform } from '~/network/mainClient/common/transforms/responses';
// import config from '~/config';

export const calculate = (data) => {
    const result = {
        ...data,
        actualQuantity: data.actual_quantity,
    };
    return result;
};
