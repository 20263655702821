<template>
    <div class="page-cabinet-verification-questionary">
        <transition name="fade">
            <div class="page-cabinet-verification-questionary__loader"
                v-if="isLoading"
                >
                <div class="page-cabinet-verification-questionary__loader-container">
                    <ui-loader />
                </div>
            </div>
        </transition>
        <div class="page-cabinet-verification-questionary__list">
            <div class="page-cabinet-verification-questionary__list-item"
                v-for="(q, qi) in questions"
                v-bind:key="q.id"
                >
                <label class="page-cabinet-verification-questionary__list-item-label"
                    v-text="q.text"
                />
                <div class="page-cabinet-verification-questionary__list-item-input"
                    v-if="q.type === 'text'"
                    >
                    <!-- <ui-input-base-text
                        v-model="$v.fields[field.name].value.$model"
                        v-bind:class="{ _invalid: $v.fields[field.name].value.$error }"
                        v-bind:placeholder="field.placeholder"
                        v-bind:type="field.type"
                        v-on:change="updateAnswer(question.id, variant.id)"
                        no-paddings
                    /> -->
                </div>
                <div class="page-cabinet-verification-questionary__list-item-variants"
                    v-else
                    >
                    <div class="page-cabinet-verification-questionary__list-item-variants-item"
                        v-for="(v, vi) in q.variants"
                        v-bind:key="v.id"
                        >
                        <div class="page-cabinet-verification-questionary__list-item-variants-item-input">
                            <component
                                v-bind:is="q.type === 'checkbox' ? 'ui-checkbox-with-text' : 'ui-radio-with-text'"
                                v-bind:name="q.type === 'checkbox' ? null : 'q' + q.id"
                                v-bind:checked="isVariantChecked(q, v, qi, vi)"
                                v-bind:text="v.text"
                                v-on:change="updateSelectedVariant(q, v, qi, vi)"
                            />
                        </div>
                        <div class="page-cabinet-verification-questionary__list-item-variants-item-other"
                            v-if="v.is_other && isVariantChecked(q, v, qi, vi)"
                            >
                            <ui-input-base-text
                                v-bind:value="v.text_other"
                                v-bind:placeholder="'Укажите иное'"
                                v-on:input="updateVariantTextOtherDebounced($event, q, v, qi, vi)"
                                type="text"
                                is-autofocus
                                no-paddings
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-cabinet-verification-questionary__buttons">
            <div class="page-cabinet-verification-questionary__buttons-item"
                v-on:click="submitHandler"
                >
                <ui-button
                    v-bind:disabled="!isValid || isLoading"
                    theme="primary"
                    size="lg"
                    >
                    Далее
                </ui-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mainClient } from '~/network';

export default {
    name: 'page-cabinet-verification-questionary',
    props: {
        isFilled: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        inputTimeout: null,
        isLoading: false,
        isChanged: false,
        questions: [],
    }),
    computed: {
        isValid() {
            return this.questions.every(q => {
                const isOtherVariantsAreValid = q.variants.filter(v => v.is_other).every(v => {
                    const correspondSelectedVariant = q.selectedVariants.find(sv => sv.id === v.id);
                    return v.text_other || correspondSelectedVariant === undefined;
                });
                return q.selectedVariants.length > 0 && isOtherVariantsAreValid;
            });
        },
    },
    methods: {
        async getData() {
            if (this.isLoading) {
                return;
            }
            this.isLoading = true;

            let result = [];
            const questionsRequest = await mainClient.questionary.requests.getQuestions();
            if (questionsRequest.error) {
                console.error(questionsRequest.error, questionsRequest.status);
                console.error('Error above relates to component page-cabinet-verification-questionary getQuestions');
            } else {
                result = questionsRequest.data.results;
                result.forEach(q => {
                    q.variants.forEach(v => {
                        if (v.is_other) {
                            v.text_other = '';
                        }
                    });
                });
            }

            const answersRequest = await mainClient.questionary.requests.getAnswers();
            if (answersRequest.error) {
                console.error(answersRequest.error, answersRequest.status);
                console.error('Error above relates to component page-cabinet-verification-questionary getAnswers');
            } else {
                answersRequest.data.answers.forEach(a => {
                    const correspondQuestion = result.find(q => q.id === a.question);
                    if (correspondQuestion !== undefined) {
                        correspondQuestion.selectedVariants = a.variants.map(v => ({ id: v.variant, text: v.text, answerId: a.id }));
                    }
                });
            }

            result.forEach(q => {
                if (q.selectedVariants === undefined) {
                    q.selectedVariants = [];
                }
                q.variants.forEach(v => {
                    if (v.is_other) {
                        const correspondSelectedVariant = q.selectedVariants.find(sv => sv.id === v.id);
                        if (correspondSelectedVariant !== undefined) {
                            v.text_other = correspondSelectedVariant.text;
                        }
                    }
                });
            });

            this.questions = result;

            this.$nextTick(() => {
                if (this.isValid) {
                    this.$emit('filled', 'questionary');
                }
            });

            this.isLoading = false;
        },
        updateSelectedVariant(question, clickedVariant, questionIndex, clickedVariantIndex) {
            const newSelectedVariant = {
                id: clickedVariant.id,
                text: clickedVariant.is_other ? clickedVariant.text_other : clickedVariant.text,
            };
            if (question.type === 'text' || question.type === 'radio') {
                question.selectedVariants = [ newSelectedVariant ];
            } else {
                const selectedVariantIndex = question.selectedVariants.findIndex(sv => sv.id === clickedVariant.id);
                if (selectedVariantIndex > -1) {
                    question.selectedVariants = question.selectedVariants.filter(sv => sv.id !== clickedVariant.id);
                } else {
                    question.selectedVariants.push(newSelectedVariant);
                }
            }
            this.questions = [ ...this.questions ];
            this.isChanged = true;
        },
        updateVariantTextOtherDebounced(e, question, variantToUpdate, questionIndex, variantToUpdateIndex) {
            if (this.inputTimeout) {
                clearTimeout(this.inputTimeout);
            }
            this.inputTimeout = setTimeout(() => {
                variantToUpdate.text_other = e;
                this.updateVariantTextOther(this.isVariantChecked(question, variantToUpdate, questionIndex, variantToUpdateIndex));
            }, 250);
        },
        updateVariantTextOther(change = false) {
            this.questions = [ ...this.questions ];
            if (change) {
                this.isChanged = true;
            }
        },
        isVariantChecked(question, variant, questionIndex, variantIndex) {
            const correspondSelectedVariant = question.selectedVariants.find(sv => sv.id === variant.id);
            return correspondSelectedVariant !== undefined;
        },
        async submitHandler() {
            if (this.isLoading || !this.isValid) {
                return;
            }
            // console.log('submitHandler questionary');
            if (this.isChanged) {
                this.isLoading = true;
                const result = [];
                this.questions.forEach(q => {
                    result.push({
                        question: q.id,
                        variants: q.selectedVariants.map(sv => {
                            const result = {
                                variant: sv.id,
                            };
                            const correspondVariant = q.variants.find(v => v.id === sv.id);
                            if (correspondVariant.is_other) {
                                result.text = correspondVariant.text_other;
                            }
                            return result;
                        }),
                    });
                });
                const payload = { answers: result };
                const { error, status } = await mainClient.questionary.requests.updateAnswers(payload);
                if (error) {
                    console.error(error, status);
                    console.error('Error above relates to component page-cabinet-verification-questionary submitHandler');
                } else {
                    this.isChanged = false;
                    this.$emit('filled', 'questionary');
                    this.$emit('submitted');
                }
                this.isLoading = false;
            } else {
                this.$emit('submitted');
            }
        },
    },
    mounted() {
        this.getData();
    },
    watch: {
        isChanged(newValue) {
            if (newValue && this.isFilled) {
                this.$emit('unfilled', 'questionary');
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-verification-questionary {
    position: relative;
    &__loader {
        .transition-fade();

        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        background-color: fade(@color-gray-lightest, 50%);
        &-container {
            width: 100px;
            margin: auto;
        }
    }
    &__list {
        margin-bottom: 40px;
        &-item {
            margin-bottom: 32px;
            &-label {
                .typography-heading();

                display: block;
                margin-bottom: 12px;
            }
            &-variants {
                &-item {
                    .typography-body();

                    padding: 5px 0;
                    &-other {
                        margin-top: 32px;
                        max-width: 413px;
                    }
                }
            }
        }
    }
    &__buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: -12px;
        &-item {
            width: 100%;
            max-width: 290px;
            padding: 12px;
        }
    }
    @media @media-md-down {
    }
}
</style>
