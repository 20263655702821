<template>
    <div class="ui-input-base-text">
        <input class="ui-input-base-text__input"
            v-bind:class="{
                _no_placeholder: isFocused && value.toString().length > 0,
                _focused: isFocused,
                _invalid: isInvalid,
                _disabled: isDisabled,
                _with_units: units !== null,
                _filled: !!value,
            }"
            v-bind:value="value"
            v-bind:type="type"
            v-bind:placeholder="placeholder"
            v-bind:autofocus="isAutofocus"
            v-bind:disabled="isDisabled"
            v-on="handler"
            v-on:keydown.enter="inputHandler"
            tabindex="0"
            ref="input"
        />
        <div class="ui-input-base-text__units"
            v-if="units !== null"
            v-text="units"
        />
    </div>
</template>

<script>
export default {
    name: 'ui-input-base-text',
    props: {
        value: {
            type: [ String, Number ],
            default: '',
        },
        type: {
            type: String,
            default: 'text',
        },
        formatter: {
            type: Function,
            default: null,
        },
        placeholder: {
            type: String,
            default: '',
        },
        isAutofocus: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        isInvalid: {
            type: Boolean,
            default: false,
        },
        onInput: {
            type: Function,
            default: null,
        },
        onFocus: {
            type: Function,
            default: null,
        },
        onBlur: {
            type: Function,
            default: null,
        },
        errors: {
            type: Object,
            default: null,
        },
        isLazy: {
            type: Boolean,
            dafault: false,
        },
        units: {
            type: String,
            default: null,
        },
    },
    model: {
        prop: 'value',
        event: 'input',
    },
    data: () => ({
        isFocused: false,
        cursorPosition: null,
    }),
    computed: {
        handler() {
            const result = {
                focus: this.focusHandler,
                blur: this.blurHandler,
            };
            // result.change = this.inputHandler;
            if (this.isLazy) {
                result.change = this.inputHandler;
            } else {
                result.input = this.inputHandler;
            }
            return result;
        },
    },
    methods: {
        focusHandler(e) {
            if (this.onFocus !== null) {
                this.onFocus(e);
            }
            this.$emit('focus', e);
            this.isFocused = true;
        },
        blurHandler(e) {
            if (this.onBlur !== null) {
                this.onBlur(e);
            }
            this.$emit('blur', e);
            this.isFocused = false;
        },
        inputHandler(e) {
            this.cursorPosition = e.target.selectionEnd ?? null;
            if (this.onInput !== null) {
                this.onInput(e);
            }
            if (this.formatter !== null) {
                const formatted = this.formatter(e.target.value, this.cursorPosition);
                if (typeof formatted === 'object' && formatted !== null && formatted.value !== undefined && formatted.position !== undefined) {
                    e.target.value = formatted.value;
                    this.cursorPosition = formatted.position;
                } else {
                    e.target.value = formatted;
                    this.cursorPosition = formatted.length;
                }
            }
            if (e.target.value !== this.value) {
                this.$emit('input', e.target.value);
            }
            if (this.cursorPosition !== null && this.$refs.input) {
                this.$refs.input.setSelectionRange(this.cursorPosition, this.cursorPosition);
            }
        },
    },
    mounted() {
        if (this.isAutofocus) {
            this.$refs.input.focus();
        }
    },
    watch: {
        value() {
            if (this.cursorPosition !== null && this.$refs.input) {
                this.$refs.input.setSelectionRange(this.cursorPosition, this.cursorPosition);
            }
        },
        cursorPosition() {
            if (this.cursorPosition !== null && this.$refs.input) {
                this.$refs.input.setSelectionRange(this.cursorPosition, this.cursorPosition);
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-input-base-text {
    .typography-body();

    position: relative;
    &__input {
        .typography-body();

        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding: 6px 0 3px;
        border-bottom: 1px solid @color-gray-main;
        border-radius: 0;

        color: @color-gray-darker;
        text-decoration: none;

        // background: @color-gray-lightest;
        background: transparent;

        appearance: none;
        resize: none;

        transition-property: border-color, color;
        transition-duration: @duration-fast;
        transition-timing-function: ease-in-out;
        &::placeholder {
            color: @color-gray-main;

            transition: opacity @duration-fast ease-in-out;
        }
        &._no_placeholder::placeholder {
            opacity: 0;
        }
        &:focus {
            border-color: @color-primary;
        }
        &._positive {
            border-color: @color-positive;

            color: @color-positive;
        }
        &._invalid {
            border-color: @color-accent-negative;
        }
        &._with_units {
            padding-right: 30px;
        }
        &._disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }
    &__units {
        position: absolute;
        right: 0;
        top: 5px;

        color: @color-gray-main;

        transition-property: color;
        transition-duration: @duration-fast;
        transition-timing-function: ease-in-out;
    }
    &__input._filled + &__units {
        color: @color-gray-darkest;
    }
    &__input:focus + &__units,
    &__input._focused + &__units {
        color: @color-primary;
    }
    &__input._invalid + &__units {
        color: @color-accent-negative;
    }
}
</style>
