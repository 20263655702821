<template>
    <div class="modal-status-form-estate">
        <div class="modal-status-form-estate__title"
            v-text="'Estate ID: ' + estateId"
        />
        <div class="modal-status-form-estate__item">
            <ui-input-base-select
                v-bind:options="options"
                v-model="estateId"
            />
        </div>
        <div class="modal-status-form-estate__item"
            v-if="getFormDataError"
            >
            ERROR: estate with ID {{ estateId }} not found
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'modal-status-form-estate',
    data: () => ({
        estateId: '',
    }),
    computed: {
        ...mapState('status', [
            'givenEstate',
            'getFormDataIsLoading',
            'getFormDataResult',
            'getFormDataError',

            'createFormDataIsLoading',
            'createFormDataResult',
            'createFormDataError',
        ]),
        ...mapState('estates', [
            'getItemsIsLoading',
            'getItemsResult',
            'getItemsError',
        ]),
        options() {
            if (!this.$store.getters['estates/items']) {
                return [];
            }
            return this.$store.getters['estates/items'].map((item) => ({
                value: item.id,
                text: item.title,
            }));
        },
    },
    beforeMount() {
        if (this.givenEstate) {
            this.estateId = this.givenEstate;
        } else {
            this.$store.dispatch('estates/getItems');
        }
    },
    watch: {
        estateId: {
            handler(value) {
                if (value) {
                    this.$store.dispatch('status/getFormData', {
                        id: value,
                        onSuccess: () => {
                            this.$store.state.status.estateId = this.estateId;
                        },
                    });
                }
            },
            immediate: true,
        },
        getFormDataError(value) {
            if (value) {
                this.$store.dispatch('status/createFormData', { id: this.estateId });
            }
        },
        createFormDataResult(value) {
            if (value) {
                this.$store.dispatch('status/getFormData', {
                    id: this.estateId,
                    onSuccess: () => {
                        this.$store.state.status.estateId = this.estateId;
                    },
                });
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-status-form-estate {
    &__title {
        .typography-heading();
    }
    &__item {
        position: relative;
        z-index: 1;

        margin-top: 16px;
    }
}
</style>
