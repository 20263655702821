<template>
    <component class="ui-trigger"
        v-bind:is="component"
        v-bind:href="href ? href : null"
        v-bind:target="href ? '_blank' : null"
        v-bind:type="href ? null : type"
        v-bind:disabled="isDisabled || isLoading"
        v-bind:class="[
            `_theme_${theme}`,
            {
                _underlined: isUnderlined,
                _inlined: isInlined,
                _loading: isLoading,
                _disabled: isDisabled,
                _inherited: isInherited,
                _breakable: isBreakable,
                _uppercase: isUppercase,
                [`_size_${size}`]: size,
            },
        ]"
        v-on:click="(href || isDisabled || isLoading) ? null : $emit('click')"
        >
        <transition name="fade">
            <div class="ui-trigger__loader"
                v-if="isLoading"
                >
                <ui-loader is-mini />
            </div>
        </transition>
        <div class="ui-trigger__icon _left"
             v-if="iconLeft"
            >
            <icon v-bind:name="iconLeft" />
        </div>
        <div class="ui-trigger__text"
            v-if="text !== null"
            v-text="text"
        />
        <div class="ui-trigger__text"
            v-else-if="$slots.default"
            >
            <slot />
        </div>
        <div class="ui-trigger__icon _right"
             v-if="iconRight"
            >
            <icon v-bind:name="iconRight" />
        </div>
    </component>
</template>

<script>
export default {
    name: 'ui-trigger',
    props: {
        text: {
            type: String,
            default: null,
        },
        href: {
            type: String,
            default: null,
        },
        type: {
            type: String,
            default: null,
        },
        iconLeft: {
            type: String,
            default: null,
        },
        iconRight: {
            type: String,
            default: null,
        },
        theme: {
            type: String,
            default: 'primary',
        },
        isUnderlined: {
            type: Boolean,
            default: false,
        },
        isInlined: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        isInherited: {
            type: Boolean,
            default: false,
        },
        isBreakable: {
            type: Boolean,
            default: false,
        },
        isUppercase: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: 'md',
        },
    },
    computed: {
        component() {
            if (this.href) {
                return 'a';
            }
            if (this.type === 'button' || this.type === 'submit') {
                return 'button';
            }
            return 'div';
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-trigger {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%;
    min-height: 100%;
    border: none;
    outline: none;

    text-align: center;
    text-decoration: none;
    white-space: nowrap;

    background-color: transparent;

    appearance: none;
    user-select: none;
    cursor: pointer;

    transition:
        opacity @duration-faster ease-in-out,
        color @duration-faster ease-in-out;
    &[disabled] {
        pointer-events: none;
    }
    &._uppercase {
        .typography-caption();

        font-weight: bold;
        text-transform: uppercase;
    }
    &._breakable {
        white-space: normal;
    }
    &__loader {
        .transition-fade();

        position: absolute;
        top: 50%;
        left: 50%;

        width: 16px;
        height: 16px;

        transform: translate(-50%, -50%);

        transition: opacity @duration-faster ease-in-out;
    }
    &__text {
        .typography-body();

        flex: 0 1 auto;

        transition: opacity @duration-faster ease-in-out;
    }
    &._size_xs &__text,
    &._size_xxs &__text {
        .typography-caption();
    }
    &__icon {
        flex: 0 0 auto;

        font-size: 1.6rem;

        transition: opacity @duration-faster ease-in-out;
    }
    &__icon._left + &__text {
        margin-left: 8px;
    }
    &__text + &__icon._right {
        margin-left: 8px;
    }
    &._loading &__text,
    &._loading &__icon {
        opacity: 0;
    }
    &._inlined {
        display: inline-flex;
    }
    &._underlined {
        .typography-body();

        font-weight: normal;
        line-height: 2rem;
        text-decoration: underline;
        text-transform: none;
    }
    &._theme_primary {
        color: @color-primary;
    }
    &._theme_secondary {
        color: @color-gray-darkest;
    }
    &._theme_danger {
        color: @color-accent-negative;
    }
    &._disabled {
        color: @color-gray-main;

        pointer-events: none;
    }
    &._inherited {
        color: inherit;
    }
    &:hover:not(.ui-button &) {
        opacity: 0.8;
    }
    &:active:not(.ui-button &) {
        opacity: 0.9;
    }
}

</style>
