<template>
    <section class="plugin-home-promo"
        v-bind:style="backgroundStyle"
        >
        <div class="plugin-home-promo__container">
            <div class="plugin-home-promo__wrapper">
                <div class="plugin-home-promo__title"
                    v-text="title"
                />
                <div class="plugin-home-promo__text">
                    <div class="common-ckeditor"
                        v-html="text"
                    />
                </div>
                <div class="plugin-home-promo__button">
                    <div class="plugin-home-promo__button-link">
                        <ui-button
                            v-bind:href="buttonUrl"
                            icon-right="arrow-right"
                            theme="primary"
                            size="lg"
                            >
                            Зарегистрироваться
                        </ui-button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import config from '~/config';
import utils from '~/utils';

export default {
    name: 'plugin-home-promo',
    props: {
        title: {
            type: String,
            default: '',
        },
        text: {
            type: String,
            default: '',
        },
        image: {
            type: String,
            default: '',
        },
    },
    computed: {
        user() {
            return this.$store.getters['users/user'];
        },
        isAuthorized() {
            return this.$store.getters['users/isAuthorized'];
        },
        backgroundStyle() {
            if (!this.image) {
                return null;
            }
            const resize = utils.common.getResize();
            return { backgroundImage: `url(${utils.common.injectResize(this.image, resize)})` };
        },
        buttonUrl() {
            if (this.isAuthorized) {
                return config.urls.cabinet.dashboard;
            }
            return config.urls.auth.registration;
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-home-promo {
    background-color: @color-gray-main;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    &__container {
        .container();
    }
    &__wrapper {
        min-height: 667px;
        padding: 120px calc(@grid-gap + @grid-column);
    }
    &__title {
        .typography-extra-large();

        max-width: 668px;
        margin-bottom: 24px;
    }
    &__text {
        .typography-small();

        max-width: 668px;
        margin-bottom: 60px;
    }
    &__button {
        display: flex;
        justify-content: flex-start;
        &-link {
            display: block;

            text-decoration: none;
        }
    }
    @media @media-md-down {
        padding: 0;

        &__wrapper {
            min-height: 0;
            padding: 60px 0;
        }
        &__title {
            .typography-large();

            margin-bottom: 24px;

            line-height: 3.4rem;
            text-align: center;
        }
        &__text {
            .typography-body();

            margin-bottom: 60px;

            text-align: center;
        }
        &__button {
            justify-content: center;
            &-link {
                width: 100%;
                max-width: 320px;
            }
        }
    }
}
</style>
