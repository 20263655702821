<template>
    <div>
        plugin-text-button-image
    </div>
</template>

<script>
export default {
    name: 'plugin-text-button-image',
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-text-button-image {
    position: relative;

    margin-bottom: 60px;

    &__illustration {
        position: absolute;
        top: 60px;
        right: 0;
        bottom: -60px;
        left: calc(50% + @grid-gap-half);

        background-color: @color-gray-main;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    &__card {
        position: relative;

        width: calc(@grid-column * 7 + @grid-gap * 6);
        padding: 80px calc(@grid-column + @grid-gap);
        color: @color-gray-lightest;

        background-color: @color-gray-darker;
        &-button {
            margin-top: 40px;
            &-link {
                display: inline-block;

                text-decoration: none;
            }
        }
    }
}
</style>
