<template>
    <div class="ui-table-cell-status"
        v-bind:class="{
            _positive: status === 'accepted',
            _negative: status === 'rejected',
            _neutral: status === 'delayed',
            _pending: status === 'pending',
        }"
        >
        <div class="ui-table-cell-status__indicator" />
        <div class="ui-table-cell-status__title"
            v-text="statusText"
        />
    </div>
</template>

<script>
export default {
    name: 'ui-table-cell-status',
    props: {
        status: {
            type: String,
            default: '',
        },
    },
    computed: {
        statusText() {
            if (this.status === 'accepted') {
                return 'Одобрено';
            }
            if (this.status === 'rejected') {
                return 'Отказано';
            }
            if (this.status === 'delayed') {
                return 'Отложено';
            }
            if (this.status === 'pending') {
                return 'Ожидает';
            }
            return 'Обрабатывается';
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-table-cell-status {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    &._positive {
        color: @color-positive;
    }
    &._neutral {
        color: @color-accent-neutral;
    }
    &._negative {
        color: @color-accent-negative;
    }
    &._pending {
        color: @color-gray-main;
    }
    &__indicator {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 8px;

        background-color: currentColor;
    }
    &__title {
        .typography-caption();
    }
}
</style>
