<template>
    <div class="modal-pre-invest">
        <transition name="fade">
            <div class="modal-pre-invest__loader"
                v-if="getRequestsIsLoading"
                >
                <div class="modal-pre-invest__loader-container">
                    <ui-loader />
                </div>
            </div>
        </transition>
        <div class="modal-pre-invest__sign">
            <icon name="warning" />
        </div>
        <div class="modal-pre-invest__text">
            Пожалуйста, укажите, сколько Вы суммарно проинвестировали через все инвестиционные платформы в этом году. <ui-hint text="Согласно закону №259-ФЗ «О привлечении инвестиций с использованием инвестиционных платформ» неквалифицированные инвесторы и физические лица, не являющиеся индивидуальными предпринимателями, могут инвестировать через инвестиционные платформы суммарно не более 600 000 рублей в год" />
        </div>
        <div class="modal-pre-invest__form">
            <div class="modal-pre-invest__form-fields">
                <ui-form
                    v-bind:model="fields"
                    v-bind:validation="$v"
                    v-bind:submit-handler="submitHandler"
                    v-on:update="updateFormData"
                />
                <div class="modal-pre-invest__form-fields-checkbox">
                    <ui-input-checkbox
                        v-model="policy.value"
                        v-bind:model="policy"
                        v-bind:validation="$v.policy.value"
                    />
                </div>
            </div>
            <div class="modal-pre-invest__form-buttons">
                <div class="modal-pre-invest__form-buttons-item">
                    <ui-button
                        v-on:click="submitHandler"
                        v-bind:disabled="$v.$invalid"
                        theme="primary"
                        >
                        Отправить
                    </ui-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import config from '~/config';
import forms from '~/forms';

export default {
    name: 'modal-pre-invest',
    props: {
        estateId: {
            type: [ Number, String ],
            default: null,
        },
    },
    data: () => ({
        availableInvestment: 0,
        fields: {
            currentYearInvestments: { ...forms.fields.currentYearInvestments },
        },
        policy: { ...forms.fields.annualInvestmentPolicy },
    }),
    validations() {
        return {
            fields: {
                currentYearInvestments: {
                    value: {
                        ...forms.validations.currentYearInvestments.value,
                        isValidSEInvestments: (value) => value >= this.amountAlreadyInvested,
                    },
                },
            },
            policy: {
                value: {
                    isValid: (value) => value,
                },
            },
        };
    },
    computed: {
        ...mapState('investments', [
            'getRequestsResult',
            'getRequestsIsLoading',
            'getRequestsError',
        ]),
        amountAlreadyInvested() {
            if (!this.getRequestsResult) {
                return 0;
            }
            return this.getRequestsResult.reduce((acc, curr) => acc + parseFloat(curr.amount), 0);
        },
    },
    methods: {
        updateFormData(newData) {
            this.$v.$touch();
            this.fields = {
                ...this.fields,
                ...newData,
            };
        },
        close() {
            this.$store.commit('modals/pop');
        },
        submitHandler() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.availableInvestment = config.common.annualInvestmentLimit - parseFloat(this.fields.currentYearInvestments.value);
            this.close();
        },
    },
    mounted() {
        this.$store.dispatch('investments/getRequests', {
            status__in: 'completed',
            created_after: new Date().getFullYear() + '-01-01T00:00:00.000000',
        });
    },
    beforeDestroy() {
        if (this.availableInvestment > 0) {
            this.$store.commit('modals/push', {
                name: 'invest',
                props: {
                    availableInvestment: this.availableInvestment,
                    estateId: this.estateId,
                },
            });
        } else {
            this.$store.commit('modals/push', {
                name: 'generic',
                props: {
                    title: 'Действие недоступно',
                    text: 'К сожалению, не обладая статусом ИП или КИ, в данный момент вы не можете делать новые инвестиционные вложения',
                },
            });
        }
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-pre-invest {
    .modal();

    position: relative;
    &__loader {
        .transition-fade();

        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        background-color: @color-gray-lightest;
        &-container {
            width: 50px;
        }
    }
    &__sign {
        width: fit-content;
        margin: 0 auto 16px;

        color: @color-primary;
        font-size: 3.2rem;
    }
    &__text {
        .typography-body();

        margin-bottom: 24px;
    }
    &__form {
        &-fields {
            &-checkbox {
                margin-top: 24px;
                margin-bottom: 40px;
            }
        }
        &-buttons {
            &-item {
                width: 207px;
                margin: auto;
            }
        }
    }
}
</style>
