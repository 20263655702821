<template>
    <section class="plugin-home-estates">
        <div class="plugin-home-estates__container">
            <div class="plugin-home-estates__wrapper">
                <h2 class="plugin-home-estates__title"
                    v-text="title"
                />
                <div class="plugin-home-estates__columns">
                    <div class="plugin-home-estates__columns-item">
                        <div class="plugin-home-estates__item"
                            v-for="(item, index) in items.filter((_, i) => i % 2 !== 0)"
                            v-bind:key="index"
                            >
                            <plugin-home-estates-item
                                v-bind:item="item"
                            />
                        </div>
                    </div>
                    <div class="plugin-home-estates__columns-spacer">
                    </div>
                    <div class="plugin-home-estates__columns-item">
                        <div class="plugin-home-estates__item"
                            v-for="(item, index) in items.filter((_, i) => i % 2 === 0)"
                            v-bind:key="index"
                            >
                            <plugin-home-estates-item
                                v-bind:item="item"
                            />
                        </div>
                    </div>
                </div>
                <div class="plugin-home-estates__slider">
                    <div class="swiper-container"
                        ref="container"
                        >
                        <div class="swiper-wrapper">
                            <div class="swiper-slide"
                                v-for="(item, index) in items"
                                v-bind:key="index"
                                >
                                <div class="plugin-home-estates__item">
                                    <plugin-home-estates-item
                                        v-bind:item="item"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="plugin-home-estates__slider-pagination"
                            ref="pagination"
                            >
                        </div>
                    </div>
                </div>
                <div class="plugin-home-estates__button">
                    <ui-button
                        v-bind:href="buttonUrl"
                        v-bind:text="buttonText"
                        theme="inverted"
                        size="lg"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Swiper from 'swiper/dist/js/swiper';
import utils from '~/utils';

export default {
    name: 'plugin-home-estates',
    props: {
        title: {
            type: String,
            default: '',
        },
        buttonText: {
            type: String,
            default: '',
        },
        buttonUrl: {
            type: String,
            default: '',
        },
        items: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        slider: null,
    }),
    methods: {
        checkScreen() {
            if (window.innerWidth < 1024) {
                if (this.slider === null) {
                    this.initSlider();
                }
            } else {
                if (this.slider !== null) {
                    this.slider.destroy();
                    this.slider = null;
                }
            }
        },
        initSlider() {
            const container = this.$refs.container;
            const pagination = this.$refs.pagination;

            this.slider = new Swiper(container, {
                speed: 600,
                slidesPerView: 'auto',
                spaceBetween: 16,
                watchSlidesVisibility: true,
                pagination: {
                    el: pagination,
                },
            });
        },
    },
    mounted() {
        this.checkScreen();
        utils.dom.addLoadedCallback(this.checkScreen);
        window.addEventListener('resize', this.checkScreen);
    },
    beforeDestroy() {
        utils.dom.removeLoadedCallback(this.checkScreen);
        window.removeEventListener('resize', this.checkScreen);
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-home-estates {
    color: @color-gray-lightest;

    background-color: @color-gray-darker;
    &__container {
        .container();
    }
    &__wrapper {
        padding: 120px calc(@grid-gap + @grid-column);
    }
    &__title {
        .typography-extra-large();

        width: 50%;
        margin: 0;
    }
    &__columns {
        display: flex;
        &-item {
            flex: 1 1 100%;
            &:first-child {
                padding-top: 80px;
            }
        }
        &-spacer {
            flex: 0 0 auto;
            width: 11.5%;
        }
    }
    &__slider {
        display: none;
    }
    &__item {
        margin-bottom: 60px;
    }
    &__button {
        width: fit-content;
        margin: 0 auto;
    }
    @media @media-md-down {
        &__container {
            padding: 0;
        }
        &__wrapper {
            padding: 63px 0 60px;
        }
        &__title {
            .container();
            .typography-large();

            margin-bottom: 23px;

            line-height: 3.4rem;
        }
        &__columns {
            display: none;
        }
        &__slider {
            display: block;
            .swiper-container {
                .container();
                .swiper-wrapper {
                    .swiper-slide {
                        width: auto;
                    }
                }
            }
            &-pagination {
                .container();

                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                margin-top: 40px;
                margin-bottom: 40px;
                /deep/ .swiper-pagination-bullet {
                    background-color: @color-gray-lightest;

                    opacity: 0.4;

                    transition: opacity @duration-fast ease-in-out;
                    &-active {
                        opacity: 1;
                    }
                }
            }
        }
        &__item {
            margin-bottom: 0;
            width: 480px;
        }
        &__button {
            width: 100%;
            max-width: 320px;
        }
    }
    @media @media-sm-down {
        &__item {
            width: 288px;
        }
    }
}
</style>
