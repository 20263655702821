<template>
    <div class="plugin-about-contacts">
        <div class="plugin-about-contacts__info">
            <div class="plugin-about-contacts__info-title"
                v-if="title"
                v-text="title"
            />
            <div class="plugin-about-contacts__info-items">
                <slot />
                <plugin-about-contacts-item
                    v-bind:url="urls.telegram"
                    text="Мы в Telegram"
                />
                <!-- <div class="plugin-about-contacts__info-socials">
                    <a class="plugin-about-contacts__info-socials-item"
                        v-bind:href="urls.facebook"
                        target="_blank"
                        >
                        <icon name="facebook" />
                    </a>
                    <a class="plugin-about-contacts__info-socials-item"
                        v-bind:href="urls.instagram"
                        target="_blank"
                        >
                        <icon name="instagram" />
                    </a>
                    <a class="plugin-about-contacts__info-socials-item"
                        v-bind:href="urls.telegram"
                        target="_blank"
                        >
                        <icon name="telegram" />
                    </a>
                </div> -->
                <div class="plugin-about-contacts__info-button"
                    v-if="buttonText && buttonUrl"
                    >
                    <ui-button
                        v-bind:href="buttonUrl"
                        v-bind:text="buttonText"
                    />
                </div>
            </div>
        </div>
        <div class="plugin-about-contacts__map"
            v-html="mapCode"
        />
    </div>
</template>

<script>
export default {
    name: 'plugin-about-contacts',
    props: {
        title: {
            type: String,
            default: '',
        },
        buttonText: {
            type: String,
            default: '',
        },
        buttonUrl: {
            type: String,
            default: '',
        },
        mapCode: {
            type: String,
            default: '',
        },
    },
    computed: {
        contacts() {
            return this.$store.getters['site/contacts'];
        },
        urls() {
            return {
                // facebook: this.$store.state.site.contacts.socials.facebook.href,
                // instagram: this.$store.state.site.contacts.socials.instagram.href,
                telegram: this.$store.state.site.contacts.socials.telegram.href,
            };
        },
    },
};
</script>

<style lang="less">
@import '~theme';

.plugin-about-contacts {
    .container();
    .container-paddings();

    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    min-height: 480px;
    &__info {
        flex: 1 1 auto;
        width: calc(@grid-column * 6 + @grid-gap * 6);
        padding: 80px calc(@grid-column + @grid-gap);

        background-color: @color-gray-lighter;
        background-position: right bottom;
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-image: url('/static/images/plugin-about-contacts-bg.jpg');
        &-title {
            .typography-extra-large();

            margin-bottom: 40px;
        }
        // &-socials {
        //     display: flex;
        //     align-items: center;
        //     justify-content: flex-start;
        //     margin: 4px -12px -12px;
        //     &-item {
        //         flex: 0 0 auto;
        //         padding: 6px;
        //         margin: 6px;

        //         font-size: 2.4rem;
        //     }
        // }
        &-button {
            margin-top: 32px;
            min-width: 186px;
            width: fit-content;
        }
    }
    &__map {
        flex: 1 1 auto;
        width: calc(@grid-column * 6 + @grid-gap * 5);

        filter: grayscale(1);
        & > iframe {
            width: 100% !important;
            height: 100% !important;
        }
    }
    @media @media-md-down {
        display: block;
        &__info {
            width: 100%;
            &-button {
                width: 100%;
            }
        }
        &__map {
            width: auto;
            margin: 0 -@container-padding-mobile;
            height: 66vw;
        }
    }
    @media @media-sm-down {
        display: block;
        &__info {
            width: auto;
            min-height: 360px;
            padding: 40px @container-padding-mobile;
            margin: 0 -@container-padding-mobile;

            background-image: url('/static/images/plugin-about-contacts-bg-mobile.jpg');
            &-title {
                .typography-large();

                margin-bottom: 40px;
            }
        }
        &__map {
            height: 100vw;
        }
    }
}
</style>
