<template>
    <div>
        plugin-card-structure
    </div>
</template>

<script>
import utils from '~/utils';

export default {
    name: 'plugin-card-structure',
    props: {
        items: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        currency() {
            return this.$store.getters['site/currency'].name.short;
        },
        total() {
            return this.items.reduce((total, current) => total + current.value, 0);
        },
    },
    methods: {
        getFormattedNumber(number) {
            return utils.formats.formatNumberWithSpaces(number);
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-card-structure {
    .typography-body();

    &__total {
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        width: 100%;
        margin-bottom: 17px;
        &-amount {
            .typography-medium();

            margin-right: 4px;
        }
        &-units {
            color: @color-gray-main;
        }
    }
    &__list {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        width: 100%;
        &-item {
            display: flex;
            justify-content: flex-start;
            align-items: stretch;
            &-percent {
                .typography-medium();

                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 70px;
                width: 136px;
                margin-right: @grid-gap;

                color: @color-gray-lightest;
                text-align: center;

                background-color: @color-primary;
            }
            &:nth-child(even) &-percent {
                background-color: @color-gray-darker;
            }
            &-info {
                .typography-caption();

                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: stretch;
                width: 100%;
                &-value {
                    display: flex;
                    justify-content: flex-start;
                    align-items: baseline;
                    &-amount {
                        .typography-small();

                        margin-right: 2px;
                    }
                    &-units {
                        color: @color-gray-main;
                    }
                }
            }
        }
    }
}
</style>
