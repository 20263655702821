import { render, staticRenderFns } from "./info-activity.vue?vue&type=template&id=952a8e82&scoped=true&"
import script from "./info-activity.vue?vue&type=script&lang=js&"
export * from "./info-activity.vue?vue&type=script&lang=js&"
import style0 from "./info-activity.vue?vue&type=style&index=0&id=952a8e82&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "952a8e82",
  null
  
)

export default component.exports