<template>
    <div class="modal-market-application-confirm-success">
        <div class="modal-market-application-confirm-success__title">
            Ваша заявка успешно подтверждена
        </div>
        <template v-if="isFinal">
            <template v-if="deal.type === 'buy'">
                <div class="modal-market-application-confirm-success__subtitle">
                    Сделка подписана, теперь Вам нужно открыть специальный счет, ссылка на инструкцию приведена ниже
                </div>
                <div class="modal-market-application-confirm-success__warning">
                    <div class="modal-market-application-confirm-success__warning-sign">
                        <icon name="warning" />
                    </div>
                    <div class="modal-market-application-confirm-success__warning-text">
                        Уважаемый покупатель! Обращаем Ваше внимание, что открыть лицевой счет у реестродержателя АО "Статус" необходимо в течение 5-ти рабочих дней после заключения сделки (п. 4.2 Договора).
                        <br />
                        <br />
                        В случае невыполнения данного требования, применяются штрафные санкции (п. 5.2 договора). Просим внимательно и ответственно отнестись к данному сроку!
                    </div>
                </div>
                <div class="modal-market-application-confirm-success__button">
                    <ui-button v-bind:href="'/static/docs/guide_buy.pdf'">
                        Инструкция
                    </ui-button>
                </div>
                <!-- <div class="modal-market-application-confirm-success__button">
                    <ui-button v-on:click="openStatusModal">
                        Открыть счет
                    </ui-button>
                </div> -->
            </template>
            <template v-else>
                <div class="modal-market-application-confirm-success__subtitle">
                    Сделка подписана, теперь Вам нужно перевести акции на счет покупателя, ссылка на инструкцию приведена ниже
                </div>
                <div class="modal-market-application-confirm-success__warning">
                    <div class="modal-market-application-confirm-success__warning-sign">
                        <icon name="warning" />
                    </div>
                    <div class="modal-market-application-confirm-success__warning-text">
                        Уважаемый продавец! Обращаем Ваше внимание, что оформить распоряжение на перевод акций Покупателю необходимо в течение 5-ти рабочих дней после заключения сделки (п. 4.3 Договора).
                        <br />
                        <br />
                        В случае невыполнения данного требования, применяются штрафные санкции (п. 5.1 договора). Просим внимательно и ответственно отнестись к данному сроку!
                    </div>
                </div>
                <div class="modal-market-application-confirm-success__button">
                    <ui-button v-bind:href="'/static/docs/guide_sell.pdf'">
                        Инструкция
                    </ui-button>
                </div>
            </template>
        </template>
        <div class="modal-market-application-confirm-success__subtitle"
            v-else
            >
            Ожидайте подтверждения от второй стороны
        </div>
    </div>
</template>

<script>
export default {
    name: 'modal-market-application-confirm-success',
    props: {
        isFinal: {
            type: Boolean,
            default: false,
        },
        deal: {
            type: Object,
        },
    },
    methods: {
        openStatusModal() {
            this.$store.commit('modals/pop');
            this.$store.commit('modals/push', {
                name: 'status-form',
                props: {
                    estate: this.deal.estate,
                },
            });
        },
    },
};
</script>

<style lang="less">
@import '~theme';

.modal-market-application-confirm-success {
    max-width: 640px;
    padding-top: 40px;
    margin-left: auto;
    margin-right: auto;
    &__title {
        .typography-large();

        margin-bottom: 8px;
    }
    &__subtitle {
        .typography-small();
    }
    &__warning {
        margin-top: 24px;
        &-sign {
            margin: 0 auto 16px;
            width: fit-content;

            color: @color-primary;
            font-size: 3.2rem;
        }
        &-text {
            .typography-small();

            padding-left: 16px;
            border-left: 2px solid @color-primary;
        }
    }
    &__button {
        width: fit-content;
        margin: 40px auto 24px;
    }
    @media @media-sm-down {
        &__title {
            .typography-medium();
        }
    }
}
</style>
