<template>
    <div class="modal-sms-input">
        <div class="modal-sms-input__loader"
            v-if="isLoading"
            >
            <div class="modal-sms-input__loader-container">
                <ui-loader />
            </div>
        </div>
        <div class="modal-sms-input__title">
            Подтверждение
        </div>
        <div class="modal-sms-input__verification">
            <common-sms-verification
                v-bind="smsVerificationProps"
                v-on:submit="onSubmit"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'modal-sms-input',
    props: {
        smsVerificationProps: {
            type: Object,
            default: null,
        },
    },
    computed: {
        ...mapState('sms', {
            getCodeIsLoading: state => state.getCodeIsLoading,
            checkCodeIsLoading: state => state.checkCodeIsLoading,
        }),
        isLoading() {
            return this.getCodeIsLoading || this.checkCodeIsLoading;
        },
    },
    methods: {
        onSubmit(code) {
            this.$emit('submit', code);
            this.$store.commit('modals/pop');
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-sms-input {
    .modal();

    position: relative;
    &__loader {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        display: flex;
        width: 100%;
        height: 100%;

        background: fade(@color-gray-lightest, 50%);
        &-container {
            width: 100px;
            margin: auto;
        }
    }
    &__title {
        .typography-large();

        margin-bottom: 8px;

        text-align: center;
    }
    &__input {
        width: fit-content;
        margin: 0 auto 40px;
    }
    &__error {
        .typography-body();

        margin: 16px 0 40px;

        color: @color-accent-negative;
        text-align: center;
    }
    &__button {
        width: 205px;
        margin: 0 auto 24px;
    }
    &__help {
        .typography-body();

        text-align: center;
        &-trigger {
            cursor: pointer;

            color: @color-primary;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
        &-timer {
            display: inline-block;
            min-width: 40px;

            color: @color-primary;
            text-align: left;
        }
    }
    @media @media-sm-down {
        padding: 16px 16px 24px;
    }
}
</style>
