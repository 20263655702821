<template>
    <div class="ui-dots-separator">
        <div class="ui-dots-separator__filler" />
        <div class="ui-dots-separator__text"
            v-if="text !== null"
            v-text="text"
        />
        <div class="ui-dots-separator__filler" />
    </div>
</template>

<script>
export default {
    name: 'ui-dots-separator',
    props: {
        text: {
            type: String,
            default: null,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-dots-separator {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    &__filler {
        flex: 1 1 auto;
        height: 2px;

        background-image: linear-gradient(to right, transparent 0, transparent 6px, @color-gray-main 6px, @color-gray-main 8px);
        background-size: 8px;
        background-position: right;
        background-repeat: repeat-x;
        &:last-child {
            background-position: left;
        }
    }
    &__text {
        .typography-body();

        margin: 0 24px;
    }
}
</style>
