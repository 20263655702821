import config from '~/config';
import { mainClient } from '~/network';
import utils from '../../utils';

const moduleTitle = 'users';

const state = {
    user: null,
    isLoading: false,

    getUserProfileResult: null,
    getUserProfileIsLoading: false,
    getUserProfileError: null,

    updateUserProfileResult: null,
    updateUserProfileIsLoading: false,
    updateUserProfileError: null,

    getUserPersonalInfoResult: null,
    getUserPersonalInfoIsLoading: false,
    getUserPersonalInfoError: null,

    updateUserPersonalInfoResult: null,
    updateUserPersonalInfoIsLoading: false,
    updateUserPersonalInfoError: null,

    getUserJuridicalInfoResult: null,
    getUserJuridicalInfoIsLoading: false,
    getUserJuridicalInfoError: null,

    updateUserJuridicalInfoResult: null,
    updateUserJuridicalInfoIsLoading: false,
    updateUserJuridicalInfoError: null,

    getUserAdditionalInfoResult: null,
    getUserAdditionalInfoIsLoading: false,
    getUserAdditionalInfoError: null,

    updateUserAdditionalInfoResult: null,
    updateUserAdditionalInfoIsLoading: false,
    updateUserAdditionalInfoError: null,

    getUserMigrationInfoResult: null,
    getUserMigrationInfoIsLoading: false,
    getUserMigrationInfoError: null,

    updateUserMigrationInfoResult: null,
    updateUserMigrationInfoIsLoading: false,
    updateUserMigrationInfoError: null,

    passwordIsLoading: false,
    passwordError: null,

    getUserPhotosResult: null,
    getUserPhotosIsLoading: false,
    getUserPhotosError: null,

    updateUserPhotoResult: null,
    updateUserPhotoIsLoading: false,
    updateUserPhotoError: null,

    registrationResult: null,
    registrationIsLoading: false,
    registrationError: null,

    loginResult: null,
    loginIsLoading: false,
    loginError: null,

    resetPasswordResult: null,
    resetPasswordIsLoading: false,
    resetPasswordError: null,

    getAnnouncementsResult: null,
    getAnnouncementsIsLoading: false,
    getAnnouncementsError: null,

    getTwoFactorResult: null,
    getTwoFactorIsLoading: false,
    getTwoFactorError: null,

    enableTwoFactorResult: null,
    enableTwoFactorIsLoading: false,
    enableTwoFactorError: null,

    disableTwoFactorResult: null,
    disableTwoFactorIsLoading: false,
    disableTwoFactorError: null,

    verificationResetResult: null,
    verificationResetIsLoading: false,
    verificationResetError: null,
};

const getters = {
    user(state) {
        return state.user;
    },
    profile(state) {
        return state.profile;
    },
    profileFull(state) {
        if (state.profile === null ||
            state.personalInfo === null ||
            state.juridicalInfo === null ||
            state.migrationInfo === null ||
            state.additionalInfo === null) {
            return null;
        }
        return {
            ...state.profile,
            ...state.personalInfo,
            ...state.juridicalInfo,
            ...state.migrationInfo,
            ...state.additionalInfo,
        };
    },
    profileFullIsLoading(state) {
        return state.profileIsLoading ||
            state.personalInfoIsLoading ||
            state.juridicalInfoIsLoading ||
            state.migrationInfoIsLoading ||
            state.additionalInfoIsLoading;
    },
    profileIsLoading(state) {
        return state.profileIsLoading;
    },
    isAuthorized(state) {
        return state.user !== null && state.user !== 'guest';
    },
    isVerified(state) {
        return state.user !== null && state.user.status === 'verified';
    },
    passwordIsLoading(state) {
        return state.passwordIsLoading;
    },
    passwordError(state) {
        return state.passwordError;
    },
};

const actions = {
    async getUser({ state }) {
        if (state.isLoading) {
            return;
        }

        state.isLoading = true;
        const { data, error } = await mainClient[moduleTitle].requests.getUser();
        if (error) {
            console.error(`No current user found, classified as a Guest`);
            state.user = 'guest';
        } else if (data) {
            state.user = data;
        }
        state.isLoading = false;
    },

    async updateCurrent({ state }, payload) {
        if (state.isLoading) {
            return;
        }

        state.isLoading = true;
        const { data, error, status } = await mainClient[moduleTitle].requests.updateUser(payload);
        if (error) {
            console.error(`Error in action ${moduleTitle}/updateCurrent (${status})`);
            state.user = null;
        } else if (data) {
            state.user = data;
        }
        state.isLoading = false;
    },

    async updatePassword({ state }, payload) {
        if (state.passwordIsLoading) {
            return;
        }

        state.passwordIsLoading = true;
        state.passwordError = null;
        const { error, status } = await mainClient[moduleTitle].requests.updatePassword(payload);
        if (error) {
            console.error(`Error in action ${moduleTitle}/updatePassword (${status})`);
            state.passwordError = 'Текущий пароль указан неверно';
        }
        state.passwordIsLoading = false;
    },

    async getUserProfile({ state }, { onSuccess = null } = {}) {
        if (state.getUserProfileIsLoading) {
            return;
        }

        state.getUserProfileIsLoading = true;

        const { data, error, status } = await mainClient.users.requests.getUserProfile();
        if (error) {
            console.error(`Error in action ${moduleTitle}/getUserProfile (${status})`);
        } else {
            state.getUserProfileResult = data;
            if (onSuccess) {
                onSuccess(data);
            }
        }
        state.getUserProfileIsLoading = false;
    },
    async updateUserProfile({ state }, { onSuccess = null, ...payload }) {
        if (state.updateUserProfileIsLoading) {
            return;
        }
        state.updateUserProfileIsLoading = true;

        const { data, error } = await mainClient.users.requests.updateUserProfile(payload);
        if (error) {
            state.updateUserProfileError = utils.network.parseError(error, true);
            state.updateUserProfileResult = null;
        } else {
            state.updateUserProfileError = null;
            state.updateUserProfileResult = data;
            if (onSuccess) {
                onSuccess(data);
            }
        }
        state.updateUserProfileIsLoading = false;
    },

    async getUserPersonalInfo({ state }, { onSuccess = null } = {}) {
        if (state.getUserPersonalInfoIsLoading) {
            return;
        }

        state.getUserPersonalInfoIsLoading = true;

        const { data, error } = await mainClient.users.requests.getUserPersonalInfo();
        if (error) {
            state.getUserPersonalInfoError = error;
            state.getUserPersonalInfoResult = null;
        } else {
            state.getUserPersonalInfoError = null;
            state.getUserPersonalInfoResult = data;
            if (onSuccess) {
                onSuccess(data);
            }
        }
        state.getUserPersonalInfoIsLoading = false;
    },
    async updateUserPersonalInfo({ state }, { onSuccess = null, ...payload }) {
        if (state.updateUserPersonalInfoIsLoading) {
            return;
        }

        state.updateUserPersonalInfoIsLoading = true;

        const { data, error } = await mainClient.users.requests.updateUserPersonalInfo(payload);
        if (error) {
            state.updateUserPersonalInfoError = error;
            state.updateUserPersonalInfoResult = null;
        } else {
            state.updateUserPersonalInfoError = null;
            state.updateUserPersonalInfoResult = data;
            if (onSuccess) {
                onSuccess(data);
            }
        }
        state.updateUserPersonalInfoIsLoading = false;
    },

    async getUserJuridicalInfo({ state }, { onSuccess = null } = {}) {
        if (state.getUserJuridicalInfoIsLoading) {
            return;
        }

        state.getUserJuridicalInfoIsLoading = true;

        const { data, error } = await mainClient.users.requests.getUserJuridicalInfo();
        if (error) {
            state.getUserJuridicalInfoError = error;
            state.getUserJuridicalInfoResult = null;
        } else {
            state.getUserJuridicalInfoError = null;
            state.getUserJuridicalInfoResult = data;
            if (onSuccess) {
                onSuccess(data);
            }
        }
        state.getUserJuridicalInfoIsLoading = false;
    },
    async updateUserJuridicalInfo({ state }, { onSuccess = null, ...payload }) {
        if (state.updateUserJuridicalInfoIsLoading) {
            return;
        }

        state.updateUserJuridicalInfoIsLoading = true;

        const { data, error } = await mainClient.users.requests.updateUserJuridicalInfo(payload);
        if (error) {
            state.updateUserJuridicalInfoError = error;
            state.updateUserJuridicalInfoResult = null;
        } else {
            state.updateUserJuridicalInfoError = null;
            state.updateUserJuridicalInfoResult = data;
            if (onSuccess) {
                onSuccess(data);
            }
        }
        state.updateUserJuridicalInfoIsLoading = false;
    },

    async getUserMigrationInfo({ state }, { onSuccess = null } = {}) {
        if (state.getUserMigrationInfoIsLoading) {
            return;
        }

        state.getUserMigrationInfoIsLoading = true;

        const { data, error } = await mainClient.users.requests.getUserMigrationInfo();
        if (error) {
            state.getUserMigrationInfoError = error;
            state.getUserMigrationInfoResult = null;
        } else {
            state.getUserMigrationInfoError = null;
            state.getUserMigrationInfoResult = data;
            if (onSuccess) {
                onSuccess(data);
            }
        }
        state.getUserMigrationInfoIsLoading = false;
    },
    async updateUserMigrationInfo({ state }, { onSuccess = null, ...payload }) {
        if (state.updateUserMigrationInfoIsLoading) {
            return;
        }

        state.updateUserMigrationInfoIsLoading = true;

        const { data, error } = await mainClient.users.requests.updateUserMigrationInfo(payload);
        if (error) {
            state.updateUserMigrationInfoError = error;
            state.updateUserMigrationInfoResult = null;
        } else {
            state.updateUserMigrationInfoError = null;
            state.updateUserMigrationInfoResult = data;
        }
        state.updateUserMigrationInfoIsLoading = false;
    },

    async getUserAdditionalInfo({ state }, { onSuccess = null } = {}) {
        if (state.getUserAdditionalInfoIsLoading) {
            return;
        }

        state.getUserAdditionalInfoIsLoading = true;

        const { data, error } = await mainClient.users.requests.getUserAdditionalInfo();
        if (error) {
            state.getUserAdditionalInfoError = error;
            state.getUserAdditionalInfoResult = null;
        } else {
            state.getUserAdditionalInfoError = null;
            state.getUserAdditionalInfoResult = data;
            if (onSuccess) {
                onSuccess(data);
            }
        }
        state.getUserAdditionalInfoIsLoading = false;
    },
    async updateUserAdditionalInfo({ state }, { onSuccess = null, ...payload }) {
        if (state.updateUserAdditionalInfoIsLoading) {
            return;
        }

        state.updateUserAdditionalInfoIsLoading = true;

        const { data, error } = await mainClient.users.requests.updateUserAdditionalInfo(payload);
        if (error) {
            state.updateUserAdditionalInfoError = error;
            state.updateUserAdditionalInfoResult = null;
        } else {
            state.updateUserAdditionalInfoError = null;
            state.updateUserAdditionalInfoResult = data;
            if (onSuccess) {
                onSuccess(data);
            }
        }
        state.updateUserAdditionalInfoIsLoading = false;
    },

    async getUserPhotos({ state }, { onSuccess = null, ...payload }) {
        if (state.getUserPhotosIsLoading) {
            return;
        }

        state.getUserPhotosIsLoading = true;

        const { data, error } = await mainClient.users.requests.getUserPhotos(payload);
        if (error) {
            state.getUserPhotosError = error;
            state.getUserPhotosResult = null;
        } else {
            state.getUserPhotosError = null;
            state.getUserPhotosResult = data;
            if (onSuccess) {
                onSuccess(data);
            }
        }
        state.getUserPhotosIsLoading = false;
    },

    async updateUserPhoto({ state }, {
        onSuccess = null,
        onError = null,
        isBackground = false,
        ...payload
    }) {
        if (!isBackground && state.updateUserPhotoIsLoading) {
            return;
        }

        if (!isBackground) {
            state.updateUserPhotoIsLoading = true;
        }

        const { data, error } = await mainClient.users.requests.updateUserPhoto(payload);
        if (error) {
            state.updateUserPhotoResult = null;
            state.updateUserPhotoError = error;
            if (onError) {
                onError(error);
            }
        } else {
            state.updateUserPhotoResult = data;
            state.updateUserPhotoError = null;
            if (onSuccess) {
                onSuccess(data);
            }
        }
        if (!isBackground) {
            state.updateUserPhotoIsLoading = false;
        }
    },
    async registration({ state }, payload) {
        if (state.registrationIsLoading) {
            return;
        }
        state.registrationIsLoading = true;
        const { data, error, status } = await mainClient.users.requests.registration(payload);
        if (error) {
            console.error(error, status);
            console.error(`Error in action ${moduleTitle}/registration (${status})`);
            state.registrationError = error;
            state.registrationResult = null;
        } else {
            state.registrationError = null;
            state.registrationResult = data;
        }
        state.registrationIsLoading = false;
    },
    async login({ state, commit, dispatch }, payload) {
        if (state.loginIsLoading) {
            return;
        }
        state.loginIsLoading = true;
        // const { data, error, status } = await mainClient.users.requests.login(payload);
        const { data, error } = await mainClient.users.requests.login(payload);
        if (error) {
            // if (status === 406) {
            //     commit('modals/push', {
            //         name: 'sms-input',
            //         props: {
            //             smsVerificationProps: {
            //                 phone: payload.username,
            //                 isAnonymous: true,
            //                 customCodeChecker: (code) => {
            //                     state.loginIsLoading = false;
            //                     dispatch('login', { ...payload, code });
            //                 },
            //             },
            //         },
            //     }, { root: true });
            //     return;
            // }
            // console.error(error, status);
            // console.error(`Error in action ${moduleTitle}/login (${status})`);
            state.loginError = error;
            state.loginResult = null;
        } else {
            state.loginError = null;
            state.loginResult = data;
        }
        state.loginIsLoading = false;
    },
    async resetPassword({ state }, payload) {
        if (state.resetPasswordIsLoading) {
            return;
        }
        state.resetPasswordIsLoading = true;
        const { data, error, status } = await mainClient.users.requests.resetPassword(payload);
        if (error) {
            console.error(error, status);
            console.error(`Error in action ${moduleTitle}/resetPassword (${status})`);
            state.resetPasswordError = error;
            state.resetPasswordResult = null;
        } else {
            state.resetPasswordError = null;
            state.resetPasswordResult = data;
        }
        state.resetPasswordIsLoading = false;
    },
    async getAnnouncements({ state }, payload = {}) {
        if (state.getAnnouncementsIsLoading) {
            return;
        }
        state.getAnnouncementsIsLoading = true;
        const { data, error, status } = await mainClient.users.requests.getAnnouncements(payload);
        if (error) {
            console.error(error, status);
            console.error(`Error in action ${moduleTitle}/getAnnouncements (${status})`);
            state.getAnnouncementsError = error;
            state.getAnnouncementsResult = null;
        } else {
            state.getAnnouncementsError = null;
            state.getAnnouncementsResult = data;
        }
        state.getAnnouncementsIsLoading = false;
    },
    async getTwoFactor({ state }) {
        if (state.getTwoFactorIsLoading) {
            return;
        }
        state.getTwoFactorIsLoading = true;
        const { data, error, status } = await mainClient.users.requests.getTwoFactor();
        if (error) {
            console.error(error, status);
            state.getTwoFactorError = error;
            state.getTwoFactorResult = null;
        } else {
            state.getTwoFactorError = null;
            state.getTwoFactorResult = data;
        }
        state.getTwoFactorIsLoading = false;
    },
    async enableTwoFactor({ state }, payload) {
        if (state.enableTwoFactorIsLoading) {
            return;
        }
        state.enableTwoFactorIsLoading = true;
        const { error, status } = await mainClient.users.requests.enableTwoFactor(payload);
        if (error) {
            console.error(error, status);
            state.enableTwoFactorError = error;
            state.enableTwoFactorResult = null;
        } else {
            state.enableTwoFactorError = null;
            state.enableTwoFactorResult = 'success';
        }
        state.enableTwoFactorIsLoading = false;
    },
    async disableTwoFactor({ state }, payload) {
        if (state.disableTwoFactorIsLoading) {
            return;
        }
        state.disableTwoFactorIsLoading = true;
        const { error, status } = await mainClient.users.requests.disableTwoFactor(payload);
        if (error) {
            console.error(error, status);
            state.disableTwoFactorError = error;
            state.disableTwoFactorResult = null;
        } else {
            state.disableTwoFactorError = null;
            state.disableTwoFactorResult = 'success';
        }
        state.disableTwoFactorIsLoading = false;
    },
    async verificationReset({ state }, { onSuccess = null, ...payload } = {}) {
        if (state.verificationResetIsLoading) {
            return;
        }
        state.verificationResetIsLoading = true;
        const { data, error } = await mainClient.users.requests.verificationReset(payload);
        if (error) {
            state.verificationResetError = error;
            state.verificationResetResult = null;
        } else {
            state.verificationResetError = null;
            state.verificationResetResult = data;
            if (onSuccess) {
                onSuccess(data);
            }
        }
        state.verificationResetIsLoading = false;
    },
    async logout() {
        const { error, status } = await mainClient.users.requests.logout();
        if (error) {
            console.error(error, status);
            console.error(`Error in action ${moduleTitle}/logout (${status})`);
        } else {
            window.location.href = config.urls.home;
        }
    },
};

const mutations = {
    setUser(state, payload) {
        state.user = payload;
    },
    setProfile(state, payload) {
        state.profile = payload;
    },
    setProfileFull(state, payload) {
        state.profile = {
            // ...state.profile,
            accountCode: payload.accountCode || state.profile.accountCode || '',
            username: payload.username || state.profile.username || '',
            email: payload.email || '',
            firstName: payload.firstName || '',
            lastName: payload.lastName || '',
            secondName: payload.secondName || '',
            phone: payload.phone || '',
            isIndividualEntrepreneur: payload.isIndividualEntrepreneur || false,
            isQualifiedInvestor: payload.isQualifiedInvestor || false,
        };
        state.personalInfo = {
            // ...state.personalInfo,
            juridicalInn: payload.juridicalInn,
            ogrnip: payload.ogrnip,
            passport: payload.passport,
            passportDate: payload.passportDate,
            subdivisionCode: payload.subdivisionCode,
            subdivisionName: payload.subdivisionName,
            birthDate: payload.birthDate,
            birthPlace: payload.birthPlace,
            nationality: payload.nationality,
            citizenship: payload.citizenship,
            organizationName: payload.organizationName,
            organizationAddress: payload.organizationAddress,
            registrationAddress: payload.registrationAddress,
        };
        state.juridicalInfo = {
            // ...state.juridicalInfo,
            juridicalOgrn: payload.juridicalOgrn,
            juridicalOrganizationName: payload.juridicalOrganizationName,
            juridicalOrganizationAddress: payload.juridicalOrganizationAddress,
            juridicalOkved: payload.juridicalOkved,
        };
        state.migrationInfo = {
            // ...state.migrationInfo,
            migrationInfoMode: payload.migrationInfoMode || null,
            migrationCardNumber: payload.migrationCardNumber || null,
            migrationCardDateStart: payload.migrationCardDateStart || null,
            migrationCardDateEnd: payload.migrationCardDateEnd || null,
            migrationDocumentNumber: payload.migrationDocumentNumber || null,
            migrationDocumentDateStart: payload.migrationDocumentDateStart || null,
            migrationDocumentDateEnd: payload.migrationDocumentDateEnd || null,
        };
        // state.additionalInfo = {
        //     // ...state.additionalInfo,
        //     isFinancialNonPerformance: payload.isFinancialNonPerformance || null,
        //     isBeneficiaryOwner: payload.isBeneficiaryOwner || null,
        //     hasOtherBeneficiary: payload.hasOtherBeneficiary || null,
        //     incomeSource: payload.incomeSource || null,
        //     financialPosition: payload.financialPosition || null,
        //     businessReputation: payload.businessReputation || null,
        //     ipFinHasAnnualAccountingStatement: payload.ipFinHasAnnualAccountingStatement || null,
        //     ipFinHasRegularTaxReturn: payload.ipFinHasRegularTaxReturn || null,
        //     ipFinHasAuditorReport: payload.ipFinHasAuditorReport || null,
        //     ipFinHasTaxFullfilmentReport: payload.ipFinHasTaxFullfilmentReport || null,
        //     ipFinHasBankruptCertificate: payload.ipFinHasBankruptCertificate || null,
        //     ipFinBankruptStep: payload.ipFinBankruptStep || null,
        // };
    },
    // setAdditionalData(state, payload) {
    //     const validKeys = Object.keys(payload).filter(key => !!payload[key]);
    //     const result = {};
    //     validKeys.forEach(key => {
    //         result[key] = payload[key];
    //     });
    //     state.additionalInfo = {
    //         ...state.additionalInfo,
    //         ...result,
    //     };
    // },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
