import { mainClient } from '~/network';

const moduleTitle = 'admin/estates';

const state = {
    getEstateInfoIsLoading: false,
    getEstateInfoResult: null,
    getEstateInfoError: null,

    createEstateIsLoading: false,
    createEstateResult: null,
    createEstateError: null,

    removeEstateIsLoading: false,
    removeEstateResult: null,
    removeEstateError: null,

    updateEstateInfoInfoIsButtonActive: false,
    updateEstateInfoIsSaved: true,
    updateEstateInfoIsTouched: false,
    updateEstateInfoIsTouchNeeded: false,
    updateEstateInfoIsInvalid: false,
    updateEstateInfoIsLoading: false,
    updateEstateInfoResult: null,
    updateEstateInfoError: null,

    patchEstateInfoIsLoading: false,
    patchEstateInfoResult: null,
    patchEstateInfoError: null,

    getEstateSharesIsLoading: false,
    getEstateSharesResult: null,
    getEstateSharesError: null,

    getEstateTransferOrdersArchiveIsLoading: false,
    getEstateTransferOrdersArchiveResult: null,
    getEstateTransferOrdersArchiveError: null,

    getEstateRoundsIsLoading: false,
    getEstateRoundsResult: null,
    getEstateRoundsError: null,

    getEstateRequestsIsLoading: false,
    getEstateRequestsResult: null,
    getEstateRequestsError: null,

    cancelEstateInvestRequestIsLoading: false,
    cancelEstateInvestRequestResult: null,
    cancelEstateInvestRequestError: null,

    editEstateInvestRequestResult: null,
    editEstateInvestRequestIsLoading: false,
    editEstateInvestRequestError: null,

    uploadDocumentResult: null,
    uploadDocumentIsLoading: false,
    uploadDocumentError: null,
};

const getters = {
    isLoading(state) {
        return state.getEstateInfoIsLoading ||
        state.updateEstateInfoIsLoading ||
        state.getEstateRequestsIsLoading;
    },
};

const actions = {
    async getEstateInfo({ state }, payload = {}) {
        if (state.getEstateInfoIsLoading) {
            return;
        }
        state.getEstateInfoIsLoading = true;
        const { data, error } = await mainClient.estates.requests.getAdminEstateInfo(payload);
        if (error) {
            state.getEstateInfoError = error;
            state.getEstateInfoResult = null;
        } else if (data) {
            state.getEstateInfoError = null;
            state.getEstateInfoResult = data;
        }
        state.getEstateInfoIsLoading = false;
    },
    async createEstate({ state }, payload = {}) {
        if (state.createEstateIsLoading) {
            return;
        }
        state.createEstateIsLoading = true;
        const { data, error } = await mainClient.estates.requests.createEstate(payload);
        if (error) {
            state.createEstateError = error;
            state.createEstateResult = null;
        } else if (data) {
            state.createEstateError = null;
            state.createEstateResult = data;
        }
        state.createEstateIsLoading = false;
    },
    async removeEstate({ state }, payload = {}) {
        if (state.removeEstateIsLoading) {
            return;
        }
        state.removeEstateIsLoading = true;
        const { error, status } = await mainClient.estates.requests.removeEstate(payload);
        if (error) {
            console.error(`Error in action ${moduleTitle}/removeEstate (${status})`);
            state.removeEstateError = error;
            state.removeEstateResult = null;
        } else {
            state.removeEstateError = null;
            state.removeEstateResult = true;
        }
        state.removeEstateIsLoading = false;
    },
    async updateEstateInfo({ state }, payload = {}) {
        if (state.updateEstateInfoIsLoading) {
            return;
        }
        if (state.updateEstateInfoIsInvalid) {
            if (state.updateEstateInfoInfoIsButtonActive) {
                state.updateEstateInfoInfoIsButtonActive = false;
            }
            return;
        }
        state.updateEstateInfoIsLoading = true;
        let isUpdateInfoNeeded = false;
        if (payload.isUpdateInfoNeeded) {
            isUpdateInfoNeeded = true;
            delete payload.isUpdateInfoNeeded;
        }
        if (payload.id === undefined && state.getEstateInfoResult) {
            payload.id = state.getEstateInfoResult.id;
        }
        const { data, error } = await mainClient.estates.requests.updateAdminEstateInfo({
            ...state.getEstateInfoResult,
            ...payload,
        });
        if (error) {
            state.updateEstateInfoError = error;
            state.updateEstateInfoResult = null;
        } else if (data) {
            state.updateEstateInfoError = null;
            state.updateEstateInfoResult = data;
            state.updateEstateInfoIsSaved = true;
            state.updateEstateInfoIsInvalid = false;
            if (isUpdateInfoNeeded) {
                state.getEstateInfoResult = data;
            }
        }
        state.updateEstateInfoIsLoading = false;
    },
    // async patchEstateInfo({ state }, payload = {}) {
    //     if (state.patchEstateInfoIsLoading) {
    //         return;
    //     }
    //     if (state.patchEstateInfoIsInvalid) {
    //         if (state.patchEstateInfoInfoIsButtonActive) {
    //             state.patchEstateInfoInfoIsButtonActive = false;
    //         }
    //         return;
    //     }
    //     state.patchEstateInfoIsLoading = true;
    //     let isUpdateInfoNeeded = false;
    //     if (payload.isUpdateInfoNeeded) {
    //         isUpdateInfoNeeded = true;
    //         delete payload.isUpdateInfoNeeded;
    //     }
    //     if (payload.id === undefined && state.getEstateInfoResult) {
    //         payload.id = state.getEstateInfoResult.id;
    //         delete payload.isUpdateInfoNeeded;
    //     }
    //     const { data, error, status } = await mainClient.estates.requests.updateAdminEstateInfo({
    //         ...payload,
    //     });
    //     if (error) {
    //         console.error(`Error in action ${moduleTitle}/patchEstateInfo (${status})`);
    //         state.patchEstateInfoError = error;
    //         state.patchEstateInfoResult = null;
    //     } else if (data) {
    //         state.patchEstateInfoError = null;
    //         state.patchEstateInfoResult = data;
    //         if (isUpdateInfoNeeded) {
    //             state.getEstateInfoResult = data;
    //         }
    //     }
    //     state.patchEstateInfoIsLoading = false;
    // },
    async getEstateShares({ state }, payload = {}) {
        if (state.getEstateSharesIsLoading) {
            return;
        }
        state.getEstateSharesIsLoading = true;
        const { data, error } = await mainClient.estates.requests.getAdminEstateShares(payload);
        if (error) {
            state.getEstateSharesError = error;
            state.getEstateSharesResult = null;
        } else if (data) {
            state.getEstateSharesError = null;
            state.getEstateSharesResult = data;
        }
        state.getEstateSharesIsLoading = false;
    },
    async getEstateTransferOrdersArchive({ state }, payload = {}) {
        if (state.getEstateTransferOrdersArchiveIsLoading) {
            return;
        }
        state.getEstateTransferOrdersArchiveIsLoading = true;
        const { data, error } = await mainClient.admin.requests.getEstateTransferOrdersArchive(payload);
        if (error) {
            state.getEstateTransferOrdersArchiveError = error;
            state.getEstateTransferOrdersArchiveResult = null;
        } else if (data) {
            state.getEstateTransferOrdersArchiveError = null;
            state.getEstateTransferOrdersArchiveResult = data;
        }
        state.getEstateTransferOrdersArchiveIsLoading = false;
    },
    async getEstateRounds({ state }, payload = {}) {
        if (state.getEstateRoundsIsLoading) {
            return;
        }
        state.getEstateRoundsIsLoading = true;
        const { data, error } = await mainClient.estates.requests.getAdminEstateRounds(payload);
        if (error) {
            state.getEstateRoundsError = error;
            state.getEstateRoundsResult = null;
        } else if (data) {
            state.getEstateRoundsError = null;
            state.getEstateRoundsResult = data;
        }
        state.getEstateRoundsIsLoading = false;
    },
    async getEstateRequests({ state }, { onSuccess, ...payload } = {}) {
        if (state.getEstateRequestsIsLoading) {
            return;
        }
        state.getEstateRequestsIsLoading = true;
        const { data, error } = await mainClient.estates.requests.getAdminEstateRequests(payload);
        if (error) {
            state.getEstateRequestsError = error;
            state.getEstateRequestsResult = null;
        } else if (data) {
            state.getEstateRequestsError = null;
            state.getEstateRequestsResult = data;
            if (onSuccess) {
                onSuccess(data);
            }
        }
        state.getEstateRequestsIsLoading = false;
    },
    async cancelEstateInvestRequest({ state }, { onSuccess, ...payload } = {}) {
        if (state.cancelEstateInvestRequestIsLoading) {
            return;
        }
        state.cancelEstateInvestRequestIsLoading = true;
        const { data, error } = await mainClient.estates.requests.cancelEstateInvestRequest(payload);
        if (error) {
            state.cancelEstateInvestRequestError = error;
            state.cancelEstateInvestRequestResult = null;
        } else if (data) {
            state.cancelEstateInvestRequestError = null;
            state.cancelEstateInvestRequestResult = data;
            if (onSuccess) {
                onSuccess(data);
            }
        }
        state.cancelEstateInvestRequestIsLoading = false;
    },
    async editEstateInvestRequest({ state }, { onSuccess, ...payload }) {
        if (state.editEstateInvestRequestIsLoading) {
            return;
        }
        state.editEstateInvestRequestIsLoading = true;

        const { data, error } = await mainClient.estates.requests.editEstateInvestRequest(payload);
        if (error) {
            state.editEstateInvestRequestResult = null;
            state.editEstateInvestRequestError = error;
        } else if (data) {
            state.editEstateInvestRequestResult = data;
            state.editEstateInvestRequestError = null;
            if (onSuccess) {
                onSuccess(data);
            }
        }
        state.editEstateInvestRequestIsLoading = false;
    },
    async uploadDocument({ state }, { onSuccess, onComplete, onError, isBackground, ...payload }) {
        if (state.uploadDocumentIsLoading) {
            return;
        }
        if (!isBackground) {
            state.uploadDocumentIsLoading = true;
        }

        const { data, error, status } = await mainClient.estates.requests.uploadDocument(payload);
        if (error) {
            state.uploadDocumentResult = null;
            state.uploadDocumentError = error;
            if (onError) {
                onError(error, status);
            }
        } else if (data) {
            state.uploadDocumentResult = data;
            state.uploadDocumentError = null;
            if (onSuccess) {
                onSuccess(data);
            }
        }
        if (!isBackground) {
            state.uploadDocumentIsLoading = false;
        }
        if (onComplete) {
            onComplete();
        }
    },
};

const mutations = {
    setEstateInfo(state, value) {
        state.getEstateInfoResult = value;
    },
    setUpdateEstateInfoIsInvalid(state, value) {
        state.updateEstateInfoIsInvalid = value;
    },
    setUpdateEstateInfoIsSaved(state, value) {
        state.updateEstateInfoIsSaved = value;
    },
    setUpdateEstateInfoInfoIsButtonActive(state, value) {
        state.updateEstateInfoInfoIsButtonActive = value;
    },
    setUpdateEstateInfoIsTouched(state, value) {
        state.updateEstateInfoIsTouched = value;
    },
    setUpdateEstateInfoIsTouchNeeded(state, value) {
        state.updateEstateInfoIsTouchNeeded = value;
    },
    resetCancelEstateInvestRequest(state) {
        state.cancelEstateInvestRequestIsLoading = false;
        state.cancelEstateInvestRequestResult = null;
        state.cancelEstateInvestRequestError = null;
    },
    resetEditEstateInvestRequest(state) {
        state.editEstateInvestRequestIsLoading = false;
        state.editEstateInvestRequestResult = null;
        state.editEstateInvestRequestError = null;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
