<template>
    <section class="page-blog-list">
        <section class="page-blog-list__headline">
            <div class="page-blog-list__headline-title">
                <div class="page-blog-list__headline-title-overlay" />
                <div class="page-blog-list__headline-title-content">
                    <h1 class="page-blog-list__headline-title-content-main">
                        Полезные материалы и статьи
                    </h1>
                    <div class="page-blog-list__headline-title-content-subtitle">
                        Помогаем разобраться с инвестициями в коммерческую недвижимость
                    </div>
                </div>
            </div>
            <div class="page-blog-list__headline-cover" />
        </section>
        <section class="page-blog-list__list">
            <div class="page-blog-list__list-content">
                <div class="page-blog-list__list-content-item"
                    v-for="(item, index) in accumulatedItems"
                    v-bind:key="index"
                    >
                    <page-blog-list-item
                        v-bind:item="item"
                    />
                </div>
            </div>
            <div class="page-blog-list__list-loader"
                v-if="isLoading">
                <ui-loader
                    v-bind:fixed="50"
                    v-bind:centered="true"
                />
            </div>
            <div class="page-blog-list__list-buttons"
                v-if="!isLoading && pagingTotal > pagingCurrent"
                >
                <div class="page-blog-list__list-buttons-item"
                    v-on:click="getMore"
                    >
                    <ui-button>
                        Показать еще
                    </ui-button>
                </div>
            </div>
        </section>
    </section>
</template>

<script>
export default {
    name: 'page-blog-list',
    data: () => ({
        pagingSize: 9,
        pagingCurrent: 1,
        accumulatedItems: [],
    }),
    computed: {
        items() {
            return this.$store.getters['blog/items'];
        },
        totalItems() {
            return this.$store.getters['blog/count'];
        },
        isLoading() {
            return this.$store.getters['blog/isLoading'];
        },
        pagingTotal() {
            return this.totalItems / this.pagingSize;
        },
    },
    methods: {
        getItems() {
            this.$store.dispatch('blog/getItems', {
                page: this.pagingCurrent,
                page_size: this.pagingSize,
                ordering: '-timestamp',
            });
        },
        getMore() {
            this.pagingCurrent += 1;
            this.getItems();
        },
    },
    mounted() {
        if (!this.items) {
            this.getItems();
        }
    },
    watch: {
        items(newItems) {
            if (newItems) {
                this.accumulatedItems.push(...newItems);
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-blog-list {
    padding-top: 10px;
    padding-bottom: 80px;
    &__headline {
        .container();

        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        width: 100%;
        min-height: 323px;
        margin-bottom: 24px;
        &-title {
            position: relative;

            flex: 0 0 auto;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 50%;
            padding: 50px calc(@grid-gap + @grid-column) 45px;
            border-radius: 4px 0 0 4px;
            overflow: hidden;
            &-overlay {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                background-color: @color-gray-light;
            }
            &-content {
                position: relative;
                &-main {
                    .typography-extra-large();

                    margin: 0 0 20px;
                }
                &-subtitle {
                    .typography-small();
                }
            }
        }
        &-cover {
            flex: 0 0 auto;
            width: 50%;
            border-radius: 0 4px 4px 0;

            background-image: url('/static/images/page-blog-cover.jpg');
            background-color: @color-gray-main;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
    }
    &__list {
        .container();
        &-content {
            display: flex;
            flex-wrap: wrap;
            align-content: stretch;
            justify-content: flex-start;
            padding-top: 12px;
            padding-bottom: 16px;
            margin: -16px;
            &-item {
                flex: 0 0 auto;
                width: calc(100% / 4);
                padding: 16px;
            }
        }
        &-buttons {
            display: flex;
            align-content: center;
            justify-content: center;
            &-item {
            }
        }
    }
    @media @media-xxl-down {
        &__list {
            &-content {
                &-item {
                    width: calc(100% / 3);
                }
            }
        }
    }
    @media @media-md-down {
        padding-top: 0;
        &__headline {
            min-height: 160px;
            padding: 0;
            margin-bottom: 24px;
            &-title {
                .container-paddings();

                width: 100%;
                border-radius: 0;

                background-image: url('/static/images/page-blog-cover.jpg');
                background-color: @color-gray-main;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                &-overlay {
                    opacity: 0.9;
                }
                &-content {
                    &-main {
                        .typography-large();

                        margin-bottom: 0;
                    }
                    &-subtitle {
                        .typography-body();
                    }
                }
            }
            &-cover {
                display: none;
            }
        }
        &__list {
            &-content {
                &-item {
                    width: calc(100% / 2);
                }
            }
        }
    }
    @media @media-sm-down {
        &__list {
            &-content {
                &-item {
                    width: 100%;
                }
            }
        }
    }
}
</style>
