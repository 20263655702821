import { mainClient } from '../../network';

const moduleTitle = 'transfers';

const state = {
    items: {
        state: 'initial',
        data: null,
    },
};

const getters = {
    items(state) {
        return state.items.data;
    },
};

const actions = {
    async getItems({ state, commit }, payload = {}) {
        if (state.items.state === 'loading') {
            return;
        }

        commit('setItemsState', 'loading');
        const { data, error, status } = await mainClient[moduleTitle].requests.getItems(payload);
        if (error) {
            console.error(`Error in action ${moduleTitle}/getItems (${status})`);
            commit('setItemsData', []);
            commit('setItemsState', 'error');
        } else if (data) {
            commit('setItemsData', data.items);
            commit('setItemsState', 'loaded');
        }
    },
};

const mutations = {
    setItemsState(state, value) {
        state.items.state = value;
    },

    setItemsData(state, value) {
        state.items.data = value;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
