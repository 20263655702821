import client from '../_client';
import config from '~/config';
import transforms from './transforms';
import utils from '~/utils';

export const generateInvestmentOfferDocuments = (data = {}) => {
    const id = data.id;
    delete data.id;
    return client.request({
        // headers: {
        //     'X-CSRFToken': utils.network.getCSRFToken(),
        // },
        url: config.api.estatesAdmin.investments.generateInvestmentOfferDocuments.replace('{estateId}', id),
        method: 'POST',
        data: transforms.requests.issueInvestmentOffer(data),
        // transformData: transforms.responses.adminEstateItem,
    });
};

export const issueInvestmentOffer = (data = {}) => {
    const id = data.id;
    delete data.id;
    return client.request({
        // headers: {
        //     'X-CSRFToken': utils.network.getCSRFToken(),
        // },
        url: config.api.estatesAdmin.investments.issueInvestmentOffer.replace('{estateId}', id),
        method: 'POST',
        data,
        // transformData: transforms.responses.adminEstateItem,
    });
};

export const cancelExpiredInvestmentRequests = ({ id, ...data }) => {
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.estatesAdmin.investments.cancelExpiredInvestmentRequests.replace('{estateId}', id),
        method: 'POST',
        data,
    });
};

export const calculateShares = (params = {}) => {
    const id = params.id;
    delete params.id;
    return client.request({
        // headers: {
        //     'X-CSRFToken': utils.network.getCSRFToken(),
        // },
        url: config.api.estatesAdmin.investments.calculateShares.replace('{estateId}', id),
        method: 'GET',
        params,
        transformData: transforms.responses.shareCalculation,
    });
};

export const transferMoney = (data = {}) => {
    const id = data.id;
    delete data.id;
    return client.request({
        headers: {
            'X-CSRFToken': utils.network.getCSRFToken(),
        },
        url: config.api.estatesAdmin.investments.transferMoney.replace('{estateId}', id),
        method: 'POST',
        data,
        // transformData: transforms.responses.shareCalculation,
    });
};

export const convertEstateToShares = (data = {}) => {
    const id = data.id;
    delete data.id;
    return client.request({
        // headers: {
        //     'X-CSRFToken': utils.network.getCSRFToken(),
        // },
        url: config.api.estatesAdmin.investments.convertEstateToShares.replace('{estateId}', id),
        method: 'POST',
        data,
        // transformData: transforms.responses.shareCalculation,
    });
};
