<template>
    <ui-card-base>
        <div class="page-cabinet-requests-list-secondary-item">
            <div class="page-cabinet-requests-list-secondary-item__headline">
                <div class="page-cabinet-requests-list-secondary-item__headline-image">
                    <common-image
                        v-bind:image="image"
                        v-bind:href="item.estate.url"
                    />
                </div>
                <div class="page-cabinet-requests-list-secondary-item__headline-info">
                    <div class="page-cabinet-requests-list-secondary-item__headline-info-estate"
                        v-text="item.estate.title"
                    />
                </div>
            </div>
            <div class="page-cabinet-requests-list-secondary-item__botline">
                <div class="page-cabinet-requests-list-secondary-item__botline-stats">
                    <div class="page-cabinet-requests-list-secondary-item__botline-stats-item"
                        v-for="(stat, i) in stats"
                        v-bind:key="i"
                        >
                        <div class="page-cabinet-requests-list-secondary-item__botline-stats-item-title"
                            v-text="stat.title"
                        />
                        <div class="page-cabinet-requests-list-secondary-item__botline-stats-item-value"
                            v-text="stat.value"
                        />
                    </div>
                </div>
                <div class="page-cabinet-requests-list-secondary-item__botline-actions">
                    <div class="page-cabinet-requests-list-secondary-item__botline-actions-item"
                        v-if="isStatusFormRequired"
                        >
                        <ui-button
                            v-on:click="statusFormHandler"
                            theme="primary"
                            >
                            STATUS_FORM_ACTION
                        </ui-button>
                    </div>
                    <div class="page-cabinet-requests-list-secondary-item__botline-actions-item _cancel"
                        v-if="item.status !== 'canceled'"
                        >
                        <ui-trigger
                            v-on:click="editHandler"
                            theme="secondary"
                            type="button"
                            is-uppercase
                            >
                            Редактировать
                        </ui-trigger>
                    </div>
                </div>
            </div>
        </div>
    </ui-card-base>
</template>

<script>
import { mapState } from 'vuex';
import utils from '~/utils';

export default {
    name: 'page-cabinet-requests-list-secondary-item',
    props: {
        item: {
            type: Object,
        },
    },
    computed: {
        ...mapState('market', [
            'getOrdersResult',
        ]),
        isStatusFormRequired() {
            return this.item.isStatusFormRequired;
        },
        image() {
            return utils.common.injectResize(this.item.estate.image, '134x134');
        },
        stats() {
            const result = [
                { title: 'Дата', value: this.item.dateFormatted },
                { title: 'Тип заявки', value: this.item.typeTitle },
                { title: 'Стоимость акции', value: this.item.priceFormatted },
                { title: 'Кол-во акций', value: this.item.amount },
            ];
            if (this.item.commission) {
                // result.push({ title: 'Комиссия', value: this.item.commissionFormatted });
                // result.push({ title: 'Сумма', value: this.item.sumFormatted });
                // result.push({ title: 'Сумма с комиссией', value: this.item.sumWithCommissionFormatted });
                result.push({ title: 'Сумма', value: this.item.sumWithCommissionFormatted });
            } else {
                result.push({ title: 'Сумма', value: this.item.sumFormatted });
            }
            return result;
        },
    },
    methods: {
        editHandler() {
            this.$store.commit('modals/push', {
                name: 'market-application-create',
                props: {
                    orderId: this.item.id,
                    givenShareId: this.item.share.id,
                    givenType: this.item.type,
                    givenAmountLimit: this.item.amount,
                    givenPrice: this.item.price,
                    givenIsPackageValue: this.item.isPackage,
                    isLocked: true,
                    // isLocked: !this.getOrdersResult,
                    // availableShares: this.getOrdersResult ? this.getOrdersResult.map(x => x.share.id) : null,
                    mode: 'edit',
                },
            });
        },
        statusFormHandler() {
            this.$store.commit('modals/push', {
                name: 'status-form',
                props: {
                    estate: this.item.estate,
                },
            });
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-cabinet-requests-list-secondary-item {
    padding: 8px;
    &__headline {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &-image {
            flex: 0 0 auto;
            width: 67px;
            height: 67px;
            margin-right: 24px;
        }
        &-info {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            &-estate {
                .typography-medium();

                max-width: 553px;
            }
        }
    }
    &__botline {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 16px;
        border-top: 1px solid @color-gray-light;
        padding-top: 24px;
        &-stats {
            flex: 0 0 auto;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            &-item {
                .typography-body();

                flex: 0 0 auto;
                padding-right: 48px;
                &:last-child {
                    padding-right: 24px;
                }
                &-title {
                    margin-bottom: 8px;

                    color: @color-gray-main;
                }
                &:first-child &-title {
                    text-transform: uppercase;
                }
                &:first-child &-value {
                    color: @color-gray-main;
                }
            }
        }
        &-actions {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 24px;
            &-item {
                flex: 0 0 auto;
                width: fit-content;
                &._cancel {
                    .typography-caption();

                    color: @color-gray-main;
                    font-weight: 700;
                    text-transform: uppercase;

                    cursor: pointer;
                }
            }
        }
    }
    @media @media-xl-down {
        &__botline {
            flex-direction: column;
            align-items: flex-start;
            &-actions {
                padding-top: 24px;
                width: 100%;
                justify-content: center;
            }
        }
    }
    @media @media-xl-down {
        padding: 0;
        &__headline {
            align-items: flex-start;
            &-image {
                width: 48px;
                height: 48px;
                margin-right: 16px;
            }
            &-info {
                justify-content: flex-start;
                &-status {
                    margin-bottom: 4px;
                }
                &-estate {
                    .typography-heading();
                }
            }
        }
        &__botline {
            padding-top: 16px;
            &-stats {
                display: block;
                &-item {
                    display: flex;
                    &-title {
                        margin-right: 4px;
                        &::after {
                            content: ':';
                        }
                    }
                }
            }
            &-actions {
                flex-direction: column;
                &-accept {
                    width: 100%;
                    max-width: 280px;
                    margin: 0 auto 24px;
                }
                &-cancel {
                    margin: 0 auto;
                    width: fit-content;
                }
            }
        }
    }
}
</style>
