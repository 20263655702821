<template>
    <div class="ui-table-cell-document">
        <div class="ui-table-cell-document__icon">
            <icon name="document" />
        </div>
        <a class="ui-table-cell-document__title"
            v-bind:href="href"
            v-text="title"
        />
    </div>
</template>

<script>
export default {
    name: 'ui-table-cell-document',
    props: {
        title: {
            type: String,
            default: '',
        },
        href: {
            type: String,
            default: '',
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-table-cell-document {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &__icon {
        margin-right: 4px;

        color: @color-gray-main;
        font-size: 1.8rem;
    }
    &__title {
        .typography-body();

        display: block;

        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}
</style>
