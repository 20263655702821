<template>
    <div class="modal-prepare-invest-offer-form-files-item">
        <transition name="fade">
            <div class="modal-prepare-invest-offer-form-files-item__loader"
                v-if="isLoading"
                >
                <div class="modal-prepare-invest-offer-form-files-item__loader-container">
                    <ui-loader />
                </div>
            </div>
        </transition>
        <div class="modal-prepare-invest-offer-form-files-item__title"
            v-text="document.title"
        />
        <div class="modal-prepare-invest-offer-form-files-item__subtitle"
            v-if="documentFileTitle"
            v-text="documentFileTitle"
            v-bind:title="documentFileTitle"
        />
        <div class="modal-prepare-invest-offer-form-files-item__error"
            v-if="error"
            v-text="error"
        />
        <template v-if="isUploadActive">
            <div class="modal-prepare-invest-offer-form-files-item__field"
                v-if="file === null"
                >
                <ui-input-base-file
                    v-bind="{ ...document, value: this.file }"
                    v-on:change="changeHandler"
                    v-bind:is-invalid="!!error"
                />
            </div>
            <div class="modal-prepare-invest-offer-form-files-item__file"
                v-else
                >
                <div class="modal-prepare-invest-offer-form-files-item__file-title">Файл для загрузки: {{ file.name }}</div>
                <div class="modal-prepare-invest-offer-form-files-item__file-size">Размер: {{ Math.ceil(file.size / 1024) }} Kb</div>
            </div>
        </template>
        <div class="modal-prepare-invest-offer-form-files-item__actions">
            <template v-if="isUploadActive">
                <div class="modal-prepare-invest-offer-form-files-item__actions-item"
                    v-if="file !== null"
                    >
                    <ui-trigger
                        v-on:click="uploadHandler"
                        >
                        Загрузить
                    </ui-trigger>
                </div>
                <div class="modal-prepare-invest-offer-form-files-item__actions-item"
                    v-if="file || document.value"
                    >
                    <ui-trigger
                        v-on:click="cancelHandler"
                        >
                        Отменить
                    </ui-trigger>
                </div>
            </template>
            <template v-else>
                <div class="modal-prepare-invest-offer-form-files-item__actions-item">
                    <ui-trigger
                        v-bind:href="document.value"
                        >
                        Просмотреть
                    </ui-trigger>
                </div>
                <div class="modal-prepare-invest-offer-form-files-item__actions-item">
                    <ui-trigger
                        v-on:click="activateUpload"
                        >
                        Загрузить другой файл
                    </ui-trigger>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import utils from '~/utils';

export default {
    name: 'modal-prepare-invest-offer-form-files-item',
    props: {
        document: {
            type: [ Object, String, File ],
        },
        validations: {
            type: Object,
        },
        estate: {
            type: Object,
        },
    },
    data: function() {
        return {
            file: null,
            initialValue: null,
            isUploadActive: false,
            isLoading: false,
            backendError: null,
        };
    },
    computed: {
        documentFileTitle() {
            if (this.document.value) {
                return decodeURI(this.document.value.split('/').pop());
            }
            return null;
        },
        error() {
            if (this.backendError) {
                return this.backendError;
            }
            if (this.validations.$dirty &&
                !this.validations.documents[this.document.title]
            ) {
                return 'Загрузите документ!';
            }
            return null;
        },
    },
    methods: {
        uploadHandler() {
            this.isLoading = true;
            this.$store.dispatch('admin/estates/uploadDocument', {
                document: this.document,
                file: this.file,
                estate: this.estate,
                onSuccess: data => this.updateDocument(data.url),
                onError: (error, status) => {
                    if (status === 400) {
                        this.backendError = `Oшибка валидации файла\n${utils.network.parseError(error)}`;
                        return;
                    } else {
                        this.backendError = 'Ошибка загрузки файла';
                    }
                    this.file = null;
                },
                onComplete: () => {
                    this.isLoading = false;
                },
            });
        },
        cancelHandler() {
            this.file = null;
            this.backendError = null;
            if (this.document.value) {
                this.isUploadActive = false;
            }
        },
        updateDocument(newUrl) {
            this.$emit('update-document', { title: this.document.title, value: newUrl });
        },
        activateUpload() {
            this.isUploadActive = true;
            this.file = null;
        },
        changeHandler(event) {
            this.file = event;
            this.backendError = null;
        },
    },
    watch: {
        document: {
            handler() {
                this.isUploadActive = !this.document?.value;
            },
            immediate: true,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-prepare-invest-offer-form-files-item {
    position: relative;
    &__loader {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: @color-gray-lightest;
        &-container {
            width: 50px;
        }
    }
    &__title {
        .typography-body();

    }
    &__subtitle {
        .typography-caption();

        width: 100%;
        overflow: hidden;

        color: @color-gray-main;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    &__error {
        .typography-heading();

        margin-top: 12px;

        color: @color-accent-negative;
        white-space: pre-line;
    }
    &__field {
        margin-top: 12px;
    }
    &__file {
        margin-top: 12px;
        &-title {
            .typography-body();
        }
        &-size {
            .typography-caption();

            color: @color-gray-main;
        }
    }
    &__actions {
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        gap: 16px;
        margin-top: 12px;
        &-item {
            flex: 0 0 auto;
        }
    }
}
</style>
