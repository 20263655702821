<template>
    <article class="plugin-comparisons">
        <div class="plugin-comparisons__titles">
            <div class="plugin-comparisons__titles-item"
                v-for="(item, index) in items"
                v-bind:key="index"
                v-bind:class="{ _active: index === activeIndex }"
                v-on:click="setActiveIndex(index)"
                v-text="item.title"
                >
            </div>
        </div>
        <div class="plugin-comparisons__list">
            <div class="plugin-comparisons__list-item">
                <img class="plugin-comparisons__list-item-image"
                    v-bind:src="getImageUrl(activeItem.image)"
                />
                <div class="plugin-comparisons__list-item-comment"
                    v-if="!noComments"
                    >
                    <div class="common-ckeditor"
                        v-html="activeItem.comment"
                    />
                </div>
            </div>
        </div>
    </article>
</template>

<script>
import utils from '~/utils';

export default {
    name: 'plugin-comparisons',
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        noComments: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        activeIndex: 0,
    }),
    computed: {
        activeItem() {
            return this.items[this.activeIndex];
        },
    },
    methods: {
        setActiveIndex(index) {
            this.activeIndex = index;
        },
        getImageUrl(image) {
            if (!image) {
                return null;
            }
            const resize = utils.common.getResize({
                1920: 72,
                767: 100,
            });
            return utils.common.injectResize(image, resize);
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-comparisons {
    .typography-body();

    &__titles {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: baseline;
        margin: 0 -@grid-gap-half;
        overflow: auto;
        &-item {
            .typography-heading();

            margin: 0 @grid-gap-half 24px;

            color: @color-gray-main;
            white-space: nowrap;

            cursor: pointer;

            transition: color @duration-fast ease-in-out;

            &._active {
                color: @color-gray-darker;
            }
        }
    }
    &__list {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        width: 100%;
        &-item {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            &-image {
                flex: 1 1 auto;
                width: 66.01%;
                border: 1px solid @color-gray-main;
                border-radius: @border-radius-md;
            }
            &-comment {
                flex: 0 0 auto;
                align-self: stretch;
                display: flex;
                justify-content: flex-start;
                align-items: flex-end;
                width: 33.99%;
                padding-bottom: 15px;
                padding-left: @grid-gap;
            }
        }
    }

    @media @media-md-down {
        &__list {
            &-item {
                flex-direction: column;
                &-image {
                    width: 100%;
                }
                &-comment {
                    width: 100%;
                    padding-bottom: 0;
                    padding-left: 0;
                }
            }
        }
    }
}
</style>
